import React from 'react';

import { Box, Typography, makeStyles } from '@material-ui/core';
import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0 17px',
  },
  backBtn: {
    color: theme.palette.secondaryColor,
  },
  title: {
    fontFamily: 'SofiaPro-Bold',
    color: theme.palette.secondaryColor,
    fontSize: '30px',
    lineHeight: '30px',
    letterSpacing: -0.1,
    marginTop: '2px',
  },
  subTitle: {
    fontSize: '16px',
    color: theme.palette.secondaryColor,
  },
  permission: {
    fontSize: '16px',
    color: theme.palette.secondaryColor,
    fontWeight: 'bold',
  },
  ul: {
    paddingLeft: 20,
    '& li': {
      padding: '8px 0',
      color: theme.palette.secondaryColor,
    },
  },
}));

const SystemRequirements = () => {
  const classes = useStyles();

  return (
    <Page>
      <Box className={classes.root}>
        <Box>
          <BackButton color={classes.backBtn} page="/more/setting" />
        </Box>
        <Box textAlign="center" mt={4}>
          <Typography component="h3" className={classes.title}>
            System Requirement
          </Typography>
        </Box>
        <Box textAlign="center" mt={2}>
          <Typography component="h6" className={classes.subTitle}>
            ZenSports web app requires modern browsers that support PWA.
          </Typography>
        </Box>
        <Box mt={3}>
          <Typography component="h6" className={classes.permission}>
            Permissions
          </Typography>
          <Box mt={2}>
            <ul className={classes.ul}>
              <li>Local Storage</li>
              <li>Indexed DB</li>
              <li>Internet</li>
              <li>Location</li>
            </ul>
          </Box>
        </Box>
      </Box>
    </Page>
  );
};

SystemRequirements.defaultProps = {};

SystemRequirements.propTypes = {};

export default SystemRequirements;
