import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';

import {
  getLeaguesAction,
  getBetTypesAction,
  getSchedulesAction,
  getBetListAction,
  getBetResultListAction,
  getUpcomingGamesAction,
  getBettingObjectsAction,
  getSingleBetAction,
  acceptBetAction,
  createBetAction,
  setLeagueAction,
  setIntervalsAction,
  setGamesAction,
  setBetTypeAction,
  setBetTimeAction,
  setBetOddsAction,
  setScheduleAction,
  setBettingObjectAction,
  setFilterAction,
  resetFilterAction,
  setResultsAction,
  closeBetAction,
  disputeResultsAction,
  getBetFavoriteListAction,
  deleteBetAction,
  editBetAction,
} from 'store/actions/bet';

export const useBet = () => useSelector(({ bet }) => bet);

export const useLeagues = (params) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getLeaguesAction(params));
    // eslint-disable-next-line
  }, [params]);
  return useSelector(({ bet: { leagues } }) => leagues);
};

export const useEsports = () => useSelector(({
  bet: { leagues },
}) => leagues.find((league) => league.type === 'group')?.leagues || []);

export const useBetList = (query, options) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (options) {
      dispatch(getBetListAction(query, options));
    }
    // eslint-disable-next-line
  }, [query, options]);
  return useSelector(({ bet: { list } }) => list);
};

export const useBetResultList = (query, options) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (options) {
      dispatch(getBetResultListAction(query, options));
    }
    // eslint-disable-next-line
  }, [query, options]);
  return useSelector(({ bet: { list } }) => list);
};

export const useBetFavoriteList = (query, options) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (options) {
      dispatch(getBetFavoriteListAction(query, options));
    }
    // eslint-disable-next-line
  }, [query, options]);
  return useSelector(({ bet: { list } }) => list);
};

export const useBetSchedules = (params) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (params) {
      dispatch(getSchedulesAction(params));
    }
    // eslint-disable-next-line
  }, [params]);
  return useSelector(({ bet: { schedules } }) => schedules);
};

export const useSingleBet = (betId) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (betId) {
      dispatch(getSingleBetAction({ betId }));
    }
    // eslint-disable-next-line
  }, [betId]);
  return useSelector(({ bet: { single } }) => single);
};

export const useBetTypes = (params) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (params) {
      dispatch(getBetTypesAction(params));
    }
    // eslint-disable-next-line
  }, [params]);
  return useSelector(({ bet: { betTypes } }) => betTypes);
};

export const useGames = (query, options) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (options) {
      dispatch(getUpcomingGamesAction(query, options));
    }
    // eslint-disable-next-line
  }, [query, options]);
  return useSelector(({ bet: { games } }) => games);
};

export const useBetObjects = (query, options) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (options) {
      dispatch(getBettingObjectsAction(query, options));
    }
    // eslint-disable-next-line
  }, [query, options]);
  return useSelector(({ bet: { objects } }) => objects);
};

export const useBetSelected = () => useSelector(({
  bet: { selected },
}) => selected);

export const useSelectedLeague = () => useSelector(({
  bet: { selected: { league } },
}) => league);

export const useSelectedGames = () => useSelector(({
  bet: { selected: { games } },
}) => games);

export const useSelectedBetObject = () => useSelector(({
  bet: { selected: { object } },
}) => object);

export const useSelectedBetObjectType = () => useSelector(({
  bet: { selected: { object } },
}) => object?.type || 'player');

export const useSelectedBetSchedule = () => useSelector(({
  bet: { selected: { schedule } },
}) => schedule);

export const useSelectedBetType = () => useSelector(({
  bet: { selected: { betType } },
}) => betType);

export const useSelectedBetTime = () => useSelector(({
  bet: { selected: { betTime } },
}) => betTime);

export const useSelectedBetOdds = () => useSelector(({
  bet: { selected: { odds } },
}) => odds);

export const useSelectedIntervals = () => useSelector(({
  bet: { selected: { intervals } },
}) => intervals);

export const useSelectedInterval = () => useSelector(({
  bet: { selected: { intervals } },
}) => intervals[0] || 'multiple_games');

export const useSetLeagueDispatch = () => {
  const dispatch = useDispatch();
  return (data) => dispatch(setLeagueAction(data));
};

export const useSetIntervalsDispatch = () => {
  const dispatch = useDispatch();
  return (data) => dispatch(setIntervalsAction(data));
};

export const useSetGamesDispatch = () => {
  const dispatch = useDispatch();
  return (data) => dispatch(setGamesAction(data));
};

export const useSetBetObjectDispatch = () => {
  const dispatch = useDispatch();
  return (data) => dispatch(setBettingObjectAction(data));
};

export const useSetBetScheduleDispatch = () => {
  const dispatch = useDispatch();
  return (data) => dispatch(setScheduleAction(data));
};

export const useSetBetTypeDispatch = () => {
  const dispatch = useDispatch();
  return (data) => dispatch(setBetTypeAction(data));
};

export const useSetBetTimeDispatch = () => {
  const dispatch = useDispatch();
  return (data) => dispatch(setBetTimeAction(data));
};

export const useSetBetOddsDispatch = () => {
  const dispatch = useDispatch();
  return (data) => dispatch(setBetOddsAction(data));
};

export const useSetFilterDispatch = () => {
  const dispatch = useDispatch();
  return (data) => dispatch(setFilterAction(data));
};

export const useResetFilterDispatch = () => {
  const dispatch = useDispatch();
  return () => dispatch(resetFilterAction());
};

export const useCreateBetDispatch = () => {
  const dispatch = useDispatch();
  return (data) => dispatch(createBetAction(data));
};

export const useAcceptBetDispatch = () => {
  const dispatch = useDispatch();
  return (data) => dispatch(acceptBetAction(data));
};

export const useSetResultsDispatch = () => {
  const dispatch = useDispatch();
  return (data) => dispatch(setResultsAction(data));
};

export const useCloseBetDispatch = () => {
  const dispatch = useDispatch();
  return (data) => dispatch(closeBetAction(data));
};

export const useDisputeResultsDispatch = () => {
  const dispatch = useDispatch();
  return (data) => dispatch(disputeResultsAction(data));
};

export const useDeleteBetDispatch = () => {
  const dispatch = useDispatch();
  return (data) => dispatch(deleteBetAction(data));
};

export const useEditBetDispatch = () => {
  const dispatch = useDispatch();
  return (data) => dispatch(editBetAction(data));
};
