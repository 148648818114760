import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import BetInfoCard from 'components/Bet/BetInfoCard';
import ZenDialog from 'components/Dialog/ZenDialog';
import InputCurrencyExchange from 'components/Form/InputCurrencyExchange';
import OutlineButton from 'components/Buttons/BorderOutlineButton';

import { useMappedCurrencies } from 'hooks/redux/currency';
import { useUserOddType } from 'hooks/redux/user';
import {
  useParlay,
  useSubmitParlayDispatch,
  useDeleteParlayDispatch,
  useClearParlayDispatch,
} from 'hooks/redux/parlay';
import {
  useOpenErrorModalDispatch,
  useOpenConfirmModalDispatch,
  useOpenSuccessModalDispatch,
} from 'hooks/redux/modal';

import { getAvailableMaxBetAmount, getBetInfo, getParlayAcceptInfo } from 'utils/betting';
import { convertCurrencyAmount, currencyConvert, formatCurrency } from 'utils/currency';
import { usdCurrency } from 'utils/constants';
import { getErrorMessage } from 'utils';
import commonStyle from 'styles/common';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 8px',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  emptyState: {
    textAlign: 'center',
    color: '#aeaeae',
    marginTop: '120px',
    lineHeight: '1.2',
  },
  likeContent: {
    color: palette.seaGreenColor,
    font: '14px SofiaPro-Bold',
    letterSpacing: 0,
  },
  icon: {
    marginLeft: '10px',
    color: '#29CCD3',
    fontSize: '20px',
  },
  maxBet: {
    color: '#7A869A',
    font: '12px SofiaPro',
    letterSpacing: 0,
  },
  maxBetButton: {
    backgroundColor: palette.betBtnColor,
    color: '#29BFD5',
    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.15)',
    borderRadius: '5px',
    border: palette.betBtnBorder,
    padding: '8px 5px 5px 5px',
    font: '12px SofiaPro-Bold',
    textAlign: 'center',
  },
  receive: {
    paddingTop: '14px',
  },
  winTitle: {
    color: 'white',
    font: '14px SofiaPro-Bold',
  },
  winSubTitle: {
    color: '#7A869A',
    font: '12px SofiaPro-Black',
    marginTop: '5px',
  },
  winAmount: {
    color: '#29CAD4',
    font: '13px SofiaPro-Bold',
    marginTop: '5px',
    textAlign: 'center',
  },
  betsContainer: {
    margin: '30px 0 400px',
  },
  bottomContainer: {
    margin: '0 -18px',
    position: 'fixed',
    bottom: 0,
    width: '100%',
  },
  calcContainer: {
    backgroundColor: palette.cardColor,
    padding: '10px 30px',
  },
  combinedPayoutContainer: {
    backgroundColor: '#52526f',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '44px',
    padding: '0 30px',
  },
  combinedPayoutTopic: {
    backgroundColor: '#FC5A5A',
    color: '#FFF',
    padding: '4px 10px 3px 10px',
    font: '12px SofiaPro-Medium',
    borderRadius: '20px',
  },
  combinedPayout: {
    font: '15px SofiaPro-Medium',
    letterSpacing: -0.25,
    color: '#FFF',
  },
  acceptContainer: {
    backgroundColor: '#14133D',
    padding: '0 30px',
  },
}));

const ParlayCheckout = ({ history }) => {
  const classes = useStyles();
  const commonClasses = commonStyle();
  const [cryptoCurrencyAmount, setCryptoCurrencyAmount] = useState('');
  const [usdCurrencyAmount, setUsdCurrencyAmount] = useState('');
  const [betWinAmount, setBetWinAmount] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [parlaySubmitInfo, setParlaySubmitInfo] = useState({
    maxBetAmount: 0,
    odds: 0,
    formattedMaxBetAmount: '',
    formattedOddsAmount: '',
  });
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);

  const mappedCurrencies = useMappedCurrencies();
  const oddType = useUserOddType();
  const parlay = useParlay();
  const deleteParlay = useDeleteParlayDispatch();
  const clearParlay = useClearParlayDispatch();
  const submitParlay = useSubmitParlayDispatch();
  const openSuccessModal = useOpenSuccessModalDispatch();
  const openErrorModal = useOpenErrorModalDispatch();
  const openConfirmModal = useOpenConfirmModalDispatch();

  const handleDeleteParlay = (bet) => {
    deleteParlay(bet);
  };

  const infoMessage = () => {
    let message = '';
    if (parlay.currency.symbol === 'sports') {
      message = 'Enter in the amount of your bet, up to the Maximum Bet amount or your Available Balance, whichever is less. '
        + 'Minimum Bet amount is $1 worth of SPORTS tokens (based on the current exchange rate). '
        + 'Keep in mind that ZenSports charges a 2.5% fee for accepting bets in SPORTS. '
        + 'If you tap the "Max Bet" button, '
        + 'we\'ll automatically calculate the max you can bet accounting for the 2.5% fee that will get deducted.';
    } else if (parlay.currency.symbol === 'btc') {
      message = 'Enter in the amount of your bet, up to the Maximum Bet amount or your Available Balance, '
        + 'whichever is less. Minimum Bet amount is $1 worth of BTC (based on the current exchange rate). '
        + 'Keep in mind that ZenSports charges a 5% fee for accepting bets in BTC. If you tap the "Max Bet" button, '
        + 'we\'ll automatically calculate the max you can bet accounting for the 5% fee that will get deducted.';
    } else if (parlay.currency.symbol === 'usd') {
      message = 'Enter in the amount of your bet, up to the Maximum Bet amount or your Available Balance, '
        + 'whichever is less. Minimum Bet amount is $1. Keep in mind that ZenSports charges a 5% fee for accepting bets in USD. '
        + 'If you tap the "Max Bet" button, '
        + 'we\'ll automatically calculate the max you can bet accounting for the 5% fee that will get deducted.';
    } else if (parlay.currency.symbol === 'icx') {
      message = 'Enter in the amount of your bet, up to the Maximum Bet amount or your Available Balance, '
        + 'whichever is less. Minimum Bet amount is $1. Keep in mind that ZenSports charges a 5% fee for accepting bets in ICX. '
        + 'If you tap the "Max Bet" button, '
        + 'we\'ll automatically calculate the max you can bet accounting for the 5% fee that will get deducted.';
    }
    return message;
  };

  const changeCryptoCurrencyAmount = (amount) => {
    setCryptoCurrencyAmount(amount);
    setUsdCurrencyAmount(formatCurrency(
      currencyConvert(
        convertCurrencyAmount(amount, parlay.currency),
        parlay.currency,
        usdCurrency,
      ),
      usdCurrency,
      false,
    ));
    setBetWinAmount(
      formatCurrency(
        convertCurrencyAmount(amount * parlaySubmitInfo.odds, parlay.currency),
        parlay.currency,
      ),
    );
  };

  const changeUsdCurrencyAmount = (amount) => {
    setUsdCurrencyAmount(amount);
    setCryptoCurrencyAmount(formatCurrency(
      currencyConvert(
        convertCurrencyAmount(amount, usdCurrency),
        usdCurrency,
        parlay.currency,
      ),
      parlay.currency,
      false,
    ));
    setBetWinAmount(
      formatCurrency(
        currencyConvert(
          convertCurrencyAmount(amount * parlaySubmitInfo.odds, usdCurrency),
          usdCurrency,
          parlay.currency,
        ),
        parlay.currency,
      ),
    );
  };

  const handleCryptoCurrencyAmount = (event) => {
    changeCryptoCurrencyAmount(event.target.value);
  };

  const handleUsdCurrencyAmount = (event) => {
    changeUsdCurrencyAmount(event.target.value);
  };

  const handleMaxBet = () => {
    if (selectedCurrency) {
      changeCryptoCurrencyAmount(
        formatCurrency(
          getAvailableMaxBetAmount(
            selectedCurrency.balance,
            parlaySubmitInfo.maxBetAmount,
            parlay.currency.symbol,
          ),
          parlay.currency,
          false,
        ),
      );
    }
  };

  const handleAccept = () => {
    if (parlay.list.length <= 1) {
      openErrorModal({
        title: 'Oops',
        subtitle: 'You can only check out and accept a Parlay Card that has 2 or more bets in it.'
          + ' Please add at least one more bet to this Parlay Card before checking out.',
        buttonText: 'Got it',
      });
      return;
    }
    if (Number(cryptoCurrencyAmount) <= 0) {
      openErrorModal({
        title: 'Oops',
        subtitle: 'Parlay value should be greater than zero.',
      });
      return;
    }
    openConfirmModal({
      title: 'Confirm Parlay Card acceptance',
      subtitle: `Please confirm that you want to accept this Parlay Card.
      This action can NOT be un-done once you accept it, so please review everything one more time to
      make sure that you have the bets, wager amount, and all other details exactly as you want it before 
      accepting this Parlay Card.`,
      agreeBtnText: 'Yes, Accept',
      disagreeBtnText: 'No, Don\'t Accept',
      callback: async () => {
        try {
          setDisabledSubmit(true);
          const data = {
            amount: convertCurrencyAmount(cryptoCurrencyAmount, parlay.currency),
            amount_currency_symbol: parlay.currency.symbol,
            proposed_bet_ids: parlay.list.map(({ id }) => id),
          };
          if (data.amount > parlaySubmitInfo.maxBetAmount) {
            setDisabledSubmit(false);
            openErrorModal({
              title: 'Oops',
              subtitle: `The maximum amount allowed for this parlay is ${parlaySubmitInfo.formattedMaxBetAmount}.
               Don't be so greedy - try a lower amount.`,
            });
            return;
          }
          await submitParlay(data);
          setDisabledSubmit(false);
          openSuccessModal({
            title: 'Woohoo!',
            subtitle: 'Your acceptance of this Parlay Card has been confirmed. Best of luck! 😎',
            buttonText: 'Done',
            callback: () => {
              history.goBack();
              clearParlay();
            },
          });
        } catch (e) {
          setDisabledSubmit(false);
          openErrorModal({ title: 'Accept Parlay Error', subtitle: getErrorMessage(e) });
        }
      },
    });
  };

  useEffect(() => {
    if (parlay.currency && mappedCurrencies[parlay.currency.symbol]) {
      setSelectedCurrency(mappedCurrencies[parlay.currency.symbol]);
    }
  }, [mappedCurrencies, parlay.currency]);

  useEffect(() => {
    if (parlay?.list.length > 0) {
      setParlaySubmitInfo(getParlayAcceptInfo(parlay, oddType));
    }
  }, [parlay, oddType]);

  return (
    <Page showNavigation={false}>
      <Box className={classes.root}>
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
        >
          <BackButton color={commonClasses.backBtn} />
        </Box>
        <Typography variant="h3" className={commonClasses.title2}>
          Parlay Card
        </Typography>
        <Box className={classes.betsContainer}>
          {parlay?.list.length > 0 ? (
            <>
              {
                parlay.list.map((bet) => (
                  <BetInfoCard
                    key={bet.id}
                    betInfo={getBetInfo(bet, oddType)}
                    showTrashBtn
                    showMaxBet={false}
                    showAcceptBet={false}
                    onDeleteParlay={() => handleDeleteParlay(bet)}
                  />
                ))
              }
            </>
          ) : (
            <>
              <Typography className={classes.emptyState} variant="subtitle2">
                There are no bets to accept parlay.
              </Typography>
            </>
          )}
        </Box>
        <Box className={classes.bottomContainer}>
          {parlay?.list.length > 0 && (
            <Box className={classes.calcContainer}>
              <Box mb={1} textAlign="center">
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Typography variant="subtitle2" className={classes.likeContent}>
                    How much would you like to bet?
                  </Typography>
                  <InfoOutlinedIcon className={classes.icon} onClick={() => setInfoDialogOpen(true)} />
                  { infoDialogOpen && (
                    <ZenDialog
                      title="Bet Amount"
                      message={infoMessage()}
                      cta="Got It!"
                      onClose={() => setInfoDialogOpen(false)}
                    />
                  ) }
                </Box>
                <Typography variant="subtitle2" className={classes.maxBet}>
                  Max Bet:
                  {' '}
                  {parlaySubmitInfo.formattedMaxBetAmount}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="flex-start" alignItems="center" pl={3}>
                <Box flex={1}>
                  <InputCurrencyExchange
                    currency={parlay.currency}
                    cryptoCurrencyAmount={cryptoCurrencyAmount}
                    usdCurrencyAmount={usdCurrencyAmount}
                    onCryptoCurrencyAmountChange={handleCryptoCurrencyAmount}
                    onUsdCurrencyAmountChange={handleUsdCurrencyAmount}
                  />
                </Box>
                <Box className={classes.maxBetButton} onClick={handleMaxBet}>Max Bet</Box>
              </Box>
            </Box>
          )}
          <Box className={classes.combinedPayoutContainer}>
            <Box className={classes.combinedPayoutTopic}>Combined Odds Payout</Box>
            <Box className={classes.combinedPayout}>{parlaySubmitInfo.formattedOddsAmount}</Box>
          </Box>
          <Box className={classes.acceptContainer}>
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
              className={classes.receive}
              mb={4}
            >
              <Typography variant="subtitle2" className={classes.winTitle}>
                Total You’ll Receive If You Win All bets
              </Typography>
              <Typography variant="subtitle2" className={classes.winSubTitle}>
                (includes your original bet)
              </Typography>
              <Typography variant="subtitle2" className={classes.winAmount} style={{ height: 15 }}>
                {betWinAmount}
              </Typography>
            </Box>
            <Box pb={3}>
              <OutlineButton disabled={disabledSubmit} onClick={handleAccept}>
                Accept Parlay Card
              </OutlineButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Page>
  );
};

ParlayCheckout.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(ParlayCheckout);
