import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';

import {
  formatCurrency,
  getFormattedCurrency,
  convertCurrencyAmount,
  currencyConvert,
} from 'utils/currency';
import { usdCurrency } from 'utils/constants';
import { useMappedCurrencies, useSelectedCurrency } from 'hooks/redux/currency';

const useStyles = makeStyles(({ palette }) => ({
  box: {
    borderRadius: '15px',
    background: palette.dimGray,
    padding: '12px 24px',
    font: '18px SofiaPro-Bold',
    textAlign: 'center',
  },
  exchangeRate: {
    color: palette.secondaryColor,
    textTransform: 'uppercase',
    font: '14px SofiaPro-Bold',
    marginTop: '10px',
  },
  exchangeRateLbl: {
    color: palette.secondaryColor,
    font: '14px SofiaPro-Bold',
  },
  walletImage: {
    marginRight: '20px',
    '& img': {
      width: 'auto',
      height: '56px',
    },
  },
  walletBalance: {
    flex: 1,
    overflow: 'hidden',
    color: palette.secondaryColor,
  },
  amount: {
    font: '24px SofiaPro-Bold',
    color: palette.secondaryColor,
  },
  usdAmount: {
    font: '14px SofiaPro-Bold',
    letterSpacing: -0.3,
    color: palette.secondaryColor,
  },
  currency: {
    marginTop: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    font: '14px SofiaPro-Medium',
    color: '#7A869A',
  },
}));

const CurrencyDropdown = ({
  changeable, showExchangeRate, history, match,
}) => {
  const classes = useStyles();
  const [selectedCurrency, setSelectedCurrency] = useState();
  const mappedCurrencies = useMappedCurrencies();
  const currencySymbol = useSelectedCurrency();
  const handleNext = () => {
    if (changeable) {
      history.push('/funds/wallet', { prevPath: match.url });
    }
  };

  useEffect(() => {
    if (mappedCurrencies[currencySymbol]) {
      setSelectedCurrency(mappedCurrencies[currencySymbol]);
    }
  }, [mappedCurrencies, currencySymbol]);

  return (
    <Box>
      <Box
        onClick={handleNext}
        className={classes.box}
        display="flex"
        alignItems="center"
      >
        {selectedCurrency && (
          <>
            <Box className={classes.walletImage}>
              <img src={selectedCurrency.wallet_image_url} alt="Wallet" />
            </Box>
            <Box className={classes.walletBalance}>
              <Typography className={classes.amount}>
                {
                  formatCurrency(
                    selectedCurrency.balance,
                    selectedCurrency,
                    false,
                  )
                }
              </Typography>
              {selectedCurrency.symbol !== 'usd' && (
                <Typography className={classes.usdAmount}>
                  (
                  {
                    getFormattedCurrency(
                      selectedCurrency.balance,
                      selectedCurrency,
                      'usd',
                    )
                  }
                  )
                </Typography>
              )}
              <Typography className={classes.currency}>
                {selectedCurrency.symbol.toUpperCase()}
                {' '}
                Wallet
                {changeable && (
                  <ExpandMore className="color-primary" />
                )}
              </Typography>
            </Box>
          </>
        )}
      </Box>
      {showExchangeRate && selectedCurrency && selectedCurrency.symbol !== 'usd' && (
        <Box className={classes.box} mt={2}>
          <Typography className={classes.exchangeRateLbl}>Exchange Rate</Typography>
          <Typography className={classes.exchangeRate}>
            {'1 '}
            {selectedCurrency.symbol}
            {' = '}
            {getFormattedCurrency(
              convertCurrencyAmount(
                selectedCurrency.rate,
                usdCurrency,
              ),
              usdCurrency,
              'usd',
            )}
          </Typography>
          <Typography className={classes.currency}>
            {'$1 = '}
            {getFormattedCurrency(
              currencyConvert(
                100,
                usdCurrency,
                selectedCurrency,
              ),
              selectedCurrency,
              'crypto',
            )}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

CurrencyDropdown.defaultProps = {
  changeable: true,
  showExchangeRate: false,
};

CurrencyDropdown.propTypes = {
  match: PropTypes.object.isRequired,
  changeable: PropTypes.bool,
  showExchangeRate: PropTypes.bool,
  history: PropTypes.object.isRequired,
};

export default withRouter(CurrencyDropdown);
