import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTransactionsListAction } from 'store/actions/transaction';

export const useTransactionIsLoading = () => useSelector(({ transaction: { isLoading } }) => isLoading);

export const useTransactionsList = (params) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (params) {
      dispatch(getTransactionsListAction(params));
    }
    // eslint-disable-next-line
  }, [params]);
  // Had to remove dependencies: dispatch, params to stop infinite rendering.
  return useSelector(({ transaction: { list } }) => list);
};
