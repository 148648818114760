import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@material-ui/core';
import Circle from '@material-ui/icons/PanoramaFishEye';
import CheckCircle from '@material-ui/icons/CheckCircle';

import BlankImage from 'assets/images/profile.png';

const PrimaryCheckbox = withStyles({
  root: {
    color: '#979797',
    '&.Mui-checked': {
      color: '#63C4C6',
    },
  },
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles(({ palette }) => ({
  imageUrl: {
    width: '56px',
    height: '56px',
    backgroundColor: 'transparent',
    borderRadius: '50%',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      width: 'auto',
      height: '100%',
    },
  },
  playerName: {
    color: palette.secondaryColor,
    font: '19px SofiaPro-SemiBold',
    paddingLeft: '19px',
  },
  teamName: (props) => ({
    color: props.type === 'team' ? palette.secondaryColor : '#a4a4a4',
    font: props.type === 'team' ? '19px SofiaPro-SemiBold' : '14px SofiaPro',
    paddingLeft: '19px',
  }),
  checkBox: {
    margin: '0px',
  },
}));

const FavoriteAddingCard = ({
  id,
  type,
  playerName,
  teamName,
  leagueName,
  imageUrl,
  teamImageUrl,
  leagueImageUrl,
  selectedId,
  selectFavorite,
}) => {
  const classes = useStyles({ imageUrl, type });
  return (
    <Box alignItems="center" display="flex" justifyContent="space-between" mb={4}>
      <Box display="flex" position="relative">
        <Box className={classes.imageUrl}>
          {type === 'team' && (
            <img src={teamImageUrl} alt="Team" />
          )}
          {type === 'player' && (
            <img src={imageUrl || BlankImage} alt="player" />
          )}
          {type === 'league' && (
            <img src={leagueImageUrl || BlankImage} alt="league" />
          )}
        </Box>
      </Box>
      <Box pl={2} flex={1} alignItems="flex-start" display="flex" flexDirection="column" justifyContent="center">
        {type === 'league' ? (
          <Typography variant="subtitle1" className={classes.playerName}>
            {leagueName}
          </Typography>
        ) : (
          <>
            <Typography variant="subtitle1" className={classes.playerName}>
              {playerName}
            </Typography>
            <Typography variant="subtitle1" className={classes.teamName}>
              {teamName}
            </Typography>
          </>
        )}
      </Box>
      <FormControlLabel
        control={(
          <PrimaryCheckbox
            icon={<Circle style={{ fontSize: 32, color: '#CCCCCC' }} />}
            checkedIcon={<CheckCircle style={{ fontSize: 32 }} />}
            checked={id === selectedId}
            onChange={(e) => selectFavorite(e.currentTarget.value, e.target.checked, type)}
            name="checkedH"
            size="medium"
            value={id}
          />
        )}
        label=""
        className={classes.checkBox}
      />
    </Box>
  );
};

FavoriteAddingCard.defaultProps = {
  imageUrl: null,
  teamImageUrl: null,
  leagueImageUrl: null,
  type: 'team',
  teamName: null,
  playerName: null,
  leagueName: null,
  id: 0,
  selectedId: 0,
  selectFavorite: () => {},
};

FavoriteAddingCard.propTypes = {
  imageUrl: PropTypes.string,
  teamImageUrl: PropTypes.string,
  leagueImageUrl: PropTypes.string,
  type: PropTypes.string,
  teamName: PropTypes.string,
  playerName: PropTypes.string,
  leagueName: PropTypes.string,
  id: PropTypes.number,
  selectedId: PropTypes.number,
  selectFavorite: PropTypes.func,
};

export default FavoriteAddingCard;
