import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';

import { setBirthdayAction } from 'store/actions/auth';
import { useOpenErrorModalDispatch } from 'hooks/redux/modal';

import {
  Box, Fab, FormControl, Typography, TextField,
} from '@material-ui/core';
import DatePicker from 'react-mobile-datepicker';
import { makeStyles } from '@material-ui/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Page from 'components/Page/Auth';
import BackButton from 'components/Buttons/BackButton';
import SoccerStadiumImage from 'assets/images/soccer-stadium.png';
import { dateOptions, monthMap } from 'utils/constants';

const useStyles = makeStyles({
  birthday: {
    padding: '0',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white !important',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#63c4c6 !important',
      },
    },
    '& .MuiInputBase-input': {
      textAlign: 'center',
      color: 'white',
      opacity: '1',
    },
  },
  datePicker: {
    position: 'absolute',
    width: '100%',
    '& .android': {
      position: 'relative !important',
      top: '15px !important',
      width: '90% !important',
      margin: 'auto',
      '& .datepicker-content': {
        '& .datepicker-col-1:first-child': {
          order: 3,
        },
        '& .datepicker-col-1:last-child': {
          order: 1,
        },
      },
      '& .datepicker-navbar': {
        flexDirection: 'row-reverse',
        '& .datepicker-navbar-btn:first-child': {
          borderLeft: '1px solid #d9d4d4',
        },
      },
    },
  },
});

const SubmitButton = ({ birthday, isSubmitting }) => {
  const { setFieldValue } = useFormikContext();
  const handleSubmit = useCallback(async () => {
    await setFieldValue('birthday', birthday);
  }, [setFieldValue, birthday]);
  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
      mt={5}
      mr={3}
      onClick={handleSubmit}
    >
      <Fab type="submit" disabled={isSubmitting} aria-label="Next">
        <ArrowForwardIcon />
      </Fab>
    </Box>
  );
};

SubmitButton.propTypes = {
  birthday: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

const Birthday = ({ history, setUserBirthday }) => {
  const openErrorModal = useOpenErrorModalDispatch();
  const classes = useStyles();
  const [isPopup, setIsPopup] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [birthday, setBirthday] = useState('');
  const handleSelect = (date) => {
    setIsPopup(!isPopup);
    setIsOpen(false);
    setBirthday(date.toLocaleString('en-US', dateOptions));
  };
  const handleDatePop = () => {
    setIsPopup(!isPopup);
    setIsOpen(false);
  };
  return (
    <Page backgroundImage={SoccerStadiumImage}>
      <Box>
        <BackButton />
      </Box>
      <Typography variant="h1" className="text-center color-white">
        It’s Your Birthday!
      </Typography>
      <Box mt={2}>
        <Typography variant="subtitle1" className="text-center color-white">
          Well, it’s probably not your actual birthday today. But ZenSports does need to know your birthday
          because all users must be at least 18 years of age to use ZenSports.
        </Typography>
      </Box>
      <Box mt={3} pr={3} pl={3}>
        <Formik
          initialValues={{
            birthday: '',
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            const diffMs = Date.now() - new Date(values.birthday).getTime();
            const ageDate = new Date(diffMs);
            const age = Math.abs(ageDate.getUTCFullYear() - 1970);
            if (age >= 18) {
              setUserBirthday(values.birthday);
              history.push('/location');
            } else {
              const message = 'Unfortunately, since you`re less than 18 years of age we won`t be able to give you access to ZenSports.'
                + ' We`ll be waiting for you once you`re a few years older.';
              openErrorModal({ title: 'Oops', subtitle: message });
            }
          }}
          validationSchema={Yup.object().shape({
            birthday: Yup.date()
              .required(),
          })}
        >
          {({
            touched,
            errors,
            isSubmitting,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              <FormControl
                className={classes.birthday}
                variant="outlined"
                fullWidth
                onClick={() => handleDatePop()}
              >
                <div style={{ display: 'inline-block', position: 'relative' }} onClick={() => handleDatePop()}>
                  <TextField
                    id="birthday"
                    variant="outlined"
                    name="birthday"
                    autoComplete="off"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={birthday}
                    disabled
                    placeholder="dd/mm/yyyy"
                    onClick={() => handleDatePop()}
                    aria-describedby="outlined-birthday-helper-text"
                    inputProps={{
                      'aria-label': 'birthday',
                    }}
                    error={errors.birthday && touched.birthday}
                    helperText={(errors.birthday && touched.birthday) && errors.birthday}
                  />
                  <div style={{
                    position: 'absolute',
                    left: '0',
                    right: '0',
                    top: '0',
                    bottom: '0',
                  }}
                  />
                </div>
                <Box className={classes.datePicker}>
                  <DatePicker
                    isPopup={isPopup}
                    isOpen={isOpen}
                    theme="android"
                    max={new Date()}
                    onSelect={handleSelect}
                    onCancel={() => setIsPopup(!isPopup)}
                    dateConfig={
                      {
                        year: {
                          format: 'YYYY',
                          caption: 'Year',
                          step: 1,
                        },
                        month: {
                          format: (value) => monthMap[value.getMonth() + 1],
                          caption: 'Mon',
                          step: 1,
                        },
                        date: {
                          format: 'DD',
                          caption: 'Day',
                          step: 1,
                        },
                      }
                    }
                    showHeader={false}
                    cancelText="Cancel"
                    confirmText="OK"
                  />
                </Box>
              </FormControl>
              <SubmitButton
                birthday={birthday}
                isSubmitting={isSubmitting}
              />
            </form>
          )}
        </Formik>
      </Box>
    </Page>
  );
};

Birthday.propTypes = {
  history: PropTypes.object.isRequired,
  setUserBirthday: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  setUserBirthday: (birthday) => dispatch(setBirthdayAction(birthday)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Birthday));
