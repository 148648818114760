import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  InputBase,
} from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/HighlightOff';

const useStyles = makeStyles(({ palette }) => ({
  searchIcon: {
    color: 'grey',
    width: '18px',
    paddingRight: '13px',
  },
  clearIcon: {
    color: 'grey',
    width: '18px',
    paddingRight: '0px',
    paddingLeft: '4px',
  },
  input: {
    color: 'black',
    font: '15px SofiaPro-Bold',
    letterSpacing: -0.2,
  },
  searchInput: {
    margin: '13px',
    padding: '5px 13px',
    display: 'flex',
    alignItems: 'center',
    background: palette.dimGray,
    borderRadius: '6px',
    '& input ': {
      color: palette.secondaryColor,
    },
  },
}));

const SearchInput = (props) => {
  const {
    placeholder, value, handleChange, clearSearch, showClear,
  } = props;
  const classes = useStyles();

  const displayClearBtn = () => value && showClear;

  return (
    <Box className={classes.searchInput}>
      <SearchIcon className={classes.searchIcon} />
      <InputBase
        autoComplete="off"
        className={classes.input}
        placeholder={placeholder}
        inputProps={{ 'aria-label': placeholder }}
        fullWidth
        value={value}
        onChange={handleChange}
      />
      {displayClearBtn() && <ClearIcon className={classes.clearIcon} onClick={clearSearch} />}
    </Box>
  );
};

SearchInput.defaultProps = {
  clearSearch: () => {},
  showClear: false,
};

SearchInput.propTypes = {
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  clearSearch: PropTypes.func,
  showClear: PropTypes.bool,
};

export default SearchInput;
