import httpService from './http.service';

const getFundsOverview = (params) => httpService
  .get('/balance_transactions/overview', {}, params)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

export default {
  getFundsOverview,
};
