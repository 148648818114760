import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { debounce } from 'lodash';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import TuneIcon from '@material-ui/icons/Tune';

import Page from 'components/Page/Dashboard';
import Loading from 'components/Loading/Box';
import VList from 'components/VList';
import SearchInput from 'components/Form/SearchInput';
import BetResultInfoCard from 'components/Bet/BetResultInfoCard';
import ParlayResultCard from 'components/Parlay/ParlayResultCard';

import { getBetResultInfo, getParlayResultInfo } from 'utils/betting';
import { getFilterQuery } from 'utils/betting.sort';
import { useBet, useBetResultList, useBetSelected } from 'hooks/redux/bet';
import { useMappedCurrencies } from 'hooks/redux/currency';
import { useUserOddType, useAuthUser, useUserRestrictedAccess } from 'hooks/redux/user';
import {
  useExcludeParlayItemsDispatch,
  useSetParlayResultItemsDispatch,
} from 'hooks/redux/parlay';
import commonStyle from 'styles/common';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 8px',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    '& .cardOverride': {
      margin: '5px 10px 10px 5px',
    },
  },
  filterBtn: {
    transform: 'rotate(-90deg)',
    color: palette.secondaryColor,
    fontSize: '2rem',
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
  },
  emptyState: {
    textAlign: 'center',
    color: '#aeaeae',
    marginTop: '120px',
    lineHeight: '1.2',
  },
}));

const ResultList = ({ history }) => {
  const classes = useStyles();
  const commonClasses = commonStyle();
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState();
  const [debounceSearch, setDebounceSearch] = useState('');

  const betting = useBet();
  const bets = useBetResultList(debounceSearch, options);
  const user = useAuthUser();
  const access = useUserRestrictedAccess();
  const filter = useBetSelected();
  const oddType = useUserOddType();
  const mappedCurrencies = useMappedCurrencies();
  const initParlayExclude = useExcludeParlayItemsDispatch();
  const initParlayResult = useSetParlayResultItemsDispatch();

  // eslint-disable-next-line
  const doDebounceSearch = useCallback(
    debounce((value) => setDebounceSearch(value), 500), [debounce, setDebounceSearch],
  );

  const handleSearch = (e) => {
    setSearch(e.target.value);
    doDebounceSearch(e.target.value);
  };

  const handleFilter = () => {
    history.push('/bets/results/filter');
  };

  const handleExpand = () => {
    history.push('/bets/results/leagues');
  };

  const handleSelectCard = useCallback((id, isParlay = false) => {
    if (isParlay) {
      initParlayExclude([]);
      initParlayResult([]);
      history.push(`/bets/results/parlay/${id}`);
    } else {
      history.push(`/bets/view/${id}`);
    }
  }, [history, initParlayExclude, initParlayResult]);

  useEffect(() => {
    if (filter && user.id && mappedCurrencies) {
      filter.user = user;
      filter.isCryptoVisible = access.isCryptoVisible;
      filter.league = {};
      setOptions({
        hitsPerPage: 1000,
        page: 0,
        filters: getFilterQuery(filter, mappedCurrencies, 'result'),
        filter,
      });
    }
    // eslint-disable-next-line
  }, [filter, access.isCryptoVisible]);

  return (
    <Page>
      <Box className={classes.root}>
        <Box display="flex" justifyContent="flex-end">
          <FormatListBulletedIcon fontSize="large" className={commonClasses.backBtn} onClick={() => handleExpand()} />
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center" mb={1}>
          <Typography variant="h3" className={classes.title}>
            Results
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <SearchInput
            handleChange={handleSearch}
            placeholder="Search a team or player"
            value={search}
          />
          <TuneIcon className={classes.filterBtn} onClick={() => handleFilter()} />
        </Box>

        <Box className={commonClasses.vListWrapper}>
          {!betting.isLoading ? (bets.length > 0 ? (
            <VList
              rowCount={bets.length}
              rowRender={(index) => {
                const isParlay = bets[index].type === 'parlay';
                if (isParlay) {
                  const parlayInfo = getParlayResultInfo(bets[index], user, oddType);
                  return (
                    <Box mb={2}>
                      <ParlayResultCard
                        parlayInfo={parlayInfo}
                        onClick={() => handleSelectCard(parlayInfo.id, true)}
                      />
                    </Box>
                  );
                }
                const betInfo = getBetResultInfo(bets[index], user, oddType);
                return (
                  <Box mb={2}>
                    <BetResultInfoCard
                      showAcceptType
                      betInfo={betInfo}
                      onClick={() => handleSelectCard(betInfo.id)}
                    />
                  </Box>
                );
              }}
            />
          ) : (
            <>
              {(bets.length <= 0 && search === '') ? (
                <Typography className={classes.emptyState} variant="subtitle2">
                  Bummer! It doesn&apos;t look like you currently have any created or accepted bets going on right now.
                  Tap the Bets screen in the bottom left corner to begin creating or accepting some bets!
                </Typography>
              ) : (
                <Typography className={classes.emptyState} variant="subtitle2">
                  Aw shucks! No bets match the criteria that you&apos;ve selected. Try searching of filtering by some
                  different criteria.
                </Typography>
              )}
            </>
          )) : (<Loading />)}
        </Box>
      </Box>
    </Page>
  );
};

ResultList.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(ResultList);
