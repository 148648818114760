import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  Box,
  Grid,
  InputBase,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import BetResultInfoCard from 'components/Bet/BetResultInfoCard';
import OutlineButton from 'components/Buttons/OutlineButton';

import { useCloseBetDispatch, useSetResultsDispatch } from 'hooks/redux/bet';
import {
  useOpenErrorModalDispatch,
  useOpenConfirmModalDispatch,
  useOpenSuccessModalDispatch,
} from 'hooks/redux/modal';
import { getErrorMessage } from 'utils';

const useStyles = makeStyles(({ palette }) => ({
  accept: {
    borderTop: '1px solid #cccccc',
    borderBottom: '1px solid #cccccc',
  },
  subtitle: {
    color: palette.secondaryColor,
    font: '14px SofiaPro-Medium',
    paddingBottom: '5px',
  },
  smallTitle: {
    color: palette.secondaryColor,
    font: '14px SofiaPro-Medium',
    paddingBottom: '5px',
  },
  amount: {
    color: palette.secondaryColor,
    font: '15px SofiaPro-Bold',
    textAlign: 'center',
    paddingBottom: '0px',
  },
  total: {
    borderBottom: '1px solid #cccccc',
  },
  detail: {
    color: palette.secondaryColor,
    font: '16px SofiaPro-Bold',
    paddingBottom: '8px',
  },
  noResult: {
    color: '#A4A4A4',
    font: '14px SofiaPro-Bold',
  },
  resultCards: {
    width: '100%',
    padding: '0 15px',
  },
  resultCard: {
    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.15)',
    height: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    backgroundColor: palette.fieldColor,
    '& .title': {
      color: palette.secondaryColor,
      font: '15px SofiaPro-Bold',
      lineHeight: 1,
      textAlign: 'center',
      letterSpacing: '-0.3px',
    },
    '&.selected': {
      position: 'relative',
      backgroundImage: 'linear-gradient(45deg, #298EDA, #29D2D3)',
      '& .title': {
        color: '#FFF',
      },
    },
  },
  teamPoints: {
    height: '60px',
    boxShadow: '0 3px 10px 0 rgba(0,0,0,0.15)',
    borderRadius: '6px',
    backgroundColor: palette.fieldColor,
    '& input': {
      color: palette.secondaryColor,
      font: '16px SofiaPro-Bold',
      textAlign: 'center',
    },
  },
  icon: {
    marginLeft: '5px',
    color: '#29CCD3',
    fontSize: '20px',
    paddingBottom: '8px',
  },
  textArea: {
    padding: 10,
    color: palette.secondaryColor,
    boxShadow: '0 3px 10px 0 rgba(0,0,0,0.15)',
    borderRadius: '5px',
    backgroundColor: palette.dimGray,
  },
  percentage: {
    color: palette.secondaryColor,
    font: '15px SofiaPro-Medium',
    textAlign: 'center',
    paddingBottom: '5px',
  },
  teamNames: {
    color: '#29CCD3',
    font: '16px SofiaPro-Bold',
    paddingBottom: '5px',
  },
  closeBet: {
    font: '18px SofiaPro-SemiBold',
    color: '#29CDD4',
    margin: '20px 0',
  },
}));

const resultTerms = [
  {
    label: 'Win',
    value: 'win',
  },
  {
    label: 'Loss',
    value: 'loss',
  },
  {
    label: 'It was a Tie',
    value: 'tie',
  },
  {
    label: 'Time Changed, Postponed, or Cancelled',
    value: 'canceled',
  },
];

const SubmitResult = ({ betInfo }) => {
  const classes = useStyles();
  const history = useHistory();
  const [resultData, setResultData] = useState({
    details: '',
    homeTeamPoints: '',
    awayTeamPoints: '',
  });
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const openSuccessModal = useOpenSuccessModalDispatch();
  const openErrorModal = useOpenErrorModalDispatch();
  const openConfirmModal = useOpenConfirmModalDispatch();
  const setResults = useSetResultsDispatch();
  const closeBet = useCloseBetDispatch();

  const handleSelectResultCard = (result) => {
    setResultData({ ...resultData, result });
  };

  const handleSetTeamPoints = (teamPoints, value) => {
    setResultData({ ...resultData, [teamPoints]: value });
  };

  const handleSetResultDetails = (value) => {
    setResultData({ ...resultData, details: value });
  };

  const handleCloseBet = async () => {
    openConfirmModal({
      title: 'Please Confirm',
      subtitle: `Are you sure that you want to close out this bet?
      Closing this bet will prevent any new Takers from accepting the bet under these terms.
      However, previous Takers that have already accepted the bet will still be in place,
      and you will still be required to submit the results once the sporting event is over.`,
      agreeBtnText: 'Yes, Close the Bet',
      disagreeBtnText: 'No, Don\'t Close the Bet',
      callback: async () => {
        try {
          await closeBet({ betId: betInfo.id });
          history.goBack();
        } catch (e) {
          openErrorModal({ title: 'Close Bet Error', subtitle: getErrorMessage(e) });
        }
      },
    });
  };

  const handleSubmit = async () => {
    const data = {
      result: resultData.result,
      details: resultData.details,
    };

    if (betInfo.objectType === 'team' && betInfo.interval === 'single_game') {
      data.home_team_points = resultData.homeTeamPoints;
      data.away_team_points = resultData.awayTeamPoints;

      if (!resultData.homeTeamPoints || !resultData.awayTeamPoints) {
        openErrorModal({ title: 'Oops', subtitle: 'Please enter team points!' });
        return;
      }
    } else if (!resultData.details) {
      openErrorModal({ title: 'Oops', subtitle: 'Please enter details!' });
      return;
    }
    openConfirmModal({
      title: 'Are you sure?',
      subtitle: `Are you 100% sure that you want to submit these results as you've indicated them to be?
       This can NOT be changed, and if you submit the wrong results, not only will you lose your original
       bet amount, but you'll also pay a 10% penalty for submitting incorrect results.`,
      agreeBtnText: 'Yes, Submit Results',
      disagreeBtnText: 'No, Not Yet',
      callback: async () => {
        setDisabledSubmit(true);
        try {
          await setResults({ betId: betInfo.id, data });
          setDisabledSubmit(false);
          openSuccessModal({
            title: 'Thanks for submitting results!',
            subtitle: `We've notified the other bettor(s), and they will have 24 hours to dispute these results.
           If they don't dispute the results within 24 hours, your winnings will automatically be released into your account balance.
           If they dispute the results successfully, you will lose not only your original bet, but an additional 10% of the bet amount.
           If they dispute the results unsuccessfully, you will win the bet, plus an additional 5% of the bet amount.
            We'll notify you either way of what they do and/or once your winnings are in your account balance.`,
            buttonText: 'Got it',
            nextPath: '/bets/results',
          });
        } catch (e) {
          setDisabledSubmit(false);
          openErrorModal({ title: 'Submit Error', subtitle: getErrorMessage(e) });
        }
      },
    });
  };

  return (
    <>
      <BetResultInfoCard betInfo={betInfo} showAcceptTime />
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        pb={1}
        pt={1}
        className={classes.accept}
      >
        <Typography variant="subtitle2" className={classes.smallTitle}>
          Filled/Accepted
        </Typography>
        <Typography variant="subtitle2" style={{ color: `${betInfo.currencyColor}` }} className={classes.amount}>
          {betInfo.formattedFilledPercentageAmount}
        </Typography>
        <Typography variant="subtitle2" className={classes.percentage}>
          {betInfo.closedPercentageText}
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        className={classes.total}
        pb={1}
        pt={1}
      >
        <Typography variant="subtitle2" className={classes.smallTitle}>
          Total You’ll Receive If You Win
        </Typography>
        <Typography variant="subtitle2" style={{ color: `${betInfo.currencyColor}` }} className={classes.amount}>
          {betInfo.formattedTotalReceiveAmount}
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        pb={1}
        pt={1}
      >
        <Typography variant="subtitle2" className={classes.detail}>
          Did you win this bet?
        </Typography>
        <Box mt={2} className={classes.resultCards}>
          <Grid
            container
            spacing={1}
          >
            {resultTerms.map((term) => (
              <Grid item xs={6} sm={6} key={term.value}>
                <Box
                  className={`${classes.resultCard} ${resultData.result === term.value ? 'selected' : ''}`}
                  onClick={() => handleSelectResultCard(term.value)}
                >
                  <Typography className="title">{term.label}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      {
        (betInfo.objectType === 'team' && betInfo.interval === 'single_game') && (
          <Box pb={1} pt={1}>
            <Box display="flex" mt={2} pl={2}>
              <Typography variant="subtitle2" className={classes.detail}>
                Enter Team Scores
              </Typography>
              <InfoOutlinedIcon className={classes.icon} />
            </Box>
            <Box display="flex" style={{ padding: '0px 16px' }} p={2}>
              <Grid item xs={6} sm={6}>
                <Box mt={2} pr={1}>
                  <Typography variant="subtitle2" className={classes.teamNames}>
                    {!betInfo.isSoccerGame ? betInfo.awayTeamName : betInfo.homeTeamName}
                  </Typography>
                  <InputBase
                    autoComplete="off"
                    fullWidth
                    className={classes.teamPoints}
                    placeholder="Enter Score"
                    inputProps={{ inputMode: 'numeric' }}
                    value={!betInfo.isSoccerGame ? resultData.awayTeamPoints : resultData.homeTeamPoints}
                    onChange={(e) => handleSetTeamPoints(
                      !betInfo.isSoccerGame ? 'awayTeamPoints' : 'homeTeamPoints',
                      e.target.value,
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Box mt={2} pl={1}>
                  <Typography variant="subtitle2" className={classes.teamNames}>
                    {!betInfo.isSoccerGame ? betInfo.homeTeamName : betInfo.awayTeamName}
                  </Typography>
                  <InputBase
                    autoComplete="off"
                    fullWidth
                    className={classes.teamPoints}
                    placeholder="Enter Score"
                    inputProps={{ inputMode: 'numeric' }}
                    value={!betInfo.isSoccerGame ? resultData.homeTeamPoints : resultData.awayTeamPoints}
                    onChange={(e) => handleSetTeamPoints(
                      !betInfo.isSoccerGame ? 'homeTeamPoints' : 'awayTeamPoints',
                      e.target.value,
                    )}
                  />
                </Box>
              </Grid>
            </Box>
          </Box>
        )
      }
      <>
        <Box display="flex" alignItems="center" justifyContent="flex-start" style={{ marginLeft: '15px' }} mt={2}>
          <Typography variant="subtitle2" className={classes.detail}>
            {(betInfo.objectType === 'team' && betInfo.interval === 'single_game') ? (
              'OR Enter Details Bet Results'
            ) : 'Enter Details Bet Results' }
          </Typography>
          <InfoOutlinedIcon className={classes.icon} />
        </Box>
        <Box p={2} style={{ paddingTop: '0px' }}>
          <InputBase
            autoComplete="off"
            fullWidth
            multiline
            rows={4}
            className={classes.textArea}
            placeholder="Write details here..."
            value={resultData.details}
            onChange={(e) => handleSetResultDetails(e.target.value)}
          />
        </Box>
      </>
      <Box p={2}>
        <OutlineButton disabled={disabledSubmit} onClick={() => handleSubmit()}>
          Submit Results
        </OutlineButton>
        {betInfo.status === 'partially_accepted' && !betInfo.isClosed && (
          <Box className="text-center">
            <Typography className={classes.closeBet} onClick={() => handleCloseBet()}>
              Close Bet
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

SubmitResult.defaultProps = {
  betInfo: {},
};

SubmitResult.propTypes = {
  betInfo: PropTypes.object,
};

export default SubmitResult;
