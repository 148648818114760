import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import { Box, Typography } from '@material-ui/core';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import Market from 'components/Exchange/Market';
import Loading from 'components/Loading/Box';

import config from 'utils/config';
import { useAuthAccessToken } from 'hooks/redux/user';
import { useOpenConfirmModalDispatch } from 'hooks/redux/modal';
import {
  useMarketsList,
  useGetMarketChange,
  useExchangeIsLoading,
  useShowRollingOver,
  useHideRollingOverDispatch,
} from 'hooks/redux/exchange';
import commonStyle from 'styles/common';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 15px',
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
    padding: 10,
  },
  content: {
    margin: '17px -15px 7px',
    color: '#AEAEAE',
    font: '13px SofiaPro',
  },
  subtitle: {
    font: '17px SofiaPro-Bold',
    color: palette.secondaryColor,
    padding: 10,
  },
  card: {
    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.15)',
    padding: '10px 20px',
    borderRadius: '5px',
    color: palette.secondaryColor,
    font: '18px SofiaPro-Bold',
  },
  walletImage: {
    height: '50px',
  },
  add: {
    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.15)',
    borderRadius: '5px',
    background: '#FFFFFF',
    minHeight: 40,
    color: '#29CDD4',
    font: '16px SofiaPro-Bold',
  },
  header_box: {
    borderBottom: '1px solid #cccccc',
    padding: 8,
  },
  header: {
    textAlign: 'center',
    font: '15px SofiaPro-Regular',
    color: '#AEAEAE',
  },
}));

const Markets = ({ history }) => {
  const classes = useStyles();
  const commonClasses = commonStyle();
  const [markets, setMarkets] = useState([]);

  const isLoading = useExchangeIsLoading();
  const initialMarkets = useMarketsList();
  const accessToken = useAuthAccessToken();
  const getMarketChange = useGetMarketChange();
  const showRollingOver = useShowRollingOver();
  const openConfirmModal = useOpenConfirmModalDispatch();
  const hideRollingOver = useHideRollingOverDispatch();

  const handleMarket = (market) => {
    if (market.buy_currency?.symbol === 'sports' && showRollingOver) {
      const message = `Betting on sports within our app using SPORTS utility tokens allows you to pay reduced betting fees,
earn cash back, bonuses, and other rewards. <span role="img" aria-label="emoji">😎</span><br><br>
ZenSports has a 50% rollover requirement of any SPORTS utility tokens deposited or purchased through our Exchange.
This means you must wager at least 50% of the SPORTS utility tokens deposited directly or purchased 
through our Exchange on actual sports bets within our app prior to making a withdrawal or selling your tokens through our Exchange 
(wash betting to meet the 50% rollover is strictly prohibited). <br><br>
Alternatively, you can instead choose to pay higher betting fees and not earn any rewards or bonuses at all 
by betting with Bitcoin, USDT, or Fiat (Skrill) instead.`;

      openConfirmModal({
        title: '50% Rollover Requirement',
        subtitle: message,
        agreeBtnText: 'Don\'t Show Again',
        disagreeBtnText: 'Got It',
        callback: () => {
          hideRollingOver();
        },
      });
    }
    history.push(`/funds/exchange/${market.id}`);
  };

  useEffect(() => {
    if (initialMarkets.length) {
      const ws = new WebSocket(`${config.exchangeSocketUrl}/cable?token=${accessToken}`);
      setMarkets(initialMarkets);
      const marketIds = initialMarkets.map((market) => market.id).join();
      ws.onopen = () => {
        ws.send(JSON.stringify({
          command: 'subscribe',
          identifier: `{"channel":"TradingPairChannel", "market_ids":[${marketIds}]}`,
        }));
      };

      ws.onmessage = ({ data }) => {
        const msg = JSON.parse(data);
        if (msg.identifier) {
          const identifier = JSON.parse(msg.identifier);
          if (
            identifier.channel
            && identifier.channel === 'TradingPairChannel'
            && msg.message
            && msg.message.market
          ) {
            const _markets = initialMarkets.map((market) => {
              if (msg.message.market.id === market.id) {
                return {
                  ...msg.message.market,
                  change: market.change,
                  increased: market.increased,
                  last_direction: market.last_direction,
                  last_price: market.last_price,
                  volume: market.volume,
                };
              }
              return market;
            });
            setMarkets(_markets);
          }
        }
        return () => ws.close();
      };
    }
    // eslint-disable-next-line
  }, [initialMarkets.length, accessToken]);

  useEffect(() => {
    const timer = setInterval(async () => {
      const data = await getMarketChange();
      if (data && data.markets && initialMarkets.length) {
        const _markets = initialMarkets.map((market) => {
          const index = data.markets.findIndex((_market) => _market.id === market.id);
          if (index >= 0) {
            return {
              ...market,
              change: data.markets[index].change,
              increased: data.markets[index].increased,
              last_direction: data.markets[index].last_direction,
              last_price: data.markets[index].last_price,
              volume: data.markets[index].volume,
            };
          }
          return market;
        });
        setMarkets(_markets);
      }
    }, 5000);

    return () => clearInterval(timer);
    // eslint-disable-next-line
  }, []);

  return (
    <Page>
      <Box className={classes.root}>
        <Box>
          <BackButton color={commonClasses.backBtn} page="/funds" />
        </Box>
        <Box textAlign="center" mb={4}>
          <Typography component="h3" className={classes.title}>
            Markets
          </Typography>
        </Box>
        <Box className={classes.header_box} display="flex" width="98%" justifyContent="space-between" alignItems="center">
          <Typography className={classes.header}>Pair / Volume</Typography>
          <Typography className={classes.header}>Last Price</Typography>
          <Typography className={classes.header}>24h Change</Typography>
        </Box>
        {!isLoading ? (
          <>
            {
              markets.map((market) => (
                <Market
                  item={market}
                  key={market.id}
                  onClick={() => handleMarket(market)}
                />
              ))
            }
          </>
        ) : (<Loading />)}
      </Box>
    </Page>
  );
};

Markets.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(Markets);
