import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import SelectCountry from 'components/SelectCountry';

import { setProfileCountryAction } from 'store/actions/account';
import commonStyle from 'styles/common';

const ProfileCountry = ({
  profileCountry,
  setProfileCountryInfo,
}) => {
  const commonClasses = commonStyle();
  const history = useHistory();
  const handleSelectCountry = (country) => {
    if (country.id !== profileCountry.id) {
      setProfileCountryInfo(country);
    }
    history.push('/more/setting/profile');
  };

  return (
    <Page>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <BackButton color={commonClasses.backBtn} page="/more/setting/profile" />
      </Box>
      <SelectCountry
        handleSelect={handleSelectCountry}
        countryId={profileCountry.id}
      />
    </Page>
  );
};

ProfileCountry.defaultProps = {
  profileCountry: { id: 0 },
};

ProfileCountry.propTypes = {
  profileCountry: PropTypes.object,
  setProfileCountryInfo: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  profileCountry: state.account.profile?.country,
});

const mapDispatchToProps = (dispatch) => ({
  setProfileCountryInfo: (data) => dispatch(setProfileCountryAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileCountry);
