// import axios from 'axios';
// import config from 'utils/config';
import httpService from './http.service';

// const primeTrustHttp = axios.create({ baseURL: `${config.primeTrustUrl}/` });

const getCountries = (params) => httpService
  .get('/primetrust/countries', {}, params)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const getOverview = (params) => httpService
  .get('/primetrust/overview', {}, params)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const getResourceToken = (params) => httpService
  .get('/primetrust/get_resource_token_for_new_contact', {}, params)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const getCardResourceToken = (params) => httpService
  .get('/primetrust/get_resource_token_for_new_card', {}, params)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const getWireDepositInfo = (body) => httpService
  .get('/primetrust/get_wire_transfer_reference', body)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const createAccount = (body) => httpService
  .post('/primetrust/create_account', body)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const updateAccount = (body) => httpService
  .patch('/primetrust/patch_contact', body)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const addBankAccount = (body) => httpService
  .post('/primetrust/add_bank_account', body)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const uploadDoc = (body) => httpService.post(
  '/primetrust/upload_document', body,
  {
    // params: { 'allow-download': false, hash: token },
    // headers: { 'Content-type': 'multipart/form-data' },
    'Content-type': 'multipart/form-data',
  },
).then(({ data }) => data).catch((err) => Promise.reject(err?.response?.data));

const deleteFundsMethod = (body) => httpService
  .post('/primetrust/deactivate_funds_transfer_method', body)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const updateAccountCreated = (body) => httpService
  .post('/primetrust/account_created', body)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const createCreditCardDeposit = (body) => httpService
  .post('/primetrust/deposit', body)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

export default {
  getCountries,
  getOverview,
  getResourceToken,
  getCardResourceToken,
  getWireDepositInfo,
  uploadDoc,
  createAccount,
  updateAccount,
  updateAccountCreated,
  addBankAccount,
  deleteFundsMethod,
  createCreditCardDeposit,
};
