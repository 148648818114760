import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { makeStyles } from '@material-ui/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles(({ palette }) => ({
  dialogTitle: {
    background: palette.cardColor,
    color: palette.secondaryColor,
    paddingBottom: 0,
    '& h2': {
      fontWeight: 'bold',
      fontSize: 20,
    },
  },
  dialogActions: {
    background: palette.cardColor,
  },
  dialogContent: {
    background: palette.cardColor,
    '& p': {
      color: palette.secondaryColor,
      lineHeight: 1.2,
    },
  },
  dialogButton: {
    background: 'none',
    boxShadow: 'none',
    '& span': {
      color: '#0091FF',
      fontWeight: 'bold',
    },
  },
  icon: {
    color: '#00D3DA',
    width: 15,
    height: 15,
    marginLeft: 10,
  },
}));

export default function WithdrawFundsDialog() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <InfoOutlinedIcon className={classes.icon} onClick={handleClickOpen} />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>Withdraw Options</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText id="alert-dialog-description">
            ZenSports gives you a few different options for withdrawing funds from your ZenSports account. Withdrawing
            by SPORTS utility tokens is the easiest way -- you&apos;ll receive your tokens within 12 business hours.
            Withdrawing via other cryptocurrencies will be processed within 24 business hours, excluding weekends
            and U.S. holidays.
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={handleClose} className={classes.dialogButton}>
            GOT IT
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
