import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { Box, InputBase, TextField } from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => ({
  field: {
    '& .MuiInputLabel-root': {
      color: palette.secondaryColor,
      font: '22px SofiaPro-Bold',
    },
  },
  input: {
    color: palette.colorBtnTxt,
    font: '25px SofiaPro-SemiBold',
    '& input::placeholder': {
      color: palette.placeholderColor,
    },
    '&:before': {
      borderBottom: `1px solid ${palette.secondaryColor} !important`,
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: `1px solid ${palette.secondaryColor} !important`,
    },
  },
  border: {
    border: '1px solid #AEAEAE',
    borderRadius: '5px',
  },
  borderInput: {
    color: palette.colorBtnTxt,
    font: '22px SofiaPro-SemiBold',
    letterSpacing: -0.2,
    padding: '10px 0px',
    '& input': {
      textAlign: 'center',
    },
    '& input::placeholder': {
      color: palette.placeholderColor,
    },
  },

}));

const InputBox = (props) => {
  const {
    name, label, onBlur, onChange, onClick, value, errors, touched, onFocus, type, format, isWritable,
  } = props;
  const classes = useStyles();

  return (
    <>
      {
      format === 'default' ? (
        <Box className={classes.field}>
          <TextField
            id={name}
            label={label}
            type={type}
            autoComplete="off"
            className={classes.input}
            placeholder={label}
            onChange={onChange}
            onBlur={onBlur}
            onClick={onClick}
            onFocus={onFocus}
            value={value}
            fullWidth
            InputLabelProps={{ shrink: true }}
            InputProps={{ className: classes.input }}
            name={name}
            helperText={(errors[name] && touched[name]) && errors[name]}
            error={errors[name] && touched[name]}
          />
        </Box>
      ) : (
        <Box className={classes.border}>
          <InputBase
            autoComplete="off"
            onClick={onClick}
            className={classes.borderInput}
            placeholder={label}
            name={name}
            type={type}
            inputProps={{ 'aria-label': `${label}` }}
            fullWidth
            readOnly={!isWritable}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            helpertext={(errors[name] && touched[name]) && errors[name]}
            error={errors[name] && touched[name]}
          />
        </Box>
      )
    }
    </>
  );
};

InputBox.defaultProps = {
  label: '',
  name: '',
  isWritable: false,
  type: 'text',
  format: 'default',
  onBlur: () => {},
  onChange: () => {},
  onClick: () => {},
  onFocus: () => {},
  value: '',
  errors: {},
  touched: {},
};

InputBox.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  format: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.string || PropTypes.number,
  errors: PropTypes.object,
  touched: PropTypes.object,
  isWritable: PropTypes.bool,
};

export default InputBox;
