import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';

import {
  setPlayFilterAction,
  getPlayListAction,
  getSportListAction,
  createTournamentAction,
  setTournamentQueryAction,
  getTournamentUsersAction,
  getTournamentDetailsAction,
  currentUserInTournamentAction,
  getUpcomingMatchesAction,
  getInProgressMatchesAction,
  getCompletedMatchesAction,
  getPrizeMoneyPlayerAction,
  getPrizeMoneyDirectorAction,
  setMatchWinnerAction,
  closeTournamentAction,
  deleteTournamentAction,
  registerForTournamentAction,
  updateTwitchAction,
  updateTournamentAction,
  getTwitchUrlAction,
  getTournamentMatchDetailsAction,
  setTournamentMatchWinnerAction,
  getBracketsDetailsAction,
} from 'store/actions/play';

export const usePlayIsLoading = () => useSelector(({ play: { isLoading } }) => isLoading);

export const usePlaySelectedQuery = () => useSelector(({ play: { selected } }) => selected);

export const useGetTournamentQuery = () => useSelector(({ play: { tournamentQuery } }) => tournamentQuery);

export const usePlayList = (query, options) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (options) {
      dispatch(getPlayListAction(query, options));
    }
    // eslint-disable-next-line
  }, [query, options]);
  return useSelector(({ play: { list } }) => list);
};

export const useSportList = (options) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (options) {
      dispatch(getSportListAction(options));
    }
    // eslint-disable-next-line
  }, []);
  return useSelector(({ play: { sportsList } }) => sportsList);
};

export const useGetTournamentDetail = (id) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (id) {
      dispatch(getTournamentDetailsAction(id));
    }
    // eslint-disable-next-line
  }, [id]);
  return useSelector(({ play: { tournamentDetails } }) => tournamentDetails);
};

export const useGetTournamentUsers = (id) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (id) {
      dispatch(getTournamentUsersAction(id));
    }
    // eslint-disable-next-line
  }, [id]);
  return useSelector(({ play: { tournamentUsers } }) => tournamentUsers);
};

export const useCurrentUserInTournament = (id) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (id) {
      dispatch(currentUserInTournamentAction(id));
    }
    // eslint-disable-next-line
  }, [id]);
  return useSelector(({ play: { currentUserInTournamentDetails } }) => currentUserInTournamentDetails);
};

export const useGetTwitchUrl = (id) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (id) {
      dispatch(getTwitchUrlAction(id));
    }
    // eslint-disable-next-line
  }, [id]);
  return useSelector(({ play: { twitchChannelsDetails } }) => twitchChannelsDetails);
};

export const useUpcomingMatches = (id) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (id) {
      dispatch(getUpcomingMatchesAction(id));
    }
    // eslint-disable-next-line
  }, [id]);
  return useSelector(({ play: { upcomingMatchesDetails } }) => upcomingMatchesDetails);
};

export const useInProgressMatches = (id) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (id) {
      dispatch(getInProgressMatchesAction(id));
    }
    // eslint-disable-next-line
  }, [id]);
  return useSelector(({ play: { inProgressMatchesDetails } }) => inProgressMatchesDetails);
};

export const useCompletedMatches = (id) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (id) {
      dispatch(getCompletedMatchesAction(id));
    }
    // eslint-disable-next-line
  }, [id]);
  return useSelector(({ play: { completedMatchesDetails } }) => completedMatchesDetails);
};

export const useGetPrizeMoneyPlayer = (id) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (id) {
      dispatch(getPrizeMoneyPlayerAction(id));
    }
    // eslint-disable-next-line
  }, [id]);
  return useSelector(({ play: { prizeMoneyPlayerDetails } }) => prizeMoneyPlayerDetails);
};

export const useGetPrizeMoneyDirector = (id) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (id) {
      dispatch(getPrizeMoneyDirectorAction(id));
    }
    // eslint-disable-next-line
  }, [id]);
  return useSelector(({ play: { prizeMoneyDirectorDetails } }) => prizeMoneyDirectorDetails);
};

export const useTournamentMatchDetails = (id) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (id) {
      dispatch(getTournamentMatchDetailsAction(id));
    }
    // eslint-disable-next-line
  }, [id]);
  return useSelector(({ play: { tournamentMatchDetails } }) => tournamentMatchDetails);
};

export const useGetBracketsDetails = (id) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (id) {
      dispatch(getBracketsDetailsAction(id));
    }
    // eslint-disable-next-line
  }, [id]);
  return useSelector(({ play: { bracketsDetails } }) => bracketsDetails);
};

export const useGetTournamentDetailsDispatch = () => {
  const dispatch = useDispatch();
  return (id) => dispatch(getTournamentDetailsAction(id));
};

export const useSetPlayFilterDispatch = () => {
  const dispatch = useDispatch();
  return (data) => dispatch(setPlayFilterAction(data));
};

export const useSetTournamentQueryDispatch = () => {
  const dispatch = useDispatch();
  return (data) => dispatch(setTournamentQueryAction(data));
};

export const useCreateTournamentDispatch = () => {
  const dispatch = useDispatch();
  return (body) => dispatch(createTournamentAction(body));
};

export const useSetMatchWinnerDispatch = () => {
  const dispatch = useDispatch();
  return (id, body) => dispatch(setMatchWinnerAction(id, body));
};

export const useCloseTournamentDispatch = () => {
  const dispatch = useDispatch();
  return (id) => dispatch(closeTournamentAction(id));
};

export const useDeleteTournamentDispatch = () => {
  const dispatch = useDispatch();
  return (id) => dispatch(deleteTournamentAction(id));
};

export const useRegisterForTournamentDispatch = () => {
  const dispatch = useDispatch();
  return (id, body) => dispatch(registerForTournamentAction(id, body));
};

export const useUpdateTwitchDispatch = () => {
  const dispatch = useDispatch();
  return (id, body) => dispatch(updateTwitchAction(id, body));
};

export const useUpdateTournamentDispatch = () => {
  const dispatch = useDispatch();
  return (id, body) => dispatch(updateTournamentAction(id, body));
};

export const useSetTournamentMatchWinnerDispatch = () => {
  const dispatch = useDispatch();
  return (id, body) => dispatch(setTournamentMatchWinnerAction(id, body));
};
