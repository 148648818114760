import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { debounce } from 'lodash';

import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/styles';
import TuneIcon from '@material-ui/icons/Tune';

import Page from 'components/Page/Dashboard';
import Loading from 'components/Loading/Box';
import VList from 'components/VList';
import BackButton from 'components/Buttons/BackButton';
import SearchInput from 'components/Form/SearchInput';
import BetResultInfoCard from 'components/Bet/BetResultInfoCard';

import { getBetResultInfo } from 'utils/betting';
import { getFilterQuery } from 'utils/betting.sort';
import { useBet, useBetResultList, useBetSelected } from 'hooks/redux/bet';
import { useMappedCurrencies } from 'hooks/redux/currency';
import { useUserOddType, useAuthUser } from 'hooks/redux/user';
import commonStyle from 'styles/common';

const ColorButton = withStyles(({ palette }) => ({
  root: {
    background: palette.betBtn,
    boxShadow: 'none',
    minHeight: 40,
    font: '16px SofiaPro-SemiBold',
    letterSpacing: '0.3px',
    color: 'white',
    padding: '0 30px',
    '&:hover': {
      background: '#14133D',
    },
  },
}))(Button);

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 8px',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  filterBtn: {
    transform: 'rotate(-90deg)',
    color: palette.secondaryColor,
    fontSize: '2rem',
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
  },
  contentTitle: {
    color: palette.secondaryColor,
    textAlign: 'center',
  },
}));

const ResultList = ({ history }) => {
  const classes = useStyles();
  const commonClasses = commonStyle();
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState();
  const [debounceSearch, setDebounceSearch] = useState('');

  const betting = useBet();
  const bets = useBetResultList(debounceSearch, options);
  const user = useAuthUser();
  const filter = useBetSelected();
  const oddType = useUserOddType();
  const mappedCurrencies = useMappedCurrencies();

  // eslint-disable-next-line
  const doDebounceSearch = useCallback(
    debounce((value) => setDebounceSearch(value), 500), [debounce, setDebounceSearch],
  );

  const handleSearch = (e) => {
    setSearch(e.target.value);
    doDebounceSearch(e.target.value);
  };

  const handleFilter = () => {
    history.push('/bets/results/filter');
  };

  const handleSelectCard = useCallback((betId) => {
    history.push(`/bets/view/${betId}`);
  }, [history]);

  const handleCreateBet = () => {
    history.push('/bets/create/select-league');
  };

  useEffect(() => {
    if (filter && user.id && mappedCurrencies) {
      filter.user = user;
      setOptions({
        hitsPerPage: 1000,
        page: 0,
        filters: getFilterQuery(filter, mappedCurrencies, 'result'),
        filter,
      });
    }
    // eslint-disable-next-line
  }, [filter]);

  return (
    <Page>
      <Box className={classes.root}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <BackButton color={commonClasses.backBtn} />
          <SearchInput
            handleChange={handleSearch}
            placeholder="Search a team or player"
            value={search}
          />
          <TuneIcon className={classes.filterBtn} onClick={() => handleFilter()} />
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center" mt={3} mb={1}>
          <Typography variant="h3" className={classes.title}>
            My Bets
          </Typography>
        </Box>
        <Box className={commonClasses.vListWrapper}>
          {!betting.isLoading ? (bets.length > 0 ? (
            <VList
              rowCount={bets.length}
              rowRender={(index) => {
                if (bets[index].type !== 'parlay') {
                  const betInfo = getBetResultInfo(bets[index], user, oddType);
                  return (
                    <Box mb={3}>
                      <BetResultInfoCard
                        showAcceptType
                        betInfo={betInfo}
                        onClick={() => handleSelectCard(betInfo.id)}
                      />
                    </Box>
                  );
                }
              }}
            />
          ) : (
            <>
              <Typography variant="subtitle2" className={classes.contentTitle}>
                It doesn’t look like there are any bets available right now that fit the criteria you selected.
                But good news - ZenSports lets you create YOUR OWN bets for anything you want. Tap the “Create Bet”
                button below to get started!
              </Typography>
              <Box textAlign="center" mt={4}>
                <ColorButton onClick={handleCreateBet}>
                  Create Bet
                </ColorButton>
              </Box>
            </>
          )) : (<Loading />)}
        </Box>
      </Box>
    </Page>
  );
};

ResultList.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(ResultList);
