import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

const useStyles = makeStyles({
  root: (props) => ({
    backgroundImage: props.selected ? 'linear-gradient(45deg, #298EDA, #29D2D3)' : 'none',
    backgroundColor: props.selected ? '' : 'white',
    borderRadius: '4px',
    width: '99px',
    minHeight: '97px',
    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.15)',
    textAlign: 'center',
  }),
  name: (props) => ({
    font: '14px SofiaPro-SemiBold',
    color: props.selected ? 'white' : 'black',
    marginBottom: '7px',
  }),
});

const Currency = ({
  name,
  image,
  selected,
  onClick,
}) => {
  const classes = useStyles({
    selected,
  });
  return (
    <Box
      className={classes.root}
      onClick={onClick}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      mt={2}
    >
      <Box flex={1} alignItems="center" justifyContent="center" display="flex">
        <img src={image} alt="icon" />
      </Box>
      <Typography variant="subtitle1" className={classes.name}>
        {name}
      </Typography>
    </Box>
  );
};

Currency.defaultProps = {
  name: null,
  image: null,
  selected: false,
  onClick: () => {},
};

Currency.propTypes = {
  name: PropTypes.string,
  image: PropTypes.string,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Currency;
