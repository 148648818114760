import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import config from 'utils/config';

import { getUserAction, loginAction } from 'store/actions/auth';
import { useOpenErrorModalDispatch } from 'hooks/redux/modal';

import {
  Box,
  Fab,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/styles';
import Circle from '@material-ui/icons/PanoramaFishEye';
import CheckCircle from '@material-ui/icons/CheckCircle';

import Page from 'components/Page/Auth';
import BackButton from 'components/Buttons/BackButton';
import SportsGroupImage from 'assets/images/sports-group.png';

import { getErrorMessage } from 'utils';

const PrimaryCheckbox = withStyles({
  root: {
    color: '#63C4C6',
    '&$checked': {
      color: '#63C4C6',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles({
  input: {
    '& input::placeholder': {
      opacity: '1 !important',
    },
  },
  checkBox: {
    color: 'white',
    '& span': {
      fontSize: '13px',
    },
  },
  fab: {
    margin: '0 auto 15px',
    display: 'block',
    padding: '13px 108px',
    height: 'unset',
    borderRadius: '5px',
    fontWeight: 'bold',
  },
});

const Login = ({ history, login, getUser }) => {
  const openErrorModal = useOpenErrorModalDispatch();
  const classes = useStyles();
  return (
    <Page backgroundImage={SportsGroupImage}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <BackButton page="/register" />
      </Box>
      <Typography variant="h2" className="text-center color-white">
        Login
      </Typography>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            setSubmitting(true);
            await login(values);
            getUser();
            history.push('/bets');
          } catch (e) {
            const message = getErrorMessage(e);
            let title = 'Oops';
            let callback = () => {};
            if (message.includes('90 days for security purposes')) {
              title = 'Password Change Required';
              callback = () => {
                window.open(`${config.baseUrl}/reset_password`, '_blank');
                // window.location.href = `${config.baseUrl}/reset_password`;
              };
            }
            openErrorModal({
              title,
              subtitle: message,
              callback,
            });
          }
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email()
            .required('Required'),
          password: Yup.string()
            .required('Required')
            .min(6),
        })}
      >
        {({
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Box mt={3}>
              <TextField
                id="email"
                label="Email"
                placeholder="Email"
                autoComplete="off"
                fullWidth
                mt={3}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  className: classes.input,
                }}
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={(errors.email && touched.email) && errors.email}
                error={errors.email && touched.email}
              />
            </Box>
            <Box mt={3}>
              <TextField
                id="password"
                label="Password"
                placeholder="Password"
                autoComplete="off"
                fullWidth
                mt={3}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  className: classes.input,
                  type: 'password',
                }}
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={(errors.password && touched.password) ? errors.password : 'Must be  at least 6 characters'}
                error={errors.password && touched.password}
              />
            </Box>
            <FormControlLabel
              control={(
                <PrimaryCheckbox
                  icon={<Circle fontSize="small" />}
                  checkedIcon={<CheckCircle fontSize="small" />}
                  name="checkedH"
                />
              )}
              label="Save my Login info for next time"
              className={classes.checkBox}
            />
            <Box mt={3}>
              <Fab type="submit" aria-label="Login" disabled={isSubmitting} variant="extended" className={classes.fab}>
                Login
              </Fab>
              <Typography variant="subtitle2" className="text-center font-small">
                <a href={`${config.baseUrl}/reset_password`} className="color-primary">
                  Forgot Password
                </a>
              </Typography>
            </Box>
          </form>
        )}
      </Formik>
    </Page>
  );
};

Login.propTypes = {
  history: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  login: (data) => dispatch(loginAction(data)),
  getUser: () => dispatch(getUserAction()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
