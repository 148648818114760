import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import ImageSuccessPayment from 'assets/images/funds/success-payment.png';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 15px',
  },
  paper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  subtitle: {
    font: '12px SofiaPro-SemiBold',
    color: '#08B0AA',
  },
  title: {
    marginTop: 0,
    marginBottom: '30px',
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
  },
  result: {
    paddingTop: '20px',
    paddingBottom: '20px',
    font: '18px SofiaPro-Bold',
    color: palette.secondaryColor,
  },
  description: {
    color: '#AEAEAE',
    textAlign: 'center',
    font: '15px SofiaPro',
  },
  button: {
    marginTop: 40,
    color: '#08B0AA',
  },
}));

const Confirmation = () => {
  const classes = useStyles();

  return (
    <Page>
      <Box className={classes.root}>
        <Box>
          <BackButton color="#14133D" />
        </Box>
        <Box className={classes.paper} mt={5}>
          <Typography component="h2" className={classes.subtitle}>DEPOSIT DETECTED</Typography>
          <Typography component="h2" className={classes.title}>Confirming Payment</Typography>
          <img alt="payment" src={ImageSuccessPayment} />
          <Typography className={classes.result}>
            Payment Detected!
          </Typography>
          <Typography className={classes.description}>
            {`Your payment has been detected on the blockchain, and your deposit will be credited too your ZenSports
           account as soon as all required confirmations have been successfully processed.
           You can either leave this screen open to monitor the confirmations in real time,
           or close it and we'll send you an email and in-product notification once your deposit has been fully confirmed.`}
          </Typography>
          <a href="#" className={classes.button}>
            How long will it take?
          </a>
        </Box>
      </Box>
    </Page>
  );
};

Confirmation.propTypes = {};

export default Confirmation;
