import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Avatar from 'components/Avatar';
import { displayTournamentName } from 'utils/play';
import CheckIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme) => ({
  streamBox: {
    background: theme.palette.cardColor,
    borderRadius: '8px',
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 4px',
    marginBottom: 10,
    width: 230,
    paddingBottom: 5,
  },
  playersBox: {
    height: '45px',
    width: '100%',
    borderRadius: '8px 0px 0px 8px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    padding: '6px 20px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  watchBox: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  userNameText: {
    color: theme.palette.secondaryColor,
    fontWeight: 600,
    fontSize: '16px',
    paddingLeft: 10,
  },
  crownImg: {
    position: 'absolute',
    top: '-15px',
    left: '6px',
  },
  winnerIcon: {
    position: 'absolute',
    top: '-5px',
    left: '32px',
    fontSize: '20px',
    color: theme.palette.colorBtnTxt,
  },
}));

const MatchNode = ({
  player1,
  player2,
  history,
  winnerId,
  id,
  matchId,
}) => {
  const classes = useStyles();
  return (
    <Box
      className={classes.streamBox}
      onClick={() => history.push(`/more/play/tournament/view/${id}/match-details/${matchId}`)}
    >
      <Box className={classes.playersBox}>
        <Box className={classes.watchBox}>
          {
            (winnerId === player1.id) && (
            <Box className={classes.winnerIcon}>
              <CheckIcon fontSize="inherit" color="inherit" />
            </Box>
            )
          }
          <Avatar src={player1?.profile_url} size="35px" />
          <Typography className={classes.userNameText}>
            {displayTournamentName(player1)}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.playersBox}>
        <Box className={classes.watchBox}>
          {
            (winnerId === player2.id) && (
            <Box className={classes.winnerIcon}>
              <CheckIcon fontSize="inherit" color="inherit" />
            </Box>
            )
          }
          <Avatar src={player2?.profile_url} size="35px" />
          <Typography className={classes.userNameText}>
            {displayTournamentName(player2)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

MatchNode.defaultProps = {
  player1: {},
  player2: {},
  winnerId: -1,
};

MatchNode.propTypes = {
  player1: PropTypes.object,
  player2: PropTypes.object,
  winnerId: PropTypes.number,
  history: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  matchId: PropTypes.number.isRequired,
};

export default withRouter(MatchNode);
