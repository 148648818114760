import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/styles';
import {
  Box,
  Checkbox,
  Grid,
  InputBase,
  Typography,
} from '@material-ui/core';

import Page from 'components/Page/Dashboard';
import Loading from 'components/Loading/Box';
import BackButton from 'components/Buttons/BackButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import InputDropDown from 'components/Form/InputDropDown';

import {
  // useOpenSuccessModalDispatch,
  useOpenErrorModalDispatch,
} from 'hooks/redux/modal';
import {
  useBankInstructionInfo,
  useAddBankAccountActionDispatch,
  useSetBankInstructionActionDispatch,
} from 'hooks/redux/account';
import { getErrorMessage } from 'utils';
import Circle from '@material-ui/icons/PanoramaFishEye';
import CheckCircle from '@material-ui/icons/CheckCircle';
import SwiftDialog from 'components/Dialog/SwiftDialog';
import IbanDialog from 'components/Dialog/IbanDialog';
import CommonStyle from 'styles/common';

const PrimaryCheckbox = withStyles({
  root: {
    color: '#979797',
    '&.Mui-checked': {
      color: '#63C4C6',
    },
  },
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 15px',
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
  },
  content: {
    textAlign: 'center',
    color: '#AEAEAE',
    font: '15px SofiaPro',
    justifyContent: 'center',
    alignItems: 'center',
  },
  helperText: {
    color: '#AEAEAE',
    font: '13px SofiaPro',
    display: 'block',
    margin: '5px 0 0 5px',
    '& span': {
      display: 'block',
      float: 'left',
    },
    '& span:nth-child(1)': {
      margin: '5px 0 0 5px',
    },
    '& span:nth-child(2) svg': {
      marginLeft: '5px',
    },
  },
  helperIcon: {
    marginTop: '2px',
  },
  addressCard: {
    boxShadow: '0 3px 10px 1px rgba(0,0,0,0.15)',
    borderRadius: '6px',
  },
  addressInput: {
    color: palette.secondaryColor,
    background: palette.cardColor,
    font: '15px SofiaPro-Bold',
    letterSpacing: -0.2,
    padding: '10px 0px',
    '& input': {
      textAlign: 'center',
    },
  },
  address: ({ collapsed }) => ({
    borderRadius: !collapsed ? '6px 6px 0 0' : '6px',
    padding: '22px 10px',
    font: '15px SofiaPro-Bold',
    background: !collapsed ? 'linear-gradient(45deg, #298EDA, #29D2D3)' : palette.cardColor,
    color: !collapsed ? 'white' : '#949494',
  }),
  info: {
    borderBottom: '1px solid #AEAEAE',
    '&:last-child': {
      borderBottom: 'none !important',
    },
  },
  input: {
    marginTop: 16,
    boxShadow: '0 2px 10px 1px rgba(0,0,0,0.15)',
    background: palette.cardColor,
    color: palette.secondaryColor,
    borderRadius: '6px',
    font: '15px SofiaPro-Bold',
    letterSpacing: -0.2,
    padding: '10px 0px',
    '& input': {
      textAlign: 'center',
    },
  },
  cancel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 10,
    font: '17px SofiaPro-SemiBold',
    letterSpacing: '0.3px',
    color: '#29CDD4',
  },
  trustLink: {
    color: palette.secondaryColor,
    font: '16px SofiaPro-SemiBold',
  },
}));

const Instructions = ({ history }) => {
  const instruction = useBankInstructionInfo();

  const [collapsed, setCollapsed] = useState(true);
  const classes = useStyles({ collapsed });
  const commonClasses = CommonStyle();
  const [accountName, setAccountName] = useState(instruction.account_name);
  const [bankName, setBankName] = useState(instruction.bank_name);
  const [routingNumber, setRoutingNumber] = useState(instruction.routing_number);
  const [swiftCode, setSwiftCode] = useState(instruction.swift_code);
  const [accountNumber, setAccountNumber] = useState(instruction.account_number);
  const [street1, setStreet1] = useState(instruction.street_1);
  const [street2, setStreet2] = useState(instruction.street_2);
  const [city, setCity] = useState(instruction.city);
  const [region, setRegion] = useState(instruction.region.name);
  const [postalCode, setPostalCode] = useState(instruction.postal_code);
  const [isFurtherAcc, setIsFurtherAcc] = useState(instruction.isFurtherAcc);
  const [furtherAccountName, setFurtherAccountName] = useState(instruction.further_account_name);
  const [furtherAccountNumber, setFurtherAccountNumber] = useState(instruction.further_account_number);
  const [iban, setIban] = useState(instruction.iban);
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const openErrorModal = useOpenErrorModalDispatch();
  // const openSuccessModal = useOpenSuccessModalDispatch();
  const addWireInstruction = useAddBankAccountActionDispatch();
  const setBankInstructionInfo = useSetBankInstructionActionDispatch();

  const getBankInfo = (method) => {
    const data = {
      funds_transfer_country: method === 'submit' ? instruction.funds_transfer_country.country_code.toLocaleLowerCase()
        : instruction.funds_transfer_country,
      account_name: accountName,
      bank_name: bankName,
      account_number: accountNumber,
      street_1: street1,
      street_2: street2,
      city,
      postal_code: postalCode,
      country: method === 'submit' ? instruction.country.country_code : instruction.country,
    };
    if (isFurtherAcc) {
      data.further_account_name = furtherAccountName;
      data.further_account_number = furtherAccountNumber;
    }
    if (instruction.funds_transfer_country.country_code === 'US') {
      data.routing_number = routingNumber;
    } else {
      data.swift_code = swiftCode;
      data.iban = iban;
    }
    if (instruction.country.country_code === 'US') {
      data.region = method === 'submit' ? instruction.region.abbreviation.toLocaleLowerCase() : instruction.region;
    } else {
      data.region = method === 'submit' ? region : { name: region, abbreviation: '', id: 0 };
    }
    return data;
  };

  const handleSelectState = () => {
    const data = getBankInfo('set');
    setBankInstructionInfo(data);
    if (instruction.country.country_code === 'US') {
      history.push('/funds/withdraw/wire-transfer/instructions/state');
    } else {
      // openErrorModal({ subtitle: 'In order to select state, country must be United States of America.' });
    }
  };
  const handleSelectCountry = (type) => {
    const data = getBankInfo('set');
    setBankInstructionInfo(data);
    if (type === 'bank') {
      history.push('/funds/withdraw/wire-transfer/instructions/bank-country');
    } else {
      history.push('/funds/withdraw/wire-transfer/instructions/country');
    }
  };

  const handleSubmit = async () => {
    setDisabledSubmit(true);
    try {
      const data = getBankInfo('submit');
      await addWireInstruction(data);
      setDisabledSubmit(false);
      history.goBack();
    } catch (e) {
      openErrorModal({
        title: 'Submit Error',
        subtitle: getErrorMessage(e),
        buttonText: 'Got it',
      });
      setDisabledSubmit(false);
    }
  };

  return (
    <Page>
      <Box className={classes.root} display="flex" flexDirection="column" flex={1}>
        <Box>
          <BackButton color={commonClasses.backBtn} />
        </Box>
        <Box display="flex" flexDirection="column" flex={1} justifyContent="space-between" mt={3}>
          <Box textAlign="center" mb={4}>
            <Typography component="h3" className={classes.title}>
              Add Wire Instructions
            </Typography>
            <span className={classes.content}>
              Add wire instructions to withdraw funds to.
            </span>
            <InputDropDown
              value={instruction.funds_transfer_country}
              placeholder="Country where bank is located"
              onClick={() => handleSelectCountry('bank')}
            />
            {
              instruction.funds_transfer_country?.id !== -1 && (
                <>
                  <InputBase
                    autoComplete="off"
                    className={classes.input}
                    placeholder="Bank Name"
                    inputProps={{ 'aria-label': 'Bank Name' }}
                    onChange={(e) => setBankName(e.target.value)}
                    value={bankName}
                    fullWidth
                  />
                </>
              )
            }
            {
              instruction.funds_transfer_country.country_code === 'US' && (
                <>
                  <InputBase
                    autoComplete="off"
                    className={classes.input}
                    placeholder="Routing Number"
                    inputProps={{ 'aria-label': 'Routing Number' }}
                    onChange={(e) => setRoutingNumber(e.target.value)}
                    value={routingNumber}
                    fullWidth
                  />
                </>
              )
            }
            {
              instruction.funds_transfer_country?.id !== -1 && (
                <>
                  <InputBase
                    autoComplete="off"
                    className={classes.input}
                    placeholder="Bank Account Number"
                    inputProps={{ 'aria-label': 'Bank Account Number' }}
                    onChange={(e) => setAccountNumber(e.target.value)}
                    value={accountNumber}
                    fullWidth
                  />
                </>
              )
            }
            {
              (instruction.funds_transfer_country?.id !== -1 && instruction.funds_transfer_country.country_code !== 'US') && (
                <>
                  <InputBase
                    autoComplete="off"
                    className={classes.input}
                    placeholder="SWIFT Code"
                    inputProps={{ 'aria-label': 'SWIFT Code' }}
                    onChange={(e) => setSwiftCode(e.target.value)}
                    value={swiftCode}
                    fullWidth
                  />
                  <div className={classes.helperText}>
                    <span>What is a SWIFT Code?</span>
                    <span className={classes.helperIcon}>
                      <SwiftDialog />
                    </span>
                  </div>
                </>
              )
            }
            {
              instruction.funds_transfer_country?.id !== -1 && (
                <>
                  <InputBase
                    autoComplete="off"
                    className={classes.input}
                    placeholder="Beneficiary Name"
                    inputProps={{ 'aria-label': 'Beneficiary Name' }}
                    onChange={(e) => setAccountName(e.target.value)}
                    value={accountName}
                    fullWidth
                  />
                  <Box className={classes.addressCard} mt={2}>
                    <Box
                      onClick={() => setCollapsed(!collapsed)}
                      className={classes.address}
                      display="flex"
                      justifyContent="center"
                    >
                      Beneficiary Address
                    </Box>
                    {
                      !collapsed && (
                        <>
                          <Box className={classes.info}>
                            <InputBase
                              autoComplete="off"
                              name="street1"
                              className={classes.addressInput}
                              placeholder="Address Line 1"
                              inputProps={{ 'aria-label': 'Address Line 1' }}
                              fullWidth
                              onChange={(e) => setStreet1(e.target.value)}
                              value={street1}
                            />
                          </Box>
                          <Box className={classes.info}>
                            <InputBase
                              autoComplete="off"
                              name="address2"
                              className={classes.addressInput}
                              placeholder="Address Line 2"
                              inputProps={{ 'aria-label': 'Address Line 2' }}
                              fullWidth
                              onChange={(e) => setStreet2(e.target.value)}
                              value={street2}
                            />
                          </Box>
                          <Box className={classes.info}>
                            <InputBase
                              autoComplete="off"
                              name="city"
                              className={classes.addressInput}
                              placeholder="City"
                              inputProps={{ 'aria-label': 'City' }}
                              fullWidth
                              onChange={(e) => setCity(e.target.value)}
                              value={city}
                            />
                          </Box>
                          <Box className={classes.info}>
                            <InputBase
                              autoComplete="off"
                              name="stateCode"
                              onClick={handleSelectState}
                              className={classes.addressInput}
                              placeholder="State / Region"
                              inputProps={{ 'aria-label': 'State / Region' }}
                              fullWidth
                              value={region}
                              onChange={(e) => setRegion(e.target.value)}
                            />
                          </Box>
                          <Box className={classes.info}>
                            <InputBase
                              autoComplete="off"
                              name="postalCode"
                              className={classes.addressInput}
                              placeholder="Postal Code"
                              inputProps={{ 'aria-label': 'Postal Code' }}
                              fullWidth
                              onChange={(e) => setPostalCode(e.target.value)}
                              value={postalCode}
                            />
                          </Box>
                          <Box className={classes.info}>
                            <InputBase
                              autoComplete="off"
                              onClick={() => handleSelectCountry('beneficiary')}
                              className={classes.addressInput}
                              placeholder="Country"
                              name="country"
                              inputProps={{ 'aria-label': 'Country' }}
                              fullWidth
                              readOnly
                              value={instruction.country.name}
                            />
                          </Box>
                        </>
                      )
                    }
                  </Box>
                </>
              )
            }
            {
              instruction.funds_transfer_country.country_code === 'US' && (
                <>
                  <Box display="flex" alignItems="center" mt={2}>
                    <PrimaryCheckbox
                      icon={<Circle />}
                      checkedIcon={<CheckCircle />}
                      checked={isFurtherAcc}
                      onChange={(e) => setIsFurtherAcc(e.target.checked)}
                      name="checkedH"
                      size="medium"
                    />
                    <Box className={classes.trustLink}>
                      Use Further Credit Account
                    </Box>
                  </Box>
                  {
                    isFurtherAcc && (
                      <>
                        <InputBase
                          autoComplete="off"
                          className={classes.input}
                          placeholder="Further Credit Account Name"
                          inputProps={{ 'aria-label': 'Further Credit Account Name' }}
                          onChange={(e) => setFurtherAccountName(e.target.value)}
                          value={furtherAccountName}
                          fullWidth
                        />
                        <InputBase
                          autoComplete="off"
                          className={classes.input}
                          placeholder="Further Credit Account Number"
                          inputProps={{ 'aria-label': 'Further Credit Account Number' }}
                          onChange={(e) => setFurtherAccountNumber(e.target.value)}
                          value={furtherAccountNumber}
                          fullWidth
                        />
                      </>

                    )
                  }
                </>
              )
            }
            {
              (instruction.funds_transfer_country?.id !== -1 && instruction.funds_transfer_country.country_code !== 'US') && (
                <>
                  <InputBase
                    autoComplete="off"
                    className={classes.input}
                    placeholder="IBAN (Optional)"
                    inputProps={{ 'aria-label': 'IBAN (Optional)' }}
                    onChange={(e) => setIban(e.target.value)}
                    value={iban}
                    fullWidth
                  />
                  <div className={classes.helperText}>
                    <span>What is IBAN?</span>
                    <span className={classes.helperIcon}>
                      <IbanDialog />
                    </span>
                    <br />
                  </div>
                </>
              )
            }
            {
              instruction.funds_transfer_country?.id !== -1 && (
                <Box display="flex" mt={3}>
                  <Grid onClick={() => history.push('')} item xs={6} sm={6} className={classes.cancel}>
                    <Typography className="text-center">
                      Cancel
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <OutlineButton disabled={disabledSubmit} onClick={handleSubmit}>
                      Submit
                    </OutlineButton>
                  </Grid>
                </Box>
              )
            }
          </Box>
        </Box>
      </Box>
      {
        disabledSubmit && (
          <Loading />
        )
      }
    </Page>
  );
};

Instructions.propTypes = {
  history: PropTypes.object.isRequired,
};

const mapStateToProps = () => ({});

export default withRouter(connect(mapStateToProps, null)(Instructions));
