import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Box, Fab, Typography } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import Page from 'components/Page/Auth';
import BackButton from 'components/Buttons/BackButton';
import SoccerStadiumImage from 'assets/images/soccer-stadium.png';

const Welcome = ({ history }) => {
  const handleNext = () => {
    history.push('/country');
  };

  return (
    <Page backgroundImage={SoccerStadiumImage}>
      <Box>
        <BackButton />
      </Box>
      <Box className="text-center">
        <span role="img" aria-label="emoji" style={{ fontSize: 30 }}>👋</span>
      </Box>
      <Typography variant="h1" className="text-center color-white">
        Welcome to ZenSports!
      </Typography>
      <Box mt={2}>
        <Typography variant="subtitle1" className="text-center color-white">
          ZenSports is a mobile, peer-to-peer sports betting marketplace where anyone can create and accept sports
          bets with anyone else in the world, without the need for a centralized bookmaker.
          ZenSports also offers Daily Fantasy Sports contests.
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography variant="subtitle1" className="text-center color-white">
          You can quickly and easily fund your account right from your phone. Create your OWN bets or
          Daily Fantasy Sports contests with your own odds and terms, and others in the marketplace will
          accept those bets and contests. Or feel free to accept others&apos; bets and contests.
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography variant="subtitle1" className="text-center color-white">
          The ability to create/accept bets and contests are only available in certain jurisdictions.
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography variant="subtitle1" className="text-center color-white">
          We&apos;re going to ask you some questions on the next few screens to see which features
          you&apos;re eligible to receive. Thanks again, and welcome to ZenSports!
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        mt={4}
      >
        <Fab onClick={handleNext} aria-label="Next">
          <ArrowForwardIcon />
        </Fab>
      </Box>
    </Page>
  );
};

Welcome.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(Welcome);
