import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import SelectState from 'components/SelectState';

import { setKycDocInfoAction } from 'store/actions/kyc';

const useStyles = makeStyles(() => ({
}));

const KycDocState = ({
  docCountry,
  docState,
  setKycDocInfo,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const handleSelectState = (state) => {
    if (state.id !== docState.id) {
      setKycDocInfo({ state });
    }
    history.push('/kyc/documentation/state');
  };
  return (
    <Page>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <BackButton color={classes.backBtn} page="/kyc/documentation/state" />
      </Box>
      <SelectState
        handleSelect={handleSelectState}
        country={docCountry}
        state={docState}
      />
    </Page>
  );
};

KycDocState.defaultProps = {
  docState: { id: 0 },
  docCountry: { id: 0 },
};

KycDocState.propTypes = {
  docState: PropTypes.object,
  docCountry: PropTypes.object,
  setKycDocInfo: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  docCountry: state.kyc.documentation.country,
  docState: state.kyc.documentation.state,
});

const mapDispatchToProps = (dispatch) => ({
  setKycDocInfo: (data) => dispatch(setKycDocInfoAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(KycDocState);
