import { uniq } from 'lodash';
import { isBetAcceptedBy } from './betting';
import { convertCurrencyAmount } from './currency';

export const sortByBetObjectName = (items) => items.sort(
  (a, b) => {
    if (a.betting_object.name?.toLowerCase() > b.betting_object.name?.toLowerCase()) {
      return 1;
    }
    if (a.betting_object.name?.toLowerCase() < b.betting_object.name?.toLowerCase()) {
      return -1;
    }
    return 0;
  },
);

export const sortByBeginningDate = (items) => items.sort(
  (a, b) => {
    const aDate = a.intervals[0]?.beginning_date_time ? new Date(a.intervals[0]?.beginning_date_time) : new Date();
    const bDate = b.intervals[0]?.beginning_date_time ? new Date(b.intervals[0]?.beginning_date_time) : new Date();
    return aDate - bDate;
  },
);

export const sortByCurrentHistory = (items) => items.sort(
  (a, b) => {
    const aDate = a.current_history ? new Date(a.current_history) : new Date();
    const bDate = b.current_history ? new Date(b.current_history) : new Date();
    return aDate - bDate;
  },
);

export const sortReverseByCurrentHistory = (items) => items.sort(
  (a, b) => {
    const aDate = a.current_history ? new Date(a.current_history) : new Date();
    const bDate = b.current_history ? new Date(b.current_history) : new Date();
    return bDate - aDate;
  },
);

export const sortReverseByBeginningDate = (items) => items.sort(
  (a, b) => {
    const aDate = a.intervals[0]?.beginning_date_time ? new Date(a.intervals[0]?.beginning_date_time) : new Date();
    const bDate = b.intervals[0]?.beginning_date_time ? new Date(b.intervals[0]?.beginning_date_time) : new Date();
    return bDate - aDate;
  },
);

export const sortByBetTypes = (items, filter, groupByObjectType = false) => {
  let allBets = [...items];
  const groupedByAttr = {
    moneyline: [],
    point_spread: [],
    over_under: [],
    will_not: [],
    write_in: [],
  };
  const playerGroupedByAttr = {
    moneyline: [],
    point_spread: [],
    over_under: [],
    will_not: [],
    write_in: [],
  };
  const teamGroupedByAttr = {
    moneyline: [],
    point_spread: [],
    over_under: [],
    will_not: [],
    write_in: [],
  };

  allBets.forEach((item) => {
    const objectType = item.betting_object.type.toLowerCase();
    if (item.bet_type.is_write_in) {
      if (!groupByObjectType) {
        groupedByAttr.write_in.push(item);
      } else {
        if (objectType === 'team') {
          teamGroupedByAttr.write_in.push(item);
        }
        if (objectType === 'player') {
          playerGroupedByAttr.write_in.push(item);
        }
      }
    } else if (!item.attributes.length) {
      if (!groupByObjectType) {
        groupedByAttr.moneyline.push(item);
      } else {
        if (objectType === 'team') {
          teamGroupedByAttr.moneyline.push(item);
        }
        if (objectType === 'player') {
          playerGroupedByAttr.moneyline.push(item);
        }
      }
    } else {
      for (let i = 0; i < item.attributes.length; i += 1) {
        const groupName = item.attributes[i].bet_type_attribute_group?.name?.toLowerCase();
        if (groupName.includes('plus') || groupName.includes('minus')) {
          if (!groupByObjectType) {
            groupedByAttr.point_spread.push(item);
          } else {
            if (objectType === 'team') {
              teamGroupedByAttr.point_spread.push(item);
            }
            if (objectType === 'player') {
              playerGroupedByAttr.point_spread.push(item);
            }
          }
          break;
        }
        if (groupName.includes('over') || groupName.includes('under')) {
          if (!groupByObjectType) {
            groupedByAttr.over_under.push(item);
          } else {
            if (objectType === 'team') {
              teamGroupedByAttr.over_under.push(item);
            }
            if (objectType === 'player') {
              playerGroupedByAttr.over_under.push(item);
            }
          }

          break;
        }
        if (groupName.includes('will') || groupName.includes('won')) {
          if (!groupByObjectType) {
            groupedByAttr.will_not.push(item);
          } else {
            if (objectType === 'team') {
              teamGroupedByAttr.will_not.push(item);
            }
            if (objectType === 'player') {
              playerGroupedByAttr.will_not.push(item);
            }
          }
          break;
        }
      }
    }
  });

  allBets = [];
  if (!groupByObjectType) {
    Object.keys(groupedByAttr).forEach((attribute) => {
      if (filter.betTypes.indexOf(attribute) >= 0) {
        allBets.push(...groupedByAttr[attribute]);
      }
    });
  } else {
    Object.keys(teamGroupedByAttr).forEach((attribute) => {
      if (filter.betTypes.indexOf(attribute) >= 0) {
        allBets.push(...teamGroupedByAttr[attribute]);
      }
    });
    Object.keys(playerGroupedByAttr).forEach((attribute) => {
      if (filter.betTypes.indexOf(attribute) >= 0) {
        allBets.push(...playerGroupedByAttr[attribute]);
      }
    });
  }
  return allBets;
};

export const sortByGameIds = (items, filter, isResult = false) => {
  const allBets = [];
  const gameIds = [];

  items.forEach((item) => {
    if (item.intervals[0]?.game_id) {
      gameIds.push(item.intervals[0]?.game_id);
    }
  });

  uniq(gameIds).forEach((gameId) => {
    let selectedBets = items.filter((item) => item.intervals[0]?.game_id === gameId);
    selectedBets = sortByBetTypes(selectedBets, filter, !isResult);
    allBets.push(...selectedBets);
  });
  return allBets;
};

export const sortBySingleGameDate = (items) => items.sort((a, b) => {
  const aDate = a.intervals[0]?.beginning_date_time;
  const bDate = b.intervals[0]?.beginning_date_time;
  if (aDate && bDate) {
    if (new Date(aDate) === new Date(bDate)) {
      if (a.intervals?.length && b.intervals?.length) {
        if (a.intervals[0]?.game_id > b.intervals[0]?.game_id) {
          return 1;
        }
        if (a.intervals[0]?.game_id < b.intervals[0]?.game_id) {
          return -1;
        }
        return 0;
      }
      return 1;
    }
    return new Date(aDate) - new Date(bDate);
  }
  return 1;
});

export const sortByWinLossTypes = (bets, history, winLossTypes, user) => {
  const winBets = [];
  const lossBets = [];
  const tieBets = [];
  const canceledBets = [];
  if (history && winLossTypes && winLossTypes.length && user) {
    bets.forEach((bet) => {
      winLossTypes.forEach((item) => {
        if (item === 'won') {
          if (bet.user && bet.user.id === user.id) {
            if (bet.result === 'win') {
              winBets.push(bet);
            }
          } else if (isBetAcceptedBy(bet.bets, user)) {
            if (bet.result === 'loss') {
              winBets.push(bet);
            }
          }
        }
        if (item === 'lost') {
          if (bet.user && bet.user.id === user.id) {
            if (bet.result === 'loss') {
              lossBets.push(bet);
            }
          } else if (isBetAcceptedBy(bet.bets, user)) {
            if (bet.result === 'win') {
              lossBets.push(bet);
            }
          }
        }
        if (item === 'tie') {
          if (bet.result === 'tie') {
            tieBets.push(bet);
          }
        }
        if (item === 'suspended') {
          if (bet.result === 'canceled') {
            canceledBets.push(bet);
          }
        }
      });
    });

    return [...winBets, ...lossBets, ...tieBets, ...canceledBets];
  }
  return bets;
};

export const postSort = (bets, options, isResult = false) => {
  let allBets = [...bets];
  const acceptable = options?.filter?.acceptable === 'acceptable';
  const user = options?.filter?.user;
  const winLossTypes = options?.filter?.winLossTypes;
  const history = options?.filter?.isExpired;
  // const isParlayFilter = options?.filter?.betParlayTypes?.includes('parlay');
  if (!isResult) {
    allBets = allBets.filter((bet) => acceptable === !bet.betting_closed);
    if (history) {
      allBets = allBets.filter((bet) => {
        if (bet.is_expired || (!bet.is_expired && bet.status === 'result_set') || (!bet.is_expired && bet.status === 'settled')) {
          return true;
        }
        return false;
      });
    } else {
      allBets = allBets.filter((bet) => {
        if (!bet.is_expired && !(!bet.is_expired && bet.status === 'result_set') && !(!bet.is_expired && bet.status === 'settled')) {
          return true;
        }
        return false;
      });
    }
  } else {
    if (history) {
      allBets = allBets.filter((bet) => ['expired', 'results_set', 'settled'].indexOf(bet.status) >= 0);
    } else {
      allBets = allBets.filter((bet) => ['expired', 'results_set', 'settled'].indexOf(bet.status) === -1);
    }
    allBets = sortByWinLossTypes(allBets, history, winLossTypes, user);
  }
  return sortByBetTypes(allBets, options.filter, false);
};

export const sortBets = (bets, options, isResult = false, parlay = []) => {
  const allBets = [...bets];
  let singleGameBets = [];
  let multiGameBets = [];
  let seasonBets = [];
  const playerSingleGameBets = [];
  const playerMultiGameBets = [];
  const playerSeasonBets = [];
  const teamSingleGameBets = [];
  const teamMultiGameBets = [];
  const teamSeasonBets = [];

  allBets.forEach((item) => {
    if (item.bet_type.interval === 'single_game') {
      singleGameBets.push(item);
    }
    if (item.bet_type.interval === 'multiple_games') {
      multiGameBets.push(item);
    }
    if (item.bet_type.interval === 'season') {
      seasonBets.push(item);
    }
  });

  if (options?.filter?.isExpired) {
    singleGameBets = sortReverseByBeginningDate(singleGameBets);
    multiGameBets = sortReverseByBeginningDate(multiGameBets);
    seasonBets = sortReverseByBeginningDate(seasonBets);
  } else {
    singleGameBets = sortBySingleGameDate(singleGameBets);
    multiGameBets = sortByBeginningDate(multiGameBets);
    seasonBets = sortByBeginningDate(seasonBets);
  }

  singleGameBets = sortByGameIds(singleGameBets, options.filter, isResult);
  multiGameBets = sortByBetObjectName(multiGameBets);
  seasonBets = sortByBetObjectName(seasonBets);

  if (isResult) {
    const sortedParlay = options?.filter?.isExpired ? sortReverseByCurrentHistory(parlay) : sortByCurrentHistory(parlay);
    return [
      ...singleGameBets,
      ...sortedParlay,
      ...multiGameBets,
      ...seasonBets,
    ];
  }

  singleGameBets.forEach((item) => {
    const objectType = item.betting_object.type.toLowerCase();
    if (objectType === 'team') {
      teamSingleGameBets.push(item);
    }
    if (objectType === 'player') {
      playerSingleGameBets.push(item);
    }
  });

  multiGameBets.forEach((item) => {
    const objectType = item.betting_object.type.toLowerCase();
    if (objectType === 'team') {
      teamMultiGameBets.push(item);
    }
    if (objectType === 'player') {
      playerMultiGameBets.push(item);
    }
  });

  seasonBets.forEach((item) => {
    const objectType = item.betting_object.type.toLowerCase();
    if (objectType === 'team') {
      teamSeasonBets.push(item);
    }
    if (objectType === 'player') {
      playerSeasonBets.push(item);
    }
  });

  return [
    ...teamSingleGameBets,
    ...teamMultiGameBets,
    ...teamSeasonBets,
    ...playerSingleGameBets,
    ...playerMultiGameBets,
    ...playerSeasonBets,
  ];
};

export const sortAll = (bets, options) => {
  const filteredBets = [];
  // const filteredParlay = [];

  bets.forEach((item) => {
    if (item.type === 'parlay') {
      // @todo filteredParlay.push(item);
    } else {
      filteredBets.push(item);
    }
  });

  const allBets = postSort(filteredBets, options);
  return sortBets(allBets, options);
};

export const sortResultBets = (bets, options) => {
  let acceptedBets = [];
  let notAcceptedBets = [];
  const filteredBets = [];
  const filteredParlay = [];

  bets.forEach((item) => {
    if (item.type === 'parlay') {
      filteredParlay.push(item);
    } else {
      filteredBets.push(item);
    }
  });

  const allBets = postSort(filteredBets, options, true);
  allBets.forEach((item) => {
    if (item.bets?.length) {
      acceptedBets.push(item);
    } else if (!options.filter.isExpired) {
      if (item.status === 'proposed') {
        notAcceptedBets.push(item);
      }
    } else if (item.status === 'expired') {
      notAcceptedBets.push(item);
    }
  });

  acceptedBets = sortBets(acceptedBets, options, true, filteredParlay);
  notAcceptedBets = sortBets(notAcceptedBets, options, true);

  return [
    ...acceptedBets,
    ...notAcceptedBets,
  ];
};

/**
 *
 * @param params
 * @param mappedCurrencies
 * @param mode
 * @returns {string}
 */
export const getFilterQuery = (params, mappedCurrencies, mode = 'view') => {
  const {
    betParlayTypes, amount, league, games, intervals, currencies, objectTypes, isExpired, allAcceptable, roleTypes, isCryptoVisible,
  } = params;

  let query = '(id > -1)';
  const isResultBetsQuery = mode === 'result' && betParlayTypes?.includes('bet');
  const isResultParlayQuery = mode === 'result' && betParlayTypes?.includes('parlay');

  // roleTypes
  if (mode === 'result') {
    if (roleTypes?.length && params?.user) {
      query = `${query} AND (`;
      query += roleTypes.map((roleType) => {
        let str = '';
        if (roleType === 'maker') {
          str = `user.id:"${params.user.id}"`;
          if (isResultParlayQuery && isResultBetsQuery) {
            str = `${str} OR maker.id:"${params.user.id}"`;
          } else if (isResultParlayQuery) {
            str = `maker.id:"${params.user.id}"`;
          }
          return str;
        }
        str = `bets.user.id:"${params.user.id}"`;
        if (isResultParlayQuery && isResultBetsQuery) {
          str = `${str} OR taker.id:"${params.user.id}"`;
        } else if (isResultParlayQuery) {
          str = `taker.id:"${params.user.id}"`;
        }
        return str;
      }).join(' OR ');
      query = `${query})`;
    }
  }

  // league filter
  if (mode === 'view' || mode === 'result') {
    if (league && league.id > 0) {
      query = `${query} AND (`;
      if (isResultParlayQuery) {
        query = `${query}type:"parlay" OR `;
      }
      // query = `${query}betting_object.league.id:"${league.id}" OR opposite_betting_object.league.id:"${league.id}")`;
      query = `${query}league.id:"${league.id}")`;
    }
  }

  // favorite filter
  if (mode === 'favorite' && params.favorites) {
    if (params.favorites.length) {
      query = `${query} AND (`;
      query += params.favorites.map((favorite) => {
        let str = '';
        if (favorite.object_type === 'league') {
          str = `league.id:"${league.id}"`;
        } else {
          str = `betting_object.${favorite.object_type}.id:"${favorite.object_id}" OR `;
          str += `opposite_betting_object.${favorite.object_type}.id:"${favorite.object_id}"`;
        }
        return str;
      }).join(' OR ');
      query = `${query})`;
    }
  }

  // games filter
  if (mode === 'view') {
    if (games?.length) {
      query = `${query} AND (`;
      query += games.map((game) => `intervals.game_id:"${game.id}"`).join(' OR ');
      query = `${query})`;
    }
  }

  if (mode !== 'result' || isResultBetsQuery) {
    // interval types filter: 'season', 'multiple_games', 'single_game'
    if (intervals?.length) {
      query = `${query} AND (`;
      if (isResultParlayQuery) {
        query = `${query}type:"parlay" OR `;
      }
      query += intervals.map((interval) => `intervals.interval_type:"${interval}"`).join(' OR ');
      query = `${query})`;
    }
    // object types filter: 'team', 'player'
    if (objectTypes?.length) {
      query = `${query} AND (`;
      if (isResultParlayQuery) {
        query = `${query}type:"parlay" OR `;
      }
      query += objectTypes.map((type) => `bet_type.object_type:"${type}"`).join(' OR ');
      query = `${query})`;
    }
  }

  // currencies filter
  if (!isCryptoVisible) {
    query = `${query} AND (currency.symbol:"usd")`;
  } else if (currencies?.length) {
    query = `${query} AND (`;
    query += currencies.map((currency) => `currency.symbol:"${currency}"`).join(' OR ');
    query = `${query})`;

    if (currencies.length === 1 && mappedCurrencies && mappedCurrencies[currencies[0]]) {
      if (Number(amount.min) > 0) {
        const minAmount = convertCurrencyAmount(amount.min, mappedCurrencies[currencies[0]]);
        query = `${query} AND (`;
        if (isResultParlayQuery && isResultBetsQuery) {
          query = `${query}maker_amount >= ${minAmount} OR taker_amount >= ${minAmount} OR odds.proposed_return >= ${minAmount})`;
        } else if (isResultParlayQuery) {
          query = `${query}maker_amount >= ${minAmount} OR taker_amount >= ${minAmount})`;
        } else {
          query = `${query}odds.proposed_return >= ${minAmount})`;
        }
      }
      if (Number(amount.max) > 0) {
        const maxAmount = convertCurrencyAmount(amount.max, mappedCurrencies[currencies[0]]);
        query = `${query} AND (`;
        if (isResultParlayQuery && isResultBetsQuery) {
          query = `${query}maker_amount <= ${maxAmount} OR taker_amount <= ${maxAmount} OR odds.proposed_return <= ${maxAmount})`;
        } else if (isResultParlayQuery) {
          query = `${query}maker_amount <= ${maxAmount} OR taker_amount <= ${maxAmount})`;
        } else {
          query = `${query}odds.proposed_return <= ${maxAmount})`;
        }
      }
    }
  }

  // status filter
  query = `${query} AND (NOT status:"deleted")`;

  // expired
  if (mode === 'view' || mode === 'favorite') {
    query = `${query} AND (is_expired:${isExpired})`;
  }

  // acceptable
  if (mode === 'result') {
    if (!isExpired) {
      query = `${query} AND (`;
      if (isResultParlayQuery && isResultBetsQuery) {
        query = `${query}status:"pending" OR `;
      } else if (isResultParlayQuery) {
        query = `${query}status:"pending")`;
      }
      if (isResultBetsQuery) {
        if (allAcceptable?.length === 1) {
          if (allAcceptable[0] === 'acceptable') {
            query = `${query}status:"partially_accepted" OR status:"fully_accepted")`;
          } else {
            query = `${query}status:"proposed")`;
          }
        } else {
          query = `${query}status:"proposed" OR status:"partially_accepted" OR status:"fully_accepted")`;
        }
      }
    } else if (allAcceptable?.length === 1) {
      if (isResultParlayQuery) {
        query = `${query} AND (status:"expired" OR "results_set" OR "settled")`;
      } else if (allAcceptable[0] === 'acceptable') {
        query = `${query} AND (status:"settled" OR status:"results_set")`;
      } else {
        query = `${query} AND (status:"expired")`;
      }
    } else {
      query = `${query} AND (status:"expired" OR status:"settled" OR status:"results_set")`;
    }
  }

  return query;
};
