import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { debounce } from 'lodash';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import GameCard from 'components/Bet/GameCard';
import Loading from 'components/Loading/Box';
import VList from 'components/VList';
import SearchInput from 'components/Form/SearchInput';
import ParlayCart from 'components/Parlay/ParlayCart';

import { getGameInfo } from 'utils/betting';
import {
  useBet,
  useGames,
  useSelectedLeague,
  useSelectedGames,
  useSetGamesDispatch,
} from 'hooks/redux/bet';
import { useParlay } from 'hooks/redux/parlay';
import commonStyle from 'styles/common';

const useStyles = makeStyles(({ palette }) => ({
  title: {
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
  },
}));

const UpcomingGames = ({ history }) => {
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState();
  const [debounceSearch, setDebounceSearch] = useState('');
  const [checkedGames, setCheckedGames] = useState([]);
  const [gameList, setGameList] = useState([]);
  const [orgGames, setOrgGames] = useState(null);

  const classes = useStyles();
  const commonClasses = commonStyle();
  const betting = useBet();
  const league = useSelectedLeague();
  const parlay = useParlay();
  const games = useGames(debounceSearch, options);
  const selectedGames = useSelectedGames();
  const selectGames = useSetGamesDispatch();

  // eslint-disable-next-line
  const doDebounceSearch = useCallback(
    debounce((value) => setDebounceSearch(value), 500), [debounce, setDebounceSearch],
  );

  const handleSearch = (e) => {
    setSearch(e.target.value);
    doDebounceSearch(e.target.value);
  };

  const handleNext = () => {
    selectGames(games.filter((game) => checkedGames.indexOf(game.id) >= 0));
    history.push('/bets/all');
  };

  const handleSelectGame = useCallback((id, checked) => {
    if (!checked) {
      const newGameIds = checkedGames.filter((gameId) => gameId !== Number(id));
      setCheckedGames(newGameIds);
    } else {
      setCheckedGames([...checkedGames, Number(id)]);
    }
  }, [setCheckedGames, checkedGames]);

  const handleScroll = (event) => {
    if (options) {
      const { page } = options;
      if (((event.scrollHeight - event.clientHeight) === Math.round(event.scrollTop))) {
        setOptions({
          ...options,
          page: page + 1,
        });
      }
    }
  };

  useEffect(() => {
    const ids = [];
    selectedGames.forEach((game) => {
      ids.push(game.id);
    });
    setCheckedGames(ids);
  }, [selectedGames]);

  useEffect(() => {
    if (league.id) {
      let queryFilter = '';
      queryFilter += `(betting_league_id=${league.id}) AND ((NOT status:"Cancelled")`;
      queryFilter += ' AND (NOT status:"Canceled") AND  (NOT status:"Suspended") AND (NOT status:"Awarded")';
      queryFilter += ' AND (NOT status:"Postponed") AND (NOT status:"Final")';
      queryFilter += ' AND (NOT status:"F/OT") AND (NOT status:"F/SO") AND (upcoming:true))';

      setOptions({
        hitsPerPage: 1000,
        page: 0,
        filters: queryFilter,
      });
    }
  }, [setOptions, league]);

  useEffect(() => {
    if (orgGames === games) {
      return;
    }
    if (options?.page === 0) {
      setGameList([
        ...(games || []),
      ]);
    } else {
      setGameList([
        ...gameList,
        ...(games || []),
      ]);
    }
    setOrgGames(games);
  }, [games, orgGames, gameList, options]);

  return (
    <Page>
      <Box className={commonClasses.root2}>
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
        >
          <BackButton color={commonClasses.backBtn} />
          <SearchInput
            handleChange={handleSearch}
            placeholder="e.g. NFL, hocky, Lakers or LeB…"
            value={search}
          />
        </Box>
        <Box className={commonClasses.vListWrapper}>
          {gameList.length > 0 && (
            <VList
              onScroll={handleScroll}
              rowCount={gameList.length}
              rowRender={(index) => (
                <>
                  {
                    index === 0
                  && (
                  <Box textAlign="center" mb={4} mt={3}>
                    <Typography component="h3" className={classes.title}>
                      Upcoming Games
                    </Typography>
                  </Box>
                  )
                }
                  <GameCard
                    selectedIds={checkedGames}
                    selectGame={handleSelectGame}
                    {...getGameInfo(gameList[index])}
                  />
                </>
              )}
            />
          )}
          {
            betting.isLoading
            && (<Loading />)
          }
        </Box>
      </Box>
      <Box mt={3} className={commonClasses.continue}>
        <OutlineButton onClick={() => handleNext()}>
          Continue
        </OutlineButton>
      </Box>
      {parlay?.list.length > 0 && (
        <ParlayCart parlay={parlay} />
      )}
    </Page>
  );
};

UpcomingGames.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(UpcomingGames);
