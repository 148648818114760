import * as actionTypes from 'store/actionTypes';

const INITIAL_STATE = {
  isLoading: false,
  isAuthenticated: undefined,
  user: {
    is_new: false,
    country_id: 0,
    state_id: 0,
    registration_location_id: 0,
    profile_url: '',
    dob: '',
    location: {
      lon: '',
      lat: '',
    },
    display_mode: 'light',
    odds_display_type: 'american',
    currency_display_type: 0,
    kyc_verified: false,
    favorites_json: [],
    restricted_features: [],
    badge: 0,
    kyc_attempts: 0,
  },
  location: {},
  isInstalled: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        isAuthenticated: undefined,
      };
    case actionTypes.GET_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: { ...state.user, ...action.payload.user },
        isAuthenticated: true,
      };
    case actionTypes.GET_USER_FAIL:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
      };
    case actionTypes.GET_USER_REWARD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_USER_REWARD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: { ...state.user, reward: action.payload.reward },
      };
    case actionTypes.GET_USER_REWARD_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.GET_USER_LOCATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_USER_LOCATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        location: action.payload.location,
      };
    case actionTypes.GET_USER_LOCATION_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.payload.user,
        isAuthenticated: true,
      };
    case actionTypes.LOGIN_FAIL:
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.SIGNUP_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.SIGNUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: { ...state.user, ...action.payload.user },
        isAuthenticated: true,
      };
    case actionTypes.SIGNUP_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.SET_USER_COUNTRY:
      return {
        ...state,
        user: { ...state.user, country_id: action.payload },
      };

    case actionTypes.SET_USER_STATE:
      return {
        ...state,
        user: { ...state.user, state_id: action.payload },
      };

    case actionTypes.SET_USER_REGISTRATION_COUNTRY:
      return {
        ...state,
        user: { ...state.user, registration_location_id: action.payload },
      };

    case actionTypes.SET_USER_BIRTHDAY:
      return {
        ...state,
        user: { ...state.user, dob: action.payload },
      };

    case actionTypes.SET_USER_LOCATION:
      return {
        ...state,
        user: {
          ...state.user,
          location: action.payload,
        },
      };

    case actionTypes.SET_USER_PHOTO:
      return {
        ...state,
        user: { ...state.user, profile_url: action.payload },
      };

    case actionTypes.UPDATE_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.payload.user,
        isAuthenticated: true,
      };
    case actionTypes.UPDATE_USER_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.SET_USER_ODDS:
      return {
        ...state,
        user: { ...state.user, odds_display_type: action.payload },
      };
    case actionTypes.SET_USER_CURRENCY_DISPLAY:
      return {
        ...state,
        user: { ...state.user, currency_display_type: action.payload },
      };
    case actionTypes.SET_USER_DISPLAY_MODE:
      return {
        ...state,
        user: { ...state.user, display_mode: action.payload },
      };
    case actionTypes.SET_USER_FAVORITES_REQUEST:
      return {
        ...state,
      //  isLoading: true,
      };
    case actionTypes.SET_USER_FAVORITES_SUCCESS:
      return {
        ...state,
      //  isLoading: false,
      };
    case actionTypes.SET_USER_FAVORITES_FAIL:
      return {
        ...state,
      //  isLoading: false,
      };
    case actionTypes.RESET_NOTIFICATION_BADGE_REQUEST:
      return {
        ...state,
      //  isLoading: true,
      };
    case actionTypes.RESET_NOTIFICATION_BADGE_SUCCESS:
      return {
        ...state,
        //  isLoading: false,
        user: { ...state.user, badge: action.payload.badge },
      };
    case actionTypes.RESET_NOTIFICATION_BADGE_FAIL:
      return {
        ...state,
        // isLoading: false,
      };
    case actionTypes.LOG_OUT:
      return INITIAL_STATE;
    case actionTypes.INSTALL_REQUEST:
      return {
        ...state,
      };
    case actionTypes.INSTALL_SUCCESS:
      return {
        ...state,
        isInstalled: true,
      };
    case actionTypes.INSTALL_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
};
