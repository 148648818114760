import React, { useEffect, useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import { Box, Typography } from '@material-ui/core';

import Page from 'components/Page/Dashboard';
import Loading from 'components/Loading/Box';
import VList from 'components/VList';
import BackButton from 'components/Buttons/BackButton';
import CurrencyDropdown from 'components/Currency/CurrencyDropdown';
import { formattedDate } from 'utils';
import { usdCurrency } from 'utils/constants';
import { useMappedCurrencies } from 'hooks/redux/currency';
import { currencyConvert, formatCurrency } from 'utils/currency';
import { useOpenSuccessModalDispatch } from 'hooks/redux/modal';

import { useTransactionsList, useTransactionIsLoading } from 'hooks/redux/transactions';
import TransactionCard from 'components/Transaction/TransactionCard';
import SearchInput from 'components/Form/SearchInput';
// import { isSafari } from 'utils';
import { debounce } from 'lodash';
import CommonStyle from 'styles/common';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 15px',
  },
  transactionsList: {
    flex: 1,
    margin: '20px 0 10px 0',
    padding: '0 10px',
    boxShadow: '0 3px 10px 1px rgba(0,0,0,0.15)',
    background: palette.cardColor,
    borderRadius: '6px',
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
  },
  wrapper: {
    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.15)',
    padding: '15px',
  },
  subtitle: {
    textAlign: 'center',
    color: '#AEAEAE',
    font: '15px SofiaPro',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    fontSize: '13px',
    textAlign: 'center',
    marginBottom: '20px',
  },
  question: {
    font: '13px SofiaPro-Bold',
    color: '#29CDD4',
    textAlign: 'center',
    padding: '25px 35px',
  },
  icon: {
    color: '#00D3DA',
    width: 20,
    height: 20,
    marginLeft: 5,
  },
  link: {
    width: '100%',
    display: 'inline-block',
    borderRadius: '5px',
    padding: '18px 0px',
    textAlign: 'center',
    textDecoration: 'none',
    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.15)',
    color: '#29CDD4',
  },
}));

const Transaction = ({ history, match }) => {
  const classes = useStyles();
  const commonClasses = CommonStyle();
  const [search, setSearch] = useState('');
  const [debounceSearch, setDebounceSearch] = useState('');
  const mappedCurrencies = useMappedCurrencies();
  const openSuccessModal = useOpenSuccessModalDispatch();
  const currencySymbol = match.params.currency;
  const [params, setParams] = useState();
  const [transactionList, setTransactionList] = useState([]);
  const [orgTransactions, setOrgTransactions] = useState(null);

  const transactions = useTransactionsList(params);
  const isLoading = useTransactionIsLoading();

  // eslint-disable-next-line
  const doDebounceSearch = useCallback(
    debounce((value) => setDebounceSearch(value), 500), [debounce, setDebounceSearch],
  );

  const handleSearch = (e) => {
    setSearch(e.target.value);
    doDebounceSearch(e.target.value);
  };

  const showWithdrawTransactionPopup = (transaction) => {
    const createAt = formattedDate(transaction.created_at);
    const withdrawAddress = transaction.withdrawal_address;
    let amount = 0;
    switch (currencySymbol) {
      case 'usd':
        amount = Math.abs(transaction.amount);
        break;
      case 'sports':
        amount = Math.abs(transaction.sports_amount);
        break;
      case 'icx':
        amount = Math.abs(transaction.icx_amount);
        break;
      case 'btc':
        amount = Math.abs(transaction.btc_amount);
        break;
      default:
        break;
    }
    const formattedAmount = formatCurrency(amount, mappedCurrencies[currencySymbol]);
    const title = 'Withdrawal Details';
    const message = `You made a withdrawal in the amount of ${formattedAmount} on ${createAt}.
    The wallet address that these funds were sent to was ${withdrawAddress}.`;
    const cta = 'Got It';
    openSuccessModal({
      title,
      subtitle: message,
      buttonText: cta,
    });
  };

  const showDepositTransactionPopup = (transaction) => {
    const createAt = formattedDate(transaction.created_at);
    let amount = 0;
    switch (currencySymbol) {
      case 'usd':
        amount = Math.abs(transaction.amount);
        break;
      case 'sports':
        amount = Math.abs(transaction.sports_amount);
        break;
      case 'icx':
        amount = Math.abs(transaction.icx_amount);
        break;
      case 'btc':
        amount = Math.abs(transaction.btc_amount);
        break;
      default:
        break;
    }
    const formattedAmount = formatCurrency(amount, mappedCurrencies[currencySymbol]);
    const title = 'Deposit Details';
    const message = `You made a deposit in the amount of ${formattedAmount} on ${createAt}.`;
    const cta = 'Got It';
    openSuccessModal({
      title,
      subtitle: message,
      buttonText: cta,
    });
  };

  const showDepositBonusTransactionPopup = (transaction) => {
    const createAt = formattedDate(transaction.created_at);
    let amount = 0;
    switch (currencySymbol) {
      case 'usd':
        amount = Math.abs(transaction.amount);
        break;
      case 'sports':
        amount = Math.abs(transaction.sports_amount);
        break;
      case 'icx':
        amount = Math.abs(transaction.icx_amount);
        break;
      case 'btc':
        amount = Math.abs(transaction.btc_amount);
        break;
      default:
        break;
    }
    const formattedAmount = formatCurrency(amount, mappedCurrencies[currencySymbol]);
    const formattedUsdAmount = formatCurrency(
      currencyConvert(
        amount,
        mappedCurrencies[currencySymbol],
        usdCurrency,
      ),
      usdCurrency,
      true,
    );
    const title = 'Welcome Bonus';
    const message = `You received a Welcome Bonus of ${formattedAmount}, equivalent to ${formattedUsdAmount}, on ${createAt}.`;
    const cta = 'Got It';
    openSuccessModal({
      title,
      subtitle: message,
      buttonText: cta,
    });
  };

  const handleTransaction = (transaction) => {
    const transactionType = transaction.transaction_type;
    switch (transactionType) {
      case 'withdrawal':
        showWithdrawTransactionPopup(transaction);
        break;
      case 'deposit':
        showDepositTransactionPopup(transaction);
        break;
      case 'welcome_bonus':
        showDepositBonusTransactionPopup(transaction);
        break;
      case 'exchange':
        break;
      case 'referral_bonus':
        break;
      default:
        if (transaction?.parlay_id > 0) {
          history.push(`/bets/results/parlay/${transaction.parlay_id}`);
          return;
        }
        if (transaction?.proposed_bet_id > 0) {
          history.push(`/bets/view/${transaction.proposed_bet_id}`);
        }
        break;
    }
  };

  const handleScroll = (event) => {
    if (params) {
      const { page } = params;
      if (((event.scrollHeight - event.clientHeight) === Math.round(event.scrollTop)) && transactionList.length >= (page + 1) * params.amount) {
        if (debounceSearch.length > 0) {
          setParams({
            ...params,
            page: page + 1,
            bet_id: debounceSearch,
          });
        } else {
          setParams({
            ...params,
            page: page + 1,
          });
        }
      }
    }
  };

  useEffect(() => {
    if (currencySymbol) {
      if (debounceSearch.length > 0) {
        setParams({
          currency: currencySymbol,
          page: 0,
          amount: 25,
          bet_id: debounceSearch,
        });
      } else {
        setParams({
          currency: currencySymbol,
          page: 0,
          amount: 25,
        });
      }
    }
  }, [currencySymbol, debounceSearch]);

  useEffect(() => {
    if (orgTransactions === transactions) {
      return;
    }
    if (params && params.page === 0) {
      setTransactionList([
        ...(transactions || []),
      ]);
    } else {
      setTransactionList([
        ...transactionList,
        ...(transactions || []),
      ]);
    }
    setOrgTransactions(transactions);
  }, [transactions, orgTransactions, transactionList, params]);

  return (
    <Page>
      <Box className={classes.root}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <BackButton color={commonClasses.backBtn} page="/funds" />
          <SearchInput
            handleChange={handleSearch}
            placeholder="Enter Bet ID"
            value={search}
          />
        </Box>
        <Box textAlign="center" mb={4} mt={3}>
          <Typography variant="subtitle2" className={commonClasses.topic}>
            TRANSACTIONS LIST
          </Typography>
        </Box>
        <Box>
          <CurrencyDropdown showExchangeRate />
        </Box>
      </Box>
      <Box className={classes.transactionsList}>
        {transactionList.length > 0 && (
          <VList
            onScroll={handleScroll}
            rowCount={transactionList.length}
            rowRender={(index) => (
              <>
                <TransactionCard
                  item={transactionList[index]}
                  selectedCurrency={currencySymbol}
                  onClick={() => handleTransaction(transactionList[index])}
                />
              </>
            )}
          />
        )}
      </Box>
      {
        isLoading
          && (<Loading />)
      }
    </Page>
  );
};

Transaction.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(Transaction);
