import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getNotificationsListAction,
  getNotificationSettingsAction,
  updateNotificationSettingsAction,
  resetNotificationBadgeAction,
} from 'store/actions/notification';

export const useUpdateNotificationSettingsDispatch = () => {
  const dispatch = useDispatch();
  return (data) => dispatch(updateNotificationSettingsAction(data));
};

export const useNotificationSettings = (params) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getNotificationSettingsAction(params));
    // eslint-disable-next-line
  }, [params]);
  return useSelector(({ notification: { settings } }) => settings);
};

export const useNotificationsIsLoading = () => useSelector(({ notification: { isLoading } }) => isLoading);

export const useNotifications = (params) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (params) {
      dispatch(getNotificationsListAction(params));
    }
    // eslint-disable-next-line
  }, [params]);
  return useSelector(({ notification: { list } }) => list);
};

export const useNotificationBadge = () => {
  const dispatch = useDispatch();
  if ('serviceWorker' in navigator) {
    // Listen to the response
    navigator.serviceWorker.onmessage = (event) => {
      if (event.data && event.data.type === 'UPDATE_BADGE') {
        dispatch({
          type: 'RESET_NOTIFICATION_BADGE_SUCCESS',
          payload: { badge: event.data.badge },
        });
      }
    };
  }
  return useSelector(({ auth: { user: { badge } } }) => badge);
};

export const useResetNotificationBadgeDispatch = () => {
  const dispatch = useDispatch();
  return () => dispatch(resetNotificationBadgeAction());
};
