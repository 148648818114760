import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  Box, Fab, InputBase, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import SearchIcon from '@material-ui/icons/Search';

import Page from 'components/Page/Auth';
import VList from 'components/VList';
import BackButton from 'components/Buttons/BackButton';
import SoccerStadiumImage from 'assets/images/soccer-stadium.png';

import { setStateAction } from 'store/actions/auth';
import { useOpenErrorModalDispatch } from 'hooks/redux/modal';

const useStyles = makeStyles({
  root: {
    margin: '20px',
    padding: '5px 13px',
    display: 'flex',
    alignItems: 'center',
    background: '#252451',
    borderRadius: '6px',
  },
  input: {
    '& input::placeholder': {
      opacity: '0.8 !important',
    },
  },
  searchIcon: {
    color: 'white',
    width: '18px',
    paddingRight: '13px',
  },
  stateList: {
    flex: 1,
  },
  stateItem: {
    fontSize: '17px',
    fontWeight: '600',
    lineHeight: '25px',
    color: 'white',
    textAlign: 'center',
    marginBottom: '30px',
  },
  stateActiveItem: {
    color: '#63C4C6',
  },
});

const State = ({
  history,
  states,
  stateId,
  setUserState,
}) => {
  const [search, setSearch] = useState('');
  const [stateAbbr, setStateAbbr] = useState('');
  const [searchedStates, setSearchedStates] = useState([]);
  const openErrorModal = useOpenErrorModalDispatch();
  const classes = useStyles();

  const handleNext = () => {
    if (stateAbbr === 'NV') {
      history.push('/casino');
    } else if (stateId > -1) {
      history.push('/birthday');
    } else {
      openErrorModal({ title: 'Oops', subtitle: 'Please select your state!' });
    }
  };

  const handleSetState = (userState) => {
    setUserState(userState.id);
    setStateAbbr(userState.abbreviation);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
    const filteredStates = states.filter((state) => state.name.toLowerCase().includes(
      event.target.value.toLowerCase(),
    ));
    setSearchedStates(filteredStates);
  };

  useEffect(() => {
    if (states.length) {
      setSearchedStates(states);
    }
  }, [states]);

  return (
    <Page backgroundImage={SoccerStadiumImage}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <BackButton />
        <Fab variant="extended" onClick={handleNext}>Next</Fab>
      </Box>
      <Box mt={3}>
        <Typography variant="subtitle1" className="text-center color-white">
          Now, we need to know which state you live in. Search for your state of residence
          and select it below:
        </Typography>
      </Box>
      <Box className={classes.root}>
        <SearchIcon className={classes.searchIcon} />
        <InputBase
          autoComplete="off"
          className={classes.input}
          placeholder="Search State of Residence"
          inputProps={{ 'aria-label': 'Search State of Residence' }}
          fullWidth
          value={search}
          onChange={handleSearch}
        />
      </Box>
      <Box className={classes.stateList} mt={3}>
        {searchedStates.length > 0 && (
          <VList
            rowCount={searchedStates.length}
            rowRender={(index) => (
              <>
                <Box
                  className={`${classes.stateItem} ${(stateId === searchedStates[index].id) && classes.stateActiveItem}`}
                  onClick={() => handleSetState(searchedStates[index])}
                >
                  {searchedStates[index].name}
                </Box>
              </>
            )}
          />
        )}
      </Box>
    </Page>
  );
};

State.defaultProps = {
  stateId: 0,
};

State.propTypes = {
  history: PropTypes.object.isRequired,
  states: PropTypes.array.isRequired,
  stateId: PropTypes.number,
  setUserState: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  stateId: state.auth.user.state_id,
  states: state.country.list.find(
    (country) => country.id === state.auth.user.country_id,
  )?.subdivisions || [],
});

const mapDispatchToProps = (dispatch) => ({
  setUserState: (userState) => dispatch(setStateAction(userState)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(State));
