import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Button } from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: 0,
    width: '100%',
    background: palette.colorBtn,
    marginBottom: '10px',
    minHeight: 50,
    font: '17px SofiaPro-SemiBold',
    letterSpacing: '0.3px',
    color: palette.colorBtnTxt,
  },
}));

const OutlineButton = ({
  children, onClick, disabled, type,
}) => {
  const classes = useStyles();

  return (
    <Button type={type} disabled={disabled} className={classes.root} onClick={onClick}>
      { children }
    </Button>
  );
};

OutlineButton.defaultProps = {
  onClick: () => {},
  disabled: false,
  type: '',
};

OutlineButton.propTypes = {
  type: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

export default OutlineButton;
