import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Box, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import {
  useTransaction,
  useTransactionStatusDispatch,
  useTransactionDetailDispatch,
} from 'hooks/redux/currency';
import { useOpenSuccessModalDispatch } from 'hooks/redux/modal';
import commonStyle from 'styles/common';

const qrBaseUrl = 'https://chart.googleapis.com/chart?chs=250x250&cht=qr&chl=';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 15px',
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
  },
  subtitle: {
    font: '12px SofiaPro-SemiBold',
    color: '#08B0AA',
    marginBottom: '10px',
  },
  currencyImage: {
    height: '60px',
  },
  usdAmount: {
    font: '15px SofiaPro-SemiBold',
    color: palette.secondaryColor,
  },
  account: {
    font: '25px SofiaPro-Bold',
    color: palette.secondaryColor,
  },
  switch: {
    borderRadius: '30px',
    width: '80%',
    display: 'flex',
    margin: '16px auto',
    background: palette.pillMenuBgColor,
    // backgroundColor: '#EEE',
    padding: '2px',
  },
  active: {
    width: '50%',
    color: palette.secondaryColor,
    background: palette.pillActiveBgColor,
    borderRadius: '30px',
    textAlign: 'center',
    padding: '10px 0',
    font: '15px SofiaPro-Bold',
  },
  deActive: {
    color: palette.pillInactiveColor,
    width: '50%',
    textAlign: 'center',
    padding: '10px 0',
    font: '15px SofiaPro-Bold',
  },
  content: {
    textAlign: 'center',
    font: '13px SofiaPro',
    color: '#7A869A',
    paddingBottom: '15px',
  },
  omitted: {
    width: '70%',
    display: 'inline-block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  token: {
    color: palette.secondaryColor,
    textAlign: 'center',
    font: '13px SofiaPro-Bold',
  },
  copyBlock: {
    padding: '3px 2px 2px 2px',
  },
  copyBlockContent: {
    background: palette.clipboardColor,
    border: palette.btnBorder,
    borderRadius: '6px',
    boxShadow: '0 3px 10px 0 rgba(0,0,0,0.15)',
    color: '#7A869A',
  },
  copied: {
    borderRadius: '6px',
    backgroundColor: 'rgba(8, 176, 170, 0.15)',
  },
  clipboard: {
    font: '13px SofiaPr',
    color: '#08B0AA',
    textAlign: 'center',
    height: '15px',
  },
  button: {
    textAlign: 'center',
    font: '17px SofiaPro-Bold',
    color: palette.secondaryColor,
  },
}));

const copyInitialStates = {
  address: false,
  amount: false,
};

const Instruction = ({ history }) => {
  const classes = useStyles();
  const commonClasses = commonStyle();
  const [isCopy, setIsCopy] = useState(true);
  const [copied, setCopied] = useState(copyInitialStates);
  const transaction = useTransaction();
  const getTransactionStatus = useTransactionStatusDispatch();
  const getTransactionDetail = useTransactionDetailDispatch();
  const openSuccessModal = useOpenSuccessModalDispatch();

  const copyClipboard = (value, type) => {
    const copyData = (e) => {
      e.preventDefault();
      e.clipboardData.setData('text/plain', value);
      document.removeEventListener('copy', copyData);
    };
    document.addEventListener('copy', copyData);
    document.execCommand('copy');
    setCopied({
      ...copyInitialStates,
      [type]: true,
    });
  };

  useEffect(() => {
    const timer = setInterval(async () => {
      if (transaction) {
        const status = await getTransactionStatus({ transactionId: transaction.id });
        if (status.payment_detected) {
          if (['btc', 'usdt'].includes(transaction.crypto?.symbol)) {
            history.push(`/funds/deposit/${transaction.crypto.symbol}/${transaction.id}/confirmation`);
          }
          const data = await getTransactionDetail({ transactionId: transaction.id });
          if (data) {
            openSuccessModal({
              title: 'Deposit Successful!',
              subtitle: `Congratulations! Your ${data.coins_value} ${transaction.crypto?.name} deposit has been processed successfully,
                        and funds are now available in your ZenSports account. Happy sports betting! 😎`,
              buttonText: 'Got it',
              nextPath: '/funds',
            });
            clearInterval(timer);
          }
        }
      }
    }, 4000);

    return () => clearInterval(timer);
  // eslint-disable-next-line
  }, []);

  return (
    <Page>
      <Box className={classes.root}>
        <Box>
          <BackButton color={commonClasses.backBtn} />
        </Box>
        <Box textAlign="center" mb={4} mt={3}>
          <Typography component="h6" className={classes.subtitle}>
            DEPOSIT INSTRUCTIONS
          </Typography>
          <Typography component="h3" className={classes.title}>
            Waiting on Payment
          </Typography>
        </Box>
        {transaction && (
        <>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Box>
              <img className={classes.currencyImage} src={transaction.crypto?.image_url} alt="pay" />
            </Box>
            {/* <Typography className={classes.account}> */}
            {/*  {transaction.coins_value} */}
            {/* </Typography> */}
            {/* {transaction.crypto?.symbol !== 'usd' && mappedCurrencies[transaction.crypto?.symbol] && ( */}
            {/*  <Typography className={classes.usdAmount}> */}
            {/*    ( */}
            {/*    {getFormattedCurrency( */}
            {/*      convertCurrencyAmount(transaction.coins_value, mappedCurrencies[transaction.crypto?.symbol]), */}
            {/*      mappedCurrencies[transaction.crypto?.symbol], */}
            {/*      'usd', */}
            {/*    )} */}
            {/*    ) */}
            {/*  </Typography> */}
            {/* )} */}
          </Box>
          <Box className={classes.switch} mt={2} mb={2}>
            <Typography
              className={isCopy ? classes.active : classes.deActive}
              onClick={() => setIsCopy(true)}
            >
              Copy
            </Typography>
            <Typography
              className={!isCopy ? classes.active : classes.deActive}
              onClick={() => setIsCopy(false)}
            >
              Scan
            </Typography>
          </Box>
          <Typography className={classes.content}>
            Please send
            {' '}
            {transaction.crypto.symbol.toUpperCase()}
            {' '}
            to this
            {' '}
            {(transaction.crypto.name === 'SPORTS' || transaction.crypto.name === 'Icon') ? 'ICONex' : '' }
            {' '}
            Wallet Address
          </Typography>
          <Typography className={classes.token}>
            {transaction.address}
          </Typography>
          {isCopy ? (
            <Box display="flex" mt={5}>
              <Grid item xs={6} sm={6}>
                <Box className={`${classes.copyBlock} ${copied.address ? classes.copied : ''}`}>
                  <Box className={classes.clipboard}>
                    {copied.address && (<>COPIED TO CLIPBOARD</>)}
                  </Box>
                  <Box p={2} m={1} className={classes.copyBlockContent}>
                    <Box display="flex" mb={1} justifyContent="center">
                      <span className={classes.omitted}>
                        {transaction.address}
                      </span>
                      <span>
                        {transaction.address.substr(transaction.address.length - 3)}
                      </span>
                    </Box>
                    <Typography className={classes.button} onClick={() => copyClipboard(transaction.address, 'address')}>
                      Copy Address
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Box className={`${classes.copyBlock} ${copied.amount ? classes.copied : ''}`}>
                  <Box className={classes.clipboard}>
                    {copied.amount && (<>COPIED TO CLIPBOARD</>)}
                  </Box>
                  <Box p={2} m={1} className={classes.copyBlockContent}>
                    <Box mb={1} textAlign="center">
                      {transaction.coins_value}
                    </Box>
                    <Typography className={classes.button} onClick={() => copyClipboard(transaction.coins_value, 'amount')}>
                      Copy Amount
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Box>
          ) : (
            <Box display="flex" justifyContent="center" mb={5} mt={3}>
              <img
                src={`${qrBaseUrl}${transaction.crypto.symbol}:${transaction.address}?amount=%.8f${transaction.amount}`}
                alt="QR"
              />
            </Box>
          )}
        </>
        )}
      </Box>
    </Page>
  );
};

Instruction.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(Instruction);
