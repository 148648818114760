import config from 'utils/config';
import httpService from './http.service';

const getMarkets = (params) => httpService
  .get('/exchange/markets', {}, params, config.exchangeBaseUrl)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));
const getMarketsDetails = (params) => httpService
  .get(`/exchange/${params.id}/market_details`, {}, {}, config.exchangeBaseUrl)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));
const getMarketChange = () => httpService
  .get('/exchange/market_change', {}, {}, config.exchangeBaseUrl)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));
const createExchangeOrder = (body) => httpService
  .post('/exchange/create_order', body, {}, {}, config.exchangeBaseUrl)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));
const getOrders = (params) => httpService
  .get('/exchange/orders', {}, params, config.exchangeBaseUrl)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));
const cancelOrder = (params) => httpService
  .post(`/exchange/${params.id}/cancel_order`, {}, {}, {}, config.exchangeBaseUrl)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));
const cancelAllOrders = (body) => httpService
  .post('/exchange/cancel_all', body, {}, {}, config.exchangeBaseUrl)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

export default {
  getMarkets,
  getMarketsDetails,
  getMarketChange,
  createExchangeOrder,
  getOrders,
  cancelAllOrders,
  cancelOrder,
};
