import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { debounce } from 'lodash';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import BackButton from 'components/Buttons/BackButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import GameCard from 'components/Bet/GameCard';
import Loading from 'components/Loading/Box';
import VList from 'components/VList';
import SearchInput from 'components/Form/SearchInput';
import { useOpenErrorModalDispatch } from 'hooks/redux/modal';
import commonStyle from 'styles/common';

import { getGameInfo } from 'utils/betting';
import {
  useBet,
  useGames,
  useSelectedLeague,
  useSelectedInterval,
  useSelectedGames,
  useSetGamesDispatch,
  useSetBetObjectDispatch,
} from 'hooks/redux/bet';

const useStyles = makeStyles(() => ({
  line: {
    background: 'linear-gradient(45deg, #298EDA, #29D2D3)',
    height: '3px',
    marginLeft: '-27px',
    width: '50%',
  },
  topic: {
    textAlign: 'center',
    fontFamily: 'SofiaPro-Black',
    color: '#08B0AA',
    fontSize: '12px',
    letterSpacing: 0.5,
    marginTop: '10px',
    marginBottom: '16px',
  },
  icon: {
    color: '#00D3DA',
    width: 15,
    height: 15,
    marginLeft: 10,
  },
}));

const SelectGame = ({ history, mode, match }) => {
  const classes = useStyles();
  const commonClasses = commonStyle();
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState();
  const [debounceSearch, setDebounceSearch] = useState('');
  const [checkedGames, setCheckedGames] = useState([]);
  const [gameList, setGameList] = useState([]);
  const [orgGames, setOrgGames] = useState(null);

  const betting = useBet();
  const league = useSelectedLeague();
  const interval = useSelectedInterval();
  const games = useGames(debounceSearch, options);
  const selectedGames = useSelectedGames();
  const selectGames = useSetGamesDispatch();
  const setBettingObject = useSetBetObjectDispatch();
  const openErrorModal = useOpenErrorModalDispatch();

  // eslint-disable-next-line
  const doDebounceSearch = useCallback(
    debounce((value) => setDebounceSearch(value), 500), [debounce, setDebounceSearch],
  );

  const handleNext = () => {
    if (checkedGames.length === 0) {
      openErrorModal({ title: 'Oops', subtitle: 'You must select at least one game before continuing.' });
      return;
    }
    selectGames(games.filter((game) => checkedGames.indexOf(game.id) >= 0));
    if (mode === 'create') {
      setBettingObject({});
      history.push('/bets/create/select-team-player');
    } else {
      history.push(`/bets/edit/${match.params.betId}/select-team-player`);
    }
  };

  const handleSelectGame = useCallback((id, checked) => {
    if (!checked) {
      if (interval === 'multiple_games') {
        const newGameIds = checkedGames.filter((gameId) => gameId !== Number(id));
        setCheckedGames(newGameIds);
      } else {
        setCheckedGames([0]);
      }
    } else if (interval === 'multiple_games') {
      setCheckedGames([...checkedGames, Number(id)]);
    } else {
      setCheckedGames([Number(id)]);
    }
  }, [setCheckedGames, checkedGames, interval]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    doDebounceSearch(e.target.value);
  };

  const handleScroll = (event) => {
    if (options) {
      const { page } = options;
      if (((event.scrollHeight - event.clientHeight) === Math.round(event.scrollTop))) {
        setOptions({
          ...options,
          page: page + 1,
        });
      }
    }
  };

  useEffect(() => {
    const ids = [];
    selectedGames.forEach((game) => {
      ids.push(game.id);
    });
    setCheckedGames(ids);
  }, [selectedGames]);

  useEffect(() => {
    if (league.id) {
      let queryFilter = '';
      queryFilter += `(betting_league_id=${league.id}) AND ((NOT status : "Cancelled")`;
      queryFilter += ' AND (NOT status : "Canceled") AND  (NOT status :"Suspended") AND (NOT status :"Awarded")';
      queryFilter += ' AND (NOT status :"Postponed") AND (NOT status :"Final")';
      queryFilter += ' AND (NOT status :"F/OT") AND (NOT status :"F/SO") AND (upcoming: true))';

      setOptions({
        hitsPerPage: 25,
        page: 0,
        filters: queryFilter,
      });
    }
  }, [league]);

  useEffect(() => {
    if (orgGames === games) {
      return;
    }
    if (options && options.page === 0) {
      setGameList([
        ...(games || []),
      ]);
    } else {
      setGameList([
        ...gameList,
        ...(games || []),
      ]);
    }
    setOrgGames(games);
  }, [games, orgGames, gameList, options]);

  return (
    <>
      <Box className={commonClasses.root2}>
        <Box className={classes.line} mb={2} />
        <Box
          display="flex"
          justifyContent="center"
        >
          <BackButton color={commonClasses.backBtn} />
          <SearchInput
            handleChange={handleSearch}
            placeholder="e.g. NFL, hocky, Lakers or LeB…"
            value={search}
          />
        </Box>
        <Box className={commonClasses.vListWrapper}>
          {gameList.length > 0 && (
            <VList
              onScroll={handleScroll}
              rowCount={gameList.length}
              rowRender={(index) => (
                <>
                  {
                    index === 0
                    && (
                    <Box textAlign="center" mb={4}>
                      {
                        mode === 'create' ? (
                          <>
                            <Typography component="p" className={classes.topic}>
                              STEP 3
                            </Typography>
                            <Typography component="h3" className={commonClasses.title2}>
                              Select game to create a bet
                            </Typography>
                          </>
                        ) : (
                          <Typography component="h3" className={commonClasses.title2}>
                            Select game to edit a bet
                          </Typography>
                        )
                      }
                    </Box>
                    )
                  }
                  <GameCard
                    selectedIds={checkedGames}
                    selectGame={handleSelectGame}
                    {...getGameInfo(gameList[index])}
                  />
                </>
              )}
            />
          )}
          {
            betting.isLoading
            && (<Loading />)
          }
        </Box>
      </Box>
      <Box mt={3} className={commonClasses.continue}>
        <OutlineButton onClick={() => handleNext()}>
          Continue
        </OutlineButton>
      </Box>
    </>
  );
};

SelectGame.defaultProps = {
  mode: 'create',
};

SelectGame.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  mode: PropTypes.string,
};

export default withRouter(SelectGame);
