import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';
import CryptoDepositCard from 'components/Deposit/CryptoDepositCard';
import DebitIcon from 'assets/images/funds/debit.png';
import WireIcon from 'assets/images/funds/wire.png';

import { useKycVerified } from 'hooks/redux/kyc';
import { useAccountOverviewInfo } from 'hooks/redux/account';
import {
  useAuthUser,
  useUserRestrictedStatus,
  useUserRestrictedAccess,
  useCheckNevadaLocation,
} from 'hooks/redux/user';

import { useOpenSuccessModalDispatch } from 'hooks/redux/modal';
import {
  usdCurrency, comingSoonMessage, nevadaBlockMessage, restrictedMessage,
} from 'utils/constants';
import ZenDialog from 'components/Dialog/ZenDialog';
import DepositGuide from './DepositGuide';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    boxShadow: '0 2px 10px 1px rgba(0,0,0,0.15)',
    borderRadius: '6px',
    background: palette.cardColor,
    marginBottom: '10px',
  },
  deposit: ({ collapsed }) => ({
    padding: '10px 0',
    margin: '0 24px',
    borderBottom: collapsed ? '1px solid #F1F1F5' : 'none',
    color: palette.secondaryColor,
    font: '18px SofiaPro-Bold',
  }),
  walletImage: {
    height: '50px',
  },
}));

const UsdDepositCard = ({
  usdtCurrency,
  history,
  collapsed,
  onCardClick,
}) => {
  const [usdtCollapsed, setUsdtCollapsed] = useState(true);
  const classes = useStyles({ collapsed });
  const [comingSoonDialogOpen, setComingSoonDialogOpen] = useState(false);

  const user = useAuthUser();
  const overview = useAccountOverviewInfo();
  const kycVerified = useKycVerified();
  const userRestricted = useUserRestrictedStatus();
  const access = useUserRestrictedAccess();
  const nevadaLocation = useCheckNevadaLocation();
  const openSuccessModal = useOpenSuccessModalDispatch();

  const handleCloseComingSoonDialog = () => {
    setComingSoonDialogOpen(false);
  };

  const handleFiatTransaction = (path) => {
    if (nevadaLocation === 'UNVERIFIED') {
      openSuccessModal({
        title: 'In-Person Registration Required',
        subtitle: nevadaBlockMessage,
        buttonText: 'Got It',
      });
      return;
    }
    if (nevadaLocation === 'N/A' && userRestricted) {
      openSuccessModal({
        title: 'Oops!',
        subtitle: restrictedMessage(user),
        buttonText: 'Got It',
      });
      return;
    }
    if (kycVerified) {
      if (path === '/funds/deposit/debit-card/add') {
        window.location.href = '/funds/deposit/debit-card/add';
      } else {
        history.push(path);
      }
    } else if (overview.status === 'new') {
      openSuccessModal({
        title: 'KYC/AML Verification Needed',
        subtitle: `In order to make a fiat deposit via debit card or wire transfer, you must first 
        go through the KYC/AML verification process.`,
        buttonText: 'Got It',
        nextPath: '/kyc/profile',
      });
    } else if (overview.status === 'pending') {
      history.push('/kyc/status');
    }
  };

  const handleCardClick = () => {
    if (collapsed) {
      onCardClick('');
    } else {
      onCardClick('usd');
    }
  };

  return (
    <Box className={classes.root}>
      <Box
        onClick={handleCardClick}
        className={classes.deposit}
        display="flex"
        alignItems="center"
        style={{ borderColor: '#298EDB' }}
      >
        <Grid item xs={4} sm={4}>
          <Box>
            <img className={classes.walletImage} src={usdCurrency.wallet_image_url} alt="navigation" />
          </Box>
        </Grid>
        <Grid item xs={8} sm={8}>
          USD
        </Grid>
      </Box>
      {
        collapsed && (
          <Box>
            <DepositGuide />
            {access.isCryptoVisible && (
              <Box>
                <Box
                  onClick={() => setUsdtCollapsed(!usdtCollapsed)}
                  className={classes.deposit}
                  display="flex"
                  alignItems="center"
                >
                  <Grid item xs={4} sm={4}>
                    <Box>
                      <img className={classes.walletImage} src={usdtCurrency.wallet_image_url} alt="navigation" />
                    </Box>
                  </Grid>
                  <Grid item xs={8} sm={8}>
                    USDT (Tether)
                  </Grid>
                </Box>
                {!usdtCollapsed && (<CryptoDepositCard currency={usdtCurrency} noBorder />)}
              </Box>
            )}
            <Box
              onClick={() => {
                if (overview?.active_cards?.length) {
                  handleFiatTransaction('/funds/deposit/debit-card/submit');
                } else {
                  handleFiatTransaction('/funds/deposit/debit-card/add');
                }
              }}
              className={classes.deposit}
              display="flex"
              alignItems="center"
            >
              <Grid item xs={4} sm={4}>
                <Box>
                  <img className={classes.walletImage} src={DebitIcon} alt="navigation" />
                </Box>
              </Grid>
              <Grid item xs={8} sm={8}>
                Debit Card
              </Grid>
            </Box>
            <Box
              onClick={() => handleFiatTransaction('/funds/deposit/wire-transfer')}
              className={classes.deposit}
              display="flex"
              alignItems="center"
            >
              <Grid item xs={4} sm={4}>
                <Box>
                  <img className={classes.walletImage} src={WireIcon} alt="navigation" />
                </Box>
              </Grid>
              <Grid item xs={8} sm={8}>
                Wire Transfer
              </Grid>
            </Box>
          </Box>
        )
      }
      { comingSoonDialogOpen && (
      <ZenDialog
        title="Feature Coming Soon!"
        message={comingSoonMessage}
        cta="Close"
        customCta="Download iOS/Android App"
        customCtaLink="zensports.com"
        onClose={handleCloseComingSoonDialog}
      />
      )}
    </Box>
  );
};
UsdDepositCard.defaultProps = {
  usdtCurrency: {},
  collapsed: false,
  onCardClick: () => {},
};

UsdDepositCard.propTypes = {
  history: PropTypes.object.isRequired,
  usdtCurrency: PropTypes.object,
  collapsed: PropTypes.bool,
  onCardClick: PropTypes.func,
};

export default withRouter(UsdDepositCard);
