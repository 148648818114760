import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles({
  root: ({ background }) => ({
    padding: 0,
    background,
  }),
});

const BackButton = ({ history, ...props }) => {
  const { color, size, page } = props;
  const classes = useStyles(props);

  const handleClick = () => {
    if (page) {
      history.push(page);
    } else {
      history.goBack();
    }
  };

  return (
    <IconButton className={classes.root} onClick={handleClick}>
      <ArrowBackIcon style={{ fontSize: size, color }} />
    </IconButton>
  );
};

BackButton.defaultProps = {
  color: 'white',
  background: 'transparent',
  size: 30,
  page: '',
};

BackButton.propTypes = {
  color: PropTypes.string,
  background: PropTypes.string,
  size: PropTypes.number,
  page: PropTypes.string,
  history: PropTypes.object.isRequired,
};

export default withRouter(BackButton);
