import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import SelectCountry from 'components/SelectCountry';

import { setKycUserInfoAction } from 'store/actions/kyc';
import CommonStyle from 'styles/common';

const KycUserCountry = ({
  kycUserCountry,
  setKycUserInfo,
}) => {
  const commonClasses = CommonStyle();
  const history = useHistory();
  const handleSelectCountry = (country) => {
    if (country.id !== kycUserCountry.id) {
      setKycUserInfo({
        country,
        state: {
          id: 0,
          name: '',
        },
      });
    }
    history.push('/kyc/profile/country');
  };

  return (
    <Page>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <BackButton color={commonClasses.backBtn} page="/kyc/profile/country" />
      </Box>
      <SelectCountry
        handleSelect={handleSelectCountry}
        countryId={kycUserCountry.id}
      />
    </Page>
  );
};

KycUserCountry.defaultProps = {
  kycUserCountry: { id: 0 },
};

KycUserCountry.propTypes = {
  kycUserCountry: PropTypes.object,
  setKycUserInfo: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  kycUserCountry: state.kyc.user?.country,
});

const mapDispatchToProps = (dispatch) => ({
  setKycUserInfo: (data) => dispatch(setKycUserInfoAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(KycUserCountry);
