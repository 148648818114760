import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Loading from 'components/Loading/Box';

const PublicRoute = ({
  component: Component,
  isLoading,
  isAuthenticated,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => (
      isAuthenticated === undefined ? (
        <Loading />
      ) : (
        isAuthenticated ? (
          <Redirect to="/bets" />
        ) : (
          <Component {...props} />
        )
      )
    )}
  />
);

PublicRoute.defaultProps = {
  isAuthenticated: undefined,
};

PublicRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]).isRequired,
  isAuthenticated: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isLoading: state.auth.isLoading,
});

export default withRouter(connect(mapStateToProps, null)(PublicRoute));
