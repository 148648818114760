import * as actionTypes from 'store/actionTypes';
import currencyService from 'services/currency.service';
import storageService from 'services/storage.service';
import balanceService from 'services/balance.service';
import config from 'utils/config';

export const getCurrencyRatesAction = (params = {}) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.GET_CURRENCY_RATES_REQUEST,
    });

    try {
      const response = await currencyService.getCurrencyRates(params);

      dispatch({
        type: actionTypes.GET_CURRENCY_RATES_SUCCESS,
        payload: response,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.GET_CURRENCY_RATES_FAIL,
      });
    }
  } else {
    dispatch({
      type: actionTypes.GET_CURRENCY_RATES_FAIL,
    });
  }
};

export const getTransactionStatusAction = (params = {}) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.GET_TRANSACTION_STATUS_REQUEST,
    });

    try {
      const response = await currencyService.getTransactionStatus(params);

      dispatch({
        type: actionTypes.GET_TRANSACTION_STATUS_SUCCESS,
        payload: response,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.GET_TRANSACTION_STATUS_FAIL,
      });
    }
  } else {
    dispatch({
      type: actionTypes.GET_TRANSACTION_STATUS_FAIL,
    });
  }
};

export const getTransactionDetailAction = (params = {}) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.GET_TRANSACTION_DETAIL_REQUEST,
    });

    try {
      const response = await currencyService.getTransactionDetail(params);

      dispatch({
        type: actionTypes.GET_TRANSACTION_DETAIL_SUCCESS,
        payload: response,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.GET_TRANSACTION_DETAIL_FAIL,
      });
    }
  } else {
    dispatch({
      type: actionTypes.GET_TRANSACTION_DETAIL_FAIL,
    });
  }
};

export const createTransactionAction = (params = {}) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.CREATE_TRANSACTION_REQUEST,
    });

    try {
      const response = await currencyService.createTransaction(params);

      dispatch({
        type: actionTypes.CREATE_TRANSACTION_SUCCESS,
        payload: response,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.CREATE_TRANSACTION_FAIL,
        payload: { message: e.error },
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.CREATE_TRANSACTION_FAIL,
    });
  }
};

export const withdrawTransactionAction = (body = {}) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.WITHDRAW_TRANSACTION_REQUEST,
    });

    try {
      const response = await balanceService.withdrawTransaction(body);

      dispatch({
        type: actionTypes.WITHDRAW_TRANSACTION_SUCCESS,
        payload: response,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.WITHDRAW_TRANSACTION_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.WITHDRAW_TRANSACTION_FAIL,
    });
  }
};

export const selectCurrencyAction = (symbol) => async (dispatch) => {
  dispatch({
    type: actionTypes.SELECT_CURRENCY,
    payload: symbol,
  });
};

export const hideFundsGuideAction = () => async (dispatch) => {
  dispatch({
    type: actionTypes.HIDE_FUNDS_GUIDE,
    payload: {},
  });
};
