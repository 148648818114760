import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

const useStyles = makeStyles({
  root: (props) => ({
    backgroundImage: `url(${props.background})`,
    backgroundSize: 'cover',
    minHeight: 60,
    borderRadius: '5px',
  }),
  title: {
    font: '17px SofiaPro-SemiBold',
    color: 'white',
    letterSpacing: -0.3,
    margin: '0px',
    textTransform: 'capitalize',
  },
});

const BetListSmallCard = ({
  image,
  title,
  onClick,
}) => {
  const classes = useStyles({
    background: image,
  });
  return (
    <Box
      className={classes.root}
      display="flex"
      alignItems="center"
      justifyContent="center"
      mb={3}
      onClick={onClick}
    >
      <Typography variant="h1" className={classes.title}>
        {title}
      </Typography>
    </Box>
  );
};

BetListSmallCard.defaultProps = {
  image: null,
  title: null,
  onClick: () => {},
};

BetListSmallCard.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
};

export default BetListSmallCard;
