import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Modal } from '@material-ui/core';

import {
  useSuccessModal, useShowSuccessModal, useCloseModalDispatch,
} from 'hooks/redux/modal';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: palette.secondaryColor,
  },
  paper: {
    background: palette.cardColor,
    padding: '18px',
    width: 300,
  },
  title: {
    margin: 0,
    fontSize: '18px',
    color: palette.secondaryColor,
  },
  content: {
    lineHeight: 1.2,
  },
  button: {
    color: '#0091FF',
    fontWeight: 'bold',
    margin: 0,
    textAlign: 'right',
    textTransform: 'uppercase',
  },
}));

const SuccessModal = ({ history }) => {
  const rootRef = React.useRef(null);
  const classes = useStyles();
  const showModal = useShowSuccessModal();
  const modal = useSuccessModal();
  const closeModal = useCloseModalDispatch();
  const handleCloseModal = () => {
    closeModal();
    if (modal.callback) {
      modal.callback();
    }
    if (modal.nextPath) {
      history.push(modal.nextPath);
    }
  };

  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={showModal}
      aria-labelledby="server-modal-title"
      aria-describedby="server-modal-description"
      className={classes.root}
      container={() => rootRef.current}
    >
      <div className={classes.paper}>
        <h2 id="server-modal-title" className={classes.title}>{modal.title}</h2>
        <p className={classes.content} id="server-modal-description" dangerouslySetInnerHTML={{ __html: modal.subtitle }} />
        <p onClick={handleCloseModal} className={classes.button}>{modal.buttonText}</p>
      </div>
    </Modal>
  );
};

SuccessModal.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(SuccessModal);
