import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => ({
  title: {
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
  },
  subtitle: {
    marginLeft: '-15px',
  },
  content: {
    fontSize: '14px',
    marginBottom: '20px',
    color: palette.secondaryColor,
  },
  resolve: {
    margin: '15px 0px',
    font: '14px SofiaPro-Bold',
    color: palette.secondaryColor,
  },
}));

const Failed = ({ exceptions }) => {
  const classes = useStyles();

  return (
    <Box>
      <Box textAlign="center" mt={3} mb={3}>
        <Typography component="h3" className={classes.title}>
          Failed KYC/AML Verification
        </Typography>
      </Box>
      <Typography className={classes.content}>
        Oh no! Looks like your KYC/AML Verification failed due to the following reasons:
      </Typography>
      <ul className={classes.subtitle}>
        {
          exceptions.map((item) => (
            <li key={item.id} className={classes.content}>
              { item.description }
              <Typography className={classes.resolve}>Resolution:</Typography>
              { item.resolution }
            </li>
          ))
        }
      </ul>
    </Box>
  );
};

Failed.defaultProps = {
  exceptions: [],
};

Failed.propTypes = {
  exceptions: PropTypes.array,
};

export default Failed;
