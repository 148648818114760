import * as actionTypes from 'store/actionTypes';
import storageService from 'services/storage.service';
import transactionService from 'services/transaction.service';
import config from 'utils/config';

// eslint-disable-next-line import/prefer-default-export
export const getTransactionsListAction = (body = {}) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.GET_TRANSACTIONS_LIST_REQUEST,
    });

    try {
      const response = await transactionService.getTransactions(body);

      dispatch({
        type: actionTypes.GET_TRANSACTIONS_LIST_SUCCESS,
        payload: response,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.GET_TRANSACTIONS_LIST_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.GET_TRANSACTIONS_LIST_FAIL,
    });
  }
};
