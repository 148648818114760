import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Box, Typography } from '@material-ui/core';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import Loading from 'components/Loading/Box';
import SeasonCard from 'components/Bet/SeasonCard';
import VList from 'components/VList';
import SearchInput from 'components/Form/SearchInput';
import commonStyle from 'styles/common';

import {
  useBet,
  useBetSchedules,
  useSelectedLeague,
  useSelectedBetSchedule,
  useSetBetObjectDispatch,
  useSetBetScheduleDispatch,
} from 'hooks/redux/bet';
import { getSeasonInfo } from 'utils/betting';

const SelectSeason = ({ history }) => {
  const [search, setSearch] = useState('');
  const [params, setParams] = useState();
  const [selectedId, setSelectedId] = useState(0);
  const [selectedSeasons, setSelectedSeasons] = useState([]);
  const commonClasses = commonStyle();

  const betting = useBet();
  const schedules = useBetSchedules(params);
  const selectedLeague = useSelectedLeague();
  const selectedSchedule = useSelectedBetSchedule();
  const setSchedule = useSetBetScheduleDispatch();
  const setBettingObject = useSetBetObjectDispatch();

  const handleNext = () => {
    setBettingObject({});
    setSchedule({
      id: selectedId,
      name: schedules.find((schedule) => schedule.id === selectedId)?.name,
    });
    history.push('/bets/create/select-team-player');
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
    setSelectedSeasons(
      schedules.filter((schedule) => schedule.name.toLowerCase().includes(
        event.target.value.toLowerCase(),
      )),
    );
  };

  const handleSelectSeason = useCallback((id, checked) => {
    if (checked) {
      setSelectedId(Number(id));
    } else {
      setSelectedId(0);
    }
  }, [setSelectedId]);

  useEffect(() => {
    setParams({
      interval: 'season',
      object_type: selectedLeague.type,
      betting_object_id: selectedLeague.id,
    });
  }, [selectedLeague]);

  useEffect(() => {
    setSelectedSeasons(schedules);
  }, [schedules]);

  useEffect(() => {
    if (selectedSchedule.id) {
      setSelectedId(selectedSchedule.id);
    }
  }, [selectedSchedule]);

  return (
    <Page>
      <Box className={commonClasses.root2}>
        <Box className={commonClasses.line2} mb={2} />
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
        >
          <BackButton color={commonClasses.backBtn} />
          <SearchInput
            handleChange={handleSearch}
            placeholder="e.g. NFL,hocky, Lakers…"
            value={search}
          />
        </Box>
        <Box className={commonClasses.vListWrapper}>
          {!betting.isLoading ? (
            <VList
              rowCount={selectedSeasons.length}
              rowRender={(index) => (
                <>
                  { index === 0
                  && (
                  <Box textAlign="center" mb={4}>
                    <Typography component="p" className={commonClasses.topic2}>
                      STEP 3
                    </Typography>
                    <Typography component="h3" className={commonClasses.title2}>
                      Select season to create a bet
                    </Typography>
                  </Box>
                  )}
                  <SeasonCard
                    selectedId={selectedId}
                    selectSeason={handleSelectSeason}
                    {...getSeasonInfo(selectedSeasons[index])}
                  />
                </>
              )}
            />
          ) : (<Loading />)}
        </Box>
      </Box>
      <Box mt={3} className={commonClasses.continue}>
        <OutlineButton onClick={() => handleNext()}>
          Continue
        </OutlineButton>
      </Box>
    </Page>
  );
};

SelectSeason.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(SelectSeason);
