import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { useOpenSuccessModalDispatch } from 'hooks/redux/modal';

const useStyles = makeStyles(({ palette }) => ({
  card: {
    borderRadius: '6px',
    boxShadow: '0 3px 10px 0 rgba(0,0,0,0.15)',
    marginTop: '15px',
    padding: '10px 0px',
    background: palette.cardColor,
    color: palette.secondaryColor,
    position: 'relative',
    overflow: 'hidden',
  },
  cardHeader: {
    padding: '8px 20px 18px 20px',
    borderBottom: '1px solid #AEAEAE',
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardTitle: {
    font: '17px SofiaPro-Bold',
    color: palette.secondaryColor,
  },
  edit: {
    font: '17px SofiaPro',
    color: palette.colorBtnTxt,
  },
  cardTopic: {
    padding: '10px 20px',
    font: '17px SofiaPro-Bold',
    color: palette.secondaryColor,
  },
  cardContent: {
    padding: '3px 20px 10px 20px',
    font: '17px SofiaPro',
    color: '#AEAEAE',
  },
  upload: {
    padding: '0px 20px',
  },
  uploadLabel: {
    display: 'block',
    border: `1px dashed ${palette.colorBtnTxt}`,
    borderRadius: '6px',
    color: palette.colorBtnTxt,
    font: '15px SofiaPro-Medium',
    letterSpacing: -0.2,
    padding: '10px 0px',
  },
  labelColor: {
    color: palette.secondaryColor,
    font: '15px SofiaPro-Bold',
  },
  redColor: {
    color: palette.dangerTextColor,
  },
  infoIcon: {
    marginLeft: '5px',
    fontSize: '20px',
    color: palette.dangerTextColor,
  },
}));

const DetailCard = ({ history, ...props }) => {
  const {
    title, items, exceptions, route, isResolve,
  } = props;
  const classes = useStyles();
  const openSuccessModal = useOpenSuccessModalDispatch();
  const openTipModal = () => {
    if (exceptions.length && exceptions[0].description && exceptions[0].resolution) {
      openSuccessModal({
        title: 'KYC Exception',
        subtitle: `${exceptions[0].description}<br/><br/><strong>Resolution:</strong><br/>${exceptions[0].resolution}<br/>`,
        buttonText: 'Got it',
      });
    }
  };
  return (
    <Box className={classes.card}>
      <Box className={classes.cardHeader}>
        <Box display="flex" alignItems="center" onClick={openTipModal}>
          <Typography className={`${classes.cardTitle} ${((exceptions.length > 0)) ? classes.redColor : ''}`}>
            { title }
          </Typography>
          {
            (exceptions.length > 0) && <InfoOutlinedIcon className={classes.infoIcon} />
          }
        </Box>
        {
          isResolve && (
            <Typography className={classes.edit} onClick={() => history.push(route, 'resolve')}>
              Edit
            </Typography>
          )
        }
      </Box>
      {items.map((item) => (
        <Box key={item.topic}>
          <Typography className={classes.cardTopic}>
            { item.topic }
          </Typography>
          {
            (item.topic === 'Front of ID' || item.topic === 'Back of ID') ? (
              <Box className={classes.upload}>
                <label htmlFor="front-id" className={`${classes.uploadLabel} ${item.value && classes.labelColor}`}>
                  <Box alignItems="center" display="flex" justifyContent="center">
                    <PhotoCameraOutlinedIcon className={classes.camera} />
                    { item.topic === 'Front of ID' && 'Front side of document uploaded!' }
                    { item.topic === 'Back of ID' && 'Back side of document uploaded!' }
                  </Box>
                </label>
              </Box>
            ) : (
              <Typography className={classes.cardContent}>
                { item.value }
              </Typography>
            )
          }
        </Box>
      ))}
    </Box>
  );
};

DetailCard.defaultProps = {
  title: '',
  items: () => [],
  exceptions: [],
  route: '',
  isResolve: false,
};

DetailCard.propTypes = {
  history: PropTypes.object.isRequired,
  items: PropTypes.array,
  title: PropTypes.string,
  route: PropTypes.string,
  exceptions: PropTypes.array,
  isResolve: PropTypes.bool,
};

export default withRouter(DetailCard);
