import React from 'react';

import Page from 'components/Page/Dashboard';
import SelectGame from 'components/Bet/Step/SelectGame';

const SelectGameWrapper = () => (
  <Page>
    <SelectGame mode="create" />
  </Page>
);

export default SelectGameWrapper;
