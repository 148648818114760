import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import MatchNode from 'components/Play/Tournament/View/MatchNode';

const DoubleElimination = ({ bracket, classes }) => {
  const bracketClasses = {
    a: 'roundOneBox',
    b: 'roundTwoBox',
    c: 'roundFinalBox',
    d: 'roundFinalBox',
  };

  const drawBrackets = (type, condition, className) => (
    <Box className={classes[className]}>
      {
            bracket?.matches?.map((round, index) => {
              const conditions = {
                a: type === 'winner' ? (index < 4) : index >= 7 && index <= 8,
                b: type === 'winner' ? (index >= 4 && index <= 5) : index >= 9 && index <= 10,
                c: type === 'winner' ? (index === 6) : index === 11,
                d: type === 'winner' ? (index === 13) : index === 12,
              };
              if ((conditions[condition]) && (round?.users?.length > 0)) {
                return (
                  <MatchNode
                    player1={round?.users?.[0]}
                    player2={round?.users?.[1]}
                    winnerId={round?.winner_id}
                    id={round?.tournament_id}
                    matchId={round?.id}
                    key={round?.id}
                  />
                );
              }
              return null;
            })
          }
    </Box>
  );
  return (
    <>
      <Box className={classes.rootBox}>
        <Box className={classes.bracketLabel}>
          { 'Winner\'s Bracket' }
        </Box>
        <Box className={classes.treeBox}>
          {Object.keys(bracketClasses).map((key) => <span key={key}>{drawBrackets('winner', key, bracketClasses[key])}</span>)}
        </Box>
        <Box className={classes.bracketLabel} mt={7}>
          { 'Loser\'s Bracket' }
        </Box>
        <Box className={classes.treeBox}>
          {Object.keys(bracketClasses).map((key) => <span key={key}>{drawBrackets('looser', key, bracketClasses[key])}</span>)}
        </Box>
      </Box>
    </>
  );
};
DoubleElimination.propTypes = {
  bracket: PropTypes.objectOf(PropTypes.any).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default DoubleElimination;
