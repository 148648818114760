import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  makeStyles,
  Typography,
  InputBase,
} from '@material-ui/core';
import FormattedNumberInput from 'components/Form/FormattedNumberInput';

import { currencyDecimalScale } from 'utils/constants';
import Clear from '@material-ui/icons/Clear';
import MasterCard from 'assets/images/funds/master_card.png';
import VisaCard from 'assets/images/funds/visa_card.png';

const useStyles = makeStyles(({ palette }) => ({
  card: (props) => ({
    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.15)',
    padding: props.collapsed ? '10px 10px 10px 20px' : '30px 10px 10px 20px',
    borderRadius: '5px',
    background: palette.cardColor,
  }),
  exp: {
    font: '17px SofiaPro',
    color: palette.secondaryColor,
  },
  expTitle: {
    font: '12px SofiaPro',
    color: palette.secondaryColor,
  },
  last: {
    font: '20px SofiaPro-Bold',
    textAlign: 'center',
    color: palette.secondaryColor,
  },
  type: {
    font: '17px SofiaPro',
    color: palette.secondaryColor,
  },
  amountText: {
    textAlign: 'center',
    color: palette.secondaryColor,
  },
  cardImg: {
    width: '50px',
  },
  icon: {
    color: palette.secondaryColor,
    position: 'absolute',
    right: 10,
    top: 10,
  },
  collapsed: {
    borderBottom: '1px solid #949494',
  },
  currency: {
    font: '25px SofiaPro-Bold',
    width: '40%',
    letterSpacing: -0.3,
    '& input': {
      color: palette.colorBtnTxt,
      textAlign: 'center',
    },
  },
}));

const DebitCard = (props) => {
  const {
    item, open, onClick, handleChange, value, handleDelete, collapsed,
  } = props;
  const classes = useStyles({ collapsed });

  return (
    <Box
      mt={2}
      className={classes.card}
      display="flex"
      flexDirection="column"
      position="relative"
      onClick={() => onClick(item.id)}
    >
      {
        !collapsed && <Clear fontSize="small" className={classes.icon} onClick={() => handleDelete(item.id)} />
      }
      <img src={item.method === 'MC' ? MasterCard : VisaCard} alt="card" className={classes.cardImg} />
      <Typography variant="h6" className={classes.last}>
        {'xxxx xxxx xxxx '}
        {item.last_4}
      </Typography>
      <Box mt={1} display="flex" width="100%" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography variant="subtitle2" className={classes.expTitle}>EXP</Typography>
          <Typography variant="h6" className={classes.exp}>{item.expiration_date}</Typography>
        </Box>
        <Typography className={classes.type}>{item.name}</Typography>
      </Box>
      {
        open && (
          <>
            <Box className={classes.collapsed} pt={2}>
              <Typography className={classes.amountText}>
                Amount in  USD to deposit
              </Typography>
              <Box display="flex" justifyContent="center" alignItems="center">
                <InputBase
                  autoComplete="off"
                  className={classes.currency}
                  value={value}
                  name="amount"
                  onChange={(e) => handleChange(e.target.value)}
                  inputComponent={FormattedNumberInput}
                  inputProps={{ 'aria-label': 'naked', decimalScale: currencyDecimalScale.usd }}
                />
              </Box>
            </Box>
          </>
        )
      }
    </Box>
  );
};

DebitCard.defaultProps = {
  item: {},
  open: false,
  onClick: () => {},
  handleChange: () => {},
  handleDelete: () => {},
  value: '',
  collapsed: false,
};

DebitCard.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func,
  handleChange: PropTypes.func,
  handleDelete: PropTypes.func,
  open: PropTypes.bool,
  value: PropTypes.string,
  collapsed: PropTypes.bool,
};

export default DebitCard;
