import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  FormControlLabel,
  Switch,
  Typography,
  Divider,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/styles';

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 40,
    height: 24,
    padding: 0,
    margin: theme.spacing(1),
    borderRadius: '29px',
  },
  switchBase: {
    padding: 2,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#63C4C6',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#63C4C6',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 20,
    height: 20,
  },
  track: {
    borderRadius: 10,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[400],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => (
  <Switch
    focusVisibleClassName={classes.focusVisible}
    disableRipple
    classes={{
      root: classes.root,
      switchBase: classes.switchBase,
      thumb: classes.thumb,
      track: classes.track,
      checked: classes.checked,
    }}
    {...props}
  />
));

const useStyles = makeStyles(({ palette }) => ({
  body: {
    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.15)',
    marginBottom: 22,
    minHeight: 60,
    padding: 20,
    background: palette.cardColor,
    color: palette.secondaryColor,
  },
  border: {
    backgroundColor: '#EEEEEE',
    width: '100%',
    margin: '10px 0',
  },
  switchBox: {
    margin: '0px',
    display: 'flex',
    width: '100%',
    font: '13px SofiaPro',
    justifyContent: 'space-between',
    '& span': {
      margin: '0px',
    },
    '& .MuiSwitch-thumb': {
      background: 'white',
    },
  },
  topic: {
    font: '15px SofiaPro-Bold',
    color: palette.secondaryColor,
    marginBottom: 10,
  },
  content: {
    font: '13px SofiaPro',
    color: '#7A869A',
    marginBottom: 15,
  },
}));

const NotificationCard = (props) => {
  const classes = useStyles();
  const {
    name, description, emailSubscribed, notificationSubscribed, onChange, id,
  } = props;
  const [emailChecked, setEmailChecked] = useState(false);
  const [notificationChecked, setNotificationChecked] = useState(false);
  const handleChange = (event) => {
    const data = {
      email: emailChecked,
      notification: notificationChecked,
    };
    if (event.target.name === 'email') {
      setEmailChecked(event.target.checked);
    } else {
      setNotificationChecked(event.target.checked);
    }
    onChange(id, {
      ...data,
      [event.target.name]: event.target.checked,
    });
  };
  useEffect(() => {
    setEmailChecked(emailSubscribed);
    setNotificationChecked(notificationSubscribed);
  }, [emailSubscribed, notificationSubscribed]);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      className={classes.body}
      flexDirection="column"
    >
      <Typography variant="h6" className={classes.topic}>
        { name }
      </Typography>
      <Typography variant="subtitle2" className={classes.content}>
        { description }
      </Typography>
      <FormControlLabel
        labelPlacement="start"
        className={classes.switchBox}
        control={<IOSSwitch checked={emailChecked} onChange={handleChange} name="email" />}
        label="Emails"
      />
      <Divider className={classes.border} />
      <FormControlLabel
        labelPlacement="start"
        className={classes.switchBox}
        control={<IOSSwitch checked={notificationChecked} onChange={handleChange} name="notification" />}
        label="Push Notifs"
      />
    </Box>
  );
};

NotificationCard.defaultProps = {
  name: '',
  description: '',
  id: null,
  emailSubscribed: false,
  notificationSubscribed: false,
};

NotificationCard.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  description: PropTypes.string,
  emailSubscribed: PropTypes.bool,
  notificationSubscribed: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default NotificationCard;
