import * as actionTypes from 'store/actionTypes';

export const openSuccessModalAction = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.OPEN_SUCCESS_MODAL,
    payload: data,
  });
};

export const openErrorModalAction = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.OPEN_ERROR_MODAL,
    payload: data,
  });
};

export const openConfirmModalAction = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.OPEN_CONFIRM_MODAL,
    payload: data,
  });
};

export const closeModalAction = () => (dispatch) => {
  dispatch({
    type: actionTypes.CLOSE_MODAL,
  });
};
