import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';

import {
  useGetTournamentDetail,
  useSetTournamentQueryDispatch,
} from 'hooks/redux/play';
import { useOpenErrorModalDispatch } from 'hooks/redux/modal';
import { useSelectCurrencyDispatch } from 'hooks/redux/currency';
import { prizeMoneyPayouts, tournamentFormatType } from 'utils/constants';
import { formattedDate } from 'utils';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0 15px',
  },
  backBtn: {
    color: theme.palette.secondaryColor,
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: theme.palette.secondaryColor,
    width: '100%',
    textAlign: 'center',
    margin: 'auto',
  },
  textTitle: {
    fontSize: '16px',
    fontWeight: 600,
    letterSpacing: -0.3,
    marginBottom: 10,
    color: theme.palette.secondaryColor,
  },
  card: {
    background: theme.palette.cardColor,
    borderRadius: '5px',
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 4px',
    paddingBottom: '15px',
    marginBottom: 20,
  },
  competitionLogo: {
    backgroundSize: 'cover',
    width: '100%',
    height: '170px',
    backgroundRepeat: 'no-repeat',
  },
  contentContainer: {
    padding: '15px 20px',
  },
  contentContainerFormat: {
    padding: '15px 20px 0',
  },
  typesText: {
    color: '#b5b5b5',
    marginTop: 5,
  },
  tagContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  tag: {
    width: '80%',
    textAlign: 'center',
    paddingLeft: '20px',
    color: theme.palette.secondaryColor,
  },
  dateContainer: {
    width: '20%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  dateText: {
    color: '#52d4e3',
    fontSize: '14px',
    lineHeight: '0px',
  },
  line: {
    background: '#d3d3db',
    height: '1px',
    margin: '15px -20px',
  },
  detail: {
    margin: '10px 20px 0',
  },
}));

const EditTournament = ({ match, history }) => {
  const classes = useStyles();

  const { id } = match.params;
  const details = useGetTournamentDetail(id);
  const setTournamentQuery = useSetTournamentQueryDispatch();
  const openErrorModal = useOpenErrorModalDispatch();
  const selectCurrency = useSelectCurrencyDispatch();

  const editTournament = (route) => {
    if (route.includes('fee')) {
      let totalPrizeMoney = 0;
      prizeMoneyPayouts[details.format].forEach((item) => {
        totalPrizeMoney += details[item.value] || 0;
      });
      if (totalPrizeMoney > 0 || details.users.length) {
        openErrorModal({
          title: 'Oops',
          subtitle: 'Competition Fees are no longer editable after a player has registered or prize money has been allocated.',
        });
        return;
      }
    }

    if (route.includes('format') && details.closed) {
      openErrorModal({
        title: 'Oops',
        subtitle: 'Competition Format is no longer editable after entries have been closed.',
      });
      return;
    }

    const query = {
      ...details,
      game_type: details.game_type_display,
      currency_id: details.currency.id,
      fee: details.fee / (10 ** details.currency.decimal_places),
      weight: details.weight === 1 ? 'handicap' : details.weight === 2 ? 'semi_handicap' : 'open',
    };
    selectCurrency(details.currency.symbol);
    setTournamentQuery(query);
    history.push(route);
  };
  return (
    <Page>
      <Box className={classes.root}>
        <Box>
          <Box>
            <BackButton color={classes.backBtn} page={`/more/play/tournament/view/${id}`} />
          </Box>
          <Box textAlign="center" mb={2} mt={2}>
            <Typography component="h3" className={classes.title}>
              Edit Competition Details
            </Typography>
          </Box>
          <Box textAlign="center" mb={4} mt={1}>
            <Typography component="p" className={classes.typesText}>
              {`Competition ID: ${id}`}
            </Typography>
          </Box>
        </Box>
        <Box className={`cardOverride ${classes.card}`}>
          <Box className={classes.contentContainer}>
            <Box className={classes.tagContainer}>
              <Box className={classes.tag}>
                Competition Information
              </Box>
              <Box className={classes.dateContainer}>
                <Typography
                  className={classes.dateText}
                  onClick={() => editTournament(`/more/play/tournament/edit/${id}/information`)}
                >
                  Edit
                </Typography>
              </Box>
            </Box>
            <Box className={classes.line} />
            <Typography className={classes.textTitle}>
              Competition Name  and Cover Image
            </Typography>
            <Typography className={classes.typesText}>
              {details?.name}
            </Typography>
          </Box>
          <Box
            className={classes.competitionLogo}
            style={{
              backgroundImage: `url(${details?.profile_url})`,
            }}
          />
          <Box className={classes.detail}>
            <Typography className={classes.textTitle}>
              Location
            </Typography>
            <Typography className={classes.typesText}>
              {details?.location}
            </Typography>
          </Box>
          <Box className={classes.detail}>
            <Typography className={classes.textTitle}>
              Which Sport Are you Playing
            </Typography>
            <Typography className={classes.typesText}>
              {details?.game_type_display}
            </Typography>
          </Box>
        </Box>
        <Box className={`cardOverride ${classes.card}`}>
          <Box className={classes.contentContainerFormat}>
            <Box className={classes.tagContainer}>
              <Box className={classes.tag}>
                Competition Format
              </Box>
              <Box className={classes.dateContainer}>
                <Typography
                  className={classes.dateText}
                  onClick={() => editTournament(`/more/play/tournament/edit/${id}/format`)}
                >
                  Edit
                </Typography>
              </Box>
            </Box>
            <Box className={classes.line} />
          </Box>
          <Box className={classes.detail}>
            <Typography className={classes.textTitle}>
              Format
            </Typography>
            <Typography className={classes.typesText}>
              {tournamentFormatType[details.format] || ''}
            </Typography>
          </Box>
          {/* <Box className={classes.detail}> */}
          {/*  <Typography className={classes.textTitle}> */}
          {/*    Type */}
          {/*  </Typography> */}
          {/*  <Typography className={classes.typesText}> */}
          {/*    {details?.weight_display} */}
          {/*  </Typography> */}
          {/* </Box> */}
        </Box>
        <Box className={`cardOverride ${classes.card}`}>
          <Box className={classes.contentContainerFormat}>
            <Box className={classes.tagContainer}>
              <Box className={classes.tag}>
                When is the competition
              </Box>
              <Box className={classes.dateContainer}>
                <Typography
                  className={classes.dateText}
                  onClick={() => editTournament(`/more/play/tournament/edit/${id}/date-time`)}
                >
                  Edit
                </Typography>
              </Box>
            </Box>
            <Box className={classes.line} />
          </Box>
          <Box className={classes.detail}>
            <Typography className={classes.textTitle}>
              Date
            </Typography>
            <Typography className={classes.typesText}>
              {details?.date_iso8601 && formattedDate(details.date_iso8601)}
            </Typography>
          </Box>
          <Box className={classes.detail}>
            <Typography className={classes.textTitle}>
              Frequency
            </Typography>
            <Typography className={classes.typesText}>
              {details?.frequency_display}
            </Typography>
          </Box>
        </Box>
        <Box className={`cardOverride ${classes.card}`}>
          <Box className={classes.contentContainerFormat}>
            <Box className={classes.tagContainer}>
              <Box className={classes.tag}>
                Competition Fees
              </Box>
              <Box className={classes.dateContainer}>
                <Typography
                  className={classes.dateText}
                  onClick={() => editTournament(`/more/play/tournament/edit/${id}/fees`)}
                >
                  Edit
                </Typography>
              </Box>
            </Box>
            <Box className={classes.line} />
          </Box>
          <Box className={classes.detail}>
            <Typography className={classes.textTitle}>
              Currency
            </Typography>
            <Typography className={classes.typesText}>
              <img src={details?.currency?.wallet_image_url} alt="" />
            </Typography>
          </Box>
          <Box className={classes.detail}>
            <Typography className={classes.textTitle}>
              Entry Fee Amount
            </Typography>
            <Typography className={classes.typesText}>
              {details?.fee_display}
            </Typography>
          </Box>
        </Box>
        <Box className={`cardOverride ${classes.card}`}>
          <Box className={classes.contentContainerFormat}>
            <Box className={classes.tagContainer}>
              <Box className={classes.tag}>
                Additional Info
              </Box>
              <Box className={classes.dateContainer}>
                <Typography
                  className={classes.dateText}
                  onClick={() => editTournament(`/more/play/tournament/edit/${id}/additional-info`)}
                >
                  Edit
                </Typography>
              </Box>
            </Box>
            <Box className={classes.line} />
          </Box>
          <Box className={classes.detail}>
            <Typography className={classes.textTitle}>
              Competition Twitch Channel
            </Typography>
            <Typography className={classes.typesText}>
              {details?.twitch_channel_url}
            </Typography>
          </Box>
          <Box className={classes.detail}>
            <Typography className={classes.textTitle}>
              Note
            </Typography>
            <Typography className={classes.typesText}>
              {details?.notes}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Page>
  );
};

EditTournament.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(EditTournament);
