import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Box, Checkbox, FormControlLabel } from '@material-ui/core';
import Circle from '@material-ui/icons/PanoramaFishEye';
import CheckCircle from '@material-ui/icons/CheckCircle';

const PrimaryCheckbox = withStyles({
  root: {
    display: 'block',
    color: '#979797',
    '&.Mui-checked': {
      color: '#63C4C6',
    },
  },
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles(({ palette }) => ({
  checkBox: {
    margin: '0px',
    color: palette.secondaryColor,
    font: '17px SofiaPro-SemiBold',
    letterSpacing: -0.3,
  },
}));

const BetTypeCard = ({
  id,
  name,
  selectedId,
  selectType,
}) => {
  const classes = useStyles();
  return (
    <Box display="flex" justifyContent="space-between" mb={1}>
      <FormControlLabel
        control={(
          <PrimaryCheckbox
            icon={<Circle />}
            checkedIcon={<CheckCircle />}
            checked={id === selectedId}
            onChange={(e) => selectType(e.currentTarget.value, e.target.checked)}
            name="checkedH"
            size="medium"
            value={id}
          />
        )}
        label={name}
        className={classes.checkBox}
      />
    </Box>
  );
};

BetTypeCard.defaultProps = {
  id: 0,
  name: null,
  selectedId: 0,
  selectType: () => {},
};

BetTypeCard.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  selectedId: PropTypes.number,
  selectType: PropTypes.func,
};

export default BetTypeCard;
