import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import { add } from 'date-fns';

import { Box, Typography, InputBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import BackButton from 'components/Buttons/BackButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import CreateBetStep6Dialog from 'components/Dialog/CreateBetStep6Dialog';

import {
  useSelectedInterval,
  useSelectedBetTime,
  useSetBetTimeDispatch,
  useSetBetOddsDispatch,
  useSelectedGames,
} from 'hooks/redux/bet';
import { useSelectCurrencyDispatch } from 'hooks/redux/currency';
import { useOpenErrorModalDispatch } from 'hooks/redux/modal';
import commonStyle from 'styles/common';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 15px 70px',
  },
  line: {
    background: 'linear-gradient(45deg, #298EDA, #29D2D3)',
    height: '3px',
    marginLeft: '-27px',
    width: '80%',
  },
  icon: {
    color: '#00D3DA',
    width: 15,
    height: 15,
    marginLeft: 10,
  },
  collapse: {
    padding: 0,
    background: palette.cardColor,
    width: '100%',
    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.15)',
    borderRadius: '4px 4px 0px 0px',
    color: palette.secondaryColor,
    marginBottom: '20px',
  },
  collapseHeader: {
    minHeight: 60,
    font: '17px SofiaPro-SemiBold',
    letterSpacing: '-0.3px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  selected: {
    background: 'linear-gradient(45deg, #298EDA, #29D2D3)',
    color: '#ffffff',
  },
  specificDateTime: {
    '& .MuiInputBase-input': {
      font: '17px SofiaPro-SemiBold',
      color: palette.secondaryColor,
      textAlign: 'center',
    },
    '& fieldset': {
      display: 'none',
    },
  },
  inputBase: {
    minHeight: 60,
    font: '17px SofiaPro',
    color: palette.secondaryColor,
    textAlign: 'center',
    padding: '5px',
    '& .MuiInputBase-input': {
      textAlign: 'center',
    },
  },
  continue: {
    width: '90%',
    margin: '50px auto 20px auto',
    // background: 'white',
  },
}));

const SelectBetTime = ({ history, match, mode }) => {
  const classes = useStyles();
  const commonClasses = commonStyle();
  const [selectedBetTime, setSelectedBetTime] = useState('');
  const [state, setState] = useState({
    datetime: null,
    days: '',
    hours: '',
    minutes: '',
    seconds: '',
  });

  const betTime = useSelectedBetTime();
  const interval = useSelectedInterval();
  const setBetTime = useSetBetTimeDispatch();
  const setBetOdds = useSetBetOddsDispatch();
  const selectCurrency = useSelectCurrencyDispatch();
  const openErrorModal = useOpenErrorModalDispatch();
  const games = useSelectedGames();
  const handleSelectState = (value, type) => {
    setState({ ...state, [type]: value });
  };

  const handleNext = () => {
    if (selectedBetTime === '') {
      openErrorModal({ title: 'Oops', subtitle: 'Select required options to move to the next step.' });
      return;
    }
    const data = {
      should_expire: true,
      current_history: '',
    };

    if (selectedBetTime === 'start_time_game') {
      data.current_history = new Date(games[0].date_time).toISOString();
    }
    if (selectedBetTime === 'manual') {
      data.should_expire = false;
    }
    if (selectedBetTime === 'specific_date_time') {
      if (state.datetime == null) {
        openErrorModal({
          title: 'Oops',
          subtitle: `You need to choose both a date and a time in order to set a 
        specific Date & Time for the expiration of this bet.`,
        });
        return;
      }
      data.current_history = new Date(state.datetime).toISOString();
    }
    if (selectedBetTime === 'static') {
      if (state.days === '' && state.hours === '' && state.minutes === '' && state.seconds === '') {
        openErrorModal({
          title: 'Oops',
          subtitle: `You need to enter in a number in either the Days, Hours, Minutes, 
        or Seconds field in order to expire the bet in a certain amount of time.`,
        });
        return;
      }
      data.current_history = add(new Date(), {
        days: state.days,
        hours: state.hours,
        minutes: state.minutes,
        seconds: state.seconds,
      });
    }

    setBetTime(data);
    if (mode === 'create') {
      selectCurrency('');
      setBetOdds({ proposed_bet_amount: null, proposed_return: null });
      history.push('/bets/create/final-submit');
    } else {
      history.push(`/bets/edit/${match.params.betId}/final-submit`);
    }
  };

  useEffect(() => {
    if (betTime && betTime.current_history) {
      handleSelectState(betTime.current_history, 'datetime');
      setSelectedBetTime('specific_date_time');
    }
    // eslint-disable-next-line
  }, [betTime]);

  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.line} mb={2} />
        <Box>
          <BackButton color={commonClasses.backBtn} />
        </Box>
        {
          mode === 'create' && (
            <Typography component="p" className={commonClasses.topic2}>
              STEP 6
            </Typography>
          )
        }
        <Box display="flex" justifyContent="center" alignItems="center" mb={4}>
          <Typography component="h3" className={commonClasses.title2}>
            When should this bet close?
            <CreateBetStep6Dialog />
          </Typography>
        </Box>
        <Box>
          {interval === 'multiple_games' && (
            <Box className={classes.collapse}>
              <Box
                className={`${classes.collapseHeader} ${selectedBetTime === 'start_time_series' ? classes.selected : ''}`}
                onClick={() => setSelectedBetTime('start_time_series')}
              >
                Start Time of Last Game in Series
              </Box>
            </Box>
          )}
          {interval === 'single_game' && (
            <Box className={classes.collapse}>
              <Box
                className={`${classes.collapseHeader} ${selectedBetTime === 'start_time_game' ? classes.selected : ''}`}
                onClick={() => setSelectedBetTime('start_time_game')}
              >
                Start Time of Game
              </Box>
            </Box>
          )}
          <Box className={classes.collapse}>
            <Box
              className={`${classes.collapseHeader} ${selectedBetTime === 'specific_date_time' ? classes.selected : ''}`}
              onClick={() => setSelectedBetTime('specific_date_time')}
            >
              Specific Date & Time
            </Box>
            {selectedBetTime === 'specific_date_time' && (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  autoComplete="off"
                  fullWidth
                  placeholder="Pick Date & Time"
                  disablePast
                  inputVariant="outlined"
                  value={state.datetime}
                  onChange={(value) => handleSelectState(value, 'datetime')}
                  className={classes.specificDateTime}
                />
              </MuiPickersUtilsProvider>
            )}
          </Box>
          <Box className={classes.collapse}>
            <Box
              className={`${classes.collapseHeader} ${selectedBetTime === 'static' ? classes.selected : ''}`}
              onClick={() => setSelectedBetTime('static')}
            >
              In X Days, Hours, Minutes, and Seconds
            </Box>
            {selectedBetTime === 'static' && (
              <Box display="flex">
                <InputBase
                  type="number"
                  autoComplete="off"
                  value={state.days}
                  className={classes.inputBase}
                  placeholder="Days"
                  inputProps={{ inputMode: 'numeric' }}
                  onChange={(e) => handleSelectState(e.target.value, 'days')}
                />
                <InputBase
                  type="number"
                  autoComplete="off"
                  value={state.hours}
                  className={classes.inputBase}
                  placeholder="Hrs"
                  inputProps={{ inputMode: 'numeric' }}
                  onChange={(e) => handleSelectState(e.target.value, 'hours')}
                />
                <InputBase
                  type="number"
                  autoComplete="off"
                  value={state.minutes}
                  className={classes.inputBase}
                  placeholder="Mins"
                  inputProps={{ inputMode: 'numeric' }}
                  onChange={(e) => handleSelectState(e.target.value, 'minutes')}
                />
                <InputBase
                  type="number"
                  autoComplete="off"
                  value={state.seconds}
                  className={classes.inputBase}
                  placeholder="Sec"
                  inputProps={{ inputMode: 'numeric' }}
                  onChange={(e) => handleSelectState(e.target.value, 'seconds')}
                />
              </Box>
            )}
          </Box>
          <Box className={classes.collapse}>
            <Box
              className={`${classes.collapseHeader} ${selectedBetTime === 'manual' ? classes.selected : ''}`}
              onClick={() => setSelectedBetTime('manual')}
            >
              Manually
            </Box>
          </Box>
        </Box>
        <Box className={classes.continue}>
          <OutlineButton onClick={() => handleNext()}>
            Continue
          </OutlineButton>
        </Box>
      </Box>
    </>
  );
};

SelectBetTime.defaultProps = {
  mode: 'create',
};

SelectBetTime.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  mode: PropTypes.string,
};

export default withRouter(SelectBetTime);
