import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import { Box, Typography } from '@material-ui/core';

import {
  currencyConvert,
  convertCurrencyAmount,
  formatCurrency,
} from 'utils/currency';
import { usdCurrency } from 'utils/constants';
import { getErrorMessage } from 'utils';

import { useOpenErrorModalDispatch } from 'hooks/redux/modal';
import { useSelectedCurrency, useMappedCurrencies } from 'hooks/redux/currency';
import { useGetTournamentQuery, useSetTournamentQueryDispatch, useUpdateTournamentDispatch } from 'hooks/redux/play';

import commonStyles from 'styles/common';
import BackButton from 'components/Buttons/BackButton';
import OutlineButton from 'components/Buttons/BorderOutlineButton';
import CurrencyDropdown from 'components/Currency/CurrencyDropdown';
import InputCurrencyExchange from 'components/Form/InputCurrencyExchange';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
  },
  stepLine: {
    height: '3px',
    background: 'linear-gradient(to right, #3597da, #36b6d6, #38c8d4)',
    margin: '-10px -10px 10px',
    width: '80%',
  },
  root: {
    padding: '0 17px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  backBtn: {
    color: theme.palette.secondaryColor,
  },
  reset: {
    color: '#101451',
    font: '18px SofiaPro-SemiBold',
    letterSpacing: -0.3,
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: theme.palette.secondaryColor,
    letterSpacing: -0.1,
  },
  topic: {
    font: '18px SofiaPro-SemiBold',
    color: theme.palette.secondaryColor,
    letterSpacing: -0.3,
  },
  selectedButton: {
    '&:not(:last-child)': {
      marginRight: '10px',
    },
  },
  selectedFormatButton: {
    width: '100%',
    '& button': {
      width: '100%',
    },
    '&:not(:last-child)': {
      marginRight: '10px',
    },
  },
  selectedHistoryButton: {
    width: '50%',
    '& button': {
      width: '100%',
    },
    '&:not(:last-child)': {
      marginRight: '10px',
    },
  },
  amount: {
    borderBottom: `1px solid ${theme.palette.secondaryColor}`,
  },
  description: {
    fontSize: '16px',
    color: theme.palette.secondaryColor,
  },
  titleDescription: {
    fontSize: '16px',
    color: '#aeaeae',
    marginTop: '15px',
    lineHeight: 1.1,
    letterSpacing: 0,
  },
  minMax: {
    font: '16px SofiaPro-SemiBold',
    color: theme.palette.secondaryColor,
    letterSpacing: -0.1,
    '& input': {
      textAlign: 'center',
    },
  },
  withdrawAddress: {
    color: theme.palette.primaryColor,
    font: '15px SofiaPro',
    textAlign: 'center',
    borderBottom: '1px solid #CCCCCC',
    '& input': {
      textAlign: 'center',
    },
  },
  currencyBox: {
    borderRadius: '6px',
    boxShadow: '0 3px 10px 0 rgba(0,0,0,0.15)',
    marginTop: '15px',
    padding: '20px 24px 10px',
    background: theme.palette.cardColor,
    color: theme.palette.secondaryColor,
  },
}));

const TournamentFees = ({ history, edit }) => {
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [cryptoCurrencyAmount, setCryptoCurrencyAmount] = useState('');
  const [usdCurrencyAmount, setUsdCurrencyAmount] = useState('');
  const classes = useStyles();
  const commonClasses = commonStyles();

  const data = useGetTournamentQuery();
  const currencySymbol = useSelectedCurrency();
  const currencies = useMappedCurrencies();
  const setTournamentQuery = useSetTournamentQueryDispatch();
  const updateTournament = useUpdateTournamentDispatch();
  const openErrorModal = useOpenErrorModalDispatch();

  const handleNext = async () => {
    try {
      const query = {
        ...data,
        currency_id: selectedCurrency.id,
        fee: convertCurrencyAmount(cryptoCurrencyAmount, selectedCurrency),
      };
      if (edit) {
        await updateTournament(data.id, {
          tournament: query,
        });
        history.push(`/more/play/tournament/view/${data.id}/edit`);
      } else {
        history.push('/more/play/tournament/create/additional-info');
      }
      setTournamentQuery(query);
    } catch (e) {
      const message = getErrorMessage(e);
      openErrorModal({ title: 'Oops', subtitle: message });
    }
  };

  const changeFeesAmount = (amount) => {
    setCryptoCurrencyAmount(amount);
    setUsdCurrencyAmount(
      formatCurrency(
        currencyConvert(
          convertCurrencyAmount(amount, selectedCurrency),
          selectedCurrency,
          usdCurrency,
        ),
        usdCurrency,
        false,
      ),
    );
  };

  const changeFeesUsdAmount = (amount) => {
    setUsdCurrencyAmount(amount);
    setCryptoCurrencyAmount(
      formatCurrency(
        currencyConvert(
          convertCurrencyAmount(amount, usdCurrency),
          usdCurrency,
          selectedCurrency,
        ),
        selectedCurrency,
        false,
      ),
    );
  };

  const handleUsdCurrencyAmount = (event) => {
    changeFeesUsdAmount(event.target.value);
  };

  const handleCryptoCurrencyAmount = (event) => {
    changeFeesAmount(event.target.value);
  };

  useEffect(() => {
    if (currencies[currencySymbol]) {
      setSelectedCurrency(currencies[currencySymbol]);
    }
  }, [currencies, currencySymbol]);

  useEffect(() => {
    if (data && data.currency_id && data.fee && selectedCurrency) {
      changeFeesAmount(data.fee);
    }
    // eslint-disable-next-line
  }, [data, selectedCurrency]);

  return (
    <Box className={classes.container}>
      <Box className={classes.stepLine} />
      <Box className={classes.root}>
        <Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <BackButton color={classes.backBtn} />
          </Box>
          <Box textAlign="center" mb={4} mt={2}>
            <Typography className={commonClasses.subTitle}>
              Step 4
            </Typography>
            <Typography variant="h3" className={classes.title}>
              Competition Entry Fee
            </Typography>
            <Typography variant="subtitle2" className={classes.titleDescription}>
              This is the entry fee that players have to pay to enter the competition.
              If it’s a free entry just enter 0.
            </Typography>
          </Box>
          <Box className={classes.currencyBox}>
            <CurrencyDropdown />
            <Box mt={2} mb={1} textAlign="center">
              <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                <Typography variant="subtitle2" className={classes.description}>
                  Entry Fee
                </Typography>
              </Box>
            </Box>
            <Box display="flex" justifyContent="flex-start" alignItems="center" mr={5} ml={1}>
              {selectedCurrency && (
                <>
                  <Box flex={1}>
                    <InputCurrencyExchange
                      currency={selectedCurrency}
                      cryptoCurrencyAmount={cryptoCurrencyAmount}
                      usdCurrencyAmount={usdCurrencyAmount}
                      onCryptoCurrencyAmountChange={handleCryptoCurrencyAmount}
                      onUsdCurrencyAmountChange={handleUsdCurrencyAmount}
                    />
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Box>
        <Box>
          <OutlineButton onClick={handleNext} disabled={!cryptoCurrencyAmount}>
            {
              edit ? 'Save' : 'Next'
            }
          </OutlineButton>
        </Box>
      </Box>
    </Box>
  );
};

TournamentFees.defaultProps = {
  edit: false,
};

TournamentFees.propTypes = {
  history: PropTypes.object.isRequired,
  edit: PropTypes.bool,
};

export default withRouter(TournamentFees);
