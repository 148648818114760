import axios from 'axios';
import config from 'utils/config';

const checkAppVersion = () => axios.get(config.appVersionUrl, {
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  },
})
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

export default {
  checkAppVersion,
};
