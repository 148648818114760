import React from 'react';

import Page from 'components/Page/Dashboard';
import SelectBetTime from 'components/Bet/Step/SelectBetTime';

const SelectBetTimeWrapper = () => (
  <Page>
    <SelectBetTime mode="create" />
  </Page>
);

export default SelectBetTimeWrapper;
