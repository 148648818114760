import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getResourceTokenAction,
  createAccountAction,
  updateAccountAction,
  updateAccountCreatedAction,
  uploadDocAction,
  setKycUserInfoAction,
  setKycDocInfoAction,
} from 'store/actions/kyc';

export const useKycResourceToken = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getResourceTokenAction());
    // eslint-disable-next-line
  }, []);
  return useSelector(({ kyc: { resourceToken } }) => resourceToken);
};

export const useKycVerified = () => useSelector(({
  auth: { user },
  account: { overview },
}) => user.kyc_verified || overview.status === 'verified');

export const useKycUserInfo = () => useSelector(({
  kyc: { user },
}) => user);

export const useKycDocInfo = () => useSelector(({
  kyc: { documentation },
}) => documentation);

export const useSetKycUserInfoDispatch = () => {
  const dispatch = useDispatch();
  return (info) => dispatch(setKycUserInfoAction(info));
};

export const useSetKycDocInfoDispatch = () => {
  const dispatch = useDispatch();
  return (data) => dispatch(setKycDocInfoAction(data));
};

export const useCreateAccountDispatch = () => {
  const dispatch = useDispatch();
  return (data) => dispatch(createAccountAction(data));
};

export const useUpdateAccountDispatch = () => {
  const dispatch = useDispatch();
  return (data) => dispatch(updateAccountAction(data));
};

export const useUpdateCreatedAccountDispatch = () => {
  const dispatch = useDispatch();
  return (data) => dispatch(updateAccountCreatedAction(data));
};

export const useUploadDocDispatch = () => {
  const dispatch = useDispatch();
  return (data) => dispatch(uploadDocAction(data));
};
