import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import BetResultInfoCard from 'components/Bet/BetResultInfoCard';
import resultIconImage from 'assets/images/icon/result.png';

import { useSingleParlay } from 'hooks/redux/parlay';

const useStyles = makeStyles(({ palette }) => ({
  icon: {
    marginLeft: '5px',
    color: '#29CCD3',
    fontSize: '20px',
  },
  subtitle: {
    color: palette.secondaryColor,
    font: '14px SofiaPro-Medium',
    paddingBottom: '5px',
  },
  results: ({ resultColor }) => ({
    backgroundColor: palette.type === 'light' ? resultColor : palette.cardBetColor,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '16px 5px',
  }),
  resultImage: {
    width: '60px',
  },
  card: {
    backgroundColor: palette.cardColor,
    width: '90%',
    borderRadius: '6px',
    padding: '10px',
    marginBottom: '10px',
  },
  cardTitle: {
    paddingBottom: '5px',
    color: palette.resultsColor,
    textAlign: 'center',
  },
  content: {
    color: palette.secondaryColor,
    font: '16px SofiaPro-Bold',
    textAlign: 'center',
  },
}));

const ParlayResultView = ({ betInfo, match }) => {
  const classes = useStyles({
    resultColor: (betInfo.status === 'results_set' || betInfo.status === 'settled') ? '#d3f2f5' : '#eeeeee',
  });
  const [resultData, setResultData] = useState({
    details: '',
    homeTeamPoints: '',
    awayTeamPoints: '',
  });

  const { parlayId } = match.params;
  const parlay = useSingleParlay(parlayId);

  useEffect(() => {
    if (parlay && parlay.proposed_bets) {
      const parlayBet = parlay.proposed_bets.find((bet) => bet.proposed_bet.id === betInfo.id);

      if (parlayBet) {
        setResultData({
          details: parlayBet.result_details,
          awayTeamPoints: parlayBet.away_points,
          homeTeamPoints: parlayBet.home_points,
        });
      }
    }
  }, [parlay, setResultData, betInfo]);

  return (
    <>
      <BetResultInfoCard betInfo={betInfo} showMaxBet={false} showOdds={false} />
      <Box className={classes.results} p={2}>
        <img alt="bet" src={resultIconImage} className={classes.resultImage} />
        <Typography variant="subtitle2" className={classes.subtitle}>Bet results</Typography>
        <Box className={classes.card}>
          {(resultData.awayTeamPoints && resultData.homeTeamPoints) && (
            <>
              <Typography variant="subtitle2" className={classes.cardTitle}>
                Result Scores
              </Typography>
              <Typography variant="subtitle2" className={classes.content}>
                {!betInfo.isSoccerGame ? betInfo.awayTeamName : betInfo.homeTeamName}
                {' '}
                {!betInfo.isSoccerGame ? resultData.awayTeamPoints : resultData.homeTeamPoints}
              </Typography>
              <Typography variant="subtitle2" className={classes.content}>
                {!betInfo.isSoccerGame ? betInfo.homeTeamName : betInfo.awayTeamName}
                {' '}
                {!betInfo.isSoccerGame ? resultData.homeTeamPoints : resultData.awayTeamPoints}
              </Typography>
            </>
          )}
          {resultData.details && (
            <>
              <Typography variant="subtitle2" className={classes.cardTitle}>
                Result Details
              </Typography>
              <Typography variant="subtitle2" className={classes.content}>
                {resultData.details}
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

ParlayResultView.defaultProps = {
  betInfo: {},
};

ParlayResultView.propTypes = {
  betInfo: PropTypes.object,
  match: PropTypes.object.isRequired,
};

export default withRouter(ParlayResultView);
