import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/styles';
import Page from 'components/Page/Auth';
import SportsGroupImage from 'assets/images/sports-group.png';

const useStyles = makeStyles(({ palette }) => ({
  dialogTitle: {
    textAlign: 'center',
    background: palette.cardColor,
    color: palette.secondaryColor,
    '& h2': {
      fontWeight: 'bold',
      fontSize: 20,
    },
  },
  dialogActions: {
    background: palette.cardColor,
  },
  dialogContent: {
    background: palette.cardColor,
    textAlign: 'center',
    '& p': {
      color: palette.secondaryColor,
      lineHeight: 1.2,
    },
    '& p span': {
      display: 'block',
      marginBottom: '19px',
    },
  },
  dialogButton: {
    background: 'none',
    boxShadow: 'none',
    textTransform: 'uppercase',
    '& span': {
      color: '#0091FF',
      fontWeight: 'bold',
    },
  },
  icon: {
    color: '#00D3DA',
    width: 15,
    height: 15,
    marginLeft: 10,
  },
}));

const MobileOnlyDialog = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
    window.location.href = 'https://zensports.com';
  };

  return (
    <Page backgroundImage={SportsGroupImage}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
          Mobile Only
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText id="alert-dialog-description">
            Our web app is only available / downloadable from the mobile version of the ZenSports website.
            Please visit
            {' '}
            <a href="https://app.zensports.com">app.zensports.com</a>
            {' '}
            from your phone to begin accessing our mobile web app.
            <span role="img" aria-label="emoji">😎</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={handleClose} className={classes.dialogButton}>
            Got It
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
};

MobileOnlyDialog.defaultProps = {};

MobileOnlyDialog.propTypes = {};

export default MobileOnlyDialog;
