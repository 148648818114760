import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import SelectState from 'components/SelectState';
import commonStyle from 'styles/common';

import { setProfileStateAction } from 'store/actions/account';

const ProfileState = ({
  profileCountry,
  profileState,
  setProfileStateInfo,
}) => {
  const classes = commonStyle();
  const history = useHistory();

  const handleSelectState = (state) => {
    if (state.id !== profileState.id) {
      setProfileStateInfo(state);
    }
    history.push('/more/setting/profile');
  };

  return (
    <Page>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <BackButton color={classes.backBtn} page="/more/setting/profile" />
      </Box>
      <SelectState
        handleSelect={handleSelectState}
        country={profileCountry}
        state={profileState}
      />
    </Page>
  );
};

ProfileState.defaultProps = {
  profileCountry: { id: 0 },
  profileState: { id: 0 },
};

ProfileState.propTypes = {
  profileCountry: PropTypes.object,
  profileState: PropTypes.object,
  setProfileStateInfo: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  profileCountry: state.account.profile?.country,
  profileState: state.account.profile?.state,
});

const mapDispatchToProps = (dispatch) => ({
  setProfileStateInfo: (data) => dispatch(setProfileStateAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileState);
