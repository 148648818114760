import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Button, Grid,
  Typography,
} from '@material-ui/core';
import Clear from '@material-ui/icons/Clear';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';

import {
  useAccountOverviewInfo,
  useGetOverviewActionDispatch,
  useDeleteFundsMethodActionDispatch,
} from 'hooks/redux/account';
import commonStyle from 'styles/common';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 15px',
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
  },
  card: {
    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.15)',
    padding: '10px',
    borderRadius: '5px',
    background: palette.cardColor,
  },
  icon: {
    color: palette.secondaryColor,
    position: 'absolute',
    right: 10,
    top: 10,
  },
  subtitle: {
    lineHeight: 2,
    font: '13px SofiaPro',
    color: palette.secondaryColor,
  },
  value: {
    lineHeight: 2,
    color: palette.secondaryColor,
    font: '15px SofiaPro',
  },
  add: {
    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.15)',
    borderRadius: '5px',
    background: palette.cardColor,
    color: palette.secondaryColor,
    minHeight: 40,
    font: '16px SofiaPro-Bold',
  },
}));

const Wires = ({ history }) => {
  const classes = useStyles();
  const commonClasses = commonStyle();
  const overview = useAccountOverviewInfo();
  const getOverview = useGetOverviewActionDispatch();
  const deleteFundsMethod = useDeleteFundsMethodActionDispatch();

  const handleDeleteWireCard = useCallback(async (id) => {
    await deleteFundsMethod({ method_id: id });
    getOverview();
  }, [deleteFundsMethod, getOverview]);

  return (
    <Page>
      <Box className={classes.root}>
        <Box>
          <BackButton className={commonClasses.backBtn} />
        </Box>
        <Box textAlign="center" mb={4}>
          <Typography component="h3" className={classes.title}>
            Manage Wire Instructions
          </Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Button className={classes.add} onClick={() => history.push('/funds/withdraw/wire-transfer/instructions')}>
            Add Wire Instructions
          </Button>
        </Box>
        {
          overview?.active_bank_accounts?.length > 0 && overview.active_bank_accounts.map((wire) => (
            <Box
              mt={2}
              key={wire.id}
              className={classes.card}
              display="flex"
              flexDirection="column"
              position="relative"
            >
              <Clear fontSize="small" className={classes.icon} onClick={() => handleDeleteWireCard(wire.id)} />
              <Box display="flex">
                <Grid item xs={6} sm={6}>
                  {
                    wire.bank_name !== null && (
                      <>
                        <Typography className={classes.subtitle}>
                          Bank Name
                        </Typography>
                        <Typography className={classes.value}>
                          {wire.bank_name}
                        </Typography>
                      </>
                    )
                  }
                  {
                    wire.routing_number !== null && (
                      <>
                        <Typography className={classes.subtitle}>
                          Routing Number
                        </Typography>
                        <Typography className={classes.value}>
                          {wire.routing_number}
                        </Typography>
                      </>
                    )
                  }
                  <Typography className={classes.subtitle}>
                    Account Number
                  </Typography>
                  <Typography className={classes.value}>
                    ***********
                    {wire.last_4}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6}>
                  {
                    wire.swift_code !== null && (
                      <>
                        <Typography className={classes.subtitle}>
                          SWIFT Code
                        </Typography>
                        <Typography className={classes.value}>
                          {wire.swift_code}
                        </Typography>
                      </>
                    )
                  }
                </Grid>
              </Box>
            </Box>
          ))
        }
      </Box>
    </Page>
  );
};

Wires.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(Wires);
