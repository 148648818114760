import React, { useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import Avatar from 'components/Avatar';
import TwitchIcon from 'components/Icons/TwitchIcon';

import { useGetTournamentDetail } from 'hooks/redux/play';
import { displayTournamentName } from 'utils/play';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0 15px',
  },
  backBtn: {
    color: theme.palette.secondaryColor,
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: theme.palette.secondaryColor,
    textAlign: 'center',
    margin: 'auto',
  },
  playersBox: {
    height: '50px',
    width: '100%',
    borderRadius: '8px 0px 0px 8px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    padding: '10px 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  watchBox: {
    display: 'flex',
    alignItems: 'center',
  },
  userNameText: {
    color: theme.palette.secondaryColor,
    fontWeight: 600,
    fontSize: '18px',
    paddingLeft: 10,
  },
  streamBox: {
    background: theme.palette.cardColor,
    borderRadius: '8px',
    display: 'flex',
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 4px',
    marginBottom: 10,
  },
  noUsersText: {
    color: theme.palette.secondaryColor,
  },
}));

const RegisteredUsers = ({ match }) => {
  const classes = useStyles();
  const { id } = match.params;
  const details = useGetTournamentDetail(id);

  // eslint-disable-next-line
  const handleClickStream = useCallback((channelUrl) => {
    if (channelUrl) {
      window.open(`https://www.twitch.tv/${channelUrl}`, '_blank');
    }
  }, []);

  return (
    <Page>
      <Box className={classes.root}>
        <Box>
          <BackButton color={classes.backBtn} />
        </Box>
        <Box textAlign="center" mb={4} mt={4}>
          <Typography component="h3" className={classes.title}>
            Registered Player
          </Typography>
        </Box>
        {
          details?.users?.length > 0
            ? details.users.map((user) => (
              <Box key={user.id} className={classes.streamBox}>
                <Box
                  className={classes.playersBox}
                >
                  <Box className={classes.watchBox}>
                    <Avatar src={user.profile_url} />
                    <Typography className={classes.userNameText}>
                      {displayTournamentName(user)}
                    </Typography>
                  </Box>
                  {user.twitch_channel_url && (
                    <TwitchIcon
                      onClick={() => handleClickStream(user.twitch_channel_url)}
                    />
                  )}
                </Box>
              </Box>
            )) : (
              <Typography align="center" className={classes.noUsersText}>
                There are no registered users.
              </Typography>
            )
        }
      </Box>
    </Page>
  );
};

RegisteredUsers.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(RegisteredUsers);
