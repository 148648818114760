/* Theme */
export const CHANGE_THEME = 'CHANGE_THEME';
export const SHOW_NAVIGATION = 'SHOW_NAVIGATION';
/* Theme */

export const CHECK_APP_VERSION = 'CHECK_APP_VERSION';

/* Auth & User */
export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAIL = 'GET_USER_FAIL';

export const GET_USER_REWARD_REQUEST = 'GET_USER_REWARD_REQUEST';
export const GET_USER_REWARD_SUCCESS = 'GET_USER_REWARD_SUCCESS';
export const GET_USER_REWARD_FAIL = 'GET_USER_REWARD_FAIL';

export const GET_USER_LOCATION_REQUEST = 'GET_USER_LOCATION_REQUEST';
export const GET_USER_LOCATION_SUCCESS = 'GET_USER_LOCATION_SUCCESS';
export const GET_USER_LOCATION_FAIL = 'GET_USER_LOCATION_FAIL';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';

export const GET_COUNTRY_REQUEST = 'GET_COUNTRY_REQUEST';
export const GET_COUNTRY_SUCCESS = 'GET_COUNTRY_SUCCESS';
export const GET_COUNTRY_FAIL = 'GET_COUNTRY_FAIL';

export const GET_REGISTRATION_LOCATION_REQUEST = 'GET_REGISTRATION_LOCATION_REQUEST';
export const GET_REGISTRATION_LOCATION_SUCCESS = 'GET_REGISTRATION_LOCATION_SUCCESS';
export const GET_REGISTRATION_LOCATION_FAIL = 'GET_REGISTRATION_LOCATION_FAIL';

export const SET_USER_COUNTRY = 'SET_USER_COUNTRY';
export const SET_USER_STATE = 'SET_USER_STATE';
export const SET_USER_REGISTRATION_COUNTRY = 'SET_USER_REGISTRATION_COUNTRY';
export const SET_USER_BIRTHDAY = 'SET_USER_BIRTHDAY';
export const SET_USER_LOCATION = 'SET_USER_LOCATION';
export const SET_USER_PHOTO = 'SET_USER_PHOTO';
export const SET_COVER_PHOTO = 'SET_COVER_PHOTO';
export const SET_USER_ODDS = 'SET_USER_ODDS';
export const SET_USER_CURRENCY_DISPLAY = 'SET_USER_CURRENCY_DISPLAY';
export const SET_USER_DISPLAY_MODE = 'SET_USER_DISPLAY_MODE';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

export const SET_USER_FAVORITES_REQUEST = 'SET_USER_FAVORITES_REQUEST';
export const SET_USER_FAVORITES_SUCCESS = 'SET_USER_FAVORITES_SUCCESS';
export const SET_USER_FAVORITES_FAIL = 'SET_USER_FAVORITES_FAIL';

export const LOG_OUT = 'LOG_OUT';

export const INSTALL_REQUEST = 'INSTALL_REQUEST';
export const INSTALL_SUCCESS = 'INSTALL_SUCCESS';
export const INSTALL_FAIL = 'INSTALL_FAIL';
export const UNINSTALL_REQUEST = 'UNINSTALL_REQUEST';
export const UNINSTALL_SUCCESS = 'UNINSTALL_SUCCESS';
export const UNINSTALL_FAIL = 'UNINSTALL_FAIL';

/* Auth & User */

/* BET */
export const GET_LEAGUES_REQUEST = 'GET_LEAGUES_REQUEST';
export const GET_LEAGUES_SUCCESS = 'GET_LEAGUES_SUCCESS';
export const GET_LEAGUES_FAIL = 'GET_LEAGUES_FAIL';

export const GET_SCHEDULES_REQUEST = 'GET_SCHEDULES_REQUEST';
export const GET_SCHEDULES_SUCCESS = 'GET_SCHEDULES_SUCCESS';
export const GET_SCHEDULES_FAIL = 'GET_SCHEDULES_FAIL';

export const GET_UPCOMING_GAMES_REQUEST = 'GET_UPCOMING_GAMES_REQUEST';
export const GET_UPCOMING_GAMES_SUCCESS = 'GET_UPCOMING_GAMES_SUCCESS';
export const GET_UPCOMING_GAMES_FAIL = 'GET_UPCOMING_GAMES_FAIL';

export const GET_BET_OBJECTS_REQUEST = 'GET_BET_OBJECTS_REQUEST';
export const GET_BET_OBJECTS_SUCCESS = 'GET_BET_OBJECTS_SUCCESS';
export const GET_BET_OBJECTS_FAIL = 'GET_BET_OBJECTS_FAIL';

export const GET_BET_TYPES_REQUEST = 'GET_BET_TYPES_REQUEST';
export const GET_BET_TYPES_SUCCESS = 'GET_BET_TYPES_SUCCESS';
export const GET_BET_TYPES_FAIL = 'GET_BET_TYPES_FAIL';

export const GET_BETS_REQUEST = 'GET_BETS_REQUEST';
export const GET_BETS_SUCCESS = 'GET_BETS_SUCCESS';
export const GET_BETS_FAIL = 'GET_BETS_FAIL';

export const GET_BET_RESULTS_REQUEST = 'GET_BET_RESULTS_REQUEST';
export const GET_BET_RESULTS_SUCCESS = 'GET_BET_RESULTS_SUCCESS';
export const GET_BET_RESULTS_FAIL = 'GET_BET_RESULTS_FAIL';

export const GET_BET_REQUEST = 'GET_BET_REQUEST';
export const GET_BET_SUCCESS = 'GET_BET_SUCCESS';
export const GET_BET_FAIL = 'GET_BET_FAIL';

export const ACCEPT_BET_REQUEST = 'ACCEPT_BET_REQUEST';
export const ACCEPT_BET_SUCCESS = 'ACCEPT_BET_SUCCESS';
export const ACCEPT_BET_FAIL = 'ACCEPT_BET_FAIL';

export const CREATE_BET_REQUEST = 'CREATE_BET_REQUEST';
export const CREATE_BET_SUCCESS = 'CREATE_BET_SUCCESS';
export const CREATE_BET_FAIL = 'CREATE_BET_FAIL';

export const GET_NOTIFICATIONS_REQUEST = 'GET_NOTIFICATIONS_REQUEST';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAIL = 'GET_NOTIFICATIONS_FAIL';

export const GET_NOTIFICATION_SETTING_REQUEST = 'GET_NOTIFICATION_SETTING_REQUEST';
export const GET_NOTIFICATION_SETTING_SUCCESS = 'GET_NOTIFICATION_SETTING_SUCCESS';
export const GET_NOTIFICATION_SETTING_FAIL = 'GET_NOTIFICATION_SETTING_FAIL';

export const UPDATE_NOTIFICATION_SETTING_REQUEST = 'UPDATE_NOTIFICATION_SETTING_REQUEST';
export const UPDATE_NOTIFICATION_SETTING_SUCCESS = 'UPDATE_NOTIFICATION_SETTING_SUCCESS';
export const UPDATE_NOTIFICATION_SETTING_FAIL = 'UPDATE_NOTIFICATION_SETTING_FAIL';

export const RESET_NOTIFICATION_BADGE_REQUEST = 'RESET_NOTIFICATION_BADGE_REQUEST';
export const RESET_NOTIFICATION_BADGE_SUCCESS = 'RESET_NOTIFICATION_BADGE_SUCCESS';
export const RESET_NOTIFICATION_BADGE_FAIL = 'RESET_NOTIFICATION_BADGE_FAIL';

export const SET_RESULTS_REQUEST = 'SET_RESULTS_REQUEST';
export const SET_RESULTS_SUCCESS = 'SET_RESULTS_SUCCESS';
export const SET_RESULTS_FAIL = 'SET_RESULTS_FAIL';

export const CLOSE_BET_REQUEST = 'CLOSE_BET_REQUEST';
export const CLOSE_BET_SUCCESS = 'CLOSE_BET_SUCCESS';
export const CLOSE_BET_FAIL = 'CLOSE_BET_FAIL';

export const DISPUTE_RESULTS_REQUEST = 'DISPUTE_RESULTS_REQUEST';
export const DISPUTE_RESULTS_SUCCESS = 'DISPUTE_RESULTS_SUCCESS';
export const DISPUTE_RESULTS_FAIL = 'DISPUTE_RESULTS_FAIL';

export const DELETE_BET_REQUEST = 'DELETE_BET_REQUEST';
export const DELETE_BET_SUCCESS = 'DELETE_BET_SUCCESS';
export const DELETE_BET_FAIL = 'DELETE_BET_FAIL';

export const EDIT_BET_REQUEST = 'EDIT_BET_REQUEST';
export const EDIT_BET_SUCCESS = 'EDIT_BET_SUCCESS';
export const EDIT_BET_FAIL = 'EDIT_BET_FAIL';

export const GET_BET_FAVORITES_REQUEST = 'GET_BET_FAVORITES_REQUEST';
export const GET_BET_FAVORITES_SUCCESS = 'GET_BET_FAVORITES_SUCCESS';
export const GET_BET_FAVORITES_FAIL = 'GET_BET_FAVORITES_FAIL';

export const SET_LEAGUE = 'SET_LEAGUE';
export const SET_INTERVALS = 'SET_INTERVALS';
export const SET_SCHEDULE = 'SET_SCHEDULE';
export const SET_GAMES = 'SET_GAMES';
export const SET_SINGLE_BET = 'SET_SINGLE_BET';
export const SET_BET_OBJECT = 'SET_BET_OBJECT';
export const SET_BET_TYPE = 'SET_BET_TYPE';
export const SET_BET_TIME = 'SET_BET_TIME';
export const SET_BET_ODDS = 'SET_BET_ODDS';
export const SET_AMOUNT = 'SET_AMOUNT';
export const SET_FILTER = 'SET_FILTER';
export const RESET_FILTER = 'RESET_FILTER';

/* BET */

/* CURRENCIES */

export const GET_CURRENCY_RATES_REQUEST = 'GET_CURRENCY_RATES_REQUEST';
export const GET_CURRENCY_RATES_SUCCESS = 'GET_CURRENCY_RATES_SUCCESS';
export const GET_CURRENCY_RATES_FAIL = 'GET_CURRENCY_RATES_FAIL';
export const CREATE_TRANSACTION_REQUEST = 'CREATE_TRANSACTION_REQUEST';
export const CREATE_TRANSACTION_SUCCESS = 'CREATE_TRANSACTION_SUCCESS';
export const CREATE_TRANSACTION_FAIL = 'CREATE_TRANSACTION_FAIL';
export const WITHDRAW_TRANSACTION_REQUEST = 'WITHDRAW_TRANSACTION_REQUEST';
export const WITHDRAW_TRANSACTION_SUCCESS = 'WITHDRAW_TRANSACTION_SUCCESS';
export const WITHDRAW_TRANSACTION_FAIL = 'WITHDRAW_TRANSACTION_FAIL';
export const GET_TRANSACTION_STATUS_REQUEST = 'GET_TRANSACTION_STATUS_REQUEST';
export const GET_TRANSACTION_STATUS_SUCCESS = 'GET_TRANSACTION_STATUS_SUCCESS';
export const GET_TRANSACTION_STATUS_FAIL = 'GET_TRANSACTION_STATUS_FAIL';
export const GET_TRANSACTION_DETAIL_REQUEST = 'GET_TRANSACTION_DETAIL_REQUEST';
export const GET_TRANSACTION_DETAIL_SUCCESS = 'GET_TRANSACTION_DETAIL_SUCCESS';
export const GET_TRANSACTION_DETAIL_FAIL = 'GET_TRANSACTION_DETAIL_FAIL';
export const DEPOSIT_DEBIT_INFO = 'DEPOSIT_DEBIT_INFO';

export const SELECT_CURRENCY = 'SELECT_CURRENCY';
export const HIDE_FUNDS_GUIDE = 'HIDE_FUNDS_GUIDE';

/* CURRENCIES */

/* TRANSACTIONS LIST */

export const GET_TRANSACTIONS_LIST_REQUEST = 'GET_TRANSACTIONS_LIST_REQUEST';
export const GET_TRANSACTIONS_LIST_SUCCESS = 'GET_TRANSACTIONS_LIST_SUCCESS';
export const GET_TRANSACTIONS_LIST_FAIL = 'GET_TRANSACTIONS_LIST_FAIL';

/* TRANSACTIONS LIST */

/* KYC */

export const GET_KYC_COUNTRIES_REQUEST = 'GET_KYC_COUNTRIES_REQUEST';
export const GET_KYC_COUNTRIES_SUCCESS = 'GET_KYC_COUNTRIES_SUCCESS';
export const GET_KYC_COUNTRIES_FAIL = 'GET_KYC_COUNTRIES_FAIL';

export const GET_KYC_TOKEN_REQUEST = 'GET_KYC_TOKEN_REQUEST';
export const GET_KYC_TOKEN_SUCCESS = 'GET_KYC_TOKEN_SUCCESS';
export const GET_KYC_TOKEN_FAIL = 'GET_KYC_TOKEN_FAIL';

export const UPLOAD_KYC_DOC_REQUEST = 'UPLOAD_KYC_DOC_REQUEST';
export const UPLOAD_KYC_DOC_SUCCESS = 'UPLOAD_KYC_DOC_SUCCESS';
export const UPLOAD_KYC_DOC_FAIL = 'UPLOAD_KYC_DOC_FAIL';

export const CREATE_KYC_USER_REQUEST = 'CREATE_KYC_USER_REQUEST';
export const CREATE_KYC_USER_SUCCESS = 'CREATE_KYC_USER_SUCCESS';
export const CREATE_KYC_USER_FAIL = 'CREATE_KYC_USER_FAIL';

export const UPDATE_KYC_USER_REQUEST = 'UPDATE_KYC_USER_REQUEST';
export const UPDATE_KYC_USER_SUCCESS = 'UPDATE_KYC_USER_SUCCESS';
export const UPDATE_KYC_USER_FAIL = 'UPDATE_KYC_USER_FAIL';

export const UPDATE_CREATED_KYC_USER_REQUEST = 'UPDATE_CREATED_KYC_USER_REQUEST';
export const UPDATE_CREATED_KYC_USER_SUCCESS = 'UPDATE_CREATED_KYC_USER_SUCCESS';
export const UPDATE_CREATED_KYC_USER_FAIL = 'UPDATE_CREATED_KYC_USER_FAIL';

export const SET_KYC_USER_INFO = 'SET_KYC_USER_INFO';
export const SET_KYC_DOC_INFO = 'SET_KYC_DOC_INFO';

/* KYC */

/* ACCOUNT */

export const GET_ACCOUNT_OVERVIEW_REQUEST = 'GET_ACCOUNT_OVERVIEW_REQUEST';
export const GET_ACCOUNT_OVERVIEW_SUCCESS = 'GET_ACCOUNT_OVERVIEW_SUCCESS';
export const GET_ACCOUNT_OVERVIEW_FAIL = 'GET_ACCOUNT_OVERVIEW_FAIL';

export const ADD_BANK_ACCOUNT_REQUEST = 'ADD_BANK_ACCOUNT_REQUEST';
export const ADD_BANK_ACCOUNT_SUCCESS = 'ADD_BANK_ACCOUNT_SUCCESS';
export const ADD_BANK_ACCOUNT_FAIL = 'ADD_BANK_ACCOUNT_FAIL';

export const GET_BANK_DEPOSIT_REQUEST = 'GET_BANK_DEPOSIT_REQUEST';
export const GET_BANK_DEPOSIT_SUCCESS = 'GET_BANK_DEPOSIT_SUCCESS';
export const GET_BANK_DEPOSIT_FAIL = 'GET_BANK_DEPOSIT_FAIL';

export const GET_CARD_TOKEN_REQUEST = 'GET_CARD_TOKEN_REQUEST';
export const GET_CARD_TOKEN_SUCCESS = 'GET_CARD_TOKEN_SUCCESS';
export const GET_CARD_TOKEN_FAIL = 'GET_CARD_TOKEN_FAIL';

export const SET_BANK_INSTRUCTION_INFO = 'SET_BANK_INSTRUCTION_INFO';

export const DELETE_FUNDS_METHOD_REQUEST = 'DELETE_FUNDS_METHOD_REQUEST';
export const DELETE_FUNDS_METHOD_SUCCESS = 'DELETE_FUNDS_METHOD_SUCCESS';
export const DELETE_FUNDS_METHOD_FAIL = 'DELETE_FUNDS_METHOD_FAIL';

export const SET_PROFILE_COUNTRY = 'SET_PROFILE_COUNTRY';
export const SET_PROFILE_STATE = 'SET_PROFILE_STATE';

/* ACCOUNT */

/* MODAL */

export const CLOSE_MODAL = 'CLOSE_MODAL';
export const OPEN_SUCCESS_MODAL = 'OPEN_SUCCESS_MODAL';
export const OPEN_ERROR_MODAL = 'OPEN_ERROR_MODAL';
export const OPEN_CONFIRM_MODAL = 'OPEN_CONFIRM_MODAL';

/* MODAL */

/* FUND */

export const GET_FUNDS_OVERVIEW_REQUEST = 'GET_FUNDS_OVERVIEW_REQUEST';
export const GET_FUNDS_OVERVIEW_SUCCESS = 'GET_FUNDS_OVERVIEW_SUCCESS';
export const GET_FUNDS_OVERVIEW_FAIL = 'GET_FUNDS_OVERVIEW_FAIL';

export const CREATE_CREDIT_CARD_DEPOSIT_REQUEST = 'CREATE_CREDIT_CARD_DEPOSIT_REQUEST';
export const CREATE_CREDIT_CARD_DEPOSIT_SUCCESS = 'CREATE_CREDIT_CARD_DEPOSIT_SUCCESS';
export const CREATE_CREDIT_CARD_DEPOSIT_FAIL = 'CREATE_CREDIT_CARD_DEPOSIT_FAIL';

/* REFERRAL */

export const GET_BONUSES_LIST_REQUEST = 'GET_BONUSES_LIST_REQUEST';
export const GET_BONUSES_LIST_SUCCESS = 'GET_BONUSES_LIST_SUCCESS';
export const GET_BONUSES_LIST_FAIL = 'GET_BONUSES_LIST_FAIL';

/* REFERRAL */

/* EXCHANGE */
export const GET_MARKETS_DETAILS_REQUEST = 'GET_MARKETS_DETAILS_REQUEST';
export const GET_MARKETS_DETAILS_SUCCESS = 'GET_MARKETS_DETAILS_SUCCESS';
export const GET_MARKETS_DETAILS_FAIL = 'GET_MARKETS_DETAILS_FAIL';

export const CREATE_EXCHANGE_ORDER_REQUEST = 'CREATE_EXCHANGE_ORDER_REQUEST';
export const CREATE_EXCHANGE_ORDER_SUCCESS = 'CREATE_EXCHANGE_ORDER_SUCCESS';
export const CREATE_EXCHANGE_ORDER_FAIL = 'CREATE_EXCHANGE_ORDER_FAIL';

export const HIDE_ROLLING_OVER = 'HIDE_ROLLING_OVER';

/* MARKETS */
export const GET_MARKETS_REQUEST = 'GET_MARKETS_REQUEST';
export const GET_MARKETS_SUCCESS = 'GET_MARKETS_SUCCESS';
export const GET_MARKETS_FAIL = 'GET_MARKETS_FAIL';

export const GET_MARKET_CHANGE_REQUEST = 'GET_MARKET_CHANGE_REQUEST';
export const GET_MARKET_CHANGE_SUCCESS = 'GET_MARKET_CHANGE_SUCCESS';
export const GET_MARKET_CHANGE_FAIL = 'GET_MARKET_CHANGE_FAIL';

/* ORDERS LIST */

export const GET_ORDERS_LIST_REQUEST = 'GET_ORDERS_LIST_REQUEST';
export const GET_ORDERS_LIST_SUCCESS = 'GET_ORDERS_LIST_SUCCESS';
export const GET_ORDERS_LIST_FAIL = 'GET_ORDERS_LIST_FAIL';

/* CANCEL ORDER */

export const CANCEL_ORDER_REQUEST = 'CANCEL_ORDER_REQUEST';
export const CANCEL_ORDER_SUCCESS = 'CANCEL_ORDER_SUCCESS';
export const CANCEL_ORDER_FAIL = 'CANCEL_ORDER_FAIL';

/* CANCEL ALL ORDERS */

export const CANCEL_ALL_ORDERS_REQUEST = 'CANCEL_ALL_ORDERS_REQUEST';
export const CANCEL_ALL_ORDERS_SUCCESS = 'CANCEL_ALL_ORDERS_SUCCESS';
export const CANCEL_ALL_ORDERS_FAIL = 'CANCEL_ALL_ORDERS_FAIL';

/* EXCHANGE */

/* Play */

export const GET_PLAY_REQUEST = 'GET_PLAY_REQUEST';
export const GET_PLAY_SUCCESS = 'GET_PLAY_SUCCESS';
export const GET_PLAY_FAIL = 'GET_PLAY_FAIL';

export const GET_SPORTS_REQUEST = 'GET_SPORTS_REQUEST';
export const GET_SPORTS_SUCCESS = 'GET_SPORTS_SUCCESS';
export const GET_SPORTS_FAIL = 'GET_SPORTS_FAIL';

export const CREATE_TOURNAMENT_REQUEST = 'CREATE_TOURNAMENT_REQUEST';
export const CREATE_TOURNAMENT_SUCCESS = 'CREATE_TOURNAMENT_SUCCESS';
export const CREATE_TOURNAMENT_FAIL = 'CREATE_TOURNAMENT_FAIL';

export const UPDATE_TOURNAMENT_REQUEST = 'UPDATE_TOURNAMENT_REQUEST';
export const UPDATE_TOURNAMENT_SUCCESS = 'UPDATE_TOURNAMENT_SUCCESS';
export const UPDATE_TOURNAMENT_FAIL = 'UPDATE_TOURNAMENT_FAIL';

export const SET_SELECTED_PLAY_FILTER = 'SET_SELECTED_PLAY_FILTER';
export const SET_TOURNAMENT_QUERY = 'SET_TOURNAMENT_QUERY';

export const GET_TOURNAMENT_DETAILS_REQUEST = 'GET_TOURNAMENT_DETAILS_REQUEST';
export const GET_TOURNAMENT_DETAILS_SUCCESS = 'GET_TOURNAMENT_DETAILS_SUCCESS';
export const GET_TOURNAMENT_DETAILS_FAIL = 'GET_TOURNAMENT_DETAILS_FAIL';

export const GET_TOURNAMENT_USERS_REQUEST = 'GET_TOURNAMENT_USERS_REQUEST';
export const GET_TOURNAMENT_USERS_SUCCESS = 'GET_TOURNAMENT_USERS_SUCCESS';
export const GET_TOURNAMENT_USERS_FAIL = 'GET_TOURNAMENT_USERS_FAIL';

export const CURRENT_USER_IN_TOURNAMENT_REQUEST = 'CURRENT_USER_IN_TOURNAMENT_REQUEST';
export const CURRENT_USER_IN_TOURNAMENT_SUCCESS = 'CURRENT_USER_IN_TOURNAMENT_SUCCESS';
export const CURRENT_USER_IN_TOURNAMENT_FAIL = 'CURRENT_USER_IN_TOURNAMENT_FAIL';

export const GET_UPCOMING_MATCHES_REQUEST = 'GET_UPCOMING_MATCHES_REQUEST';
export const GET_UPCOMING_MATCHES_SUCCESS = 'GET_UPCOMING_MATCHES_SUCCESS';
export const GET_UPCOMING_MATCHES_FAIL = 'GET_UPCOMING_MATCHES_FAIL';

export const GET_IN_PROGRESS_MATCHES_REQUEST = 'GET_IN_PROGRESS_MATCHES_REQUEST';
export const GET_IN_PROGRESS_MATCHES_SUCCESS = 'GET_IN_PROGRESS_MATCHES_SUCCESS';
export const GET_IN_PROGRESS_MATCHES_FAIL = 'GET_IN_PROGRESS_MATCHES_FAIL';

export const GET_COMPLETED_MATCHES_REQUEST = 'GET_COMPLETED_MATCHES_REQUEST';
export const GET_COMPLETED_MATCHES_SUCCESS = 'GET_COMPLETED_MATCHES_SUCCESS';
export const GET_COMPLETED_MATCHES_FAIL = 'GET_COMPLETED_MATCHES_FAIL';

export const GET_PRIZE_MONEY_PLAYER_REQUEST = 'GET_PRIZE_MONEY_PLAYER_REQUEST';
export const GET_PRIZE_MONEY_PLAYER_SUCCESS = 'GET_PRIZE_MONEY_PLAYER_SUCCESS';
export const GET_PRIZE_MONEY_PLAYER_FAIL = 'GET_PRIZE_MONEY_PLAYER_FAIL';

export const GET_PRIZE_MONEY_DIRECTOR_REQUEST = 'GET_PRIZE_MONEY_DIRECTOR_REQUEST';
export const GET_PRIZE_MONEY_DIRECTOR_SUCCESS = 'GET_PRIZE_MONEY_DIRECTOR_SUCCESS';
export const GET_PRIZE_MONEY_DIRECTOR_FAIL = 'GET_PRIZE_MONEY_DIRECTOR_FAIL';

export const SET_MATCH_WINNER_REQUEST = 'SET_MATCH_WINNER_REQUEST';
export const SET_MATCH_WINNER_SUCCESS = 'SET_MATCH_WINNER_SUCCESS';
export const SET_MATCH_WINNER_FAIL = 'SET_MATCH_WINNER_FAIL';

export const CLOSE_TOURNAMENT_REQUEST = 'CLOSE_TOURNAMENT_REQUEST';
export const CLOSE_TOURNAMENT_SUCCESS = 'CLOSE_TOURNAMENT_SUCCESS';
export const CLOSE_TOURNAMENT_FAIL = 'CLOSE_TOURNAMENT_FAIL';

export const DELETE_TOURNAMENT_REQUEST = 'DELETE_TOURNAMENT_REQUEST';
export const DELETE_TOURNAMENT_SUCCESS = 'DELETE_TOURNAMENT_SUCCESS';
export const DELETE_TOURNAMENT_FAIL = 'DELETE_TOURNAMENT_FAIL';

export const REGISTER_FOR_TOURNAMENT_REQUEST = 'REGISTER_FOR_TOURNAMENT_REQUEST';
export const REGISTER_FOR_TOURNAMENT_SUCCESS = 'REGISTER_FOR_TOURNAMENT_SUCCESS';
export const REGISTER_FOR_TOURNAMENT_FAIL = 'REGISTER_FOR_TOURNAMENT_FAIL';

export const UPDATE_TWITCH_REQUEST = 'UPDATE_TWITCH_REQUEST';
export const UPDATE_TWITCH_SUCCESS = 'UPDATE_TWITCH_SUCCESS';
export const UPDATE_TWITCH_FAIL = 'UPDATE_TWITCH_FAIL';

export const GET_TWITCH_REQUEST = 'GET_TWITCH_REQUEST';
export const GET_TWITCH_SUCCESS = 'GET_TWITCH_SUCCESS';
export const GET_TWITCH_FAIL = 'GET_TWITCH_FAIL';

export const GET_TOURNAMENT_MATCH_DETAILS_REQUEST = 'GET_TOURNAMENT_MATCH_DETAILS_REQUEST';
export const GET_TOURNAMENT_MATCH_DETAILS_SUCCESS = 'GET_TOURNAMENT_MATCH_DETAILS_SUCCESS';
export const GET_TOURNAMENT_MATCH_DETAILS_FAIL = 'GET_TOURNAMENT_MATCH_DETAILS_FAIL';

export const SET_MATCH_WINNER_TOURNAMENT_REQUEST = 'SET_MATCH_WINNER_TOURNAMENT_REQUEST';
export const SET_MATCH_WINNER_TOURNAMENT_SUCCESS = 'SET_MATCH_WINNER_TOURNAMENT_SUCCESS';
export const SET_MATCH_WINNER_TOURNAMENT_FAIL = 'SET_MATCH_WINNER_TOURNAMENT_FAIL';

export const GET_BRACKETS_REQUEST = 'GET_BRACKETS_REQUEST';
export const GET_BRACKETS_SUCCESS = 'GET_BRACKETS_SUCCESS';
export const GET_BRACKETS_FAIL = 'GET_BRACKETS_FAIL';

/* Parlay */
export const GET_PARLAY_REQUEST = 'GET_PARLAY_REQUEST';
export const GET_PARLAY_SUCCESS = 'GET_PARLAY_SUCCESS';
export const GET_PARLAY_FAIL = 'GET_PARLAY_FAIL';

export const SUBMIT_PARLAY_REQUEST = 'SUBMIT_PARLAY_REQUEST';
export const SUBMIT_PARLAY_SUCCESS = 'SUBMIT_PARLAY_SUCCESS';
export const SUBMIT_PARLAY_FAIL = 'SUBMIT_PARLAY_FAIL';

export const SET_RESULT_PARLAY_REQUEST = 'SET_RESULT_PARLAY_REQUEST';
export const SET_RESULT_PARLAY_SUCCESS = 'SET_RESULT_PARLAY_SUCCESS';
export const SET_RESULT_PARLAY_FAIL = 'SET_RESULT_PARLAY_FAIL';

export const EXCLUDE_BET_PARLAY_REQUEST = 'EXCLUDE_BET_PARLAY_REQUEST';
export const EXCLUDE_BET_PARLAY_SUCCESS = 'EXCLUDE_BET_PARLAY_SUCCESS';
export const EXCLUDE_BET_PARLAY_FAIL = 'EXCLUDE_BET_PARLAY_FAIL';

export const EXCLUDE_PARLAY_ITEMS = 'EXCLUDE_PARLAY_ITEMS';
export const SET_RESULT_PARLAY_ITEMS = 'SET_RESULT_PARLAY_ITEMS';
export const ADD_PARLAY_ITEM = 'ADD_PARLAY_ITEM';
export const DELETE_PARLAY_ITEM = 'DELETE_PARLAY_ITEM';
export const CLEAR_PARLAY_ITEMS = 'CLEAR_PARLAY_ITEMS';
