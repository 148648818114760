import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

import BlankImage from 'assets/images/profile.png';

const useStyles = makeStyles(({ palette }) => ({
  card: ({ backgroundColor, textColor }) => ({
    backgroundColor: palette.type === 'dark' ? palette.cardColor : backgroundColor,
    color: palette.type === 'dark' ? palette.secondaryColor : textColor,
    padding: '14px 24px 10px 24px',
    marginBottom: '24px',
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 4px',
    borderRadius: '5px 5px 0px 0px',
  }),
  image: {
    height: '64px',
    width: '64px',
    borderRadius: '50%',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      width: '100%',
      height: 'auto',
    },
  },
  name: {
    font: '18px SofiaPro-SemiBold',
    letterSpacing: -0.3,
  },
  type: {
    font: '13px SofiaPro',
    letterSpacing: -0.1,
  },
  description: {
    font: '13px SofiaPro',
    letterSpacing: -0.1,
  },
  betType: {
    font: '13px SofiaPro-Black',
    letterSpacing: -0.1,
  },
}));

const BetSummaryCard = ({ betInfo }) => {
  const {
    title, type, description, time, imageUrl, backgroundColor, textColor,
  } = betInfo;
  const classes = useStyles({ backgroundColor, textColor });

  return (
    <Box className={classes.card}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Box className={classes.image}>
          <img src={imageUrl || BlankImage} alt="Team" />
        </Box>
        <Box pl={3} flex={1}>
          <Typography variant="subtitle1" className={classes.name}>
            {title}
          </Typography>
          <Typography variant="subtitle1" className={classes.type}>
            {type}
          </Typography>
          <Box mt={1}>
            <Typography variant="subtitle1" className={classes.description}>
              {description}
            </Typography>
            <Typography variant="subtitle1" className={classes.description}>
              {time}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

BetSummaryCard.propTypes = {
  betInfo: PropTypes.object.isRequired,
};

export default BetSummaryCard;
