import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(({ palette }) => ({
  dialogTitle: {
    background: palette.cardColor,
    color: palette.secondaryColor,
    '& h2': {
      fontWeight: 'bold',
      fontSize: 20,
    },
  },
  dialogActions: {
    background: palette.cardColor,
  },
  dialogContent: {
    background: palette.cardColor,
    '& p': {
      color: palette.secondaryColor,
      lineHeight: 1.2,
    },
    '& p span': {
      display: 'block',
      marginBottom: '19px',
    },
  },
  dialogButton: {
    background: 'none',
    boxShadow: 'none',
    textTransform: 'uppercase',
    '& span': {
      color: '#0091FF',
      fontWeight: 'bold',
    },
  },
  dialogButtonClose: {
    background: 'none',
    boxShadow: 'none',
    textTransform: 'uppercase',
    '& span': {
      color: '#AEAEAE',
      fontWeight: 'bold',
    },
  },
  icon: {
    color: '#00D3DA',
    width: 15,
    height: 15,
    marginLeft: 10,
  },
}));

const ZenDialog = ({
  title,
  message,
  cta,
  onClose,
  customDialog,
  customCta,
  customCtaLink,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);

  let messageTxt = message;
  if (customDialog === 'potentialBalance') {
    messageTxt = message.split('\n').map((str) => <span>{str.trim()}</span>);
  }

  const handleClose = () => {
    onClose();
    setOpen(false);
  };

  const handleCustomLink = () => {
    setOpen(false);
    window.open(`https://${customCtaLink}`);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
          {title}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText id="alert-dialog-description">
            {messageTxt}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          {(customCta !== '') ? (
            <>
              <Button onClick={handleCustomLink} className={classes.dialogButton}>
                {customCta}
              </Button>
              <Button onClick={handleClose} className={classes.dialogButtonClose}>
                {cta}
              </Button>
            </>
          ) : (
            <Button onClick={handleClose} className={classes.dialogButton}>
              {cta}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

ZenDialog.defaultProps = {
  title: '',
  message: '',
  cta: 'Got It!',
  onClose: () => {},
  customDialog: '',
  customCta: '',
  customCtaLink: '',
};

ZenDialog.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  cta: PropTypes.string,
  onClose: PropTypes.func,
  customDialog: PropTypes.string,
  customCta: PropTypes.string,
  customCtaLink: PropTypes.string,
};

export default ZenDialog;
