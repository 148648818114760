import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Box, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';

import { supportedAllCurrencies } from 'utils/constants';
import { formatCurrency, getFormattedCurrency } from 'utils/currency';

import { useMappedCurrencies, useSelectCurrencyDispatch } from 'hooks/redux/currency';
import commonStyle from 'styles/common';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 15px',
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
  },
  cardTitle: {
    color: palette.secondaryColor,
  },
  wallet: {
    minHeight: '95px',
    borderRadius: '6px',
    boxShadow: '0 3px 10px 0 rgba(0,0,0,0.15)',
    marginTop: '15px',
    background: palette.cardColor,
    padding: '0 24px',
    color: palette.secondaryColor,
    font: '18px SofiaPro-Bold',
  },
  balance: {
    font: '15px SofiaPro-SemiBold',
  },
  content: {
    marginTop: '25px',
    textAlign: 'center',
    padding: '10px 15px',
    backgroundColor: 'rgb(238,238,238,0.5)',
    color: '#AEAEAE',
    font: '12px SofiaPro',
  },
}));

const Wallet = ({ history }) => {
  const classes = useStyles();
  const commonClasses = commonStyle();
  const mappedCurrencies = useMappedCurrencies();
  const selectCurrency = useSelectCurrencyDispatch();
  const handleNext = (currency) => {
    selectCurrency(currency.symbol);
    if (history.location.state.prevPath.includes('withdraw')) {
      history.push(`/funds/withdraw/${currency.symbol}`);
    } else if (history.location.state.prevPath.includes('transaction')) {
      history.push(`/funds/transaction/${currency.symbol}`);
    } else {
      history.goBack();
    }
  };

  return (
    <Page>
      <Box className={classes.root}>
        <Box>
          <BackButton color={commonClasses.backBtn} />
        </Box>
        <Box textAlign="center" mb={4}>
          <Typography component="h3" className={classes.title}>
            Wallets
          </Typography>
        </Box>
        <Box>
          {supportedAllCurrencies.map((currency) => (
            <Box
              onClick={() => handleNext(currency)}
              className={classes.wallet}
              key={currency.symbol}
              display="flex"
              alignItems="center"
            >
              <Grid item xs={4} sm={4}>
                <Box>
                  <img src={currency.image} alt="Currency" />
                </Box>
              </Grid>
              <Grid item xs={8} sm={8}>
                <Box>
                  <Typography className={classes.cardTitle}>
                    {currency.name}
                  </Typography>
                  {mappedCurrencies[currency.symbol] && (
                    <>
                      <Typography className={classes.balance} style={{ color: currency.color }}>
                        {
                          formatCurrency(
                            mappedCurrencies[currency.symbol].balance,
                            mappedCurrencies[currency.symbol],
                          )
                        }
                      </Typography>
                      {currency.symbol !== 'usd' && (
                        <Typography className={classes.balance} style={{ color: currency.color }}>
                          (
                          {
                            getFormattedCurrency(
                              mappedCurrencies[currency.symbol].balance,
                              mappedCurrencies[currency.symbol],
                              'usd',
                            )
                          }
                          )
                        </Typography>
                      )}
                    </>
                  )}
                </Box>
              </Grid>
            </Box>
          ))}
        </Box>
        <Box pl={3} pr={3}>
          <Typography className={classes.content}>
            Select which wallet you’d like to display and use within Zensports.
          </Typography>
        </Box>
      </Box>
    </Page>
  );
};

Wallet.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(Wallet);
