import React, {
  useEffect, useCallback, useState, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Route, Redirect, withRouter, useLocation,
} from 'react-router-dom';

import Loading from 'components/Loading/Box';
import { getCurrencyRatesAction } from 'store/actions/currency';
import {
  /* getUserAction, */ logoutAction,
} from 'store/actions/auth';
import {
  useInstallDispatch, useUserRestrictedStatus, useCheckNevadaLocation, useUpdateUserLocation,
} from 'hooks/redux/user';
import { useOpenSuccessModalDispatch, useOpenErrorModalDispatch } from 'hooks/redux/modal';
import { restrictedMessage, nevadaBlockMessage, activeMessage } from 'utils/constants';

const PrivateRoute = ({
  component: Component,
  user,
  history,
  isAuthenticated,
  isLoading,
  isNewUser,
  isWelcome,
  isRates,
  isRestricted,
  isInstalled,
  getCurrencyRates,
  // getUser,
  logout,
  ...rest
}) => {
  const install = useInstallDispatch();
  const userRestricted = useUserRestrictedStatus();
  const nevadaLocation = useCheckNevadaLocation();
  const updateUserLocation = useUpdateUserLocation();
  const openSuccessModal = useOpenSuccessModalDispatch();
  const openErrorModal = useOpenErrorModalDispatch();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const logoutTimer = useRef(-1);
  const modalTimer = useRef(-1);
  const locationTimer = useRef(-1);
  // const userTimer = useRef(-1);

  const getCurrencies = useCallback(() => {
    getCurrencyRates();
  }, [getCurrencyRates]);

  const checkActiveStatus = useCallback(() => {
    clearTimeout(modalTimer.current);
    clearTimeout(logoutTimer.current);
    logoutTimer.current = setTimeout(() => {
      logout();
    }, 30 * 60 * 1000);
    modalTimer.current = setTimeout(() => {
      setShowModal(true);
      openErrorModal({
        title: 'Are you still there?',
        subtitle: activeMessage,
        buttonText: 'Yes, I\'m Here',
        callback: () => {
          setShowModal(false);
          clearTimeout(logoutTimer.current);
        },
      });
    }, 5 * 60 * 1000);
  }, [logout, openErrorModal]);

  const checkRestrictedStatus = useCallback(() => {
    if (nevadaLocation === 'UNVERIFIED') {
      openSuccessModal({
        title: 'In-Person Registration Required',
        subtitle: nevadaBlockMessage,
        buttonText: 'Got It',
      });
      history.push('/bets');
    } else if (nevadaLocation === 'N/A' && userRestricted) {
      openSuccessModal({
        title: 'Oops!',
        subtitle: restrictedMessage(user),
        buttonText: 'Got It',
      });
      history.push('/bets');
    }
  }, [userRestricted, nevadaLocation, history, openSuccessModal, user]);

  const checkUserLocation = useCallback(() => {
    clearInterval(locationTimer.current);
    updateUserLocation(user);
    locationTimer.current = setInterval(() => {
      updateUserLocation(user);
    }, 5000);
  }, [updateUserLocation, user]);

  // const pollingUserInfo = useCallback(() => {
  //   clearInterval(userTimer.current);
  //   userTimer.current = setInterval(() => {
  //     getUser();
  //   }, 30000);
  // }, [getUser]);

  useEffect(() => {
    if (isAuthenticated && !isNewUser && !isWelcome) {
      if (!showModal) {
        checkActiveStatus();
        document.addEventListener('click', checkActiveStatus);
      } else {
        document.removeEventListener('click', checkActiveStatus);
      }
    }

    return () => {
      document.removeEventListener('click', checkActiveStatus);
    };
    // eslint-disable-next-line
  }, [isAuthenticated, location.pathname, showModal]);

  useEffect(() => {
    if (isAuthenticated && !isInstalled) {
      install();
    }

    if (isAuthenticated && !isNewUser && !isWelcome) {
      if (isRates) {
        getCurrencies();
      }

      if (isRestricted) {
        checkRestrictedStatus();
      }

      checkUserLocation();
      // pollingUserInfo();
    }

    return () => {
      clearInterval(locationTimer.current);
      // clearInterval(userTimer.current);
    };
    // eslint-disable-next-line
  }, [isAuthenticated, location.pathname]);

  return (
    <Route
      {...rest}
      render={(props) => (
        isAuthenticated === undefined ? (
          <Loading />
        ) : (
          !isAuthenticated ? (
            <Redirect to="/" />
          ) : (
            (isNewUser && !isWelcome) ? (
              <Redirect to="/welcome" />
            ) : (
              <Component {...props} />
            )
          )
        )
      )}
    />
  );
};

PrivateRoute.defaultProps = {
  isWelcome: false,
  isRates: false,
  isRestricted: false,
  isAuthenticated: undefined,
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]).isRequired,
  isAuthenticated: PropTypes.bool,
  isNewUser: PropTypes.bool.isRequired,
  isWelcome: PropTypes.bool,
  isRates: PropTypes.bool,
  isRestricted: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  isInstalled: PropTypes.bool.isRequired,
  getCurrencyRates: PropTypes.func.isRequired,
  // getUser: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isInstalled: state.auth.isInstalled,
  isNewUser: state.auth.user.is_new,
  isLoading: state.auth.isLoading,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  getCurrencyRates: (data) => dispatch(getCurrencyRatesAction(data)),
  // getUser: () => dispatch(getUserAction(false)),
  logout: () => dispatch(logoutAction()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PrivateRoute));
