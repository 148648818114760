import httpService from './http.service';

const isValidReferralBonus = (code) => httpService
  .get('/referral_bonus/is_valid', {}, { code })
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const getReferralBonusesList = (code) => httpService
  .get('/referral_bonus/list_bonuses', {}, { code })
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

export default {
  isValidReferralBonus,
  getReferralBonusesList,
};
