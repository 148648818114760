import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Page from 'components/Page/Dashboard';
import VList from 'components/VList';
import BackButton from 'components/Buttons/BackButton';
import SearchInput from 'components/Form/SearchInput';

import {
  useSportList,
  useSetTournamentQueryDispatch,
  useGetTournamentQuery,
} from 'hooks/redux/play';

const useStyles = makeStyles((theme) => ({
  title: {
    font: '30px SofiaPro-Bold',
    color: theme.palette.secondaryColor,
  },
  backBtn: {
    color: theme.palette.secondaryColor,
  },
  countryList: {
    flex: 1,
  },
  countryItem: {
    fontSize: '17px',
    fontWeight: '600',
    lineHeight: '25px',
    color: theme.palette.secondaryColor,
    textAlign: 'center',
    marginBottom: '30px',
  },
  countryActiveItem: {
    color: '#63C4C6',
  },
}));

const SelectSport = () => {
  const classes = useStyles();
  const sportsList = useSportList({
    hitsPerPage: 1000,
    page: 0,
  });
  const data = useGetTournamentQuery();
  const setTournamentQuery = useSetTournamentQueryDispatch();
  const [search, setSearch] = useState('');
  const [searchedSports, setSearchedSports] = useState([]);
  const history = useHistory();
  const handleSelectCountry = (sport) => {
    if (data) {
      const query = {
        ...data,
        game_type: sport.name,
      };
      setTournamentQuery(query);
    } else {
      const query = {
        game_type: sport.name,
      };
      setTournamentQuery(query);
    }
    history.goBack();
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
    const filteredSports = sportsList.filter((sport) => sport.name.toLowerCase().includes(
      event.target.value.toLowerCase(),
    ));
    setSearchedSports(filteredSports);
  };

  useEffect(() => {
    if (sportsList && sportsList.length) {
      setSearchedSports(sportsList);
    }
    // eslint-disable-next-line
  }, [sportsList]);

  const getClassName = () => (`${classes.countryItem}`);

  return (
    <Page>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <BackButton color={classes.backBtn} />
      </Box>
      <Box textAlign="center" mt={1}>
        <Typography component="h3" className={classes.title}>
          Select Sport
        </Typography>
      </Box>
      <SearchInput
        handleChange={handleSearch}
        placeholder="Search for your sport"
        value={search}
      />
      <Box className={classes.countryList} mt={3}>
        {searchedSports.length > 0 && (
          <VList
            rowCount={searchedSports.length}
            rowRender={(index) => (
              <>
                <Box
                  className={getClassName()}
                  onClick={() => handleSelectCountry(searchedSports[index])}
                >
                  {searchedSports[index].name}
                </Box>
              </>
            )}
          />
        )}
      </Box>
    </Page>
  );
};

export default SelectSport;
