import React from 'react';

import Page from 'components/Page/Dashboard';
import SelectTeamPlayer from 'components/Bet/Step/SelectTeamPlayer';

const SelectTeamPlayerWrapper = () => (
  <Page>
    <SelectTeamPlayer mode="edit" />
  </Page>
);

export default SelectTeamPlayerWrapper;
