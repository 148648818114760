import React from 'react';
import PropTypes from 'prop-types';
import humanizeString from 'humanize-string';
import { formatCurrency } from 'utils/currency';
import { formattedDate } from 'utils';
import { useMappedCurrencies } from 'hooks/redux/currency';

import {
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => ({
  transactionCard: {
    borderBottom: '1px solid #EEEEEE',
    padding: '10px 10px',
    marginBottom: '5px',
  },
  transactionRecord: {
    color: palette.secondaryColor,
    '& span': {
      font: '13px SofiaPro-Bold',
      display: 'block',
      padding: '5px 0',
    },
    '& span:first-child': {
      float: 'left',
    },
    '& span:nth-child(2)': {
      float: 'right',
    },
  },
  date: {
    font: '13px SofiaPro-Regular',
    color: '#AEAEAE',
  },
  debit: {
    color: '#FC5A5A',
  },
  credit: {
    color: '#54A400',
  },
  availableBalance: {
    clear: 'both',
    '& span:first-child': {
      color: '#AEAEAE',
      font: '13px SofiaPro-Regular',
    },
    '& span:nth-child(2)': {
      float: 'right',
      font: '13px SofiaPro-Bold',
      color: '#29CED3',
    },
  },
}));

const TransactionCard = (props) => {
  const classes = useStyles();
  const mappedCurrencies = useMappedCurrencies();
  // const selectCurrency = useSelectCurrencyDispatch();
  const {
    item,
    selectedCurrency,
    onClick,
  } = props;

  return (
    <>
      <div className={classes.transactionCard} onClick={() => onClick(item)}>
        <span className={classes.date}>
          {formattedDate(item.created_at)}
        </span>
        <div className={classes.transactionRecord}>
          <span>
            {humanizeString(item.transaction_type)}
            { item?.parlay_id > 0 && (
              <>
                <br />
                {`Parlay ID: ${item.parlay_id}`}
              </>
            )}
            { item?.proposed_bet_id > 0 && (
            <>
              <br />
              {`Bet ID: ${item.proposed_bet_id}`}
            </>
            )}
          </span>
          <>
            {selectedCurrency === 'sports' && (
              <span className={(item.sports_amount < 0) ? classes.debit : classes.credit}>
                {formatCurrency(
                  item.sports_amount,
                  mappedCurrencies[selectedCurrency],
                )}
              </span>
            )}
            {selectedCurrency === 'btc' && (
              <span className={(item.btc_amount < 0) ? classes.debit : classes.credit}>
                {formatCurrency(
                  item.btc_amount,
                  mappedCurrencies[selectedCurrency],
                )}
              </span>
            )}
            {selectedCurrency === 'icx' && (
              <span className={(item.icx_amount < 0) ? classes.debit : classes.credit}>
                {formatCurrency(
                  item.icx_amount,
                  mappedCurrencies[selectedCurrency],
                )}
              </span>
            )}
            {selectedCurrency === 'usd' && (
              <span className={(item.amount < 0) ? classes.debit : classes.credit}>
                {formatCurrency(
                  item.amount,
                  mappedCurrencies[selectedCurrency],
                )}
              </span>
            )}
          </>
        </div>
        <div className={classes.availableBalance}>
          <span>Available Balance</span>
          <>
            {selectedCurrency === 'sports' && (
              <span className={(item.new_sports_balance < 0) ? classes.debit : classes.credit}>
                {formatCurrency(
                  item.new_sports_balance,
                  mappedCurrencies[selectedCurrency],
                )}
              </span>
            )}
            {selectedCurrency === 'btc' && (
              <span>
                {formatCurrency(
                  item.new_btc_balance,
                  mappedCurrencies[selectedCurrency],
                )}
              </span>
            )}
            {selectedCurrency === 'icx' && (
              <span className={(item.new_icx_balance < 0) ? classes.debit : classes.credit}>
                {formatCurrency(
                  item.new_icx_balance,
                  mappedCurrencies[selectedCurrency],
                )}
              </span>
            )}
            {selectedCurrency === 'usd' && (
              <span className={(item.new_balance < 0) ? classes.debit : classes.credit}>
                {formatCurrency(
                  item.new_balance,
                  mappedCurrencies[selectedCurrency],
                )}
              </span>
            )}
          </>
        </div>
      </div>
    </>
  );
};

TransactionCard.defaultProps = {
  item: {},
  selectedCurrency: '',
  onClick: () => {
  },
};

TransactionCard.propTypes = {
  item: PropTypes.object,
  selectedCurrency: PropTypes.string,
  onClick: PropTypes.func,
};

export default TransactionCard;
