import * as actionTypes from 'store/actionTypes';

const INITIAL_STATE = {
  isLoading: false,
  overview: {
    kyc_verified: false,
    kyc_attempts: -1,
    currencies: [],
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_FUNDS_OVERVIEW_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_FUNDS_OVERVIEW_SUCCESS:
      return {
        ...state,
        isLoading: false,
        overview: action.payload,
      };
    case actionTypes.GET_FUNDS_OVERVIEW_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.CREATE_CREDIT_CARD_DEPOSIT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.CREATE_CREDIT_CARD_DEPOSIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.CREATE_CREDIT_CARD_DEPOSIT_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
