import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  AutoSizer, List, CellMeasurerCache, CellMeasurer,
} from 'react-virtualized';

const VList = React.memo(({
  rowCount,
  rowRender,
  onScroll,
}) => {
  const [cache] = useState(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 50,
    }),
  );
  const customRenderRow = ({
    index, parent, key, style,
  }) => (
    <CellMeasurer
      key={key}
      cache={cache}
      parent={parent}
      columnIndex={0}
      rowIndex={index}
    >
      {({ registerChild }) => (
        <div ref={registerChild} style={style}>
          {rowRender(index)}
        </div>
      )}
    </CellMeasurer>
  );

  return (
    <AutoSizer>
      {({ height, width }) => (
        <List
          onScroll={(e) => onScroll(e)}
          width={width}
          height={height}
          deferredMeasurementCache={cache}
          rowHeight={cache.rowHeight}
          rowCount={rowCount}
          rowRenderer={customRenderRow}
        />
      )}
    </AutoSizer>
  );
});

VList.defaultProps = {
  onScroll: () => {},
};

VList.propTypes = {
  rowCount: PropTypes.number.isRequired,
  rowRender: PropTypes.func.isRequired,
  onScroll: PropTypes.func,
};

export default VList;
