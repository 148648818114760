import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import OutlineButton from 'components/Buttons/OutlineButton';
import BetInfoCard from 'components/Bet/BetInfoCard';
import CurrencyDropdown from 'components/Currency/CurrencyDropdown';
import InputCurrencyExchange from 'components/Form/InputCurrencyExchange';
import ZenDialog from 'components/Dialog/ZenDialog';

import { useAcceptBetDispatch } from 'hooks/redux/bet';
import { useSelectedCurrency, useMappedCurrencies } from 'hooks/redux/currency';
import { useOpenSuccessModalDispatch, useOpenErrorModalDispatch } from 'hooks/redux/modal';

import { usdCurrency } from 'utils/constants';
import { getAvailableMaxBetAmount, getWinAmount } from 'utils/betting';
import {
  formatCurrency,
  currencyConvert,
  convertCurrencyAmount,
} from 'utils/currency';
import { getErrorMessage } from 'utils';

const useStyles = makeStyles(({ palette }) => ({
  content: {
    background: palette.cardColor,
  },
  likeContent: {
    color: palette.seaGreenColor,
    font: '14px SofiaPro-Bold',
    letterSpacing: 0,
  },
  icon: {
    marginLeft: '10px',
    color: '#29CCD3',
    fontSize: '20px',
  },
  maxBet: {
    color: '#7A869A',
    font: '12px SofiaPro',
    letterSpacing: 0,
  },
  maxBetButton: {
    backgroundColor: palette.betBtnColor,
    color: '#29BFD5',
    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.15)',
    borderRadius: '5px',
    border: palette.betBtnBorder,
    padding: '8px 5px 5px 5px',
    font: '12px SofiaPro-Bold',
    textAlign: 'center',
  },
  receive: {
    borderTop: '1px solid #F5F7F9',
    paddingTop: '14px',
  },
  winTitle: {
    color: palette.secondaryColor,
    font: '14px SofiaPro-Bold',
  },
  winSubTitle: {
    color: '#7A869A',
    font: '12px SofiaPro-Black',
    marginTop: '5px',
  },
  winAmount: {
    color: '#29CAD4',
    font: '13px SofiaPro-Bold',
    marginTop: '5px',
    maxWidth: '200px',
    textAlign: 'center',
  },
}));

const AcceptView = ({ betInfo }) => {
  const classes = useStyles();
  const [cryptoCurrencyAmount, setCryptoCurrencyAmount] = useState('');
  const [usdCurrencyAmount, setUsdCurrencyAmount] = useState('');
  const [betWinAmount, setBetWinAmount] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);

  const currencySymbol = useSelectedCurrency();
  const mappedCurrencies = useMappedCurrencies();
  const acceptBet = useAcceptBetDispatch();
  const openSuccessModal = useOpenSuccessModalDispatch();
  const openErrorModal = useOpenErrorModalDispatch();

  const handleClickInfo = () => {
    setInfoDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setInfoDialogOpen(false);
  };

  const infoMessage = () => {
    let message = '';
    if (betInfo.currency.symbol === 'sports') {
      message = 'Enter in the amount of your bet, up to the Maximum Bet amount or your Available Balance, whichever is less. '
        + 'Minimum Bet amount is $1 worth of SPORTS tokens (based on the current exchange rate). '
        + 'Keep in mind that ZenSports charges a 2.5% fee for accepting bets in SPORTS. '
        + 'If you tap the "Max Bet" button, '
        + 'we\'ll automatically calculate the max you can bet accounting for the 2.5% fee that will get deducted.';
    } else if (betInfo.currency.symbol === 'btc') {
      message = 'Enter in the amount of your bet, up to the Maximum Bet amount or your Available Balance, '
        + 'whichever is less. Minimum Bet amount is $1 worth of BTC (based on the current exchange rate). '
        + 'Keep in mind that ZenSports charges a 5% fee for accepting bets in BTC. If you tap the "Max Bet" button, '
        + 'we\'ll automatically calculate the max you can bet accounting for the 5% fee that will get deducted.';
    } else if (betInfo.currency.symbol === 'usd') {
      message = 'Enter in the amount of your bet, up to the Maximum Bet amount or your Available Balance, '
        + 'whichever is less. Minimum Bet amount is $1. Keep in mind that ZenSports charges a 5% fee for accepting bets in USD. '
        + 'If you tap the "Max Bet" button, '
        + 'we\'ll automatically calculate the max you can bet accounting for the 5% fee that will get deducted.';
    } else if (betInfo.currency.symbol === 'icx') {
      message = 'Enter in the amount of your bet, up to the Maximum Bet amount or your Available Balance, '
        + 'whichever is less. Minimum Bet amount is $1. Keep in mind that ZenSports charges a 5% fee for accepting bets in ICX. '
        + 'If you tap the "Max Bet" button, '
        + 'we\'ll automatically calculate the max you can bet accounting for the 5% fee that will get deducted.';
    }
    return message;
  };

  const changeCryptoCurrencyAmount = (amount) => {
    setCryptoCurrencyAmount(amount);
    setUsdCurrencyAmount(formatCurrency(
      currencyConvert(
        convertCurrencyAmount(amount, betInfo.currency),
        betInfo.currency,
        usdCurrency,
      ),
      usdCurrency,
      false,
    ));
    setBetWinAmount(
      getWinAmount(
        convertCurrencyAmount(amount, betInfo.currency),
        betInfo.betAmount,
        betInfo.proposedReturnAmount,
        betInfo.currency,
      ),
    );
  };

  const changeUsdCurrencyAmount = (amount) => {
    setUsdCurrencyAmount(amount);
    setCryptoCurrencyAmount(formatCurrency(
      currencyConvert(
        convertCurrencyAmount(amount, usdCurrency),
        usdCurrency,
        betInfo.currency,
      ),
      betInfo.currency,
      false,
    ));
    setBetWinAmount(
      getWinAmount(
        currencyConvert(
          convertCurrencyAmount(amount, usdCurrency),
          usdCurrency,
          betInfo.currency,
        ),
        betInfo.betAmount,
        betInfo.proposedReturnAmount,
        betInfo.currency,
      ),
    );
  };

  const handleCryptoCurrencyAmount = (event) => {
    changeCryptoCurrencyAmount(event.target.value);
  };

  const handleUsdCurrencyAmount = (event) => {
    changeUsdCurrencyAmount(event.target.value);
  };

  const handleMaxBet = () => {
    if (selectedCurrency) {
      changeCryptoCurrencyAmount(
        formatCurrency(
          getAvailableMaxBetAmount(
            selectedCurrency.balance,
            betInfo.maxBetAmount,
            betInfo.currency.symbol,
          ),
          betInfo.currency,
          false,
        ),
      );
    }
  };

  const handleAccept = async () => {
    if (Number(cryptoCurrencyAmount) <= 0) {
      openErrorModal({ title: 'Oops', subtitle: 'Bet value should be greater than zero.' });
      return;
    }
    setDisabledSubmit(true);
    try {
      const data = {
        amount: convertCurrencyAmount(cryptoCurrencyAmount, betInfo.currency),
        amount_currency: betInfo.currency.symbol,
      };
      if (data.amount > betInfo.maxBetAmount) {
        setDisabledSubmit(false);
        openErrorModal({
          title: 'Oops',
          subtitle: `The maximum amount allowed for this bet is ${betInfo.formattedMaxBetAmount}. Don't be so greedy - try a lower amount.`,
        });
        return;
      }
      await acceptBet({ betId: betInfo.id, data });
      setDisabledSubmit(false);
      openSuccessModal({
        title: 'Woohoo!',
        subtitle: 'Your acceptance of this bet has been confirmed. Best of luck! 😎',
        buttonText: 'Done',
        nextPath: '/bets',
      });
    } catch (e) {
      setDisabledSubmit(false);
      openErrorModal({ title: 'Oops', subtitle: getErrorMessage(e) });
    }
  };

  useEffect(() => {
    if (mappedCurrencies[currencySymbol]) {
      setSelectedCurrency(mappedCurrencies[currencySymbol]);
    }
  }, [mappedCurrencies, currencySymbol]);

  return (
    <>
      <BetInfoCard
        betInfo={betInfo}
        format="single"
        showAcceptBet={false}
        showNotice
      />
      <Box pt={3} pl={3} pr={3} className={classes.content}>
        <CurrencyDropdown changeable={false} />
        <Box mt={3} mb={1} ml={3} textAlign="left">
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle2" className={classes.likeContent}>
              How much would you like to bet?
            </Typography>
            <InfoOutlinedIcon className={classes.icon} onClick={handleClickInfo} />
            { infoDialogOpen && <ZenDialog title="Bet Amount" message={infoMessage()} cta="Got It!" onClose={handleCloseDialog} /> }
          </Box>
          <Typography variant="subtitle2" className={classes.maxBet}>
            Max Bet:
            {' '}
            {betInfo.formattedMaxBetAmount}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="flex-start" alignItems="center" pl={3} mb={1}>
          <Box flex={1}>
            <InputCurrencyExchange
              currency={betInfo.currency}
              cryptoCurrencyAmount={cryptoCurrencyAmount}
              usdCurrencyAmount={usdCurrencyAmount}
              onCryptoCurrencyAmountChange={handleCryptoCurrencyAmount}
              onUsdCurrencyAmountChange={handleUsdCurrencyAmount}
            />
          </Box>
          <Box className={classes.maxBetButton} onClick={handleMaxBet}>Max Bet</Box>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          className={classes.receive}
          mb={4}
        >
          <Typography variant="subtitle2" className={classes.winTitle}>
            Total You’ll Receive If You Win
          </Typography>
          <Typography variant="subtitle2" className={classes.winSubTitle}>
            (includes your original bet)
          </Typography>
          <Typography variant="subtitle2" className={classes.winAmount}>
            {betWinAmount}
          </Typography>
        </Box>
        <Box pb={3}>
          <OutlineButton disabled={disabledSubmit} onClick={handleAccept}>
            Accept Bet
          </OutlineButton>
        </Box>
      </Box>
    </>
  );
};

AcceptView.defaultProps = {
  betInfo: {},
};

AcceptView.propTypes = {
  betInfo: PropTypes.object,
};

export default AcceptView;
