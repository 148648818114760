import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    background: 'black',
    width: '100%',
    height: '170px',
    position: 'relative',
    '& iframe': {
      border: 'none',
    },
  },
}));

const TwitchPlayer = ({ channel }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {channel && (
        <iframe
          title="Twitch"
          src={`https://player.twitch.tv/?channel=${channel}&parent=stgapp.zensport.co&parent=app.zensports.com`}
          width="100%"
          height="100%"
        />
      )}
    </Box>
  );
};
TwitchPlayer.defaultProps = {
  channel: '',
};

TwitchPlayer.propTypes = {
  channel: PropTypes.string,
};

export default TwitchPlayer;
