import React from 'react';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import SingleElimination from 'components/Play/brackets/SingleElimination';
import DoubleElimination from 'components/Play/brackets/DoubleElimination';

import { useGetBracketsDetails } from 'hooks/redux/play';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0 10px',
    height: '100%',
  },
  backBtn: {
    color: theme.palette.secondaryColor,
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: theme.palette.secondaryColor,
    textAlign: 'center',
    margin: 'auto',
  },
  subtitle: {
    color: '#d4d2d2',
    marginTop: 5,
  },
  bracketLabel: {
    font: '16px SofiaPro-Bold',
    color: theme.palette.secondaryColor,
    marginBottom: '10px',
    marginLeft: '10px',
  },
  switchIcon: {
    paddingRight: 5,
  },
  rootBox: {
    overflow: 'auto',
  },
  treeBox: {
    display: 'flex',
    alignItems: 'center',
  },
  singleTreeBox: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'auto',
  },
  roundOneBox: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 15,
    marginLeft: 15,
  },
  roundTwoBox: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 15,
    height: '100%',
    justifyContent: 'space-around',
  },
  roundFinalBox: {
    marginRight: 15,
  },
}));

const Brackets = ({ match }) => {
  const classes = useStyles();
  const { id } = match.params;
  const bracket = useGetBracketsDetails(id);

  return (
    <Page>
      <Box className={classes.root}>
        <Box>
          <BackButton color={classes.backBtn} />
        </Box>
        <Box textAlign="center" mb={4} mt={4}>
          <Typography component="h3" className={classes.title}>
            Bracket
          </Typography>
          <Typography component="h3" className={classes.subtitle}>
            Manage Your Competition Bracket
          </Typography>
        </Box>
        {bracket?.format === 'double_elimination'
          ? <DoubleElimination bracket={bracket} classes={classes} />
          : <SingleElimination bracket={bracket} classes={classes} />}
      </Box>
    </Page>
  );
};

Brackets.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(Brackets);
