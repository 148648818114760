import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Box, Typography, Button } from '@material-ui/core';
import { usePlayList, usePlaySelectedQuery, useSetTournamentQueryDispatch } from 'hooks/redux/play';
import { getFilterQuery } from 'utils/play.sort';
import PlayBannerImage from 'assets/images/play/play_banner.png';
import ZenDialog from 'components/Dialog/ZenDialog';
import Tabs from 'components/Tabs/Tabs';
import CompetitionsVlist from './CompetitionVlist';

const comingSoonMessage = 'We are working on revamping these features. '
  + 'Email support@zensports.com and let us know about your interest in these features.';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    flex: 1,
    paddingTop: '70px',
    display: 'flex',
    flexDirection: 'column',
  },
  subTitle: {
    font: '24px SofiaPro-Bold',
    color: palette.secondaryColor,
    padding: '10px 10px 0 10px',
    margin: '18px 0 26px',
  },
  logoMark: {
    backgroundImage: `url(${PlayBannerImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    padding: '18px',
    borderRadius: '5px',
    margin: '15px 8px 8px',
  },
  content: {
    fontFamily: 'SofiaPro-Regular',
    color: 'white',
    fontSize: '15px',
    lineHeight: '20px',
    letterSpacing: 0.6,
    paddingBottom: '10px',
  },
  betButton: {
    width: '175px',
    backgroundImage: 'linear-gradient(45deg, #298EDB, #29D2D3)',
    padding: '6px 0px !important',
    '& .MuiButton-label': {
      color: '#FFF',
      fontSize: '15px',
      fontFamily: 'SofiaPro-Medium',
      textTransform: 'capitalize',
    },
  },
  tabContainer: {
    flex: '1',
    '& > div': {
      height: '100%',
      background: palette.themeColor,
    },
    '& header': {
      width: '100% !important',
      marginLeft: '0px !important',
    },
    '& .react-swipeable-view-container': {
      willChange: 'inherit !important',
      transform: 'inherit !important',
    },
    '& .MuiTabs-flexContainer .MuiTab-wrapper': {
      color: '#adadbc',
      fontSize: '16px',
      textTransform: 'capitalize',
    },
    '& header .MuiTabs-root': {
      height: 'auto',
    },
    '& .MuiTab-fullWidth': {
      flexGrow: 0,
      maxWidth: 'auto',
      flexBasis: 'auto',
      flexShrink: 'auto',
      padding: '6px 15px 6px 0px',
    },
    '& .MuiTabs-scroller': {
      overflow: 'auto !important',
    },
    '& .MuiTabs-indicator': {
      backgroundColor: '#00dde1',
    },
    '& .MuiTabs-root': {
      width: '100%',
      marginLeft: 0,
    },
  },
}));

const Competitions = ({ history, debounceSearch, searchValue }) => {
  const classes = useStyles();
  const [options, setOptions] = useState();

  const setTournamentQuery = useSetTournamentQueryDispatch();
  const filter = usePlaySelectedQuery();
  const play = usePlayList(debounceSearch, options);

  useEffect(() => {
    if (filter) {
      setOptions({
        hitsPerPage: 1000,
        page: 0,
        filters: getFilterQuery(filter),
        filter,
      });
    }
    // eslint-disable-next-line
  }, [filter]);

  const createTournament = () => {
    setTournamentQuery({});
    history.push('/more/play/tournament/create/information');
  };

  const renderPlayTabs = () => [
    {
      label: 'Competitions',
      component: <CompetitionsVlist play={play} />,
    },
    {
      label: 'Players',
      component: <ZenDialog
        title="Feature Coming Soon!"
        message={comingSoonMessage}
        cta="Got It!"
      />,
    },
  ];

  return (
    <Box className={classes.root}>
      {searchValue
        ? (
          <>
            <CompetitionsVlist play={play} />
          </>
        )
        : (
          <>
            <Box className={classes.logoMark}>
              <Typography variant="subtitle1" className={classes.content}>
                What will you play next?
              </Typography>
              <Button
                variant="contained"
                className={classes.betButton}
                onClick={createTournament}
              >
                Create Competition
              </Button>
            </Box>
            <Box className={classes.tabContainer}>
              <Tabs tabs={renderPlayTabs()} hidePadding />
            </Box>
          </>
        )}
    </Box>
  );
};

Competitions.propTypes = {
  history: PropTypes.object.isRequired,
  debounceSearch: PropTypes.string.isRequired,
  searchValue: PropTypes.string.isRequired,
};

export default withRouter(Competitions);
