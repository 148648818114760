import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Box, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';

import Page from 'components/Page/Dashboard';
import TwitchPlayer from 'components/Play/Tournament/View/TwitchPlayer';
import BackButton from 'components/Buttons/BackButton';
import TwitchIcon from 'components/Icons/TwitchIcon';
import Avatar from 'components/Avatar';
import CrownImage from 'assets/images/play/crown.png';

import {
  useTournamentMatchDetails,
  useSetTournamentMatchWinnerDispatch,
} from 'hooks/redux/play';
import { useOpenConfirmModalDispatch, useOpenErrorModalDispatch } from 'hooks/redux/modal';
import { getErrorMessage } from 'utils';
import { displayTournamentName } from 'utils/play';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0 10px',
  },
  backBtn: {
    color: theme.palette.secondaryColor,
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: theme.palette.secondaryColor,
    textAlign: 'center',
    margin: 'auto',
  },
  card: {
    background: theme.palette.cardColor,
    borderRadius: '5px',
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 4px',
    position: 'relative',
    overflow: 'hidden',
    marginBottom: '10px',
    width: '100%',
    marginRight: 20,
    paddingBottom: 10,
  },
  contentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px',
  },
  matchContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    position: 'relative',
  },
  completedContainer: {
    display: 'flex',
    flex: 0.7,
    alignItems: 'center',
    justifyContent: 'center',
  },
  matchText: {
    color: theme.palette.secondaryColor,
    fontSize: '12px',
    marginTop: 5,
    marginBottom: 5,
  },
  completedText: {
    color: '#a8b2bd',
    fontSize: '12px',
    marginTop: 5,
    textTransform: 'capitalize',
  },
  roundText: {
    textAlign: 'center',
    color: theme.palette.secondaryColor,
    marginTop: 10,
  },
  crownImg: {
    position: 'absolute',
    top: '-17px',
  },
  subtitle: {
    color: '#d4d2d2',
    marginTop: 5,
  },
  line: {
    background: '#d3d3db',
    height: '1px',
  },
  menuLine: {
    background: '#f1f2f5',
    height: '1px',
    width: '85%',
    margin: 'auto',
  },
  contentText: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.secondaryColor,
    margin: '10px 20px 10px',
    fontSize: '13px',
  },
  bracketBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bracketBtn: {
    padding: 0,
    width: '48%',
    background: theme.palette.colorBtn,
    border: '2px solid white',
    boxShadow: '0 1px 20px 0 rgba(215,209,209,0.3), 0 3px 10px 0 rgba(215,209,209,0.8)',
    margin: '10px 0',
    minHeight: 35,
    borderRadius: 50,
    font: '16px SofiaPro-SemiBold',
    letterSpacing: '0.3px',
    color: theme.palette.colorBtnTxt,
  },
  switchIcon: {
    paddingRight: 5,
  },
  watchingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 5,
  },
  watchingText: {
    color: '#adb5c0',
    paddingRight: 4,
    fontSize: 14,
  },
  watchingTextUser: {
    color: theme.palette.secondaryColor,
    fontSize: 14,
  },
}));

const MatchDetails = ({ match }) => {
  const classes = useStyles();
  const [winnerId, setWinnerId] = useState(null);
  const [streamIndex, setStreamIndex] = useState(0);
  const { matchId } = match.params;
  const details = useTournamentMatchDetails(matchId);
  const setTournamentMatchWinner = useSetTournamentMatchWinnerDispatch();
  const openErrorModal = useOpenErrorModalDispatch();
  const openConfirmModal = useOpenConfirmModalDispatch();

  const updateMatchWinner = async (id) => {
    const message = `Click the confirmation button below to confirm that 
    ${displayTournamentName(details?.users?.[0])} beat ${displayTournamentName(details?.users?.[1])}.`;
    openConfirmModal({
      title: 'Please Confirm!',
      subtitle: message,
      agreeBtnText: 'CONFIRM',
      disagreeBtnText: 'CANCEL',
      callback: async () => {
        try {
          const query = {
            winner_id: id,
          };
          await setTournamentMatchWinner(matchId, query);
          setWinnerId(id);
        } catch (e) {
          openErrorModal({
            title: 'Oops',
            subtitle: getErrorMessage(e),
          });
        }
      },
    });
  };
  return (
    <Page>
      <Box className={classes.root}>
        <Box>
          <BackButton color={classes.backBtn} />
        </Box>
        <Box textAlign="center" mb={4} mt={4}>
          <Typography component="h3" className={classes.title}>
            Match Details
          </Typography>
          <Typography component="h3" className={classes.subtitle}>
            {
              details?.status === 'finished'
                ? 'Tap on the player to set them as the winner of this match.'
                : 'Tap on a player to set them as the winner of this match.'
            }
          </Typography>
        </Box>
        <Box>
          <Box className={`cardOverride ${classes.card}`}>
            <Typography className={classes.roundText}>
              {details?.place}
            </Typography>
            <Box className={classes.contentContainer}>
              <Box
                key={details?.users?.[0]?.position}
                className={classes.matchContainer}
                onClick={() => updateMatchWinner(details?.users?.[0]?.id)}
              >
                {
                  (winnerId || details?.winner_id) === details?.users?.[0]?.id
                  && <img src={CrownImage} alt="" className={classes.crownImg} />
                }
                <Avatar src={details?.users?.[0]?.profile_url} size="50px" />
                <Typography className={classes.matchText}>
                  {displayTournamentName(details?.users?.[0])}
                </Typography>
                {details?.users?.[0]?.twitch_channel_url && (<TwitchIcon />)}
              </Box>
              <Box className={classes.completedContainer}>
                <Typography className={classes.completedText}>
                  {details?.status}
                </Typography>
              </Box>
              <Box
                key={details?.users?.[1]?.position}
                className={classes.matchContainer}
                onClick={() => updateMatchWinner(details?.users?.[1]?.id)}
              >
                {
                  (winnerId || details?.winner_id) === details?.users?.[1]?.id
                  && <img src={CrownImage} alt="" className={classes.crownImg} />
                }
                <Avatar src={details?.users?.[1]?.profile_url} size="50px" />
                <Typography className={classes.matchText}>
                  {displayTournamentName(details?.users?.[1])}
                </Typography>
                {details?.users?.[1]?.twitch_channel_url && (<TwitchIcon />)}
              </Box>
            </Box>
            <Box>
              <Box className={classes.line} />
              <Typography
                className={classes.contentText}
              >
                View Player Streams
              </Typography>
            </Box>
            <TwitchPlayer channel={details?.users?.[streamIndex]?.twitch_channel_url} />
            <Box className={classes.watchingContainer}>
              <Typography className={classes.watchingText}>
                You are watching:
              </Typography>
              <Typography className={classes.watchingTextUser}>
                {displayTournamentName(details?.users?.[streamIndex])}
              </Typography>
            </Box>
            <Box className={classes.bracketBox}>
              <Button className={classes.bracketBtn} onClick={() => setStreamIndex(streamIndex === 0 ? 1 : 0)}>
                <SwapHorizIcon className={classes.switchIcon} />
                Switch Player
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Page>
  );
};

MatchDetails.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(MatchDetails);
