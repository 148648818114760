import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/styles';
import { Box, Typography } from '@material-ui/core';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import InputBox from 'components/Form/InputBox';

import { useAuthUser } from 'hooks/redux/user';
import { useKycUserInfo, useSetKycUserInfoDispatch } from 'hooks/redux/kyc';
import CommonStyle from 'styles/common';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 15px',
  },
  line: {
    background: 'linear-gradient(45deg, #298EDA, #29D2D3)',
    height: '3px',
    marginLeft: '-27px',
    width: '30%',
  },
  topic: {
    font: '12px SofiaPro-SemiBold',
    color: palette.colorBtnTxt,
    textTransform: 'capitalize',
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'space-between',
  },
}));

const Profile = ({ history }) => {
  const classes = useStyles();
  const commonClasses = CommonStyle();
  const user = useAuthUser();
  const kycInfo = useKycUserInfo();
  const setKycUserInfo = useSetKycUserInfoDispatch();
  // const openErrorModal = useOpenErrorModalDispatch();
  const isResolve = history.location.state === 'resolve';

  useEffect(() => {
    if (user.id && !kycInfo.email) {
      setKycUserInfo({
        email: user.email,
        name: `${user.first_name} ${user.last_name}`,
      });
    }
    // eslint-disable-next-line
  }, [user.id]);

  return (
    <Page>
      <Box className={classes.root} display="flex" flexDirection="column" flex={1}>
        <Box className={classes.line} mb={2} />
        <Box>
          <BackButton color={commonClasses.backBtn} page={isResolve ? '/kyc/resolve' : ''} />
        </Box>
        <Box textAlign="center" mt={3} mb={1}>
          <Typography component="h3" className={classes.topic}>
            KYC/AML VERIFICATION FORM
          </Typography>
        </Box>
        <Box textAlign="center" mb={6}>
          <Typography component="h3" className={classes.title}>
            Contact Information
          </Typography>
        </Box>
        {
          kycInfo.name !== '' && (
            <Formik
              initialValues={{
                name: kycInfo.name,
                email: kycInfo.email,
              }}
              onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(true);
                setKycUserInfo({
                  name: values.name,
                  email: values.email,
                });
                const route = isResolve ? '/kyc/resolve' : '/kyc/profile/birthday';
                history.push(route);
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email()
                  .required('Required'),
                name: Yup.string()
                  .required('Required'),
              })}
            >
              {({
                errors,
                touched,
                values,
                isSubmitting,
                handleChange,
                handleSubmit,
                handleBlur,
              }) => (
                <form onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.form}>
                  <Box>
                    <InputBox
                      label="Full Name"
                      name="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      errors={errors}
                      touched={touched}
                    />
                    <Box mt={5}>
                      <InputBox
                        label="Email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        errors={errors}
                        touched={touched}
                      />
                    </Box>
                  </Box>
                  <Box display="flex" className={commonClasses.continue}>
                    <OutlineButton type="submit" disabled={isSubmitting}>
                      {isResolve ? 'Done' : 'Next'}
                    </OutlineButton>
                  </Box>
                </form>
              )}
            </Formik>
          )
        }
      </Box>
    </Page>
  );
};

Profile.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(Profile);
