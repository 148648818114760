import httpService from './http.service';

const getLeagues = () => httpService
  .get('/betting/league_menu')
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const getSchedules = (params) => httpService
  .get('/betting/get_schedule', {}, params)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const getBetObjects = (body) => httpService
  .post('/betting/get_betting_objects', { ...body.data })
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const getBetTypes = (params) => httpService
  .get('/betting/create_bet_options', {}, params)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const getBetList = (params) => httpService
  .get('/betting/get_proposed_bets', {}, params)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const getSingleBet = (params) => httpService
  .get(`/betting/${params.betId}/get_proposed_bet`)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const acceptBet = (body) => httpService
  .post(`/betting/${body.betId}/accept_bet`, { ...body.data })
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const createBet = (body) => httpService
  .post('/betting/create_proposed_bet', body)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const setResults = (body) => httpService
  .post(`/betting/${body.betId}/set_results`, { ...body.data })
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const closeBet = (body) => httpService
  .post(`/betting/${body.betId}/close_betting`, body)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const disputeResults = (body) => httpService
  .post(`/betting/${body.betId}/dispute_results`, { ...body.data })
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const editBet = (body) => httpService
  .post(`/betting/${body.betId}/edit_proposed_bet`, { ...body.data })
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const deleteBet = (body) => httpService
  .remove(`/betting/${body.betId}/delete_bet`, body)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

export default {
  getLeagues,
  getBetObjects,
  getSchedules,
  getBetTypes,
  getBetList,
  getSingleBet,
  acceptBet,
  createBet,
  setResults,
  closeBet,
  disputeResults,
  deleteBet,
  editBet,
};
