import axios from 'axios';
import { dateTimeOptions } from 'utils/constants';

export const convertObjectToQueryString = (params) => Object.keys(params).map((key) => `
${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`).join('&');

export const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'tablet';
  }
  if (
    /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua,
    )
  ) {
    return 'mobile';
  }
  return 'desktop';
};

export const isStandalone = () => {
  if (('standalone' in window.navigator) || window.navigator.standalone) {
    return true;
  }
  return false;
};

export const isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1
  && navigator.userAgent
  && navigator.userAgent.indexOf('CriOS') === -1
  && navigator.userAgent.indexOf('FxiOS') === -1;

export const isISODateString = (str) => {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/.test(str)) return false;
  // const d = new Date(str);
  // return d.toISOString() === str;
  return true;
};

export const formattedDate = (str) => {
  if (str) {
    if (isISODateString(str)) {
      return new Date(str).toLocaleString('en-US', dateTimeOptions);
    }
    if (str.includes(' UTC')) {
      return new Date(str.replace(' UTC', '').replace(' ', 'T')).toLocaleString('en-US', dateTimeOptions);
    }
  }
  return '';
};

export const renderAuthorizedIframeContent = (url, elementId, authToken) => {
  const header = { Authorization: `Basic ${authToken}` };
  const iframe = document.getElementById(elementId).contentDocument;
  axios.get(url, { headers: header })
    .then((response) => {
      iframe.write(response.data);
    });
};

export const getErrorMessage = (e) => {
  if (e?.error) {
    if (typeof e.error === 'string') {
      return e.error;
    }
    if (typeof e.error === 'object') {
      if (e.error.attributes && e.error.attributes.detail) {
        return e.error.attributes.detail;
      }
    }
  } else if (e?.messages) {
    return e.messages;
  }
  return 'Error';
};

export const shareLinks = ({ title, text, url }) => {
  if (navigator.share) {
    navigator.share({
      title,
      text,
      url,
    })
      .then(() => {
        // eslint-disable-next-line
        console.log('Successful share');
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.log('Error sharing', error)
      });
  } else {
    // eslint-disable-next-line
    alert('Not available on this browser');
  }
};

export const checkPasswordRules = (password) => {
  let condition = 0;
  if (!new RegExp(/^(?=.{8,}$).*$/).test(password)) {
    return false;
  }
  if (new RegExp(/^(?=.*?[a-z]).*$/).test(password)) {
    condition += 1;
  }
  if (new RegExp(/^(?=.*?[A-Z]).*$/).test(password)) {
    condition += 1;
  }
  if (new RegExp(/^(?=.*?[0-9]).*$/).test(password)) {
    condition += 1;
  }
  if (new RegExp(/^(?=.*?\W).*$/).test(password)) {
    condition += 1;
  }
  return condition >= 2;
};

/**
 * Android work solution
 */
export const forceDismissKeyboard = () => {
  const input = document.createElement('input');
  input.setAttribute('type', 'text');
  document.body.appendChild(input);

  setTimeout(() => {
    input.focus();
    setTimeout(() => {
      input.setAttribute('style', 'display:none;');
      document.body.removeChild(input);
    }, 50);
  }, 50);
};
