import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Button,
  Grid,
  Typography,
} from '@material-ui/core';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import DebitIcon from 'assets/images/funds/debit.png';
import WireIcon from 'assets/images/funds/wire.png';

import { useAccountOverviewInfo } from 'hooks/redux/account';
import commonStyle from 'styles/common';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 15px',
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
    padding: 10,
  },
  content: {
    margin: '17px -15px 7px',
    color: '#AEAEAE',
    font: '13px SofiaPro',
  },
  subtitle: {
    font: '17px SofiaPro-Bold',
    color: palette.secondaryColor,
    padding: 10,
  },
  card: {
    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.15)',
    padding: '10px 20px',
    background: palette.cardColor,
    borderRadius: '5px',
    color: palette.secondaryColor,
    font: '18px SofiaPro-Bold',
  },
  walletImage: {
    height: '50px',
  },
  add: {
    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.15)',
    borderRadius: '5px',
    minHeight: 40,
    color: '#29CDD4',
    font: '16px SofiaPro-Bold',
    background: palette.cardColor,
  },
}));

const Fiat = ({ history }) => {
  const classes = useStyles();
  const commonClasses = commonStyle();
  const overview = useAccountOverviewInfo();

  const handleClickAddCard = () => {
    // history.push('/funds/deposit/debit-card/add');
    window.location.href = '/funds/deposit/debit-card/add';
  };

  return (
    <Page>
      <Box className={classes.root}>
        <Box>
          <BackButton color={commonClasses.backBtn} page="/funds" />
        </Box>
        <Box textAlign="center" mb={4}>
          <Typography component="h3" className={classes.title}>
            Manage Fiat Accounts
          </Typography>
        </Box>
        {
          overview?.active_cards?.length <= 0
            ? (
              <Box
                mt={3}
                className={classes.card}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Typography variant="h6" className={classes.subtitle}>
                  No Debit/Credit Cards Added
                </Typography>
                <Button className={classes.add} onClick={handleClickAddCard}>
                  Add Debit/Credit Card
                </Button>
                <Typography variant="subtitle2" className={classes.content}>
                  NOTE: Debit/Credit cards are for deposit use only.
                </Typography>
              </Box>
            )
            : (
              <Box
                className={classes.card}
                display="flex"
                alignItems="center"
                onClick={() => history.push('/funds/fiat-accounts/debit-cards')}
              >
                <Grid item xs={4} sm={4}>
                  <Box>
                    <img className={classes.walletImage} src={DebitIcon} alt="navigation" />
                  </Box>
                </Grid>
                <Grid item xs={8} sm={8}>
                  Debit/Credit Cards
                </Grid>
              </Box>
            )
        }
        {
          overview?.active_bank_accounts?.length <= 0
            ? (
              <Box
                mt={3}
                className={classes.card}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Typography variant="h6" className={classes.subtitle}>
                  No Wire Instructions Added
                </Typography>
                <Button className={classes.add} onClick={() => history.push('/funds/withdraw/wire-transfer/instructions')}>
                  Add Wire Instructions
                </Button>
                <Typography variant="subtitle2" className={classes.content}>
                  NOTE: Wire instructions are for withdraw use only.
                </Typography>
              </Box>
            )
            : (
              <Box
                mt={3}
                className={classes.card}
                display="flex"
                alignItems="center"
                onClick={() => history.push('/funds/fiat-accounts/wires')}
              >
                <Grid item xs={4} sm={4}>
                  <Box>
                    <img className={classes.walletImage} src={WireIcon} alt="navigation" />
                  </Box>
                </Grid>
                <Grid item xs={8} sm={8}>
                  Wire Instructions
                </Grid>
              </Box>
            )
        }
      </Box>
    </Page>
  );
};

Fiat.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(Fiat);
