import React, { useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import { Box, Typography } from '@material-ui/core';
import Loading from 'components/Loading/Box';

import Page from 'components/Page/Dashboard';
import Notif from 'components/Notif';
import { useNotifications, useNotificationsIsLoading } from 'hooks/redux/notification';
import VList from 'components/VList';
import * as notificationTypes from './notificationTypes';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 10px',
  },
  notificationsList: {
    flex: 1,
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
    padding: 10,
  },
}));

const Notifs = ({ history }) => {
  const classes = useStyles();
  const [params, setParams] = useState();
  const [notificationList, setNotificationList] = useState([]);
  const [orgNotifications, setOrgNotifications] = useState(null);

  const handleNotif = useCallback((notif) => {
    const notificationType = notif.notification_type;
    switch (notificationType) {
      case notificationTypes.WELCOME:
      case notificationTypes.SPORTS_BETTING_TIPS:
      case notificationTypes.MATCH_PLAY_BONUS_DEPOSITED:
        const { link } = notif;
        if (link.trim() === '') {
          history.push('/');
        } else {
          window.open(link);
        }
        break;
      case notificationTypes.BETS:
      case notificationTypes.FAVORITES:
        history.push('/bets');
        break;
      case notificationTypes.BET_ACCEPTED:
      case notificationTypes.BET_RESULTS_SUBMITTED:
      case notificationTypes.BET_RESULTS_DISPUTED:
        const proposedBetId = notif.proposed_bet_id;
        history.push(`/bets/view/${proposedBetId}`);
        break;
      case notificationTypes.PARLAY:
        const parlayId = notif.parlay_id;
        history.push(`/bets/results/parlay/${parlayId}`);
        break;
      case notificationTypes.DEPOSIT:
      case notificationTypes.WITHDRAWAL_REQUEST:
      case notificationTypes.KYC_SUCCESSFULLY_VERIFIED:
      case notificationTypes.KYC_VERIFICATION_FAILED:
        history.push('/funds');
        break;
      default:
        break;
    }
  }, [history]);

  const notifications = useNotifications(params);
  const isLoading = useNotificationsIsLoading();

  const handleScroll = (event) => {
    if (params) {
      const { page } = params;
      if (((event.scrollHeight - event.clientHeight) === Math.round(event.scrollTop)) && notificationList.length >= (page + 1) * params.amount) {
        setParams({
          ...params,
          page: page + 1,
        });
      }
    }
  };

  useEffect(() => {
    setParams({
      page: 0,
      amount: 25,
    });
  }, []);

  useEffect(() => {
    if (orgNotifications === notifications) {
      return;
    }
    if (params && params.page === 0) {
      setNotificationList([
        ...(notifications || []),
      ]);
    } else {
      setNotificationList([
        ...notificationList,
        ...(notifications || []),
      ]);
    }
    setOrgNotifications(notifications);
  }, [notifications, orgNotifications, notificationList, params]);

  return (
    <Page>
      <Box className={classes.root}>
        <Box textAlign="center" mb={4}>
          <Typography component="h3" className={classes.title}>
            Notifications
          </Typography>
        </Box>
      </Box>
      <Box className={classes.notificationsList}>
        {notificationList.length > 0 && (
          <VList
            onScroll={handleScroll}
            rowCount={notificationList.length}
            rowRender={(index) => (
              <>
                <Notif
                  item={notificationList[index]}
                  onClick={() => handleNotif(notificationList[index])}
                />
              </>
            )}
          />
        )}
      </Box>
      {
        isLoading
        && (<Loading />)
      }
    </Page>
  );
};

Notifs.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(Notifs);
