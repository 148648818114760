import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Box, Typography } from '@material-ui/core';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import SmallGameListCard from 'components/Bet/BetListSmallCard';
import Loading from 'components/Loading/Box';
import SearchInput from 'components/Form/SearchInput';

import { useEsports, useBet, useSetLeagueDispatch } from 'hooks/redux/bet';
import { getLeagueInfo } from 'utils/betting';
import CommonStyle from 'styles/common';

const Esports = ({ history }) => {
  const [search, setSearch] = useState('');
  const [searchedGames, setSearchedGames] = useState([]);
  const commonClasses = CommonStyle();

  const esports = useEsports();
  const betting = useBet();
  const setLeague = useSetLeagueDispatch();

  const handleSearch = (event) => {
    setSearch(event.target.value);
    setSearchedGames(
      esports.filter((game) => game.name.toLowerCase().includes(
        event.target.value.toLowerCase(),
      )),
    );
  };

  const handleNext = useCallback((type, league) => {
    setLeague(league);
    history.push('/bets/results/mine');
  }, [setLeague, history]);

  useEffect(() => {
    if (esports.length) {
      setSearchedGames(esports);
    }
  }, [esports]);

  return (
    <Page>
      <Box className={commonClasses.root}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <BackButton color="black" />
          <SearchInput
            handleChange={handleSearch}
            placeholder="Enter a game title…"
            value={search}
          />
        </Box>
        <Box
          textAlign="center"
          mt={3}
          mb={2}
        >
          <Typography variant="subtitle2" className={commonClasses.topic}>
            ESPORTS
          </Typography>
          <Typography variant="h1" className={commonClasses.title}>
            Select a game title
          </Typography>
        </Box>
        {!betting.isLoading ? searchedGames.map((item) => (
          <SmallGameListCard
            onClick={() => handleNext(item.type, item)}
            key={item.name}
            {...getLeagueInfo(item)}
          />
        )) : (<Loading />)}
      </Box>
    </Page>
  );
};

Esports.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(Esports);
