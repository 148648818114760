import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { Box, BottomNavigation, BottomNavigationAction } from '@material-ui/core';

import Badge from '@material-ui/core/Badge';
import IconResultsImage from 'assets/images/bottomNavIcon/result.png';
import IconMarketImage from 'assets/images/bottomNavIcon/icon_market.png';
import IconMoreImage from 'assets/images/bottomNavIcon/more-icon-no.png';
import IconNoticeImage from 'assets/images/bottomNavIcon/noun_notification.png';
import IconNewFeedImage from 'assets/images/bottomNavIcon/icon_newfeeds-n.png';
import IconActiveNewFeedImage from 'assets/images/bottomNavIcon/icon_newfeeds.png';
import IconActiveResultsImage from 'assets/images/bottomNavIcon/result-icon.png';
import IconActiveMarketImage from 'assets/images/bottomNavIcon/icon_market-co.png';
import IconActiveMoreImage from 'assets/images/bottomNavIcon/more-icon.png';
import IconActiveNoticeImage from 'assets/images/bottomNavIcon/noun_notification-co.png';

import { useNotificationBadge, useResetNotificationBadgeDispatch } from 'hooks/redux/notification';

const navItems = [
  {
    path: '/bets',
    label: 'Bets/Contests',
    icon: IconNewFeedImage,
    activeIcon: IconActiveNewFeedImage,
  },
  {
    path: '/bets/results',
    label: 'Results',
    icon: IconResultsImage,
    activeIcon: IconActiveResultsImage,
  },
  {
    path: '/funds',
    label: 'Funds',
    icon: IconMarketImage,
    activeIcon: IconActiveMarketImage,
  },
  {
    path: '/notifications',
    label: 'Notifs',
    icon: IconNoticeImage,
    activeIcon: IconActiveNoticeImage,
  },
  {
    path: '/more',
    label: 'More',
    icon: IconMoreImage,
    activeIcon: IconActiveMoreImage,
  },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '70px',
    backgroundColor: '#14133D',
    '& .MuiButtonBase-root.MuiBottomNavigationAction-root': {
      minWidth: 'unset',
    },
    '& .MuiBottomNavigationAction-label': {
      color: '#4E586E',
      fontSize: '11px',
    },
    '& .MuiBottomNavigationAction-label.Mui-selected ': {
      color: '#63C4C6',
      fontSize: '11px',
    },
  },
  iconWrapper: {
    minHeight: '30px',
  },
});

const Navigation = ({ history, match }) => {
  const [activePath, setActivePath] = useState('/bets');
  const classes = useStyles();

  const badge = useNotificationBadge();
  const resetNotificationBadge = useResetNotificationBadgeDispatch();

  const handleChange = (event, path) => {
    if (path === '/notifications') {
      resetNotificationBadge();
    }
    history.push(path);
  };

  useEffect(() => {
    const uri = match.path.split('/');
    if (uri[1] === 'bets' && uri[2] === 'results') {
      setActivePath(`/${uri[1]}/${uri[2]}`);
    } else {
      setActivePath(`/${uri[1]}`);
    }
  }, [match]);

  return (
    <BottomNavigation
      value={activePath}
      onChange={handleChange}
      showLabels
      className={classes.root}
    >
      {navItems.map((navItem) => (
        <BottomNavigationAction
          value={navItem.path}
          label={navItem.label}
          key={navItem.path}
          icon={(
            <Box
              className={classes.iconWrapper}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {navItem.label === 'Notifs'
                ? (
                  <Badge badgeContent={badge} color="error">
                    <img src={activePath === navItem.path ? navItem.activeIcon : navItem.icon} alt="navigation" />
                  </Badge>
                )
                : <img src={activePath === navItem.path ? navItem.activeIcon : navItem.icon} alt="navigation" />}
            </Box>
          )}
        />
      ))}
    </BottomNavigation>
  );
};

Navigation.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withRouter(Navigation);
