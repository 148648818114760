import httpService from './http.service';

const getTransactions = (params) => httpService
  .get('/balance_transactions/list_transactions', {}, params)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

export default {
  getTransactions,
};
