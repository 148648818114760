import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import DatePicker from 'react-mobile-datepicker';

import {
  Box,
  Typography,
  TextField,
  FormControl,
  InputAdornment,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import OutlineButton from 'components/Buttons/BorderOutlineButton';
import PublicProfilePhoto from 'components/PublicProfilePhoto';
import CoverPhoto from 'components/CoverPhoto';

import { dateConfig } from 'utils/constants';
import { useUserProfile } from 'hooks/redux/account';
import { updateUserAction, getUserAction } from 'store/actions/auth';
import commonStyle from 'styles/common';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 15px',
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
  },
  topic: {
    font: '15px SofiaPro-Bold',
    color: '#002251',
    marginBottom: 10,
  },
  content: {
    font: '13px SofiaPro',
    color: 'grey',
    marginBottom: '15px',
    marginTop: '15px',
  },
  sectionTitle: {
    font: '15px SofiaPro-Bold',
    margin: '15px 0',
    color: palette.secondaryColor,
  },
  textField: {
    margin: '8px 0',
    '& label': {
      fontWeight: 'bold',
      color: '#63C4C6',
      fontSize: '14px',
    },
    '& .Mui-disabled': {
      color: '#63C4C6',
    },
    '& input': {
      color: palette.secondaryColor,
    },
    '& .Mui-disabled input': {
      color: '#AEAEAE',
    },
    '& .MuiInput-underline:before': {
      borderBottom: `1px solid ${palette.secondaryColor} !important`,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: `1px solid ${palette.secondaryColor} !important`,
    },
    textField: {
      margin: '15px 0',
    },
  },
  checkBox: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginTop: '10px',
    '& span': {
      paddingTop: '0px',
      paddingBottom: '0px',
      fontSize: '14px',
      fontWeight: 'Bold',
    },
  },
  saveProfile: {
    padding: 0,
    width: '100%',
    background: palette.colorBtn,
    border: '2px solid #29CFD4',
    boxShadow: '0 1px 20px 0 rgba(0,214,219,0.3), 0 3px 10px 0 rgba(0,214,219,0.3)',
    margin: '20px 0',
    minHeight: 50,
    font: '17px SofiaPro-SemiBold',
    letterSpacing: '0.3px',
    color: palette.colorBtnTxt,
  },
  coverImage: {
    height: '150px',
    border: '2px dashed #63C4C6',
    borderRadius: '10px',
    textAlign: 'center',
    margin: '10px 0',
    '& img': {
      width: '32px',
      marginTop: '50px',
      display: 'inline-block',
    },
    '& span': {
      display: 'block',
      font: '14px SofiaPro-Bold',
      color: '#63C4C6',
    },
  },
  fieldLabel: {
    fontWeight: 'bold',
    color: '#63C4C6',
    fontSize: '12px',
    marginBottom: '15px',
  },
  avatarField: {
    textAlign: 'center',
    margin: '10px 0',
    '& img': {
      width: '28px',
      display: 'inline-block',
      marginTop: '16px',
    },
    '& span': {
      display: 'block',
      font: '14px SofiaPro-Bold',
      color: '#63C4C6',
    },
  },
  avatarCircle: {
    borderRadius: '50px',
    border: '2px solid #63C4C6',
    width: '60px',
    height: '60px',
    display: 'inline-block',
    marginBottom: '5px',
  },
  downIcon: {
    color: '#1cdfe4',
  },
  dateContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
  },
  dateField: {
    display: 'inline-block',
    position: 'relative',
  },
}));

const SettingProfile = ({ user, history, updateUser }) => {
  const classes = useStyles();
  const commonClasses = commonStyle();
  const userProfile = useUserProfile();
  const [coverUrl, setCoverUrl] = useState(user.cover_url);
  const [profileUrl, setProfileUrl] = useState(user.profile_url);
  const [dob, setDob] = useState(user.dob);
  const [isPopup, setIsPopup] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const handleSelect = (date) => {
    setIsPopup(!isPopup);
    setIsOpen(false);
    setDob(date.toISOString().slice(0, 10));
  };
  const handleDatePop = () => {
    setIsPopup(!isPopup);
    setIsOpen(false);
  };

  return (
    <Page>
      <Formik
        initialValues={{
          display_name: user.display_name,
          last_name: user.last_name,
          first_name: user.first_name,
          email: user.email,
          country_id: userProfile?.country?.id || user.country_id,
          country_name: userProfile?.country?.name || user.country_name,
          state_id: userProfile?.state?.id || user.state_id,
          state_name: userProfile?.state?.name || user.state_name,
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          const userData = {
            id: user.id,
            profile_url: profileUrl,
            cover_url: coverUrl,
            first_name: values.first_name,
            last_name: values.last_name,
            display_name: values.display_name || '',
            email: values.email,
            dob,
            country_id: values.country_id,
            country_name: values.country_name,
          };
          if (values.country_name === 'United States of America') {
            userData.state_id = values.state_id;
            userData.state_name = values.state_name;
          }

          updateUser(userData);
        }}
        validationSchema={Yup.object().shape({
          country_name: Yup.string()
            .required('Required'),
          state_name: Yup.string()
            .nullable()
            .when('country', {
              is: (val) => (val === 'United States of America'),
              then: Yup.string().required(),
            }),
          display_name: Yup.string()
            .nullable(),
          last_name: Yup.string()
            .required('Required'),
          first_name: Yup.string()
            .required('Required'),
          email: Yup.string()
            .required('Required'),
        })}
      >
        {({
          errors,
          touched,
          values,
          isSubmitting,
          handleChange,
          handleSubmit,
          handleBlur,
        }) => (
          <form onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.form}>
            <Box className={classes.root}>
              <Box>
                <BackButton color={commonClasses.backBtn} />
              </Box>
              <Box textAlign="center" mb={4} mt={4}>
                <Typography component="h3" className={classes.title}>
                  Edit Profile
                </Typography>
                <Typography component="h3" className={classes.content}>
                  Manage your profile information
                </Typography>
              </Box>
              <Box>
                <Typography component="h3" className={classes.sectionTitle}>
                  Public Profile
                </Typography>
                <PublicProfilePhoto profileUrl={profileUrl} changeUrl={setProfileUrl} />
                <label className={classes.fieldLabel}>Background Image</label>
                <CoverPhoto coverUrl={coverUrl} changeUrl={setCoverUrl} />
                <TextField
                  label="Display Name"
                  className={classes.textField}
                  placeholder="Enter Display Name"
                  helperText=""
                  fullWidth
                  name="display_name"
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.display_name}
                />
                {
                  (errors.display_name && touched.display_name) && (
                  <Typography className={commonClasses.error}>
                    {errors.display_name}
                  </Typography>
                  )
                }
              </Box>
              <Box>
                <Typography component="h3" className={classes.sectionTitle}>
                  Account Details
                </Typography>
                <TextField
                  label="First Name"
                  className={classes.textField}
                  placeholder="Enter First Name"
                  onBlur={handleBlur}
                  helperText=""
                  onChange={handleChange}
                  name="first_name"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={values.first_name}
                />
                {
                  (errors.first_name && touched.first_name) && (
                  <Typography className={commonClasses.error}>
                    {errors.first_name}
                  </Typography>
                  )
                }
                <TextField
                  label="Last Name"
                  className={classes.textField}
                  placeholder="Enter Last Name"
                  helperText=""
                  onBlur={handleBlur}
                  name="last_name"
                  fullWidth
                  onChange={handleChange}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={values.last_name}
                />
                {
                  (errors.last_name && touched.last_name) && (
                  <Typography className={commonClasses.error}>
                    {errors.last_name}
                  </Typography>
                  )
                }
                <TextField
                  label="Email"
                  className={classes.textField}
                  placeholder="Enter Email"
                  helperText=""
                  onBlur={handleBlur}
                  name="email"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={values.email}
                />
                {
                  (errors.email && touched.email) && (
                  <Typography className={commonClasses.error}>
                    {errors.email}
                  </Typography>
                  )
                }
                <FormControl
                  className={classes.birthday}
                  variant="outlined"
                  fullWidth
                  onClick={() => handleDatePop()}
                >
                  <div className={classes.dateField} onClick={() => handleDatePop()}>
                    <TextField
                      label="Date of Birth"
                      className={classes.textField}
                      placeholder="Enter DOB"
                      helperText=""
                      type="date"
                      name="dob"
                      fullWidth
                      id="dob"
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        max: new Date().toISOString().slice(0, 10),
                        'aria-label': 'dob',
                      }}
                      value={dob}
                    />
                    <div className={classes.dateContainer} />
                  </div>
                  <Box>
                    <DatePicker
                      isPopup={isPopup}
                      isOpen={isOpen}
                      theme="android"
                      max={new Date()}
                      onSelect={handleSelect}
                      onCancel={() => setIsPopup(!isPopup)}
                      dateConfig={dateConfig}
                      showHeader={false}
                      cancelText="Cancel"
                      confirmText="OK"
                    />
                  </Box>
                </FormControl>
                {
                  (errors.dob && touched.dob) && (
                  <Typography className={commonClasses.error}>
                    {errors.dob}
                  </Typography>
                  )
                }
                <TextField
                  label="Country"
                  className={classes.textField}
                  placeholder="Select Country"
                  onClick={() => history.push('/more/setting/profile/country')}
                  helperText=""
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="country_name"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end"><ExpandMoreIcon className={classes.downIcon} /></InputAdornment>,
                  }}
                  value={userProfile?.country?.name || values.country_name}
                >
                  Country
                </TextField>
                {
                  (errors.country_name && touched.country_name) && (
                  <Typography className={commonClasses.error}>
                    {errors.country_name}
                  </Typography>
                  )
                }
                {
                  (values.country_name === 'United States of America') && (
                  <TextField
                    label="State"
                    className={classes.textField}
                    onClick={() => history.push('/more/setting/profile/state')}
                    placeholder="Select State"
                    helperText=""
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="state_name"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={values.state_name}
                    InputProps={{
                      endAdornment: <InputAdornment position="end"><ExpandMoreIcon className={classes.downIcon} /></InputAdornment>,
                    }}
                  >
                    State
                  </TextField>
                  )
                }
                {
                  (errors.state_name && touched.state_name) && (
                  <Typography className={commonClasses.error}>
                    {errors.state_name}
                  </Typography>
                  )
                }
              </Box>
              <OutlineButton
                type="submit"
                style={{ margin: '20px 0' }}
                disabled={isSubmitting}
              >
                Save
              </OutlineButton>
            </Box>
          </form>
        )}
      </Formik>
    </Page>
  );
};

SettingProfile.propTypes = {
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  updateUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  updateUser: (data) => dispatch(updateUserAction(data)),
  getMe: () => dispatch(getUserAction()),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SettingProfile));
