import * as actionTypes from 'store/actionTypes';
import countryService from 'services/country.service';
import storageService from 'services/storage.service';
import config from 'utils/config';

export const getCountriesAction = (params = {}) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.GET_COUNTRY_REQUEST,
    });

    try {
      const response = await countryService.getCountries(params);

      dispatch({
        type: actionTypes.GET_COUNTRY_SUCCESS,
        payload: { countries: response },
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.GET_COUNTRY_FAIL,
      });
    }
  } else {
    dispatch({
      type: actionTypes.GET_COUNTRY_FAIL,
    });
  }
};

export const getRegistrationLocationsAction = (params = {}) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.GET_REGISTRATION_LOCATION_REQUEST,
    });

    try {
      const response = await countryService.getRegistrationLocations(params);

      dispatch({
        type: actionTypes.GET_REGISTRATION_LOCATION_SUCCESS,
        payload: { locations: response },
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.GET_REGISTRATION_LOCATION_FAIL,
      });
    }
  } else {
    dispatch({
      type: actionTypes.GET_REGISTRATION_LOCATION_FAIL,
    });
  }
};
