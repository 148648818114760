import * as actionTypes from 'store/actionTypes';

const initialModal = {
  successModal: {
    title: 'Success',
    subtitle: '',
    buttonText: 'Ok',
    nextPath: '',
    callback: () => {},
  },
  confirmModal: {
    title: 'Confirm',
    subtitle: '',
    agreeBtnText: 'Ok',
    disagreeBtnText: 'Cancel',
    callback: () => {},
  },
  errorModal: {
    title: 'Oops!',
    subtitle: '',
    buttonText: 'GOT IT',
    callback: () => {},
  },
};

const INITIAL_STATE = {
  showSuccessModal: false,
  showErrorModal: false,
  showConfirmModal: false,
  successModal: {
    ...initialModal.successModal,
  },
  confirmModal: {
    ...initialModal.confirmModal,
  },
  errorModal: {
    ...initialModal.errorModal,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.CLOSE_MODAL:
      return {
        ...state,
        showSuccessModal: false,
        showErrorModal: false,
        showConfirmModal: false,
      };
    case actionTypes.OPEN_SUCCESS_MODAL:
      return {
        ...state,
        showSuccessModal: true,
        successModal: { ...initialModal.successModal, ...action.payload },
      };
    case actionTypes.OPEN_CONFIRM_MODAL:
      return {
        ...state,
        showConfirmModal: true,
        confirmModal: { ...initialModal.confirmModal, ...action.payload },
      };
    case actionTypes.OPEN_ERROR_MODAL:
      return {
        ...state,
        showErrorModal: true,
        errorModal: { ...initialModal.errorModal, ...action.payload },
      };
    default:
      return state;
  }
};
