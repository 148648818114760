import * as actionTypes from 'store/actionTypes';
import notificationService from 'services/notification.service';
import storageService from 'services/storage.service';
import config from 'utils/config';

export const getNotificationSettingsAction = () => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.GET_NOTIFICATION_SETTING_REQUEST,
    });
    try {
      const response = await notificationService.getNotificationSettings();
      dispatch({
        type: actionTypes.GET_NOTIFICATION_SETTING_SUCCESS,
        payload: response,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.GET_NOTIFICATION_SETTING_FAIL,
      });
    }
  } else {
    dispatch({
      type: actionTypes.GET_NOTIFICATION_SETTING_FAIL,
    });
  }
};

export const updateNotificationSettingsAction = (body) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.UPDATE_NOTIFICATION_SETTING_REQUEST,
    });

    try {
      const response = await notificationService.updateNotificationSettings(body);
      dispatch({
        type: actionTypes.UPDATE_NOTIFICATION_SETTING_SUCCESS,
        payload: response,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.UPDATE_NOTIFICATION_SETTING_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.UPDATE_NOTIFICATION_SETTING_FAIL,
    });
  }
};

export const getNotificationsListAction = (body = {}) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.GET_NOTIFICATIONS_REQUEST,
    });

    try {
      const response = await notificationService.getNotifications(body);

      dispatch({
        type: actionTypes.GET_NOTIFICATIONS_SUCCESS,
        payload: response,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.GET_NOTIFICATIONS_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.GET_NOTIFICATIONS_FAIL,
    });
  }
};

export const resetNotificationBadgeAction = () => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.RESET_NOTIFICATION_BADGE_REQUEST,
    });

    try {
      const response = await notificationService.resetNotificationBadge();

      dispatch({
        type: actionTypes.RESET_NOTIFICATION_BADGE_SUCCESS,
        payload: response,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.RESET_NOTIFICATION_BADGE_FAIL,
      });
    }
  } else {
    dispatch({
      type: actionTypes.RESET_NOTIFICATION_BADGE_FAIL,
    });
  }
};
