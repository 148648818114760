import { useSelector, useDispatch } from 'react-redux';

import {
  openSuccessModalAction,
  openErrorModalAction,
  closeModalAction,
  openConfirmModalAction,
} from 'store/actions/modal';

export const useModal = () => useSelector(({ modal }) => modal);

export const useSuccessModal = () => useSelector(({
  modal: { successModal },
}) => successModal);

export const useShowSuccessModal = () => useSelector(({
  modal: { showSuccessModal },
}) => showSuccessModal);

export const useErrorModal = () => useSelector(({
  modal: { errorModal },
}) => errorModal);

export const useShowErrorModal = () => useSelector(({
  modal: { showErrorModal },
}) => showErrorModal);

export const useConfirmModal = () => useSelector(({
  modal: { confirmModal },
}) => confirmModal);

export const useShowConfirmModal = () => useSelector(({
  modal: { showConfirmModal },
}) => showConfirmModal);

export const useOpenSuccessModalDispatch = () => {
  const dispatch = useDispatch();
  return (data) => dispatch(openSuccessModalAction(data));
};

export const useOpenConfirmModalDispatch = () => {
  const dispatch = useDispatch();
  return (data) => dispatch(openConfirmModalAction(data));
};

export const useOpenErrorModalDispatch = () => {
  const dispatch = useDispatch();
  return (data) => dispatch(openErrorModalAction(data));
};

export const useCloseModalDispatch = () => {
  const dispatch = useDispatch();
  return (data) => dispatch(closeModalAction(data));
};
