import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { forceDismissKeyboard } from 'utils';

const FormattedNumberInput = (props) => {
  const {
    inputRef, onChange, onBlur, decimalScale, ...other
  } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onKeyUp={((event) => {
        const code = event.keyCode || event.which;
        if (code === 13) {
          forceDismissKeyboard();
        }
      })}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      decimalScale={decimalScale}
      decimalSeparator="."
      thousandSeparator=","
      inputMode="decimal"
      pattern="[0-9],*"
      isNumericString
    />
  );
};

FormattedNumberInput.defaultProps = {
  decimalScale: null,
  prefix: '',
};

FormattedNumberInput.propTypes = {
  onBlur: PropTypes.func.isRequired,
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  decimalScale: PropTypes.number,
  prefix: PropTypes.string,
};

export default FormattedNumberInput;
