import React, { useCallback } from 'react';
import { withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import { Box, Button, Typography } from '@material-ui/core';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import DebitCard from 'components/Deposit/DebitCard';

import {
  useAccountOverviewInfo,
  useGetOverviewActionDispatch,
  useDeleteFundsMethodActionDispatch,
} from 'hooks/redux/account';
import commonStyle from 'styles/common';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 15px',
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
    padding: 10,
  },
  add: {
    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.15)',
    borderRadius: '5px',
    background: palette.cardColor,
    color: palette.secondaryColor,
    minHeight: 40,
    font: '16px SofiaPro-Bold',
  },
}));

const Debits = () => {
  const classes = useStyles();
  const commonClasses = commonStyle();
  const overview = useAccountOverviewInfo();
  const getOverview = useGetOverviewActionDispatch();
  const deleteFundsMethod = useDeleteFundsMethodActionDispatch();

  const handleDeleteDebitCard = useCallback(async (id) => {
    await deleteFundsMethod({ method_id: id });
    getOverview();
  }, [deleteFundsMethod, getOverview]);

  const handleClickAddCard = () => {
    // history.push('/funds/deposit/debit-card/add');
    window.location.href = '/funds/deposit/debit-card/add';
  };

  return (
    <Page>
      <Box className={classes.root}>
        <Box>
          <BackButton color={commonClasses.backBtn} />
        </Box>
        <Box textAlign="center" mb={4}>
          <Typography component="h3" className={classes.title}>
            Manage Debit Cards
          </Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Button className={classes.add} onClick={handleClickAddCard}>
            Add Debit Card
          </Button>
        </Box>
        {
          overview?.active_cards?.length > 0 && overview.active_cards.map((debit) => (
            <DebitCard
              item={debit}
              handleDelete={handleDeleteDebitCard}
              key={debit.id}
            />
          ))
        }
      </Box>
    </Page>
  );
};

Debits.propTypes = {};

export default withRouter(Debits);
