import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  Box,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import OutlineButton from 'components/Buttons/OutlineButton';
import BetResultInfoCard from 'components/Bet/BetResultInfoCard';
import resultIconImage from 'assets/images/icon/result.png';

import {
  useDeleteBetDispatch,
  useSetLeagueDispatch,
  useSetIntervalsDispatch,
  useSetGamesDispatch,
  useSetBetObjectDispatch,
  useSetBetTypeDispatch,
  useSetBetTimeDispatch,
  useSetBetOddsDispatch,
} from 'hooks/redux/bet';
import { useSelectCurrencyDispatch } from 'hooks/redux/currency';
import { useOpenConfirmModalDispatch, useOpenErrorModalDispatch } from 'hooks/redux/modal';
import { getErrorMessage } from 'utils';

const useStyles = makeStyles(({ palette }) => ({
  icon: {
    marginLeft: '5px',
    color: '#29CCD3',
    fontSize: '20px',
  },
  content: {
    color: palette.secondaryColor,
    font: '14px SofiaPro-Bold',
    textAlign: 'center',
  },
  disputeContent: {
    color: palette.secondaryColor,
    font: '14px SofiaPro',
    textAlign: 'left',
    marginBottom: '10px',
    '& span': {
      display: 'block',
    },
  },
  accept: {
    borderTop: '1px solid #cccccc',
    borderBottom: '1px solid #cccccc',
  },
  subtitle: {
    color: palette.secondaryColor,
    font: '14px SofiaPro-Medium',
    paddingBottom: '5px',
  },
  smallTitle: {
    color: palette.secondaryColor,
    font: '14px SofiaPro-Medium',
    paddingBottom: '5px',
  },
  amount: {
    color: palette.secondaryColor,
    font: '15px SofiaPro-Bold',
    textAlign: 'center',
    paddingBottom: '0px',
  },
  total: {
    borderBottom: '1px solid #cccccc',
  },
  results: ({ resultColor }) => ({
    backgroundColor: palette.type === 'light' ? resultColor : palette.cardBetColor,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '16px 5px',
  }),
  noResult: {
    color: '#A4A4A4',
    font: '14px SofiaPro-Bold',
  },
  resultImage: {
    width: '60px',
  },
  card: {
    backgroundColor: palette.cardColor,
    width: '90%',
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 4px',
    borderRadius: '6px',
    padding: '10px',
    marginBottom: '10px',
  },
  cardTitle: {
    paddingBottom: '5px',
    color: palette.resultsColor,
    textAlign: 'center',
  },
  delete: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 10,
    font: '17px SofiaPro-SemiBold',
    letterSpacing: '0.3px',
    color: '#29CDD4',
  },
  percentage: {
    color: palette.secondaryColor,
    font: '15px SofiaPro-Medium',
    textAlign: 'center',
    paddingBottom: '5px',
  },
  actionContainer: {
    background: palette.cardColor,
  },
}));

const MakerView = ({ history, betInfo }) => {
  const classes = useStyles({
    resultColor: (betInfo.status === 'results_set' || betInfo.status === 'settled') ? '#d3f2f5' : '#eeeeee',
  });

  const setLeague = useSetLeagueDispatch();
  const setInterval = useSetIntervalsDispatch();
  const setGames = useSetGamesDispatch();
  const setBetObject = useSetBetObjectDispatch();
  const setBetType = useSetBetTypeDispatch();
  const setBetTime = useSetBetTimeDispatch();
  const setBetOdds = useSetBetOddsDispatch();
  const deleteBet = useDeleteBetDispatch();
  const selectCurrency = useSelectCurrencyDispatch();
  const openConfirmModal = useOpenConfirmModalDispatch();
  const openErrorModal = useOpenErrorModalDispatch();

  const handleEdit = () => {
    selectCurrency(betInfo.currency?.symbol);
    setLeague(betInfo.bettingObject.league);
    setInterval([betInfo.interval]);
    setGames(betInfo.games);
    setBetObject(betInfo.bettingObject[betInfo.objectType]);
    setBetType({
      id: betInfo.betType.id,
      name: betInfo.betType.name,
      is_write_in: betInfo.betType.is_write_in,
      has_goal: betInfo.betType.has_goal,
      goal: betInfo.goal,
      description: betInfo.details,
      attribute: {
        group_id: betInfo.attributes[0]?.bet_type_attribute_group?.id,
        group_name: betInfo.attributes[0]?.bet_type_attribute_group?.name,
        id: betInfo.attributes[0]?.bet_type_attribute?.id,
        name: betInfo.attributes[0]?.bet_type_attribute?.name,
      },
    });
    setBetTime({
      should_expire: betInfo.shouldExpire,
      current_history: betInfo.currentHistory,
    });
    setBetOdds({
      proposed_bet_amount: betInfo.betAmount / 10 ** betInfo.currency.decimal_places,
      proposed_return: betInfo.proposedReturnAmount / 10 ** betInfo.currency.decimal_places,
    });
    history.push(`/bets/edit/${betInfo.id}/select-game`);
  };

  const handleDelete = () => {
    openConfirmModal({
      title: 'Are you sure?',
      subtitle: `Are you sure you want delete this bet?
      Doing so will remove the bet from others being able to accept it.
      Your original bet amount will be refunded back to your Available Balance.`,
      agreeBtnText: 'Yes, Delete Bet',
      disagreeBtnText: 'No, Keep Bet',
      callback: async () => {
        try {
          await deleteBet({
            betId: betInfo.id,
          });
          history.push('/bets/results');
        } catch (e) {
          openErrorModal({
            title: 'Delete Error',
            subtitle: getErrorMessage(e),
          });
        }
      },
    });
  };

  return (
    <>
      <BetResultInfoCard betInfo={betInfo} showAcceptTime />
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        pb={1}
        pt={1}
        className={classes.accept}
      >
        <Typography variant="subtitle2" className={classes.smallTitle}>
          Filled/Accepted
        </Typography>
        <Typography variant="subtitle2" style={{ color: `${betInfo.currencyColor}` }} className={classes.amount}>
          {betInfo.formattedFilledPercentageAmount}
        </Typography>
        <Typography variant="subtitle2" className={classes.percentage}>
          {betInfo.closedPercentageText}
        </Typography>
      </Box>
      {(betInfo.result === 'win' && betInfo.status === 'settled') && (
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          className={classes.total}
          pb={1}
          pt={1}
        >
          <Typography variant="subtitle2" className={classes.smallTitle}>
            Total Payout Received, Bet + Winnings
          </Typography>
          <Typography variant="subtitle2" style={{ color: `${betInfo.currencyColor}` }} className={classes.amount}>
            {betInfo.formattedTotalReceiveAmount}
          </Typography>
        </Box>
      )}
      {(betInfo.result === 'win' && betInfo.status === 'results_set') && (
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          className={classes.total}
          pb={1}
          pt={1}
        >
          <Typography variant="subtitle2" className={classes.smallTitle}>
            Total You’ll Receive If You Win
          </Typography>
          <Typography variant="subtitle2" style={{ color: `${betInfo.currencyColor}` }} className={classes.amount}>
            {betInfo.formattedTotalReceiveAmount}
          </Typography>
        </Box>
      )}
      <Box className={classes.results} p={2}>
        <img alt="bet" src={resultIconImage} className={classes.resultImage} />
        <Typography variant="subtitle2" className={classes.subtitle}>Bet results</Typography>
        {betInfo.result && (
          <>
            <Box display="flex" mt={3} style={{ flexWrap: 'wrap' }} className={classes.card}>
              <Grid item xs={6} sm={6}>
                <Typography variant="subtitle2" className={classes.cardTitle}>
                  Winner of Bet
                </Typography>
                <Typography variant="subtitle2" className={classes.content}>
                  {betInfo.winnerDescription}
                </Typography>
              </Grid>
              {(betInfo.awayTeamPoints && betInfo.homeTeamPoints) && (
                <>
                  <Grid item xs={6} sm={6}>
                    <Typography variant="subtitle2" className={classes.cardTitle}>
                      Team Scores
                    </Typography>
                    <Typography variant="subtitle2" className={classes.content}>
                      {!betInfo.isSoccerGame ? betInfo.awayTeamName : betInfo.homeTeamName}
                      {' '}
                      {!betInfo.isSoccerGame ? betInfo.awayTeamPoints : betInfo.homeTeamPoints}
                    </Typography>
                    <Typography variant="subtitle2" className={classes.content}>
                      {!betInfo.isSoccerGame ? betInfo.homeTeamName : betInfo.awayTeamName}
                      {' '}
                      {!betInfo.isSoccerGame ? betInfo.homeTeamPoints : betInfo.awayTeamPoints}
                    </Typography>
                  </Grid>
                </>
              )}
              {betInfo.resultDetails && (
                <Grid item xs={6} sm={6}>
                  <Typography variant="subtitle2" className={classes.cardTitle}>
                    Result Details
                  </Typography>
                  <Typography variant="subtitle2" className={classes.content}>
                    {betInfo.resultDetails}
                  </Typography>
                </Grid>
              )}
              {betInfo.resultDetailsList?.length === 2 && (
                <Grid item xs={6} sm={6}>
                  <Typography variant="subtitle2" className={classes.cardTitle}>
                    {betInfo.resultDetailsList[1].title}
                  </Typography>
                  <Typography variant="subtitle2" className={classes.content}>
                    {betInfo.resultDetailsList[1].details}
                  </Typography>
                </Grid>
              )}
            </Box>
            {((betInfo.disputes?.length > 0) || (betInfo.status === 'results_set' && betInfo.result === 'win')) && (
              <Grid item xs={12} sm={12} className={classes.card}>
                <Typography variant="subtitle2" className={classes.disputeContent}>
                  <b>Dispute Status: </b>
                  {betInfo.disputeStatus}
                </Typography>
                {(betInfo.disputes.length > 0) && (
                  <Typography variant="subtitle2" className={classes.disputeContent}>
                    <b>Dispute Details Submitted by Taker: </b>
                    {betInfo.disputes.map((detail) => (
                      <span key={detail.details}>
                        {detail.details}
                      </span>
                    ))}
                  </Typography>
                )}
              </Grid>
            )}
          </>
        )}
        {(betInfo.status === 'expired' || betInfo.status === 'proposed') && (
          <Box mt={3} textAlign="center">
            <Typography className={classes.noResult}>
              No Results to display for this bet because it was not accepted by any Takers.
            </Typography>
          </Box>
        )}
      </Box>
      {betInfo.status === 'proposed' && (
        <Box display="flex" mt={3} className={classes.actionContainer}>
          <Grid item xs={6} sm={6} className={classes.delete}>
            <Typography className="text-center" onClick={handleDelete}>
              Delete Bet
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Box p={2}>
              <OutlineButton onClick={handleEdit}>
                Edit Bet
              </OutlineButton>
            </Box>
          </Grid>
        </Box>
      )}
    </>
  );
};

MakerView.defaultProps = {
  betInfo: {},
};

MakerView.propTypes = {
  history: PropTypes.object.isRequired,
  betInfo: PropTypes.object,
};

export default withRouter(MakerView);
