import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import {
  Box,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import Button from 'components/Buttons/WhiteButton';

import { comingSoonMessage } from 'utils/constants';

import {
  useSetGamesDispatch,
  useSetIntervalsDispatch,
} from 'hooks/redux/bet';

import CreateBetStep2Dialog from 'components/Dialog/CreateBetStep2Dialog';
import ZenDialog from 'components/Dialog/ZenDialog';
import commonStyle from 'styles/common';

const navItems = [
  {
    path: '/bets/create/select-season',
    interval: 'season',
    label: 'Season (Futures)',
  },
  {
    path: '/bets/create/select-game',
    interval: 'multiple_games',
    label: 'Multiple Games (Partial Season)',
  },
  {
    path: '/bets/create/select-game',
    interval: 'single_game',
    label: 'Single Game',
  },
];

const useStyles = makeStyles(() => ({
  root: {
    padding: '0 15px',
  },
  line: {
    background: 'linear-gradient(45deg, #298EDA, #29D2D3)',
    height: '3px',
    marginLeft: '-27px',
    width: '40%',
  },
}));

const SelectTimePeriod = ({ history }) => {
  const classes = useStyles();
  const commonClasses = commonStyle();

  const setIntervals = useSetIntervalsDispatch();
  const setGames = useSetGamesDispatch();
  const [comingSoonDialogOpen, setComingSoonDialogOpen] = useState(false);
  const handleCloseComingSoonDialog = () => {
    setComingSoonDialogOpen(false);
  };

  const handleNext = useCallback((nav) => {
    setIntervals([nav.interval]);
    setGames([]);
    if (nav.interval === 'multiple_games') {
      setComingSoonDialogOpen(true);
    } else {
      history.push(nav.path);
    }
  }, [history, setIntervals, setGames]);

  return (
    <Page>
      <Box className={classes.root}>
        <Box className={classes.line} mb={2} />
        <Box>
          <BackButton color={commonClasses.backBtn} />
        </Box>
        <Typography component="p" className={commonClasses.topic2}>
          STEP 2
        </Typography>
        <Box display="flex" justifyContent="center" alignItems="center" mb={4}>
          <Typography component="h3" className={commonClasses.title2}>
            What time period is this bet for?
            <CreateBetStep2Dialog />
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column">
          {navItems.map((navItem) => (
            <Button
              onClick={() => handleNext(navItem)}
              key={navItem.interval}
            >
              {navItem.label}
            </Button>
          ))}
        </Box>
      </Box>
      {comingSoonDialogOpen && (
        <ZenDialog
          title="Feature Coming Soon!"
          message={comingSoonMessage}
          cta="Close"
          customCta="Download iOS/Android App"
          customCtaLink="zensports.com"
          onClose={handleCloseComingSoonDialog}
        />
      )}
    </Page>
  );
};

SelectTimePeriod.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(SelectTimePeriod);
