import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { debounce } from 'lodash';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import TuneIcon from '@material-ui/icons/Tune';

import Page from 'components/Page/Dashboard';
import Loading from 'components/Loading/Box';
import BackButton from 'components/Buttons/BackButton';
import BetInfoCard from 'components/Bet/BetInfoCard';
import VList from 'components/VList';
import SearchInput from 'components/Form/SearchInput';
import ParlayCart from 'components/Parlay/ParlayCart';

import editIconImage from 'assets/images/icon/edit.png';
import favoriteEmptyImage from 'assets/images/favorite/favorite_empty.png';

import { getBetInfo } from 'utils/betting';
import { getFilterQuery } from 'utils/betting.sort';
import { useBet, useBetFavoriteList, useBetSelected } from 'hooks/redux/bet';
import { useMappedCurrencies } from 'hooks/redux/currency';
import { useUserOddType, useUserFavorites, useAuthUser } from 'hooks/redux/user';
import { useParlay } from 'hooks/redux/parlay';
import commonStyle from 'styles/common';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 8px',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  filterBtn: {
    transform: 'rotate(-90deg)',
    color: palette.secondaryColor,
    fontSize: '2rem',
  },
  editButton: {
    backgroundColor: palette.fvrtEditBgColor,
    border: palette.fvrtEditBorderColor,
    padding: '6px 17px',
    borderRadius: '40px',
  },
  editTitle: {
    font: '15px SofiaPro-Bold',
    color: palette.secondaryColor,
  },
  editIcon: {
    width: '30px',
    marginRight: '5px',
    filter: palette.invertImage,
  },
  emptyImage: {
    width: '80px',
  },
  content: {
    marginTop: '20px',
    font: '16px SofiaPro',
    color: '#aeaeae',
    textAlign: 'center',
  },
  createFav: {
    padding: 0,
    width: '70%',
    background: palette.pillActiveBgColor,
    border: '2px solid #29CFD4',
    borderRadius: '6px',
    boxShadow: '0 1px 20px 0 rgba(0,214,219,0.3), 0 3px 10px 0 rgba(0,214,219,0.3)',
    minHeight: 50,
    font: '17px SofiaPro-SemiBold',
    letterSpacing: '0.3px',
    color: palette.fvrtBtnColor,
    display: 'block',
    margin: '50px auto',
  },
}));

const Favorites = ({ history }) => {
  const classes = useStyles();
  const commonClasses = commonStyle();
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState();
  const [debounceSearch, setDebounceSearch] = useState('');

  const betting = useBet();
  const favorites = useUserFavorites();
  const parlay = useParlay();
  const bets = useBetFavoriteList(debounceSearch, options);
  const filter = useBetSelected();
  const oddType = useUserOddType();
  const user = useAuthUser();
  const mappedCurrencies = useMappedCurrencies();

  // eslint-disable-next-line
  const doDebounceSearch = useCallback(
    debounce((value) => setDebounceSearch(value), 500), [debounce, setDebounceSearch],
  );

  const handleSearch = (e) => {
    setSearch(e.target.value);
    doDebounceSearch(e.target.value);
  };

  const handleFilter = () => {
    history.push('/bets/favorites/filter');
  };

  const handleSelectCard = useCallback((betId) => {
    history.push(`/bets/view/${betId}`);
  }, [history]);

  useEffect(() => {
    if (filter && favorites && user.id && mappedCurrencies) {
      filter.favorites = favorites;
      filter.user = user;
      setOptions({
        hitsPerPage: 1000,
        page: 0,
        filters: getFilterQuery(filter, mappedCurrencies, 'favorite'),
        filter,
      });
    }
  // eslint-disable-next-line
  }, [filter]);

  return (
    <Page>
      <Box className={classes.root}>
        <Box
          display="flex"
          justifyContent="left"
          alignItems="baseline"
        >
          <BackButton color={commonClasses.backBtn} />
          {(!favorites || favorites.length === 0) && (
            <>
              <SearchInput
                handleChange={handleSearch}
                placeholder="e.g. NFL,hocky, Lakers…"
                value={search}
              />
              <TuneIcon className={classes.filterBtn} onClick={() => handleFilter()} />
            </>
          )}
        </Box>

        <Box className={commonClasses.vListWrapper} mt={3}>
          {!betting.isLoading ? (bets.length > 0 ? (
            <VList
              rowCount={bets.length}
              rowRender={(index) => (
                <>
                  {index === 0
                  && (
                  <>
                    <Typography variant="h3" className={commonClasses.title2}>
                      Favorites
                    </Typography>
                    <Box display="flex" justifyContent="center" alignItems="center" mb={3} mt={1}>
                      <Box
                        display="inline-flex"
                        alignItems="center"
                        className={classes.editButton}
                        onClick={() => history.push('/bets/favorites/edit')}
                      >
                        <img src={editIconImage} alt="edit" className={classes.editIcon} />
                        <Typography variant="h3" className={classes.editTitle}>
                          Edit Favorites
                        </Typography>
                      </Box>
                    </Box>
                  </>
                  )}
                  {bets[index].type !== 'parlay' && (
                    <BetInfoCard
                      betInfo={getBetInfo(bets[index], oddType)}
                      onClick={() => handleSelectCard(bets[index].id)}
                    />
                  )}
                </>
              )}
            />
          ) : (
            <>
              <Typography variant="h3" className={classes.title}>
                Favorites
              </Typography>
              {(!favorites || favorites.length === 0) ? (
                <>
                  <Typography style={{ marginTop: '50px' }} variant="subtitle2" className={classes.content}>
                    {`You don't currenlty have any favorite bet types set up. Creating some Favorites gives you the ability to
                    quickly view and accept the bet types taht are most important to you. Click the "Create Favorites" button below
                    to get started.`}
                  </Typography>
                  <button type="button" className={classes.createFav} onClick={() => history.push('/bets/favorites/edit')}>
                    Create Favorites
                  </button>
                  <Typography variant="subtitle2" className={classes.content}>
                    {`* Pro tip: After you've created some favorites, you can edit your favorites at any time by tapping on
                    the "Edit Favorites" button that will appear at the top of this screen`}
                  </Typography>
                </>
              ) : (
                <>
                  <Box display="flex" justifyContent="center" alignItems="center" mb={10} mt={1}>
                    <Box
                      display="inline-flex"
                      alignItems="center"
                      className={classes.editButton}
                      onClick={() => history.push('/bets/favorites/edit')}
                    >
                      <img src={editIconImage} alt="edit" className={classes.editIcon} />
                      <Typography variant="h3" className={classes.editTitle}>
                        Edit Favorites
                      </Typography>
                    </Box>
                  </Box>
                  <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" pt={5}>
                    <img src={favoriteEmptyImage} alt="empty" className={classes.emptyImage} />
                    <Typography variant="subtitle2" className={classes.content}>
                      {`Unfortunately, there aren't any bets that match the favorite criteria that you've selected.
                      Tap the "Edit Favorites" button above to add more favorites and expand your search criteria.`}
                    </Typography>
                  </Box>
                </>
              )}
            </>
          )) : (<Loading />)}
        </Box>
      </Box>
      {parlay?.list.length > 0 && (
        <ParlayCart parlay={parlay} />
      )}
    </Page>
  );
};

Favorites.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(Favorites);
