import * as actionTypes from 'store/actionTypes';
import storageService from 'services/storage.service';
import algoliaService from 'services/algolia.service';
import playService from 'services/play.service';
import config from 'utils/config';
import { sortCompetitions } from 'utils/play.sort';

export const getPlayListAction = (query, options = {}) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.GET_PLAY_REQUEST,
    });

    try {
      const copied = { ...options };
      delete copied.filter;
      const response = await algoliaService.search('TournamentLeague', query, copied);

      dispatch({
        type: actionTypes.GET_PLAY_SUCCESS,
        payload: { list: sortCompetitions(response, options) },
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.GET_PLAY_FAIL,
      });
    }
  } else {
    dispatch({
      type: actionTypes.GET_PLAY_FAIL,
    });
  }
};

export const getSportListAction = (options = {}) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.GET_SPORTS_REQUEST,
    });

    try {
      const response = await algoliaService.search('Sport', '', options);

      dispatch({
        type: actionTypes.GET_SPORTS_SUCCESS,
        payload: { list: response },
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.GET_SPORTS_FAIL,
      });
    }
  } else {
    dispatch({
      type: actionTypes.GET_SPORTS_FAIL,
    });
  }
};

export const setPlayFilterAction = (payload) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_SELECTED_PLAY_FILTER,
    payload,
  });
};

export const setTournamentQueryAction = (payload) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_TOURNAMENT_QUERY,
    payload,
  });
};

export const createTournamentAction = (body = {}) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.CREATE_TOURNAMENT_REQUEST,
    });

    try {
      const response = await playService.createTournament(body);
      dispatch({
        type: actionTypes.CREATE_TOURNAMENT_SUCCESS,
        payload: response,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.CREATE_TOURNAMENT_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.CREATE_TOURNAMENT_FAIL,
    });
  }
};

export const getTournamentDetailsAction = (id) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.GET_TOURNAMENT_DETAILS_REQUEST,
    });

    try {
      const response = await playService.getTournamentDetails(id);
      dispatch({
        type: actionTypes.GET_TOURNAMENT_DETAILS_SUCCESS,
        payload: response,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.GET_TOURNAMENT_DETAILS_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.GET_TOURNAMENT_DETAILS_FAIL,
    });
  }
};

export const getTournamentUsersAction = (id) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.GET_TOURNAMENT_USERS_REQUEST,
    });

    try {
      const response = await playService.getTournamentUsers(id);
      dispatch({
        type: actionTypes.GET_TOURNAMENT_USERS_SUCCESS,
        payload: response,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.GET_TOURNAMENT_USERS_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.GET_TOURNAMENT_USERS_FAIL,
    });
  }
};

export const currentUserInTournamentAction = (id) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.CURRENT_USER_IN_TOURNAMENT_REQUEST,
    });

    try {
      const response = await playService.currentUserInTournament(id);
      dispatch({
        type: actionTypes.CURRENT_USER_IN_TOURNAMENT_SUCCESS,
        payload: response,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.CURRENT_USER_IN_TOURNAMENT_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.CURRENT_USER_IN_TOURNAMENT_FAIL,
    });
  }
};

export const getUpcomingMatchesAction = (id) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.GET_UPCOMING_MATCHES_REQUEST,
    });

    try {
      const response = await playService.getUpcomingMatches(id);
      dispatch({
        type: actionTypes.GET_UPCOMING_MATCHES_SUCCESS,
        payload: response,
      });
      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.GET_UPCOMING_MATCHES_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.GET_UPCOMING_MATCHES_FAIL,
    });
  }
};

export const getInProgressMatchesAction = (id) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.GET_IN_PROGRESS_MATCHES_REQUEST,
    });

    try {
      const response = await playService.getMatchesInProgress(id);
      dispatch({
        type: actionTypes.GET_IN_PROGRESS_MATCHES_SUCCESS,
        payload: response,
      });
      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.GET_IN_PROGRESS_MATCHES_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.GET_IN_PROGRESS_MATCHES_FAIL,
    });
  }
};

export const getCompletedMatchesAction = (id) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.GET_COMPLETED_MATCHES_REQUEST,
    });

    try {
      const response = await playService.getCompletedMatches(id);
      dispatch({
        type: actionTypes.GET_COMPLETED_MATCHES_SUCCESS,
        payload: response,
      });
      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.GET_COMPLETED_MATCHES_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.GET_COMPLETED_MATCHES_FAIL,
    });
  }
};

export const getPrizeMoneyPlayerAction = (id) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.GET_PRIZE_MONEY_PLAYER_REQUEST,
    });

    try {
      const response = await playService.getPrizeMoneyPlayer(id);
      dispatch({
        type: actionTypes.GET_PRIZE_MONEY_PLAYER_SUCCESS,
        payload: response,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.GET_PRIZE_MONEY_PLAYER_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.GET_PRIZE_MONEY_PLAYER_FAIL,
    });
  }
};

export const getPrizeMoneyDirectorAction = (id) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.GET_PRIZE_MONEY_DIRECTOR_REQUEST,
    });

    try {
      const response = await playService.getPrizeMoneyDirector(id);
      dispatch({
        type: actionTypes.GET_PRIZE_MONEY_DIRECTOR_SUCCESS,
        payload: response,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.GET_PRIZE_MONEY_DIRECTOR_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.GET_PRIZE_MONEY_DIRECTOR_FAIL,
    });
  }
};

export const setMatchWinnerAction = (id, body) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.SET_MATCH_WINNER_REQUEST,
    });

    try {
      const response = await playService.setMatchWinner(id, body);
      dispatch({
        type: actionTypes.SET_MATCH_WINNER_SUCCESS,
        payload: response,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.SET_MATCH_WINNER_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.SET_MATCH_WINNER_FAIL,
    });
  }
};

export const closeTournamentAction = (id) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.CLOSE_TOURNAMENT_REQUEST,
    });

    try {
      const response = await playService.closeTournament(id);
      dispatch({
        type: actionTypes.CLOSE_TOURNAMENT_SUCCESS,
        payload: response,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.CLOSE_TOURNAMENT_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.CLOSE_TOURNAMENT_FAIL,
    });
  }
};

export const deleteTournamentAction = (id) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.DELETE_TOURNAMENT_REQUEST,
    });

    try {
      const response = await playService.deleteTournament(id);
      dispatch({
        type: actionTypes.DELETE_TOURNAMENT_SUCCESS,
        payload: response,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.DELETE_TOURNAMENT_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.DELETE_TOURNAMENT_FAIL,
    });
  }
};

export const registerForTournamentAction = (id, body) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.REGISTER_FOR_TOURNAMENT_REQUEST,
    });

    try {
      const response = await playService.registerForTournament(id, body);
      dispatch({
        type: actionTypes.REGISTER_FOR_TOURNAMENT_SUCCESS,
        payload: response,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.REGISTER_FOR_TOURNAMENT_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.REGISTER_FOR_TOURNAMENT_FAIL,
    });
  }
};

export const updateTwitchAction = (id, body) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.UPDATE_TWITCH_REQUEST,
    });

    try {
      const response = await playService.updateTwitchUrl(id, body);
      dispatch({
        type: actionTypes.UPDATE_TWITCH_SUCCESS,
        payload: response,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.UPDATE_TWITCH_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.UPDATE_TWITCH_FAIL,
    });
  }
};

export const updateTournamentAction = (id, body) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.UPDATE_TOURNAMENT_REQUEST,
    });

    try {
      const response = await playService.updateTournament(id, body);
      dispatch({
        type: actionTypes.UPDATE_TOURNAMENT_SUCCESS,
        payload: response,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.UPDATE_TOURNAMENT_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.UPDATE_TOURNAMENT_FAIL,
    });
  }
};

export const getTwitchUrlAction = (id, body) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.GET_TWITCH_REQUEST,
    });

    try {
      const response = await playService.getTwitchUrl(id, body);
      dispatch({
        type: actionTypes.GET_TWITCH_SUCCESS,
        payload: response,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.GET_TWITCH_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.GET_TWITCH_FAIL,
    });
  }
};

export const getTournamentMatchDetailsAction = (id) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.GET_TOURNAMENT_MATCH_DETAILS_REQUEST,
    });

    try {
      const response = await playService.getTournamentMatchDetails(id);
      dispatch({
        type: actionTypes.GET_TOURNAMENT_MATCH_DETAILS_SUCCESS,
        payload: response,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.GET_TOURNAMENT_MATCH_DETAILS_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.GET_TOURNAMENT_MATCH_DETAILS_FAIL,
    });
  }
};

export const setTournamentMatchWinnerAction = (id, body) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.SET_MATCH_WINNER_TOURNAMENT_REQUEST,
    });

    try {
      const response = await playService.setTournamentMatchWinner(id, body);
      dispatch({
        type: actionTypes.SET_MATCH_WINNER_TOURNAMENT_SUCCESS,
        payload: response,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.SET_MATCH_WINNER_TOURNAMENT_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.SET_MATCH_WINNER_TOURNAMENT_FAIL,
    });
  }
};

export const getBracketsDetailsAction = (id) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.GET_BRACKETS_REQUEST,
    });

    try {
      const response = await playService.getBracketsDetails(id);
      dispatch({
        type: actionTypes.GET_BRACKETS_SUCCESS,
        payload: response,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.GET_BRACKETS_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.GET_BRACKETS_FAIL,
    });
  }
};
