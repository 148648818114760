import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

const useStyles = makeStyles({
  root: (props) => ({
    display: 'flex',
    background: `url(${props.backgroundImage}) no-repeat top`,
    backgroundSize: 'cover',
    width: 'calc(100% - 48px)',
    minHeight: 'calc(100% - 60px)',
    position: 'relative',
    padding: '30px 24px',
  }),
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 0,
    backgroundColor: 'rgba(20,19,61,0.6)',
  },
  content: {
    position: 'relative',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1,
  },
});

const PageAuth = (props) => {
  const { backgroundImage, className, children } = props;
  const classes = useStyles({
    backgroundImage,
  });
  return (
    <Box className={`${classes.root} ${className}`}>
      <Box className={classes.overlay} />
      <Box className={classes.content}>
        {children}
      </Box>
    </Box>
  );
};

PageAuth.defaultProps = {
  backgroundImage: null,
  className: '',
};

PageAuth.propTypes = {
  backgroundImage: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default PageAuth;
