import * as actionTypes from 'store/actionTypes';
import fundService from 'services/funds.service';
import primeTrust from 'services/primeTrust.service';
import storageService from 'services/storage.service';
import config from 'utils/config';

export const getFundsOverviewAction = (params = {}) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.GET_FUNDS_OVERVIEW_REQUEST,
    });

    try {
      const response = await fundService.getFundsOverview(params);

      dispatch({
        type: actionTypes.GET_FUNDS_OVERVIEW_SUCCESS,
        payload: response,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.GET_FUNDS_OVERVIEW_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.GET_FUNDS_OVERVIEW_FAIL,
    });
  }
};

export const createCreditCardDepositAction = (body = {}) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.CREATE_CREDIT_CARD_DEPOSIT_REQUEST,
    });

    try {
      const response = await primeTrust.createCreditCardDeposit(body);

      dispatch({
        type: actionTypes.CREATE_CREDIT_CARD_DEPOSIT_SUCCESS,
        payload: response,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.CREATE_CREDIT_CARD_DEPOSIT_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.CREATE_CREDIT_CARD_DEPOSIT_FAIL,
    });
  }
};

export default getFundsOverviewAction;
