import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Button,
  Grid,
  Typography,
} from '@material-ui/core';

import {
  formatCurrency,
} from 'utils/currency';
import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import WireWithdrawCard from 'components/Withdraw/WireWithdrawCard';

import { usdCurrency } from 'utils/constants';
import { useMappedCurrencies, useWithdrawTransactionDispatch } from 'hooks/redux/currency';
import { useAccountOverviewInfo } from 'hooks/redux/account';
import { useOpenConfirmModalDispatch, useOpenSuccessModalDispatch, useOpenErrorModalDispatch } from 'hooks/redux/modal';
import { getErrorMessage } from 'utils';
import CommonStyle from 'styles/common';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 15px',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
  },
  content: {
    textAlign: 'center',
    color: '#AEAEAE',
    font: '15px SofiaPro',
    justifyContent: 'center',
    alignItems: 'center',
  },
  add: {
    padding: 10,
    minHeight: 40,
    width: '100%',
    background: '#FFFFFF',
    boxShadow: '0 2px 10px 1px rgba(0,0,0,0.15)',
    marginBottom: '10px',
    font: '15px SofiaPro-SemiBold',
    letterSpacing: '0.3px',
    color: '#29CDD4',
  },
}));

const Submit = ({ history }) => {
  const [selectedWireId, setSelectedWireId] = useState(-1);
  const [selectedWireLastFourDigit, setSelectedWireLastFourDigit] = useState('');
  const [selectedWireAmount, setSelectedWireAmount] = useState('');
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const classes = useStyles();
  const commonClasses = CommonStyle();

  const overview = useAccountOverviewInfo();
  const openErrorModal = useOpenErrorModalDispatch();
  const openSuccessModal = useOpenSuccessModalDispatch();
  const withdraw = useWithdrawTransactionDispatch();
  const openConfirmModal = useOpenConfirmModalDispatch();
  const mappedCurrencies = useMappedCurrencies();

  const handleClick = (wire) => {
    setSelectedWireId(wire.id);
    setSelectedWireLastFourDigit(wire.last_4);
  };

  const handleSubmit = async () => {
    const currency = mappedCurrencies.usd;
    const data = {
      currency: 'usd',
      amount_currency: 'usd',
      method: 'wire-transfer',
      amount: Number(selectedWireAmount) * 10 ** usdCurrency.decimal_places,
      address: selectedWireId,
    };
    const withdrawAmount = formatCurrency(
      Number(selectedWireAmount) * 10 ** usdCurrency.decimal_places, currency, true, false,
    );
    const withdrawFeeAmount = formatCurrency(currency.withdrawal_fee, currency, true, false);
    const balance = formatCurrency(currency.balance, currency, true, false);
    const messageStr1 = `You indicated that you want to withdraw ${withdrawAmount} to the following bank account ending in:
    <br><br>${selectedWireLastFourDigit}`;
    const messageStr2 = '<br><br>Please verify that this is the correct account your funds will be sent to.';
    const messageStr3 = `<br><br>A convenience fee of <span><b>${withdrawFeeAmount}</b></span> 
    will be deducted from your wallet to account for processing fees.`;
    const messageConfirm = messageStr1 + messageStr2 + messageStr3;
    openConfirmModal({
      title: 'Please Confirm',
      subtitle: messageConfirm,
      agreeBtnText: 'Yes, Withdraw Funds',
      disagreeBtnText: 'No, Not Yet',
      callback: async () => {
        setDisabledSubmit(true);
        try {
          await withdraw(data);
          setDisabledSubmit(false);
          const content = `We have received your withdrawal request of ${withdrawAmount} and your USD wallet has been debited.
          All withdrawal requests get processed on our end within 24 hours.
          From there, the amount of time that it will take for you to receive
          your funds is dependent on your bank's processing time and what
          country you're located in.`;
          openSuccessModal({
            title: 'Withdrawal Request Received!',
            subtitle: content,
            buttonText: 'Got it',
            nextPath: '/funds',
          });
        } catch (e) {
          setDisabledSubmit(false);
          let message = getErrorMessage(e);
          if (message === 'Looks like you don\'t enough funds in your Available Balance to cover this withdrawal amount. Try a lower amount.') {
            message = `Looks like you don't have enough funds in your Available Balance for this wallet to cover the amount of this withdrawal 
plus the withdrawal fee. You're trying to withdraw ${withdrawAmount} plus cover the Withdrawal Fee of 
${withdrawFeeAmount}, and you only have ${balance} in your wallet. Please reduce your withdrawal amount to cover the cost of both the withdrawal 
amount PLUS the withdrawal fee.`;
          }
          openErrorModal({ title: 'Oops', subtitle: message });
        }
      },
    });
  };

  return (
    <Page>
      <Box className={classes.root} display="flex" flexDirection="column" flex={1}>
        <Box>
          <BackButton color={commonClasses.backBtn} page="/funds/withdraw/usd" />
        </Box>
        <Box display="flex" flexDirection="column" flex={1} justifyContent="space-between" mt={3}>
          <Box textAlign="center" mb={4}>
            <Typography component="h3" className={classes.title}>
              Withdrawal Amount
            </Typography>
            <span className={classes.content}>
              Enter the amount you would like to withdraw
            </span>
            <Box display="flex" mt={3} justifyContent="flex-end">
              <Grid item xs={7} sm={7}>
                <Button className={classes.add} onClick={() => history.push('/funds/withdraw/wire-transfer/instructions')}>
                  Add Wire Instructions
                </Button>
              </Grid>
            </Box>
            {overview?.active_bank_accounts?.map((wire) => (
              <WireWithdrawCard
                item={wire}
                value={selectedWireAmount}
                onClick={() => handleClick(wire)}
                handleChange={setSelectedWireAmount}
                open={selectedWireId === wire.id}
                key={wire.id}
              />
            ))}
          </Box>
        </Box>
        <OutlineButton disabled={disabledSubmit} onClick={handleSubmit}>
          Submit Withdrawal
        </OutlineButton>
      </Box>
    </Page>
  );
};

Submit.propTypes = {
  history: PropTypes.object.isRequired,
};

const mapStateToProps = () => ({});

export default withRouter(connect(mapStateToProps, null)(Submit));
