import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { dateTimeOptions } from 'utils/constants';

const useStyles = makeStyles(({ palette }) => ({
  card: () => ({
    marginTop: '1px',
    padding: '16px 8px',

    borderRadius: '5px',
    borderBottom: '1px solid #EEEEEE',
  }),
  cardImg: {
    width: '64px',
    height: '64px',
  },
  imageGroup: {
    width: '80px',
  },
  message: {
    color: palette.secondaryColor,
    font: '14px SofiaPro-SemiBold',
    letterSpacing: -0.3,
  },
  date: {
    color: '#A4A4A4',
    font: '13px SofiaPro',
    letterSpacing: -0.1,
    marginTop: '10px',
  },
}));

const Notif = (props) => {
  const {
    item, onClick,
  } = props;
  const classes = useStyles();

  return (
    <Box
      className={classes.card}
      display="flex"
      flexDirection="row"
      position="relative"
      justifyContent="space-between"
      onClick={() => onClick(item)}
    >
      <Box display="flex" position="relative" className={classes.imageGroup}>
        <Box className={classes.image}>
          <img src={item.image_url} alt="notif" className={classes.cardImg} />
        </Box>
      </Box>
      <Box flex={1}>

        <Typography variant="subtitle1" className={classes.message}>
          {item.message}
        </Typography>
        <Typography variant="subtitle1" className={classes.date}>
          {new Date(item.created_at).toLocaleString('en-US', dateTimeOptions)}
        </Typography>
      </Box>
    </Box>
  );
};

Notif.defaultProps = {
  item: {},
  onClick: () => {
  },
};

Notif.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func,
};

export default Notif;
