import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import {
  Box,
  Typography,
  Grid,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import OutlineButton from 'components/Buttons/BorderOutlineButton';
import FormattedNumberInput from 'components/Form/FormattedNumberInput';

import {
  usePlayIsLoading, useGetTournamentDetail, useGetPrizeMoneyPlayer, useUpdateTournamentDispatch,
} from 'hooks/redux/play';
import { useOpenErrorModalDispatch } from 'hooks/redux/modal';
import { formatCurrency, convertCurrencyAmount } from 'utils/currency';
import { currencyDecimalScale, supportedAllMappedCurrencies, prizeMoneyPayouts } from 'utils/constants';
import { getErrorMessage } from 'utils';
import commonStyles from 'styles/common';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0 15px',
  },
  backBtn: {
    color: theme.palette.secondaryColor,
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: theme.palette.secondaryColor,
    width: '100%',
    textAlign: 'center',
    margin: 'auto',
  },
  subtitle: {
    color: '#d4d2d2',
    marginTop: 15,
  },
  card: {
    background: theme.palette.cardColor,
    borderRadius: '5px',
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 4px',
    marginBottom: '10px',
    padding: '10px 0 1px',
    '& .placeField': {
      margin: '0px !important',
    },
  },
  cardTitle: {
    fontWeight: 'bold',
  },
  balanceContainer: {
    padding: '0px 20px 10px',
    alignItems: 'center',
  },
  balanceAmount: ({ color }) => ({
    fontWeight: 'bold',
    fontSize: '30px',
    color,
  }),
  labelInput: {
    fontWeight: 'bold',
    color: theme.palette.secondaryColor,
    fontSize: '18px',
    position: 'relative',
    zIndex: 4399,
    top: 40,
    flex: 2,
  },
  error: {
    color: 'red',
    marginTop: 10,
    marginBottom: -30,
    right: 0,
    position: 'absolute',
  },
  textInput: ({ color }) => ({
    flex: 3,
    '& .MuiInput-underline:before': {
      borderBottom: 'none !important',
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none !important',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none !important',
    },
    '& .MuiInputBase-input': {
      textAlign: 'right !important',
      color,
      '&::placeholder': {
        color: theme.palette.secondaryColor,
      },
    },
  }),
}));

const EnterPrize = ({ match, history }) => {
  const { id } = match.params;
  const isLoading = usePlayIsLoading();
  const details = useGetTournamentDetail(id);
  const prizeMoney = useGetPrizeMoneyPlayer(id);
  const updateTournament = useUpdateTournamentDispatch();
  const openErrorModal = useOpenErrorModalDispatch();

  const classes = useStyles({
    color: supportedAllMappedCurrencies[details.currency?.symbol]?.color || 'black',
  });

  const commonClasses = commonStyles();

  const getInitialFormValues = (payouts) => {
    const data = {};
    prizeMoneyPayouts[details.format].forEach((item) => {
      const filteredAmount = payouts?.find((payout) => payout.place === item.label)?.amount;
      if (filteredAmount > 0) {
        data[item.value] = filteredAmount / (10 ** details.currency.decimal_places);
      } else {
        data[item.value] = '';
      }
    });
    return data;
  };

  const getValidationSchema = () => {
    const schema = {};
    // prizeMoneyPayouts[details.format].forEach((item) => {
    //   schema[item.value] = Yup.string().required('Required');
    // });
    return schema;
  };

  return (
    <Page>
      <Box className={classes.root}>
        <Box>
          <Box>
            <BackButton color={classes.backBtn} page={`/more/play/tournament/view/${id}/prize`} />
          </Box>
          <Box textAlign="center" mb={4} mt={2}>
            <Typography component="h3" className={classes.title}>
              Allocate Prize Money
            </Typography>
          </Box>
        </Box>
        {(!isLoading && details) ? (
          <>
            <Box className={`cardOverride ${classes.card}`}>
              <Grid className={classes.balanceContainer} container>
                <Grid item xs={4} sm={4}>
                  <Box>
                    <img src={details?.currency?.wallet_image_url} alt="Currency" />
                  </Box>
                </Grid>
                <Grid item xs={8} sm={8}>
                  <Box>
                    <Typography className={classes.cardTitle}>
                      Total Prize Money
                    </Typography>
                    <Typography className={classes.balanceAmount}>
                      {details?.currency && prizeMoney?.total_prize_money >= 0 && (
                        formatCurrency(
                          prizeMoney.total_prize_money,
                          details.currency,
                          false,
                        )
                      )}
                    </Typography>
                    <Typography>
                      {details.currency?.symbol?.toUpperCase()}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            {(prizeMoney && prizeMoneyPayouts[details.format]) && (
              <Formik
                initialValues={{
                  ...getInitialFormValues(prizeMoney.payouts),
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  setSubmitting(true);
                  try {
                    const data = { ...details };
                    prizeMoneyPayouts[details.format].forEach((payout) => {
                      if (values[payout.value] > 0) {
                        data[payout.value] = convertCurrencyAmount(values[payout.value], details.currency);
                      }
                    });
                    await updateTournament(id, {
                      tournament: data,
                    });
                    history.push(`/more/play/tournament/view/${id}/prize`);
                  } catch (e) {
                    const message = getErrorMessage(e);
                    openErrorModal({ title: 'Oops', subtitle: message });
                  }
                }}
                validationSchema={Yup.object().shape(getValidationSchema())}
              >
                {({
                  errors,
                  touched,
                  values,
                  isSubmitting,
                  handleChange,
                  handleSubmit,
                  handleBlur,
                }) => (
                  <form onSubmit={handleSubmit} noValidate autoComplete="off">
                    {prizeMoneyPayouts[details.format].map((item) => (
                      <Box key={item.value} position="relative" className={commonClasses.textFieldContainer}>
                        <Typography className={classes.labelInput}>
                          {item.label}
                        </Typography>
                        <TextField
                          className={`placeField ${commonClasses.textField} ${classes.textInput}`}
                          placeholder="Enter in prize money"
                          fullWidth
                          name={item.value}
                          margin="normal"
                          InputProps={{
                            inputComponent: FormattedNumberInput,
                            inputProps: {
                              decimalScale: currencyDecimalScale[details?.currency.symbol],
                              prefix: details.currency.symbol === 'usd' ? '$ ' : '',
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values[item.value]}
                        />
                        {
                          (errors[item.value] && touched[item.value]) && (
                            <Typography className={classes.error}>
                              {errors[item.value]}
                            </Typography>
                          )
                        }
                      </Box>
                    ))}
                    <OutlineButton
                      type="submit"
                      style={{ margin: '80px 0 20px' }}
                      disabled={isSubmitting}
                    >
                      Save
                    </OutlineButton>
                  </form>
                )}
              </Formik>
            )}
          </>
        ) : null}
      </Box>
    </Page>
  );
};

EnterPrize.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(EnterPrize);
