import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  Fab,
  Box,
  Typography,
  makeStyles,
} from '@material-ui/core';

import Page from 'components/Page/Auth';
import BackButton from 'components/Buttons/BackButton';
import SoccerStadiumImage from 'assets/images/soccer-stadium.png';
import { useOpenErrorModalDispatch } from 'hooks/redux/modal';
import { setLocationAction } from 'store/actions/auth';

const useStyles = makeStyles({
  title: {
    marginBottom: '15px',
    textAlign: 'center',
    color: 'white',
    lineHeight: 1,
  },
  content: {
    marginTop: '-7px',
    textAlign: 'center',
    color: 'white',
  },
  fab: {
    margin: '42px auto 0',
    display: 'block',
    padding: '13px 45px',
    height: 'unset',
    borderRadius: '5px',
    fontWeight: 'bold',
  },
});

const Location = ({ history, setLocation }) => {
  const classes = useStyles();
  const openErrorModal = useOpenErrorModalDispatch();

  const getPosition = (position) => {
    if (position.coords) {
      setLocation({
        lat: position.coords.latitude,
        lon: position.coords.longitude,
      });
      history.push('/referral-bonus');
    }
  };

  const getError = (error) => {
    let message = '';
    switch (error.code) {
      case error.PERMISSION_DENIED:
        message = 'User denied the request for Geolocation.';
        break;
      case error.POSITION_UNAVAILABLE:
        message = 'Location information is unavailable.';
        break;
      case error.TIMEOUT:
        message = 'The request to get user location timed out.';
        break;
      default:
        message = 'An unknown error occurred.';
        break;
    }
    openErrorModal({
      title: 'Geolocation Error!',
      subtitle: message,
      buttonText: 'Got it',
    });
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(getPosition, getError);
    } else {
      openErrorModal({
        title: 'Geolocation Error!',
        subtitle: 'Geolocation is not supported by this browser.',
        buttonText: 'Got it',
      });
    }
  };

  const handleNext = () => {
    getLocation();
  };
  return (
    <Page backgroundImage={SoccerStadiumImage}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <BackButton />
      </Box>
      <Box mt={3}>
        <Typography variant="h1" className={classes.title}>Where&apos;s Your Location?</Typography>
        <Typography variant="subtitle1" className={classes.content}>
          {`In order to provide you the best access to our services, we require you to verify your location.
          Tap the "Got It" button below to bring up the location authorization window.`}
        </Typography>
        <Fab variant="extended" size="large" onClick={handleNext} className={classes.fab}>Got It</Fab>
      </Box>
    </Page>
  );
};

Location.propTypes = {
  history: PropTypes.object.isRequired,
  setLocation: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  setLocation: (location) => dispatch(setLocationAction(location)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Location));
