import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addParlayItemAction,
  deleteParlayItemAction,
  clearParlayItemsAction,
  submitParlayAction,
  setResultParlayAction,
  excludeBetParlayAction,
  excludeParlayItemsActions,
  setResultParlayItemsActions,
  getParlayAction,
} from 'store/actions/parlay';

export const useParlay = () => useSelector(({ parlay }) => parlay);

export const useParlayLoading = () => useSelector(({ parlay: { isLoading } }) => isLoading);
export const useExcludedParlayItems = () => useSelector(({ parlay: { excludedList } }) => excludedList || []);
export const useResultParlayItems = () => useSelector(({ parlay: { resultList } }) => resultList || []);

export const useSingleParlay = (parlayId) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (parlayId) {
      dispatch(getParlayAction(parlayId));
    }
    // eslint-disable-next-line
  }, [parlayId]);
  return useSelector(({ parlay: { single } }) => single);
};

export const useSubmitParlayDispatch = () => {
  const dispatch = useDispatch();
  return (data) => dispatch(submitParlayAction(data));
};

export const useGetParlayDispatch = () => {
  const dispatch = useDispatch();
  return (data) => dispatch(getParlayAction(data));
};

export const useSetResultParlayDispatch = () => {
  const dispatch = useDispatch();
  return (data) => dispatch(setResultParlayAction(data));
};

export const useExcludeBetParlayDispatch = () => {
  const dispatch = useDispatch();
  return (data) => dispatch(excludeBetParlayAction(data));
};

export const useExcludeParlayItemsDispatch = () => {
  const dispatch = useDispatch();
  return (data) => dispatch(excludeParlayItemsActions(data));
};

export const useSetParlayResultItemsDispatch = () => {
  const dispatch = useDispatch();
  return (data) => dispatch(setResultParlayItemsActions(data));
};

export const useAddParlayDispatch = () => {
  const dispatch = useDispatch();
  return (parlay) => dispatch(addParlayItemAction(parlay));
};

export const useDeleteParlayDispatch = () => {
  const dispatch = useDispatch();
  return (parlay) => dispatch(deleteParlayItemAction(parlay));
};

export const useClearParlayDispatch = () => {
  const dispatch = useDispatch();
  return () => dispatch(clearParlayItemsAction());
};
