import * as actionTypes from 'store/actionTypes';
import config from 'utils/config';
import storageService from 'services/storage.service';
import primeTrust from 'services/primeTrust.service';

export const getOverviewAction = (params = {}) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.GET_ACCOUNT_OVERVIEW_REQUEST,
    });

    try {
      const response = await primeTrust.getOverview(params);

      dispatch({
        type: actionTypes.GET_ACCOUNT_OVERVIEW_SUCCESS,
        payload: response,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.GET_ACCOUNT_OVERVIEW_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.GET_ACCOUNT_OVERVIEW_FAIL,
    });
  }
};

export const setProfileCountryAction = (country) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_PROFILE_COUNTRY,
    payload: country,
  });
};

export const setProfileStateAction = (state) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_PROFILE_STATE,
    payload: state,
  });
};

export const getCardResourceTokenAction = (params = {}) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.GET_CARD_TOKEN_REQUEST,
    });

    try {
      const response = await primeTrust.getCardResourceToken(params);

      dispatch({
        type: actionTypes.GET_CARD_TOKEN_SUCCESS,
        payload: response.token,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.GET_CARD_TOKEN_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.GET_CARD_TOKEN_FAIL,
    });
  }
};

export const getBankDepositInfoAction = (params = {}) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.GET_BANK_DEPOSIT_REQUEST,
    });

    try {
      const response = await primeTrust.getWireDepositInfo(params);

      dispatch({
        type: actionTypes.GET_BANK_DEPOSIT_SUCCESS,
        payload: response,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.GET_BANK_DEPOSIT_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.GET_BANK_DEPOSIT_FAIL,
    });
  }
};

export const addBankAccountAction = (body = {}) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.ADD_BANK_ACCOUNT_REQUEST,
    });

    try {
      const response = await primeTrust.addBankAccount(body);
      const initialData = {
        country: {
          id: 0,
          name: '',
          country_code: '',
        },
        funds_transfer_country: {
          id: 0,
          name: '',
          country_code: '',
        },
        account_name: '',
        bank_name: '',
        account_number: '',
        street_1: '',
        street_2: '',
        city: '',
        region: {
          id: 0,
          name: '',
          abbreviation: '',
        },
        postal_code: '',
        routing_number: '',
        isFurtherAcc: true,
        further_account_name: '',
        further_account_number: '',
        swift_code: '',
        iban: '',
      };
      dispatch({
        type: actionTypes.ADD_BANK_ACCOUNT_SUCCESS,
        payload: initialData,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.ADD_BANK_ACCOUNT_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.ADD_BANK_ACCOUNT_FAIL,
    });
  }
};

export const deleteFundsMethodAction = (body = {}) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.DELETE_FUNDS_METHOD_REQUEST,
    });

    try {
      const response = await primeTrust.deleteFundsMethod(body);

      dispatch({
        type: actionTypes.DELETE_FUNDS_METHOD_SUCCESS,
        payload: response,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.DELETE_FUNDS_METHOD_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.DELETE_FUNDS_METHOD_FAIL,
    });
  }
};

export const depositDebitAction = (data = {}) => async (dispatch) => {
  dispatch({
    type: actionTypes.DEPOSIT_DEBIT_INFO,
    payload: data,
  });
};

export const setBankInstructionInfoAction = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_BANK_INSTRUCTION_INFO,
    payload: data,
  });
};
