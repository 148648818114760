import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import {
  Box,
  Typography,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import OutlineButton from 'components/Buttons/BorderOutlineButton';
import commonStyles from 'styles/common';
import {
  useGetTournamentQuery,
  useCreateTournamentDispatch,
  useUpdateTournamentDispatch,
  useSetTournamentQueryDispatch,
} from 'hooks/redux/play';
import { useOpenErrorModalDispatch } from 'hooks/redux/modal';
import { getErrorMessage } from 'utils';

import BackButton from 'components/Buttons/BackButton';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ZenDialog from 'components/Dialog/ZenDialog';

const useStyles = makeStyles((theme) => ({
  stepLine: {
    height: '3px',
    background: 'linear-gradient(to right, #3597da, #36b6d6, #38c8d4)',
    margin: '-10px -10px 10px',
    width: '110%',
  },
  root: {
    padding: '0 15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  form: {
    height: '100%',
  },
  backBtn: {
    color: theme.palette.secondaryColor,
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: theme.palette.secondaryColor,
    width: '80%',
    textAlign: 'center',
    margin: 'auto',
  },
  icon: {
    marginLeft: '10px',
    marginTop: '2px',
    color: '#29CCD3',
    fontSize: '20px',
  },
  topic: {
    font: '18px SofiaPro-SemiBold',
    color: theme.palette.secondaryColor,
    letterSpacing: -0.3,
    marginBottom: '0px !important',
  },
  labelBox: {
    marginBottom: '-10px',
  },
  infoInput: {
    marginBottom: '0px !important',
  },
}));

const tooltipMessage = {
  twitch: 'Enter the Twitch username of the channel you would like to broadcast on the main Competition screen.',
  notes: 'Enter any notes you want players or spectators to know about this competition.'
    + 'It can be instructions that you want the players to join a Discord channel or the schedules for specific matches etc.',
};

const AdditionalInfo = ({ history, edit }) => {
  const classes = useStyles();
  const commonClasses = commonStyles();

  const data = useGetTournamentQuery();
  const createTournament = useCreateTournamentDispatch();
  const updateTournament = useUpdateTournamentDispatch();
  const setTournamentQuery = useSetTournamentQueryDispatch();
  const openErrorModal = useOpenErrorModalDispatch();

  const [twitchTooltipOpen, setTwitchTooltipOpen] = useState(false);
  const [notesTooltipOpen, setNotesTooltipOpen] = useState(false);

  const handleOpenTooltip = () => {
    setTwitchTooltipOpen(true);
  };

  const handleCloseTooltip = () => {
    setTwitchTooltipOpen(false);
  };

  const handleOpenNotesTooltip = () => {
    setNotesTooltipOpen(true);
  };

  const handleCloseNotesTooltip = () => {
    setNotesTooltipOpen(false);
  };

  return (
    <>
      <Formik
        initialValues={{
          notes: (data && data.notes) || '',
          twitch_channel_url: (data && data.twitch_channel_url) || '',
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            setSubmitting(true);
            const query = {
              ...data,
              notes: values.notes,
              twitch_channel_url: values.twitch_channel_url,
            };
            if (edit) {
              setTournamentQuery(query);
              await updateTournament(data.id, {
                tournament: query,
              });
              history.push(`/more/play/tournament/view/${data.id}/edit`);
            } else {
              const res = await createTournament({
                tournament: query,
              });
              history.push(`/more/play/tournament/view/${res.id}`);
            }
          } catch (e) {
            const message = getErrorMessage(e);
            openErrorModal({ title: 'Oops', subtitle: message });
          }
        }}
        validationSchema={Yup.object().shape({
          notes: Yup.string().optional(),
          twitch_channel_url: Yup
            .string()
            .optional()
            .matches(
              // eslint-disable-next-line no-useless-escape
              /^[a-zA-Z0-9\-]+$/,
              'No Symbols or Spaces.',
            ),
        })}
      >
        {({
          errors,
          touched,
          values,
          isSubmitting,
          handleChange,
          handleSubmit,
          handleBlur,
        }) => (
          <form onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.form}>
            <Box className={classes.stepLine} />
            <Box className={classes.root}>
              <Box>
                <Box>
                  <BackButton color={classes.backBtn} />
                </Box>
                <Box textAlign="center" mb={4} mt={2}>
                  <Typography className={commonClasses.subTitle}>
                    Step 5
                  </Typography>
                  <Typography component="h3" className={classes.title}>
                    Additional Info
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" className={classes.labelBox}>
                  <Typography className={classes.topic}>Competition Twitch Channel</Typography>
                  <InfoOutlinedIcon className={classes.icon} onClick={handleOpenTooltip} />
                  { twitchTooltipOpen
                  && <ZenDialog title="Competition Twitch Channel" message={tooltipMessage.twitch} cta="Got It!" onClose={handleCloseTooltip} /> }
                </Box>
                <Box>
                  <TextField
                    className={commonClasses.textField}
                    placeholder="Enter Twitch Username"
                    helperText=""
                    fullWidth
                    name="twitch_channel_url"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.twitch_channel_url}
                  />
                  {
                    (errors.twitch_channel_url && touched.twitch_channel_url) && (
                      <Typography className={commonClasses.error}>
                        {errors.twitch_channel_url}
                      </Typography>
                    )
                  }

                  <Box display="flex" alignItems="center" className={classes.labelBox} mt={4}>
                    <Typography className={classes.topic}>Notes</Typography>
                    <InfoOutlinedIcon className={classes.icon} onClick={handleOpenNotesTooltip} />
                    { notesTooltipOpen && <ZenDialog title="Notes" message={tooltipMessage.notes} cta="Got It!" onClose={handleCloseNotesTooltip} /> }
                  </Box>
                  <TextField
                    className={[commonClasses.textField, classes.infoInput]}
                    placeholder="Enter Notes"
                    helperText=""
                    fullWidth
                    name="notes"
                    rows={4}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    multiline
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.notes}
                  />

                  {
                    (errors.notes && touched.notes) && (
                      <Typography className={commonClasses.error}>
                        {errors.notes}
                      </Typography>
                    )
                  }
                </Box>
              </Box>
              <OutlineButton
                type="submit"
                style={{ margin: '20px 0' }}
                disabled={isSubmitting}
              >
                {
                  edit ? 'Save' : 'Create'
                }
              </OutlineButton>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};
AdditionalInfo.defaultProps = {
  edit: false,
};

AdditionalInfo.propTypes = {
  history: PropTypes.object.isRequired,
  edit: PropTypes.bool,
};

export default withRouter(AdditionalInfo);
