export default {
  baseUrl: process.env.REACT_APP_API_URL,
  exchangeBaseUrl: process.env.REACT_APP_EXCHANGE_API_URL,
  exchangeSocketUrl: process.env.REACT_APP_EXCHANGE_SOCKET_URL,
  appVersionUrl: process.env.REACT_APP_VERSION_URL,
  primeTrustUrl: process.env.REACT_APP_PRIME_TRUST_API_URL,
  primeTrustBootstrap: process.env.REACT_APP_PRIME_TRUST_BOOTSTRAP,
  primeTrustPurchase: process.env.REACT_APP_PRIME_TRUST_PURCHASE,
  primeTrustPurchaseId: process.env.REACT_APP_PRIME_TRUST_PURCHASE_ID,
  cardPaymentUrl: process.env.REACT_APP_CARD_PAYMENT_URL,
  userTokenKey: process.env.REACT_APP_USER_TOKEN_KEY,
  cloudinaryCloudName: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
  cloudinaryUploadPreset: process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET,
  algoliaAppId: process.env.REACT_APP_ALGOLIA_APP_ID,
  algoliaApiKey: process.env.REACT_APP_ALGOLIA_API_KEY,
  branchKey: process.env.REACT_APP_BRANCH_KEY,
  env: process.env.REACT_APP_ENV,
  themeKey: 'theme',
  firebase: {
    apiKey: process.env.REACT_APP_FB_API_KEY,
    authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FB_DB_URL,
    projectId: process.env.REACT_APP_FB_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FB_MSG_SENDER_ID,
    appId: process.env.REACT_APP_FB_APP_ID,
    measurementId: process.env.REACT_APP_FB_MES_ID,
  },
};
