import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import Page from 'components/Page/Dashboard';
import TournamentFees from 'components/Play/Tournament/Edit/TournamentFees';

const Step4 = ({ match }) => (
  <Page showNavigation={false}>
    <TournamentFees edit={match.path.includes('edit')} />
  </Page>
);

Step4.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(Step4);
