import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import { Box, Typography } from '@material-ui/core';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import Tabs from 'components/Tabs/Tabs';
import Loading from 'components/Loading/Box';
import Trade from 'components/Exchange/Trade';
import OpenOrders from 'components/Exchange/OpenOrders';
import OrdersHistory from 'components/Exchange/OrdersHistory';

import { useAuthAccessToken } from 'hooks/redux/user';
import { useMappedCurrencies } from 'hooks/redux/currency';
import {
  useMarketsDetails,
  useExchangeIsLoading,
  useOrdersIsLoading,
  useCancelOrdersIsLoading,
} from 'hooks/redux/exchange';

import config from 'utils/config';
import commonStyle from 'styles/common';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 15px',
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
    padding: '10px 10px 0 10px',
  },
  content: {
    margin: '17px -15px 7px',
    color: '#AEAEAE',
    font: '13px SofiaPro',
  },
  subtitle: {
    font: '17px SofiaPro-Bold',
    color: palette.secondaryColor,
    padding: 10,
  },
  card: {
    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.15)',
    padding: '10px 20px',
    borderRadius: '5px',
    color: palette.secondaryColor,
    font: '18px SofiaPro-Bold',
  },
  walletImage: {
    height: '50px',
  },
  add: {
    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.15)',
    borderRadius: '5px',
    background: '#FFFFFF',
    minHeight: 40,
    color: '#29CDD4',
    font: '16px SofiaPro-Bold',
  },
  header: {
    textAlign: 'center',
    font: '15px SofiaPro-Regular',
    color: '#AEAEAE',
  },
  exchangeTabs: {
    '& .MuiTabs-indicator': {
      backgroundColor: '#00dde1',
    },
    '& > div': {
      background: palette.themeColor,
    },
  },
}));

const Market = ({ match }) => {
  const classes = useStyles();
  const commonClasses = commonStyle();
  const [tradeData, setTradeData] = useState({ market: {} });

  const isLoading = useExchangeIsLoading();
  const isOrdersLoading = useOrdersIsLoading();
  const isCancelOrdersLoading = useCancelOrdersIsLoading();
  const marketDetails = useMarketsDetails(match.params.id);
  const currencies = useMappedCurrencies();
  const accessToken = useAuthAccessToken();

  const tabsList = [
    {
      label: 'Trade',
      component: <Trade {...tradeData} />,
    },
    {
      label: 'Open Orders',
      component: <OpenOrders {...tradeData} />,
    },
    {
      label: 'Order History',
      component: <OrdersHistory {...tradeData} />,
    },
  ];

  const getMappedCurrencies = ({ balances }) => {
    const mappedCurrencies = {};
    balances.forEach((currency) => {
      mappedCurrencies[currency.symbol] = {
        ...currencies[currency.symbol],
        ...currency,
      };
    });
    return mappedCurrencies;
  };

  useEffect(() => {
    if (marketDetails?.market?.id > 0) {
      setTradeData({
        ...marketDetails,
        mappedCurrencies: getMappedCurrencies(marketDetails),
      });
    }
    // eslint-disable-next-line
  }, [marketDetails]);

  useEffect(() => {
    if (tradeData?.market?.id > 0) {
      const ws = new WebSocket(`${config.exchangeSocketUrl}/cable?token=${accessToken}`);
      ws.onopen = () => {
        ws.send(JSON.stringify({
          command: 'subscribe',
          identifier: `{"channel":"TradingPairChannel", "market_ids":[${tradeData.market.id}]}`,
        }));
        ws.send(JSON.stringify({
          command: 'subscribe',
          identifier: '{"channel":"UserBalancesChannel"}',
        }));
      };

      ws.onmessage = ({ data }) => {
        const msg = JSON.parse(data);
        if (msg.identifier) {
          const identifier = JSON.parse(msg.identifier);
          if (identifier.channel) {
            if (
              identifier.channel === 'TradingPairChannel'
              && msg.message
              && msg.message.market
            ) {
              if (tradeData.market.id === msg.message.market.id) {
                setTradeData((prevTradeData) => ({
                  ...prevTradeData,
                  market: msg.message.market,
                  mappedCurrencies: getMappedCurrencies(prevTradeData),
                }));
              }
            }
            if (
              identifier.channel === 'UserBalancesChannel'
              && msg.message
              && msg.message.balances
            ) {
              setTradeData((prevTradeData) => ({
                ...prevTradeData,
                balances: msg.message.balances,
                mappedCurrencies: getMappedCurrencies(msg.message),
              }));
            }
          }
        }
      };
      return () => {
        ws.close();
      };
    }
    // eslint-disable-next-line
  }, [tradeData.market.id]);

  return (
    <Page>
      <Box>
        <Box>
          <BackButton color={commonClasses.backBtn} page="/funds/exchange" />
        </Box>
        {!isLoading ? (
          <>
            {tradeData?.market?.id > 0 && (
              <>
                <Box textAlign="center">
                  <Typography component="h3" className={classes.title}>
                    {tradeData.market.buy_currency?.symbol?.toUpperCase()}
                    /
                    {tradeData.market.sell_currency?.symbol?.toUpperCase()}
                  </Typography>
                </Box>
                <Box className={classes.exchangeTabs}>
                  <Tabs tabs={tabsList} hidePadding={false} />
                </Box>
              </>
            )}
          </>
        ) : (<Loading />)}
        {(isOrdersLoading || isCancelOrdersLoading) && (<Loading />)}
      </Box>
    </Page>
  );
};

Market.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(Market);
