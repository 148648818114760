import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import SelectState from 'components/SelectState';

import { setBankInstructionInfoAction } from 'store/actions/account';
import CommonStyle from 'styles/common';

const WithdrawState = ({
  bankCountry,
  instructionCountry,
  instructionRegion,
  setWireInstructionInfo,
}) => {
  const [country, setCountry] = useState({});
  const commonClasses = CommonStyle();
  const history = useHistory();

  const handleSelectState = (state) => {
    if (state.id !== instructionRegion.id) {
      setWireInstructionInfo({ region: state });
    }
    history.push('/funds/withdraw/wire-transfer/instructions');
  };
  useEffect(() => {
    const withdrawCountry = bankCountry.country_code === 'US' ? bankCountry : instructionCountry;
    setCountry(withdrawCountry);
    // eslint-disable-next-line
  }, [bankCountry, instructionCountry, setCountry]);
  return (
    <Page>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <BackButton color={commonClasses.backBtn} page="/funds/withdraw/wire-transfer/instructions" />
      </Box>
      <SelectState
        handleSelect={handleSelectState}
        country={country}
        state={instructionRegion}
      />
    </Page>
  );
};

WithdrawState.defaultProps = {
  instructionRegion: { id: 0 },
  bankCountry: { id: 0 },
  instructionCountry: { id: 0 },
};

WithdrawState.propTypes = {
  instructionRegion: PropTypes.object,
  bankCountry: PropTypes.object,
  instructionCountry: PropTypes.object,
  setWireInstructionInfo: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  bankCountry: state.account.bankInstruction?.funds_transfer_country,
  instructionCountry: state.account.bankInstruction?.country,
  instructionRegion: state.account.bankInstruction?.region,
});

const mapDispatchToProps = (dispatch) => ({
  setWireInstructionInfo: (data) => dispatch(setBankInstructionInfoAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawState);
