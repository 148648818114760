import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { AccessTime } from '@material-ui/icons';
import { Box, Typography } from '@material-ui/core';
import { formattedDate } from 'utils';
import { tournamentFormatType } from 'utils/constants';
// import TimeImage from 'assets/images/play/time.png';

const useStyles = makeStyles(({ palette }) => ({
  card: {
    background: palette.cardColor,
    borderRadius: '5px',
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 4px',
    position: 'relative',
    overflow: 'hidden',
    marginBottom: '15px',
  },
  competitionLogo: {
    backgroundSize: 'cover',
    width: '100%',
    height: '170px',
    backgroundRepeat: 'no-repeat',
  },
  contentContainer: {
    padding: '10px 20px',
  },
  title: {
    font: '30px SofiaPro-Bold',
    fontSize: '21px',
    letterSpacing: -0.3,
    color: palette.secondaryColor,
  },
  tagContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '13px',
  },
  tag: {
    background: '#6236ff',
    color: '#fff',
    padding: '3px 12px',
    fontSize: '11px',
    borderRadius: '20px',
    fontWeight: 'lighter',
  },
  dateContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& img': {
      width: '17px',
      height: '18px',
      marginRight: '5px',
    },
  },
  dateText: {
    color: '#b5b5b5',
    fontSize: '12px',
  },
  dateIcon: {
    fontSize: '20px',
    marginRight: '5px',
    color: '#b5b5b5',
  },
  line: {
    background: '#d3d3db',
    height: '1px',
    margin: '5px 20px 10px',
  },
  contentText: {
    color: palette.secondaryColor,
    margin: '0 20px 10px',
    fontSize: '13px',
  },
}));

const CompetitionCard = ({ item, history }) => {
  const classes = useStyles();
  let description = '';

  if (item.id) {
    description += `ID: ${item.id} `;
  }
  if (item.location) {
    description += `- ${item.location} `;
  }
  if (item.format_display) {
    description += `- ${tournamentFormatType[item.format] || ''} `;
  }
  // if (item.weight_display) {
  //   description += `- ${item.weight_display} `;
  // }

  const viewTournament = () => {
    history.push(`/more/play/tournament/view/${item.id}`);
  };

  return (
    <Box className={`cardOverride ${classes.card}`} onClick={viewTournament}>
      <Box
        className={classes.competitionLogo}
        style={{
          backgroundImage: `url(${item.profile_url})`,
        }}
      />
      <Box className={classes.contentContainer}>
        <Typography className={classes.title}>
          {item.name}
        </Typography>
        <Box className={classes.tagContainer}>
          <Box className={classes.tag}>
            {item.game_type_display}
          </Box>
          <Box className={classes.dateContainer}>
            <AccessTime className={classes.dateIcon} />
            <Typography className={classes.dateText}>
              {formattedDate(item.date)}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className={classes.line} />
      <Box>
        <Typography className={classes.contentText}>
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

CompetitionCard.propTypes = {
  item: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(CompetitionCard);
