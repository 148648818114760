import * as actionTypes from 'store/actionTypes';

const INITIAL_STATE = {
  isLoading: false,
  list: [],
  sportsList: [],
  tournament: {},
  tournamentDetails: {},
  tournamentUsers: [],
  currentUserInTournamentDetails: {},
  upcomingMatchesDetails: [],
  inProgressMatchesDetails: [],
  completedMatchesDetails: [],
  prizeMoneyPlayerDetails: {},
  prizeMoneyDirectorDetails: {},
  twitchChannelsDetails: [],
  tournamentMatchDetails: {},
  bracketsDetails: [],
  tournamentQuery: {
    date: '',
    address: '',
    notes: '',
    profile_url: '',
    format: 'single_elimination_no_buyback',
    weight: 'open',
    establishment: '',
    draw_type: '',
    game_type: '',
    frequency: 'once',
    name: '',
    location: '',
    currency_id: '',
    twitch_channel_url: '',
  },
  selected: {
    mSport: '',
    singleEliminationWithBuyback: true,
    doubleElimination: true,
    open: true,
    singleEliminationNoBuyback: true,
    handiCap: true,
    semiHandiCap: true,
    currentHistory: true,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_PLAY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_PLAY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: action.payload.list,
      };
    case actionTypes.GET_PLAY_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.GET_SPORTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_SPORTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        sportsList: action.payload.list,
      };
    case actionTypes.GET_SPORTS_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.CREATE_TOURNAMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.CREATE_TOURNAMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tournament: action.payload,
      };
    case actionTypes.CREATE_TOURNAMENT_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.SET_SELECTED_PLAY_FILTER:
      return {
        ...state,
        selected: action.payload,
      };
    case actionTypes.SET_TOURNAMENT_QUERY:
      return {
        ...state,
        tournamentQuery: action.payload,
      };
    case actionTypes.GET_TOURNAMENT_DETAILS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_TOURNAMENT_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tournamentDetails: action.payload,
      };
    case actionTypes.GET_TOURNAMENT_DETAILS_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.GET_TOURNAMENT_USERS_REQUEST:
      return {
        ...state,
        tournamentUsers: true,
      };
    case actionTypes.GET_TOURNAMENT_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tournamentUsers: action.payload,
      };
    case actionTypes.GET_TOURNAMENT_USERS_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.CURRENT_USER_IN_TOURNAMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.CURRENT_USER_IN_TOURNAMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentUserInTournamentDetails: action.payload,
      };
    case actionTypes.CURRENT_USER_IN_TOURNAMENT_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.GET_UPCOMING_MATCHES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_UPCOMING_MATCHES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        upcomingMatchesDetails: action.payload,
      };
    case actionTypes.GET_UPCOMING_MATCHES_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.GET_IN_PROGRESS_MATCHES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_IN_PROGRESS_MATCHES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        inProgressMatchesDetails: action.payload,
      };
    case actionTypes.GET_IN_PROGRESS_MATCHES_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.GET_COMPLETED_MATCHES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_COMPLETED_MATCHES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        completedMatchesDetails: action.payload,
      };
    case actionTypes.GET_COMPLETED_MATCHES_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.GET_PRIZE_MONEY_PLAYER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_PRIZE_MONEY_PLAYER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        prizeMoneyPlayerDetails: action.payload,
      };
    case actionTypes.GET_PRIZE_MONEY_PLAYER_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.GET_PRIZE_MONEY_DIRECTOR_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_PRIZE_MONEY_DIRECTOR_SUCCESS:
      return {
        ...state,
        isLoading: false,
        prizeMoneyDirectorDetails: action.payload,
      };
    case actionTypes.GET_PRIZE_MONEY_DIRECTOR_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.SET_MATCH_WINNER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.SET_MATCH_WINNER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.SET_MATCH_WINNER_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.CLOSE_TOURNAMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.CLOSE_TOURNAMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.CLOSE_TOURNAMENT_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.DELETE_TOURNAMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.DELETE_TOURNAMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.DELETE_TOURNAMENT_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.REGISTER_FOR_TOURNAMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.REGISTER_FOR_TOURNAMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.REGISTER_FOR_TOURNAMENT_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.UPDATE_TWITCH_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.UPDATE_TWITCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.UPDATE_TWITCH_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.GET_TWITCH_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_TWITCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        twitchChannelsDetails: action.payload.users,
      };
    case actionTypes.GET_TWITCH_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.GET_TOURNAMENT_MATCH_DETAILS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_TOURNAMENT_MATCH_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tournamentMatchDetails: action.payload,
      };
    case actionTypes.GET_TOURNAMENT_MATCH_DETAILS_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.GET_BRACKETS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_BRACKETS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        bracketsDetails: action.payload,
      };
    case actionTypes.GET_BRACKETS_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
