import {
  betAcceptFee, betCreationFee, dateTimeOptions, supportedAllMappedCurrencies,
} from './constants';
import { getFormattedCurrency } from './currency';

/**
 *
 * @param betAmount
 * @param proposedReturn
 * @returns {string}
 */
export const getAmericanOddsAmount = (betAmount, proposedReturn) => {
  if (Number(betAmount) <= Number(proposedReturn)) {
    return `+${Math.round((100 * proposedReturn) / betAmount)}`;
  }
  return `-${Math.round((100 * betAmount) / proposedReturn)}`;
};

/**
 * @param betAmount
 * @param proposedReturn
 * @returns {number}
 */
export const getDecimalOddsAmount = (
  betAmount, proposedReturn,
) => ((Number(betAmount) + Number(proposedReturn)) / betAmount);

/**
 *
 * @param betAmount
 * @param proposedReturn
 * @param oddType
 * @param mode
 * @param toFixed
 * @returns {string | number}
 */
export const getFormattedOddsAmount = (
  betAmount, proposedReturn, oddType, mode, toFixed = true,
) => {
  if (oddType === 'american') {
    return mode === 'maker' ? getAmericanOddsAmount(betAmount, proposedReturn) : getAmericanOddsAmount(proposedReturn, betAmount);
  }
  const odds = mode === 'maker' ? getDecimalOddsAmount(betAmount, proposedReturn) : getDecimalOddsAmount(proposedReturn, betAmount);

  return toFixed ? odds.toFixed(2) : odds;
};

/**
 *
 * @param decimalOdds
 * @returns {string}
 */
export const convertDecimalOddsToAmerican = (decimalOdds) => {
  if (Number(decimalOdds) >= 2) {
    const americanOdds = Math.floor(Number(decimalOdds) * 100 - 100);
    return `+${americanOdds}`;
  }
  return `-${Math.floor(100 / (decimalOdds - 1))}`;
};

/**
 *
 * @param americanOdds
 * @returns {string}
 */
export const convertAmericanOddsToDecimal = (americanOdds) => Number(Math.abs(americanOdds / 100 + 1)).toFixed(2);

/**
 * @param balance
 * @param maxBetAmount
 * @param currency
 * @param kind
 * @returns {number|*}
 */
export const getAvailableMaxBetAmount = (balance, maxBetAmount, currency, kind = 'accept') => {
  const fee = kind === 'accept' ? betAcceptFee[currency] : betCreationFee[currency];
  if (!fee) {
    return 0;
  }
  if (kind === 'create') {
    return Number(balance) / fee;
  }
  if ((Number(balance) / fee) > Number(maxBetAmount)) {
    return maxBetAmount;
  }
  return Number(balance) / fee;
};

/**
 *
 * @param amount
 * @param betAmount
 * @param proposedReturnAmount
 * @param currency
 * @returns {string}
 */
export const getWinAmount = (amount, betAmount, proposedReturnAmount, currency) => {
  const portion = Math.round(amount * (betAmount / proposedReturnAmount));
  return getFormattedCurrency(amount + portion, currency);
};

/**
 * @param bettingObject
 * @param objectType
 * @param betTypeName
 * @param intervalType
 * @param interval
 * @param isSoccerGame
 * @returns {string|*}
 */
export const getBetTitle = (bettingObject, objectType, betTypeName, intervalType, interval, isSoccerGame = false) => {
  if (['tie/draw', 'ties/draws', 'combined', 'Points Scored (Both Teams)', 'Runs Scored (Both Teams)']
    .findIndex((item) => betTypeName.toLowerCase().includes(item)) > -1
    && intervalType === 'single_game'
    && objectType === 'team'
  ) {
    // const mediumDescription = interval.game.medium_description;
    return interval.game.medium_description.replace('at', '&').replace('vs', '&');
  }
  if (!bettingObject) {
    return '';
  }
  if (objectType === 'team' && !isSoccerGame) {
    return bettingObject.city ? `${bettingObject.city} ${bettingObject.name}` : bettingObject.name;
  }
  if (objectType === 'player') {
    return `${bettingObject?.first_name || ''} ${bettingObject?.last_name || ''}`;
  }
  return bettingObject.name;
};

/**
 * @param bettingObject
 * @returns {string}
 */
export const getBetImageUrl = (bettingObject) => {
  if (bettingObject) {
    return bettingObject?.image_url;
  }
  return '';
};

/**
 * @param info
 * @param objectType
 * @param betTypeName
 * @param intervalType
 * @param interval
 * @param isSoccerGame
 * @param mode
 * @returns {{one: string, two: null}}
 */
export const getBetImageUrls = (info, objectType, betTypeName, intervalType, interval, isSoccerGame = false, mode = 'taker') => {
  let one = info.hasOppositeBettingObject && mode === 'taker'
    ? info.oppositeBettingObject?.[objectType]?.image_url : info.bettingObject?.[objectType]?.image_url;
  let two = null;

  if (info.hasOppositeBettingObject && info.oppositeBettingObject) {
    if (['tie/draw', 'ties/draws', 'combined', 'Points Scored (Both Teams)', 'Runs Scored (Both Teams)']
      .findIndex((item) => betTypeName.toLowerCase().includes(item)) > -1
      && intervalType === 'single_game'
      && objectType === 'team'
    ) {
      const teamName = info.bettingObject.name;
      const teamNames = interval.game.description.replace('at', '&').replace('vs', '&').split('&');
      if (teamNames.length > 1) {
        if (teamNames[0].includes(teamName) || isSoccerGame) {
          one = info.bettingObject[objectType].image_url;
          two = info.oppositeBettingObject[objectType].image_url;
        } else {
          one = info.oppositeBettingObject[objectType].image_url;
          two = info.bettingObject[objectType].image_url;
        }
      }
    }
  }

  return {
    one,
    two,
  };
};

/**
 * @param betType
 * @param attributes
 * @param goal
 * @param details
 * @param mode
 * @returns {string}
 */
export const getBetTypeDescription = (betType, attributes, goal, details, mode = 'taker') => {
  if (betType.is_write_in) {
    return mode === 'taker' ? `Maker Predicts: ${details}` : details;
  }
  let description = '';
  let pitchingDescription = '';
  if (attributes) {
    attributes.forEach((attr) => {
      if (attr.bet_type_attribute_group) {
        if (attr.bet_type_attribute_group.name?.toLowerCase() === 'pitching/batting') {
          pitchingDescription = ` (${mode === 'taker' ? attr.bet_type_attribute.reverse_display_name : attr.bet_type_attribute.name})`;
        } else {
          description += `${mode === 'taker' ? attr.bet_type_attribute.reverse_display_name : attr.bet_type_attribute.name} `;
        }
      }
    });
  }
  if (betType.has_goal) {
    description += `${goal} `;
  }
  description += `${betType.name}`;
  if (pitchingDescription) {
    description += `${pitchingDescription}`;
  }

  return description;
};

/**
 * @param bettingObject
 * @returns {string}
 */
export const getBetTextColor = (bettingObject) => {
  if (bettingObject) {
    return bettingObject.text_color ? `${bettingObject.text_color}` : '#14133D';
  }
  return '#14133D';
};

/**
 * @param bettingObject
 * @returns {string}
 */
export const getBetBackgroundColor = (bettingObject) => {
  if (bettingObject) {
    return bettingObject.background_color ? bettingObject.background_color : '#FFFFFF';
  }
  return '#FFFFFF';
};

/**
 * @param intervalType
 * @param interval
 * @returns {string|*}
 */
export const getBetDescription = (intervalType, interval) => {
  if (intervalType === 'season') {
    return `${interval.season.name} Season`;
  }
  if (intervalType === 'multiple_games') {
    return 'Multiple Games';
  }
  if (intervalType === 'single_game') {
    return interval.game.description;
  }
  return '';
};

/**
 * @param bets
 * @param maxBetAmount
 * @returns {*}
 */
export const getMaxBetAmount = (bets, maxBetAmount) => {
  let result = maxBetAmount;
  bets?.forEach((item) => {
    result -= item.amount;
  });
  return result;
};

/**
 * @param bets
 * @returns {*}
 */
export const getFilledBetAmount = (bets) => {
  let result = 0;
  bets?.forEach((item) => {
    result += item.amount;
  });
  return result;
};

/**
 * @param bets
 * @param user
 * @returns {*}
 */
export const getUserBetAmount = (bets, user) => {
  let result = 0;
  bets?.forEach((item) => {
    if (user?.id === item.user?.id) {
      result += item.amount;
    }
  });
  return result;
};

/**
 * @param bets
 * @param user
 * @returns {*}
 */
export const getSelectedBetId = (bets, user) => {
  if (!bets || !bets.length) {
    return 0;
  }
  for (let i = 0; i < bets.length; i += 1) {
    if (bets[i].user?.id === user.id) {
      return bets[i].id;
    }
  }
  return 0;
};

/**
 * @param closed
 * @param filledBetAmount
 * @param proposedReturn
 * @returns {string}
 */
export const getBetClosedPercentage = (closed, filledBetAmount, proposedReturn) => {
  let closedStr = '';
  if (closed) {
    closedStr = '- Closed Out';
  }
  return `${Math.floor((100 * filledBetAmount) / proposedReturn)}% ${closedStr}`;
};

/**
 * @param intervalType
 * @param interval
 * @returns {string}
 */
export const getBetTime = (intervalType, interval) => {
  if (intervalType === 'single_game') {
    return new Date(interval.game.date_time).toLocaleString('en-US', dateTimeOptions);
  }
  return '';
};

/**
 * @param bets
 * @param user
 * @param mode
 * @returns {string}
 */
export const getBetAcceptTime = (bets, user, mode) => {
  if (!bets || !bets.length) {
    return '';
  }
  if (mode === 'maker') {
    const sortedBets = bets.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    return new Date(sortedBets[0].created_at).toLocaleString('en-US', dateTimeOptions);
  }
  for (let i = 0; i < bets.length; i += 1) {
    if (bets[i].user?.id === user.id) {
      return new Date(bets[i].created_at).toLocaleString('en-US', dateTimeOptions);
    }
  }
  return '';
};

/**
 * @param bets
 * @param disputes
 * @param proposedReturnAmount
 * @param currency
 * @returns {string}
 */
export const getBetDisputeStatus = (bets, disputes, proposedReturnAmount, currency) => {
  let disputeStatus = '';
  if (disputes && disputes.length) {
    let filled = 0;
    let dispute = null;
    disputes.forEach((item) => {
      dispute = item;
      bets?.forEach((bet) => {
        if (dispute && bet.id === item.bet_id) {
          filled += bet.amount;
        }
      });
    });
    const percentage = Math.round((100 * filled) / proposedReturnAmount);
    if (dispute && dispute.status === 'submitted') {
      disputeStatus = `${percentage}% currently in dispute by ${disputes.length} Taker(s) - voting results will be posted
      within 48 business hours from when the dispute was submitted`;
    } else if (dispute && dispute.status === 'maker_win') {
      disputeStatus = 'Dispute results found in favor of Maker';
    } else if (dispute && dispute.status === 'taker_win') {
      disputeStatus = `Dispute results found in favor of Taker(s) -- ${getFormattedCurrency(filled, currency)} refunded to Taker(s)`;
    }
  } else {
    disputeStatus = 'Bet results being reviewed by Taker(s). Takers have 24 hours from when you submitted the results to submit a dispute.';
  }
  return disputeStatus;
};

/**
 * @param bets
 * @param disputes
 * @param amount
 * @param currency
 * @returns {[]}
 */
export const getBetDisputeDetails = (bets, disputes, amount, currency) => {
  const details = [];
  if (disputes && disputes.length && bets && bets.length) {
    disputes.forEach((dispute, index) => {
      const bet = bets.find((item) => item.id === dispute.bet_id);
      if (index >= 0 && dispute && bet && bet.amount && dispute.details && currency) {
        const formattedAmount = getFormattedCurrency(bet.amount, currency);
        const desc = `Taker ${index + 1} ${formattedAmount} ${dispute.details}`;
        details.push(desc);
      }
    });
  }
  return details;
};

/**
 * @param resultDetails
 * @param homeName
 * @param awayTeamName
 * @param homeTeamPoints
 * @param awayTeamPoints
 * @param objectType
 * @param interval
 * @returns {[]}
 */
export const getBetResultDetailsList = (resultDetails, homeName, awayTeamName, homeTeamPoints, awayTeamPoints, objectType, interval) => {
  const details = [];
  if (!resultDetails && (!homeTeamPoints || !awayTeamPoints)) {
    details.push({
      title: 'Result Details',
      details: 'Not Submitted Yet',
    });
  } else if (objectType === 'team' && interval === 'single_game') {
    if (!homeTeamPoints && !awayTeamPoints) {
      if (resultDetails) {
        details.push({
          title: 'Result Details',
          details: resultDetails,
        });
      }
    } else {
      details.push({
        title: 'Team Scores',
        details: `${awayTeamName} ${awayTeamPoints}:${homeName} ${homeTeamPoints}`,
      });
      if (resultDetails) {
        details.push({
          title: 'Result Details',
          details: resultDetails,
        });
      }
    }
  } else {
    details.push({
      title: 'Result Details',
      details: resultDetails,
    });
  }
  return details;
};

/**
 * @param bet
 * @param mode
 * @returns {string}
 */
export const getAcceptType = (bet, mode) => {
  if (mode === 'maker') {
    if (bet.bets?.length) {
      return 'maker-accepted';
    }
    return 'maker-not-accepted';
  }
  if (mode === 'taker' && bet.bets?.length) {
    return 'taker-accepted';
  }
  return '';
};

/**
 * @param bets
 * @param user
 * @returns {boolean}
 */
export const isBetAcceptedBy = (bets, user) => {
  if (!bets || !bets.length) {
    return false;
  }
  for (let i = 0; i < bets.length; i += 1) {
    if (bets[i].user?.id === user.id) {
      return true;
    }
  }
  return false;
};

/**
 * @param bets
 * @param user
 * @returns {boolean}
 */
export const isBetDisputedBy = (bets, user) => {
  if (!bets || !bets.length) {
    return false;
  }
  for (let i = 0; i < bets.length; i += 1) {
    if (bets[i].user?.id === user.id) {
      if (bets[i].dispute) {
        return true;
      }
    }
  }
  return false;
};

/**
 *
 * @param isExpired
 * @param isClosed
 * @param status
 * @returns {boolean}
 */
export const isBetAcceptable = (isExpired, isClosed, status) => {
  if (isExpired) {
    return false;
  }
  return !(!(status === 'proposed' || status === 'partially_accepted') || isClosed);
};

/**
 *
 * @param game
 * @returns {{awayTeamName: string, homeTeamName: string}}
 */
export const getTeamNames = (game) => {
  const result = {
    awayTeamName: '',
    homeTeamName: '',
  };

  if (game) {
    const awayTeamID = game.away_team_id;
    const homeTeamID = game.home_team_id;
    const teams = game.teams || [];
    result.awayTeamName = teams.find((team) => team.id === awayTeamID)?.name;
    result.homeTeamName = teams.find((team) => team.id === homeTeamID)?.name;
  }

  return result;
};

/**
 * @param bet
 * @param user
 * @returns {string}
 */
export const getBetViewMode = (bet, user) => {
  let mode = 'taker';

  if (bet.user) {
    if (bet.user.id === user.id) {
      mode = 'maker';
      if (
        (!bet.result || bet.result === 'null')
        && (bet.status === 'fully_accepted' || (bet.status === 'partially_accepted'))
      ) {
        mode = 'submit';
      }
    }
  } else if (!isBetAcceptedBy(bet.bets, user)) {
    if (isBetAcceptable(bet.is_expired, bet.betting_closed, bet.status)) {
      mode = 'accept';
    } else {
      mode = 'market';
    }
  }

  return mode;
};

/**
 * @param parlay
 * @param oddType
 * @returns {Object}
 */
export const getParlayAcceptInfo = (parlay, oddType) => {
  const info = {
    maxBetAmount: 0,
    formattedMaxBetAmount: '',
    odds: 1,
    formattedOddsAmount: '',
  };

  parlay.list.forEach((bet) => {
    info.maxBetAmount += getMaxBetAmount(bet.bets, bet.odds.proposed_return);
    info.odds *= getDecimalOddsAmount(bet.odds.proposed_return, bet.odds.proposed_bet_amount);
  });
  info.formattedMaxBetAmount = getFormattedCurrency(info.maxBetAmount, parlay.currency);
  info.formattedOddsAmount = oddType === 'decimal'
    ? Number(info.odds).toFixed(2)
    : convertDecimalOddsToAmerican(info.odds);

  return info;
};

/**
 * @param bets
 * @param parlay
 * @param excludedBets
 * @param oddType
 * @returns {Object}
 */
export const getParlayOddsWithExcluded = (bets, parlay, excludedBets, oddType) => {
  const info = {
    odds: 1,
    formattedOddsAmount: '',
  };
  let count = 0;
  bets.forEach((bet) => {
    const betIndex = excludedBets.findIndex((eBet) => eBet.proposed_bet.id === bet.proposed_bet.id);
    if (betIndex === -1) {
      info.odds *= getDecimalOddsAmount(
        bet.proposed_bet.odds.proposed_bet_amount, bet.proposed_bet.odds.proposed_return,
      );
    } else {
      count += 1;
    }
  });
  if (!count) {
    info.odds = parlay.decimalOdds;
    info.formattedOddsAmount = parlay.formattedOddsAmount;
  } else {
    info.formattedOddsAmount = oddType === 'decimal'
      ? Number(info.odds).toFixed(2)
      : convertDecimalOddsToAmerican(info.odds);
  }
  return info;
};

/**
 *
 * @param bet
 * @param oddType
 * @returns {Object}
 */
export const getBetInfo = (bet, oddType = 'american') => {
  const interval = bet.intervals[0] || {};
  const objectType = bet.bet_type?.object_type;
  const games = bet.intervals.map((item) => item.game);

  const info = {
    id: bet.id,
    type: '',
    title: '',
    objectType,
    games: games || [],
    interval: bet.bet_type.interval,
    hasOppositeBettingObject: bet.bet_type.has_opposite_betting_object,
    bettingObject: bet.betting_object,
    oppositeBettingObject: bet.opposite_betting_object,
    isSoccerGame: bet.betting_object?.league?.sport?.toLowerCase() === 'soccer',
    currency: bet.currency,
    betAmount: bet.odds.proposed_bet_amount,
    proposedReturnAmount: bet.odds.proposed_return,
    isWriteIn: bet.bet_type?.is_write_in,
    formattedOddsAmount: '',
    maxBetAmount: 0,
    isClosed: bet.betting_closed,
    isExpired: bet.is_expired,
    status: bet.status,
    formattedMaxBetLabel: 'Max Bet',
    formattedMaxBetAmount: '',
    homeTeamName: '',
    awayTeamName: '',
    currencyColor: '',
    backgroundColor: '',
    textColor: '',
    imageUrl: null,
    imageTwoUrl: null,
    description: '',
    time: '',
  };

  const bettingObject = info.hasOppositeBettingObject ? info.oppositeBettingObject : info.bettingObject;
  const imageUrls = getBetImageUrls(info, objectType, bet.bet_type.name, info.interval, interval, info.isSoccerGame);
  info.title = getBetTitle(bettingObject[objectType], objectType, bet.bet_type.name, info.interval, interval, info.isSoccerGame);
  info.textColor = getBetTextColor(bettingObject[objectType]);
  info.backgroundColor = getBetBackgroundColor(bettingObject[objectType]);
  info.type = getBetTypeDescription(bet.bet_type, bet.attributes, bet.goal, bet.details);
  info.description = getBetDescription(info.interval, interval);
  info.maxBetAmount = getMaxBetAmount(bet.bets, bet.odds.proposed_return);
  info.formattedMaxBetAmount = getFormattedCurrency(info.maxBetAmount, bet.currency);
  info.formattedOddsAmount = getFormattedOddsAmount(bet.odds.proposed_bet_amount, bet.odds.proposed_return, oddType);
  info.currencyColor = supportedAllMappedCurrencies[bet.currency.symbol]?.color;
  info.isAcceptable = isBetAcceptable(info.isExpired, info.isClosed, info.status);
  info.time = getBetTime(info.interval, interval);
  info.imageUrl = imageUrls.one;
  info.imageTwoUrl = imageUrls.two;
  // get team names
  const teamNames = getTeamNames(info.games[0]);
  info.homeTeamName = teamNames.homeTeamName;
  info.awayTeamName = teamNames.awayTeamName;

  if (!info.isAcceptable) {
    info.formattedMaxBetLabel = 'Filled/Accepted';
  }

  return info;
};

/**
 *
 * @param bet
 * @param user
 * @param oddType
 * @returns {Object}
 */
export const getBetResultInfo = (bet, user, oddType = 'american') => {
  let mode = 'taker';
  if (bet.user && bet.user.id === user.id) {
    mode = 'maker';
  }
  const interval = bet.intervals[0] || {};
  const games = bet.intervals.map((item) => item.game);
  const bettingObject = bet.bet_type.has_opposite_betting_object && mode === 'taker' ? bet.opposite_betting_object : bet.betting_object;
  const objectType = bet.bet_type.object_type;

  const info = {
    id: bet.id,
    selectedBetId: 0,
    type: '',
    title: '',
    acceptType: '',
    status: bet.status,
    isClosed: bet.betting_closed,
    isExpired: bet.is_expired,
    isAccepted: bet.bets?.length > 0,
    isAcceptable: false,
    isAcceptedByMe: false,
    isDisputed: bet.disputes?.length > 0,
    isWriteIn: bet.bet_type?.is_write_in,
    shouldExpire: bet.should_expire,
    currentHistory: bet.current_history,
    betType: bet.bet_type,
    goal: bet.goal,
    details: bet.details,
    objectType,
    interval: bet.bet_type.interval,
    result: bet.result,
    currency: bet.currency,
    hasOppositeBettingObject: bet.bet_type.has_opposite_betting_object,
    bettingObject: bet.betting_object,
    oppositeBettingObject: bet.opposite_betting_object,
    games: games || [],
    attributes: bet.attributes,
    betAmount: bet.odds.proposed_bet_amount,
    proposedReturnAmount: bet.odds.proposed_return,
    isSoccerGame: bettingObject.league?.sport?.toLowerCase() === 'soccer',
    homeTeamName: '',
    homeTeamPoints: bet.home_team_points || '',
    awayTeamName: '',
    awayTeamPoints: bet.away_team_points || '',
    disputes: bet.disputes || [],
    disputeStatus: '',
    disputeDetails: [],
    maxBetAmount: '',
    formattedMaxBetAmount: '',
    formattedOddsAmount: '',
    maxOriginalBetLabel: 'Original Bet',
    maxOriginalBetAmount: '',
    formattedMaxOriginalBetAmount: '',
    filledAmount: 0,
    filledPercentage: '',
    formattedFilledAmount: '',
    closedPercentageText: '',
    totalReceiveAmount: '',
    formattedTotalReceiveAmount: '',
    winnerDescription: 'Not Submitted Yet',
    filledDisputeAmount: '',
    formattedFilledDisputeAmount: '',
    filledDisputedPercentage: '',
    resultDetails: bet.result_details || '',
    resultDetailsList: [],
    currencyColor: '',
    backgroundColor: '',
    textColor: '',
    imageUrl: null,
    imageTwoUrl: null,
    description: '',
    time: '',
    acceptTime: '',
    viewMode: 'view',
    mode,
  };

  info.viewMode = getBetViewMode(bet, user);
  info.selectedBetId = getSelectedBetId(bet.bets, user);
  info.title = getBetTitle(bettingObject[objectType], objectType, bet.bet_type.name, info.interval, interval, info.isSoccerGame);
  info.textColor = getBetTextColor(bettingObject[objectType]);
  info.backgroundColor = getBetBackgroundColor(bettingObject[objectType]);
  info.formattedOddsAmount = getFormattedOddsAmount(info.betAmount, info.proposedReturnAmount, oddType, mode);
  info.maxBetAmount = getMaxBetAmount(bet.bets, bet.odds.proposed_return);
  info.formattedMaxBetAmount = getFormattedCurrency(info.maxBetAmount, bet.currency);
  info.filledAmount = getFilledBetAmount(bet.bets);
  info.formattedFilledAmount = getFormattedCurrency(info.filledAmount, bet.currency);
  info.type = getBetTypeDescription(bet.bet_type, bet.attributes, bet.goal, bet.details, mode);
  info.acceptType = getAcceptType(bet, mode);
  info.description = getBetDescription(bet.bet_type.interval, interval);
  info.acceptTime = getBetAcceptTime(bet.bets, user, mode);
  info.currencyColor = supportedAllMappedCurrencies[bet.currency.symbol]?.color;
  info.time = getBetTime(info.interval, interval);
  info.isAcceptable = isBetAcceptable(info.isExpired, info.isClosed, info.status);
  info.isAcceptedByMe = isBetAcceptedBy(bet.bets, user);
  info.isDisputedByMe = isBetDisputedBy(bet.bets, user);
  info.disputeStatus = getBetDisputeStatus(bet.bets, bet.disputes, info.proposedReturnAmount, bet.currency);
  info.disputeDetails = getBetDisputeDetails(bet.bets, bet.disputes, bet.currency);
  // get team names
  const teamNames = getTeamNames(info.games[0]);
  info.homeTeamName = teamNames.homeTeamName;
  info.awayTeamName = teamNames.awayTeamName;
  info.resultDetailsList = getBetResultDetailsList(
    info.resultDetails, info.homeTeamName, info.awayTeamName, info.homeTeamPoints, info.awayTeamPoints, objectType, interval,
  );

  // get filledPercentage
  info.filledPercentageAmount = (info.filledAmount / info.proposedReturnAmount) * info.betAmount;
  info.formattedFilledPercentageAmount = getFormattedCurrency(info.filledPercentageAmount, bet.currency);
  // get closedPercentage
  info.closedPercentageText = getBetClosedPercentage(info.isClosed, info.filledAmount, info.proposedReturnAmount);

  const imageUrls = getBetImageUrls(info, objectType, bet.bet_type.name, info.interval, interval, info.isSoccerGame, mode);
  info.imageUrl = imageUrls.one;
  info.imageTwoUrl = imageUrls.two;

  // get max original bet amount
  if (mode === 'maker') {
    if (info.isClosed || (info.viewMode === 'submit')) {
      info.maxOriginalBetAmount = info.filledPercentageAmount;
      // info.maxOriginalBetLabel = 'Filled/Accepted';
      info.maxOriginalBetLabel = 'Filled Amount';
    } else {
      info.maxOriginalBetAmount = info.betAmount;
    }
  } else if (info.viewMode === 'market') {
    info.maxOriginalBetAmount = info.filledAmount;
    info.maxOriginalBetLabel = 'Max Bet';
  } else {
    info.maxOriginalBetAmount = getUserBetAmount(bet.bets, user);
    info.maxOriginalBetLabel = 'Bet Amount';
  }
  info.formattedMaxOriginalBetAmount = getFormattedCurrency(info.maxOriginalBetAmount, bet.currency);

  // get totalReceiveAmount
  const oddsAmount = getFormattedOddsAmount(info.betAmount, info.proposedReturnAmount, 'decimal', mode, false);
  info.totalReceiveAmount = Number(oddsAmount) * info.maxOriginalBetAmount;
  info.formattedTotalReceiveAmount = getFormattedCurrency(info.totalReceiveAmount, bet.currency);

  // get winnerDescription
  if (bet.result && bet.result !== 'null') {
    if (bet.result === 'win') {
      info.winnerDescription = 'Maker';
    } else if (bet.result === 'loss') {
      info.winnerDescription = 'Taker';
    } else if (bet.result === 'tie') {
      info.winnerDescription = 'Tie';
    } else if (bet.result === 'canceled') {
      info.winnerDescription = 'Time Changed, Postponed, or Cancelled';
    }
  }
  return info;
};

export const getParlayResultInfo = (parlay, user, oddType = 'american') => {
  let mode = 'taker';
  if (parlay.maker?.id === user.id) {
    mode = 'maker';
  }

  const info = {
    id: parlay.id,
    mode,
    status: parlay.status,
    acceptType: mode === 'maker' ? 'maker-accepted' : 'taker-accepted',
    acceptTime: parlay.created_at ? new Date(parlay.created_at).toLocaleString('en-US', dateTimeOptions) : '',
    amount: mode === 'maker' ? parlay.maker_amount : parlay.taker_amount,
    formattedAmount: '',
    formattedCryptoAmount: '',
    formattedAmountLabel: mode === 'maker' ? 'Filled Amount' : 'Bet Amount',
    winAmount: 0,
    formattedWinAmount: '',
    decimalOdds: mode === 'maker' ? parlay.maker_decimal_odds : parlay.taker_decimal_odds,
    formattedOddsAmount: '',
    currentHistory: parlay.current_history,
    currencyColor: supportedAllMappedCurrencies[parlay.currency.symbol]?.color,
    winnerDescription: '',
    bets: [],
  };
  info.formattedOddsAmount = oddType === 'decimal'
    ? Number(info.decimalOdds).toFixed(2)
    : convertDecimalOddsToAmerican(info.decimalOdds);
  info.formattedAmount = getFormattedCurrency(info.amount, parlay.currency);
  info.formattedCryptoAmount = getFormattedCurrency(info.amount, parlay.currency, 'crypto');
  // info.winAmount = info.decimalOdds * (mode === 'maker' ? (parlay.maker_amount + parlay.taker_amount) : parlay.taker_amount);
  info.winAmount = info.decimalOdds * info.amount;
  info.formattedWinAmount = getFormattedCurrency(info.winAmount, parlay.currency);

  info.bets = parlay.proposed_bets.map((item) => {
    const bet = item.proposed_bet;
    bet.hasOppositeBettingObject = bet.bet_type.has_opposite_betting_object;
    bet.bettingObject = bet.betting_object;
    bet.oppositeBettingObject = bet.opposite_betting_object;
    const betType = bet.betting_object?.type;
    const isSoccerGame = bet.betting_object.league?.sport?.toLowerCase() === 'soccer';
    const interval = bet.intervals[0] || {};
    const images = getBetImageUrls(bet, betType, bet.bet_type.name, bet.bet_type.interval, interval, isSoccerGame, mode);
    return {
      id: item.id,
      imageUrl: images.one,
      backgroundColor: item.proposed_bet?.betting_object[betType]?.background_color,
    };
  });
  // get winnerDescription
  if (parlay.result && parlay.result !== 'null') {
    if (parlay.result === 'win') {
      info.winnerDescription = 'Maker';
    } else if (parlay.result === 'loss') {
      info.winnerDescription = 'Taker';
    } else if (parlay.result === 'tie') {
      info.winnerDescription = 'Tied';
    } else if (parlay.result === 'canceled') {
      info.winnerDescription = 'Cancelled/Postponed';
    }
  }

  return info;
};

/**
 * @param bet
 * @param oddType
 * @returns {{imageUrl: string, description: string, title: string}}
 */
export const getBetShareInfo = (bet, oddType = 'american') => {
  const data = {
    id: bet.id,
    title: '',
    description: '',
    imageUrl: '',
  };

  const objectType = bet.bet_type.object_type;
  const interval = bet.intervals[0] || {};
  const intervalType = bet.bet_type.interval;
  const maxBetAmount = getMaxBetAmount(bet.bets, bet.odds.proposed_return);
  const formattedMaxBetAmount = getFormattedCurrency(maxBetAmount, bet.currency);
  const odds = getFormattedOddsAmount(bet.odds.proposed_bet_amount, bet.odds.proposed_return, oddType, 'taker');

  if (objectType === 'team') {
    const bettingObject = bet.bet_type.has_opposite_betting_object ? bet.opposite_betting_object : bet.betting_object;
    if (intervalType === 'season') {
      data.title = `${bettingObject.team.name}: ${interval.season.name} Season`;
    } else if (intervalType === 'multiple_games') {
      data.title = `${bettingObject.team.name}: Multiple games`;
    } else if (intervalType === 'single_game') {
      const gameTime = new Date(interval.game.date_time).toLocaleString('en-US', dateTimeOptions);
      data.title = `${interval.game.description}\n ${gameTime}`;
    }
    data.imageUrl = bettingObject.team.image_url;
  } else {
    const bettingObject = bet.betting_object;
    const user = bet.betting_object.player;
    const playerName = `${user.first_name}${user.last_name ? ` ${user.last_name}` : ''}`;
    if (intervalType === 'season') {
      data.title = `${playerName}: ${interval.season.name} Season`;
    } else if (intervalType === 'multiple_games') {
      data.title = `${playerName}: Multiple games`;
    } else if (intervalType === 'single_game') {
      const gameTime = new Date(interval.game.date_time).toLocaleString('en-US', dateTimeOptions);
      data.title = `${playerName}: ${interval.game.description}\n ${gameTime}`;
    }
    data.imageUrl = bettingObject.player.image_url;
  }
  data.description = getBetTypeDescription(bet.bet_type, bet.attributes, bet.goal, bet.details);
  data.description += `;\n\nOdds: ${odds} (Max Bet: ${formattedMaxBetAmount})`;
  if (bet.bet_type.is_write_in) {
    data.description += '\n\nNOTE: You are betting that this WON\'T happen.';
  }

  return data;
};

/**
 * @param data
 * @returns {Object}
 */
export const getBetSubmitInfo = ({
  bettingObject, betType, intervalType, interval,
}) => {
  const attributes = [];
  if (betType.attribute && betType.attribute.group_id && betType.attribute.id) {
    attributes.push({
      bet_type_attribute_group: {
        name: betType.attribute.group_name,
      },
      bet_type_attribute: {
        name: betType.attribute.name,
      },
    });
  }

  return {
    title: getBetTitle(bettingObject, bettingObject.type, betType.name, intervalType, interval),
    type: getBetTypeDescription(betType, attributes, betType.goal, betType.description, 'maker'),
    description: getBetDescription(intervalType, interval),
    backgroundColor: getBetBackgroundColor(bettingObject),
    textColor: getBetTextColor(bettingObject),
    imageUrl: getBetImageUrl(bettingObject),
    teamImageUrl: bettingObject.team?.image_url,
    time: getBetTime(intervalType, interval),
  };
};

/**
 *
 * @param data
 * @returns {Object}
 */
export const getBettingObjectInfo = (data) => ({
  id: data.id,
  type: data.type,
  leagueName: data.type === 'league' ? data.name : '',
  playerName: data.type === 'player' ? `${data.first_name}${data.last_name ? ` ${data.last_name}` : ''}` : '',
  teamName: data.type === 'team' ? data.display_name : data.team?.display_name,
  imageUrl: data.image_url,
  teamImageUrl: data.team?.image_url,
  leagueImageUrl: data.large_image_url,
});

/**
 *
 * @param league
 * @returns {Object}
 */
export const getLeagueInfo = (league) => ({
  largeImage: league.large_image_url,
  image: league.image_url,
  title: league.name,
  content: league.acronym,
});

/**
 *
 * @param game
 * @returns {Object}
 */
export const getGameInfo = (game) => {
  const info = {
    id: game.id,
    time: new Date(game.date_time).toLocaleString('en-US', dateTimeOptions),
    content: game.medium_description,
    image1: '',
    image2: '',
  };

  if (game.players.length === 2) {
    info.image1 = game.players[0].image_url;
    info.image2 = game.players[1].image_url;
  }
  if (game.teams.length === 2) {
    info.image1 = game.teams[0].image_url;
    info.image2 = game.teams[1].image_url;
  }
  return info;
};

/**
 *
 * @param data
 * @returns {Object}
 */
export const getSeasonInfo = (data) => ({
  id: data.id,
  name: data.name,
});

/**
 *
 * @param data
 * @returns {Object}
 */
export const getBetTypeInfo = (data) => ({
  id: data.id,
  name: data.name,
  interval: data.interval,
  objectType: data.object_type,
  order: data.order,
  leagueId: data.league_id,
  goalType: data.goal_type,
  hasGoal: data.has_goal,
});

/**
 *
 * @param favorite
 * @returns {Object}
 */
export const getFavoritesInfo = (favorite) => {
  const info = {
    id: favorite.object_id,
    imageUrl: null,
    description: '',
  };

  if (favorite.object_type === 'league') {
    info.imageUrl = favorite.league.large_image_url;
    info.description = favorite.league.name;
  } else if (favorite.object_type === 'team') {
    info.imageUrl = favorite.team.image_url;
    info.description = favorite.team.name;
  } else {
    info.imageUrl = favorite.player.image_url;
    info.description = `${favorite.player.first_name} ${favorite.player.last_name}`;
  }

  return info;
};

/**
 *
 * @returns {Object}
 * @param data
 * @param type
 */
export const getBetChartInfo = (data, type) => {
  const info = {
    min: 0,
    max: 1999999,
    title: '',
    volume: 0,
    tier: 0,
  };
  const makerVolume = data.monthly_maker_betting_volume + data.monthly_maker_fantasy_volume;
  const takerVolume = data.monthly_taker_betting_volume + data.monthly_taker_fantasy_volume;
  const titleStr = type === 'maker' ? 'Maker Betting Volume' : 'Taker Betting Volume';
  info.tier = type === 'maker' ? data.maker_tier : data.taker_tier;
  if (Object.keys(data).length > 0) {
    info.volume = type === 'maker' ? makerVolume : takerVolume;
    info.volume = Math.floor(info.volume / 10 ** 4);
    if (info.volume < 2000000) {
      info.min = 0;
      info.max = 1999999;
      info.tier = 0;
      info.title = `${titleStr}`;
    } else if (info.volume >= 2000000 && info.volume < 6000000) {
      info.min = 2000000;
      info.max = 5999999;
      info.tier = 1;
      info.title = `${titleStr} - Tier 1 (0.25%)`;
    } else if (info.volume >= 6000000 && info.volume < 10000000) {
      info.min = 6000000;
      info.max = 9999999;
      info.tier = 2;
      info.title = `${titleStr} - Tier 2 (0.50%)`;
    } else if (info.volume >= 10000000 && info.volume < 20000000) {
      info.tier = 3;
      info.min = 10000000;
      info.max = 19999999;
      info.title = `${titleStr} - Tier 3 (${type === 'maker' ? '0.75%' : '1.00%'})`;
    } else if (info.volume >= 20000000) {
      info.tier = 4;
      info.min = 20000000;
      info.max = info.volume;
      info.title = `${titleStr} - Tier 4 (${type === 'maker' ? '1.00%' : '2.00%'})`;
    }
  }
  return info;
};
