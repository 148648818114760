import React from 'react';

import Page from 'components/Page/Dashboard';
import BetFilter from 'components/Bet/BetFilter';

const Filter = () => (
  <Page>
    <BetFilter mode="favorite" />
  </Page>
);

export default Filter;
