import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  root: {
    padding: '0 17px',
  },
  root2: {
    padding: '0 15px 70px',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  backBtn: {
    color: theme.palette.secondaryColor,
  },
  title: {
    fontFamily: 'SofiaPro-Bold',
    color: theme.palette.secondaryColor,
    fontSize: '30px',
    lineHeight: '30px',
    letterSpacing: -0.1,
    marginTop: '2px',
  },
  topic: {
    fontFamily: 'SofiaPro-Black',
    color: '#08B0AA',
    fontSize: '12px',
    letterSpacing: 0.5,
    marginTop: '-4px',
  },
  line: {
    background: 'linear-gradient(45deg, #298EDA, #29D2D3)',
    height: '3px',
    marginLeft: '-27px',
    width: '25%',
  },
  content: {
    font: '15px SofiaPro-Regular',
    color: '#C2C2C2',
    letterSpacing: -0.2,
    marginTop: '9px',
  },
  vListWrapper: {
    flex: 1,
  },
  line2: {
    background: 'linear-gradient(45deg, #298EDA, #29D2D3)',
    height: '3px',
    marginLeft: '-27px',
    width: '70%',
  },
  title2: {
    font: '30px SofiaPro-Bold',
    color: theme.palette.secondaryColor,
    textAlign: 'center',
  },
  topic2: {
    textAlign: 'center',
    fontFamily: 'SofiaPro-Black',
    color: '#08B0AA',
    fontSize: '12px',
    letterSpacing: 0.5,
    marginTop: '15px',
    marginBottom: '16px',
  },
  continue: {
    position: 'absolute',
    bottom: '0px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '90%',
  },
  textFieldContainer: {
    display: 'flex',
    borderBottom: `1px solid ${theme.palette.secondaryColor} !important`,
  },
  textField: {
    margin: '8px 0',
    '& label': {
      fontWeight: 'bold',
      color: theme.palette.secondaryColor,
      fontSize: '20px',
      marginBottom: '20px !important',
    },
    '& .Mui-disabled': {
      color: theme.palette.secondaryColor,
    },
    '& .Mui-focused': {
      color: theme.palette.secondaryColor,
    },
    '& input': {
      color: theme.palette.secondaryColor,
    },
    '& textarea': {
      color: theme.palette.secondaryColor,
    },
    '& .Mui-disabled input': {
      color: theme.palette.secondaryColor,
    },
    '& .MuiInput-underline': {
      marginTop: '30px',
    },
    '& .MuiInput-underline:before': {
      borderBottom: `1px solid ${theme.palette.secondaryColor} !important`,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: `1px solid ${theme.palette.secondaryColor} !important`,
    },
  },
  subTitle: {
    color: '#63C4C6',
    fontSize: '16px',
  },
  error: {
    color: 'red',
  },
}));
