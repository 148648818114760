import httpService from './http.service';

const getUser = () => httpService
  .get('/me')
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const getRewardStatus = () => httpService
  .get('/users/get_rewards_status')
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const getUserLocation = () => httpService
  .get('/users/location')
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const login = (body) => httpService
  .post('/auth_user', body)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const register = (body) => httpService
  .post('/users', body)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const updateUser = (body) => httpService
  .put(`/users/${body.id}`, body)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const setUserFavorites = (body) => httpService
  .post('/users/set_favorites', body);

const installation = (body) => httpService
  .post('/installations', body);

export default {
  getUser,
  getUserLocation,
  getRewardStatus,
  login,
  register,
  updateUser,
  setUserFavorites,
  installation,
};
