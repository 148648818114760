import sportsIconImage from 'assets/images/currency/sports-wallet-logo.png';
import btcIconImage from 'assets/images/currency/btc-wallet-logo.png';
import usdIconImage from 'assets/images/currency/usd-wallet-logo.png';
import icxIconImage from 'assets/images/currency/icx-wallet-logo.png';

export const betParlayAllTypes = [
  {
    type: 'parlay',
    name: 'Parlays',
  },
  {
    type: 'bet',
    name: 'Individual Bets',
  },
];

export const betAllIntervals = [
  {
    type: 'season',
    name: 'Features',
  },
  {
    type: 'multiple_games',
    name: 'Multiple Games',
  },
  {
    type: 'single_game',
    name: 'Single Game',
  },
];

export const supportedAllCurrencies = [
  {
    symbol: 'sports',
    name: 'SPORTS',
    image: sportsIconImage,
    color: '#29CED3',
  },
  {
    symbol: 'btc',
    name: 'BTC',
    image: btcIconImage,
    color: '#F3B200',
    is_fiat: false,
  },
  {
    symbol: 'usd',
    name: 'USD',
    image: usdIconImage,
    color: '#32CD32',
    is_fiat: true,
  },
  {
    symbol: 'icx',
    name: 'ICX',
    image: icxIconImage,
    color: '#275AFA',
  },
];

export const supportedAllMappedCurrencies = {
  sports: {
    symbol: 'sports',
    name: 'SPORTS',
    image: sportsIconImage,
    color: '#29CED3',
  },
  btc: {
    symbol: 'btc',
    name: 'BTC',
    image: btcIconImage,
    color: '#F3B200',
  },
  usd: {
    symbol: 'usd',
    name: 'USD',
    image: usdIconImage,
    color: '#32CD32',
  },
  icx: {
    symbol: 'icx',
    name: 'ICX',
    image: icxIconImage,
    color: '#275AFA',
  },
};

export const betAllTypes = [
  {
    type: 'moneyline',
    name: 'Moneyline',
  },
  {
    type: 'point_spread',
    name: 'Point Spread',
  },
  {
    type: 'over_under',
    name: 'Over/Under',
  },
  {
    type: 'will_not',
    name: 'Will/Won\'t',
  },
  {
    type: 'write_in',
    name: 'Write in',
  },
];

export const currencyDecimalScale = {
  usd: 2,
  usdt: 4,
  btc: 8,
  icx: 2,
  sports: 0,
};

export const exchangeCurrencyDecimalScale = {
  usd: 2,
  usdt: 8,
  btc: 8,
  icx: 2,
  sports: 0,
};

export const betMappedAcceptTypes = {
  'maker-accepted': {
    value: 'maker-accepted',
    label: 'Maker - Accepted',
    color: '#00c1d3',
  },
  'maker-not-accepted': {
    value: 'maker-not-accepted',
    label: 'Maker - Not Accepted',
    color: '#aeaeae',
  },
  'taker-accepted': {
    value: 'taker-accepted',
    label: 'Taker - Accepted',
    color: '#5730cf',
  },
};

export const betAllObjectTypes = [
  {
    type: 'team',
    name: 'Team',
  },
  {
    type: 'player',
    name: 'Player',
  },
];

export const betAllExpiresTypes = [
  {
    value: false,
    name: 'Current',
  },
  {
    value: true,
    name: 'History',
  },
];

export const betAllAcceptableTypes = [
  {
    value: 'acceptable',
    name: 'Acceptable',
  },
  {
    value: 'not_acceptable',
    name: 'Not Acceptable',
  },
];

export const betAllAcceptedTypes = [
  {
    value: 'acceptable',
    name: 'Accepted',
  },
  {
    value: 'not_acceptable',
    name: 'Not Accepted',
  },
];

export const betAllRoleTypes = [
  {
    value: 'maker',
    name: 'Maker',
  },
  {
    value: 'taker',
    name: 'Taker',
  },
];

export const betAllWinLossTypes = [
  {
    value: 'won',
    name: 'Won',
  },
  {
    value: 'lost',
    name: 'Lost',
  },
  {
    value: 'tie',
    name: 'Tie',
  },
  {
    value: 'suspended',
    name: 'Suspended',
  },
];

export const betCreationFee = {
  usd: 1.125,
  btc: 1.125,
  icx: 1.125,
  usdt: 1.125,
  sports: 1.06,
};

export const betAcceptFee = {
  usd: 1.05,
  btc: 1.05,
  icx: 1.05,
  usdt: 1.05,
  sports: 1.025,
};

export const usdCurrency = {
  symbol: 'usd',
  is_fiat: true,
  name: 'US Dollar',
  rate: 1.0,
  dollar_to_tokens: 1,
  decimal_places: 2,
  dollar_in_tokens_as_int: 100,
  wallet_image_url: 'https://res.cloudinary.com/production/image/upload/v1575335967/Icons/Currencies/usd-wallet-logo.png',
  image_url: 'https://res.cloudinary.com/production/image/upload/v1576101923/Icons/Currencies/usd-toggle-logo.png',
};

export const dateTimeOptions = {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
};

export const dateOptions = {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
};

export const dateMYOptions = {
  year: 'numeric',
  month: 'short',
};

export const monthMap = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'Jun',
  7: 'Jul',
  8: 'Aug',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
};

export const dateConfig = {
  month: {
    format: (value) => monthMap[value.getMonth() + 1],
    caption: 'Mon',
    step: 1,
  },
  date: {
    format: 'DD',
    caption: 'Day',
    step: 1,
  },
  year: {
    format: 'YYYY',
    caption: 'Year',
    step: 1,
  },
};

export const betsSortCriteria = {
  objectTypes: ['team', 'player'],
  intervals: ['single_game', 'multiple_games', 'season'],
  attributes: ['moneyline', 'point spread', 'over/under', 'will/won\'t', 'write in'],
};

export const documentTypes = [
  {
    id: 1,
    value: 'drivers_license',
    name: 'Driver License',
  },
  {
    id: 2,
    value: 'passport',
    name: 'Passport',
  },
  {
    id: 3,
    value: 'government_id',
    name: 'Government ID',
  },
  {
    id: 4,
    value: 'residence_permit',
    name: 'Residence Permit',
  },
];

export const KYCVerificationExceptionCode = [
  {
    code: 'address_auto_check_failed',
    reason: 'There was a failure when trying to verify the address you provided.',
    resolve: 'Update your KYC verification form with your correct address.',
    section: ['address'],
  },
  {
    code: 'address_manual_check_failed',
    reason: 'There was a failure when trying to verify the address you provided.',
    resolve: 'Update your KYC verification form with your correct address.',
    section: ['address'],
  },
  {
    code: 'auto_check_failed',
    reason: 'Your KYC is under manual review. We will reach out with next steps once the review is completed.',
    resolve: '',
    section: ['none'],
  },
  {
    code: 'date_of_birth_auto_check_failed ',
    reason: 'There was a failure when trying to verify the date of birth you provided.',
    resolve: 'Update your KYC verification form with your correct date of birth.',
    section: ['birthday'],
  },
  {
    code: 'manual_review_required',
    reason: 'Your KYC is under manual review. We will reach out with next steps once the review is completed.',
    resolve: '',
    section: ['none'],
  },
  {
    code: 'name_auto_check_failed',
    reason: 'There was a failure when trying to match the name you provided to other records related to your contact information.',
    resolve: 'Update your KYC verification form with your correct name',
    section: ['contact-info'],
  },
  {
    code: 'tax_id_number_auto_check_failed',
    reason: 'There was a failure when trying to verify the Tax ID number you provided.',
    resolve: 'Update your KYC verification form with your correct Tax ID.',
    section: ['tax-id'],
  },
  {
    code: 'tax_id_number_manual_check_failed',
    reason: 'There was a failure when trying to verify the Tax ID number you provided.',
    resolve: 'Update your KYC verification form with your correct Tax ID.',
    section: ['tax-id'],
  },
  {
    code: 'auto_check_failed',
    reason: 'The documents you submitted could not be verified.',
    resolve: 'Update your KYC verification form with verifiable documents.',
    section: ['documents'],
  },
  {
    code: 'authenticity_auto_check_failed',
    reason: 'The documents you submitted were not authentic and could not be verified.',
    resolve: 'Update your KYC verification form with authentic documents.',
    section: ['documents'],
  },
  {
    code: 'countryNotObtainable',
    reason: 'The documents you submitted did not have the country clearly showing and could not be verified.',
    resolve: 'Update your KYC verification form with the country clearly showing in the documents',
    section: ['documents'],
  },
  {
    code: 'validityAutoCheckFailed',
    reason: 'The document types you submitted could not be identified nor verified.',
    resolve: 'Update your KYC verification form with correct type of documents listed out in the directions.',
    section: ['documents'],
  },
  {
    code: 'manual_review_check_failed',
    reason: 'Your KYC is under manual review. We will reach out with next steps once the review is completed.',
    resolve: '',
    section: ['none'],
  },
  {
    code: 'date_of_birth_auto_check_failed',
    reason: 'The date of birth you listed does not match the date of birth on the document you provided.',
    resolve: 'Update your KYC verification form with the correct date of birth listed out in the documents.',
    section: ['birthday', 'document'],
  },
  {
    code: 'document_valid_auto_check_failed',
    reason: ' The document provided is not valid or has expired.',
    resolve: 'Update your KYC verification form with the valid documents that have not yet expired.',
    section: ['documents'],
  },
  {
    code: 'tax_country_auto_check_failed',
    reason: 'The issuing country of the document you provided does not match the country you listed in your address.',
    resolve: 'Update your KYC verification form with documents where the country matches the country in your address.',
    section: ['documents'],
  },
];

export const comingSoonMessage = 'This feature is not currently available in our newly launched Web App. '
  + 'However, it is available in our native iOS and Android apps! 🙂 If you would like to access this '
  + 'feature, please download our native iOS or Android app from the zensports.com website.';

export const activeMessage = `Due to gaming regulations, customers are required to be active within the ZenSports app within a 5 minute period. 
        Tap "Yes I'm Here" to confirm that you're still in an active gaming session.`;

export const restrictedMessage = (user) => `Hey ${user.first_name}! It looks like you're located in one of our restricted locations,
which means that we unfortunately can't give you access to our Deposit, Sports Betting, Daily Fantasy Sports,
or Exchange features. We're obtaining new licenses and adding new locations to our white list all the time,
so please check back soon to find out when your location will become available.`;

export const nevadaBlockMessage = `Gaming regulations require that all customers in Nevada first register in-person prior to depositing 
or withdrawing funds. This is a ONE TIME requirement, and once you register in-person, you can deposit, withdraw, and bet via mobile 
remotely at anytime. You can register at the Big Wheel Casino located in Lovelock, NV.`;

export const passwordRuleMessage = 'Your password doesn\'t meet the minimum requirements that ZenSports has. '
  + 'Passwords must be at least 8 characters and contain a combination of at least two of the following criteria: '
  + 'Upper case letters, lower case letters, numeric, and/or special characters.';

export const prizeMoneyPayouts = {
  single_elimination_no_buyback: [
    {
      value: 'place1',
      label: '1st',
    },
    {
      value: 'place2',
      label: '2nd',
    },
    {
      value: 'place3_4',
      label: '3rd - 4th',
    },
    {
      value: 'place5_8',
      label: '5th - 8th',
    },
    {
      value: 'place9_16',
      label: '9th - 16th',
    },
    {
      value: 'place17_32',
      label: '17th - 32nd',
    },
    {
      value: 'place33_64',
      label: '33rd - 64th',
    },
  ],
  double_elimination: [
    {
      value: 'place1',
      label: '1st',
    },
    {
      value: 'place2',
      label: '2nd',
    },
    {
      value: 'place3',
      label: '3rd',
    },
    {
      value: 'place4',
      label: '4th',
    },
    {
      value: 'place5_6',
      label: '5th - 6th',
    },
    {
      value: 'place7_8',
      label: '7th - 8th',
    },
    {
      value: 'place9_12',
      label: '9th - 12th',
    },
    {
      value: 'place13_16',
      label: '13th - 16th',
    },
    {
      value: 'place17_24',
      label: '17th - 24th',
    },
    {
      value: 'place25_32',
      label: '25th - 32nd',
    },
    {
      value: 'place33_48',
      label: '33rd - 48th',
    },
    {
      value: 'place49_64',
      label: '49th - 64th',
    },
  ],
};

export const tournamentFormatType = {
  single_elimination_no_buyback: 'Single Elimination',
  single_elimination_with_buyback: 'Single Elimination',
  double_elimination: 'Double Elimination',
};
