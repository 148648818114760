import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Box, makeStyles } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';

import { useOpenErrorModalDispatch } from 'hooks/redux/modal';

import BtcImage from 'assets/images/parlay/btc.png';
import IcxImage from 'assets/images/parlay/icx.png';
import SportsImage from 'assets/images/parlay/sports.png';
import UsdImage from 'assets/images/parlay/usd.png';

const cartImages = {
  btc: BtcImage,
  icx: IcxImage,
  sports: SportsImage,
  usdt: UsdImage,
  usd: UsdImage,
};

const useStyles = makeStyles(() => ({
  root: ({ backgroundImage }) => ({
    width: '80px',
    height: '80px',
    position: 'fixed',
    bottom: '70px',
    right: '8px',
    borderRadius: '50%',
    backgroundImage: `url(${backgroundImage || SportsImage})`,
    backgroundRepeat: 'no-repeat',
  }),
  badge: {
    position: 'absolute',
    right: '19px',
    top: '18px',
  },
}));

const ParlayCart = (props) => {
  const { parlay, history } = props;
  const classes = useStyles({
    backgroundImage: cartImages[parlay?.currency?.symbol],
  });

  const openErrorModal = useOpenErrorModalDispatch();

  const handleClick = () => {
    if (parlay?.list.length > 1) {
      history.push('/bets/parlay-checkout');
    } else {
      openErrorModal({
        title: 'Oops',
        subtitle: 'You can only check out and accept a Parlay Card that has 2 or more bets in it. '
          + 'Please add at least one more bet to this Parlay Card before checking out.',
        buttonText: 'Got it',
      });
    }
  };

  return (
    <Box className={classes.root} onClick={handleClick}>
      <Badge badgeContent={parlay?.list.length} className={classes.badge} color="error" />
    </Box>
  );
};

ParlayCart.defaultProps = {
  parlay: {},
};

ParlayCart.propTypes = {
  parlay: PropTypes.object,
  history: PropTypes.object.isRequired,
};

export default withRouter(ParlayCart);
