import * as actionTypes from 'store/actionTypes';

const INITIAL_STATE = {
  isLoading: false,
  list: [],
  settings: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_NOTIFICATION_SETTING_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_NOTIFICATION_SETTING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        settings: action.payload,
      };
    case actionTypes.GET_NOTIFICATION_SETTING_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.UPDATE_NOTIFICATION_SETTING_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.UPDATE_NOTIFICATION_SETTING_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.UPDATE_NOTIFICATION_SETTING_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.GET_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: action.payload,
      };
    case actionTypes.GET_NOTIFICATIONS_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
