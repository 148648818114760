import React from 'react';
import PropTypes from 'prop-types';
import { dateTimeOptions } from 'utils/constants';
import { formatCurrency } from 'utils/currency';

import { useMappedCurrencies } from 'hooks/redux/currency';

import {
  Box,
  Typography,
  makeStyles,
  Divider,
} from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => ({
  card: () => ({
    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.15)',
    padding: '10px 10px 10px 10px',
    borderRadius: '5px',
    background: palette.cardColor,
    margin: '10px 20px 10px',
  }),
  limitBuy: {
    textTransform: 'capitalize',
    color: '#54A400',
    background: '#ccffef',
    textAlign: 'center',
    width: '70px',
    font: '13px SofiaPro-Medium',
    padding: 4,
    marginBottom: '8px',
    borderRadius: '4px',
  },
  limitSell: {
    textTransform: 'capitalize',
    color: '#FC5A5A',
    background: '#ffd6cc',
    textAlign: 'center',
    width: '70px',
    font: '13px SofiaPro-Medium',
    padding: 4,
    marginBottom: '8px',
    borderRadius: '4px',
  },
  marketParent: {
    marginBottom: '8px',
  },
  market: {
    font: '14px SofiaPro-SemiBold',
    color: palette.secondaryColor,
    textTransform: 'capitalize',
  },
  date: {
    color: '#A4A4A4',
    font: '12px SofiaPro',
    letterSpacing: -0.1,
    marginBottom: '8px',
  },
  headersMargin: {
    marginBottom: '8px',
    marginTop: '8px',
  },
  cancel: {
    color: '#00DDE1',
    font: '12px SofiaPro-Medium',
  },
  headers: {
    color: '#A4A4A4',
    font: '11px SofiaPro',
    letterSpacing: -0.1,
  },
  prices: {
    color: palette.secondaryColor,
    font: '12px SofiaPro-Medium',
    letterSpacing: -0.1,
  },
}));

const Order = (props) => {
  const classes = useStyles();
  const mappedCurrencies = useMappedCurrencies();
  const {
    item,
    onCancel,
  } = props;
  return (
    <Box
      className={classes.card}
      display="flex"
      flexDirection="column"
      position="relative"
      justifyContent="space-between"
    >
      <Box
        display="flex"
        flexDirection="column"
      >
        {
          item.is_buying
            ? (
              <Typography variant="subtitle1" className={classes.limitBuy}>
                {`${item.order_type} Buy`}
              </Typography>
            )
            : (
              <Typography variant="subtitle1" className={classes.limitSell}>
                {`${item.order_type} Sell`}
              </Typography>
            )
        }
      </Box>
      <Box
        className={classes.marketParent}
        display="flex"
        flexDirection="row"
        position="relative"
        justifyContent="space-between"
      >
        <Typography variant="subtitle1" className={classes.market}>
          {item.market?.buy_currency?.symbol?.toUpperCase()}
          /
          {item.market?.sell_currency?.symbol?.toUpperCase()}
        </Typography>
        <Typography
          className={classes.cancel}
          onClick={() => onCancel(item)}
        >
          Cancel
        </Typography>
      </Box>
      <Typography variant="subtitle1" className={classes.date}>
        {new Date(item.created_at).toLocaleString('en-US', dateTimeOptions)}
      </Typography>
      <Divider />

      <Box
        className={classes.headersMargin}
        display="flex"
        flexDirection="row"
        position="relative"
        justifyContent="space-between"
      >
        <Typography variant="subtitle1" className={classes.headers}>
          Price(USDT)
        </Typography>
        <Typography variant="subtitle1" className={classes.headers}>
          Quantity
        </Typography>
        <Typography variant="subtitle1" className={classes.headers}>
          Filled
        </Typography>
      </Box>
      {mappedCurrencies[item.market?.sell_currency?.symbol] && mappedCurrencies[item.market?.buy_currency?.symbol] && (
        <Box
          display="flex"
          flexDirection="row"
          position="relative"
          justifyContent="space-between"
        >
          <Typography variant="subtitle1" className={classes.prices}>
            {formatCurrency(
              item.limit_price,
              mappedCurrencies[item.market.sell_currency.symbol],
              true,
              true,
              item.market.buy_currency.symbol,
            )}
          </Typography>
          <Typography variant="subtitle1" className={classes.prices}>
            {formatCurrency(
              item.amount,
              mappedCurrencies[item.market.buy_currency.symbol],
            )}
          </Typography>
          <Typography variant="subtitle1" className={classes.prices}>
            {formatCurrency(
              item.amount - item.amount_unfilled,
              mappedCurrencies[item.market.buy_currency.symbol],
            )}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

Order.defaultProps = {
  item: {},
  onCancel: () => {
  },
};

Order.propTypes = {
  item: PropTypes.object,
  onCancel: PropTypes.func,
};

export default Order;
