import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import { Box, Typography } from '@material-ui/core';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import CurrencyDropdown from 'components/Currency/CurrencyDropdown';
import InputCurrencyExchange from 'components/Form/InputCurrencyExchange';
import OutlineButton from 'components/Buttons/OutlineButton';

import {
  useOpenErrorModalDispatch,
  useOpenSuccessModalDispatch,
} from 'hooks/redux/modal';
import {
  useKycUserInfo,
  useKycDocInfo,
  useKycResourceToken,
  useUpdateAccountDispatch,
} from 'hooks/redux/kyc';
import { useSelectedCurrency, useMappedCurrencies } from 'hooks/redux/currency';
import { usdCurrency } from 'utils/constants';
import {
  formatCurrency,
  currencyConvert,
  convertCurrencyAmount,
} from 'utils/currency';
import { getSubmitKycInfo } from 'utils/kyc';
import { getErrorMessage } from 'utils';
import CommonStyle from 'styles/common';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 15px',
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
  },
  card: {
    borderRadius: '6px',
    boxShadow: '0 3px 10px 0 rgba(0,0,0,0.15)',
    marginTop: '15px',
    padding: '30px 30px',
    background: palette.cardColor,
    color: palette.secondaryColor,
    position: 'relative',
    overflow: 'hidden',
  },
  content: {
    marginTop: '10px',
    font: '17px SofiaPro',
    color: '#AEAEAE',
  },
  fee: {
    font: '15px SofiaPro-Bold',
    color: palette.secondaryColor,
  },
}));

const usdCurrencyAmount = '10.00';

const Payment = () => {
  const classes = useStyles();
  const commonClasses = CommonStyle();
  const [currency, setCurrency] = useState();
  const [cryptoCurrencyAmount, setCryptoCurrencyAmount] = useState('');
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const currencySymbol = useSelectedCurrency();
  const currencies = useMappedCurrencies();
  const user = useKycUserInfo();
  const doc = useKycDocInfo();
  const resourceToken = useKycResourceToken();
  const openSuccessModal = useOpenSuccessModalDispatch();
  const openErrorModal = useOpenErrorModalDispatch();
  const updateAccount = useUpdateAccountDispatch();

  const handleSubmit = async () => {
    setDisabledSubmit(true);
    try {
      const data = getSubmitKycInfo(user, doc, resourceToken);
      if (currencySymbol) {
        data.currency = currencySymbol;
      }
      await updateAccount(data);
      setDisabledSubmit(false);
      openSuccessModal({
        title: 'Successfully submitted!',
        subtitle: '',
        buttonText: 'Got it',
        nextPath: '/kyc/status',
      });
    } catch (e) {
      setDisabledSubmit(false);
      openErrorModal({
        title: 'Submit Error',
        subtitle: getErrorMessage(e),
        buttonText: 'Got it',
      });
    }
  };

  useEffect(() => {
    if (currencies[currencySymbol]) {
      setCurrency(currencies[currencySymbol]);
      setCryptoCurrencyAmount(
        formatCurrency(
          currencyConvert(
            convertCurrencyAmount(usdCurrencyAmount, usdCurrency),
            usdCurrency,
            currencies[currencySymbol],
          ),
          currencies[currencySymbol],
          false,
        ),
      );
    }
  }, [currencies, currencySymbol]);
  return (
    <Page>
      <Box className={classes.root} display="flex" flexDirection="column" flex={1}>
        <Box>
          <BackButton color={commonClasses.backBtn} page="/kyc/resolve" />
        </Box>
        <Box textAlign="center" mt={3} mb={3}>
          <Typography component="h3" className={classes.title}>
            KYC Verification Payment
          </Typography>
          <Typography className={classes.content}>
            Please select the currency you would like to pay the KYC verification in by tapping on the wallet area below.
          </Typography>
        </Box>
        <Box className={classes.card} mb={7}>
          <CurrencyDropdown />
          <Box display="flex" alignItems="center" flexDirection="column" mt={4}>
            <Typography className={classes.fee}>KYC Verification Fee</Typography>
            {
              currency && (
                <InputCurrencyExchange
                  currency={currency}
                  cryptoCurrencyAmount={cryptoCurrencyAmount}
                  usdCurrencyAmount={usdCurrencyAmount}
                  disabled
                />
              )
            }
          </Box>
        </Box>
        <Box mt={7}>
          <OutlineButton onClick={handleSubmit} disabled={disabledSubmit}>
            Submit KYC
          </OutlineButton>
        </Box>
      </Box>
    </Page>
  );
};

Payment.propTypes = {
  // history: PropTypes.object.isRequired,
};

export default withRouter(Payment);
