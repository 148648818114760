import httpService from './http.service';

const withdrawTransaction = (body) => httpService
  .post('/balance_transactions/withdraw', body)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

export default {
  withdrawTransaction,
};
