import * as actionTypes from 'store/actionTypes';

const INITIAL_STATE = {
  isLoading: false,
  isMarketChangeLoading: false,
  isOrdersLoading: false,
  isCreateOrderLoading: false,
  isCancelOrderLoading: false,
  markets: [],
  orders: [],
  marketDetails: {},
  createOrder: {},
  showRollingOver: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_MARKETS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_MARKETS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        markets: action.payload,
      };
    case actionTypes.GET_MARKETS_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.GET_MARKETS_DETAILS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_MARKETS_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        marketDetails: action.payload,
      };
    case actionTypes.GET_MARKETS_DETAILS_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.CREATE_EXCHANGE_ORDER_REQUEST:
      return {
        ...state,
        isCreateOrderLoading: true,
      };
    case actionTypes.CREATE_EXCHANGE_ORDER_SUCCESS:
      return {
        ...state,
        isCreateOrderLoading: false,
        createOrder: action.payload,
      };
    case actionTypes.CREATE_EXCHANGE_ORDER_FAIL:
      return {
        ...state,
        isCreateOrderLoading: false,
      };
    case actionTypes.GET_ORDERS_LIST_REQUEST:
      return {
        ...state,
        orders: [],
        isOrdersLoading: true,
      };
    case actionTypes.GET_ORDERS_LIST_SUCCESS:
      return {
        ...state,
        isOrdersLoading: false,
        orders: action.payload,
      };
    case actionTypes.GET_ORDERS_LIST_FAIL:
      return {
        ...state,
        isOrdersLoading: false,
      };
    case actionTypes.CANCEL_ORDER_REQUEST:
      return {
        ...state,
        isCancelOrderLoading: true,
      };
    case actionTypes.CANCEL_ORDER_SUCCESS:
      return {
        ...state,
        isCancelOrderLoading: false,
      };
    case actionTypes.CANCEL_ORDER_FAIL:
      return {
        ...state,
        isCancelOrderLoading: false,
      };
    case actionTypes.CANCEL_ALL_ORDERS_REQUEST:
      return {
        ...state,
        isCancelOrderLoading: true,
      };
    case actionTypes.CANCEL_ALL_ORDERS_SUCCESS:
      return {
        ...state,
        isCancelOrderLoading: false,
      };
    case actionTypes.CANCEL_ALL_ORDERS_FAIL:
      return {
        ...state,
        isCancelOrderLoading: false,
      };
    case actionTypes.HIDE_ROLLING_OVER:
      return {
        ...state,
        showRollingOver: false,
      };
    default:
      return state;
  }
};
