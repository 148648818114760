import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import BackButton from 'components/Buttons/BackButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import BetSummaryCard from 'components/Bet/BetSummaryCard';
import CurrencyDropdown from 'components/Currency/CurrencyDropdown';
import Loading from 'components/Loading/Box';
import InputCurrencyExchange from 'components/Form/InputCurrencyExchange';

import { usdCurrency } from 'utils/constants';
import { getAvailableMaxBetAmount, getBetSubmitInfo } from 'utils/betting';
import {
  currencyConvert,
  convertCurrencyAmount,
  getFormattedCurrency,
  formatCurrency,
} from 'utils/currency';
import {
  useBet,
  useSelectedBetType,
  useSelectedBetObject,
  useSelectedBetTime,
  useSelectedInterval,
  useSelectedGames,
  useSelectedBetSchedule,
  useSelectedBetOdds,
  useCreateBetDispatch,
  useEditBetDispatch,
} from 'hooks/redux/bet';
import { useSelectedCurrency, useMappedCurrencies } from 'hooks/redux/currency';
import { useOpenSuccessModalDispatch, useOpenErrorModalDispatch } from 'hooks/redux/modal';
import { getErrorMessage } from 'utils';
import commonStyle from 'styles/common';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 5px',
  },
  fixPadding: {
    padding: '0 15px',
  },
  line: {
    background: 'linear-gradient(45deg, #298EDA, #29D2D3)',
    height: '3px',
    marginLeft: '-27px',
    width: '105%',
  },
  container: {
    boxShadow: '0 -2px 15px rgba(0, 0, 0, 0.15)',
    borderRadius: '5px',
    backgroundColor: palette.cardColor,
  },
  subTitle: {
    color: palette.seaGreenColor,
    font: '14px SofiaPro-Bold',
    letterSpacing: 0,
  },
  description: {
    color: '#7A869A',
    font: '12px SofiaPro',
    letterSpacing: 0,
  },
  maxBetButton: {
    backgroundColor: palette.betBtnColor,
    color: '#29BFD5',
    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.15)',
    border: palette.betBtnBorder,
    borderRadius: '5px',
    padding: '8px 5px 5px 5px',
    font: '12px SofiaPro-Bold',
    textAlign: 'center',
  },
  receive: {
    borderTop: '1px solid #F5F7F9',
    paddingTop: '14px',
  },
  winAmount: {
    color: '#29CAD4',
    font: '13px SofiaPro-Bold',
    marginTop: '5px',
    maxWidth: '200px',
    textAlign: 'center',
  },
}));

const FinalSubmit = ({ match, mode }) => {
  const classes = useStyles();
  const commonClasses = commonStyle();
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [cryptoCurrencyAmount, setCryptoCurrencyAmount] = useState('');
  const [usdCurrencyAmount, setUsdCurrencyAmount] = useState('');
  const [expectedWinAmount, setExpectedWinAmount] = useState('');
  const [expectedWinUSDAmount, setExpectedWinUSDAmount] = useState('');
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const betting = useBet();
  const interval = useSelectedInterval();
  const games = useSelectedGames();
  const betType = useSelectedBetType();
  const schedule = useSelectedBetSchedule();
  const object = useSelectedBetObject();
  const betTime = useSelectedBetTime();
  const betOdds = useSelectedBetOdds();
  const createBet = useCreateBetDispatch();
  const editBet = useEditBetDispatch();
  const currencySymbol = useSelectedCurrency();
  const currencies = useMappedCurrencies();
  const openSuccessModal = useOpenSuccessModalDispatch();
  const openErrorModal = useOpenErrorModalDispatch();

  const changeBetAmount = (amount) => {
    setCryptoCurrencyAmount(amount);
    setUsdCurrencyAmount(
      formatCurrency(
        currencyConvert(
          convertCurrencyAmount(amount, selectedCurrency),
          selectedCurrency,
          usdCurrency,
        ),
        usdCurrency,
        false,
      ),
    );
  };

  const changeBetUsdAmount = (amount) => {
    setUsdCurrencyAmount(amount);
    setCryptoCurrencyAmount(
      formatCurrency(
        currencyConvert(
          convertCurrencyAmount(amount, usdCurrency),
          usdCurrency,
          selectedCurrency,
        ),
        selectedCurrency,
        false,
      ),
    );
  };

  const changeExpectedWinAmount = (amount) => {
    setExpectedWinAmount(amount);
    setExpectedWinUSDAmount(
      formatCurrency(
        currencyConvert(
          convertCurrencyAmount(amount, selectedCurrency),
          selectedCurrency,
          usdCurrency,
        ),
        usdCurrency,
        false,
      ),
    );
  };

  const changeExpectedUsdAmount = (amount) => {
    setExpectedWinUSDAmount(amount);
    setExpectedWinAmount(
      formatCurrency(
        currencyConvert(
          convertCurrencyAmount(amount, usdCurrency),
          usdCurrency,
          selectedCurrency,
        ),
        selectedCurrency,
        false,
      ),
    );
  };

  const handleUsdCurrencyAmount = (event) => {
    changeBetUsdAmount(event.target.value);
  };

  const handleCryptoCurrencyAmount = (event) => {
    changeBetAmount(event.target.value);
  };

  const handleExpectedWinAmount = (event) => {
    changeExpectedWinAmount(event.target.value);
  };

  const handleExpectedWinUSDAmount = (event) => {
    changeExpectedUsdAmount(event.target.value);
  };

  const handleMaxBet = () => {
    changeBetAmount(
      formatCurrency(
        getAvailableMaxBetAmount(
          selectedCurrency.balance,
          null,
          selectedCurrency.symbol,
          'create',
        ),
        selectedCurrency,
        false,
      ),
    );
  };

  const handleCreateBet = async () => {
    if (Number(cryptoCurrencyAmount) <= 0 || Number(expectedWinAmount) <= 0) {
      openErrorModal({ title: 'Oops', subtitle: 'Bet value should be greater than zero.' });
      return;
    }
    setDisabledSubmit(true);
    try {
      const data = {
        amount: convertCurrencyAmount(cryptoCurrencyAmount, selectedCurrency),
        return_currency: selectedCurrency.symbol,
        amount_currency: selectedCurrency.symbol,
        currency: selectedCurrency.symbol,
        proposed_return: convertCurrencyAmount(expectedWinAmount, selectedCurrency),
        bet_type_id: betType.id,
        betting_object_id: object.id,
        intervals: [],
        attributes: [],
        should_expire: betTime.should_expire,
        current_history: betTime.current_history,
      };

      if (betType.has_goal) {
        data.goal = betType.goal;
      }
      if (betType.is_write_in) {
        data.details = betType.description;
      }
      if (betType.attribute && betType.attribute.group_id && betType.attribute.id) {
        data.attributes.push({
          group_id: betType.attribute.group_id,
          id: betType.attribute.id,
        });
      }
      if (games.length) {
        games.forEach((game) => {
          data.intervals.push({
            type: interval,
            id: game.id,
          });
        });
      } else if (schedule !== null && schedule.id != null) {
        data.intervals.push({
          type: 'season',
          id: schedule.id,
        });
      }
      let title = '';
      let content = '';
      if (mode === 'create') {
        await createBet(data);
        title = 'Woohoo!';
        content = `Your bet has been created and is now available for others to accept on the Bets screen. You can
      update and manage your bet from the Results screen. You can also share your bet with others by tapping
      the "Share" button in the upper right corner of the bet. Best of luck! 😎`;
      } else {
        await editBet({ ...match.params, data });
        title = 'Success!';
        content = `Your bet has been edited and we've replaced the previous bet details with the new bet details that you just updated.
        These changes are effective immediately within ZenSports will still display the old information of your bet until someone clicks
        on the link and comes into ZenSports (at which point, they'll see the new bet details).`;
      }
      setDisabledSubmit(false);
      openSuccessModal({
        title,
        subtitle: content,
        buttonText: 'Done',
        nextPath: '/bets',
      });
    } catch (e) {
      setDisabledSubmit(false);
      openErrorModal({ title: 'Oops', subtitle: getErrorMessage(e) });
    }
  };

  useEffect(() => {
    if (currencies[currencySymbol]) {
      setSelectedCurrency(currencies[currencySymbol]);
    }
  }, [currencies, currencySymbol]);

  useEffect(() => {
    if (betOdds && betOdds.proposed_bet_amount && betOdds.proposed_return && selectedCurrency) {
      changeBetAmount(betOdds.proposed_bet_amount.toString());
      changeExpectedWinAmount(betOdds.proposed_return.toString());
    }
    // eslint-disable-next-line
  }, [betOdds, selectedCurrency]);

  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.line} mb={2} />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <BackButton color={commonClasses.backBtn} />
        </Box>
        {betting.isLoading && (<Loading />)}
        <Box className={classes.container}>
          {games && object && betType && interval && schedule && (
            <BetSummaryCard betInfo={
              getBetSubmitInfo({
                games,
                bettingObject: object,
                betType,
                intervalType: interval,
                interval: {
                  game: games[0] || null,
                  season: schedule,
                },
              })
            }
            />
          )}
          <Box className={classes.fixPadding} pl={3} pr={3}>
            <CurrencyDropdown changeable={mode === 'create'} />
            <Box mt={3} mb={1} textAlign="center">
              <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                <Typography variant="subtitle2" className={classes.subTitle}>
                  Your Bet Amount
                </Typography>
                <Typography variant="subtitle2" className={classes.description}>
                  Withdrawn from your account balance today
                </Typography>
              </Box>
            </Box>
            <Box display="flex" justifyContent="flex-start" alignItems="center" pl={2} mb={1}>
              {selectedCurrency && (
                <>
                  <Box flex={1}>
                    <InputCurrencyExchange
                      currency={selectedCurrency}
                      cryptoCurrencyAmount={cryptoCurrencyAmount}
                      usdCurrencyAmount={usdCurrencyAmount}
                      onCryptoCurrencyAmountChange={handleCryptoCurrencyAmount}
                      onUsdCurrencyAmountChange={handleUsdCurrencyAmount}
                    />
                  </Box>
                  <Box className={classes.maxBetButton} onClick={handleMaxBet}>Max Bet</Box>
                </>
              )}
            </Box>
            <Box mt={3} mb={1} textAlign="center">
              <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                <Typography variant="subtitle2" className={classes.subTitle}>
                  Amount Expected to Win
                </Typography>
                <Typography variant="subtitle2" className={classes.description}>
                  Withdrawn from your account balance today
                </Typography>
              </Box>
            </Box>
            <Box display="flex" justifyContent="flex-start" alignItems="center" pl={2} mb={1}>
              {selectedCurrency && (
                <>
                  <Box flex={1}>
                    <InputCurrencyExchange
                      currency={selectedCurrency}
                      cryptoCurrencyAmount={expectedWinAmount}
                      usdCurrencyAmount={expectedWinUSDAmount}
                      onCryptoCurrencyAmountChange={handleExpectedWinAmount}
                      onUsdCurrencyAmountChange={handleExpectedWinUSDAmount}
                    />
                  </Box>
                  <Box className={classes.maxBetButton} visibility="hidden">Max Bet</Box>
                </>
              )}
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
              className={classes.receive}
              mb={4}
            >
              <Typography variant="subtitle2" className={classes.subTitle}>
                Total You’ll Receive If You Win
              </Typography>
              <Typography variant="subtitle2" className={classes.description}>
                Withdrawn from your account balance today
              </Typography>
              {selectedCurrency && (
                <Typography variant="subtitle2" className={classes.winAmount}>
                  {
                    getFormattedCurrency(
                      convertCurrencyAmount(
                        Number(
                          cryptoCurrencyAmount.replace(/,/g, ''),
                        ) + Number(
                          expectedWinAmount.replace(/,/g, ''),
                        ),
                        selectedCurrency,
                      ),
                      selectedCurrency,
                    )
                  }
                </Typography>
              )}
            </Box>
            <Box pb={3}>
              {
                mode === 'create' ? (
                  <OutlineButton disabled={disabledSubmit} onClick={handleCreateBet}>
                    Create Bet
                  </OutlineButton>
                ) : (
                  <OutlineButton disabled={disabledSubmit} onClick={handleCreateBet}>
                    Done
                  </OutlineButton>
                )
              }
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

FinalSubmit.defaultProps = {
  mode: 'create',
};

FinalSubmit.propTypes = {
  match: PropTypes.object.isRequired,
  mode: PropTypes.string,
};

export default withRouter(FinalSubmit);
