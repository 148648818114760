import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import { Formik } from 'formik';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  useGetTournamentQuery,
  useSetTournamentQueryDispatch,
  useUpdateTournamentDispatch,
} from 'hooks/redux/play';
import { useOpenErrorModalDispatch } from 'hooks/redux/modal';
import { getErrorMessage } from 'utils';

import SelectButton from 'components/Buttons/SelectButton';
import BackButton from 'components/Buttons/BackButton';
import OutlineButton from 'components/Buttons/BorderOutlineButton';
import ZenDialog from 'components/Dialog/ZenDialog';

import commonStyles from 'styles/common';

const useStyles = makeStyles((theme) => ({
  stepLine: {
    height: '3px',
    background: 'linear-gradient(to right, #3597da, #36b6d6, #38c8d4)',
    margin: '-10px -10px 10px',
    width: '60%',
  },
  root: {
    padding: '0 15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    '& .datePicker': {
      '& input': {
        marginTop: '20px',
      },
    },
  },
  form: {
    height: '100%',
  },
  backBtn: {
    color: theme.palette.secondaryColor,
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: theme.palette.secondaryColor,
    width: '80%',
    textAlign: 'center',
    margin: 'auto',
  },
  topic: {
    font: '18px SofiaPro-SemiBold',
    color: theme.palette.secondaryColor,
    letterSpacing: -0.3,
  },
  selectedButton: {
    '&:not(:last-child)': {
      marginRight: '10px',
    },
  },
  icon: {
    marginLeft: '10px',
    marginTop: '5px',
    color: '#29CCD3',
    fontSize: '20px',
  },

}));

const tooltipMessage = 'Select the frequency you would like to hold this competition.'
  + 'You can select from either Once or Weekly and the competition will be re-created at the frequency you select.';

const TournamentDate = ({ history, edit }) => {
  const classes = useStyles();
  const commonClasses = commonStyles();

  const data = useGetTournamentQuery();
  const setTournamentQuery = useSetTournamentQueryDispatch();
  const updateTournament = useUpdateTournamentDispatch();
  const openErrorModal = useOpenErrorModalDispatch();

  const [form, setForm] = useState(data);
  const [date, setDate] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleOpenTooltip = () => {
    setTooltipOpen(true);
  };

  const handleCloseTooltip = () => {
    setTooltipOpen(false);
  };

  const onHandleFrequency = (name) => {
    setForm({
      ...form,
      frequency: name,
    });
  };

  const handleDateChange = (value) => {
    setDate(value);
  };

  useEffect(() => {
    if (!data.frequency && !data.date) {
      setForm({
        ...data,
        frequency: 'once',
      });
    } else if (data && edit) {
      setForm(data);
      setDate(data.date_iso8601);
    }
  }, [data, edit]);

  return (
    <>
      <Formik
        initialValues={{
          date,
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            setSubmitting(true);
            const query = {
              ...data,
              date,
              frequency: form.frequency,
            };
            setTournamentQuery(query);
            if (edit) {
              await updateTournament(data.id, {
                tournament: query,
              });
              history.push(`/more/play/tournament/view/${data.id}/edit`);
            } else {
              history.push('/more/play/tournament/create/fees');
            }
          } catch (e) {
            const message = getErrorMessage(e);
            openErrorModal({ title: 'Oops', subtitle: message });
          }
        }}
      >
        {({
          errors,
          touched,
          isSubmitting,
          handleSubmit,
          handleBlur,
        }) => (
          <form onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.form}>
            <Box className={classes.stepLine} />
            <Box className={classes.root}>
              <Box>
                <Box>
                  <BackButton color={classes.backBtn} />
                </Box>
                <Box textAlign="center" mb={4} mt={2}>
                  <Typography className={commonClasses.subTitle}>
                    Step 3
                  </Typography>
                  <Typography component="h3" className={classes.title}>
                    When is the Competition?
                  </Typography>
                </Box>
                <Box mb={3} mt={1}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      autoComplete="off"
                      fullWidth
                      placeholder="Competition Date/Time"
                      label="Competition Date/Time (Your local time) *"
                      disablePast
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="date"
                      className={`datePicker ${commonClasses.textField}`}
                      onBlur={handleBlur}
                      onChange={handleDateChange}
                      value={date}
                    />
                  </MuiPickersUtilsProvider>
                  {
                    (errors.date && touched.date) && (
                      <Typography className={commonClasses.error}>
                        {errors.date}
                      </Typography>
                    )
                  }
                </Box>
                <Box display="flex" alignItems="center">
                  <Typography className={classes.topic}>Frequency *</Typography>
                  <InfoOutlinedIcon
                    className={classes.icon}
                    onClick={handleOpenTooltip}
                  />
                  {tooltipOpen && (
                  <ZenDialog
                    title="Frequency"
                    message={tooltipMessage}
                    cta="Got It!"
                    onClose={handleCloseTooltip}
                  />
                  )}
                </Box>

                <Box display="flex" justifyContent="flex-start" mt={1} mb={4}>
                  <SelectButton
                    className={classes.selectedButton}
                    selected={form.frequency === 'once'}
                    onClick={() => onHandleFrequency('once')}
                  >
                    Once
                  </SelectButton>
                  <SelectButton
                    className={classes.selectedButton}
                    selected={form.frequency === 'weekly'}
                    onClick={() => onHandleFrequency('weekly')}
                  >
                    Weekly
                  </SelectButton>
                </Box>
              </Box>
              <OutlineButton
                type="submit"
                style={{ margin: '20px 0' }}
                disabled={isSubmitting}
              >
                {edit ? 'Save' : 'Next'}
              </OutlineButton>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

TournamentDate.defaultProps = {
  edit: false,
};

TournamentDate.propTypes = {
  history: PropTypes.object.isRequired,
  edit: PropTypes.bool,
};

export default withRouter(TournamentDate);
