import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

import TrashButton from 'components/Buttons/TrashButton';

const useStyles = makeStyles(({ palette }) => ({
  card: ({ format, backgroundColor }) => ({
    margin: format !== 'single' ? '5px 5px 15px 5px' : 0,
    backgroundColor: (format !== 'single' || palette.type === 'dark')
      ? palette.cardColor
      : backgroundColor,
    // background: `url(${props.imageUrl})`,
    padding: '10px 10px 0',
    borderRadius: format !== 'single' ? '5px' : '5px 5px 0 0',
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 4px',
  }),
  info: {
    padding: '5px 0',
  },
  imageGroup: {
    width: '60px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  image: {
    height: '45px',
    width: '45px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    '& img': {
      width: '100%',
      height: 'auto',
    },
  },
  title: ({ format, textColor }) => ({
    color: (format !== 'single' || palette.type === 'dark')
      ? palette.secondaryColor
      : textColor,
    font: '16px SofiaPro-SemiBold',
    letterSpacing: -0.3,
  }),
  type: ({ format, textColor }) => ({
    color: (format !== 'single' || palette.type === 'dark') ? '#A4A4A4' : textColor,
    font: '12px SofiaPro',
    letterSpacing: -0.1,
    marginTop: '8px',
  }),
  note: {
    color: palette.secondaryColor,
    font: '12px SofiaPro-Bold',
  },
  trashBtn: {
    color: palette.secondaryColor,
  },
  betId: ({ format, textColor }) => ({
    color: (format !== 'single' || palette.type === 'dark') ? '#A4A4A4' : textColor,
    font: '11px SofiaPro',
    letterSpacing: -0.1,
  }),
  description: ({ format, textColor }) => ({
    color: (format !== 'single' || palette.type === 'dark')
      ? palette.secondaryColor
      : textColor,
    font: '13px SofiaPro',
    letterSpacing: -0.1,
  }),
  oddsContainer: {
    borderTop: '1px solid #F1F1F5',
    padding: '10px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  oddsTopic: ({ format, textColor }) => ({
    backgroundColor: format !== 'single' ? '#FC5A5A' : '#ffffff1a',
    color: format !== 'single' ? '#FFF' : textColor,
    padding: '4px 10px 3px 10px',
    font: '13px SofiaPro-Medium',
    borderRadius: '20px',
  }),
  odds: ({ format, textColor }) => ({
    color: (format !== 'single' || palette.type === 'dark') ? '#20CFD4' : textColor,
    font: '16px SofiaPro-Medium',
    letterSpacing: -0.25,
  }),
  maxBetTopic: ({ format, textColor }) => ({
    backgroundColor: format !== 'single' ? '#6236FF' : '#ffffff1a',
    color: (format !== 'single' || palette.type === 'dark') ? '#FFF' : textColor,
    padding: '4px 10px 3px 10px',
    font: '13px SofiaPro-Medium',
    borderRadius: '20px',
  }),
  amount: ({ format, textColor, currencyColor }) => ({
    color: (format !== 'single' || palette.type === 'dark') ? currencyColor : textColor,
    font: '16px SofiaPro-Medium',
    letterSpacing: -0.2,
    flex: 1,
    marginLeft: '10px',
    textAlign: 'right',
  }),
  ctaWrapper: {
    margin: '0 -15px 0',
  },
  cta: {
    height: '45px',
    backgroundColor: '#6236ff26',
    font: '14px SofiaPro-Medium',
    color: palette.type === 'dark' ? '#FFF' : '#6236FF',
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    '&:nth-child(2)': {
      marginLeft: '3px',
    },
  },
}));

const BetInfoCard = ({
  betInfo,
  format,
  showTrashBtn,
  showMaxBet,
  showAcceptBet,
  showAddParlay,
  showNotice,
  onClick,
  onAddParlay,
  onDeleteParlay,
}) => {
  const {
    id,
    title,
    type,
    imageUrl,
    imageTwoUrl,
    description,
    time,
    formattedOddsAmount,
    formattedMaxBetLabel,
    formattedMaxBetAmount,
    currencyColor,
    backgroundColor,
    textColor,
    isWriteIn,
  } = betInfo;

  const classes = useStyles({
    format,
    currencyColor,
    backgroundColor,
    textColor,
    imageUrl,
  });

  return (
    <Box className={`rippleContainer ${classes.card}`}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        className={classes.info}
        onClick={onClick}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          pb={1}
        >
          <Box className={classes.imageGroup}>
            <Box className={classes.image}>
              {imageUrl && (<img src={imageUrl} alt="One" />)}
            </Box>
            {imageTwoUrl && (
              <Box className={classes.image}>
                <img src={imageTwoUrl} alt="Two" />
              </Box>
            )}
          </Box>
          <Box flex={1}>
            <Typography variant="subtitle1" className={classes.title}>
              {title}
            </Typography>
            <Typography variant="subtitle1" className={classes.type}>
              {type}
              {isWriteIn && showNotice && (
                <span className={classes.note}> Note: You are betting that this WON&lsquo;T happen.</span>
              )}
            </Typography>
            <Box mt={1}>
              <Typography variant="subtitle1" className={classes.description}>
                {description}
              </Typography>
              <Typography variant="subtitle1" className={classes.description}>
                {time}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" flexDirection="column">
          {showTrashBtn && (
            <TrashButton color={classes.trashBtn} onClick={onDeleteParlay} />
          )}
          <Box mt={1}>
            <Typography variant="subtitle1" className={classes.betId}>
              Bet ID:
            </Typography>
            <Typography variant="subtitle1" className={classes.betId}>
              {id}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box onClick={onClick} className={classes.oddsContainer}>
        <Typography variant="subtitle1" className={classes.oddsTopic}>
          Odds
        </Typography>
        <Typography variant="subtitle1" className={classes.odds}>
          {formattedOddsAmount}
        </Typography>
      </Box>
      {showMaxBet && (
        <Box onClick={onClick} className={classes.oddsContainer}>
          <Typography variant="subtitle1" className={classes.maxBetTopic}>
            {formattedMaxBetLabel}
          </Typography>
          <Typography variant="subtitle1" className={classes.amount}>
            {formattedMaxBetAmount}
          </Typography>
        </Box>
      )}
      <Box display="flex" className={classes.ctaWrapper}>
        {showAcceptBet && (
          <Box onClick={onClick} className={classes.cta}>Accept Bet</Box>
        )}
        {showAddParlay && (
          <Box className={classes.cta} onClick={onAddParlay}>Add to Parlay</Box>
        )}
      </Box>
    </Box>
  );
};

BetInfoCard.defaultProps = {
  betInfo: {
    id: null,
    title: null,
    imageUrl: null,
    imageTwoUrl: null,
    type: null,
    description: null,
    time: '',
    formattedOddsAmount: '',
    formattedMaxBetAmount: '',
    currencyColor: '',
    backgroundColor: '#FFFFFF',
    textColor: '#14133D',
  },
  format: 'list',
  showMaxBet: true,
  showTrashBtn: false,
  showAddParlay: false,
  showAcceptBet: true,
  showNotice: false,
  onClick: () => {},
  onAddParlay: () => {},
  onDeleteParlay: () => {},
};

BetInfoCard.propTypes = {
  betInfo: PropTypes.object,
  format: PropTypes.string,
  showMaxBet: PropTypes.bool,
  showTrashBtn: PropTypes.bool,
  showAddParlay: PropTypes.bool,
  showAcceptBet: PropTypes.bool,
  showNotice: PropTypes.bool,
  onClick: PropTypes.func,
  onAddParlay: PropTypes.func,
  onDeleteParlay: PropTypes.func,
};

export default BetInfoCard;
