import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import { Box, Grid } from '@material-ui/core';

import CryptoWithdrawCard from 'components/Withdraw/CryptoWithdrawCard';
import WireIcon from 'assets/images/funds/wire.png';

import { useOpenSuccessModalDispatch } from 'hooks/redux/modal';
import { useUserRestrictedAccess, useCheckNevadaLocation } from 'hooks/redux/user';
import { nevadaBlockMessage } from 'utils/constants';

const useStyles = makeStyles(({ palette }) => ({
  walletImage: {
    height: '50px',
  },
  withdraw: {
    borderRadius: '10px',
    padding: '7px 20px',
    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.15)',
    color: palette.secondaryColor,
    background: palette.cardColor,
    font: '18px SofiaPro-Bold',
  },
  usdt: ({ collapsed }) => ({
    borderBottom: !collapsed ? '1px solid #F1F1F5' : 'none',
  }),
}));

const UsdWithdrawCard = ({
  usdtCurrency,
  account,
  kycVerified,
  handleKyc,
  history,
}) => {
  const [usdtCollapsed, setUsdtCollapsed] = useState(true);
  const classes = useStyles({ collapsed: usdtCollapsed });
  const access = useUserRestrictedAccess();
  const nevadaLocation = useCheckNevadaLocation();
  const openSuccessModal = useOpenSuccessModalDispatch();

  const handleWireWithdraw = () => {
    if (nevadaLocation === 'UNVERIFIED') {
      openSuccessModal({
        title: 'In-Person Registration Required',
        subtitle: nevadaBlockMessage,
        buttonText: 'Got It',
      });
      return;
    }
    if (handleKyc()) {
      const bankAccounts = account.bank_accounts?.filter((item) => !item.inactive);
      if (bankAccounts.length) {
        history.push('/funds/withdraw/wire-transfer/submit');
      } else {
        history.push('/funds/withdraw/wire-transfer/instructions');
      }
    }
  };

  return (
    <Box>
      {access.isCryptoVisible && (
        <Box className={classes.withdraw} mt={2}>
          <Box display="flex" alignItems="center" className={classes.usdt} onClick={() => setUsdtCollapsed(!usdtCollapsed)}>
            <Grid item xs={4} sm={4}>
              <Box>
                <img className={classes.walletImage} src={usdtCurrency.wallet_image_url} alt="navigation" />
              </Box>
            </Grid>
            <Grid item xs={8} sm={8}>
              USDT (Tether)
            </Grid>
          </Box>
          {
            kycVerified && usdtCurrency && !usdtCollapsed && (
              <CryptoWithdrawCard currency={usdtCurrency} noBorder />
            )
          }
        </Box>
      )}
      <Box
        mt={2}
        mb={2}
        className={classes.withdraw}
        display="flex"
        alignItems="center"
        onClick={handleWireWithdraw}
      >
        <Grid item xs={4} sm={4}>
          <Box>
            <img className={classes.walletImage} src={WireIcon} alt="navigation" />
          </Box>
        </Grid>
        <Grid item xs={8} sm={8}>
          Wire Transfer
        </Grid>
      </Box>
    </Box>
  );
};

UsdWithdrawCard.defaultProps = {
  usdtCurrency: {},
  account: {},
  kycVerified: false,
};

UsdWithdrawCard.propTypes = {
  usdtCurrency: PropTypes.object,
  account: PropTypes.object,
  kycVerified: PropTypes.bool,
  history: PropTypes.object.isRequired,
  handleKyc: PropTypes.func.isRequired,
};

export default withRouter(UsdWithdrawCard);
