import React from 'react';
import PropTypes from 'prop-types';

import PullToRefresh from 'react-simple-pull-to-refresh';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import BottomNavigation from 'components/Navigation/Bottom';
import { isSafari } from 'utils';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    // top: 0,
    // bottom: 0,
    // right: 0,
    // left: 0,
    // position: 'fixed',
    height: '100%',
    width: '100%',
    minHeight: '600px',
    overflowY: 'scroll',
    WebkitOverflowScrolling: 'touch',
    background: palette.themeColor,
  },
  container: {
    padding: '10px 5px 0px',
    overflowX: 'hidden',
    zIndex: 1,
    position: 'relative',
    height: 'calc(100% - 80px)',
    width: 'calc(100% - 10px)',
    display: 'flex',
    flexDirection: 'column',
    '&.noTopPadding': {
      paddingTop: '0px',
      paddingBottom: '10px',
    },
  },
}));

const PageDashboard = (props) => {
  const { children, showNavigation, noTopPadding } = props;
  const classes = useStyles();
  return (
    <PullToRefresh
      onRefresh={() => window.location.reload()}
      isPullable={isSafari}
      pullDownThreshold="50"
      maxPullDownDistance="50"
    >
      <Box className={classes.root}>
        <Box className={`${noTopPadding ? 'noTopPadding' : ''} ${classes.container}`}>
          {children}
        </Box>
        {showNavigation && (
          <BottomNavigation />
        )}
      </Box>
    </PullToRefresh>
  );
};

PageDashboard.defaultProps = {
  showNavigation: true,
  noTopPadding: false,
};

PageDashboard.propTypes = {
  children: PropTypes.node.isRequired,
  showNavigation: PropTypes.bool,
  noTopPadding: PropTypes.bool,
};

export default PageDashboard;
