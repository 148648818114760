import * as actionTypes from 'store/actionTypes';
import storageService from 'services/storage.service';
import config from 'utils/config';

export const loadThemeAction = () => async (dispatch) => {
  const theme = storageService.getItem(config.themeKey);
  if (theme) {
    dispatch({
      type: actionTypes.CHANGE_THEME,
      payload: { theme },
    });
  }
};

export const changeThemeAction = (theme) => async (dispatch) => {
  dispatch({
    type: actionTypes.CHANGE_THEME,
    payload: { theme },
  });
};

export const showNavigationAction = (payload) => async (dispatch) => {
  dispatch({
    type: actionTypes.SHOW_NAVIGATION,
    payload,
  });
};
