import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@material-ui/core';
import Circle from '@material-ui/icons/PanoramaFishEye';
import CheckCircle from '@material-ui/icons/CheckCircle';

const PrimaryCheckbox = withStyles({
  root: {
    color: '#979797',
    '&.Mui-checked': {
      color: '#63C4C6',
    },
  },
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles(({ palette }) => ({
  playerName: {
    color: palette.secondaryColor,
    font: '19px SofiaPro-SemiBold',
    letterSpacing: -0.3,
  },
  name: {
    color: palette.secondaryColor,
    font: '19px SofiaPro-SemiBold',
  },
  checkBox: {
    margin: '0px',
  },
}));

const SeasonCard = ({
  name, id, selectedId, selectSeason,
}) => {
  const classes = useStyles();
  return (
    <Box display="flex" justifyContent="space-between" mb={1}>
      <Box pl={2} flex={1} alignItems="flex-start" display="flex" flexDirection="column" justifyContent="center">
        <Typography variant="subtitle1" className={classes.name}>
          {name}
        </Typography>
      </Box>
      <FormControlLabel
        control={(
          <PrimaryCheckbox
            icon={<Circle />}
            checkedIcon={<CheckCircle />}
            checked={id === selectedId}
            onChange={(e) => selectSeason(e.currentTarget.value, e.target.checked)}
            name="checkedH"
            size="medium"
            value={id}
          />
        )}
        label=""
        className={classes.checkBox}
      />
    </Box>
  );
};

SeasonCard.defaultProps = {
  name: null,
  id: 0,
  selectedId: 0,
  selectSeason: () => {},
};

SeasonCard.propTypes = {
  name: PropTypes.string,
  id: PropTypes.number,
  selectedId: PropTypes.number,
  selectSeason: PropTypes.func,
};

export default SeasonCard;
