import * as actionTypes from 'store/actionTypes';
import betService from 'services/bet.service';
import storageService from 'services/storage.service';
import algoliaService from 'services/algolia.service';
import config from 'utils/config';
import { betsSortCriteria } from 'utils/constants';
import { sortAll, sortResultBets } from 'utils/betting.sort';

export const getLeaguesAction = () => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.GET_LEAGUES_REQUEST,
    });

    try {
      const response = await betService.getLeagues();
      const sortedLeagues = response.sort((a, b) => a.league_order - b.league_order);
      dispatch({
        type: actionTypes.GET_LEAGUES_SUCCESS,
        payload: { leagues: sortedLeagues },
      });

      return sortedLeagues;
    } catch (e) {
      dispatch({
        type: actionTypes.GET_LEAGUES_FAIL,
      });
    }
  } else {
    dispatch({
      type: actionTypes.GET_LEAGUES_FAIL,
    });
  }
};

export const getSchedulesAction = (params) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.GET_SCHEDULES_REQUEST,
    });

    try {
      const response = await betService.getSchedules(params);
      const sortedResponse = response.sort((a, b) => {
        if (a.name < b.name) {
          return 1;
        }
        if (a.name > b.name) {
          return -1;
        }
        return 0;
      });
      dispatch({
        type: actionTypes.GET_SCHEDULES_SUCCESS,
        payload: { schedules: sortedResponse },
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.GET_SCHEDULES_FAIL,
      });
    }
  } else {
    dispatch({
      type: actionTypes.GET_SCHEDULES_FAIL,
    });
  }
};

export const getBetTypesAction = (params) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.GET_BET_TYPES_REQUEST,
    });

    try {
      const response = await betService.getBetTypes(params);
      const sortedBetTypes = response.sort((a, b) => a.order - b.order);
      dispatch({
        type: actionTypes.GET_BET_TYPES_SUCCESS,
        payload: { betTypes: sortedBetTypes },
      });

      return sortedBetTypes;
    } catch (e) {
      dispatch({
        type: actionTypes.GET_BET_TYPES_FAIL,
      });
    }
  } else {
    dispatch({
      type: actionTypes.GET_BET_TYPES_FAIL,
    });
  }
};

export const getBetListAction = (query, options = {}) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.GET_BETS_REQUEST,
    });

    try {
      const { filter, ...rest } = options;
      let indexName = 'ProposedBets';
      if (filter?.games?.length === 0) {
        indexName = filter.isExpired ? 'ProposedBetsReverseChronological' : 'ProposedBetsChronological';
      }
      const response = await algoliaService.search(indexName, query, rest);
      const sortedResponse = sortAll(response, options);
      dispatch({
        type: actionTypes.GET_BETS_SUCCESS,
        payload: { list: sortedResponse },
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.GET_BETS_FAIL,
      });
    }
  } else {
    dispatch({
      type: actionTypes.GET_BETS_FAIL,
    });
  }
};

export const getBetResultListAction = (query, options = {}) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.GET_BET_RESULTS_REQUEST,
    });

    try {
      const { filter, ...rest } = options;
      const indexName = filter.isExpired ? 'MyBetsReverseChronological' : 'MyBetsChronological';
      const response = await algoliaService.search(indexName, query, rest);
      const sortedResponse = sortResultBets(response, options);

      dispatch({
        type: actionTypes.GET_BET_RESULTS_SUCCESS,
        payload: { list: sortedResponse },
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.GET_BET_RESULTS_FAIL,
      });
    }
  } else {
    dispatch({
      type: actionTypes.GET_BET_RESULTS_FAIL,
    });
  }
};

export const getBetFavoriteListAction = (query, options = {}) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.GET_BET_FAVORITES_REQUEST,
    });

    try {
      const { filter, ...rest } = options;
      const indexName = filter.isExpired ? 'ProposedBetsReverseChronological' : 'ProposedBetsChronological';
      if (filter?.favorites?.length) {
        const response = await algoliaService.search(indexName, query, rest);
        const sortedResponse = sortAll(response, options);

        dispatch({
          type: actionTypes.GET_BET_FAVORITES_SUCCESS,
          payload: { list: sortedResponse },
        });

        return response;
      }

      dispatch({
        type: actionTypes.GET_BET_FAVORITES_SUCCESS,
        payload: { list: [] },
      });
      return [];
    } catch (e) {
      dispatch({
        type: actionTypes.GET_BET_FAVORITES_FAIL,
      });
    }
  } else {
    dispatch({
      type: actionTypes.GET_BET_FAVORITES_FAIL,
    });
  }
};

export const getUpcomingGamesAction = (query, options = {}) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.GET_UPCOMING_GAMES_REQUEST,
    });

    try {
      const response = await algoliaService.search('Games', query, options);
      const list = response.filter((item) => {
        if (!item.date_time) {
          return false;
        }
        const gameDate = new Date(item.date_time);
        const nowDate = new Date();
        if (item.betting_league_id === 8) {
          nowDate.setHours(nowDate.getHours() - 96);
          return gameDate >= nowDate;
        }

        if (item.betting_league_id === 11) {
          nowDate.setHours(nowDate.getHours() - 12);
          return gameDate >= nowDate;
        }

        nowDate.setHours(nowDate.getHours() - 5);
        return gameDate >= nowDate;
      });

      dispatch({
        type: actionTypes.GET_UPCOMING_GAMES_SUCCESS,
        payload: { list },
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.GET_UPCOMING_GAMES_FAIL,
      });
    }
  } else {
    dispatch({
      type: actionTypes.GET_UPCOMING_GAMES_FAIL,
    });
  }
};

export const getBettingObjectsAction = (query, options = {}) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.GET_BET_OBJECTS_REQUEST,
    });

    try {
      let leagueId = 0;
      let sortedBetObjects = [];
      let betObjects = [];

      if (options.facetFilters.length) {
        leagueId = Number(options.facetFilters[0][0].split(':')[1]);
      }

      if (leagueId === 11 || leagueId === 16 || leagueId === 17) {
        const response = await betService.getBetObjects({ data: { game_ids: options.gameIds } });
        if (response.teams.length) {
          betObjects.push(...response.teams);
        }
        if (response.players.length) {
          betObjects.push(...response.players);
        }
      } else {
        const { gameIds, ...restOptions } = options;
        betObjects = await algoliaService.search('BettingObjects', query, restOptions);
      }
      // sort by name
      sortedBetObjects = betObjects.sort((a, b) => {
        const aName = a.type === 'player' ? `${a.first_name} ${a.last_name}` : a.display_name;
        const bName = b.type === 'player' ? `${b.first_name} ${b.last_name}` : b.display_name;
        if (aName > bName) {
          return 1;
        }
        if (aName < bName) {
          return -1;
        }
        return 0;
      });
      // sort by object type
      sortedBetObjects = betObjects.sort(
        (a, b) => betsSortCriteria.objectTypes.indexOf(a.type) - betsSortCriteria.objectTypes.indexOf(b.type),
      );

      dispatch({
        type: actionTypes.GET_BET_OBJECTS_SUCCESS,
        payload: { list: sortedBetObjects },
      });

      return sortedBetObjects;
    } catch (e) {
      dispatch({
        type: actionTypes.GET_BET_OBJECTS_FAIL,
      });
    }
  } else {
    dispatch({
      type: actionTypes.GET_BET_OBJECTS_FAIL,
    });
  }
};

export const getSingleBetAction = (params) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.GET_BET_REQUEST,
    });

    try {
      const response = await betService.getSingleBet(params);
      dispatch({
        type: actionTypes.GET_BET_SUCCESS,
        payload: { single: response },
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.GET_BET_FAIL,
      });
    }
  } else {
    dispatch({
      type: actionTypes.GET_BET_FAIL,
    });
  }
};

export const acceptBetAction = (params) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.ACCEPT_BET_REQUEST,
    });

    try {
      const response = await betService.acceptBet(params);
      dispatch({
        type: actionTypes.ACCEPT_BET_SUCCESS,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.ACCEPT_BET_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.ACCEPT_BET_FAIL,
    });
  }
};

export const createBetAction = (params) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.CREATE_BET_REQUEST,
    });

    try {
      const response = await betService.createBet(params);
      dispatch({
        type: actionTypes.CREATE_BET_SUCCESS,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.CREATE_BET_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.CREATE_BET_FAIL,
    });
  }
};

export const setResultsAction = (params) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.SET_RESULTS_REQUEST,
    });

    try {
      const response = await betService.setResults(params);
      dispatch({
        type: actionTypes.SET_RESULTS_SUCCESS,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.SET_RESULTS_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.SET_RESULTS_FAIL,
    });
  }
};

export const closeBetAction = (params) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.CLOSE_BET_REQUEST,
    });

    try {
      const response = await betService.closeBet(params);
      dispatch({
        type: actionTypes.CLOSE_BET_SUCCESS,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.CLOSE_BET_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.CLOSE_BET_FAIL,
    });
  }
};

export const disputeResultsAction = (params) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.DISPUTE_RESULTS_REQUEST,
    });

    try {
      const response = await betService.disputeResults(params);
      dispatch({
        type: actionTypes.DISPUTE_RESULTS_SUCCESS,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.DISPUTE_RESULTS_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.DISPUTE_RESULTS_FAIL,
    });
  }
};

export const deleteBetAction = (params) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.DELETE_BET_REQUEST,
    });

    try {
      const response = await betService.deleteBet(params);
      dispatch({
        type: actionTypes.DELETE_BET_SUCCESS,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.DELETE_BET_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.DELETE_BET_FAIL,
    });
  }
};

export const editBetAction = (params) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.EDIT_BET_REQUEST,
    });

    try {
      const response = await betService.editBet(params);
      dispatch({
        type: actionTypes.EDIT_BET_SUCCESS,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.EDIT_BET_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.EDIT_BET_FAIL,
    });
  }
};

export const setLeagueAction = (league) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_LEAGUE,
    payload: league,
  });
};

export const setIntervalsAction = (intervals) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_INTERVALS,
    payload: intervals,
  });
};

export const setGamesAction = (games) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_GAMES,
    payload: games,
  });
};

export const setBettingObjectAction = (ids) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_BET_OBJECT,
    payload: ids,
  });
};

export const setScheduleAction = (schedule) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_SCHEDULE,
    payload: schedule,
  });
};

export const setBetTypeAction = (betType) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_BET_TYPE,
    payload: betType,
  });
};

export const setBetTimeAction = (betTime) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_BET_TIME,
    payload: betTime,
  });
};

export const setBetOddsAction = (odds) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_BET_ODDS,
    payload: odds,
  });
};

export const setAmountAction = (amount) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_AMOUNT,
    payload: amount,
  });
};

export const setFilterAction = (payload) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_FILTER,
    payload,
  });
};

export const resetFilterAction = () => (dispatch) => {
  dispatch({
    type: actionTypes.RESET_FILTER,
    payload: {},
  });
};
