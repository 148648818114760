import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import avatarImage from 'assets/images/avatar.jpg';

const useStyles = makeStyles(() => ({
  root: ({ borderColor, size }) => ({
    borderRadius: '50%',
    border: `2px solid ${borderColor}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    width: size,
    height: size,
  }),
  avatar: {
    width: '100%',
    height: 'auto',
  },
}));

const Avatar = (props) => {
  const { src, size, borderColor } = props;
  const classes = useStyles({ size, borderColor });
  return (
    <Box className={classes.root}>
      <img src={src || avatarImage} className={classes.avatar} alt="Avatar" />
    </Box>
  );
};

Avatar.defaultProps = {
  src: '',
  size: '40px',
  borderColor: '#52d4e3',
};

Avatar.propTypes = {
  src: PropTypes.string,
  size: PropTypes.string,
  borderColor: PropTypes.string,
};

export default Avatar;
