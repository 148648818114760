import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import BetListCard from 'components/Bet/BetListCard';
import Loading from 'components/Loading/Box';
import SearchInput from 'components/Form/SearchInput';

import { useEsports, useBet, useSetLeagueDispatch } from 'hooks/redux/bet';
import { getLeagueInfo } from 'utils/betting';
import commonStyle from 'styles/common';

const useStyles = makeStyles(({ palette }) => ({
  title: {
    fontFamily: 'SofiaPro-Bold',
    color: palette.secondaryColor,
    fontSize: '30px',
    lineHeight: '30px',
    letterSpacing: -0.1,
    marginTop: '2px',
  },
}));

const Esports = ({ history }) => {
  const [search, setSearch] = useState('');
  const [searchedGames, setSearchedGames] = useState([]);

  const classes = useStyles();
  const commonClasses = commonStyle();
  const esports = useEsports();
  const betting = useBet();
  const setLeague = useSetLeagueDispatch();

  const handleSearch = (event) => {
    setSearch(event.target.value);
    const filteredStates = esports.filter((game) => game.name.includes(event.target.value));
    setSearchedGames(filteredStates);
  };

  const handleNext = useCallback((type, league) => {
    setLeague(league);
    history.push('/bets/time-period');
  }, [setLeague, history]);

  useEffect(() => {
    if (esports.length) {
      setSearchedGames(esports);
    }
  }, [esports]);

  return (
    <Page>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
      >
        <BackButton color="black" />
        <SearchInput
          handleChange={handleSearch}
          placeholder="Enter a game title..."
          value={search}
        />
      </Box>
      <Box
        textAlign="center"
        mt={3}
        mb={4}
      >
        <Typography variant="subtitle2" className={commonClasses.topic}>
          ESPORTS
        </Typography>
        <Typography variant="h1" className={classes.title}>
          Select a game title
        </Typography>
      </Box>
      {!betting.isLoading ? searchedGames.map((item) => (
        <BetListCard
          onClick={() => handleNext(item.type, item)}
          key={item.name}
          {...getLeagueInfo(item)}
        />
      )) : (<Loading />)}
    </Page>
  );
};

Esports.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(Esports);
