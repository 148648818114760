import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  Box, Fab, InputBase, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import SearchIcon from '@material-ui/icons/Search';

import Page from 'components/Page/Auth';
import VList from 'components/VList';
import BackButton from 'components/Buttons/BackButton';
import SoccerStadiumImage from 'assets/images/soccer-stadium.png';

import { useCountries } from 'hooks/redux/country';
import { setCountryAction } from 'store/actions/auth';
import { useOpenErrorModalDispatch } from 'hooks/redux/modal';

const useStyles = makeStyles({
  root: {
    margin: '20px',
    padding: '5px 13px',
    display: 'flex',
    alignItems: 'center',
    background: '#252451',
    borderRadius: '6px',
  },
  input: {
    '& input::placeholder': {
      opacity: '0.8 !important',
    },
  },
  searchIcon: {
    color: 'white',
    width: '18px',
    paddingRight: '13px',
  },
  countryList: {
    flex: 1,
  },
  countryItem: {
    fontSize: '17px',
    fontWeight: '600',
    lineHeight: '25px',
    color: 'white',
    textAlign: 'center',
    marginBottom: '30px',
  },
  countryActiveItem: {
    color: '#63C4C6',
  },
});

const Country = ({
  history,
  countryId,
  setUserCountry,
}) => {
  const [search, setSearch] = useState('');
  const [searchedCountries, setSearchedCountries] = useState([]);
  const openErrorModal = useOpenErrorModalDispatch();
  const classes = useStyles();

  const countries = useCountries();

  const handleNext = () => {
    if (countryId > -1) {
      if (countryId === 226) {
        history.push('/state');
      } else {
        history.push('/birthday');
      }
    } else {
      openErrorModal({ title: 'Oops', subtitle: 'Please select your country!' });
    }
  };

  const handleSetCountry = (country) => {
    setUserCountry(country.id);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
    const filteredCountries = countries.filter((country) => country.name.toLowerCase().includes(
      event.target.value.toLowerCase(),
    ));
    setSearchedCountries(filteredCountries);
  };

  useEffect(() => {
    if (countries.length) {
      setSearchedCountries(countries);
    }
    // eslint-disable-next-line
  }, [countries.length]);

  return (
    <Page backgroundImage={SoccerStadiumImage}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <BackButton />
        <Fab variant="extended" onClick={handleNext}>Next</Fab>
      </Box>
      <Box mt={3}>
        <Typography variant="subtitle1" className="text-center color-white">
          First, we need to know where you live. Simply search for your country of
          residence and select it below:
        </Typography>
      </Box>
      <Box className={classes.root}>
        <SearchIcon className={classes.searchIcon} />
        <InputBase
          autoComplete="off"
          className={classes.input}
          placeholder="Search Country of Residence"
          inputProps={{ 'aria-label': 'Search Country of Residence' }}
          fullWidth
          value={search}
          onChange={handleSearch}
        />
      </Box>
      <Box className={classes.countryList} mt={3}>
        <VList
          rowCount={searchedCountries.length}
          rowRender={(index) => (
            <>
              <Box
                className={`${classes.countryItem} ${(countryId === searchedCountries[index].id) && classes.countryActiveItem}`}
                onClick={() => handleSetCountry(searchedCountries[index])}
              >
                {searchedCountries[index].name}
              </Box>
            </>
          )}
        />
      </Box>
    </Page>
  );
};

Country.defaultProps = {
  countryId: 0,
};

Country.propTypes = {
  history: PropTypes.object.isRequired,
  countryId: PropTypes.number,
  setUserCountry: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  countryId: state.auth.user.country_id,
});

const mapDispatchToProps = (dispatch) => ({
  setUserCountry: (country) => dispatch(setCountryAction(country)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Country));
