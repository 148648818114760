import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { Box, InputBase, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import BackButton from 'components/Buttons/BackButton';
import SelectButton from 'components/Buttons/SelectButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import Currency from 'components/Currency/Currency';
import { useUserRestrictedAccess } from 'hooks/redux/user';
import { setFilterAction, resetFilterAction } from 'store/actions/bet';
import {
  supportedAllCurrencies,
  betParlayAllTypes,
  betAllIntervals,
  betAllTypes,
  betAllObjectTypes,
  betAllExpiresTypes,
  betAllAcceptableTypes,
  betAllAcceptedTypes,
  betAllRoleTypes,
  betAllWinLossTypes,
} from 'utils/constants';
import commonStyle from 'styles/common';

const useStyles = makeStyles(({ palette }) => ({
  reset: {
    color: palette.secondaryColor,
    font: '18px SofiaPro-SemiBold',
    letterSpacing: -0.3,
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
    letterSpacing: -0.1,
  },
  topic: {
    font: '18px SofiaPro-SemiBold',
    color: palette.secondaryColor,
    letterSpacing: -0.3,
  },
  selectedButton: {
    '&:not(:last-child)': {
      marginRight: '10px',
    },
  },
  amount: {
    borderBottom: `1px solid ${palette.secondaryColor}`,
  },
  minMax: {
    font: '16px SofiaPro-SemiBold',
    color: palette.secondaryColor,
    letterSpacing: -0.1,
    '& input': {
      textAlign: 'center',
    },
  },
}));

const totalBetSpecsFilterLength = betAllIntervals.length + betAllObjectTypes.length + betAllTypes.length;

const BetFilter = ({
  history,
  filter,
  resetFilter,
  setFilter,
  mode,
}) => {
  const classes = useStyles();
  const commonClasses = commonStyle();
  const {
    betParlayTypes, intervals, currencies, objectTypes, amount, betTypes, isExpired, acceptable, allAcceptable, roleTypes, winLossTypes,
  } = filter;
  const [selectedFilter, setSelectedFilter] = useState({
    betParlayTypes: [],
    intervals: [],
    currencies: [],
    objectTypes: [],
    betTypes: [],
    amount: {
      min: '',
      max: '',
    },
    isExpired: false,
    acceptable: 'acceptable',
    allAcceptable: [],
    roleTypes: [],
    winLossTypes: [],
  });

  const access = useUserRestrictedAccess();

  const isSelected = (parent, value) => {
    if (Array.isArray(parent)) {
      return parent.indexOf(value) >= 0;
    }
    return parent === value;
  };

  const handleSelect = useCallback((key, value) => {
    const betSpecsLength = selectedFilter.intervals.length + selectedFilter.objectTypes.length + selectedFilter.betTypes.length;
    let futureSelectedFilter = selectedFilter;
    if (
      mode === 'result'
      && (['intervals', 'objectTypes', 'betTypes'].indexOf(key) >= 0)
      && (betSpecsLength === totalBetSpecsFilterLength)
    ) {
      futureSelectedFilter = {
        ...futureSelectedFilter,
        betParlayTypes: ['bet'],
      };
    }
    if (key === 'betParlayTypes' && value === 'parlay' && betSpecsLength !== totalBetSpecsFilterLength) {
      return;
    }
    if (Array.isArray(selectedFilter[key])) {
      const items = selectedFilter[key];
      const index = items.indexOf(value);
      if (index < 0) { // add item
        items.push(value);
      } else if (items.length >= 2) { // remove item
        items.splice(index, 1);
      }
      setSelectedFilter({
        ...futureSelectedFilter,
        [key]: items,
      });
    } else {
      setSelectedFilter({
        ...futureSelectedFilter,
        [key]: value,
      });
    }
  }, [selectedFilter, setSelectedFilter, mode]);

  const handleMinMaxAmount = (event, type) => {
    if (type === 'min') {
      setSelectedFilter({
        ...selectedFilter,
        amount: {
          min: event.target.value,
          max: selectedFilter.amount.max,
        },
      });
    }
    if (type === 'max') {
      setSelectedFilter({
        ...selectedFilter,
        amount: {
          min: selectedFilter.amount.min,
          max: event.target.value,
        },
      });
    }
  };

  const handleReset = () => {
    resetFilter();
  };

  const handleApply = () => {
    setFilter(selectedFilter);
    if (mode === 'view') {
      history.push('/bets/all');
    } else if (mode === 'favorite') {
      history.push('/bets/favorites');
    } else {
      history.push('/bets/results');
    }
  };

  useEffect(() => {
    setSelectedFilter({
      betParlayTypes,
      intervals,
      currencies,
      objectTypes,
      betTypes,
      amount,
      isExpired,
      acceptable,
      allAcceptable,
      roleTypes,
      winLossTypes,
    });
  }, [
    betParlayTypes,
    intervals,
    currencies,
    objectTypes,
    amount,
    betTypes,
    isExpired,
    acceptable,
    allAcceptable,
    roleTypes,
    winLossTypes,
  ]);

  return (
    <Box className={commonClasses.root}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <BackButton color={commonClasses.backBtn} />
        <Box onClick={() => handleReset()}>
          <Typography className={classes.reset}>Reset</Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" mb={4} mt={3}>
        <Typography variant="h3" className={classes.title}>
          View Bets Filter
        </Typography>
      </Box>
      {access.isCryptoVisible && (
        <>
          <Typography className={classes.topic}>Currency</Typography>
          <Box display="flex" flexWrap="wrap" justifyContent="space-between" alignItems="center" mb={3}>
            {supportedAllCurrencies.map((item) => (
              <Currency
                key={item.symbol}
                {...item}
                selected={isSelected(selectedFilter.currencies, item.symbol)}
                onClick={() => handleSelect('currencies', item.symbol)}
              />
            ))}
          </Box>
        </>
      )}
      {selectedFilter.currencies.length === 1 && (
        <>
          <Typography className={classes.topic}>Minimum/Maximum Bet Amount</Typography>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            className={classes.amount}
            mt={2}
            mb={4}
          >
            <InputBase
              autoComplete="off"
              className={classes.minMax}
              value={selectedFilter.amount.min}
              placeholder="Min"
              onChange={(e) => handleMinMaxAmount(e, 'min')}
              inputProps={{ 'aria-label': 'Min' }}
              fullWidth
            />
            <Typography className={classes.minMax}>to</Typography>
            <InputBase
              autoComplete="off"
              className={classes.minMax}
              value={selectedFilter.amount.max}
              onChange={(e) => handleMinMaxAmount(e, 'max')}
              placeholder="Max"
              inputProps={{ 'aria-label': 'Max' }}
              fullWidth
            />
          </Box>
        </>
      )}
      {mode === 'result' && (
        <>
          <Typography className={classes.topic}>Parlays/Individual Bets</Typography>
          <Box display="flex" justifyContent="flex-start" mt={1} mb={4}>
            {betParlayAllTypes.map((item) => (
              <SelectButton
                key={item.type}
                className={classes.selectedButton}
                selected={isSelected(selectedFilter.betParlayTypes, item.type)}
                onClick={() => handleSelect('betParlayTypes', item.type)}
              >
                {item.name}
              </SelectButton>
            ))}
          </Box>
        </>
      )}
      {/* {(mode !== 'result' || selectedFilter.betParlayTypes.includes('bet')) && ( */}
      {/*  <> */}
      <Typography className={classes.topic}>Time Period</Typography>
      <Box display="flex" justifyContent="flex-start" mt={1} mb={4}>
        {betAllIntervals.map((item) => (
          <SelectButton
            key={item.type}
            className={classes.selectedButton}
            selected={isSelected(selectedFilter.intervals, item.type)}
            onClick={() => handleSelect('intervals', item.type)}
          >
            {item.name}
          </SelectButton>
        ))}
      </Box>
      <Typography className={classes.topic}>Bet Type</Typography>
      <Box display="flex" justifyContent="flex-start" flexWrap="wrap" mt={1} mb={4}>
        {betAllTypes.map((item) => (
          <SelectButton
            key={item.type}
            className={classes.selectedButton}
            selected={isSelected(selectedFilter.betTypes, item.type)}
            onClick={() => handleSelect('betTypes', item.type)}
          >
            {item.name}
          </SelectButton>
        ))}
      </Box>
      <Typography className={classes.topic}>Team/Player</Typography>
      <Box display="flex" justifyContent="flex-start" flexWrap="wrap" mt={1} mb={4}>
        {betAllObjectTypes.map((item) => (
          <SelectButton
            key={item.type}
            className={classes.selectedButton}
            selected={isSelected(selectedFilter.objectTypes, item.type)}
            onClick={() => handleSelect('objectTypes', item.type)}
          >
            {item.name}
          </SelectButton>
        ))}
      </Box>
      {/*  </> */}
      {/* )} */}
      <Typography className={classes.topic}>Current/History</Typography>
      <Box display="flex" justifyContent="flex-start" flexWrap="wrap" mt={1} mb={4}>
        {betAllExpiresTypes.map((item) => (
          <SelectButton
            key={item.name}
            className={classes.selectedButton}
            selected={isSelected(selectedFilter.isExpired, item.value)}
            onClick={() => handleSelect('isExpired', item.value)}
          >
            {item.name}
          </SelectButton>
        ))}
      </Box>
      {
        mode === 'result' && (
          <>
            <Typography className={classes.topic}>Role Type</Typography>
            <Box display="flex" justifyContent="flex-start" flexWrap="wrap" mt={1} mb={4}>
              {betAllRoleTypes.map((item) => (
                <SelectButton
                  key={item.value}
                  className={classes.selectedButton}
                  selected={isSelected(selectedFilter.roleTypes, item.value)}
                  onClick={() => handleSelect('roleTypes', item.value)}
                >
                  {item.name}
                </SelectButton>
              ))}
            </Box>
          </>
        )
      }
      {mode === 'result' ? (
        <>
          <Typography className={classes.topic}>Accepted/Not Accepted</Typography>
          <Box display="flex" justifyContent="flex-start" flexWrap="wrap" mt={1} mb={5}>
            {betAllAcceptedTypes.map((item) => (
              <SelectButton
                key={item.name}
                className={classes.selectedButton}
                selected={isSelected(selectedFilter.allAcceptable, item.value)}
                onClick={() => handleSelect('allAcceptable', item.value)}
              >
                {item.name}
              </SelectButton>
            ))}
          </Box>
        </>
      ) : (
        <>
          <Typography className={classes.topic}>Acceptable/Not Acceptable</Typography>
          <Box display="flex" justifyContent="flex-start" flexWrap="wrap" mt={1} mb={5}>
            {betAllAcceptableTypes.map((item) => (
              <SelectButton
                key={item.name}
                className={classes.selectedButton}
                selected={isSelected(selectedFilter.acceptable, item.value)}
                onClick={() => handleSelect('acceptable', item.value)}
              >
                {item.name}
              </SelectButton>
            ))}
          </Box>
        </>
      )}
      {
        // mode === 'result' && selectedFilter.isExpired && selectedFilter.betParlayTypes.includes('bet') && (
        mode === 'result' && selectedFilter.isExpired && (
          <>
            <Typography className={classes.topic}>Won/Lost/Tie/Postponed</Typography>
            <Box display="flex" justifyContent="flex-start" flexWrap="wrap" mt={1} mb={4}>
              {betAllWinLossTypes.map((item) => (
                <SelectButton
                  key={item.value}
                  className={classes.selectedButton}
                  selected={isSelected(selectedFilter.winLossTypes, item.value)}
                  onClick={() => handleSelect('winLossTypes', item.value)}
                >
                  {item.name}
                </SelectButton>
              ))}
            </Box>
          </>
        )
      }
      <Box>
        <OutlineButton onClick={() => handleApply()}>
          Apply
        </OutlineButton>
      </Box>
    </Box>
  );
};

BetFilter.defaultProps = {
  mode: 'view',
};

BetFilter.propTypes = {
  history: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  resetFilter: PropTypes.func.isRequired,
  mode: PropTypes.string,
};

const mapStateToProps = (state) => ({
  filter: state.bet.selected,
});

const mapDispatchToProps = (dispatch) => ({
  setFilter: (payload) => dispatch(setFilterAction(payload)),
  resetFilter: () => dispatch(resetFilterAction()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BetFilter));
