import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

import ExcludeButton from 'components/Buttons/ExcludeButton';
import IncludeButton from 'components/Buttons/IncludeButton';

import { betMappedAcceptTypes } from 'utils/constants';

const useStyles = makeStyles(({ palette }) => ({
  card: {
    background: palette.cardColor,
    padding: '10px 10px 0',
    borderRadius: '5px 5px 0 0',
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 4px',
    position: 'relative',
    overflow: 'hidden',
  },
  info: ({ showAcceptType }) => ({
    padding: '5px 0',
    marginTop: showAcceptType ? '10px' : 0,
  }),
  excludeBtn: {
    color: palette.secondaryColor,
  },
  accept: ({ acceptColor }) => ({
    backgroundColor: `${acceptColor}`,
    color: '#FFF',
    justifyContent: 'center',
    font: '12px SofiaPro-Medium',
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    padding: '4px 0',
  }),
  imageGroup: {
    width: '60px',
    position: 'relative',
    marginTop: '5px',
    display: 'flex',
    flexDirection: 'column',
  },
  image: {
    height: '45px',
    width: '45px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    '& img': {
      width: '100%',
      height: 'auto',
    },
  },
  title: {
    color: palette.secondaryColor,
    font: '18px SofiaPro-SemiBold',
    letterSpacing: -0.3,
    margin: '5px 0px',
  },
  type: {
    color: '#A4A4A4',
    font: '12px SofiaPro',
    letterSpacing: -0.1,
    marginTop: '8px',
  },
  note: {
    color: palette.secondaryColor,
    font: '12px SofiaPro-Bold',
  },
  betId: {
    color: '#A4A4A4',
    font: '11px SofiaPro',
    letterSpacing: -0.1,
  },
  description: {
    color: '#FC5A5A',
    font: '13px SofiaPro',
    letterSpacing: -0.1,
  },
  acceptTime: {
    color: '#A4A4A4',
    font: '13px SofiaPro',
  },
  oddsContainer: {
    borderTop: '1px solid #F1F1F5',
    padding: '10px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  oddsTopic: {
    backgroundColor: '#FC5A5A',
    color: '#FFF',
    padding: '4px 10px 3px 10px',
    font: '13px SofiaPro-Medium',
    borderRadius: '20px',
  },
  odds: {
    color: '#20CFD4',
    font: '16px SofiaPro-Medium',
    letterSpacing: -0.25,
  },
  originalBetTopic: {
    backgroundColor: '#6236FF', // palette.maxBetBackground,
    color: '#FFF',
    padding: '4px 10px 3px 10px',
    font: '13px SofiaPro-Medium',
    borderRadius: '20px',
  },
  originalBet: ({ currencyColor }) => ({
    color: currencyColor,
    font: '16px SofiaPro-Medium',
    letterSpacing: -0.2,
    flex: 1,
    marginLeft: '10px',
    textAlign: 'right',
  }),
  excludeBox: {
    textAlign: 'center',
    width: 54,
  },
  excludedTopic: {
    backgroundColor: '#FC5A5A55',
    color: '#FC5A5A',
    padding: '3px 4px 2px 4px',
    font: '11px SofiaPro-Medium',
    marginTop: '2px',
  },
}));

const BetResultInfoCard = ({
  excluded,
  betInfo,
  showAcceptType,
  showExcluded,
  showExcludeBtn,
  showOdds,
  showMaxBet,
  showNotice,
  showAcceptTime,
  onClick,
  onExcludeParlay,
}) => {
  const {
    id,
    title,
    type,
    imageUrl,
    imageTwoUrl,
    description,
    time,
    formattedOddsAmount,
    formattedMaxOriginalBetAmount,
    maxOriginalBetLabel,
    currencyColor,
    acceptType,
    isWriteIn,
    acceptTime,
  } = betInfo;

  const classes = useStyles({
    showAcceptType,
    currencyColor,
    acceptColor: acceptType ? betMappedAcceptTypes[acceptType].color : '#FFF',
  });

  return (
    <Box className={`${classes.card}`}>
      {showAcceptType && betMappedAcceptTypes[acceptType] && (
        <Box display="flex" className={classes.accept}>{betMappedAcceptTypes[acceptType].label}</Box>
      )}
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        className={classes.info}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          pb={1}
          onClick={onClick}
        >
          <Box className={classes.imageGroup}>
            <Box className={classes.image}>
              {imageUrl && (<img src={imageUrl} alt="One" />)}
            </Box>
            {imageTwoUrl && (
              <Box className={classes.image}>
                <img src={imageTwoUrl} alt="Two" />
              </Box>
            )}
          </Box>
          <Box flex={1}>
            <Typography variant="subtitle1" className={classes.title}>
              {title}
            </Typography>
            <Typography variant="subtitle1" className={classes.type}>
              {type}
              {isWriteIn && showNotice && (
                <span className={classes.note}> Note: You are betting that this WON&lsquo;T happen.</span>
              )}
            </Typography>
            <Box mt={1}>
              <Typography variant="subtitle1" className={classes.description}>
                {description}
              </Typography>
              <Typography variant="subtitle1" className={classes.description}>
                {time}
              </Typography>
            </Box>
            {showAcceptTime && acceptTime && (
              <Box mt={1}>
                <Typography variant="subtitle1" className={classes.acceptTime}>
                  {`Accepted Time: ${acceptTime}`}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        <Box mt={1} display="flex" alignItems="center" flexDirection="column">
          <Box className={classes.excludeBox}>
            {showExcludeBtn && (
              <>
                {excluded ? (
                  <IncludeButton color={classes.excludeBtn} onClick={() => onExcludeParlay(false)} />
                ) : (
                  <ExcludeButton color={classes.excludeBtn} onClick={() => onExcludeParlay(true)} />
                )}
              </>
            )}
            {showExcluded && excluded && (
              <Box className={classes.excludedTopic}>Excluded</Box>
            )}
          </Box>
          <Box mt={1} onClick={onClick}>
            <Typography variant="subtitle1" className={classes.betId}>
              Bet ID:
            </Typography>
            <Typography variant="subtitle1" className={classes.betId}>
              {id}
            </Typography>
          </Box>
        </Box>
      </Box>
      {showOdds && (
        <Box className={classes.oddsContainer} onClick={onClick}>
          <Typography variant="subtitle1" className={classes.oddsTopic}>
            Odds
          </Typography>
          <Typography variant="subtitle1" className={classes.odds}>
            {formattedOddsAmount}
          </Typography>
        </Box>
      )}
      {showMaxBet && (
        <Box className={classes.oddsContainer} onClick={onClick}>
          <Typography variant="subtitle1" className={classes.originalBetTopic}>
            {maxOriginalBetLabel}
          </Typography>
          <Typography variant="subtitle1" className={classes.originalBet}>
            {formattedMaxOriginalBetAmount}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

BetResultInfoCard.defaultProps = {
  excluded: false,
  betInfo: {},
  showExcluded: false,
  showAcceptType: false,
  showExcludeBtn: false,
  showOdds: true,
  showMaxBet: true,
  showNotice: false,
  showAcceptTime: false,
  onClick: () => {},
  onExcludeParlay: () => {},
};

BetResultInfoCard.propTypes = {
  excluded: PropTypes.bool,
  betInfo: PropTypes.object,
  showExcluded: PropTypes.bool,
  showAcceptType: PropTypes.bool,
  showOdds: PropTypes.bool,
  showMaxBet: PropTypes.bool,
  showExcludeBtn: PropTypes.bool,
  showNotice: PropTypes.bool,
  showAcceptTime: PropTypes.bool,
  onClick: PropTypes.func,
  onExcludeParlay: PropTypes.func,
};

export default BetResultInfoCard;
