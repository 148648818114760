import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Box, Typography } from '@material-ui/core';
import Page from 'components/Page/Dashboard';
import CloseIcon from '@material-ui/icons/Close';

import { useCardDepositInfo } from 'hooks/redux/account';
import { useCreateCreditCardDeposit } from 'hooks/redux/funds';
import config from 'utils/config';
import commonStyle from 'styles/common';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    flexDirection: 'column',
    flex: 1,
  },
  title: {
    marginTop: 0,
    marginBottom: '30px',
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
    textAlign: 'center',
  },
  widget: {
    height: '100%',
    textAlign: 'center',
    '& h3': {
      textAlign: 'center',
      color: palette.secondaryColor,
    },
    '& p': {
      color: '#aeaeae',
    },
    '& iframe': {
      border: 'none !important',
    },
  },
}));

const AccountCreation = ({ history }) => {
  const classes = useStyles();
  const commonClasses = commonStyle();
  const depositInfo = useCardDepositInfo();
  const createDeposit = useCreateCreditCardDeposit();

  /* eslint-disable */
  useEffect(() => {
    if (depositInfo.id && depositInfo.amount) {
      const paymentWidget = document.getElementById('payment_widget');
      const bootstrap = document.createElement('script');
      const targetElement = document.getElementById('payment_widget');
      targetElement.innerHTML = '';
      bootstrap.id = config.primeTrustPurchaseId;
      bootstrap.src = config.primeTrustPurchase;

      window.createDeposit = createDeposit;
      const paymentScript = document.createElement('script');
      paymentScript.type = 'text/javascript';
      paymentScript.innerHTML = `
         console.log('[primetrust] 1. Loading Prime Trust Embedding API');
         window.primeTrustReady = (pt) => {
           console.log('[primetrust] 2. Loading Purchase Protection...');
           pt.start3DS({
             amount: ${depositInfo.amount},
             fundsTransferMethodId: '${depositInfo.id}',
             challengeContainer: document.getElementById('payment_widget'),
           })
           .then(async (challengeId) => {
              const data = {
                 amount: ${depositInfo.amount},
                 challenge_id: challengeId,
                 card_id: '${depositInfo.id}',
                 user_agent: navigator.userAgent,
                 forter_token: document.cookie.split('; ').find(row => row.startsWith('forterToken')).split('=')[1],
              };
              console.log(data);
              await createDeposit(data);
              
              const element = document.getElementById("payment_widget");

              const newImg = document.createElement("img");
              newImg.src="https://res.cloudinary.com/production/image/upload/web/assets/success.png"

              const newDiv = document.createElement("h3");
              const newContent = document.createTextNode("Approved!");
              newDiv.appendChild(newContent);

              const newPara = document.createElement("p");
              const newParaText = document.createTextNode("Your card was successfully charged, and your ZenSports account has been credited for this deposit amount.");
              newPara.appendChild(newParaText);

              const newPara2 = document.createElement("p");
              const newParaText2 = document.createTextNode("You are not being redirected to the Funds screen.");
              newPara2.appendChild(newParaText2);

              element.innerHTML = '';
              element.appendChild(newImg);
              element.appendChild(newDiv);
              element.appendChild(newPara);
              element.appendChild(newPara2);

              setTimeout(() => {
                window.location.href = '/funds';
              }, 3000);
           })
           .catch((err) => console.log('Err: ', err));
         };
      `;

      paymentWidget.appendChild(bootstrap);
      paymentWidget.appendChild(paymentScript);

      return () => {
        paymentWidget.removeChild(bootstrap);
        paymentWidget.removeChild(paymentScript);
        window.pt = null;
      };
    }
  }, [depositInfo.id]);
  /* eslint-enable */

  return (
    <Page>
      <Box className={classes.root} display="flex" flexDirection="column" flex={1}>
        <Box display="flex" flexDirection="row-reverse">
          <CloseIcon className={commonClasses.backBtn} onClick={() => history.goBack()} />
        </Box>
        <Box mt={5} flex={1}>
          <Typography component="h2" className={classes.title}>Deposit Verification</Typography>
          <div id="payment_widget" className={classes.widget} />
        </Box>
      </Box>
    </Page>
  );
};

AccountCreation.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(AccountCreation);
