import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { formatCurrency } from 'utils/currency';
import { useMappedCurrencies } from 'hooks/redux/currency';

const useStyles = makeStyles(({ palette }) => ({
  card: {
    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.15)',
    padding: '10px 10px 10px 20px',
    background: palette.cardColor,
    borderRadius: '5px',
  },
  marketCurrencies: {
    textAlign: 'left',
    color: palette.secondaryColor,
    marginBottom: '8px',
    font: '14px SofiaPro-SemiBold',
  },
  lastPrice: {
    textAlign: 'center',
    marginBottom: '8px',
    font: '13px SofiaPro-SemiBold',
    color: '#7F7F7F',
  },
  lastPricePositive: {
    textAlign: 'center',
    marginBottom: '8px',
    font: '13px SofiaPro-SemiBold',
    color: '#54A400',
  },
  lastPriceNegative: {
    textAlign: 'center',
    marginBottom: '8px',
    font: '13px SofiaPro-SemiBold',
    color: '#FC5A5A',
  },
  changePercentage: {
    textAlign: 'center',
    width: '50px',
    font: '13px SofiaPro-SemiBold',
    padding: 4,
    borderRadius: '4px',
  },
  volume: {
    textAlign: 'left',
    font: '11px SofiaPro-Regular',
    color: '#AEAEAE',
  },
  usdPrice: {
    textAlign: 'center',
    font: '11px SofiaPro-Regular',
    color: '#AEAEAE',
  },
  percentageChange: {
    color: '#14133D',
    background: '#e6e6e6',
    textAlign: 'center',
    font: '13px SofiaPro-SemiBold',
    padding: 4,
    borderRadius: '4px',
    display: 'initial',
    float: 'right',
  },
  positiveChange: {
    color: '#54A400',
    background: '#ccffef',
    textAlign: 'center',
    width: '50px',
    font: '13px SofiaPro-SemiBold',
    padding: 4,
    borderRadius: '4px',
  },
  negativeChange: {
    color: '#FC5A5A',
    background: '#ffd6cc',
    textAlign: 'center',
    width: '50px',
    font: '13px SofiaPro-SemiBold',
    padding: 4,
    borderRadius: '4px',
  },
}));

const Market = ({ item, onClick }) => {
  const classes = useStyles();
  const mappedCurrencies = useMappedCurrencies();

  return (
    <>
      <Box
        mt={2}
        className={classes.card}
        display="flex"
        flexDirection="column"
        position="relative"
        onClick={() => onClick(item)}
      >
        <Box mt={1} mb={1} display="flex" width="100%" justifyContent="space-between" alignItems="center">
          <Box display="flex" flex={1} justifyContent="space-between">
            <Box>
              <Typography className={classes.marketCurrencies}>
                {`${item?.buy_currency?.symbol?.toUpperCase()}/${item?.sell_currency?.symbol?.toUpperCase()}`}
              </Typography>
              {mappedCurrencies[item.buy_currency?.symbol] && (
                <Typography className={classes.volume}>
                  {`Vol. ${formatCurrency(
                    item.volume,
                    mappedCurrencies[item?.buy_currency?.symbol],
                    false,
                    false,
                  )}`}
                </Typography>
              )}
            </Box>
            {mappedCurrencies[item.sell_currency?.symbol] && (
              <Box>
                {
                  item.last_direction === 0
                    ? (
                      <Typography width="33%" className={classes.lastPrice}>
                        {`${formatCurrency(
                          item.last_price,
                          mappedCurrencies[item?.sell_currency.symbol],
                          false,
                          true,
                          item.buy_currency.symbol,
                        )}`}
                      </Typography>
                    )
                    : item.last_direction > 0
                      ? (
                        <Typography width="33%" className={classes.lastPricePositive}>
                          {`${formatCurrency(
                            item.last_price,
                            mappedCurrencies[item.sell_currency.symbol],
                            false,
                            true,
                            item.buy_currency.symbol,
                          )}`}
                        </Typography>
                      )
                      : (
                        <Typography width="33%" className={classes.lastPriceNegative}>
                          {`${formatCurrency(
                            item.last_price,
                            mappedCurrencies[item.sell_currency.symbol],
                            false,
                            true,
                            item.buy_currency.symbol,
                          )}`}
                        </Typography>
                      )
                }
                <Typography className={classes.usdPrice}>
                  {`${formatCurrency(
                    item.last_usd_price,
                    mappedCurrencies.usd,
                  )}`}
                </Typography>
              </Box>
            )}
          </Box>
          <Box width="35%">
            {
              item.change >= 0 && (
                <Typography className={classes.percentageChange}>
                  {`${item.change.toFixed(2)}%`}
                </Typography>
              )
            }
            {
              item.change < 0 && (
                <Typography className={classes.negativeChange}>
                  {`${item.change.toFixed(2)}%`}
                </Typography>
              )
            }
          </Box>
        </Box>
      </Box>
    </>
  );
};

Market.defaultProps = {
  item: {},
  onClick: () => {
  },
};

Market.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func,
};

export default Market;
