import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import { Box, Typography } from '@material-ui/core';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import CurrencyDropdown from 'components/Currency/CurrencyDropdown';
import CryptoWithdrawCard from 'components/Withdraw/CryptoWithdrawCard';
import UsdWithdrawCard from 'components/Withdraw/UsdWithdrawCard';
import WithdrawFundsDialog from 'components/Dialog/WithdrawFundsDialog';

import { useMappedCurrencies, useSelectCurrencyDispatch } from 'hooks/redux/currency';
import { useAccountOverviewInfo } from 'hooks/redux/account';
import { useKycVerified } from 'hooks/redux/kyc';
import { useAuthUser, useUserRestrictedAccess } from 'hooks/redux/user';
import { useOpenSuccessModalDispatch } from 'hooks/redux/modal';
import { isStandalone } from 'utils';
import CommonStyle from 'styles/common';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 15px',
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
    marginBottom: '10px',
  },
  wrapper: {
    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.15)',
    background: palette.cardColor,
    padding: '15px',
  },
  subtitle: {
    textAlign: 'center',
    color: '#AEAEAE',
    font: '15px SofiaPro',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    fontSize: '13px',
    color: palette.secondaryColor,
    textAlign: 'center',
    marginBottom: '20px',
  },
  question: {
    font: '13px SofiaPro-Bold',
    color: palette.secondaryColor,
    textAlign: 'center',
    padding: '25px 35px',
  },
  icon: {
    color: '#00D3DA',
    width: 20,
    height: 20,
    marginLeft: 5,
  },
  link: {
    width: '100%',
    display: 'inline-block',
    background: palette.cardColor,
    borderRadius: '5px',
    padding: '18px 0px',
    textAlign: 'center',
    textDecoration: 'none',
    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.15)',
    color: palette.fvrtBtnColor,
    border: '1px solid #29CDD4',
  },
  pending_button: {
    padding: 0,
    width: '100%',
    background: '#AEAEAE',
    marginBottom: '10px',
    marginTop: '10px',
    minHeight: 50,
    lineHeight: '50px',
    font: '17px SofiaPro-SemiBold',
    letterSpacing: '0.3px',
    color: '#FFFFFF',
    textAlign: 'center',
    borderRadius: '5px',
    alignContent: 'center',
  },
  page_link: {
    textAlign: 'center',
    marginTop: '10px',
    font: '13px SofiaPro-Bold',
    color: '#29CFD4',
    textDecoration: 'underline',
  },
}));

const Withdraw = ({ match, history }) => {
  const currencySymbol = match.params.currency;
  const classes = useStyles();
  const commonClasses = CommonStyle();

  const overview = useAccountOverviewInfo();
  const kycVerified = useKycVerified();
  const selectCurrency = useSelectCurrencyDispatch();
  const mappedCurrencies = useMappedCurrencies();
  const user = useAuthUser();
  const access = useUserRestrictedAccess();
  const openSuccessModal = useOpenSuccessModalDispatch();

  const messageOne = `ZenSports requires all customers to go through KYC (Know Your Customer) and
  AML (Anti Money Laundering) verification before they can withdraw funds.
  This is to ensure that you are who you say you are, that you're not a criminal who is laundering funds through ZenSports, etc.
  The verification process is quick and easy.`;
  const messageTwo = 'Tap the "Get Verified" button to get started.';
  const handleKyc = (showModal = true) => {
    if (kycVerified) {
      return true;
    }
    if (overview.status === 'new') {
      if (showModal) {
        openSuccessModal({
          title: 'KYC/AML Verification Needed',
          subtitle: `In order to make a fiat deposit via debit card or wire transfer, you must first 
        go through the KYC/AML verification process.`,
          buttonText: 'Got It',
          nextPath: '/kyc/profile',
        });
      } else {
        history.push('/kyc/profile');
      }
    } else if (overview.status === 'pending') {
      history.push('/kyc/status');
    }
    return false;
  };

  useEffect(() => {
    if (!access.isCryptoVisible) {
      selectCurrency('usd');
    } else if (currencySymbol) {
      selectCurrency(currencySymbol);
    }
    // eslint-disable-next-line
  }, [currencySymbol, access.isCryptoVisible]);

  return (
    <Page>
      <Box className={classes.root}>
        <Box>
          <BackButton color={commonClasses.backBtn} page="/funds" />
        </Box>
        <Box textAlign="center" mb={4} mt={3}>
          <Typography component="h3" className={classes.title}>
            Withdraw Funds
          </Typography>
          <Typography component="h3" className={classes.subtitle}>
            How would you like to withdraw funds?
            <WithdrawFundsDialog />
          </Typography>
        </Box>
        <Box>
          <CurrencyDropdown showExchangeRate={kycVerified} changeable={access.isCryptoVisible} />
          {
            (access.isCryptoVisible && kycVerified && currencySymbol !== 'usd' && mappedCurrencies[currencySymbol]) && (
              <Box mb={15}>
                <CryptoWithdrawCard currency={mappedCurrencies[currencySymbol]} />
              </Box>
            )
          }
          {
            (currencySymbol === 'usd' && mappedCurrencies.usdt) && (
              <UsdWithdrawCard usdtCurrency={mappedCurrencies.usdt} handleKyc={handleKyc} account={overview} kycVerified={kycVerified} />
            )
          }
          {(access.isCryptoVisible && !kycVerified) && (
            <Box className={classes.wrapper} mt={2} mb={2}>
              {(!user.primetrust_kyc_verified && user.primetrust_account_id !== null && user.primetrust_account_id.length > 0)
                ? (
                  <Box>
                    <Typography className={classes.content}>
                      {messageOne}
                    </Typography>
                    <Typography className={classes.pending_button}>
                      { 'KYC Verification ' }
                      { overview.primetrust_kyc_failed ? 'Failed' : 'Pending' }
                    </Typography>
                    <Typography className={classes.page_link} onClick={() => handleKyc(false)}>
                      Learn more about Pending statuses
                    </Typography>
                  </Box>
                )
                : (
                  <Box>
                    <Typography className={classes.content}>
                      {`${messageOne} ${messageTwo}`}
                    </Typography>
                    <OutlineButton onClick={() => handleKyc(false)}>
                      Get Verified
                    </OutlineButton>
                  </Box>
                )}
              <Typography className={classes.question}>
                Having troubles getting KYC/AML verified? Tap below to go through manual verification via the Yoti app.
              </Typography>
              <a
                href="https://support.zensports.com/en/articles/3136458-yoti-kyc-aml-verification-option"
                className={classes.link}
                target={isStandalone ? '_blank' : '_self'}
              >
                Manually Verify via Yoti
              </a>
            </Box>
          )}
        </Box>
      </Box>
    </Page>
  );
};

Withdraw.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(Withdraw);
