import React, { useState } from 'react';
import { Box, Typography /* , InputBase */ } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  useSetPlayFilterDispatch,
  usePlaySelectedQuery,
} from 'hooks/redux/play';
import OutlineButton from 'components/Buttons/OutlineButton';
import BackButton from 'components/Buttons/BackButton';
import SelectButton from 'components/Buttons/SelectButton';
import commonStyle from 'styles/common';

const useStyles = makeStyles(({ palette }) => ({
  reset: {
    color: '#101451',
    font: '18px SofiaPro-SemiBold',
    letterSpacing: -0.3,
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
    letterSpacing: -0.1,
  },
  topic: {
    font: '18px SofiaPro-SemiBold',
    color: palette.secondaryColor,
    letterSpacing: -0.3,
  },
  selectedButton: {
    '&:not(:last-child)': {
      marginRight: '10px',
    },
  },
  selectedFormatButton: {
    width: '100%',
    '& button': {
      width: '100%',
    },
    '&:not(:last-child)': {
      marginRight: '10px',
    },
  },
  selectedHistoryButton: {
    width: '50%',
    '& button': {
      width: '100%',
    },
    '&:not(:last-child)': {
      marginRight: '10px',
    },
  },
  amount: {
    borderBottom: `1px solid ${palette.secondaryColor}`,
  },
  minMax: {
    font: '16px SofiaPro-SemiBold',
    color: palette.secondaryColor,
    letterSpacing: -0.1,
    '& input': {
      textAlign: 'center',
    },
  },
  withdrawAddress: {
    color: palette.primaryColor,
    font: '15px SofiaPro',
    textAlign: 'center',
    borderBottom: '1px solid #CCCCCC',
    '& input': {
      textAlign: 'center',
    },
  },
}));

const PlayFilter = ({ history }) => {
  const classes = useStyles();
  const commonClasses = commonStyle();
  const selected = usePlaySelectedQuery();
  const [filter, setFilter] = useState(selected);
  const setPlayFilter = useSetPlayFilterDispatch();

  /* eslint-disable */
  const onHandleChange = (e) => {
    setFilter({
      ...filter,
      mSport: e.target.value,
    });
  };
  /* eslint-enable */

  const onHandleClick = (name) => {
    setFilter({
      ...filter,
      [name]: !filter[`${name}`],
    });
  };

  const onSubmitFilter = () => {
    setPlayFilter(filter);
    history.push('/more/play');
  };

  const resetFilter = () => {
    setPlayFilter({
      mSport: '',
      singleEliminationWithBuyback: true,
      doubleElimination: true,
      open: true,
      singleEliminationNoBuyback: true,
      handiCap: true,
      semiHandiCap: true,
      currentHistory: true,
    });
    history.push('/more/play');
  };

  return (
    <Box className={commonClasses.root}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <BackButton color={commonClasses.backBtn} />
        <Box>
          <Typography className={classes.reset} onClick={resetFilter}>Reset</Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" mb={4} mt={3}>
        <Typography variant="h3" className={classes.title}>
          Competitions Filter
        </Typography>
      </Box>
      <Typography className={classes.topic}>Current/History</Typography>
      <Box display="flex" justifyContent="flex-start" mt={1} mb={4}>
        <SelectButton
          className={classes.selectedHistoryButton}
          selected={filter.currentHistory}
          onClick={() => onHandleClick('currentHistory')}
        >
          Current
        </SelectButton>
        <SelectButton
          className={classes.selectedHistoryButton}
          selected={!filter.currentHistory}
          onClick={() => onHandleClick('currentHistory')}
        >
          History
        </SelectButton>
      </Box>
      {/*
      <Typography className={classes.topic}>Sport</Typography>
      <Box mt={1} mb={4}>
        <InputBase
          fullWidth
          autoComplete="off"
          className={classes.withdrawAddress}
          placeholder="Search for a sport"
          onChange={onHandleChange}
          value={filter.mSport}
        />
      </Box>
      */}
      <Typography className={classes.topic}>Format</Typography>
      <Box mt={1} mb={4}>
        <SelectButton
          className={classes.selectedFormatButton}
          selected={filter.singleEliminationNoBuyback}
          onClick={() => onHandleClick('singleEliminationNoBuyback')}
        >
          Single Elimination
        </SelectButton>
        {/*
        <SelectButton
          className={classes.selectedFormatButton}
          selected={filter.singleEliminationWithBuyback}
          onClick={() => onHandleClick('singleEliminationWithBuyback')}
        >
          Single Elimination, with buybacks
        </SelectButton>
        */}
        <SelectButton
          className={classes.selectedFormatButton}
          selected={filter.doubleElimination}
          onClick={() => onHandleClick('doubleElimination')}
        >
          Double Elimination
        </SelectButton>
      </Box>
      {/*
      <Typography className={classes.topic}>Type</Typography>
      <Box display="flex" justifyContent="flex-start" mt={1} mb={4}>
        <SelectButton
          className={classes.selectedButton}
          selected={filter.open}
          onClick={() => onHandleClick('open')}
        >
          Open/Scratch
        </SelectButton>
        <SelectButton
          className={classes.selectedButton}
          selected={filter.handiCap}
          onClick={() => onHandleClick('handiCap')}
        >
          Handicap
        </SelectButton>
        <SelectButton
          className={classes.selectedButton}
          selected={filter.semiHandiCap}
          onClick={() => onHandleClick('semiHandiCap')}
        >
          Semi-Handicap
        </SelectButton>
      </Box>
      */}
      <Box>
        <OutlineButton onClick={onSubmitFilter}>
          Apply
        </OutlineButton>
      </Box>
    </Box>
  );
};

PlayFilter.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(PlayFilter);
