import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  Box,
  Grid,
  InputBase,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import BetResultInfoCard from 'components/Bet/BetResultInfoCard';
import OutlineButton from 'components/Buttons/OutlineButton';

import {
  useOpenErrorModalDispatch,
} from 'hooks/redux/modal';

import {
  useResultParlayItems,
  useSetParlayResultItemsDispatch,
} from 'hooks/redux/parlay';

const useStyles = makeStyles(({ palette }) => ({
  accept: {
    borderTop: '1px solid #cccccc',
    borderBottom: '1px solid #cccccc',
  },
  subtitle: {
    color: palette.secondaryColor,
    font: '14px SofiaPro-Medium',
    paddingBottom: '5px',
  },
  smallTitle: {
    color: palette.secondaryColor,
    font: '14px SofiaPro-Medium',
    paddingBottom: '5px',
  },
  amount: {
    color: palette.secondaryColor,
    font: '15px SofiaPro-Bold',
    textAlign: 'center',
    paddingBottom: '0px',
  },
  total: {
    borderBottom: '1px solid #cccccc',
  },
  detail: {
    color: palette.secondaryColor,
    font: '16px SofiaPro-Bold',
    paddingBottom: '8px',
  },
  noResult: {
    color: '#A4A4A4',
    font: '14px SofiaPro-Bold',
  },
  teamPoints: {
    height: '60px',
    boxShadow: '0 3px 10px 0 rgba(0,0,0,0.15)',
    borderRadius: '6px',
    backgroundColor: palette.fieldColor,
    '& input': {
      color: palette.secondaryColor,
      font: '16px SofiaPro-Bold',
      textAlign: 'center',
    },
  },
  icon: {
    marginLeft: '5px',
    color: '#29CCD3',
    fontSize: '20px',
    paddingBottom: '8px',
  },
  textArea: {
    padding: 10,
    color: palette.secondaryColor,
    boxShadow: '0 3px 10px 0 rgba(0,0,0,0.15)',
    borderRadius: '5px',
    backgroundColor: palette.dimGray,
  },
  teamNames: {
    color: '#29CCD3',
    font: '16px SofiaPro-Bold',
    paddingBottom: '5px',
  },
}));

const SubmitResult = ({ betInfo }) => {
  const classes = useStyles();
  const [resultData, setResultData] = useState({
    details: '',
    homeTeamPoints: '',
    awayTeamPoints: '',
  });

  const history = useHistory();
  const resultBets = useResultParlayItems();
  const setResultBets = useSetParlayResultItemsDispatch();

  const openErrorModal = useOpenErrorModalDispatch();

  const handleSetTeamPoints = (teamPoints, value) => {
    setResultData({ ...resultData, [teamPoints]: value });
  };

  const handleSetResultDetails = (value) => {
    setResultData({ ...resultData, details: value });
  };

  const handleSaveResult = async () => {
    if (betInfo.objectType === 'team' && betInfo.interval === 'single_game') {
      if (!resultData.homeTeamPoints || !resultData.awayTeamPoints) {
        openErrorModal({ title: 'Oops', subtitle: 'Please enter team points!' });
        return;
      }
    } else if (!resultData.details) {
      openErrorModal({ title: 'Oops', subtitle: 'Please enter details!' });
      return;
    }
    const savedIndex = resultBets.findIndex((sBet) => sBet.id === betInfo.id);
    const data = [...resultBets];
    if (savedIndex >= 0) {
      data[savedIndex].away_points = resultData.awayTeamPoints;
      data[savedIndex].home_points = resultData.homeTeamPoints;
      data[savedIndex].result_details = resultData.details;
    } else {
      data.push({
        id: betInfo.id,
        away_points: resultData.awayTeamPoints,
        home_points: resultData.homeTeamPoints,
        result_details: resultData.details,
      });
    }
    setResultBets(data);
    history.goBack();
  };

  useEffect(() => {
    if (resultBets) {
      const savedResult = resultBets.find((sBet) => sBet.id === betInfo.id);
      if (savedResult) {
        setResultData({
          details: savedResult.result_details,
          homeTeamPoints: savedResult.home_points,
          awayTeamPoints: savedResult.away_points,
        });
      }
    }
  }, [resultBets, betInfo]);

  return (
    <>
      <BetResultInfoCard betInfo={betInfo} showMaxBet={false} showOdds={false} />
      {
        (betInfo.objectType === 'team' && betInfo.interval === 'single_game') && (
          <Box pb={1} pt={1}>
            <Box display="flex" mt={2} pl={2}>
              <Typography variant="subtitle2" className={classes.detail}>
                Enter Team Scores
              </Typography>
              <InfoOutlinedIcon className={classes.icon} />
            </Box>
            <Box display="flex" style={{ padding: '0px 16px' }} p={2}>
              <Grid item xs={6} sm={6}>
                <Box mt={2} pr={1}>
                  <Typography variant="subtitle2" className={classes.teamNames}>
                    {!betInfo.isSoccerGame ? betInfo.awayTeamName : betInfo.homeTeamName}
                  </Typography>
                  <InputBase
                    autoComplete="off"
                    fullWidth
                    className={classes.teamPoints}
                    placeholder="Enter Score"
                    inputProps={{ inputMode: 'numeric' }}
                    value={!betInfo.isSoccerGame ? resultData.awayTeamPoints : resultData.homeTeamPoints}
                    onChange={(e) => handleSetTeamPoints(
                      !betInfo.isSoccerGame ? 'awayTeamPoints' : 'homeTeamPoints',
                      e.target.value,
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Box mt={2} pl={1}>
                  <Typography variant="subtitle2" className={classes.teamNames}>
                    {!betInfo.isSoccerGame ? betInfo.homeTeamName : betInfo.awayTeamName}
                  </Typography>
                  <InputBase
                    autoComplete="off"
                    fullWidth
                    className={classes.teamPoints}
                    placeholder="Enter Score"
                    inputProps={{ inputMode: 'numeric' }}
                    value={!betInfo.isSoccerGame ? resultData.homeTeamPoints : resultData.awayTeamPoints}
                    onChange={(e) => handleSetTeamPoints(
                      !betInfo.isSoccerGame ? 'homeTeamPoints' : 'awayTeamPoints',
                      e.target.value,
                    )}
                  />
                </Box>
              </Grid>
            </Box>
          </Box>
        )
      }
      <>
        <Box display="flex" alignItems="center" justifyContent="flex-start" style={{ marginLeft: '15px' }} mt={2}>
          <Typography variant="subtitle2" className={classes.detail}>
            {(betInfo.objectType === 'team' && betInfo.interval === 'single_game') ? (
              'OR Enter Details Bet Results'
            ) : 'Enter Details Bet Results' }
          </Typography>
          <InfoOutlinedIcon className={classes.icon} />
        </Box>
        <Box p={2} style={{ paddingTop: '0px' }}>
          <InputBase
            autoComplete="off"
            fullWidth
            multiline
            rows={4}
            className={classes.textArea}
            placeholder="Write details here..."
            value={resultData.details}
            onChange={(e) => handleSetResultDetails(e.target.value)}
          />
        </Box>
      </>
      <Box p={2}>
        <OutlineButton onClick={() => handleSaveResult()}>
          Save Results
        </OutlineButton>
      </Box>
    </>
  );
};

SubmitResult.defaultProps = {
  betInfo: {},
};

SubmitResult.propTypes = {
  betInfo: PropTypes.object,
};

export default SubmitResult;
