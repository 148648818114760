import { detect } from 'detect-browser';
import * as actionTypes from 'store/actionTypes';
import authService from 'services/auth.service';
import storageService from 'services/storage.service';
import config from 'utils/config';
import requestFirebaseNotificationPermission from 'utils/firebase';
import { getErrorMessage } from 'utils';

export const getUserAccessToken = () => storageService.getItem(config.userTokenKey);

export const getUserAction = (loading = true) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    if (loading) {
      dispatch({
        type: actionTypes.GET_USER_REQUEST,
      });
    }

    try {
      const response = await authService.getUser();

      dispatch({
        type: actionTypes.GET_USER_SUCCESS,
        payload: { user: response },
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.GET_USER_FAIL,
      });
      dispatch({
        type: actionTypes.OPEN_ERROR_MODAL,
        payload: {
          title: 'Oops!',
          subtitle: getErrorMessage(e),
        },
      });
    }
  } else {
    dispatch({
      type: actionTypes.GET_USER_FAIL,
    });
  }
};

export const getUserRewardAction = () => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.GET_USER_REWARD_REQUEST,
    });

    try {
      const response = await authService.getRewardStatus();
      dispatch({
        type: actionTypes.GET_USER_REWARD_SUCCESS,
        payload: { reward: response },
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.GET_USER_REWARD_FAIL,
      });
    }
  } else {
    dispatch({
      type: actionTypes.GET_USER_REWARD_FAIL,
    });
  }
};

export const getUserLocationAction = () => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.GET_USER_LOCATION_REQUEST,
    });

    try {
      const response = await authService.getUserLocation();
      dispatch({
        type: actionTypes.GET_USER_LOCATION_SUCCESS,
        payload: { location: response },
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.GET_USER_LOCATION_FAIL,
      });
    }
  } else {
    dispatch({
      type: actionTypes.GET_USER_LOCATION_FAIL,
    });
  }
};

export const loginAction = (data) => async (dispatch) => {
  dispatch({
    type: actionTypes.LOGIN_REQUEST,
  });

  try {
    const response = await authService.login(data);

    storageService.setItem(config.userTokenKey, response.auth_token);

    dispatch({
      type: actionTypes.LOGIN_SUCCESS,
      payload: { user: response.auth_user },
    });

    return response;
  } catch (e) {
    dispatch({
      type: actionTypes.LOGIN_FAIL,
    });

    throw e;
  }
};

export const registerAction = (data) => async (dispatch) => {
  dispatch({
    type: actionTypes.SIGNUP_REQUEST,
  });

  try {
    const response = await authService.register(data);

    storageService.setItem(config.userTokenKey, response.auth_token);

    dispatch({
      type: actionTypes.SIGNUP_SUCCESS,
      payload: { user: response.auth_user },
    });

    return response;
  } catch (e) {
    dispatch({
      type: actionTypes.SIGNUP_FAIL,
    });

    throw e;
  }
};

export const updateUserAction = (data) => async (dispatch) => {
  dispatch({
    type: actionTypes.UPDATE_USER_REQUEST,
  });

  try {
    const response = await authService.updateUser(data);
    dispatch({
      type: actionTypes.UPDATE_USER_SUCCESS,
      payload: { user: response },
    });

    return response;
  } catch (e) {
    dispatch({
      type: actionTypes.UPDATE_USER_FAIL,
    });

    throw e;
  }
};

export const setUserFavoritesAction = (params) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.SET_USER_FAVORITES_REQUEST,
    });

    try {
      const response = await authService.setUserFavorites(params);

      dispatch({
        type: actionTypes.SET_USER_FAVORITES_SUCCESS,
        payload: response,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.SET_USER_FAVORITES_FAIL,
      });
    }
  } else {
    dispatch({
      type: actionTypes.SET_USER_FAVORITES_FAIL,
    });
  }
};

export const setCountryAction = (country) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_USER_COUNTRY,
    payload: country,
  });
};

export const setStateAction = (state) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_USER_STATE,
    payload: state,
  });
};

export const setRegistrationLocationAction = (location) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_USER_REGISTRATION_COUNTRY,
    payload: location,
  });
};

export const setBirthdayAction = (birthday) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_USER_BIRTHDAY,
    payload: birthday,
  });
};

export const setLocationAction = (location) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_USER_LOCATION,
    payload: location,
  });
};

export const setPhotoAction = (profileUrl) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_USER_PHOTO,
    payload: profileUrl,
  });
};

export const setCoverAction = (coverUrl) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_COVER_PHOTO,
    payload: coverUrl,
  });
};

export const setUserCurrencyTypeAction = (currencyType) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_USER_CURRENCY_DISPLAY,
    payload: currencyType,
  });
};

export const setUserOddsTypeAction = (oddType) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_USER_ODDS,
    payload: oddType,
  });
};

export const setUserDisplayModeAction = (mode) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_USER_DISPLAY_MODE,
    payload: mode,
  });
};

export const logoutAction = () => async () => {
  storageService.removeItem(config.userTokenKey);
  window.location.href = '/';
};

export const installAction = () => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.INSTALL_REQUEST,
    });
    try {
      const browser = detect();
      const devToken = await requestFirebaseNotificationPermission()
        .then((firebaseToken) => firebaseToken)
        .catch((err) => err);
      const params = {
        device_type: browser.name,
        device_model: browser.version,
        device_brand: browser.os,
        uuid: devToken,
        web_token: devToken,
      };
      storageService.setItem('web_token', devToken);

      const response = await authService.installation(params);

      dispatch({
        type: actionTypes.INSTALL_SUCCESS,
        payload: response,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.INSTALL_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.INSTALL_FAIL,
    });
  }
};

export const unInstallAction = () => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.UNINSTALL_REQUEST,
    });
    try {
      const webToken = storageService.getItem('web_token');
      const params = {
        uuid: webToken,
        web_token: '',
      };
      storageService.removeItem('web_token');

      const response = await authService.installation(params);

      dispatch({
        type: actionTypes.UNINSTALL_SUCCESS,
        payload: response,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.UNINSTALL_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.UNINSTALL_FAIL,
    });
  }
};
