import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBonusesListAction } from 'store/actions/referral';

export const useReferralIsLoading = () => useSelector(({ referral: { isLoading } }) => isLoading);

export const useBonusesList = (code) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (code) {
      dispatch(getBonusesListAction(code));
    }
    // eslint-disable-next-line
  }, [code]);
  return useSelector(({ referral: { list } }) => list);
};
