import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Typography,
} from '@material-ui/core';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';

import BlankImage from 'assets/images/profile.png';

const useStyles = makeStyles(({ palette }) => ({
  photo: {
    width: '56px',
    height: '56px',
    border: 'none',
    borderRadius: '50%',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      width: 'auto',
      height: '100%',
    },
  },
  content: {
    color: palette.secondaryColor,
    font: '19px SofiaPro-SemiBold',
    marginBottom: 5,
  },
  icon: {
    color: '#fc5a5a',
  },
}));

const FavoriteCard = ({
  id, imageUrl, description, removeFavorite,
}) => {
  const classes = useStyles();
  return (
    <Box alignItems="center" display="flex" justifyContent="space-between" mb={4}>
      <Box display="flex">
        <Box className={classes.photo}>
          <img src={imageUrl || BlankImage} alt="favorite" />
        </Box>
      </Box>
      <Box pl={2} flex={1}>
        <Typography variant="subtitle1" className={classes.content}>
          {description}
        </Typography>
      </Box>
      <Box onClick={() => removeFavorite(id)}>
        <CancelRoundedIcon className={classes.icon} />
      </Box>
    </Box>
  );
};

FavoriteCard.defaultProps = {
  id: 0,
  imageUrl: null,
  description: '',
  removeFavorite: () => {},
};

FavoriteCard.propTypes = {
  id: PropTypes.number,
  imageUrl: PropTypes.string,
  description: PropTypes.string,
  removeFavorite: PropTypes.func,
};

export default FavoriteCard;
