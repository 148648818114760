import * as actionTypes from 'store/actionTypes';

const INITIAL_STATE = {
  isLoading: false,
  usd_balance: 0,
  sports_balance: 0,
  btc_balance: 0,
  icx_balance: 0,
  selected_currency_symbol: '',
  rates: [],
  currencies: [],
  transaction: {},
  show_funds_guide: true,
  message: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_CURRENCY_RATES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_CURRENCY_RATES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };
    case actionTypes.GET_CURRENCY_RATES_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.SELECT_CURRENCY:
      return {
        ...state,
        selected_currency_symbol: action.payload,
      };
    case actionTypes.CREATE_TRANSACTION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.CREATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        transaction: action.payload,
      };
    case actionTypes.CREATE_TRANSACTION_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.WITHDRAW_TRANSACTION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.WITHDRAW_TRANSACTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        transaction: action.payload,
      };
    case actionTypes.WITHDRAW_TRANSACTION_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.GET_TRANSACTION_STATUS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_TRANSACTION_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.GET_TRANSACTION_STATUS_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.GET_TRANSACTION_DETAIL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_TRANSACTION_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        transaction: action.payload,
      };
    case actionTypes.GET_TRANSACTION_DETAIL_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.HIDE_FUNDS_GUIDE:
      return {
        ...state,
        show_funds_guide: false,
      };
    default:
      return state;
  }
};
