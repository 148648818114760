import httpService from './http.service';

const getNotificationSettings = () => httpService
  .get('/users/get_email_and_notification_permissions')
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const updateNotificationSettings = (body) => httpService
  .post('/users/set_email_and_notification_permissions', body)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const resetNotificationBadge = (body) => httpService
  .post('/users/reset_badge', body);

const getNotifications = (params) => httpService
  .get('/zen_notifications/list_notifications', {}, params)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

export default {
  getNotifications,
  getNotificationSettings,
  updateNotificationSettings,
  resetNotificationBadge,
};
