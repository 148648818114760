import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => ({
  copied: {
    font: '13px SofiaPro',
    color: palette.secondaryColor,
    textAlign: 'center',
    height: '15px',
    position: 'absolute',
    display: 'inline-block',
    padding: '15px',
    borderRadius: '60px',
    backgroundColor: '#EAEAEA',
    bottom: '50px',
    zIndex: 200,
    left: '50%',
    transform: 'translateX(-50%)',
    opacity: 0,
    transition: 'all ease-in 0.3s',
  },
  show: {
    opacity: '1 !important',
  },
}));

const Toast = ({ children, show, setShow }) => {
  const classes = useStyles();

  useEffect(() => {
    if (show) {
      window.setTimeout(() => setShow(false), 3000);
    }
  }, [show, setShow]);
  return (
    <Box className={`${classes.copied} ${show ? classes.show : ''}`}>
      {children}
    </Box>
  );
};

Toast.defaultProps = {
  show: false,
  setShow: () => {},
};

Toast.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  children: PropTypes.node.isRequired,
};

export default Toast;
