import * as actionTypes from 'store/actionTypes';

const INITIAL_STATE = {
  isLoading: false,
  list: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_BONUSES_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_BONUSES_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: action.payload,
      };
    case actionTypes.GET_BONUSES_LIST_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
