import React, {
  useCallback, useEffect, useState, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import {
  Box, Typography, InputBase,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import BackButton from 'components/Buttons/BackButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import BetTypeCard from 'components/Bet/BetTypeCard';
import Loading from 'components/Loading/Box';
import CreateBetStep5Dialog from 'components/Dialog/CreateBetStep5Dialog';

import {
  useBet,
  useBetTypes,
  useSelectedLeague,
  useSelectedBetType,
  useSelectedBetObjectType,
  useSetBetTypeDispatch,
  useSetBetTimeDispatch,
  useSelectedInterval,
} from 'hooks/redux/bet';
import { getBetTypeInfo } from 'utils/betting';
import { useOpenErrorModalDispatch } from 'hooks/redux/modal';
import commonStyle from 'styles/common';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 15px',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  subTitle: {
    font: '20px SofiaPro',
    color: palette.secondaryColor,
  },
  icon: {
    color: '#00D3DA',
    width: 15,
    height: 15,
    marginLeft: 10,
  },
  input: {
    color: palette.secondaryColor,
    fontFamily: 'SofiaPro',
    letterSpacing: -0.2,
    border: '1px solid #aaa',
    padding: '5px',
    borderRadius: '5px',
    '& input.MuiInputBase-input': {
      textAlign: 'center',
      fontSize: '20px',
    },
  },
  continue: {
    width: '90%',
    margin: '20px auto',
    // background: 'white',
  },
}));

const SelectBetType = ({ history, match, mode }) => {
  const classes = useStyles();
  const commonClasses = commonStyle();
  const [state, setState] = useState({
    goal: '',
    description: '',
  });
  const [params, setParams] = useState();
  const [selectedBetAttrId, setSelectedBetAttrId] = useState(0);
  const [selectedBetTypeId, setSelectedBetTypeId] = useState(0);
  const [selectedBetType, setSelectedBetType] = useState();

  const betting = useBet();
  const league = useSelectedLeague();
  const betTypes = useBetTypes(params);
  const interval = useSelectedInterval();
  const objectType = useSelectedBetObjectType();
  const betType = useSelectedBetType();
  const setBetTime = useSetBetTimeDispatch();
  const setBetType = useSetBetTypeDispatch();
  const myRef = useRef(null);
  const executeScroll = () => myRef?.current?.scrollIntoView({ behavior: 'smooth' });
  const openErrorModal = useOpenErrorModalDispatch();

  const isValidated = () => {
    let isValidate = true;
    if (selectedBetTypeId === 0) {
      openErrorModal({ title: 'Oops', subtitle: 'Select required options to move to the next step.' });
      isValidate = false;
    } else if (selectedBetType.is_write_in && state.description === '') {
      openErrorModal({
        title: 'Oops',
        subtitle: `You must type in something for this Write In bet. 
      Otherwise, choose a pre-defined bet type from the list at the top instead.`,
      });
      isValidate = false;
    } else if (selectedBetType.has_goal && state.goal === '') {
      openErrorModal({
        title: 'Oops',
        subtitle: `You must make sure to fill in a value for how many ${selectedBetType.name} you would like to set the line at.`,
      });
      isValidate = false;
    } else if (selectedBetType.attribute_groups.length > 0 && selectedBetAttrId === 0) {
      openErrorModal({ title: 'Oops', subtitle: 'Select required options to move to the next step.' });
      isValidate = false;
    }
    return isValidate;
  };
  const handleNext = () => {
    if (!isValidated()) {
      return;
    }
    setBetType({
      ...state,
      id: selectedBetType.id,
      name: selectedBetType.name,
      is_write_in: selectedBetType.is_write_in,
      has_goal: selectedBetType.has_goal,
      attribute: {
        group_id: selectedBetType.attribute_groups[0]?.id,
        group_name: selectedBetType.attribute_groups[0]?.name,
        id: selectedBetAttrId,
        name: selectedBetType.attribute_groups[0]?.attributes?.find(
          (attribute) => attribute.id === selectedBetAttrId,
        )?.name,
      },
    });
    if (mode === 'create') {
      setBetTime({});
      history.push('/bets/create/select-bet-time');
    } else {
      history.push(`/bets/edit/${match.params.betId}/select-bet-time`);
    }
  };

  const handleSelectBetTypeId = useCallback((id, checked) => {
    if (checked) {
      setSelectedBetTypeId(Number(id));
    } else {
      setSelectedBetTypeId(0);
    }
  }, [setSelectedBetTypeId]);

  const handleSelectBetAttrId = useCallback((id, checked) => {
    if (checked) {
      setSelectedBetAttrId(Number(id));
    } else {
      setSelectedBetAttrId(0);
    }
  }, [setSelectedBetAttrId]);

  useEffect(() => {
    if (betType.id) {
      setSelectedBetTypeId(betType.id);
      if (betType.goal || betType.description) {
        setState({
          goal: betType.goal,
          description: betType.description,
        });
      }
      if (betType.attribute?.id) {
        setSelectedBetAttrId(betType.attribute.id);
      }
    }
  }, [betType]);

  useEffect(() => {
    setSelectedBetType(betTypes.find((item) => item.id === selectedBetTypeId));
    const betTypeChecked = betTypes.find((item) => item.id === selectedBetTypeId);
    if (betTypeChecked && betTypeChecked.has_goal) {
      setTimeout(() => { executeScroll(); }, 500);
    }
  }, [betTypes, selectedBetTypeId]);

  useEffect(() => {
    setParams({
      league_id: league.id,
      interval,
      object_type: objectType,
    });
  }, [league, interval, objectType]);

  return (
    <>
      <Box className={classes.root}>
        <Box className={commonClasses.line2} mb={2} />
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
        >
          <BackButton color={commonClasses.backBtn} />
        </Box>
        <Box textAlign="center" mb={4}>
          {
            mode === 'create' && (
              <Typography component="p" className={commonClasses.topic2}>
                STEP 5
              </Typography>
            )
          }
          <Typography component="h3" className={commonClasses.title2}>
            What type of bet is this?
            <CreateBetStep5Dialog />
          </Typography>
        </Box>
        {!betting.isLoading ? (
          <Box>
            {betTypes.map((item) => (
              <BetTypeCard
                key={item.id}
                selectedId={selectedBetTypeId}
                selectType={handleSelectBetTypeId}
                {...getBetTypeInfo(item)}
              />
            ))}
            {selectedBetType && (
              <Box mt={3}>
                { selectedBetType.is_write_in && (
                  <Box ml={1}>
                    <Typography component="h4" className={classes.subTitle}>
                      Write In the details of the bet description
                    </Typography>
                    <Box mt={2}>
                      <InputBase
                        autoComplete="off"
                        fullWidth
                        multiline
                        rows={3}
                        className={classes.input}
                        value={state.description}
                        placeholder="Be creative and descriptive! (max 100 characters)"
                        onChange={(e) => setState({
                          ...state,
                          description: e.target.value,
                        })}
                      />
                    </Box>
                  </Box>
                )}
                {selectedBetType.has_goal && (
                  <Box ref={myRef} ml={1}>
                    <Typography component="h4" className={classes.subTitle}>
                      {selectedBetType.direction}
                    </Typography>
                    <Box mt={2}>
                      <InputBase
                        type="number"
                        autoComplete="off"
                        fullWidth
                        placeholder="Enter Number"
                        className={classes.input}
                        value={state.goal}
                        inputProps={{ inputMode: 'decimal' }}
                        onChange={(e) => setState({
                          ...state,
                          goal: e.target.value,
                        })}
                      />
                    </Box>
                  </Box>
                )}
                {selectedBetType.attribute_groups.length > 0 && selectedBetType.attribute_groups[0].attributes.length > 0 && (
                  <Box ref={myRef} ml={1} mt={3}>
                    <Typography component="h4" className={classes.subTitle}>
                      {selectedBetType.attribute_groups[0].name}
                    </Typography>
                    <Box mt={2}>
                      {selectedBetType.attribute_groups[0]?.attributes?.map((attribute) => (
                        <BetTypeCard
                          key={attribute.id}
                          selectedId={selectedBetAttrId}
                          selectType={handleSelectBetAttrId}
                          id={attribute.id}
                          name={attribute.name}
                        />
                      ))}
                    </Box>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        ) : (<Loading />)}
        <Box mt={3} className={classes.continue}>
          <OutlineButton onClick={() => handleNext()}>
            Continue
          </OutlineButton>
        </Box>
      </Box>
    </>
  );
};

SelectBetType.defaultProps = {
  mode: 'create',
};

SelectBetType.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  mode: PropTypes.string,
};

export default withRouter(SelectBetType);
