/* eslint-disable import/prefer-default-export */

/**
 * @param user
 * @returns {string}
 */

export const displayTournamentName = (user) => {
  let name;
  const fname = user?.first_name || '';
  const lname = user?.last_name || '';

  if (user?.display_name) {
    name = `${user?.display_name}`;
  } else {
    name = `${fname.charAt(0)} ${lname.charAt(0)}`;
  }
  return name;
};
