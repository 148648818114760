import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/styles';
import { Box, Typography } from '@material-ui/core';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import InputBox from 'components/Form/InputBox';

import { useKycDocInfo } from 'hooks/redux/kyc';
import CommonStyle from 'styles/common';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 15px',
  },
  line: {
    background: 'linear-gradient(45deg, #298EDA, #29D2D3)',
    height: '3px',
    marginLeft: '-27px',
    width: '60%',
  },
  topic: {
    font: '12px SofiaPro-SemiBold',
    color: palette.colorBtnTxt,
    textTransform: 'capitalize',
  },
  title: {
    font: '30px SofiaPro-Bold',
    lineHeight: '30px',
    marginBottom: '10px',
    color: palette.secondaryColor,
  },
  content: {
    textAlign: 'center',
    color: '#AEAEAE',
    font: '15px SofiaPro',
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'space-between',
  },
  error: {
    color: 'red',
    textAlign: 'center',
  },
}));

const DocumentationCountry = ({ history }) => {
  const classes = useStyles();
  const commonClasses = CommonStyle();
  const doc = useKycDocInfo();
  // const openErrorModal = useOpenErrorModalDispatch();
  const isResolve = history.location.state === 'resolve';

  return (
    <Page>
      <Box className={classes.root} display="flex" flexDirection="column" flex={1}>
        <Box className={classes.line} mb={2} />
        <Box>
          <BackButton color={commonClasses.backBtn} page={isResolve ? '/kyc/resolve' : '/kyc/profile/address'} />
        </Box>
        <Box textAlign="center" mt={3} mb={1}>
          <Typography component="h3" className={classes.topic}>
            KYC/AML VERIFICATION FORM
          </Typography>
        </Box>
        <Box textAlign="center" mb={6}>
          <Typography component="h3" className={classes.title}>
            Issuing Country of Documentation
          </Typography>
          <span className={classes.content}>
            Select the Country in which your verification documents are issued from.
          </span>
        </Box>
        <Formik
          initialValues={{
            country: doc.country.name,
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            if (values.country === 'United States of America') {
              const route = isResolve ? '/kyc/resolve' : '/kyc/documentation/state';
              history.push(route);
            } else {
              const route = isResolve ? '/kyc/resolve' : '/kyc/documentation/info';
              history.push(route);
            }
          }}
          validationSchema={Yup.object().shape({
            country: Yup.string()
              .required('Required'),
          })}
        >
          {({
            errors,
            touched,
            values,
            isSubmitting,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.form}>
              <Box>
                <InputBox
                  label="Select a Country"
                  name="country"
                  onClick={() => history.push('/kyc/documentation/select-country')}
                  value={values.country}
                  format="outline"
                />
                {
                  errors.country && touched.country
                    ? (
                      <Typography className={classes.error}>
                        {errors.country}
                      </Typography>
                    ) : null
                }
              </Box>
              <Box display="flex" className={commonClasses.continue}>
                <OutlineButton type="submit" disabled={isSubmitting}>
                  {isResolve ? 'Done' : 'Next'}
                </OutlineButton>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Page>
  );
};

DocumentationCountry.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(DocumentationCountry);
