import { usdCurrency } from './constants';

/**
 * @param amount
 * @param currency
 * @returns {number}
 */
export const convertCurrencyAmount = (amount, currency) => {
  let currencyAmount = amount;
  if (typeof amount === 'string') {
    currencyAmount = amount.replace(/,/g, '');
  }
  if (currency.decimal_places) {
    return Math.round(Number(currencyAmount) * (10 ** Number(currency.decimal_places)));
  }
  return Number(currencyAmount);
};

/**
 * @param amount
 * @param minimumFractionDigits
 * @param maximumFractionDigits
 * @param showUSD
 * @returns string
 */
export const convertLocaleNumberString = (amount, minimumFractionDigits, maximumFractionDigits, showUSD = false) => {
  if (!['string', 'number'].includes(typeof amount)) {
    return '';
  }

  const option = {
    minimumFractionDigits,
    maximumFractionDigits,
  };

  const reg = new RegExp(`^-?\\d+(?:.\\d{0,${minimumFractionDigits || -1}})?`);

  if (showUSD) {
    option.style = 'currency';
    option.currency = 'USD';
  }
  return Number(
    amount.toString()?.match(reg)?.[0],
  ).toLocaleString('en-US', option);
};

/**
 *
 * @param currency
 * @param buyCurrency
 * @returns {number}
 */
export const getDecimalPlaces = (currency, buyCurrency = '') => {
  const symbol = currency.symbol.toLowerCase();
  if (symbol === 'sports') {
    return 0;
  }
  if (symbol === 'icx') {
    return 2;
  }
  if (symbol === 'usdt') {
    if (buyCurrency !== 'icx') {
      return currency.decimal_places;
    }
    return 4;
  }
  return currency.decimal_places;
};

/**
 *
 * @param amount
 * @param currency
 * @param withCurrencySymbol
 * @param withFractions
 * @param buyCurrency
 * @returns {string}
 */
export const formatCurrencyAmount = (
  amount,
  currency,
  withCurrencySymbol = true,
  withFractions = true,
  buyCurrency = '',
) => {
  const currencySymbol = currency.symbol.toLowerCase();
  let decimalPlaces = getDecimalPlaces(currency, buyCurrency);
  let maximumFractionDigits = 0;
  let minimumFractionDigits = 0;

  if (!withFractions) {
    decimalPlaces = 0;
  }
  if (currencySymbol === 'usd') {
    maximumFractionDigits = decimalPlaces;
    minimumFractionDigits = decimalPlaces;
    if (withCurrencySymbol) {
      return convertLocaleNumberString(amount, minimumFractionDigits, maximumFractionDigits, true);
    }
  }
  if (currencySymbol === 'sports') {
    if (amount < 1) {
      maximumFractionDigits = 4;
      minimumFractionDigits = decimalPlaces;
    } else {
      maximumFractionDigits = decimalPlaces;
      minimumFractionDigits = decimalPlaces;
    }
  }
  if (currencySymbol === 'icx') {
    // @todo rounding mode
    maximumFractionDigits = decimalPlaces;
    minimumFractionDigits = decimalPlaces;
  }
  if (currencySymbol === 'btc') {
    maximumFractionDigits = decimalPlaces;
    minimumFractionDigits = decimalPlaces;
  }
  if (currencySymbol === 'usdt') {
    maximumFractionDigits = decimalPlaces;
    minimumFractionDigits = decimalPlaces;
  }

  if (!withFractions) {
    maximumFractionDigits = 0;
    minimumFractionDigits = 0;
  }

  if (withCurrencySymbol) {
    return `${convertLocaleNumberString(
      amount,
      minimumFractionDigits,
      maximumFractionDigits,
    )} ${currency.symbol.toUpperCase()}`;
  }

  return convertLocaleNumberString(amount, minimumFractionDigits, maximumFractionDigits);
};

/**
 *
 * @param amount
 * @param currency
 * @param withCurrencySymbol
 * @param withFractions
 * @param buyCurrency
 * @returns {string}
 */
export const formatCurrency = (
  amount,
  currency,
  withCurrencySymbol = true,
  withFractions = true,
  buyCurrency = '',
) => {
  const newAmount = amount / 10 ** currency.decimal_places;
  return formatCurrencyAmount(newAmount, currency, withCurrencySymbol, withFractions, buyCurrency);
};

/**
 *
 * @param amount
 * @param fromCurrency
 * @param toCurrency
 * @returns {number}
 */
export const currencyConvert = (amount, fromCurrency, toCurrency) => {
  const fromCurrencyDecimals = 10 ** fromCurrency.decimal_places;
  const toCurrencyDecimals = 10 ** toCurrency.decimal_places;
  const dollarAmount = (amount / fromCurrencyDecimals) * fromCurrency.rate;
  return (dollarAmount / toCurrency.rate) * toCurrencyDecimals;
};

/**
 *
 * @param amount
 * @param currency
 * @param currencyDisplay
 * @returns {string}
 */
export const getFormattedCurrency = (amount, currency, currencyDisplay = 'both') => {
  let formattedUSDAmount = '';
  let formattedCryptoAmount = '';

  if (currency.symbol.toLowerCase() === 'usd') {
    formattedUSDAmount = formatCurrency(amount, currency);
    return formattedUSDAmount;
  }

  const cryptoUSDCents = currencyConvert(amount, currency, usdCurrency);
  formattedUSDAmount = formatCurrency(cryptoUSDCents, usdCurrency);
  formattedCryptoAmount = formatCurrency(amount, currency);

  if (currencyDisplay === 'both') {
    return `${formattedCryptoAmount} (${formattedUSDAmount})`;
  }
  if (currencyDisplay === 'crypto') {
    return formattedCryptoAmount;
  }
  if (currencyDisplay === 'usd') {
    return formattedUSDAmount;
  }
  return '';
};

/**
 * @param address
 * @param currency
 * @returns {boolean}
 */
export const checkCryptoAddressPrefix = (address, currency) => {
  if (currency.symbol.toLowerCase() === 'btc') {
    return address.slice(0, 1) === '1' || address.slice(0, 1) === '3';
  }
  if (currency.symbol.toLowerCase() === 'sports') {
    return address.slice(0, 2) === 'hx';
  }
  if (currency.symbol.toLowerCase() === 'icx') {
    return address.slice(0, 2) === 'hx';
  }
  if (currency.symbol.toLowerCase() === 'usdt') {
    return address.slice(0, 2) === '0x';
  }
  return true;
};
