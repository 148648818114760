import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import Loading from 'components/Loading/Box';
import OutlineButton from 'components/Buttons/BorderOutlineButton';
import BetResultInfoCard from 'components/Bet/BetResultInfoCard';
import ZenDialog from 'components/Dialog/ZenDialog';
// import resultIconImage from 'assets/images/icon/result.png';

import { getErrorMessage } from 'utils';
import { getBetResultInfo, getParlayResultInfo, getParlayOddsWithExcluded } from 'utils/betting';
import { useUserOddType, useAuthUser } from 'hooks/redux/user';
import {
  useSingleParlay,
  useParlayLoading,
  useExcludedParlayItems,
  useResultParlayItems,
  useExcludeParlayItemsDispatch,
  useSetResultParlayDispatch,
  useExcludeBetParlayDispatch,
  useSetParlayResultItemsDispatch,
} from 'hooks/redux/parlay';
import {
  useOpenErrorModalDispatch,
  useOpenConfirmModalDispatch,
} from 'hooks/redux/modal';
import commonStyle from 'styles/common';

const infoMessage = 'ZenSports provides a set of guidelines for how to submit results for all of the various bet types.'
  + 'You can view these guidelines by tapping on View Guidelines CTA below. You are ultimately responsible for submitting correct results '
  + 'as the Maker, regardless of whether or not you understand these guidelines and how ZenSports will resolve disputes. You are bound by the '
  + 'final resolution/decision of all disputes.';

const disputeMessage = ' If you\'d like to dispute the results of this Parlay, send an email to support@zensports.com '
  + 'and reference the Parlay Card number at the top.';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 8px',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  subTitle: {
    font: '12px SofiaPro',
    color: palette.placeholderColor,
    textAlign: 'center',
    marginTop: 10,
  },
  acceptTime: {
    color: '#A4A4A4',
    font: '12px SofiaPro',
    textAlign: 'center',
    marginTop: 5,
  },
  betsContainer: {
    margin: '20px 0 380px 0',
  },
  resultContainer: {
    margin: '0 -18px',
    position: 'fixed',
    bottom: 0,
    width: '100%',
    background: palette.themeColor,
  },
  likeContent: {
    color: palette.seaGreenColor,
    font: '14px SofiaPro-Bold',
    letterSpacing: 0,
  },
  icon: {
    marginLeft: '10px',
    color: '#29CCD3',
    fontSize: '20px',
  },
  resultCard: {
    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.15)',
    height: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    backgroundColor: palette.fieldColor,
    padding: '5px',
    '& .title': {
      color: palette.secondaryColor,
      font: '15px SofiaPro-Bold',
      lineHeight: 1,
      textAlign: 'center',
      letterSpacing: '-0.3px',
    },
    '&.selected': {
      position: 'relative',
      backgroundImage: 'linear-gradient(45deg, #298EDA, #29D2D3)',
      '& .title': {
        color: '#FFF',
      },
    },
  },
  statusContainer: {
    background: palette.cardBetColor,
    textAlign: 'center',
    padding: '10px 20px',
  },
  resultImage: {
    width: '60px',
  },
  card: {
    backgroundColor: palette.resultsCardColor,
    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.15)',
    width: '90%',
    borderRadius: '6px',
    padding: '10px',
    margin: '10px 0',
  },
  cardTitle: {
    color: palette.secondaryColor,
    font: '14px SofiaPro',
    textAlign: 'center',
  },
  cardContent: {
    color: palette.secondaryColor,
    font: '14px SofiaPro-Bold',
    textAlign: 'center',
  },
  resultText: {
    color: palette.secondaryColor,
    font: '12px SofiaPro',
    marginTop: '5px',
  },
  combinedPayoutContainer: {
    backgroundColor: '#52526f',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '40px',
    padding: '0 20px',
  },
  combinedPayoutTopic: {
    backgroundColor: '#FC5A5A',
    color: '#FFF',
    padding: '4px 10px 3px 10px',
    font: '12px SofiaPro-Medium',
    borderRadius: '20px',
  },
  wagerPayoutTopic: {
    backgroundColor: '#726ee3',
    color: '#FFF',
    padding: '4px 10px 3px 10px',
    font: '12px SofiaPro-Medium',
    borderRadius: '20px',
  },
  combinedPayout: {
    font: '15px SofiaPro-Medium',
    letterSpacing: -0.25,
    color: '#FFF',
  },
  winContainer: {
    backgroundColor: '#14133D',
    padding: '20px 30px',
    textAlign: 'center',
  },
  winTitle: {
    color: 'white',
    font: '14px SofiaPro-Bold',
  },
  winSubTitle: {
    color: '#7A869A',
    font: '12px SofiaPro-Black',
    marginTop: '5px',
  },
  winAmount: {
    color: '#29CAD4',
    font: '13px SofiaPro-Bold',
    marginTop: '5px',
  },
}));

const resultTerms = [
  {
    label: 'Yes',
    value: 'win',
  },
  {
    label: 'No',
    value: 'loss',
  },
  {
    label: 'It was a Tie',
    value: 'tie',
  },
  {
    label: 'Time Changed, Postponed, or Cancelled',
    value: 'canceled',
  },
];

const SingleParlay = ({ match, history }) => {
  const classes = useStyles();
  const commonClasses = commonStyle();
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const [parlayResultInfo, setParlayResultInfo] = useState({});
  const [combinedOdds, setCombinedOdds] = useState({
    odds: 1,
    formattedOddsAmount: '',
  });
  const [resultData, setResultData] = useState({
    result: '',
  });

  const { parlayId } = match.params;
  const isLoading = useParlayLoading();
  const parlay = useSingleParlay(parlayId);
  const excludedBets = useExcludedParlayItems();
  const resultBets = useResultParlayItems();
  const oddType = useUserOddType();
  const user = useAuthUser();
  const setResultParlay = useSetResultParlayDispatch();
  const excludeBetParlay = useExcludeBetParlayDispatch();
  const setExcludeBets = useExcludeParlayItemsDispatch();
  const setResultBets = useSetParlayResultItemsDispatch();
  const openErrorModal = useOpenErrorModalDispatch();
  const openConfirmModal = useOpenConfirmModalDispatch();

  const getBetInfo = (bet) => getBetResultInfo({
    id: bet.id,
    ...bet.proposed_bet,
    user: parlay?.taker ? {} : user,
    currency: parlay.currency,
  }, user, oddType);

  const isExcluded = (id) => excludedBets?.findIndex((item) => item.id === id) >= 0;

  const isAvailableToSubmit = () => !parlay?.taker && parlay?.status === 'pending';

  const handleSelectResultCard = (result) => {
    setResultData({ ...resultData, result });
  };

  const handleSelectCard = (item) => {
    if (parlay.status !== 'pending') {
      history.push(`/bets/results/parlay-bet/${parlay.id}/${item.proposed_bet.id}`);
    } else if (
      isAvailableToSubmit() && (!item.home_points || !item.away_points) && !item.result_details
    ) {
      history.push(`/bets/results/parlay-bet-submit/${item.proposed_bet.id}`);
    }
  };

  const handleExcludeParlay = (bet, flag = true) => {
    const bets = [...excludedBets];
    const betIndex = bets.findIndex((item) => item.id === bet.id);
    if (flag) {
      if (betIndex === -1) {
        bets.push(bet);
      }
    } else if (betIndex >= 0) {
      bets.splice(betIndex, 1);
    }
    setExcludeBets(bets);
  };

  const handleSubmit = () => {
    if (!resultData.result) {
      openErrorModal({
        title: 'Oops!',
        subtitle: 'Please select one of the options above to set the result of this bet first.',
      });
      return;
    }
    const bets = parlay.proposed_bets.map((bet) => {
      const savedResult = resultBets.find((sBet) => sBet.id === bet.proposed_bet.id);
      if (savedResult) {
        return {
          ...bet,
          home_points: savedResult.home_points,
          away_points: savedResult.away_points,
          result_details: savedResult.result_details,
        };
      }
      return bet;
    });
    const _excludedBets = excludedBets.map((bet) => {
      const savedResult = resultBets.find((sBet) => sBet.id === bet.proposed_bet.id);
      if (savedResult) {
        return {
          ...bet,
          home_points: savedResult.home_points,
          away_points: savedResult.away_points,
          result_details: savedResult.result_details,
        };
      }
      return bet;
    });
    for (let i = 0; i < bets.length; i += 1) {
      if ((!bets[i].home_points || !bets[i].away_points) && !bets[i].result_details) {
        openErrorModal({
          title: 'Oops!',
          subtitle: 'Please make sure to enter the score for individual bets.',
        });
        return;
      }
    }
    openConfirmModal({
      title: 'Confirm Parlay Result Submit',
      subtitle: `Please confirm that you want to submit this Parlay result.
    This action can NOT be un-done once you submit result, so please review everything one more time to
    make sure that you have the bets, wager amount, and all other details exactly as you want it before 
    submitting this Parlay result.`,
      agreeBtnText: 'Yes, Submit',
      disagreeBtnText: 'No, Don\'t Submit',
      callback: async () => {
        try {
          setDisabledSubmit(true);
          await excludeBetParlay({
            parlayId: parlay.id,
            data: {
              scores: _excludedBets.map((bet) => ({
                proposed_bet_id: bet.proposed_bet.id,
                home_points: Number(bet.home_points),
                away_points: Number(bet.away_points),
                result_details: bet.result_details,
              })),
            },
          });
          const filteredBets = bets.filter(
            (bet) => excludedBets.findIndex((eBet) => eBet.id === bet.id) === -1,
          );
          await setResultParlay({
            parlayId: parlay.id,
            data: {
              result: resultData.result,
              scores: filteredBets.map((bet) => ({
                proposed_bet_id: bet.proposed_bet.id,
                home_points: Number(bet.home_points),
                away_points: Number(bet.away_points),
                result_details: bet.result_details,
              })),
            },
          });
          setResultBets([]);
          setExcludeBets([]);
          history.push('/bets/results');
        } catch (e) {
          setDisabledSubmit(false);
          openErrorModal({ title: 'Submit Result Error', subtitle: getErrorMessage(e) });
        }
      },
    });
  };

  useEffect(() => {
    if (parlay && parlay.status === 'pending' && parlay.proposed_bets && parlayResultInfo.id && excludedBets) {
      setCombinedOdds(
        getParlayOddsWithExcluded(parlay.proposed_bets, parlayResultInfo, excludedBets, oddType),
      );
    }
  }, [parlay, excludedBets, oddType, parlayResultInfo]);

  useEffect(() => {
    if (parlay && parlay.id && user && oddType) {
      setParlayResultInfo(
        getParlayResultInfo(parlay, user, oddType),
      );
      if (parlay.status !== 'pending' && parlay.proposed_bets.length) {
        const excludedList = parlay.proposed_bets.filter((bet) => bet.active === false);
        if (excludedList.length) {
          setExcludeBets(excludedList);
        }
      }
    }
    // eslint-disable-next-line
  }, [parlay, oddType]);

  return (
    <Page showNavigation={false}>
      <Box className={classes.root}>
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
        >
          <BackButton color={commonClasses.backBtn} />
        </Box>
        <Typography variant="h3" className={commonClasses.title2}>
          Parlay Card
        </Typography>
        <Typography variant="h3" className={classes.subTitle}>
          {`Parlay Card ID: ${parlayId}`}
        </Typography>
        {parlayResultInfo && parlayResultInfo.acceptTime && (
          <Typography variant="subtitle1" className={classes.acceptTime}>
            {`Accepted Time: ${parlayResultInfo.acceptTime}`}
          </Typography>
        )}
        {isLoading && (<Loading />)}
        {parlay && (
          <>
            <Box className={classes.betsContainer}>
              {parlay.proposed_bets?.map((item) => (
                <Box mb={2} key={item.id}>
                  <BetResultInfoCard
                    betInfo={getBetInfo(item)}
                    excluded={isExcluded(item.id)}
                    showExcluded
                    showExcludeBtn={isAvailableToSubmit()}
                    showMaxBet={false}
                    onExcludeParlay={(flag) => handleExcludeParlay(item, flag)}
                    onClick={() => handleSelectCard(item)}
                  />
                </Box>
              ))}
            </Box>
            <Box className={classes.resultContainer}>
              {isAvailableToSubmit() ? (
                <>
                  <Box display="flex" alignItems="center" justifyContent="center" mt={1} mb={1}>
                    <Typography variant="subtitle2" className={classes.likeContent}>
                      Did you win this parlay?
                    </Typography>
                    <InfoOutlinedIcon className={classes.icon} onClick={() => setInfoDialogOpen(true)} />
                    {infoDialogOpen && (
                      <ZenDialog
                        title="Did you win this bet?"
                        message={infoMessage}
                        cta="Got It!"
                        customCta="View Guidelines"
                        customCtaLink="support.zensports.com/en/articles/3013852-guidelines-on-settling-of-bet-results-and-disputes"
                        onClose={() => setInfoDialogOpen(false)}
                      />
                    )}
                  </Box>
                  <Box px={2}>
                    <Grid
                      container
                      spacing={1}
                    >
                      {resultTerms.map((term) => (
                        <Grid item xs={6} sm={6} key={term.value}>
                          <Box
                            className={`${classes.resultCard} ${resultData.result === term.value ? 'selected' : ''}`}
                            onClick={() => handleSelectResultCard(term.value)}
                          >
                            <Typography className="title">{term.label}</Typography>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                  <Box mt={3} mb={1} mx={2}>
                    <OutlineButton disabled={disabledSubmit} onClick={handleSubmit}>
                      Submit Results
                    </OutlineButton>
                  </Box>
                </>
              ) : (
                <Box className={classes.statusContainer}>
                  <Typography variant="subtitle2" className={classes.cardContent}>Parlay results</Typography>
                  {parlayResultInfo.status !== 'pending' && (
                    <Box className={classes.card}>
                      <Box display="flex" alignItems="center" justifyContent="center">
                        <Typography variant="subtitle2" className={classes.cardTitle}>
                          Winner of Bet
                        </Typography>
                        <InfoOutlinedIcon className={classes.icon} onClick={() => setInfoDialogOpen(true)} />
                        {infoDialogOpen && (
                          <ZenDialog
                            title="Did you win this bet?"
                            message={disputeMessage}
                            cta="Got It!"
                            onClose={() => setInfoDialogOpen(false)}
                          />
                        )}
                      </Box>
                      <Typography variant="subtitle2" className={classes.cardContent}>
                        {parlayResultInfo.winnerDescription}
                      </Typography>
                    </Box>
                  )}
                  {parlayResultInfo.mode === 'taker' && parlayResultInfo.status === 'pending' && (
                    <Typography variant="subtitle2" className={classes.resultText}>
                      ZenSports has not yet submitted the results for this Parlay yet. ZenSports has 24 hours from
                      when the last event in a Parlay has started to submit results. Hang tight! Results will be in shortly.
                      <span role="img" aria-label="sunglasses">😎</span>
                    </Typography>
                  )}
                </Box>
              )}
              <Box className={classes.combinedPayoutContainer}>
                <Box className={classes.combinedPayoutTopic}>Combined Odds Payout</Box>
                <Box className={classes.combinedPayout}>
                  {isAvailableToSubmit() ? combinedOdds.formattedOddsAmount : parlayResultInfo.formattedOddsAmount}
                </Box>
              </Box>
              <Box className={classes.combinedPayoutContainer}>
                <Box className={classes.wagerPayoutTopic}>Original Parlay Wager</Box>
                <Box className={classes.combinedPayout}>{parlayResultInfo.formattedCryptoAmount}</Box>
              </Box>
              {!parlay.result && (
                <Box className={classes.winContainer}>
                  <Typography variant="subtitle2" className={classes.winTitle}>
                    Total You’ll Receive If You Win All bets
                  </Typography>
                  <Typography variant="subtitle2" className={classes.winSubTitle}>
                    (includes your original bet)
                  </Typography>
                  <Typography variant="subtitle2" className={classes.winAmount}>
                    {parlayResultInfo.formattedWinAmount}
                  </Typography>
                </Box>
              )}
              {(
                (parlay.result === 'win' && parlayResultInfo.mode === 'maker')
                || (parlay.result === 'loss' && parlayResultInfo.mode === 'taker')
              ) && (
                <Box className={classes.winContainer}>
                  <Typography variant="subtitle2" className={classes.winTitle}>
                    Total amount you’ve Won
                  </Typography>
                  <Typography variant="subtitle2" className={classes.winSubTitle}>
                    (includes your original bet)
                  </Typography>
                  <Typography variant="subtitle2" className={classes.winAmount}>
                    {parlayResultInfo.formattedWinAmount}
                  </Typography>
                </Box>
              )}
              {(parlay.result === 'tie' || parlay.result === 'canceled') && (
                <Box className={classes.winContainer}>
                  <Typography variant="subtitle2" className={classes.winTitle}>
                    Original Parlay Wager refunded
                  </Typography>
                </Box>
              )}
            </Box>
          </>
        )}
      </Box>
    </Page>
  );
};

SingleParlay.defaultProps = {};

SingleParlay.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};
export default withRouter(SingleParlay);
