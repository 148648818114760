import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Box, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Avatar from 'components/Avatar';
import NoMatchesImage from 'assets/images/icon/hashrate.png';
import { displayTournamentName } from 'utils/play';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    margin: '10px -5px 8px',
  },
  container: {
    overflow: 'auto',
  },
  flexBox: {
    display: 'flex',
    alignItems: 'center',
  },
  card: {
    background: theme.palette.cardColor,
    borderRadius: '5px',
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 4px',
    position: 'relative',
    overflow: 'hidden',
    // top: -15,
    marginBottom: '10px',
    width: '280px',
    marginRight: 20,
  },
  competitionLogo: {
    backgroundSize: 'cover',
    width: '100%',
    height: '170px',
    backgroundRepeat: 'no-repeat',
  },
  contentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px',
  },
  line: {
    background: '#d3d3db',
    height: '1px',
  },
  matchContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  matchText: {
    color: theme.palette.secondaryColor,
    fontSize: '13px',
    marginTop: 5,
  },
  roundText: {
    textAlign: 'center',
    color: theme.palette.secondaryColor,
    marginTop: 10,
  },
  contentText: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#b5b5b5',
    margin: '10px 20px 10px',
    fontSize: '13px',
  },
  viewAllText: {
    color: '#52d4e3',
    fontSize: '16px',
    marginBottom: 10,
  },
  bracketBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bracketBtn: {
    padding: 0,
    width: '55%',
    background: theme.palette.colorBtn,
    border: '1.5px solid #29CFD4',
    boxShadow: '0 1px 20px 0 rgba(0,214,219,0.3), 0 3px 10px 0 rgba(0,0,0,0.15)',
    margin: '30px 0 0',
    minHeight: 32,
    borderRadius: 50,
    font: '14px SofiaPro-SemiBold',
    letterSpacing: '0.3px',
    color: theme.palette.colorBtnTxt,
  },
  noMatchesImage: {
    width: '72px',
    height: 'auto',
    margin: '20px auto',
    display: 'block',
  },
  noMatchesText: {
    color: theme.palette.secondaryColor,
    marginTop: 10,
  },
}));

const Matches = ({
  id,
  matches,
  type,
  page,
  isDirector,
  closed,
  history,
  closeEntries,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {
        matches?.length > 0 && (
          <Box display="flex" justifyContent="flex-end">
            <Typography
              className={classes.viewAllText}
              onClick={() => history.push(`/more/play/tournament/view/${id}/${page}`)}
            >
              View all
            </Typography>
          </Box>
        )
      }
      <Box className={classes.container}>
        <Box
          className={classes.flexBox}
          style={{
            width: matches?.length > 0
              ? (matches.length) * 300
              : 'auto',
          }}
        >
          {
            matches?.length > 0 ? matches.map((item) => (
              <Box
                key={item.id}
                className={`cardOverride  ${classes.card}`}
                onClick={() => history.push(`/more/play/tournament/view/${id}/match-details/${item.id}`)}
              >
                <Typography className={classes.roundText}>
                  {item?.place}
                </Typography>
                <Box className={classes.contentContainer}>
                  {item?.users?.map((user) => (
                    <Box key={user.id} className={classes.matchContainer}>
                      <Avatar src={user.profile_url} size="50px" />
                      <Typography className={classes.matchText}>
                        {displayTournamentName(user)}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            )) : (
              <Box>
                <img src={NoMatchesImage} alt="NoMatches" className={classes.noMatchesImage} />
                {type === 'upcoming' && (
                  <>
                    {closed ? (
                      <Typography align="center" className={classes.noMatchesText}>
                        There are no upcoming matches because results have not been submitted.
                      </Typography>
                    ) : (
                      <>
                        {isDirector ? (
                          <Typography align="center" className={classes.noMatchesText}>
                            There are no upcoming matches because you haven&#39;t closed the entries.
                          </Typography>
                        ) : (
                          <Typography align="center" className={classes.noMatchesText}>
                            There are no upcoming matches because the Competition Director has not closed the entries for this competition.
                          </Typography>
                        )}
                      </>
                    )}
                  </>
                )}
                {type === 'completed' && (
                  <>
                    {closed ? (
                      <Typography align="center" className={classes.noMatchesText}>
                        There are no completed matches because results have not been submitted.
                      </Typography>
                    ) : (
                      <>
                        {isDirector ? (
                          <Typography align="center" className={classes.noMatchesText}>
                            There are no completed matches because you haven&#39;t closed the entries.
                          </Typography>
                        ) : (
                          <Typography align="center" className={classes.noMatchesText}>
                            There are no completed matches because the Competition Director has not closed the entries for this competition.
                          </Typography>
                        )}
                      </>
                    )}
                  </>
                )}
                {!closed && isDirector && (
                  <Box display="flex" justifyContent="center">
                    <Button className={classes.bracketBtn} onClick={closeEntries}>
                      Close Entries
                    </Button>
                  </Box>
                )}
              </Box>
            )
          }
        </Box>
      </Box>
      {matches?.length > 0 && (
        <Box className={classes.bracketBox}>
          <Button className={classes.bracketBtn} onClick={() => history.push(`/more/play/tournament/view/${id}/brackets`)}>
            View Bracket
          </Button>
        </Box>
      )}
    </Box>
  );
};

Matches.defaultProps = {
  matches: [],
  type: 'upcoming',
  isDirector: false,
  closed: false,
  closeEntries: () => {},
};

Matches.propTypes = {
  id: PropTypes.number.isRequired,
  matches: PropTypes.array,
  type: PropTypes.string,
  page: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  closed: PropTypes.bool,
  isDirector: PropTypes.bool,
  closeEntries: PropTypes.func,
};

export default withRouter(Matches);
