import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { makeStyles, withStyles } from '@material-ui/styles';
import {
  Box, Button, Checkbox, Typography,
} from '@material-ui/core';
import Circle from '@material-ui/icons/PanoramaFishEye';
import CheckCircle from '@material-ui/icons/CheckCircle';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import OutlineButton from 'components/Buttons/OutlineButton';

import {
  useOpenSuccessModalDispatch,
  useOpenErrorModalDispatch,
} from 'hooks/redux/modal';
import {
  useKycUserInfo,
  useKycDocInfo,
  useKycResourceToken,
  useCreateAccountDispatch,
} from 'hooks/redux/kyc';
import { getSubmitKycInfo } from 'utils/kyc';
import CommonStyle from 'styles/common';

const PrimaryCheckbox = withStyles({
  root: {
    color: '#979797',
    paddingLeft: '0px',
    '&.Mui-checked': {
      color: '#63C4C6',
    },
  },
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 15px',
    height: '100%',
  },
  line: {
    background: 'linear-gradient(45deg, #298EDA, #29D2D3)',
    height: '3px',
    marginLeft: '-27px',
    width: '100%',
  },
  topic: {
    font: '12px SofiaPro-SemiBold',
    color: palette.colorBtnTxt,
    textTransform: 'capitalize',
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
  },
  content: {
    flex: 1,
    overflow: 'auto',
    color: palette.secondaryColor,
  },
  subtitle: {
    fontSize: '12px',
    lineHeight: '1.3',
  },
  trustLink: {
    color: palette.secondaryColor,
    font: '16px SofiaPro-SemiBold',
  },
  cancel: {
    padding: 0,
    width: '100%',
    color: palette.closeBtnColor,
    background: palette.themeColor,
    border: 'none',
    boxShadow: 'none',
    marginBottom: '10px',
    minHeight: 50,
    font: '17px SofiaPro-SemiBold',
    letterSpacing: '0.3px',
  },
}));

const Tos = ({ history }) => {
  const classes = useStyles();
  const commonClasses = CommonStyle();
  const user = useKycUserInfo();
  const doc = useKycDocInfo();
  const openErrorModal = useOpenErrorModalDispatch();
  const openSuccessModal = useOpenSuccessModalDispatch();
  const resourceToken = useKycResourceToken();
  const createAccount = useCreateAccountDispatch();

  const [termOfService, setTemOfService] = useState(false);
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const handleSubmit = async () => {
    setDisabledSubmit(true);
    try {
      if (termOfService) {
        const data = getSubmitKycInfo(user, doc, resourceToken);
        await createAccount(data);
        setDisabledSubmit(false);
        openSuccessModal({
          title: 'Successfully submitted!',
          subtitle: '',
          buttonText: 'Got it',
          nextPath: '/kyc/status',
        });
      }
    } catch (e) {
      setDisabledSubmit(false);
      openErrorModal({
        title: 'Submit Error',
        subtitle: '',
        buttonText: 'Got it',
      });
    }
  };

  return (
    <Page>
      <Box className={classes.root} display="flex" flexDirection="column" flex={1}>
        <Box className={classes.line} mb={2} />
        <Box>
          <BackButton color={commonClasses.backBtn} page="/kyc/documentation/info" />
        </Box>
        <Box textAlign="center" mt={3} mb={1}>
          <Typography component="h3" className={classes.topic}>
            KYC/AML VERIFICATION FORM
          </Typography>
        </Box>
        <Box textAlign="center" mb={3}>
          <Typography component="h3" className={classes.title}>
            Prime Trust Agreement
          </Typography>
        </Box>
        <Box className={classes.content}>
          <Typography className={classes.subtitle}>
            {'I hereby requests and directs that Prime Trust, LLC("Prime Trust", "Custodian", "we", "our", "us"),'
            + ' a Nevada chartered trust company, establish a Custodial Account ("Account") for and in the name of Account Holder,'
            + ' and to hold as custodian all assets deposited to, or collected with respect to such Account, upon the following terms '
            + 'and conditions:'}
          </Typography>
          <Box mt={2} mb={2}>
            <Typography>
              1. APPOINTMENT OF CUSTODIAN
            </Typography>
          </Box>
          <Typography className={classes.subtitle}>
            {`Account Holder hereby appoints Prime Trust to be custodian of and to hold
            or process as directed all securities, cash and other assets of Account
            Holder (hereinafter referred to as "Custodial Property") that are delivered to
            and accepted by Custodian by Account Holder or Account Holder's Agent(s)
            (as defined below) to the Account in accordance with the terms of this Agreement.`}
          </Typography>
          <Box mt={2} mb={2}>
            <Typography>
              2. SELF-DIRECTED INVESTMENTS:
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography className={classes.subtitle}>
              a.&nbsp;&nbsp;&nbsp;&nbsp;
              {`This Account is a self-direted Account by Account Holder and/or Account Holder's Agents.
            Prime Trust will act solely as custodian of the Custodial Property and will not exercise any
            investment or tax planning discretion regarding your Account, as this is solely your responsibility
            and/or the responsivility of advisors, brokers and others you designate and appoint as your agent
            through setting and tools we provide you with for your Account("Agents"), if any.
            Prime Trust undertakes to perform only such duties as are expressly set forth herein, all of which are ministerial in nature.`}
              <br />
              <br />
              b.&nbsp;&nbsp;&nbsp;&nbsp; As a self-directed Account, you recognize and accept that:
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;i.&nbsp;&nbsp;&nbsp;&nbsp; The value of your Account will be solely dependent
              &nbsp;upon the performance of any asset(s) chosen by you and/or your Agents.
            </Typography>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column">
          <Box display="flex" alignItems="center" mt={2} mb={2}>
            <PrimaryCheckbox
              icon={<Circle />}
              checkedIcon={<CheckCircle />}
              checked={termOfService}
              onChange={(e) => setTemOfService(e.target.checked)}
              name="checkedH"
              size="medium"
            />
            <Box className={classes.trustLink}>
              I Agree to the&nbsp;&nbsp;
              <a
                href="https://zensports.s3.eu-central-1.amazonaws.com/docs/prime_trust_agreement.pdf"
                className={classes.link}
                target="_blank"
                rel="noreferrer"
              >
                Prime Trust Agreement
              </a>
            </Box>
          </Box>
          <OutlineButton type="submit" disabled={disabledSubmit} className={classes.next} onClick={handleSubmit}>
            Submit KYC
          </OutlineButton>
          <Button mt={2} onClick={() => history.push('/kyc/documentation/info')} className={classes.cancel}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Page>
  );
};

Tos.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(Tos);
