import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CancelIcon from '@material-ui/icons/Cancel';
import { setCoverAction } from 'store/actions/auth';

import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import config from 'utils/config';
import cameraIcon from 'assets/images/setting/camera.png';

const useStyles = makeStyles({
  coverImage: {
    height: '150px',
    borderRadius: '10px',
    textAlign: 'center',
    margin: '10px 0',
    '& span': {
      display: 'block',
      font: '14px SofiaPro-Bold',
      color: '#63C4C6',
    },
  },
  cancelIcon: {
    color: '#63C4C6',
    width: 25,
    height: 25,
    background: 'white',
    borderRadius: '50%',
    position: 'relative',
    left: '95%',
    marginBottom: '-26px',
    curson: 'pointer',
  },
  defaultImage: {
    width: '50px',
    height: '40px',
    marginTop: '50px',
  },
  profileImage: {
    height: '150px',
    width: '100%',
    borderRadius: '10px',
    display: 'inline-block',
  },
});

const CoverPhoto = React.memo(({ coverUrl, setCoverUrl, changeUrl }) => {
  const classes = useStyles();

  const onUploadFinish = (error, result) => {
    if (error) {
    //  console.log('Upload error: ', error);
    }
    if (result.event === 'success' && result.info?.secure_url) {
      changeUrl(result.info.secure_url);
      setCoverUrl(result.info.secure_url);
    }
  };
  const removeImage = () => {
    setCoverUrl(null);
    changeUrl(null);
  };
  const onUploadFile = () => {
    const cropWidget = window.cloudinary.createUploadWidget(
      {
        cloudName: config.cloudinaryCloudName,
        uploadPreset: config.cloudinaryUploadPreset,
        folder: 'widgetUpload',
        cropping: true,
      },
      onUploadFinish,
    );
    cropWidget.open();
  };

  return (
    <Box>
      {
        coverUrl && <CancelIcon className={classes.cancelIcon} onClick={removeImage} />
      }
      <Box className={classes.coverImage} style={{ border: coverUrl ? 'none' : '2px dashed #63C4C6' }} onClick={onUploadFile}>
        <img className={coverUrl === null ? classes.defaultImage : classes.profileImage} src={coverUrl || cameraIcon} alt="camera" />
        {
          !coverUrl && <span>Upload</span>
        }
      </Box>
    </Box>
  );
});

CoverPhoto.defaultProps = {
  coverUrl: '',
};

CoverPhoto.propTypes = {
  coverUrl: PropTypes.string,
  setCoverUrl: PropTypes.func.isRequired,
  changeUrl: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  profileUrl: state.auth.user.cover_url,
});

const mapDispatchToProps = (dispatch) => ({
  setCoverUrl: (data) => dispatch(setCoverAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CoverPhoto);
