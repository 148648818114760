import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { makeStyles } from '@material-ui/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles(({ palette }) => ({
  dialogTitle: {
    paddingBottom: 0,
    background: palette.cardColor,
    color: palette.secondaryColor,
    '& h2': {
      fontWeight: 'bold',
      fontSize: 20,
    },
  },
  dialogActions: {
    background: palette.cardColor,
  },
  dialogContent: {
    background: palette.cardColor,
    '& p': {
      color: palette.secondaryColor,
      lineHeight: 1.2,
    },
  },
  dialogButton: {
    background: 'none',
    boxShadow: 'none',
    '& span': {
      color: '#0091FF',
      fontWeight: 'bold',
    },
  },
  icon: {
    color: '#00D3DA',
    width: 15,
    height: 15,
    marginLeft: 10,
  },
}));

export default function CreateBetStep2Dialog() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <InfoOutlinedIcon className={classes.icon} onClick={handleClickOpen} />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>Closing Date/Time Definitions</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText id="alert-dialog-description">
            When a bet &quot;closes&quot;, it is no longer available to any new Takers to accept,
            and you will be refunded on any unaccepted portions of the bet at that
            time. Here are the 7 different closing date/time options that you can choose:
            <br />
            <br />
            1)
            <b> Start Time of First Game in Season</b>
            - Bets that will close at the start time of the first game in a season for that particular
            league/sport.  NOTE: This option will not be available if the season schedule has not been set.
            Please choose one of the other options below if that is the case.
            <br />
            <br />
            2)
            <b> Start Time of Last Game in Season </b>
            - Bets that will close at the start time of the last game in a season for that particular league/sport.
            <br />
            <br />
            3)
            <b> Start Time of Last Game in Series </b>
            - Bets that will close at the start time of the last game in a series of multiple games that you select.
            <br />
            <br />
            4)
            <b> Start Time of Game </b>
            - Bets that will close at the start time of a game.
            <br />
            <br />
            5)
            <b> Specific Date & Time </b>
            - Bets that will close at a specific date and time that you select.
            <br />
            <br />
            6)
            <b> In X Days, Hours, Minutes, and Seconds </b>
            - Bets that will close in a certain number of days, hours, minutes,
            and/or seconds that you set. This &quot;clock&quot; starts ticking from the moment that
            the bet has been created after Step 7 in ZenSports.
            <br />
            <br />
            7)
            <b> Manual </b>
            - ZenSports doesn&apos;t automatically close the bet at all. You are responsible for going in
            and deleting or closing out the bet manually.
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={handleClose} className={classes.dialogButton}>
            GOT IT!
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
