import * as actionTypes from 'store/actionTypes';
import storageService from 'services/storage.service';
import parlayService from 'services/parlay.service';
import config from 'utils/config';

export const submitParlayAction = (data) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.SUBMIT_PARLAY_REQUEST,
    });

    try {
      const response = await parlayService.submitParlay(data);
      dispatch({
        type: actionTypes.SUBMIT_PARLAY_SUCCESS,
        payload: response,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.SUBMIT_PARLAY_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.SUBMIT_PARLAY_FAIL,
    });
  }
};

export const getParlayAction = (id) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.GET_PARLAY_REQUEST,
    });

    try {
      const response = await parlayService.getParlay(id);
      dispatch({
        type: actionTypes.GET_PARLAY_SUCCESS,
        payload: {
          data: response,
        },
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.GET_PARLAY_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.GET_PARLAY_FAIL,
    });
  }
};

export const setResultParlayAction = (data) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.SET_RESULT_PARLAY_REQUEST,
    });

    try {
      const response = await parlayService.setResultParlay(data);
      dispatch({
        type: actionTypes.SET_RESULT_PARLAY_SUCCESS,
        payload: response,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.SET_RESULT_PARLAY_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.SET_RESULT_PARLAY_FAIL,
    });
  }
};

export const excludeBetParlayAction = (data) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.EXCLUDE_BET_PARLAY_REQUEST,
    });

    try {
      const response = await parlayService.excludeBetParlay(data);
      dispatch({
        type: actionTypes.EXCLUDE_BET_PARLAY_SUCCESS,
        payload: response,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.EXCLUDE_BET_PARLAY_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.EXCLUDE_BET_PARLAY_FAIL,
    });
  }
};

export const addParlayItemAction = (payload) => async (dispatch) => {
  dispatch({
    type: actionTypes.ADD_PARLAY_ITEM,
    payload,
  });
};

export const deleteParlayItemAction = (payload) => async (dispatch) => {
  dispatch({
    type: actionTypes.DELETE_PARLAY_ITEM,
    payload,
  });
};

export const clearParlayItemsAction = () => async (dispatch) => {
  dispatch({
    type: actionTypes.CLEAR_PARLAY_ITEMS,
    payload: {},
  });
};

export const excludeParlayItemsActions = (payload) => async (dispatch) => {
  dispatch({
    type: actionTypes.EXCLUDE_PARLAY_ITEMS,
    payload,
  });
};

export const setResultParlayItemsActions = (payload) => async (dispatch) => {
  dispatch({
    type: actionTypes.SET_RESULT_PARLAY_ITEMS,
    payload,
  });
};
