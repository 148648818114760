import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import { Box, Typography } from '@material-ui/core';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import KycDialog from 'components/Dialog/KycDialog';

import { getResourceTokenAction } from 'store/actions/kyc';
import { useUpdateCreatedAccountDispatch } from 'hooks/redux/kyc';
import config from 'utils/config';
import CommonStyle from 'styles/common';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    flexDirection: 'column',
    flex: 1,
  },
  content: {
    textAlign: 'center',
    color: '#AEAEAE',
    font: '15px SofiaPro',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 12px',
  },
  icon: {
    color: '#00D3DA',
    width: 20,
    height: 20,
    marginLeft: 5,
    marginBottom: -3,
  },
  widget: {
    '& h3': {
      textAlign: 'center',
      color: palette.secondaryColor,
    },
    '& iframe': {
      border: 'none !important',
    },
  },
}));

const AccountCreation = ({ getResourceToken }) => {
  const classes = useStyles();
  const commonClasses = CommonStyle();
  const updateAccount = useUpdateCreatedAccountDispatch();

  useEffect(() => {
    /* eslint-disable */
    const bootstrap = document.createElement('script');
    const kycScript = document.createElement('script');

    const init = async () => {
      const resource = await getResourceToken();
      window.updateKycAccount = updateAccount;
      bootstrap.src = config.primeTrustBootstrap;
      kycScript.type = 'text/javascript';
      kycScript.innerHTML = `
        console.log('[primetrust] 1. Loading Prime Trust Embedding API');
        window.primeTrustReady = (pt) => {
          console.log('[primetrust] 2. Loading embedded account creation...');
          // or launchCreditCard, launchCreateCompany, launchCreateNaturalPerson
          pt.launchCreateAccount({
            target: document.getElementById('kyc'),
            // this token is generated server-side with our \`/v2/resource-tokens\` API
            resourceTokenHash: '${resource.token}',
            events: {
              loaded: function () {
                console.log('[primetrust] 3. Embedded account creation loaded!')
              },
              resourceCreated: function (accountId) {
                console.log('[primetrust] 4. Account created with ID: ', accountId);
                updateKycAccount({account_id: accountId})

                const element = document.getElementById("kyc");
                const newDiv = document.createElement("h3");
                const newContent = document.createTextNode("Success! Your KYC has been submitted");
                newDiv.appendChild(newContent);
                element.innerHTML = '';
                element.appendChild(newDiv);

                setTimeout(function(){
                  window.location.href = '/kyc/status';
                }, 3000);

              }
            },
          });
        };
      `;
    };
    init();

    document.body.appendChild(bootstrap);
    document.body.appendChild(kycScript);
    return () => {
      document.body.removeChild(bootstrap);
      document.body.removeChild(kycScript);
    };
    /* eslint-enable */
    // eslint-disable-next-line
  }, []);

  return (
    <Page>
      <Box className={classes.root} display="flex" flexDirection="column" flex={1}>
        <Box>
          <BackButton color={commonClasses.backBtn} />
        </Box>
        <Box textAlign="center" mt={3}>
          <Typography component="h3" className={commonClasses.title2}>
            KYC/AML Verification
          </Typography>
          <span className={classes.content}>
            Please submit the following information to get KYC Verified.
            <KycDialog />
          </span>
        </Box>
        <Box mt={5} display="flex" flexDirection="column">
          <div className={classes.widget} id="kyc" />
        </Box>
      </Box>
    </Page>
  );
};

AccountCreation.propTypes = {
  getResourceToken: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  getResourceToken: () => dispatch(getResourceTokenAction()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountCreation));
