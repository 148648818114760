import firebase from 'firebase/app';
import 'firebase/messaging';
import constant from './config';

const config = { ...constant.firebase };

firebase.initializeApp(config);
let messaging;
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
}

const requestFirebaseNotificationPermission = () => new Promise((resolve, reject) => {
  if (firebase.messaging.isSupported()) {
    messaging
      .getToken()
      .then((firebaseToken) => {
        resolve(firebaseToken);
      })
      .catch((err) => {
        reject(err);
      });
  }
});

export default requestFirebaseNotificationPermission;
