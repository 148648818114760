import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  Box, Fab, Typography, makeStyles,
} from '@material-ui/core';

import Page from 'components/Page/Auth';
import InputDropDown from 'components/Form/InputDropDown';
import BackButton from 'components/Buttons/BackButton';
import SoccerStadiumImage from 'assets/images/soccer-stadium.png';

import { setRegistrationLocationAction } from 'store/actions/auth';
import { useRegistrationLocations } from 'hooks/redux/country';
import { useOpenErrorModalDispatch } from 'hooks/redux/modal';

const useStyles = makeStyles({
  content: {
    marginTop: '30px',
  },
  subTitle: {
    fontSize: '14px',
    color: 'white',
    textAlign: 'center',
  },
  dropDownContent: {
    background: 'white',
    borderRadius: '6px',
    '& input': {
      fontSize: '18px',
    },
  },
});

const Casino = ({ history, setLocation, locationId }) => {
  const classes = useStyles();
  const locations = useRegistrationLocations();
  const openErrorModal = useOpenErrorModalDispatch();

  const handleNext = () => {
    if (locationId) {
      history.push('/birthday');
    } else {
      openErrorModal({ title: 'Oops', subtitle: 'Please select a location!' });
    }
  };

  const handleRegistrationLocation = (location) => {
    setLocation(location.id);
  };

  useEffect(() => {
    if (locations.length) {
      handleRegistrationLocation(locations[0]);
    }
    // eslint-disable-next-line
  }, [locations.length]);

  return (
    <Page backgroundImage={SoccerStadiumImage}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <BackButton />
        <Fab variant="extended" onClick={handleNext}>Next</Fab>
      </Box>
      <Typography variant="h1" className="text-center color-white">
        Sports Book Location
      </Typography>
      <Box className={classes.content}>
        <Typography variant="subtitle1" className={classes.subTitle}>
          Choose the sports book location that you are signing up for a ZenSports account at.
        </Typography>
        {locations?.length > 0 && (
          <Box className={classes.dropDownContent}>
            <InputDropDown
              value={locations[0]}
              items={locations}
              handleSelect={handleRegistrationLocation}
            />
          </Box>
        )}
      </Box>
    </Page>
  );
};

Casino.defaultProps = {
  locationId: 0,
};

Casino.propTypes = {
  history: PropTypes.object.isRequired,
  locationId: PropTypes.number,
  setLocation: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  locationId: state.auth.user.registration_location_id,
});

const mapDispatchToProps = (dispatch) => ({
  setLocation: (location) => dispatch(setRegistrationLocationAction(location)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Casino));
