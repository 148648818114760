import * as actionTypes from 'store/actionTypes';
import config from 'utils/config';
import storageService from 'services/storage.service';
import primeTrust from 'services/primeTrust.service';

export const getResourceTokenAction = (params = {}) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.GET_KYC_TOKEN_REQUEST,
    });

    try {
      const response = await primeTrust.getResourceToken(params);

      dispatch({
        type: actionTypes.GET_KYC_TOKEN_SUCCESS,
        payload: response.token,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.GET_KYC_TOKEN_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.GET_KYC_TOKEN_FAIL,
    });
  }
};

export const createAccountAction = (body = {}) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.CREATE_KYC_USER_REQUEST,
    });

    try {
      const response = await primeTrust.createAccount(body);

      dispatch({
        type: actionTypes.CREATE_KYC_USER_SUCCESS,
        payload: response,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.CREATE_KYC_USER_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.CREATE_KYC_USER_FAIL,
    });
  }
};

export const updateAccountCreatedAction = (body = {}) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.UPDATE_CREATED_KYC_USER_REQUEST,
    });

    try {
      const response = await primeTrust.updateAccountCreated(body);

      dispatch({
        type: actionTypes.UPDATE_CREATED_KYC_USER_SUCCESS,
        payload: response,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.UPDATE_CREATED_KYC_USER_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.UPDATE_CREATED_KYC_USER_FAIL,
    });
  }
};

export const updateAccountAction = (body = {}) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.UPDATE_KYC_USER_REQUEST,
    });

    try {
      const response = await primeTrust.updateAccount(body);

      dispatch({
        type: actionTypes.UPDATE_KYC_USER_SUCCESS,
        payload: response,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.UPDATE_KYC_USER_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.UPDATE_KYC_USER_FAIL,
    });
  }
};

export const uploadDocAction = (body) => async (dispatch) => {
  dispatch({
    type: actionTypes.UPLOAD_KYC_DOC_REQUEST,
  });

  try {
    const response = await primeTrust.uploadDoc(body);

    dispatch({
      type: actionTypes.UPLOAD_KYC_DOC_SUCCESS,
      payload: response,
    });

    return response;
  } catch (e) {
    dispatch({
      type: actionTypes.UPLOAD_KYC_DOC_FAIL,
    });
    throw e;
  }
};

export const setKycUserInfoAction = (info) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_KYC_USER_INFO,
    payload: info,
  });
};

export const setKycDocInfoAction = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_KYC_DOC_INFO,
    payload: data,
  });
};
