import React from 'react';

import Page from 'components/Page/Dashboard';
import PlayFilter from 'components/Play/PlayFilter';

const Filter = () => (
  <Page>
    <PlayFilter />
  </Page>
);

export default Filter;
