import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { debounce } from 'lodash';

import { makeStyles } from '@material-ui/styles';
import TuneIcon from '@material-ui/icons/Tune';
import { Box } from '@material-ui/core';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import PlayTabs from 'components/Play/Competitions';

import CommonStyle from 'styles/common';
import SearchInput from 'components/Form/SearchInput';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 5px',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  headerTools: {
    position: 'fixed',
    top: 0,
    zIndex: 1,
    width: 'calc(100% - 20px)',
    background: palette.themeColor,
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
    padding: '10px 10px 0 10px',
    marginTop: '20px',
  },
  filterBtn: {
    transform: 'rotate(-90deg)',
    color: palette.secondaryColor,
    fontSize: '2rem',
  },
  clearfix: {
    overflow: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  floatBtn: {
    float: 'left',
    margin: '10px 0',
  },
  pillMenu: {
    listStyle: 'none',
    background: palette.pillMenuBgColor,
    borderRadius: '24px',
    margin: 'auto',
    padding: '2px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    '& li a': {
      float: 'left',
      fontSize: '12px',
      color: palette.pillInactiveColor,
      padding: '10px 15px',
      borderRadius: '25px',
    },
    '& li a.active': {
      color: palette.secondaryColor,
      background: palette.pillActiveBgColor,
    },
  },
}));

const Play = ({ history }) => {
  const classes = useStyles();
  const commonClasses = CommonStyle();
  const [search, setSearch] = useState('');
  const [debounceSearch, setDebounceSearch] = useState('');

  // eslint-disable-next-line
  const doDebounceSearch = useCallback(
    debounce((value) => setDebounceSearch(value), 500), [debounce, setDebounceSearch],
  );

  // Handle search input
  const handleSearch = (e) => {
    setSearch(e.target.value);
    doDebounceSearch(e.target.value);
  };

  // Handle clearing search input
  const handleClearSearch = () => {
    setSearch('');
    doDebounceSearch('');
  };

  const handleFilter = () => {
    history.push('/more/play/filter');
  };

  return (
    <Page noTopPadding>
      <Box className={classes.root}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          className={classes.headerTools}
        >
          <BackButton color={commonClasses.backBtn} />
          <SearchInput
            handleChange={handleSearch}
            placeholder="Search by competition title."
            value={search}
            clearSearch={handleClearSearch}
            showClear
          />
          <TuneIcon className={classes.filterBtn} onClick={handleFilter} />
        </Box>

        <PlayTabs debounceSearch={debounceSearch} searchValue={search} />
      </Box>
    </Page>
  );
};

Play.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(Play);
