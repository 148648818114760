import React from 'react';
import PropTypes from 'prop-types';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0 -10px 8px',
  },
  detailsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  heading: {
    color: theme.palette.secondaryColor,
    width: '35%',
    fontWeight: 600,
    fontSize: '17px',
  },
  content: {
    fontSize: '17px',
    width: '65%',
    color: theme.palette.secondaryColor,
  },
  line: {
    background: '#d3d3db',
    height: '1px',
    margin: '18px 0 15px',
  },
}));

const Details = ({ details }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={classes.detailsContainer}>
        <Typography className={classes.heading}>
          Entry Fee
        </Typography>
        <Typography className={classes.content}>
          {details?.fee_display}
        </Typography>
      </Box>
      <Box className={classes.line} />
      <Box className={classes.detailsContainer}>
        <Typography className={classes.heading}>
          Frequency
        </Typography>
        <Typography className={classes.content}>
          {details?.frequency_display}
        </Typography>
      </Box>
      <Box className={classes.line} />
      <Box className={classes.detailsContainer}>
        <Typography className={classes.heading}>
          Notes
        </Typography>
        <Typography className={classes.content}>
          {details?.notes || ''}
        </Typography>
      </Box>
    </Box>
  );
};

Details.propTypes = {
  details: PropTypes.object.isRequired,
};

export default Details;
