import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Grid,
} from '@material-ui/core';
import InputCurrencyExchange from 'components/Form/InputCurrencyExchange';
import OutlineButton from 'components/Buttons/OutlineButton';

import {
  useCreateTransactionDispatch,
} from 'hooks/redux/currency';
import { useOpenErrorModalDispatch } from 'hooks/redux/modal';
import { usdCurrency } from 'utils/constants';
import {
  formatCurrency,
  currencyConvert,
  convertCurrencyAmount,
} from 'utils/currency';
import { getErrorMessage } from 'utils';
import DepositGuide from './DepositGuide';

const useStyles = makeStyles(({ palette }) => ({
  root: ({ noBorder }) => ({
    boxShadow: !noBorder ? '0 2px 10px 1px rgba(0,0,0,0.15)' : 'none',
    borderRadius: '6px',
    marginBottom: '10px',
    background: palette.cardColor,
  }),
  deposit: ({ collapsed }) => ({
    padding: '10px 0',
    margin: '0 24px',
    borderBottom: collapsed ? '1px solid #F1F1F5' : 'none',
    color: palette.secondaryColor,
    font: '18px SofiaPro-Bold',
  }),
  walletImage: {
    height: '50px',
  },
}));

const CryptoDepositCard = ({
  currency,
  history,
  noBorder,
  collapsed,
  onCardClick,
}) => {
  const classes = useStyles({ collapsed, noBorder });
  const [cryptoCurrencyAmount, setCryptoCurrencyAmount] = useState('');
  const [usdCurrencyAmount, setUsdCurrencyAmount] = useState('');
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const createTransaction = useCreateTransactionDispatch();
  const openErrorModal = useOpenErrorModalDispatch();

  const handleCryptoCurrencyAmount = (event) => {
    setCryptoCurrencyAmount(event.target.value);
    setUsdCurrencyAmount(
      formatCurrency(
        currencyConvert(
          convertCurrencyAmount(event.target.value, currency),
          currency,
          usdCurrency,
        ),
        usdCurrency,
        false,
      ),
    );
  };

  const handleUsdCurrencyAmount = (event) => {
    setUsdCurrencyAmount(event.target.value);
    setCryptoCurrencyAmount(
      formatCurrency(
        currencyConvert(
          convertCurrencyAmount(event.target.value, usdCurrency),
          usdCurrency,
          currency,
        ),
        currency,
        false,
      ),
    );
  };

  const handleCreateTransaction = async () => {
    if (Number(cryptoCurrencyAmount) <= 0) {
      openErrorModal({ title: 'Oops', subtitle: 'Bet value should be greater than zero.' });
      return;
    }
    setDisabledSubmit(true);
    try {
      const data = {
        amount: convertCurrencyAmount(cryptoCurrencyAmount, currency),
        amount_currency: currency.symbol,
        currency: currency.symbol,
        end_currency: 'usd',
      };
      const transaction = await createTransaction(data);
      setDisabledSubmit(false);
      if (transaction.id) {
        history.push(`/funds/deposit/${currency.symbol}/${transaction.id}`);
      }
    } catch (e) {
      setDisabledSubmit(false);
      openErrorModal({ title: 'Oops', subtitle: getErrorMessage(e) });
    }
  };

  const handleCardClick = () => {
    if (collapsed) {
      onCardClick('');
    } else {
      onCardClick(currency.symbol);
    }
  };

  return (
    <Box className={classes.root}>
      {currency.symbol !== 'usdt' && (
        <Box
          onClick={handleCardClick}
          className={classes.deposit}
          display="flex"
          alignItems="center"
        >
          <Grid item xs={4} sm={4}>
            <Box>
              <img className={classes.walletImage} src={currency.wallet_image_url} alt="navigation" />
            </Box>
          </Grid>
          <Grid item xs={8} sm={8}>
            {currency.label}
          </Grid>
        </Box>
      )}
      <Box>
        {currency.symbol !== 'usdt' && collapsed && (
          <DepositGuide />
        )}
        {((currency.symbol === 'usdt') || (currency.symbol !== 'usdt' && collapsed)) && (
          <>
            <Box display="flex" justifyContent="flex-start" pl={5} mb={1} mt={3}>
              <InputCurrencyExchange
                currency={currency}
                cryptoCurrencyAmount={cryptoCurrencyAmount}
                usdCurrencyAmount={usdCurrencyAmount}
                onCryptoCurrencyAmountChange={handleCryptoCurrencyAmount}
                onUsdCurrencyAmountChange={handleUsdCurrencyAmount}
              />
            </Box>
            <Box mt={3} width="90%" m="auto">
              <OutlineButton disabled={disabledSubmit} onClick={handleCreateTransaction}>
                Continue
              </OutlineButton>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};
CryptoDepositCard.defaultProps = {
  currency: {},
  noBorder: false,
  collapsed: false,
  onCardClick: () => {},
};

CryptoDepositCard.propTypes = {
  history: PropTypes.object.isRequired,
  currency: PropTypes.object,
  collapsed: PropTypes.bool,
  noBorder: PropTypes.bool,
  onCardClick: PropTypes.func,
};

export default withRouter(CryptoDepositCard);
