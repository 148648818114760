import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { makeStyles } from '@material-ui/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles(({ palette }) => ({
  dialogTitle: {
    background: palette.cardColor,
    color: palette.secondaryColor,
    paddingBottom: 0,
    '& h2': {
      fontWeight: 'bold',
      fontSize: 20,
    },
  },
  dialogActions: {
    background: palette.cardColor,
  },
  dialogContent: {
    background: palette.cardColor,
    '& p': {
      color: palette.secondaryColor,
      lineHeight: 1.2,
    },
  },
  dialogButton: {
    background: 'none',
    boxShadow: 'none',
    '& span': {
      color: '#0091FF',
      fontWeight: 'bold',
    },
  },
  icon: {
    color: '#00D3DA',
    width: 15,
    height: 15,
    marginLeft: 10,
  },
}));

export default function DepositFundsDialog() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <InfoOutlinedIcon className={classes.icon} onClick={handleClickOpen} />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}> Funding Options</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText>
            ZenSports gives you a few different options for depositing funds into your account. You can deposit
            ZenSports own utility token called SPORTS, which is built on ICON&apos;s protocol. You can also deposit
            Bitcoin, USD, or ICX.
          </DialogContentText>
          <DialogContentText>
            If you choose to deposit SPORTS, Bitcoin, USDT (Tether), or ICX, we&apos;ll show you a temporary wallet
            address to send your funds to. That temporary wallet address is tied to your ZenSports account,
            and once funds are received at that temporary wallet address, your ZenSports account will
            automatically be credited with that amount. If you choose the Debit Card or Wire Transfer options for
            fiat, you&apos;ll need to first go through KYC/AML verification, and then you can make your deposit.
          </DialogContentText>
          <DialogContentText>
            Once you&apos;ve deposited funds, you can then bet and pay for anything in that currency. You can also
            exchange between currencies at any time using the &quot;Exchange&quot; feature on the Funds screen.
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={handleClose} className={classes.dialogButton}>
            GOT IT
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
