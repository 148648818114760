import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal } from '@material-ui/core';

import { useErrorModal, useShowErrorModal, useCloseModalDispatch } from 'hooks/redux/modal';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: palette.secondaryColor,
  },
  paper: {
    background: palette.cardColor,
    padding: '18px',
    width: 300,
  },
  title: {
    margin: 0,
    fontSize: '18px',
    textAlign: 'left',
    color: palette.secondaryColor,
  },
  button: {
    color: '#63C4C6',
    margin: 0,
    textAlign: 'right',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
}));

const ErrorModal = () => {
  const rootRef = React.useRef(null);
  const classes = useStyles();

  const showModal = useShowErrorModal();
  const modal = useErrorModal();
  const closeModal = useCloseModalDispatch();

  const handleCallback = () => {
    closeModal();
    modal.callback();
  };

  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={showModal}
      aria-labelledby="server-modal-title"
      aria-describedby="server-modal-description"
      className={classes.root}
      container={() => rootRef.current}
    >
      <div className={classes.paper}>
        <h2 id="server-modal-title" className={classes.title}>{modal.title}</h2>
        <p id="server-modal-description">
          {modal.subtitle}
        </p>
        <p onClick={handleCallback} className={classes.button}>{modal.buttonText}</p>
      </div>
    </Modal>
  );
};

export default ErrorModal;
