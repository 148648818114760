import React, { useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import { Box, Button, Typography } from '@material-ui/core';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import DebitCard from 'components/Deposit/DebitCard';

import { useAccountOverviewInfo, useDepositDebitDispatch } from 'hooks/redux/account';
import commonStyle from 'styles/common';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 15px',
  },
  add: {
    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.15)',
    borderRadius: '5px',
    background: palette.cardColor,
    color: palette.secondaryColor,
    minHeight: 40,
    font: '16px SofiaPro-Bold',
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
    padding: 10,
  },
  content: {
    marginTop: '10px',
    color: '#AEAEAE',
    font: '15px SofiaPro',
  },
}));

const DebitSubmit = () => {
  const classes = useStyles();
  const commonClasses = commonStyle();
  const [firstRender, setFirstRender] = useState(true);
  const [selectedDebitId, setSelectedDebitId] = useState(-1);
  const [selectedDepositAmount, setSelectedDepositAmount] = useState('');
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const overview = useAccountOverviewInfo();
  const depositDebit = useDepositDebitDispatch();

  const handleClick = useCallback((debit) => {
    setSelectedDebitId(debit.id);
    setSelectedDepositAmount('');
    if (firstRender) {
      setFirstRender(false);
    }
  }, [firstRender]);

  const handleClickAddCard = () => {
    // history.push('/funds/deposit/debit-card/add');
    window.location.href = '/funds/deposit/debit-card/add';
  };

  const handleSubmit = async () => {
    setDisabledSubmit(true);
    try {
      const data = {
        amount: Number(selectedDepositAmount),
        id: selectedDebitId,
      };
      depositDebit(data);
      setDisabledSubmit(false);
      // history.push('/funds/deposit/debit-card/payment');
      window.location.href = '/funds/deposit/debit-card/payment';
    } catch (e) {
      setDisabledSubmit(false);
      // @todo console.log(e);
    }
  };

  return (
    <Page>
      <Box className={classes.root}>
        <Box>
          <BackButton color={commonClasses.backBtn} />
        </Box>
        <Box textAlign="center" mb={4}>
          <Typography component="h3" className={classes.title}>
            Deposit Amount
          </Typography>
          <Typography component="h3" className={classes.content}>
            Tap on the Debit Card that you wish to use below, enter in the amount to deposit, and then tap the &#34;Submit Deposit&#34; button.
          </Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Button className={classes.add} onClick={handleClickAddCard}>
            Add Debit Card
          </Button>
        </Box>
        <Box mb={3}>
          {
            overview?.active_cards?.length > 0 && overview.active_cards.map((debit, index) => (
              <DebitCard
                item={debit}
                collapsed
                value={selectedDepositAmount}
                onClick={() => handleClick(debit)}
                handleChange={setSelectedDepositAmount}
                open={firstRender ? index === 0 : selectedDebitId === debit.id}
                key={debit.id}
              />
            ))
          }
        </Box>
        <OutlineButton disabled={disabledSubmit} onClick={handleSubmit}>
          Submit Deposit
        </OutlineButton>
      </Box>
    </Page>
  );
};

DebitSubmit.propTypes = {};

export default withRouter(DebitSubmit);
