import * as actionTypes from 'store/actionTypes';

const INITIAL_STATE = {
  isLoading: false,
  overview: {
    account_id: '',
    status: 'new',
    cards: [],
    bank_accounts: [],
    primetrust_exceptions: [],
    primetrust_kyc_failed: false,
    failed_kyc_attempts: 0,
  },
  profile: {
    country: {
      id: 0,
      name: '',
      country_code: '',
    },
    state: {
      id: 0,
      name: '',
      abbreviation: '',
    },
  },
  cardResourceToken: '',
  cardDeposit: {
    amount: 0,
    id: 0,
  },
  bankDeposit: {
    account_number: '',
    bank_phone: '',
    bank_address: '',
    beneficiary_address: '',
    credit_to: '',
    depository_bank_name: '',
    reference: '',
    routing_number: '',
    swift_code: '',
  },
  bankInstruction: {
    country: {
      id: 0,
      name: '',
      country_code: '',
    },
    funds_transfer_country: {
      id: 0,
      name: '',
      country_code: '',
    },
    account_name: '',
    bank_name: '',
    account_number: '',
    street_1: '',
    street_2: '',
    city: '',
    region: {
      id: 0,
      name: '',
      abbreviation: '',
    },
    postal_code: '',
    routing_number: '',
    isFurtherAcc: true,
    further_account_name: '',
    further_account_number: '',
    swift_code: '',
    iban: '',
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_ACCOUNT_OVERVIEW_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_ACCOUNT_OVERVIEW_SUCCESS:
      return {
        ...state,
        isLoading: false,
        overview: action.payload,
      };
    case actionTypes.GET_ACCOUNT_OVERVIEW_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.GET_CARD_TOKEN_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_CARD_TOKEN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        cardResourceToken: action.payload,
      };
    case actionTypes.GET_CARD_TOKEN_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.GET_BANK_DEPOSIT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_BANK_DEPOSIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        bankDeposit: {
          ...state.bankDeposit,
          ...action.payload,
        },
      };
    case actionTypes.GET_BANK_DEPOSIT_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.ADD_BANK_ACCOUNT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.ADD_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        bankInstruction: { ...action.payload },
      };
    case actionTypes.ADD_BANK_ACCOUNT_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.SET_BANK_INSTRUCTION_INFO:
      return {
        ...state,
        bankInstruction: {
          ...state.bankInstruction,
          ...action.payload,
        },
      };
    case actionTypes.DELETE_FUNDS_METHOD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.DELETE_FUNDS_METHOD_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.DELETE_FUNDS_METHOD_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.DEPOSIT_DEBIT_INFO:
      return {
        ...state,
        cardDeposit: action.payload,
      };
    case actionTypes.SET_PROFILE_COUNTRY:
      return {
        ...state,
        profile: {
          ...state.profile,
          country: action.payload,
          state: {
            id: 0,
            name: '',
          },
        },
      };
    case actionTypes.SET_PROFILE_STATE:
      return {
        ...state,
        profile: {
          ...state.profile,
          state: action.payload,
        },
      };
    default:
      return state;
  }
};
