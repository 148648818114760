import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import { Box, Typography } from '@material-ui/core';
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import InputDropDown from 'components/Form/InputDropDown';
import InputBox from 'components/Form/InputBox';

import {
  useOpenConfirmModalDispatch,
  useOpenSuccessModalDispatch,
  useOpenErrorModalDispatch,
  useCloseModalDispatch,
} from 'hooks/redux/modal';
import {
  useKycDocInfo,
  useSetKycDocInfoDispatch,
  useKycResourceToken,
  useUploadDocDispatch,
} from 'hooks/redux/kyc';
import { documentTypes } from 'utils/constants';
import CommonStyle from 'styles/common';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 15px',
  },
  line: {
    background: 'linear-gradient(45deg, #298EDA, #29D2D3)',
    height: '3px',
    marginLeft: '-27px',
    width: '90%',
  },
  topic: {
    font: '12px SofiaPro-SemiBold',
    color: palette.colorBtnTxt,
    textTransform: 'capitalize',
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
  },
  upload: {
    display: 'none',
  },
  uploadLabel: {
    display: 'block',
    marginTop: 10,
    border: `1px dashed ${palette.colorBtnTxt}`,
    borderRadius: '6px',
    color: palette.colorBtnTxt,
    font: '15px SofiaPro-Medium',
    letterSpacing: -0.2,
    padding: '17px 0px',
  },
  uploadText: {
    color: palette.placeholderColor,
  },
  headLabel: {
    textAlign: 'left',
    color: palette.secondaryColor,
    font: '16px SofiaPro-SemiBold',
  },
  labelColor: {
    color: palette.secondaryColor,
    font: '15px SofiaPro-Bold',
  },
  next: {
    marginTop: 20,
    width: '100%',
  },
}));

const Documentation = ({ history }) => {
  const classes = useStyles();
  const commonClasses = CommonStyle();
  const doc = useKycDocInfo();
  const setDocumentation = useSetKycDocInfoDispatch();
  const openConfirmModal = useOpenConfirmModalDispatch();
  const openErrorModal = useOpenErrorModalDispatch();
  const openSuccessModal = useOpenSuccessModalDispatch();
  const closeModal = useCloseModalDispatch();
  const uploadDoc = useUploadDocDispatch();
  const resourceToken = useKycResourceToken();

  const [taxId, setTaxId] = useState(doc.tax_id_number);
  const [documentType, setDocumentType] = useState(doc.documentType);
  const [frontID, setFrontID] = useState();
  const [backID, setBackID] = useState();

  const isResolve = history.location.state === 'resolve';

  const getBackPath = () => {
    if (isResolve) {
      return '/kyc/resolve';
    }
    return doc.country.country_code !== 'US' ? '/kyc/documentation/country' : '/kyc/documentation/tax';
  };

  const handleNext = async () => {
    setDocumentation({
      documentType,
      tax_id_number: taxId,
    });
    const route = isResolve ? '/kyc/resolve' : '/kyc/tos';
    history.push(route);
  };

  const handleUpload = async (e) => {
    try {
      const files = e.target.files || e.dataTransfer.files;
      const id = e.target.id || '';
      if (window.File && window.FileList && window.FileReader) {
        if (files.length === 1) {
          const content = 'You document image is uploading please keep in mind that '
            + 'it can take up to 30 seconds to complete the upload process';
          openConfirmModal({
            title: 'Uploading your image...',
            subtitle: content,
            agreeBtnText: 'OK',
            disagreeBtnText: '',
            callback: () => {},
          });
          if (id === 'front-id') {
            setFrontID(files[0].name);
          } else {
            setBackID(files[0].name);
          }
          const data = new FormData();
          data.append('file', files[0]);
          data.append('token', resourceToken);
          const result = await uploadDoc(data);
          if (id === 'front-id') {
            setDocumentation({
              uploaded_front_id: result.document_id,
            });
          } else {
            setDocumentation({
              uploaded_back_id: result.document_id,
            });
          }
          closeModal();
          openSuccessModal({
            title: 'Successfully uploaded!',
            subtitle: '',
            buttonText: 'Got it',
            nextPath: '',
          });
        }
      }
    } catch (err) {
      closeModal();
      openErrorModal({
        title: 'Upload Doc Error',
        subtitle: err.error,
        buttonText: 'Got it',
      });
    }
  };

  return (
    <Page>
      <Box className={classes.root} display="flex" flexDirection="column" flex={1}>
        <Box className={classes.line} mb={2} />
        <Box>
          <BackButton
            color={commonClasses.backBtn}
            page={getBackPath()}
          />
        </Box>
        <Box textAlign="center" mt={3} mb={1}>
          <Typography component="h3" className={classes.topic}>
            KYC/AML VERIFICATION FORM
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" flex={1} justifyContent="space-between">
          <Box textAlign="center">
            <Typography component="h3" className={classes.title}>
              Upload Documentation
            </Typography>
            <Box mt={6}>
              {
                Object.keys(doc.country).length !== 0 && (
                <InputDropDown
                  value={documentType}
                  type="input"
                  placeholder="Document Type"
                  handleSelect={(item) => setDocumentType(item)}
                  items={documentTypes}
                />
                )
              }
              {
                (Object.keys(doc.country).length !== 0 && doc.country.country_code !== 'US') && (
                  <Box mt={5}>
                    <InputBox
                      name="taxId"
                      label="Document ID Number"
                      onChange={(e) => setTaxId(e.target.value)}
                      value={taxId}
                    />
                  </Box>
                )
              }
              {
                Object.keys(documentType).length !== 0 && (
                  <>
                    <Box mt={3}>
                      <Box className={classes.headLabel}>
                        Front of ID
                      </Box>
                      <input
                        accept="image/*"
                        className={classes.upload}
                        id="front-id"
                        multiple
                        type="file"
                        onChange={handleUpload}
                      />
                      <label htmlFor="front-id" className={`${classes.uploadLabel} ${frontID && classes.labelColor}`}>
                        {
                          frontID || (
                            <>
                              <PhotoCameraOutlinedIcon className={classes.camera} />
                              <Typography className={classes.uploadText}>
                                Tap to Upload document
                              </Typography>
                            </>
                          )
                        }
                      </label>
                    </Box>
                    {
                      documentType.id !== 2 && (
                        <Box mt={3}>
                          <Box className={classes.headLabel}>
                            Back of ID
                          </Box>
                          <input
                            accept="image/*"
                            className={classes.upload}
                            id="back-id"
                            multiple
                            type="file"
                            onChange={handleUpload}
                          />
                          <label htmlFor="back-id" className={`${classes.uploadLabel} ${backID && classes.labelColor}`}>
                            {
                              backID || (
                                <>
                                  <PhotoCameraOutlinedIcon className={classes.camera} />
                                  <Typography className={classes.uploadText}>
                                    Tap to Upload document
                                  </Typography>
                                </>
                              )
                            }
                          </label>
                        </Box>
                      )
                    }
                  </>
                )
              }
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" className={classes.next}>
            <OutlineButton type="submit" onClick={handleNext}>
              {isResolve ? 'Done' : 'Next'}
            </OutlineButton>
          </Box>
        </Box>
      </Box>
    </Page>
  );
};

Documentation.propTypes = {
  history: PropTypes.object.isRequired,
};

const mapStateToProps = () => ({});

export default withRouter(connect(mapStateToProps, null)(Documentation));
