import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import {
  getOverviewAction,
  getCardResourceTokenAction,
  getBankDepositInfoAction,
  addBankAccountAction,
  deleteFundsMethodAction,
  setBankInstructionInfoAction,
  depositDebitAction,
} from 'store/actions/account';

export const useUserProfile = () => useSelector(({ account: { profile } }) => profile);

export const useCardResourceToken = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCardResourceTokenAction());
    // eslint-disable-next-line
  }, []);
  return useSelector(({ account: { cardResourceToken } }) => cardResourceToken);
};

export const useAccountOverviewInfo = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOverviewAction());
    // eslint-disable-next-line
  }, []);
  return useSelector(({ account: { overview } }) => ({
    ...overview,
    manual_review_required: overview.primetrust_exceptions?.findIndex((item) => item.code === 'manual_review_required') >= 0,
    active_bank_accounts: overview.bank_accounts?.filter((item) => !item.inactive),
    active_cards: overview.cards?.filter((item) => !item.inactive),
  }));
};

export const useBankDepositInfo = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBankDepositInfoAction());
    // eslint-disable-next-line
  }, []);
  return useSelector(({ account: { bankDeposit } }) => bankDeposit);
};

export const useCardDepositInfo = () => useSelector(({
  account: { cardDeposit },
}) => cardDeposit);

export const useBankInstructionInfo = () => useSelector(({
  account: { bankInstruction },
}) => bankInstruction);

export const useSetBankInstructionActionDispatch = () => {
  const dispatch = useDispatch();
  return (data) => dispatch(setBankInstructionInfoAction(data));
};

export const useGetOverviewActionDispatch = () => {
  const dispatch = useDispatch();
  return (data) => dispatch(getOverviewAction(data));
};

export const useAddBankAccountActionDispatch = () => {
  const dispatch = useDispatch();
  return (data) => dispatch(addBankAccountAction(data));
};

export const useDeleteFundsMethodActionDispatch = () => {
  const dispatch = useDispatch();
  return (data) => dispatch(deleteFundsMethodAction(data));
};

export const useDepositDebitDispatch = () => {
  const dispatch = useDispatch();
  return (data) => dispatch(depositDebitAction(data));
};
