import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Box,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import VList from 'components/VList';
import HistoryOrder from 'components/Exchange/HistoryOrder';

import { useOrdersList } from 'hooks/redux/exchange';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    display: 'flex',
    flex: 1,
    height: '73vh',
    flexDirection: 'column',
  },
  expandIcon: {
    color: '#74ebed',
    fontSize: '30px',
    marginLeft: '1px',
  },
  popover: {
    '& .MuiPopover-paper': {
      background: palette.inactiveBtnColor,
      '& li': {
        color: palette.secondaryColor,
      },
    },
  },
  orders: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  orderTypeText: {
    color: palette.secondaryColor,
    fontSize: '16px',
    fontWeight: '400',
    margin: '1rem 0 0.5rem 0',
  },
  cancelAll: {
    font: '16px SofiaPro-Medium',
    fontWeight: '450',
    margin: '1rem 0 0.5rem 0',
    color: '#00DDE1',
  },
  ordersList: {
    margin: '20px 0 10px 0',
    padding: '0 10px',
    boxShadow: '0 3px 10px 1px rgba(0,0,0,0.15)',
    borderRadius: '6px',
  },
  vListBox: {
    margin: '10px 0 10px',
    height: '100%',
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(({ palette }) => ({
  root: {
    color: 'black',
    '&:focus': {
      color: 'black',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: palette.common.white,
      },
    },
  },
}))(MenuItem);

const OrdersHistory = ({ market }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showOrder, setShowOrder] = useState('all');
  const [ordersList, setOrdersList] = useState([]);
  const [params, setParams] = useState();
  const [orgOrders, setOrgOrders] = useState(null);
  const classes = useStyles();

  const orders = useOrdersList(params);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleScroll = (event) => {
    if (params) {
      const { page } = params;
      if (((event.scrollHeight - event.clientHeight) === Math.round(event.scrollTop)) && ordersList.length >= (page + 1) * params.amount) {
        setParams({
          ...params,
          page: page + 1,
        });
      }
    }
  };

  useEffect(() => {
    if (showOrder) {
      if (showOrder === 'buy') {
        setParams({
          market_id: market.id,
          page: 0,
          amount: 25,
          open_closed: 'closed',
          is_buying: true,
        });
      } else if (showOrder === 'sell') {
        setParams({
          market_id: market.id,
          page: 0,
          amount: 25,
          open_closed: 'closed',
          is_buying: false,
        });
      } else {
        setParams({
          market_id: market.id,
          page: 0,
          amount: 25,
          open_closed: 'closed',
        });
      }
    }
    // eslint-disable-next-line
  }, [showOrder]);

  useEffect(() => {
    if (orgOrders === orders) {
      return;
    }
    if (params && params.page === 0) {
      setOrdersList([
        ...(orders || []),
      ]);
    } else {
      setOrdersList([
        ...ordersList,
        ...(orders || []),
      ]);
    }
    setOrgOrders(orders);
  }, [orders, orgOrders, ordersList, params]);

  return (
    <>
      <Box className={classes.root}>
        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          className={classes.popover}
          onClose={handleClose}
        >
          <StyledMenuItem onClick={() => { setShowOrder('all'); handleClose(); }}>
            All Orders
          </StyledMenuItem>
          <StyledMenuItem onClick={() => { setShowOrder('buy'); handleClose(); }}>
            Buy Orders
          </StyledMenuItem>
          <StyledMenuItem onClick={() => { setShowOrder('sell'); handleClose(); }}>
            Sell Orders
          </StyledMenuItem>
        </StyledMenu>
        <Box className={classes.orders}>
          <Box
            display="flex"
            alignItems="flex-end"
            aria-controls="customized-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <Typography className={classes.orderTypeText} variant="h1">
              {
                showOrder === 'all' ? 'All Orders' : showOrder === 'buy' ? 'Buy Orders' : 'Sell Orders'
              }
            </Typography>
            <ExpandMoreIcon
              className={classes.expandIcon}
            />
          </Box>
        </Box>
        <Box className={classes.vListBox}>
          {ordersList.length > 0 && (
            <VList
              onScroll={handleScroll}
              rowCount={ordersList.length}
              rowRender={(index) => (
                <HistoryOrder item={ordersList[index]} />
              )}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

OrdersHistory.propTypes = {
  market: PropTypes.object.isRequired,
};
const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(OrdersHistory);
