import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles({
  root: {
    padding: 6,
    background: '#00000010',
  },
});

const BackButton = ({ color, size, onClick }) => {
  const classes = useStyles();
  return (
    <IconButton className={classes.root} onClick={onClick}>
      <DeleteIcon style={{ fontSize: size, color }} />
    </IconButton>
  );
};

BackButton.defaultProps = {
  color: 'white',
  size: 18,
  onClick: () => {},
};

BackButton.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  onClick: PropTypes.func,
};

export default BackButton;
