import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { setPhotoAction } from 'store/actions/auth';

import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import config from 'utils/config';
import cameraIcon from 'assets/images/setting/camera.png';

const useStyles = makeStyles({
  avatarField: {
    textAlign: 'center',
    margin: '10px 0',
    '& span': {
      display: 'block',
      font: '14px SofiaPro-Bold',
      color: '#63C4C6',
    },
  },
  avatarCircle: {
    borderRadius: '50px',
    border: '2px solid #63C4C6',
    width: '60px',
    height: '60px',
    display: 'inline-block',
    marginBottom: '5px',
  },
  profileImage: {
    borderRadius: '60px',
    width: '60px',
    height: '60px',
  },
  defaultImage: {
    width: '30px',
    height: '30px',
    marginTop: '15px',
  },
});

const PublicProfilePhoto = ({ profileUrl, setProfileUrl, changeUrl }) => {
  const classes = useStyles();

  const onUploadFinish = (error, result) => {
    if (error) {
    //  console.log('Upload error: ', error);
    }
    if (result.event === 'success' && result.info?.secure_url) {
      changeUrl(result.info.secure_url);
      setProfileUrl(result.info.secure_url);
    }
  };

  const onUploadFile = () => {
    const cropWidget = window.cloudinary.createUploadWidget(
      {
        cloudName: config.cloudinaryCloudName,
        uploadPreset: config.cloudinaryUploadPreset,
        folder: 'widgetUpload',
        cropping: true,
      },
      onUploadFinish,
    );
    cropWidget.open();
  };

  return (
    <Box onClick={onUploadFile}>
      <Box className={classes.avatarField}>
        <div className={classes.avatarCircle}>
          <img
            src={profileUrl || cameraIcon}
            className={profileUrl === null ? classes.defaultImage : classes.profileImage}
            alt="camera"
          />
        </div>
        <span>Photo</span>
      </Box>
    </Box>
  );
};

PublicProfilePhoto.defaultProps = {
  profileUrl: '',
};

PublicProfilePhoto.propTypes = {
  profileUrl: PropTypes.string,
  setProfileUrl: PropTypes.func.isRequired,
  changeUrl: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  profileUrl: state.auth.user.profile_url,
});

const mapDispatchToProps = (dispatch) => ({
  setProfileUrl: (data) => dispatch(setPhotoAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PublicProfilePhoto);
