import React from 'react';
import { Box } from '@material-ui/core';
import MatchNode from 'components/Play/Tournament/View/MatchNode';
import PropTypes from 'prop-types';

const SingleElimination = ({ bracket, classes }) => {
  const bracketClasses = {
    a: 'roundOneBox',
    b: 'roundTwoBox',
    c: 'roundFinalBox',
  };

  const drawBrackets = (condition, className) => {
    const len = bracket?.matches?.length;

    return (
      <Box className={classes[className]}>
        {
            bracket?.matches?.map((round, index) => {
              const conditions = {
                a: (index < 4),
                b: (index >= 4 && index < len - 1),
                c: (index === len - 1),
              };
              if ((conditions[condition]) && (round?.users?.length > 0)) {
                return (
                  <MatchNode
                    player1={round?.users?.[0]}
                    player2={round?.users?.[1]}
                    winnerId={round?.winner_id}
                    id={round?.tournament_id}
                    matchId={round?.id}
                    key={round?.id}
                  />
                );
              }
              return null;
            })
          }
      </Box>
    );
  };

  return (
    <Box className={classes.singleTreeBox}>
      {Object.keys(bracketClasses).map((key) => <span key={key}>{drawBrackets(key, bracketClasses[key])}</span>)}
    </Box>
  );
};

SingleElimination.propTypes = {
  bracket: PropTypes.objectOf(PropTypes.any).isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default SingleElimination;
