import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';
import { Box, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import Page from 'components/Page/Dashboard';

import { getCardResourceTokenAction } from 'store/actions/account';
import config from 'utils/config';
import commonStyle from 'styles/common';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    flexDirection: 'column',
    flex: 1,
  },
  content: {
    textAlign: 'center',
    color: '#AEAEAE',
    font: '15px SofiaPro',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 12px',
  },
  widget: {
    '& h3': {
      textAlign: 'center',
      color: palette.secondaryColor,
    },
    '& iframe': {
      border: 'none !important',
    },
  },
}));

const AddDebitCard = ({ history, getResourceToken }) => {
  const classes = useStyles();
  const commonClasses = commonStyle();
  useEffect(() => {
    /* eslint-disable */
    const bootstrap = document.createElement('script');
    const debitscript = document.createElement('script');
    const targetElement = document.getElementById('credit_card_target');
    targetElement.innerHTML = '';

    const init = async () => {
      const resource = await getResourceToken();
      bootstrap.src = config.primeTrustBootstrap;
      debitscript.type = 'text/javascript';
      debitscript.innerHTML = `
      document.addEventListener('pt.bootstrap.ready', function() {
        var initializeEvent = new CustomEvent('pt.bootstrap.initialize', {
          detail: {
            app: 'credit-card',
            target: document.getElementById('credit_card_target'),
            resourceTokenHash: '${resource.token}'
          }
        });
        document.dispatchEvent(initializeEvent);
      });
      document.addEventListener("pt.app.ready", function() {
        // the credit card UI has been loaded and is ready to use
      });
      document.addEventListener("pt.app.verified", function(e) {
        // the user has successfully added their credit card information to the linked contact
        // e.detail is an object containing the keys: "contactId", "fundsTransferMethodId"
        console.log(e.detail);
        const element = document.getElementById("credit_card_target");
        const newDiv = document.createElement("h3");
        const newContent = document.createTextNode("Success! Your card has been submitted");
        newDiv.appendChild(newContent);
        element.innerHTML = '';
        element.appendChild(newDiv);
        
        setTimeout(() => {
           // window.location.href = '/funds/fiat-accounts/debit-cards';
           window.history.go(-1); 
        }, 3000);
      });
    `;
    };
    init();

    document.body.appendChild(bootstrap);
    document.body.appendChild(debitscript);
    return () => {
      document.body.removeChild(bootstrap);
      document.body.removeChild(debitscript);
      window.pt = null;
    };
    /* eslint-enable */
    // eslint-disable-next-line
  }, []);

  return (
    <Page>
      <Box className={classes.root} display="flex" flexDirection="column" flex={1}>
        <Box display="flex" flexDirection="row-reverse">
          <CloseIcon className={commonClasses.backBtn} onClick={() => history.goBack()} />
        </Box>
        <Box textAlign="center" mt={3}>
          <Typography component="h3" className={commonClasses.title2}>
            Add Debit/Credit Card
          </Typography>
          <span className={classes.content}>
            Add your Debit/Credit Card information below.
          </span>
        </Box>
        <Box mt={5} display="flex" flexDirection="column">
          <div id="credit_card_target" className={classes.widget} />
        </Box>
      </Box>
    </Page>
  );
};

AddDebitCard.propTypes = {
  history: PropTypes.object.isRequired,
  getResourceToken: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  getResourceToken: () => dispatch(getCardResourceTokenAction()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddDebitCard));
