import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { debounce } from 'lodash';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import BackButton from 'components/Buttons/BackButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import PlayerCard from 'components/Bet/PlayerCard';
import Loading from 'components/Loading/Box';
import VList from 'components/VList';
import SearchInput from 'components/Form/SearchInput';
import { useOpenErrorModalDispatch } from 'hooks/redux/modal';

import {
  useBet,
  useBetObjects,
  useSelectedLeague,
  useSelectedGames,
  useSelectedBetObject,
  useSetBetTypeDispatch,
  useSetBetObjectDispatch,
} from 'hooks/redux/bet';
import { getBettingObjectInfo } from 'utils/betting';
import commonStyle from 'styles/common';

const useStyles = makeStyles(() => ({
  line: {
    background: 'linear-gradient(45deg, #298EDA, #29D2D3)',
    height: '3px',
    marginLeft: '-27px',
    width: '70%',
  },
  content: {
    font: '15px SofiaPro-Regular',
    color: '#C2C2C2',
    letterSpacing: -0.2,
    marginTop: '9px',
  },
}));

const SelectTeamPlayer = ({ history, match, mode }) => {
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState();
  const [debounceSearch, setDebounceSearch] = useState('');
  const [selectedObjectId, setSelectedObjectId] = useState(0);

  const classes = useStyles();
  const commonClasses = commonStyle();
  const betting = useBet();
  const league = useSelectedLeague();
  const selectedGames = useSelectedGames();
  const selectedObject = useSelectedBetObject();
  const bettingObjects = useBetObjects(debounceSearch, options);
  const setBetObject = useSetBetObjectDispatch();
  const setBetType = useSetBetTypeDispatch();
  const openErrorModal = useOpenErrorModalDispatch();

  // eslint-disable-next-line
  const doDebounceSearch = useCallback(
    debounce((value) => setDebounceSearch(value), 500), [debounce, setDebounceSearch],
  );

  const handleNext = () => {
    if (selectedObjectId === 0) {
      openErrorModal({ title: 'Oops', subtitle: 'You must select at least one team or player before continuing.' });
      return;
    }
    setBetObject(
      bettingObjects.find((object) => Number(object.id) === selectedObjectId) || {},
    );
    if (mode === 'create') {
      setBetType({});
      history.push('/bets/create/select-bet-type');
    } else {
      history.push(`/bets/edit/${match.params.betId}/select-bet-type`);
    }
  };

  const handleSelectObjectId = useCallback((id, checked) => {
    if (checked) {
      setSelectedObjectId(Number(id));
    } else {
      setSelectedObjectId(0);
    }
  }, [setSelectedObjectId]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    doDebounceSearch(e.target.value);
  };

  useEffect(() => {
    if (selectedObject.id) {
      setSelectedObjectId(selectedObject.id);
    }
  }, [selectedObject]);

  useEffect(() => {
    const teamIds = [];
    const ids = [];
    const gameIds = [];
    selectedGames.forEach((game) => {
      gameIds.push(game.id);
      if (game.teams?.length) {
        game.teams.forEach((team) => {
          ids.push(`id:${team.id}`);
          teamIds.push(`betting_team_id:${team.id}`);
        });
      }
    });
    setOptions({
      hitsPerPage: 1000,
      page: 0,
      facets: [
        '*',
        'betting_league_id',
      ],
      facetFilters: [
        [
          `betting_league_id:${league.id}`,
        ],
        [
          'type:team',
          'type:player',
        ],
        [
          ...ids,
          ...teamIds,
        ],
      ],
      gameIds,
    });
  }, [league, selectedGames]);

  return (
    <>
      <Box className={commonClasses.root2}>
        <Box className={commonClasses.line2} mb={2} />
        <Box
          display="flex"
          justifyContent="center"
        >
          <BackButton color={commonClasses.backBtn} />
          <SearchInput
            handleChange={handleSearch}
            placeholder="e.g. NFL,hocky, Lakers…"
            value={search}
          />
        </Box>
        <Box className={commonClasses.vListWrapper}>
          {!betting.isLoading ? (
            <VList
              rowCount={bettingObjects.length}
              rowRender={(index) => (
                <>
                  {index === 0 && (
                    <Box textAlign="center" mb={4}>
                      {
                        mode === 'create' && (
                          <Typography component="p" className={commonClasses.topic2}>
                            STEP 4
                          </Typography>
                        )
                      }
                      <Typography component="h3" className={commonClasses.title2}>
                        Select Team/Player
                      </Typography>
                      <Typography variant="subtitle2" className={classes.content}>
                        Select which team or player this bet is for.
                      </Typography>
                    </Box>
                  )}
                  <PlayerCard
                    selectedId={selectedObjectId}
                    selectPlayer={handleSelectObjectId}
                    {...getBettingObjectInfo(bettingObjects[index])}
                  />
                </>
              )}
            />
          ) : (<Loading />)}
        </Box>
      </Box>
      <Box mt={3} className={commonClasses.continue}>
        <OutlineButton onClick={() => handleNext()}>
          Continue
        </OutlineButton>
      </Box>
    </>
  );
};

SelectTeamPlayer.defaultProps = {
  mode: 'create',
};

SelectTeamPlayer.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  mode: PropTypes.string,
};

export default withRouter(SelectTeamPlayer);
