import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import ArrowRight from '@material-ui/icons/ArrowRight';

const useStyles = makeStyles({
  root: (props) => ({
    backgroundImage: `url(${props.background})`,
    backgroundSize: 'cover',
    minHeight: 232,
    marginBottom: '8px',
    padding: '0 20px',
    borderRadius: '5px',
  }),
  title: {
    fontFamily: 'SofiaPro-Bold',
    color: 'white',
    fontSize: '32px',
    lineHeight: '30px',
    letterSpacing: -0.6,
    paddingBottom: '11px',
    textTransform: 'capitalize',
  },
  content: {
    fontFamily: 'SofiaPro-Bold',
    color: 'white',
    fontSize: '17px',
    lineHeight: '20px',
    letterSpacing: -0.3,
  },
  arrowIcon: {
    marginBottom: '-8px',
  },
});

const BetListCard = ({
  largeImage,
  title,
  content,
  onClick,
}) => {
  const classes = useStyles({
    background: largeImage,
  });
  return (
    <Box
      className={classes.root}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      onClick={onClick}
    >
      <Typography variant="h1" className={classes.title}>
        {title}
      </Typography>
      <Typography variant="subtitle1" className={classes.content}>
        View
        {' '}
        {content.toUpperCase()}
        {' '}
        bets
        <ArrowRight className={classes.arrowIcon} />
      </Typography>
    </Box>
  );
};

BetListCard.defaultProps = {
  largeImage: null,
  title: null,
  content: null,
  onClick: () => {},
};

BetListCard.propTypes = {
  largeImage: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  onClick: PropTypes.func,
};

export default BetListCard;
