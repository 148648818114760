import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  makeStyles,
  Typography,
  InputBase,
} from '@material-ui/core';

import InputCurrencyExchange from 'components/Form/InputCurrencyExchange';
import OutlineButton from 'components/Buttons/OutlineButton';

import { usdCurrency } from 'utils/constants';
import {
  convertCurrencyAmount, currencyConvert, formatCurrency, checkCryptoAddressPrefix,
} from 'utils/currency';
import { useMappedCurrencies, useWithdrawTransactionDispatch } from 'hooks/redux/currency';
import {
  useOpenErrorModalDispatch,
  useOpenConfirmModalDispatch,
  useOpenSuccessModalDispatch,
} from 'hooks/redux/modal';
import { getErrorMessage } from 'utils';

const useStyles = makeStyles(({ palette }) => ({
  root: ({ noBorder }) => ({
    padding: !noBorder ? '15px 28px' : '15px 8px',
    background: palette.cardColor,
    textAlign: 'left',
    boxShadow: !noBorder ? '0 3px 10px 1px rgba(0,0,0,0.15)' : '',
    borderRadius: !noBorder ? '6px' : '',
    flexDirection: 'column',
  }),
  subTitle: {
    color: palette.primaryColor,
    font: '14px SofiaPro-Bold',
    textAlign: 'center',
    marginTop: '20px',
  },
  withdrawAddress: {
    color: palette.primaryColor,
    font: '15px SofiaPro',
    textAlign: 'center',
    borderBottom: '1px solid #CCCCCC',
    '& input': {
      textAlign: 'center',
    },
  },
}));

const CryptoWithdrawCard = ({
  currency,
  noBorder,
}) => {
  const classes = useStyles({ noBorder });
  const [cryptoCurrencyAmount, setCryptoCurrencyAmount] = useState('');
  const [usdCurrencyAmount, setUsdCurrencyAmount] = useState('');
  const [withdrawAddress, setWithdrawAddress] = useState('');
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const withdrawTransaction = useWithdrawTransactionDispatch();
  const openSuccessModal = useOpenSuccessModalDispatch();
  const openErrorModal = useOpenErrorModalDispatch();
  const openConfirmModal = useOpenConfirmModalDispatch();
  const mappedCurrencies = useMappedCurrencies();

  const changeCryptoCurrencyAmount = (amount) => {
    setCryptoCurrencyAmount(amount);
    setUsdCurrencyAmount(formatCurrency(
      currencyConvert(
        convertCurrencyAmount(amount, currency),
        currency,
        usdCurrency,
      ),
      usdCurrency,
      false,
    ));
  };

  const changeUsdCurrencyAmount = (amount) => {
    setUsdCurrencyAmount(amount);
    setCryptoCurrencyAmount(formatCurrency(
      currencyConvert(
        convertCurrencyAmount(amount, usdCurrency),
        usdCurrency,
        currency,
      ),
      currency,
      false,
    ));
  };

  const handleCryptoCurrencyAmount = (event) => {
    changeCryptoCurrencyAmount(event.target.value);
  };

  const handleUsdCurrencyAmount = (event) => {
    changeUsdCurrencyAmount(event.target.value);
  };

  const handleWithdrawTransaction = async () => {
    if (Number(cryptoCurrencyAmount) <= 0) {
      openErrorModal({
        title: 'Oops!',
        subtitle: 'Withdraw amount should be greater than zero.',
        buttonText: 'Got it',
      });
      return;
    }
    if (withdrawAddress.length <= 0) {
      openErrorModal({
        title: 'Oops!',
        subtitle: 'Wallet address should not be empty.',
        buttonText: 'Got it',
      });
      return;
    }
    if (!checkCryptoAddressPrefix(withdrawAddress, currency)) {
      let message = '';
      if (currency.symbol.toLowerCase() === 'btc') {
        message = 'A valid Bitcoin wallet address starts with a 1 or 3. Please make sure your BTC wallet address is correct.';
      }
      if (currency.symbol.toLowerCase() === 'sports') {
        message = 'A valid SPORTS wallet address starts with a hx. Please make sure your SPORTS wallet address is correct.';
      }
      if (currency.symbol.toLowerCase() === 'icx') {
        message = 'A valid ICONex wallet address starts with a hx. Please make sure your ICONex wallet address is correct.';
      }
      if (currency.symbol.toLowerCase() === 'usdt') {
        message = `Please note that we only support wallet addresses for USDT that are on the Ethereum blockchain (not Omni).
         If you don't already have an Ethereum wallet address, you can tap below to create one.`;
      }
      openErrorModal({ title: 'Oops!', subtitle: message, buttonText: 'Got it' });
      return;
    }
    const data = {
      amount: convertCurrencyAmount(cryptoCurrencyAmount, currency),
      amount_currency: currency.symbol,
      currency: currency.symbol,
      address: withdrawAddress,
    };
    let withdrawFeeAmount = '';
    let balance = '';
    const withdrawAmount = Number(cryptoCurrencyAmount).toLocaleString('en-US');
    if (currency.symbol.toLowerCase() === 'usdt') {
      withdrawFeeAmount = formatCurrency(currency.withdrawal_fee, mappedCurrencies[currency.symbol], true, false);
      balance = formatCurrency(currency.balance, mappedCurrencies[currency.symbol], true, false);
    } else {
      withdrawFeeAmount = formatCurrency(currency.withdrawal_fee, mappedCurrencies[currency.symbol]);
      balance = formatCurrency(currency.balance, mappedCurrencies[currency.symbol]);
    }

    let messageConfirm = `You indicated that you want to withdraw ${withdrawAmount} ${currency.symbol.toUpperCase()} to the following
    <br><br>${currency.symbol.toUpperCase()} wallet address:  <br><span>${withdrawAddress}</span><br><br>
    Please verify that this address is correct, as this is the address that your funds will be sent to.
    If you entered the wrong address, your funds will be lost forever, so please make 100% sure this is correct
    before proceeding.`;
    if (currency.withdrawal_fee > 0) {
      if (currency.symbol.toLowerCase() === 'usdt') {
        messageConfirm += `<br><br>A convenience fee of <span><b>${withdrawFeeAmount}</b></span> 
        will be deducted from your wallet to account for gas costs.<br><br>`;
      } else {
        messageConfirm += `<br><br>A convenience fee of <span><b>${withdrawFeeAmount}</b></span> 
        will be deducted from your wallet to account for gas costs.<br><br>`;
      }
    }
    openConfirmModal({
      title: 'Please Confirm',
      subtitle: messageConfirm,
      agreeBtnText: 'Yes, Withdraw Funds',
      disagreeBtnText: 'No, Not Yet',
      callback: async () => {
        setDisabledSubmit(false);
        try {
          await withdrawTransaction(data);
          setDisabledSubmit(false);
          openSuccessModal({
            title: 'Withdrawal Request Received!',
            subtitle: `We've received your withdrawal request, and will process this within 24 business hours,
                       excluding weekends and U.S. holidays. In the meantime, the funds have been removed from
                       your ZenSports wallet. Want your funds sooner? Withdrawals in SPORTS tokens get processed within
                       12 business hours as an added perk for using SPORTS tokens. 😎`,
            buttonText: 'Got it',
            nextPath: '/funds',
          });
        } catch (e) {
          setDisabledSubmit(false);
          let message = getErrorMessage(e);
          if (message === 'Looks like you don\'t enough funds in your Available Balance to cover this withdrawal amount. Try a lower amount.') {
            message = `Looks like you don't have enough funds in your Available Balance for this wallet to cover the amount of this withdrawal 
plus the withdrawal fee. You're trying to withdraw ${withdrawAmount} ${currency.symbol.toUpperCase()} plus cover the Withdrawal Fee of 
${withdrawFeeAmount}, and you only have ${balance} in your wallet. Please reduce your withdrawal amount to cover the cost of both the withdrawal 
amount PLUS the withdrawal fee.`;
          }
          openErrorModal({ title: 'Oops', subtitle: message });
        }
      },
    });
  };

  return (
    <>
      <Box mt={2} display="flex" className={classes.root}>
        <Box mr={3}>
          <InputCurrencyExchange
            currency={currency}
            cryptoCurrencyAmount={cryptoCurrencyAmount}
            usdCurrencyAmount={usdCurrencyAmount}
            onCryptoCurrencyAmountChange={handleCryptoCurrencyAmount}
            onUsdCurrencyAmountChange={handleUsdCurrencyAmount}
          />
        </Box>
        <Typography className={classes.subTitle}>
          {currency.symbol.toUpperCase()}
          {' Wallet address to send funds to'}
        </Typography>
        <Box mt={2}>
          <InputBase
            fullWidth
            autoComplete="off"
            className={classes.withdrawAddress}
            value={withdrawAddress}
            name="address"
            placeholder="Wallet Address"
            onChange={(e) => setWithdrawAddress(e.target.value)}
          />
        </Box>
      </Box>
      <Box mt={3} width="90%" m="auto">
        <OutlineButton disabled={disabledSubmit} onClick={handleWithdrawTransaction}>
          Withdraw Funds
        </OutlineButton>
      </Box>
    </>
  );
};

CryptoWithdrawCard.defaultProps = {
  noBorder: false,
};

CryptoWithdrawCard.propTypes = {
  noBorder: PropTypes.bool,
  currency: PropTypes.object.isRequired,
};

export default CryptoWithdrawCard;
