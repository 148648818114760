import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@material-ui/core';
import Circle from '@material-ui/icons/PanoramaFishEye';
import CheckCircle from '@material-ui/icons/CheckCircle';

import BlankImage from 'assets/images/profile.png';

const PrimaryCheckbox = withStyles({
  root: {
    color: '#979797',
    '&.Mui-checked': {
      color: '#63C4C6',
    },
  },
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles(({ palette }) => ({
  photo: {
    width: '56px',
    height: '56px',
    border: 'none',
    borderRadius: '50%',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      width: '100%',
      height: 'auto',
    },
    '&:nth-child(2)': {
      marginTop: '20px',
      marginLeft: '-20px',
    },
  },
  time: {
    fontFamily: 'SofiaPro',
    font: '14px SofiaPro-Black',
    color: '#a4a4a4',
    letterSpacing: -0.1,
  },
  content: {
    color: palette.secondaryColor,
    font: '19px SofiaPro-SemiBold',
    marginBottom: 5,
  },
  checkBox: {
    margin: '0px',
  },
}));

const GameCard = ({
  image1,
  image2,
  content,
  time,
  id,
  selectedIds,
  selectGame,
}) => {
  const classes = useStyles();
  return (
    <Box alignItems="center" display="flex" justifyContent="space-between" mb={4}>
      <Box display="flex">
        <Box className={classes.photo}>
          <img src={image1 || BlankImage} alt="Player1" />
        </Box>
        <Box className={classes.photo}>
          <img src={image2 || BlankImage} alt="Player2" />
        </Box>
      </Box>
      <Box pl={2} flex={1}>
        <Typography variant="subtitle1" className={classes.content}>
          {content}
        </Typography>
        <Typography variant="subtitle1" className={classes.time}>
          {time}
        </Typography>
      </Box>
      <FormControlLabel
        control={(
          <PrimaryCheckbox
            icon={<Circle style={{ fontSize: 32, color: '#CCCCCC' }} />}
            checkedIcon={<CheckCircle style={{ fontSize: 32 }} />}
            name="checkedH"
            size="medium"
            checked={selectedIds.indexOf(id) >= 0}
            value={id}
            onChange={(e) => selectGame(e.currentTarget.value, e.target.checked)}
          />
        )}
        label=""
        className={classes.checkBox}
      />
    </Box>
  );
};

GameCard.defaultProps = {
  image1: null,
  image2: null,
  content: null,
  time: null,
  id: 0,
  selectedIds: [],
  selectGame: () => {},
};

GameCard.propTypes = {
  image1: PropTypes.string,
  image2: PropTypes.string,
  content: PropTypes.string,
  time: PropTypes.string,
  id: PropTypes.number,
  selectedIds: PropTypes.array,
  selectGame: PropTypes.func,
};

export default GameCard;
