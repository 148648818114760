import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import { Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import Page from 'components/Page/Dashboard';
import OutlineButton from 'components/Buttons/OutlineButton';
import Pending from 'components/KYC/Pending';
import ManualPending from 'components/KYC/ManualPending';
import Failed from 'components/KYC/Failed';

import { useAccountOverviewInfo } from 'hooks/redux/account';
import CommonStyle from 'styles/common';

const useStyles = makeStyles(() => ({
  root: {
    padding: '0 15px',
  },
  body: {
    flex: 1,
    overflow: 'auto',
  },
}));

const Status = ({ history }) => {
  const classes = useStyles();
  const commonClasses = CommonStyle();
  const overview = useAccountOverviewInfo();
  return (
    <Page>
      <Box className={classes.root} display="flex" flexDirection="column" flex={1}>
        <Box display="flex" justifyContent="flex-end">
          <CloseIcon className={commonClasses.backBtn} onClick={() => history.goBack()} />
        </Box>
        <Box className={classes.body}>
          { !overview.primetrust_kyc_failed && (<Pending />)}
          { overview.primetrust_kyc_failed && (
            <>
              {!overview.primetrust_exceptions ? (
                <ManualPending />
              ) : (
                <Failed exceptions={overview.primetrust_exceptions} />
              )}
            </>
          )}
        </Box>
        { !overview.primetrust_exceptions && (
          <OutlineButton onClick={() => history.push('/kyc/view')}>
            View KYC Details
          </OutlineButton>
        )}
        { overview.primetrust_exceptions && (
          <OutlineButton onClick={() => history.push('/kyc/resolve')}>
            Resolve KYC Issues
          </OutlineButton>
        )}
      </Box>
    </Page>
  );
};

Status.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(Status);
