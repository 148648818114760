import { useDispatch, useSelector } from 'react-redux';
import {
  selectCurrencyAction,
  getTransactionStatusAction,
  getTransactionDetailAction,
  createTransactionAction,
  withdrawTransactionAction,
  hideFundsGuideAction,
} from 'store/actions/currency';
import { usdCurrency } from 'utils/constants';
import { currencyConvert } from 'utils/currency';

export const useCurrencyIsLoading = () => useSelector(({ currency: { isLoading } }) => isLoading);

export const useCurrency = () => useSelector(({
  currency,
}) => currency);

export const useCurrencies = () => useSelector(({
  currency: { currencies },
}) => currencies);

export const useShowFundsGuide = () => useSelector(({
  currency,
}) => currency.show_funds_guide);

export const useHideFundsGuideDispatch = () => {
  const dispatch = useDispatch();
  return () => dispatch(hideFundsGuideAction());
};

export const useMappedCurrencies = () => {
  const data = {};
  return useSelector(
    ({ currency: { currencies } }) => {
      currencies.forEach((currency) => {
        data[currency.symbol] = currency;
      });
      return data;
    },
  );
};

export const useTransaction = () => useSelector(({
  currency: { transaction },
}) => transaction);

export const useSelectedCurrency = () => useSelector(({
  currency,
}) => {
  if (!currency.selected_currency_symbol) {
    const sortedCurrencies = currency.currencies.sort(
      (a, b) => currencyConvert(b.balance, b, usdCurrency) - currencyConvert(a.balance, a, usdCurrency),
    );
    if (sortedCurrencies.length) {
      return sortedCurrencies[0].symbol;
    }
    return 'sports';
  }
  return currency.selected_currency_symbol;
});

export const useSelectCurrencyDispatch = () => {
  const dispatch = useDispatch();
  return (symbol) => dispatch(selectCurrencyAction(symbol));
};

export const useCreateTransactionDispatch = () => {
  const dispatch = useDispatch();
  return (data) => dispatch(createTransactionAction(data));
};

export const useWithdrawTransactionDispatch = () => {
  const dispatch = useDispatch();
  return (data) => dispatch(withdrawTransactionAction(data));
};

export const useTransactionStatusDispatch = () => {
  const dispatch = useDispatch();
  return (data) => dispatch(getTransactionStatusAction(data));
};

export const useTransactionDetailDispatch = () => {
  const dispatch = useDispatch();
  return (data) => dispatch(getTransactionDetailAction(data));
};
