import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/styles';
import { Box, Typography } from '@material-ui/core';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import InputBox from 'components/Form/InputBox';

import { useKycDocInfo, useSetKycDocInfoDispatch } from 'hooks/redux/kyc';
import CommonStyle from 'styles/common';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 15px',
  },
  line: {
    background: 'linear-gradient(45deg, #298EDA, #29D2D3)',
    height: '3px',
    marginLeft: '-27px',
    width: '80%',
  },
  topic: {
    font: '12px SofiaPro-SemiBold',
    color: palette.colorBtnTxt,
    textTransform: 'capitalize',
  },
  title: {
    font: '30px SofiaPro-Bold',
    lineHeight: '30px',
    marginBottom: '10px',
    color: palette.secondaryColor,
  },
  content: {
    textAlign: 'center',
    color: '#AEAEAE',
    font: '15px SofiaPro',
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'space-between',
  },
}));

const DocumentationState = ({ history }) => {
  const classes = useStyles();
  const commonClasses = CommonStyle();
  const doc = useKycDocInfo();
  const country = doc.country.name;
  const setDocumentation = useSetKycDocInfoDispatch();
  const isResolve = history.location.state === 'resolve';
  return (
    <Page>
      <Box className={classes.root} display="flex" flexDirection="column" flex={1}>
        <Box className={classes.line} mb={2} />
        <Box>
          <BackButton color={commonClasses.backBtn} page={isResolve ? '/kyc/resolve' : '/kyc/documentation/state'} />
        </Box>
        <Box textAlign="center" mt={3} mb={1}>
          <Typography component="h3" className={classes.topic}>
            KYC/AML VERIFICATION FORM
          </Typography>
        </Box>
        <Box textAlign="center" mb={6}>
          <Typography component="h3" className={classes.title}>
            Tax Identification Number
          </Typography>
          <span className={classes.content}>
            Enter your Tax ID, this is often your social security number.
          </span>
        </Box>
        <Formik
          initialValues={{
            taxId: doc.tax_id_number,
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            setDocumentation({
              tax_id_number: values.taxId,
            });
            const route = isResolve ? '/kyc/resolve' : '/kyc/documentation/info';
            history.push(route);
          }}
          validationSchema={Yup.object().shape({
            taxId: country === 'United States of America'
              ? Yup.string()
                .min(9, 'Enter a valid 9 digit Tax ID')
                .max(9, 'Enter a valid 9 digit Tax ID')
                .required('Required')
              : Yup.string().required('Required'),
          })}
        >
          {({
            errors,
            touched,
            values,
            handleChange,
            handleBlur,
            isSubmitting,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.form}>
              <Box className={classes.field}>
                <InputBox
                  label="Tax ID"
                  name="taxId"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.taxId}
                  errors={errors}
                  touched={touched}
                />
              </Box>
              <Box display="flex" className={commonClasses.continue}>
                <OutlineButton type="submit" disabled={isSubmitting}>
                  {isResolve ? 'Done' : 'Next'}
                </OutlineButton>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Page>
  );
};

DocumentationState.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(DocumentationState);
