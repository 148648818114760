import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Typography,
} from '@material-ui/core';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import Toast from 'components/Toast';
import referralImage from 'assets/images/referral/referral.png';
import copyImage from 'assets/images/referral/copy.png';
import shareImage from 'assets/images/referral/share.png';
import viewImage from 'assets/images/referral/view.png';
import { useAuthUser } from 'hooks/redux/user';
import { shareLinks, isStandalone } from 'utils';
import CommonStyle from 'styles/common';

const items = [
  {
    action: 'copy',
    path: '',
    label: 'Copy Referral Bonus Code',
    icon: copyImage,
  },
  {
    action: 'share',
    path: '',
    label: 'Sharing using Pre-Populated Content',
    icon: shareImage,
  },
  {
    action: 'link',
    path: '/more/referral-bonus/view',
    label: 'View Bonuses',
    icon: viewImage,
  },
];

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 15px',
  },
  title: {
    font: '12px SofiaPro',
    color: '#29CED3',
    textTransform: 'capitalize',
  },
  subtitle: {
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
    padding: 10,
  },
  clearfix: {
    overflow: 'auto',
  },
  floatBtn: {
    float: 'left',
    margin: '10px',
  },
  content: {
    marginTop: '10px',
    marginBottom: '20px',
    color: '#AEAEAE',
    font: '15px SofiaPro',
  },
  bonusTitle: {
    color: palette.secondaryColor,
  },
  bonus: {
    font: '16px SofiaPro-Bold',
    color: palette.secondaryColor,
  },
  card: {
    marginTop: '10px',
    borderRadius: '6px',
    boxShadow: '0 3px 10px 0 rgba(0,0,0,0.15)',
    padding: '10px 10px',
    color: palette.secondaryColor,
    font: '18px SofiaPro-Bold',
    position: 'relative',
    background: palette.cardColor,
    overflow: 'hidden',
  },
  help: {
    color: '#29CED3',
  },
}));

const ReferralBonus = ({ history }) => {
  const classes = useStyles();
  const commonClasses = CommonStyle();
  const user = useAuthUser();
  const [showToast, setShowToast] = useState(false);

  const handleClick = (item) => {
    if (item.action === 'copy') {
      const copyData = (e) => {
        e.preventDefault();
        e.clipboardData.setData('text/plain', user.referral_code);
        document.removeEventListener('copy', copyData);
      };
      document.addEventListener('copy', copyData);
      document.execCommand('copy');
      setShowToast(true);
    } else if (item.action === 'share') {
      shareLinks({
        title: 'Sharing',
        text: `Check out this new sports betting app that I've been using called ZenSports.
Peer-to-Peer sports betting with no bookmaker, as well as Daily Fantasy Sports contests.
Download the app at the link below and get a Free Play Bonus of $500 in SPORTS tokens if
you enter in my code when signing up: ${user.referral_code}`,
        url: 'https://www.zensports.com/',
      });
    } else {
      history.push(item.path);
    }
  };
  return (
    <>
      <Page>
        <Box className={classes.root}>
          <Box className={classes.clearfix}>
            <div className={classes.floatBtn}>
              <BackButton color={commonClasses.backBtn} />
            </div>
          </Box>
          <Box style={{ marginBottom: '0px' }} textAlign="center" mb={4} xs={12}>
            <Typography component="h3" className={classes.title}>
              REFERRAL BONUSES
            </Typography>
            <Typography component="h3" className={classes.subtitle}>
              Share your love of Zensports and earn money
            </Typography>
            <img src={referralImage} alt="referral" />
            <Typography component="h3" className={classes.content}>
              Introduce friends, family, and others in your network to ZenSports,
              and you’ll earn a 30% Commission on their Betting Fees paid for the first 6
              months that they’re a customer. And they’ll receive a Free Play Bonus worth $500 in SPORTS tokens
              when they sign up with your Referral Code (see below). It’s a win-win!
            </Typography>
            <Typography component="h3" className={classes.bonusTitle}>
              My Referral Bonus Code:
              {' '}
              <span className={classes.bonus}>{user.referral_code}</span>
            </Typography>
          </Box>
          <Box>
            {items.map((item) => (
              <Box
                key={item.action}
                className={classes.card}
                display="flex"
                alignItems="center"
                onClick={() => handleClick(item)}
              >
                <Box mr={2}>
                  <img src={item.icon} alt="bonus" />
                </Box>
                {item.label}
              </Box>
            ))}
          </Box>
          <Box mt={2} mb={2} display="flex" justifyContent="center">
            <a
              href="https://support.zensports.com/en/articles/3381674-referral-bonuses-when-you-tell-the-world-about-zensports"
              className={classes.help}
              target={isStandalone ? '_blank' : '_self'}
            >
              More details on How It Works?
            </a>
          </Box>
        </Box>
      </Page>
      <Toast show={showToast} setShow={setShowToast}>
        Copied
      </Toast>
    </>
  );
};

ReferralBonus.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(ReferralBonus);
