import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import SelectCountry from 'components/SelectCountry';

import { setBankInstructionInfoAction } from 'store/actions/account';
import CommonStyle from 'styles/common';

const WithdrawCountry = ({
  instructionCountry,
  setWireInstructionInfo,
}) => {
  const commonClasses = CommonStyle();
  const history = useHistory();
  const handleSelectCountry = (country) => {
    if (country.id !== instructionCountry.id) {
      setWireInstructionInfo({
        country,
        region: {
          id: 0,
          name: '',
        },
      });
    }
    history.push('/funds/withdraw/wire-transfer/instructions');
  };

  return (
    <Page>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <BackButton color={commonClasses.backBtn} page="/funds/withdraw/wire-transfer/instructions" />
      </Box>
      <SelectCountry
        handleSelect={handleSelectCountry}
        countryId={instructionCountry.id}
      />
    </Page>
  );
};

WithdrawCountry.defaultProps = {
  instructionCountry: { id: 0 },
};

WithdrawCountry.propTypes = {
  instructionCountry: PropTypes.object,
  setWireInstructionInfo: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  instructionCountry: state.account.bankInstruction?.country,
});

const mapDispatchToProps = (dispatch) => ({
  setWireInstructionInfo: (data) => dispatch(setBankInstructionInfoAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawCountry);
