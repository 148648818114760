import * as actionTypes from 'store/actionTypes';

const INITIAL_STATE = {
  isLoading: false,
  currency: null,
  list: [],
  single: {},
  excludedList: [],
  resultList: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_PARLAY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_PARLAY_SUCCESS:
      return {
        ...state,
        single: action.payload.data,
        isLoading: false,
      };
    case actionTypes.GET_PARLAY_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.SUBMIT_PARLAY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.SUBMIT_PARLAY_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.SUBMIT_PARLAY_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.SET_RESULT_PARLAY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.SET_RESULT_PARLAY_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.SET_RESULT_PARLAY_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.EXCLUDE_BET_PARLAY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.EXCLUDE_BET_PARLAY_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.EXCLUDE_BET_PARLAY_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.EXCLUDE_PARLAY_ITEMS:
      return {
        ...state,
        excludedList: action.payload,
      };
    case actionTypes.SET_RESULT_PARLAY_ITEMS:
      return {
        ...state,
        resultList: action.payload,
      };
    case actionTypes.ADD_PARLAY_ITEM:
      return {
        ...state,
        list: state.list.findIndex((item) => item.id === action.payload?.id) === -1
          ? [...state.list, action.payload] : state.list,
        currency: state.list.length === 0 ? action.payload.currency : state.currency,
      };
    case actionTypes.DELETE_PARLAY_ITEM:
      const filteredList = state.list?.filter((item) => item.id !== action.payload.id);
      return {
        ...state,
        list: filteredList,
        currency: filteredList.length === 0 ? null : state.currency,
      };
    case actionTypes.CLEAR_PARLAY_ITEMS:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
