import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Box, Typography } from '@material-ui/core';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import SmallBetListCard from 'components/Bet/BetListSmallCard';
import Loading from 'components/Loading/Box';
import SearchInput from 'components/Form/SearchInput';

import { getLeagueInfo } from 'utils/betting';
import { useLeagues, useBet, useSetLeagueDispatch } from 'hooks/redux/bet';
import CommonStyle from 'styles/common';

const SelectLeague = ({ history }) => {
  const [search, setSearch] = useState('');
  const [selectedLeagues, setSelectedLeagues] = useState([]);
  const commonClasses = CommonStyle();

  const betting = useBet();
  const leagues = useLeagues();
  const setLeague = useSetLeagueDispatch();

  const handleSearch = (event) => {
    setSearch(event.target.value);
    setSelectedLeagues(
      leagues.filter((league) => league.name.toLowerCase().includes(
        event.target.value.toLowerCase(),
      )),
    );
  };

  const handleNext = useCallback((type, league) => {
    setLeague(league);
    if (type === 'group') {
      history.push('/bets/create/select-esport');
    } else {
      history.push('/bets/create/select-time-period');
    }
  }, [history, setLeague]);

  useEffect(() => {
    if (leagues.length) {
      setSelectedLeagues(leagues);
    }
  }, [leagues]);

  return (
    <Page>
      <Box className={commonClasses.root}>
        <Box className={commonClasses.line} mb={2} />
        <Box
          display="flex"
          justifyContent="center"
        >
          <BackButton color={commonClasses.backBtn} />
          <SearchInput
            handleChange={handleSearch}
            placeholder="e.g. NFL,hocky, Lakers…"
            value={search}
          />
        </Box>
        <Box
          textAlign="center"
          mt={3}
          mb={4}
        >
          <Typography variant="subtitle2" style={{ marginBottom: '16px' }} className={commonClasses.topic}>
            STEP 1
          </Typography>
          <Typography variant="h1" className={commonClasses.title}>
            Choose League
          </Typography>
          <Typography variant="subtitle2" className={commonClasses.content}>
            Choose a sport or league from the menu list below, or search for a team, player, league, or
            sport in the search box.
          </Typography>
        </Box>
        {!betting.isLoading ? selectedLeagues.map((item) => (
          <SmallBetListCard
            key={item.name}
            onClick={() => handleNext(item.type, item)}
            {...getLeagueInfo(item)}
          />
        )) : (<Loading />)}
      </Box>
    </Page>
  );
};

SelectLeague.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(SelectLeague);
