import React, { useCallback, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { debounce } from 'lodash';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Page from 'components/Page/Dashboard';
import Loading from 'components/Loading/Box';
import BackButton from 'components/Buttons/BackButton';
import SearchInput from 'components/Form/SearchInput';
import FavoriteCard from 'components/Bet/FavoriteCard';
import FavoriteAddingCard from 'components/Bet/FavoriteAddingCard';
import VList from 'components/VList';

import {
  useUserFavorites,
  useSetFavorites,
  useGetMe,
  useAuthIsLoading,
} from 'hooks/redux/user';
import { useBet, useBetObjects } from 'hooks/redux/bet';
import { useOpenErrorModalDispatch } from 'hooks/redux/modal';
import { getFavoritesInfo, getBettingObjectInfo } from 'utils/betting';
import commonStyle from 'styles/common';
import { getErrorMessage } from 'utils';

const useStyles = makeStyles(({ palette }) => ({
  title: {
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
  },
  subtitle: {
    marginTop: '10px',
    font: '15px SofiaPro',
    color: '#aeaeae',
    letterSpacing: '-0.3px',
    textAlign: 'center',
  },
}));

const EditFavorites = () => {
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState();
  const [debounceSearch, setDebounceSearch] = useState('');
  const [selectedId, setSelectedId] = useState(0);

  const classes = useStyles();
  const commonClasses = commonStyle();
  const betting = useBet();
  const favorites = useUserFavorites();
  const isAuthLoading = useAuthIsLoading();
  const bettingObjects = useBetObjects(debounceSearch, options);
  const openErrorModal = useOpenErrorModalDispatch();
  const setFavorites = useSetFavorites();
  const getMe = useGetMe();
  // eslint-disable-next-line
  const doDebounceSearch = useCallback(
    debounce((value) => setDebounceSearch(value), 500), [debounce, setDebounceSearch],
  );

  const handleSetFavorite = useCallback(async (id, checked, type) => {
    if (checked) {
      try {
        setSelectedId(Number(id));
        const items = [];
        favorites?.forEach((favorite) => {
          if (favorite.object_id !== id) {
            items.push({
              object_type: favorite.object_type,
              object_id: favorite.object_id,
            });
          }
        });
        items.push({
          object_type: type,
          object_id: id,
        });
        await setFavorites({
          favorites: items,
        });
        getMe(false);
        setSearch('');
      } catch (e) {
        openErrorModal({ title: 'Oops', subtitle: getErrorMessage(e) });
      }
    }
  }, [favorites, setFavorites, getMe, openErrorModal]);

  const handleRemoveFavorite = useCallback(async (id) => {
    if (id) {
      try {
        const items = favorites?.filter((favorite) => favorite.object_id !== id);
        await setFavorites({
          favorites: items,
        });
        getMe(false);
      } catch (e) {
        openErrorModal({ title: 'Oops', subtitle: getErrorMessage(e) });
      }
    }
  }, [favorites, setFavorites, getMe, openErrorModal]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    doDebounceSearch(e.target.value);
    setOptions({
      hitsPerPage: 1000,
      page: 0,
      facets: [
        '*',
      ],
      facetFilters: [],
    });
  };

  return (
    <Page>
      <Box className={commonClasses.root2}>
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
        >
          <BackButton color={commonClasses.backBtn} />
          <Box flex={1}>
            <SearchInput
              handleChange={handleSearch}
              placeholder="Search new favorite to add"
              value={search}
            />
          </Box>
        </Box>
        <Box textAlign="center" mb={4} mt={3}>
          <Typography component="h3" className={classes.title}>
            Edit Favorites
          </Typography>
        </Box>
        {
          !search.length ? (
            <>
              <Typography component="h6" className={classes.subtitle}>
                Existing favorites
              </Typography>
              {!isAuthLoading ? (
                <>
                  {
                    favorites?.map((favorite) => (
                      <FavoriteCard
                        key={favorite.id}
                        removeFavorite={handleRemoveFavorite}
                        {...getFavoritesInfo(favorite)}
                      />
                    ))
                  }
                </>
              ) : (<Loading />)}
            </>
          ) : (
            <Box className={commonClasses.vListWrapper}>
              {(!isAuthLoading || !betting.isLoading) ? (
                <VList
                  rowCount={bettingObjects.length}
                  rowRender={(index) => (
                    <>
                      {index === 0 && (
                        <Box textAlign="center" mb={4}>
                          <Typography variant="subtitle2" className={classes.subtitle}>
                            Set favorites
                          </Typography>
                        </Box>
                      )}
                      <FavoriteAddingCard
                        selectedId={selectedId}
                        selectFavorite={handleSetFavorite}
                        {...getBettingObjectInfo(bettingObjects[index])}
                      />
                    </>
                  )}
                />
              ) : (<Loading />) }
            </Box>
          )
        }
      </Box>
    </Page>
  );
};

EditFavorites.propTypes = {};

export default withRouter(EditFavorites);
