import * as actionTypes from 'store/actionTypes';
import storageService from 'services/storage.service';
import exchangeService from 'services/exchange.service';
import config from 'utils/config';

export const getMarketsListAction = (body = {}) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.GET_MARKETS_REQUEST,
    });

    try {
      const response = await exchangeService.getMarkets(body);

      dispatch({
        type: actionTypes.GET_MARKETS_SUCCESS,
        payload: response,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.GET_MARKETS_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.GET_MARKETS_FAIL,
    });
  }
};

export const getMarketsDetailsAction = (body = {}) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.GET_MARKETS_DETAILS_REQUEST,
    });

    try {
      const response = await exchangeService.getMarketsDetails(body);

      dispatch({
        type: actionTypes.GET_MARKETS_DETAILS_SUCCESS,
        payload: response,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.GET_MARKETS_DETAILS_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.GET_MARKETS_DETAILS_FAIL,
    });
  }
};

export const getMarketChangeAction = () => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.GET_MARKET_CHANGE_REQUEST,
    });

    try {
      const response = await exchangeService.getMarketChange();

      dispatch({
        type: actionTypes.GET_MARKET_CHANGE_SUCCESS,
        payload: response,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.GET_MARKET_CHANGE_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.GET_MARKET_CHANGE_FAIL,
    });
  }
};

export const createExchangeOrderAction = (body = {}) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.CREATE_EXCHANGE_ORDER_REQUEST,
    });

    try {
      const response = await exchangeService.createExchangeOrder(body);

      dispatch({
        type: actionTypes.CREATE_EXCHANGE_ORDER_SUCCESS,
        payload: response,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.CREATE_EXCHANGE_ORDER_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.CREATE_EXCHANGE_ORDER_FAIL,
    });
  }
};

export const getOrdersListAction = (body = {}) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.GET_ORDERS_LIST_REQUEST,
    });

    try {
      const response = await exchangeService.getOrders(body);

      dispatch({
        type: actionTypes.GET_ORDERS_LIST_SUCCESS,
        payload: response,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.GET_ORDERS_LIST_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.GET_ORDERS_LIST_FAIL,
    });
  }
};

export const cancelOrderAction = (params) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.CANCEL_ORDER_REQUEST,
    });

    try {
      const response = await exchangeService.cancelOrder(params);
      dispatch({
        type: actionTypes.CANCEL_ORDER_SUCCESS,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.CANCEL_ORDER_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.CANCEL_ORDER_FAIL,
    });
  }
};

export const cancelAllOrdersAction = (params) => async (dispatch) => {
  const token = storageService.getItem(config.userTokenKey);
  if (token) {
    dispatch({
      type: actionTypes.CANCEL_ALL_ORDERS_REQUEST,
    });

    try {
      const response = await exchangeService.cancelAllOrders(params);
      dispatch({
        type: actionTypes.CANCEL_ALL_ORDERS_SUCCESS,
      });

      return response;
    } catch (e) {
      dispatch({
        type: actionTypes.CANCEL_ALL_ORDERS_FAIL,
      });
      throw e;
    }
  } else {
    dispatch({
      type: actionTypes.CANCEL_ALL_ORDERS_FAIL,
    });
  }
};

export const hideRollingOverAction = () => async (dispatch) => {
  dispatch({
    type: actionTypes.HIDE_ROLLING_OVER,
    payload: {},
  });
};
