import React from 'react';

import Page from 'components/Page/Dashboard';
import FinalSubmit from 'components/Bet/Step/FinalSubmit';

const FinalSubmitWrapper = () => (
  <Page>
    <FinalSubmit mode="edit" />
  </Page>
);

export default FinalSubmitWrapper;
