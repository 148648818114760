import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/styles';
import Circle from '@material-ui/icons/PanoramaFishEye';
import CheckCircle from '@material-ui/icons/CheckCircle';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';

import {
  updateUserAction,
  setUserCurrencyTypeAction,
  setUserOddsTypeAction,
  setUserDisplayModeAction,
} from 'store/actions/auth';
import CommonStyle from 'styles/common';

const PrimaryCheckbox = withStyles({
  root: {
    color: '#63C4C6',
    '&$checked': {
      color: '#63C4C6',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 15px',
    '& .MuiFormControlLabel-label': {
      color: palette.secondaryColor,
    },
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
  },
  topic: {
    font: '15px SofiaPro-Bold',
    color: palette.secondaryColor,
    marginBottom: 10,
  },
  content: {
    font: '13px SofiaPro',
    color: 'grey',
    marginBottom: 15,
  },
  body: {
    background: palette.cardColor,
    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.15)',
    marginBottom: 22,
    minHeight: 60,
    padding: 20,
    font: '17px SofiaPro-SemiBold',
    letterSpacing: '0.3px',
    color: palette.secondaryColor,
  },
  checkBox: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginTop: '10px',
    '& span': {
      paddingTop: '0px',
      paddingBottom: '0px',
      fontSize: '14px',
      fontWeight: 'Bold',
    },
  },
}));

const SettingDisplay = ({
  user, updateUser, setUserCurrencyType, setUserOddsType, setUserDisplayMode,
}) => {
  const classes = useStyles();
  const commonClasses = CommonStyle();
  const [oddsDisplayType, setOddsDisplayType] = useState('american');
  const [currencyDisplayType, setCurrencyDisplayType] = useState(1);
  const [displayMode, setDisplayMode] = useState('light');

  const handleChange = async (event, name) => {
    if (name === 'odds') {
      setUserOddsType(event.target.name);
      await updateUser({ id: user.id, odds_display_type: event.target.name });
    } else if (name === 'currency') {
      setUserCurrencyType(Number(event.target.value));
      await updateUser({ id: user.id, currency_display_type: Number(event.target.value) });
    } else if (name === 'display_mode') {
      setUserDisplayMode(event.target.name);
      await updateUser({ id: user.id, display_mode: event.target.name });
    }
  };

  useEffect(() => {
    if (user.odds_display_type) {
      setOddsDisplayType(user.odds_display_type);
    }
    if (user.currency_display_type === 0 || user.currency_display_type === 1) {
      setCurrencyDisplayType(user.currency_display_type);
    }
    if (user.display_mode) {
      setDisplayMode(user.display_mode);
    }
  }, [user, setCurrencyDisplayType, setOddsDisplayType, setDisplayMode]);

  return (
    <Page>
      <Box className={classes.root}>
        <Box>
          <BackButton color={commonClasses.backBtn} />
        </Box>
        <Box textAlign="center" mb={4} mt={4}>
          <Typography component="h3" className={classes.title}>
            Display Setting
          </Typography>
        </Box>
        <Box className={classes.body}>
          <Typography variant="h6" className={classes.topic}>
            Display Odds
          </Typography>
          <Typography variant="subtitle2" className={classes.content}>
            Choose below how you`d like to display your odds throughout ZenSports.
          </Typography>
          <Typography variant="subtitle2" className={classes.content}>
            Click
            {' '}
            <a
              className="color-primary"
              href="https://www.investopedia.com/articles/investing/042115/betting-basics-fractional-decimal-american-moneyline-odds.asp"
              target="_blank"
              rel="noreferrer"
            >
              HERE
            </a>
            {' '}
            to find out more the difference between American Odds and Decimal Odds.
          </Typography>
          <Box>
            <FormControlLabel
              control={(
                <PrimaryCheckbox
                  icon={<Circle fontSize="small" />}
                  checkedIcon={<CheckCircle fontSize="small" />}
                  name="american"
                  checked={oddsDisplayType === 'american'}
                  onChange={(e) => handleChange(e, 'odds')}
                />
              )}
              label="American Odds"
              className={classes.checkBox}
            />
          </Box>
          <Box>
            <FormControlLabel
              control={(
                <PrimaryCheckbox
                  icon={<Circle fontSize="small" />}
                  checkedIcon={<CheckCircle fontSize="small" />}
                  name="decimal"
                  checked={oddsDisplayType === 'decimal'}
                  onChange={(e) => handleChange(e, 'odds')}
                />
              )}
              label="Decimal"
              className={classes.checkBox}
            />
          </Box>
        </Box>
        <Box className={classes.body}>
          <Typography variant="h6" className={classes.topic}>
            Dark Mode / Light Mode
          </Typography>
          <Box>
            <FormControlLabel
              control={(
                <PrimaryCheckbox
                  icon={<Circle fontSize="small" />}
                  checkedIcon={<CheckCircle fontSize="small" />}
                  name="light"
                  checked={displayMode === 'light' || displayMode === 'system'}
                  onChange={(e) => handleChange(e, 'display_mode')}
                />
              )}
              label="Light"
              className={classes.checkBox}
            />
          </Box>
          <Box>
            <FormControlLabel
              control={(
                <PrimaryCheckbox
                  icon={<Circle fontSize="small" />}
                  checkedIcon={<CheckCircle fontSize="small" />}
                  name="dark"
                  checked={displayMode === 'dark'}
                  onChange={(e) => handleChange(e, 'display_mode')}
                />
              )}
              label="Dark"
              className={classes.checkBox}
            />
          </Box>
        </Box>
        <Box className={classes.body}>
          <Typography variant="h6" className={classes.topic}>
            Currency Display
          </Typography>
          <Typography variant="subtitle2" className={classes.content}>
            You can choose how you&apos;d like to display currencies throughout
            the app. Below are the 2 options you can choose from.
          </Typography>
          <Box>
            <FormControlLabel
              control={(
                <PrimaryCheckbox
                  icon={<Circle fontSize="small" />}
                  checkedIcon={<CheckCircle fontSize="small" />}
                  name="currency"
                  value={1}
                  checked={currencyDisplayType === 1}
                  onChange={(e) => handleChange(e, 'currency')}
                />
              )}
              label="Cryptocurrency that the bet was created in with the USD equivalent in parentheses"
              className={classes.checkBox}
            />
          </Box>
          <Box>
            <FormControlLabel
              control={(
                <PrimaryCheckbox
                  icon={<Circle fontSize="small" />}
                  checkedIcon={<CheckCircle fontSize="small" />}
                  name="currency"
                  value={0}
                  checked={currencyDisplayType === 0}
                  onChange={(e) => handleChange(e, 'currency')}
                />
              )}
              label="Just the Cryptocurrency that the bet was created in"
              className={classes.checkBox}
            />
          </Box>
        </Box>
      </Box>
    </Page>
  );
};

SettingDisplay.propTypes = {
  user: PropTypes.object.isRequired,
  updateUser: PropTypes.func.isRequired,
  setUserOddsType: PropTypes.func.isRequired,
  setUserCurrencyType: PropTypes.func.isRequired,
  setUserDisplayMode: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  updateUser: (data) => dispatch(updateUserAction(data)),
  setUserOddsType: (oddType) => dispatch(setUserOddsTypeAction(oddType)),
  setUserCurrencyType: (currencyType) => dispatch(setUserCurrencyTypeAction(currencyType)),
  setUserDisplayMode: (mode) => dispatch(setUserDisplayModeAction(mode)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingDisplay);
