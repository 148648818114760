import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Button } from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: 0,
    background: palette.cardColor,
    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.15)',
    marginBottom: 22,
    minHeight: 60,
    font: '17px SofiaPro-SemiBold',
    letterSpacing: '0.3px',
    color: palette.secondaryColor,
  },
}));

const WhiteButton = ({ children, onClick }) => {
  const classes = useStyles();

  return (
    <Button className={classes.root} onClick={onClick}>
      { children }
    </Button>
  );
};

WhiteButton.defaultProps = {
  onClick: () => {},
};

WhiteButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

export default WhiteButton;
