import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import ShareButton from 'components/Buttons/ShareButton';
import Loading from 'components/Loading/Box';
import ZenDialog from 'components/Dialog/ZenDialog';

import SubmitView from 'components/Bet/View/SubmitView';
import TakerView from 'components/Bet/View/TakerView';
import MakerView from 'components/Bet/View/MakerView';
import MarketView from 'components/Bet/View/MarketView';
import AcceptView from 'components/Bet/View/AcceptView';
import ParlayResultView from 'components/Bet/View/ParlayResultView';
import ParlaySubmitView from 'components/Bet/View/ParlaySubmitView';

import { useBet, useSingleBet } from 'hooks/redux/bet';
import { useSelectCurrencyDispatch } from 'hooks/redux/currency';
import { useUserOddType, useAuthUser } from 'hooks/redux/user';

import {
  getBetInfo,
  getBetResultInfo,
  getBetShareInfo,
  getBetViewMode,
} from 'utils/betting';
import { comingSoonMessage } from 'utils/constants';
import { shareLinks } from 'utils';
import commonStyle from 'styles/common';

const useStyles = makeStyles(({ palette }) => ({
  title: {
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
  },
  container: {
    boxShadow: '0 -2px 15px rgba(0, 0, 0, 0.15)',
    borderRadius: '5px',
    background: palette.cardColor,
  },
}));

const SingleBet = ({ match }) => {
  const classes = useStyles();
  const commonClasses = commonStyle();
  const [viewMode, setViewMode] = useState('');
  const [betInfo, setBetInfo] = useState({});
  const [comingSoonDialogOpen, setComingSoonDialogOpen] = useState(false);

  const { betId } = match.params;
  const isParlayBet = match.path.includes('parlay-bet');
  const isParlaySubmitBet = match.path.includes('parlay-bet-submit');
  const betting = useBet();
  const bet = useSingleBet(betId);
  const oddType = useUserOddType();
  const user = useAuthUser();
  const selectCurrency = useSelectCurrencyDispatch();

  const handleShareClick = () => {
    if (bet && bet.id) {
      const shareInfo = getBetShareInfo(bet, oddType);
      const linkData = {
        campaign: `${user.first_name}${user.last_name ? ` ${user.last_name}` : ''} `,
        feature: 'Sports Betting',
        tags: [user.id],
        data: {
          proposed_bet_id: shareInfo.id,
          $og_title: shareInfo.title,
          $og_description: shareInfo.description,
          $og_image_url: shareInfo.imageUrl,
          // $fallback_url: window.location.href,
        },
      };
      window.branch.link(linkData, (err, link) => {
        if (!err) {
          shareLinks({
            title: shareInfo.title,
            text: shareInfo.description,
            url: link,
          });
        } else {
          // eslint-disable-next-line no-console
          console.log(err);
        }
      });
    }
  };

  const handleCloseComingSoonDialog = () => {
    setComingSoonDialogOpen(false);
  };

  useEffect(() => {
    if (bet.id === Number(betId) && user.id) {
      setViewMode(getBetViewMode(bet, user));
    }
    // eslint-disable-next-line
  }, [bet]);

  useEffect(() => {
    if (bet.id === Number(betId) && viewMode) {
      selectCurrency(bet.currency?.symbol);
      if (viewMode === 'accept') {
        setBetInfo(getBetInfo(bet, oddType));
      } else {
        setBetInfo(getBetResultInfo(bet, user, oddType));
      }
    }
    // eslint-disable-next-line
  }, [bet, oddType, viewMode]);

  return (
    <Page>
      <Box className={commonClasses.root}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <BackButton color={commonClasses.backBtn} />
          <ShareButton onClick={handleShareClick} />
          { comingSoonDialogOpen && (
            <ZenDialog
              title="Feature Coming Soon!"
              message={comingSoonMessage}
              cta="Close"
              customCta="Download iOS/Android App"
              customCtaLink="zensports.com"
              onClose={handleCloseComingSoonDialog}
            />
          )}
        </Box>
        {betting.isLoading && (<Loading />)}
        {Object.keys(betInfo).length > 0 && (
          <>
            {viewMode !== 'accept' && (
              <Box display="flex" justifyContent="center" alignItems="center" mt={3} mb={3}>
                <Typography variant="h3" className={classes.title}>
                  {(isParlaySubmitBet || viewMode === 'submit') ? (
                    <>Submit Results</>
                  ) : (
                    <>View Results</>
                  )}
                </Typography>
              </Box>
            )}
            <Box className={classes.container}>
              {isParlayBet ? (
                <>
                  {isParlaySubmitBet ? (
                    <ParlaySubmitView betInfo={betInfo} />
                  ) : (
                    <ParlayResultView betInfo={betInfo} />
                  )}
                </>
              ) : (
                <>
                  {viewMode === 'accept' && (<AcceptView betInfo={betInfo} />)}
                  {viewMode === 'submit' && (<SubmitView betInfo={betInfo} />)}
                  {viewMode === 'maker' && (<MakerView betInfo={betInfo} />)}
                  {viewMode === 'taker' && (<TakerView betInfo={betInfo} />)}
                  {viewMode === 'market' && (<MarketView betInfo={betInfo} />)}
                </>
              )}
            </Box>
          </>
        )}
      </Box>
    </Page>
  );
};

SingleBet.defaultProps = {};

SingleBet.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(SingleBet);
