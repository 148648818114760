import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormattedNumberInput from 'components/Form/FormattedNumberInput';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import { Box } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles } from '@material-ui/styles';
import { usdCurrency, currencyDecimalScale } from 'utils/constants';

const useStyles = makeStyles(({ palette }) => ({
  syncIcon: {
    transform: 'rotate(90deg)',
    alignSelf: 'center',
    filter: palette.invertImage,
  },
  currencyImage: {
    width: '28px',
    textAlign: 'center',
    marginRight: '5px',
    '& img': {
      height: '18px',
      width: 'auto',
      filter: palette.invertImage,
    },
  },
  currency: {
    color: '#29CFD4',
    font: '25px SofiaPro-Bold',
    letterSpacing: -0.3,
    '& input': {
      textAlign: 'center',
    },
  },
}));
const InputCurrencyExchange = ({
  currency,
  cryptoCurrencyAmount,
  usdCurrencyAmount,
  onUsdCurrencyAmountChange,
  onCryptoCurrencyAmountChange,
  disabled,
}) => {
  const [isTopPlace, setIsTopPlace] = useState(true);
  const classes = useStyles();

  const handleClick = () => {
    setIsTopPlace(!isTopPlace);
  };

  const onInputChange = (event) => {
    if (isTopPlace && event.target.name === 'cryptoAmount') {
      onCryptoCurrencyAmountChange(event);
    }
    if (!isTopPlace && event.target.name === 'usdCurrencyAmount') {
      onUsdCurrencyAmountChange(event);
    }
  };
  return (
    <Box display="flex">
      {currency.symbol !== 'usd' && (
        <SyncAltIcon className={classes.syncIcon} onClick={handleClick} />
      )}
      <Box
        display="flex"
        flexDirection={!isTopPlace ? 'column-reverse' : 'column'}
        justifyContent="center"
        alignItems="start"
        flex={1}
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box className={classes.currencyImage}>
            <img src={currency.image_url} alt="wallet" />
          </Box>
          <InputBase
            autoComplete="off"
            className={classes.currency}
            value={cryptoCurrencyAmount}
            readOnly={!isTopPlace}
            name="cryptoAmount"
            onChange={onInputChange}
            inputComponent={FormattedNumberInput}
            inputProps={{ 'aria-label': 'naked', decimalScale: currencyDecimalScale[currency.symbol] }}
            disabled={disabled}
          />
        </Box>
        {currency.symbol !== 'usd' && (
          <Box display="flex" justifyContent="center" alignItems="center">
            <Box className={classes.currencyImage}>
              <img src={usdCurrency.image_url} alt="wallet" />
            </Box>
            <InputBase
              autoComplete="off"
              readOnly={isTopPlace}
              className={classes.currency}
              value={usdCurrencyAmount}
              name="usdCurrencyAmount"
              onChange={onInputChange}
              inputComponent={FormattedNumberInput}
              inputProps={{ 'aria-label': 'naked', decimalScale: currencyDecimalScale.usd }}
              disabled={disabled}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

InputCurrencyExchange.defaultProps = {
  onCryptoCurrencyAmountChange: () => {},
  onUsdCurrencyAmountChange: () => {},
  disabled: false,
};

InputCurrencyExchange.propTypes = {
  currency: PropTypes.object.isRequired,
  cryptoCurrencyAmount: PropTypes.string.isRequired,
  usdCurrencyAmount: PropTypes.string.isRequired,
  onCryptoCurrencyAmountChange: PropTypes.func,
  onUsdCurrencyAmountChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default InputCurrencyExchange;
