import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Page from 'components/Page/Dashboard';
import SmallBetListCard from 'components/Bet/BetListSmallCard';
import Loading from 'components/Loading/Box';
import SearchInput from 'components/Form/SearchInput';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import TuneIcon from '@material-ui/icons/Tune';

import { getLeagueInfo } from 'utils/betting';
import { useLeagues, useBet, useSetLeagueDispatch } from 'hooks/redux/bet';
import commonStyle from 'styles/common';

const useStyles = makeStyles(({ palette }) => ({
  filterBtn: {
    transform: 'rotate(-90deg)',
    color: palette.secondaryColor,
    fontSize: '2rem',
  },
  expandBtn: {
    color: palette.secondaryColor,
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
  },
}));

const Leagues = ({ history }) => {
  const classes = useStyles();
  const commonClasses = commonStyle();
  const [search, setSearch] = useState('');
  const [selectedLeagues, setSelectedLeagues] = useState([]);

  const leagues = useLeagues();
  const betting = useBet();
  const setLeague = useSetLeagueDispatch();

  const handleSearch = (event) => {
    setSearch(event.target.value);
    setSelectedLeagues(
      leagues.filter((league) => league.name.toLowerCase().includes(
        event.target.value.toLowerCase(),
      )),
    );
  };

  const handleNext = useCallback((type, league) => {
    setLeague(league);
    if (type === 'group') {
      history.push('/bets/results/esports');
    } else {
      history.push('/bets/results/mine');
    }
  }, [setLeague, history]);

  const handleFilter = () => {
    history.push('/bets/results/filter');
  };

  const handleCollapse = () => {
    setLeague({});
    history.push('/bets/results');
  };

  useEffect(() => {
    if (leagues.length) {
      setSelectedLeagues(leagues);
    }
  }, [leagues]);

  return (
    <Page>
      <Box className={commonClasses.root}>
        <Box display="flex" justifyContent="flex-end">
          <ViewComfyIcon fontSize="large" className={classes.expandBtn} onClick={() => handleCollapse()} />
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center" mt={3} mb={1}>
          <Typography variant="h3" className={classes.title}>
            Results
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <SearchInput
            handleChange={handleSearch}
            placeholder="e.g. NFL,hocky, Lakers…"
            value={search}
          />
          <TuneIcon className={classes.filterBtn} onClick={() => handleFilter()} />
        </Box>
        {!betting.isLoading ? selectedLeagues.map((item) => (
          <SmallBetListCard
            onClick={() => handleNext(item.type, item)}
            key={item.name}
            {...getLeagueInfo(item)}
          />
        )) : (<Loading />)}
      </Box>
    </Page>
  );
};

Leagues.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(Leagues);
