import React from 'react';
// import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => ({
  title: {
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
  },
  subtitle: {
    marginLeft: '-15px',
  },
  content: {
    fontSize: '14px',
    marginBottom: '10px',
    color: palette.secondaryColor,
  },
}));

const Pending = () => {
  const classes = useStyles();

  return (
    <Box>
      <Box textAlign="center" mt={3} mb={3}>
        <Typography component="h3" className={classes.title}>
          Pending KYC/AML Verification
        </Typography>
      </Box>
      <Typography className={classes.content}>
        Thanks for submitting your KYC/AML information!
        {'Here\'s what\'s going to happen next:'}
      </Typography>
      <ul className={classes.subtitle}>
        <li className={classes.content}>
          {`KYC/AML approval could take anywhere from 1 minute to 3 business days.
             The required time to approve depends on if we can automatically verify
             your identify based on the identification document you provided.
             If we cannot verify your identify automatically, a manual review will have to be done,
             which can take 2 to 3 business days. You can either wait on this screen for confirmation,
             or you can leave and we'll notify you once we have the results.`}
        </li>
        <li className={classes.content}>
          {`If you choose to leave this screen, we'll notify you of the status by email and a push
            notification from our app (be sure your Notification Settings have been turned on!)`}
        </li>
        <li className={classes.content}>
          {`If you attempt to make a fiat deposit or withdrawal and your KYC/AML verification is
            still pending, you will continue to get this screen.`}
        </li>
        <li className={classes.content}>
          {`If your KYC/AML verification fails, you will be told why if failed.
            You will be prompted to go through the KYC process again if this is the case.`}
        </li>
      </ul>
    </Box>
  );
};

Pending.defaultProps = {};

Pending.propTypes = {};

export default Pending;
