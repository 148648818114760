import { dateOptions } from 'utils/constants';

/**
 *
 * @returns {Object}
 * @param user
 * @param doc
 */
export const getKycItems = (user, doc) => {
  let items = [
    {
      title: 'Contact Information',
      items: [
        { topic: 'Full Name', value: user.name },
        { topic: 'Email', value: user.email },
      ],
      section: 'contact-info',
      route: '/kyc/profile',
    },
    {
      title: 'Date of Birth',
      items: [
        { topic: 'Date', value: new Date(user.dob).toLocaleString('en-US', dateOptions) },
      ],
      section: 'birthday',
      route: '/kyc/profile/birthday',
    },
    {
      title: 'Country of Residence',
      items: [
        { topic: 'Country', value: user.country?.name },
      ],
      section: 'birthday',
      route: '/kyc/profile/country',
    },
    {
      title: 'Address',
      items: [
        { topic: 'Address Line 1', value: user.street_1 },
        { topic: 'Address Line 2', value: user.street_2 },
        { topic: 'City', value: user.city },
        { topic: 'State', value: user.state.name },
        { topic: 'Zip', value: user.postal_code },
      ],
      section: 'address',
      route: '/kyc/profile/address',
    },
    {
      title: 'Issuing Country of Documentation',
      items: [
        { topic: 'Country', value: doc.country?.name },
      ],
      section: 'country',
      route: '/kyc/documentation/country',
    },
  ];

  if (doc.country.country_code === 'US') {
    items = items.concat(
      [
        {
          title: 'Issuing State of Documentation',
          items: [
            { topic: 'State', value: doc.state?.name },
          ],
          section: 'country',
          route: '/kyc/documentation/state',
        },
        {
          title: 'Tax Identification Number',
          items: [
            { topic: 'Tax ID', value: doc.tax_id_number },
          ],
          section: 'tax_id',
          route: '/kyc/documentation/tax',
        },
        {
          title: 'Documentation',
          items: [
            { topic: 'Documentation Type', value: doc.documentType?.name },
            { topic: 'Front of ID', value: doc.uploaded_front_id },
          ],
          section: 'documents',
          route: '/kyc/documentation/info',
        },
      ],
    );
  } else {
    items = items.concat([
      {
        title: 'Documentation',
        items: [
          { topic: 'Documentation Type', value: doc.documentType?.name },
          { topic: 'Document ID Number', value: doc.tax_id_number },
          { topic: 'Front of ID', value: doc.uploaded_front_id },
        ],
        section: 'documents',
        route: '/kyc/documentation/info',
      },
    ]);
  }
  if (doc.documentType.value !== 'passport') {
    items[items.length - 1].items.push({
      topic: 'Back of ID', value: doc.uploaded_back_id,
    });
  }
  return items;
};

/**
 *
 * @returns {Object}
 * @param user
 * @param doc
 * @param resourceToken
 */
export const getSubmitKycInfo = (user, doc, resourceToken) => {
  const data = {
    city: user.city,
    name: user.name,
    email: user.email,
    country: user.country.country_code,
    region: user.country.country_code === 'US' ? user.state.abbreviation : user.state.name,
    street_1: user.street_1,
    street_2: user.street_2,
    tax_id_number: doc.tax_id_number,
    postal_code: user.postal_code,
    dob: user.dob,
    token: resourceToken,
    tax_country: doc.country.country_code,
    tax_region: doc.country.country_code === 'US' ? doc.state.abbreviation : doc.state.name,
    kyc_documents: [],
  };
  if (doc.uploaded_front_id) {
    data.kyc_documents.push({
      proof_of_address: false,
      identity: true,
      identity_photo: true,
      type: doc.documentType.value,
      country: doc.country.country_code,
      uploaded_front_id: doc.uploaded_front_id,
    });
  }
  if (doc.uploaded_back_id && doc.documentType.value !== 'passport') {
    data.kyc_documents[0].uploaded_back_id = doc.uploaded_back_id;
  }
  return data;
};

/**
 *
 * @returns {Object}
 * @param exceptions
 * @param section
 */
export const getExceptions = (exceptions, section) => {
  if (exceptions && exceptions.length) {
    return exceptions.filter(
      (item) => item?.section?.includes(section),
    );
  }
  return [];
};
