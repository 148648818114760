import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/styles';
import { Box, Typography } from '@material-ui/core';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import InputBox from 'components/Form/InputBox';

import { useKycUserInfo, useSetKycUserInfoDispatch } from 'hooks/redux/kyc';
import CommonStyle from 'styles/common';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 15px',
  },
  line: {
    background: 'linear-gradient(45deg, #298EDA, #29D2D3)',
    height: '3px',
    marginLeft: '-27px',
    width: '50%',
  },
  topic: {
    font: '12px SofiaPro-SemiBold',
    color: palette.colorBtnTxt,
    textTransform: 'capitalize',
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'space-between',
  },
  next: {
    marginTop: 20,
    width: '100%',
  },
}));

const KycAddress = ({ history }) => {
  const classes = useStyles();
  const commonClasses = CommonStyle();
  const kycInfo = useKycUserInfo();
  const setKycUserInfo = useSetKycUserInfoDispatch();
  // const openErrorModal = useOpenErrorModalDispatch();
  const country = kycInfo.country.name;
  const isResolve = history.location.state === 'resolve';

  const handleSelectState = (values) => {
    if (kycInfo.country.country_code === 'US') {
      history.push('/kyc/profile/select-state');
      setKycUserInfo({
        city: values.city,
        state: {
          ...kycInfo.state,
          name: values.stateCode,
        },
        postal_code: values.postalCode,
        street_1: values.street1,
        street_2: values.street2,
      });
    } else {
      // openErrorModal({ subtitle: 'In order to select state, country must be United States of America.' });
    }
  };

  return (
    <Page>
      <Box className={classes.root} display="flex" flexDirection="column" flex={1}>
        <Box className={classes.line} mb={2} />
        <Box>
          <BackButton color={commonClasses.backBtn} page={isResolve ? '/kyc/resolve' : '/kyc/profile/country'} />
        </Box>
        <Box textAlign="center" mt={3} mb={1}>
          <Typography component="h3" className={classes.topic}>
            KYC/AML VERIFICATION FORM
          </Typography>
        </Box>
        <Box textAlign="center" mb={4}>
          <Typography component="h3" className={classes.title}>
            Address
          </Typography>
        </Box>
        {
          kycInfo.name !== '' && (
            <Formik
              initialValues={{
                city: kycInfo.city,
                street1: kycInfo.street_1,
                street2: kycInfo.street_2,
                stateCode: kycInfo.state.name,
                postalCode: kycInfo.postal_code,
              }}
              onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(true);
                setKycUserInfo({
                  city: values.city,
                  state: {
                    ...kycInfo.state,
                    name: values.stateCode,
                  },
                  postal_code: values.postalCode,
                  street_1: values.street1,
                  street_2: values.street2,
                });
                const route = isResolve ? '/kyc/resolve' : '/kyc/documentation/country';
                history.push(route);
              }}
              validationSchema={Yup.object().shape({
                street1: Yup.string()
                  .required('Required'),
                stateCode: country === 'United States of America' ? Yup.string().required() : null,
                city: Yup.string()
                  .required('Required'),
                postalCode: country === 'United States of America'
                  ? Yup.string()
                    .min(5, 'Enter a valid 5 digit zip code')
                    .max(5, 'Enter a valid 5 digit zip code')
                    .required('Required')
                  : null,
              })}
            >
              {({
                errors,
                touched,
                values,
                isSubmitting,
                handleChange,
                handleSubmit,
                handleBlur,
              }) => (
                <form onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.form}>
                  <Box>
                    <InputBox
                      label="Address Line 1"
                      name="street1"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.street1}
                      errors={errors}
                      touched={touched}
                    />
                    <Box mt={4}>
                      <InputBox
                        label="Address Line 2"
                        name="street2"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.street2}
                        errors={errors}
                        touched={touched}
                      />
                    </Box>
                    <Box mt={4}>
                      <InputBox
                        label="City"
                        name="city"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.city}
                        errors={errors}
                        touched={touched}
                      />
                    </Box>
                    <Box mt={4}>
                      <InputBox
                        label="State"
                        name="stateCode"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onClick={() => handleSelectState(values)}
                        value={values.stateCode}
                        errors={errors}
                        touched={touched}
                      />
                    </Box>
                    <Box mt={4}>
                      <InputBox
                        label="Zip"
                        name="postalCode"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.postalCode}
                        errors={errors}
                        touched={touched}
                      />
                    </Box>
                  </Box>
                  <Box display="flex" className={classes.next}>
                    <OutlineButton type="submit" disabled={isSubmitting}>
                      {isResolve ? 'Done' : 'Next'}
                    </OutlineButton>
                  </Box>
                </form>
              )}
            </Formik>
          )
        }
      </Box>
    </Page>
  );
};

KycAddress.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(KycAddress);
