import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  Box,
  Typography,
  Button,
  Menu,
  MenuItem,
  Divider,
  InputBase,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { withStyles } from '@material-ui/core/styles';

import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import OutlineButton from 'components/Buttons/OutlineButton';
import Tabs from 'components/Tabs/Tabs';
import MarketTradeTable from 'components/Exchange/MarketTradeTable';
import OrderBookTable from 'components/Exchange/OrderBookTable';
import FormattedNumberInput from 'components/Form/FormattedNumberInput';
import TradeBalanceDialog from 'components/Dialog/TradeBalanceDialog';

import { useUserRestrictedStatus, useAuthUser } from 'hooks/redux/user';
import { useCreateExchangeOrder } from 'hooks/redux/exchange';
import { useOpenErrorModalDispatch, useOpenSuccessModalDispatch } from 'hooks/redux/modal';

import {
  formatCurrency,
  convertCurrencyAmount,
  getFormattedCurrency,
} from 'utils/currency';
import { exchangeCurrencyDecimalScale, usdCurrency, restrictedMessage } from 'utils/constants';
import { getErrorMessage } from 'utils';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  lastPrice: ({ change }) => ({
    fontSize: '25px',
    fontWeight: 'bold',
    paddingLeft: '21px',
    color: change === 0 ? palette.secondaryColor : change > 0 ? '#54a400' : 'red',
  }),
  changePercentage: ({ change }) => ({
    fontSize: '13px',
    fontWeight: 'lighter',
    color: change === 0 ? palette.secondaryColor : change > 0 ? '#54a400' : 'red',
  }),
  greenIcon: {
    color: '#54a400',
    fontSize: '30px',
    marginLeft: '-5px',
  },
  redIcon: {
    color: 'red',
    fontSize: '30px',
    marginLeft: '-5px',
  },
  divider: {
    backgroundColor: '#e6e7e8',
  },
  tradeNum1: {
    color: '#74ebed',
    fontSize: '23px',
    fontWeight: 'bold',
  },
  tradeNum2: {
    color: palette.secondaryColor,
    fontSize: '13px',
    fontWeight: 'lighter',
  },
  tradeButtonsSection: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '0.8rem',
  },
  limitTextIcon: {
    fontSize: '16px',
    fontWeight: '400',
    color: palette.secondaryColor,
    margin: '1rem 0 0.5rem 0',
  },
  limitText: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#adadbc',
  },
  limitContainer: {
    marginBottom: 10,
  },
  limitText2: {
    fontSize: '18px',
    color: '#8585a0',
    fontWeight: '400',
    marginBlockStart: '0px',
    marginBlockEnd: '0px',
  },
  limitText3: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: palette.secondaryColor,
  },
  marketPrice: {
    backgroundColor: '#f4f4fbe0',
    width: '100%',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '8px',
    margin: '1rem 0',
    color: '#89899e',
  },
  totalAmount: {
    backgroundColor: '#f4f4fbe0',
    width: '100%',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '8px',
    marginTop: '1rem',
  },
  tradeBalanceSection: {
    marginTop: '15px',
  },
  tradeBalanceText: {
    textAlign: 'right',
    color: palette.secondaryColor,
    marginRight: '5px',
    fontSize: '1.4rem',
  },
  tradeBalanceHText: {
    textAlign: 'right',
    marginRight: '5px',
    color: '#89899e',
  },
  input: {
    '&::placeholder': {
      color: 'blue',
    },
  },
  activeBtn: {
    textTransform: 'inherit',
    padding: '7px 0px',
    width: '47%',
    background: 'linear-gradient(to right, #3597da, #36b6d6, #38c8d4)',
    color: 'white',
    boxShadow: palette.btnShadow,
  },
  inActiveBtn: {
    background: palette.inactiveBtnColor,
    textTransform: 'inherit',
    padding: '7px 0px',
    color: palette.secondaryColor,
    boxShadow: palette.btnShadow,
    width: '47%',
  },
  expandIcon: {
    color: '#74ebed',
    fontSize: '30px',
    marginLeft: '1px',
  },
  limitIcons: {
    color: palette.secondaryColor,
    fontSize: '25px',
    boxShadow: palette.btnShadow,
    borderRadius: '50%',
    border: palette.btnBorder,
    marginBottom: 6,
  },
  percentages: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  percentBox: {
    marginTop: '0.7rem',
    border: `1px solid ${palette.secondaryColor}`,
    padding: '10px',
    color: palette.secondaryColor,
    width: '54px',
    height: '15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  disableBox: {
    display: 'none',
  },
  infoIcon: {
    color: '#15cfd7',
    fontSize: '20px',
  },
  buySportsBtn: {
    '& .MuiButtonBase-root': {
      font: '18px SofiaPro-SemiBold',
      color: palette.successTextColor,
      width: '100%',
      border: '2px solid #80bd28d9',
      padding: 0,
      borderRadius: '5px',
      background: palette.successBackgroundColor,
      boxShadow: '0 0px 5px 0 #77a634, 0 0px 0px 0 #77a633',
      minHeight: '50px',
      marginBottom: '10px',
      letterSpacing: '0.3px',
    },
  },
  sellSportsBtn: {
    '& .MuiButtonBase-root': {
      font: '18px SofiaPro-SemiBold',
      color: palette.dangerTextColor,
      width: '100%',
      border: '2px solid #e68a8d',
      padding: 0,
      borderRadius: '5px',
      background: palette.dangerBackgroundColor,
      boxShadow: '0 0px 5px 0 #e68a8c, 0 0px 0px 0 #f4bdbe',
      minHeight: '50px',
      marginBottom: '10px',
      letterSpacing: '0.3px',
    },
  },
  orderMarketTabs: {
    '& > div': {
      background: palette.themeColor,
    },
    '& header': {
      width: '100% !important',
      marginLeft: '0px !important',
    },
    '& .MuiTabs-flexContainer .MuiTab-wrapper': {
      color: '#adadbc',
      fontSize: '16px',
      textTransform: 'capitalize',
    },
    '& header .MuiTabs-root': {
      height: 'auto',
    },
    '& .MuiTab-fullWidth': {
      flexGrow: 0,
      maxWidth: 'auto',
      flexBasis: 'auto',
      flexShrink: 'auto',
      padding: '6px 15px 6px 0px',
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& .MuiTabs-root': {
      width: '100%',
      marginLeft: 0,
    },
    '& .Mui-selected .MuiTab-wrapper': {
      color: `${palette.secondaryColor} !important`,
      fontWeight: 'bold',
    },
  },
  popover: {
    '& .MuiPopover-paper': {
      background: palette.inactiveBtnColor,
      '& li': {
        color: palette.secondaryColor,
      },
    },
  },
  tradeForm: {
    textAlign: 'center !important',
    '& .MuiInputBase-input': {
      textAlign: 'center !important',
      fontWeight: 600,
      color: '#31dee3',
    },
    '& input::placeholder': {
      color: `${palette.placeholderColor} !important`,
      fontWeight: 600,
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none !important',
    },
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(({ palette }) => ({
  root: {
    color: 'black',
    '&:focus': {
      color: 'black',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: palette.common.white,
      },
    },
  },
}))(MenuItem);

const Trade = ({ market, mappedCurrencies }) => {
  const [showFor, setShowFor] = useState('buy');
  const [anchorEl, setAnchorEl] = useState(undefined);
  const [amount, setAmount] = useState('');
  const [price, setPrice] = useState('');
  const [orderType, setOrderType] = useState('limit');
  const classes = useStyles({ change: market.change });

  const sellCurrency = market.sell_currency?.symbol;
  const buyCurrency = market.buy_currency?.symbol;
  const pointValue = Number(1 / 10 ** exchangeCurrencyDecimalScale[sellCurrency]);

  const user = useAuthUser();
  const userRestricted = useUserRestrictedStatus();
  const createExchangeOrder = useCreateExchangeOrder();
  const openErrorModal = useOpenErrorModalDispatch();
  const openSuccessModal = useOpenSuccessModalDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleIncreasePrice = () => {
    setPrice((Number(price) + pointValue).toFixed(exchangeCurrencyDecimalScale[sellCurrency]));
  };

  const handleDecreasePrice = () => {
    setPrice((Number(price) - pointValue).toFixed(exchangeCurrencyDecimalScale[sellCurrency]));
  };

  const handlePercentage = (percent) => {
    if (Number(price) > 0) {
      if (showFor === 'buy') {
        const tradableBalance = mappedCurrencies[sellCurrency].tradable_balance;
        setAmount(
          (
            (tradableBalance * (percent / 100)) / (Number(price) * (10 ** mappedCurrencies[sellCurrency].decimal_places))
          ).toString(),
        );
      } else {
        const tradableBalance = mappedCurrencies[buyCurrency].tradable_balance;
        setAmount(
          (
            (tradableBalance * (percent / 100)) / (10 ** mappedCurrencies[buyCurrency].decimal_places)
          ).toString(),
        );
      }
    } else {
      setAmount('');
    }
  };

  const handleChangeOrderType = (type = 'limit') => {
    if (orderType === 'market') {
      // @todo orderType
    }
    setOrderType(type);
    handleClose();
  };

  const handleSubmitOrder = async () => {
    try {
      if (userRestricted) {
        openSuccessModal({
          title: 'Oops!',
          subtitle: restrictedMessage(user),
          buttonText: 'Got It',
        });
        return;
      }
      const data = {
        market_id: market.id,
        amount: convertCurrencyAmount(amount, mappedCurrencies[buyCurrency]),
        is_buying: showFor === 'buy',
      };
      if (orderType === 'limit') {
        data.limit_price = convertCurrencyAmount(price, mappedCurrencies[sellCurrency]);
      }
      await createExchangeOrder(data);
      setPrice('');
      setAmount('');
    } catch (e) {
      // eslint-disable-next-line
      openErrorModal({ title: 'Oops', subtitle: getErrorMessage(e) });
    }
  };

  const tabsList = [
    {
      label: 'Order Book',
      component: <OrderBookTable
        sellCurrency={sellCurrency}
        buyCurrency={buyCurrency}
        buyOrders={market.limit_buy_orders}
        sellOrders={market.limit_sell_orders}
        mappedCurrencies={mappedCurrencies}
      />,
    },
    {
      label: 'Market Trades',
      component: <MarketTradeTable
        sellCurrency={sellCurrency}
        buyCurrency={buyCurrency}
        trades={market.market_trades}
        mappedCurrencies={mappedCurrencies}
      />,
    },
  ];

  return (
    <Box>
      <Box mt={-1} display="flex" justifyContent="center">
        <Typography
          className={classes.lastPrice}
          align="center"
          variant="h1"
        >
          {
            formatCurrency(
              market.last_price,
              mappedCurrencies[sellCurrency],
              false,
              true,
              buyCurrency,
            )
          }
        </Typography>
        {market.change > 0 && (<ArrowDropUpIcon className={classes.greenIcon} />)}
        {market.change < 0 && (<ArrowDropDownIcon className={classes.redIcon} />)}
      </Box>
      <Box className={classes.root}>
        <Box display="flex" flex="1" justifyContent="flex-start">
          <Typography className={classes.tradeNum2} variant="body1">
            {
              formatCurrency(market.last_usd_price, usdCurrency)
            }
          </Typography>
        </Box>
        <Box display="flex" flex="1" justifyContent="center">
          <Typography
            className={classes.changePercentage}
            variant="body1"
          >
            { `${market.change > 0 ? `+${market.change}` : market.change}%` }
          </Typography>
        </Box>
        <Box display="flex" flex="1" justifyContent="flex-end">
          <Typography className={classes.tradeNum2} variant="body1">
            {`Vol. ${formatCurrency(
              market.volume,
              mappedCurrencies[buyCurrency],
              false,
              false,
            )}`}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.tradeButtonsSection}>
        <Button
          variant="contained"
          size="small"
          onClick={() => setShowFor('buy')}
          className={showFor === 'buy' ? `${classes.activeBtn}` : `${classes.inActiveBtn}`}
        >
          Buy
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={() => setShowFor('sell')}
          className={showFor === 'sell' ? `${classes.activeBtn}` : `${classes.inActiveBtn}`}
        >
          Sell
        </Button>
      </Box>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.popover}
      >
        <StyledMenuItem onClick={() => { handleChangeOrderType('limit'); }}>
          Limit Order
        </StyledMenuItem>
        <StyledMenuItem onClick={() => { handleChangeOrderType('market'); }}>
          Market Order
        </StyledMenuItem>
      </StyledMenu>
      <Box>
        <Box
          display="flex"
          alignItems="flex-end"
          aria-controls="customized-menu"
          aria-haspopup="true"
          onClick={handleClick}
          className={classes.limitContainer}
        >
          <Typography className={classes.limitTextIcon} variant="h1">
            {
              orderType === 'limit' ? 'Limit Order' : 'Market Order'
            }
          </Typography>
          <ExpandMoreIcon
            className={classes.expandIcon}
          />
        </Box>
        {
          orderType === 'limit' && (
            <>
              <Box className={classes.root} alignItems="center">
                <RemoveIcon
                  onClick={handleDecreasePrice}
                  className={classes.limitIcons}
                />
                <Box>
                  <form className={classes.tradeForm} noValidate autoComplete="off">
                    <InputBase
                      autoComplete="off"
                      value={price || ''}
                      placeholder={`Price(${sellCurrency.toUpperCase()})`}
                      name="amount"
                      onChange={(e) => setPrice(e.target.value)}
                      inputComponent={FormattedNumberInput}
                      inputProps={{ 'aria-label': 'naked', decimalScale: exchangeCurrencyDecimalScale[sellCurrency] }}
                    />
                  </form>
                </Box>
                <AddIcon
                  onClick={handleIncreasePrice}
                  className={classes.limitIcons}
                />
              </Box>
              <Divider className={classes.divider} />
              <Box className={classes.root}>
                <Typography className={classes.limitText} variant="h1">
                  Equivalent
                </Typography>
                <Typography className={classes.limitText3} variant="h1">
                  {
                    getFormattedCurrency(
                      convertCurrencyAmount(
                        price,
                        mappedCurrencies[sellCurrency],
                      ),
                      mappedCurrencies[sellCurrency],
                      'usd',
                    )
                  }
                </Typography>
              </Box>
            </>
          )
        }
        {orderType === 'market' && (<Box className={classes.marketPrice}>Market price</Box>)}
        <Box>
          <form className={classes.tradeForm} noValidate autoComplete="off">
            <InputBase
              autoComplete="off"
              value={amount}
              placeholder={`Amount(${buyCurrency.toUpperCase()})`}
              name="amount"
              onChange={(e) => setAmount(e.target.value)}
              inputComponent={FormattedNumberInput}
              inputProps={{ 'aria-label': 'naked', decimalScale: exchangeCurrencyDecimalScale[buyCurrency] }}
            />
          </form>
        </Box>
        {
          orderType === 'limit' && (
            <>
              <Divider className={classes.divider} />
              <Box className={classes.percentages}>
                {[25, 50, 75, 100].map((percent) => (
                  <Box key={percent} className={classes.percentBox} onClick={() => handlePercentage(percent)}>
                    {`${percent}%`}
                  </Box>
                ))}
              </Box>
              <Box className={classes.root} alignItems="center">
                <Box className={classes.totalAmount}>
                  <Typography className={classes.limitText2} variant="h1">
                    {(Number(price) > 0 && Number(amount) > 0) ? (
                      <>
                        {
                          formatCurrency(
                            convertCurrencyAmount(
                              Number(price) * Number(amount),
                              mappedCurrencies[sellCurrency],
                            ),
                            mappedCurrencies[sellCurrency],
                            true,
                            true,
                            buyCurrency,
                          )
                        }
                      </>
                    ) : (<>{`Total ${sellCurrency.toUpperCase()}`}</>)}
                  </Typography>
                </Box>
              </Box>
            </>
          )
        }
        <Box className={classes.tradeBalanceSection}>
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <Typography className={classes.tradeBalanceHText} variant="h6">
              Trading Balance
            </Typography>
            <TradeBalanceDialog />
          </Box>
          <Box>
            <Typography className={classes.tradeBalanceText} variant="h2">
              { showFor === 'buy' && (
                <>
                  { mappedCurrencies[sellCurrency].tradable_balance > 0
                    ? formatCurrency(
                      mappedCurrencies[sellCurrency].tradable_balance,
                      mappedCurrencies[sellCurrency],
                      true,
                      true,
                      buyCurrency,
                    )
                    : `0 ${sellCurrency.toUpperCase()}`}
                </>
              )}
              { showFor === 'sell' && (
                <>
                  { mappedCurrencies[buyCurrency].tradable_balance > 0
                    ? formatCurrency(
                      mappedCurrencies[buyCurrency].tradable_balance,
                      mappedCurrencies[buyCurrency],
                      true,
                      true,
                      buyCurrency,
                    )
                    : `0 ${buyCurrency.toUpperCase()}`}
                </>
              )}
            </Typography>
          </Box>
        </Box>
        {
          showFor === 'buy' ? (
            <Box className={classes.buySportsBtn} mt={2} mb={2}>
              <OutlineButton onClick={handleSubmitOrder}>
                { `Buy ${buyCurrency.toUpperCase()}` }
              </OutlineButton>
            </Box>
          ) : (
            <Box className={classes.sellSportsBtn} mt={2} mb={2}>
              <OutlineButton onClick={handleSubmitOrder}>
                { `Sell ${buyCurrency.toUpperCase()}` }
              </OutlineButton>
            </Box>
          )
        }
      </Box>
      <Divider className={classes.divider} />
      <Box className={classes.orderMarketTabs}>
        <Tabs tabs={tabsList} hidePadding />
      </Box>
    </Box>
  );
};

Trade.propTypes = {
  market: PropTypes.object.isRequired,
  mappedCurrencies: PropTypes.object.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Trade);
