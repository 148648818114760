import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import {
  Box, Fab, Grid, TextField, Typography, makeStyles,
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import Page from 'components/Page/Auth';
import BackButton from 'components/Buttons/BackButton';
import UploadPhoto from 'components/UploadPhoto';
import SportsGroupImage from 'assets/images/sports-group.png';

import { registerAction } from 'store/actions/auth';
import { useOpenErrorModalDispatch } from 'hooks/redux/modal';
import { checkPasswordRules, getErrorMessage } from 'utils';
import { passwordRuleMessage } from 'utils/constants';

const useStyles = makeStyles({
  form: {
    '& .MuiFormHelperText-root': {
      marginTop: '10px',
      letterSpacing: 0,
      lineHeight: '1em',
    },
  },
  input: {
    '& input::placeholder': {
      opacity: '1 !important',
    },
  },
  loginBtn: {
    border: '1px solid white',
    borderRadius: '5px',
    color: 'white',
    padding: '10px 6px 5px',
    textTransform: 'uppercase',
    fontSize: '15px',
    fontWeight: 'bold',
  },
  subTitle: {
    fontSize: '14px',
    color: 'white',
    textAlign: 'center',
  },
});

const SignUp = ({ history, register }) => {
  const openErrorModal = useOpenErrorModalDispatch();
  const classes = useStyles();

  const handleLogin = () => {
    history.push('/login');
  };

  return (
    <Page backgroundImage={SportsGroupImage}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <BackButton page="/" />
        <Box className={classes.loginBtn} onClick={handleLogin}>Login</Box>
      </Box>
      <Box mt={1} mb={2}>
        <Typography variant="h1" className="text-center color-white">
          Join With Email
        </Typography>
        <Typography variant="subtitle1" className={classes.subTitle}>
          Creating a new ZenSports account using your email is easy!
          Just enter in your first and last name, email and a password.
          Don’t forget to upload a picture - ZenSports is a social product.
          {' '}
          <span role="img" aria-label="emoji">😎</span>
        </Typography>
      </Box>
      <Formik
        initialValues={{
          first_name: '',
          last_name: '',
          email: '',
          password: '',
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            setSubmitting(true);
            if (!checkPasswordRules(values.password)) {
              openErrorModal({
                title: 'Password Requirements',
                subtitle: passwordRuleMessage,
              });
              return;
            }
            await register(values);
            history.push('/welcome');
          } catch (e) {
            openErrorModal({ title: 'Oops', subtitle: getErrorMessage(e) });
          }
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email()
            .required('Required'),
          first_name: Yup.string()
            .required('Required'),
          last_name: Yup.string()
            .required('Required'),
          password: Yup.string()
            .required('Required')
            .min(8),
        })}
      >
        {({
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form className={classes.form} onSubmit={handleSubmit} noValidate autoComplete="off">
            <Grid container mt={2}>
              <Grid item xs={6}>
                <Box pr={1}>
                  <TextField
                    id="first_name"
                    label="First Name"
                    placeholder="First Name"
                    autoComplete="off"
                    className={classes.input}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      className: classes.input,
                    }}
                    name="first_name"
                    value={values.first_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={(errors.first_name && touched.first_name) && errors.first_name}
                    error={errors.first_name && touched.first_name}
                  />
                </Box>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Box pl={1}>
                  <TextField
                    id="last_name"
                    label="Last Name"
                    placeholder="Last Name"
                    autoComplete="off"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      className: classes.input,
                    }}
                    name="last_name"
                    value={values.last_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={(errors.last_name && touched.last_name) && errors.last_name}
                    error={errors.last_name && touched.last_name}
                  />
                </Box>
              </Grid>
            </Grid>
            <Box mt={3}>
              <TextField
                id="email"
                label="Email"
                placeholder="Email"
                autoComplete="off"
                fullWidth
                mt={3}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  className: classes.input,
                }}
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={(errors.email && touched.email) && errors.email}
                error={errors.email && touched.email}
              />
            </Box>
            <Box mt={3}>
              <TextField
                id="password"
                label="Password"
                type="password"
                placeholder="Password"
                autoComplete="off"
                fullWidth
                mt={3}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  className: classes.input,
                  type: 'password',
                }}
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={(errors.password && touched.password) ? errors.password : `Passwords must be at least 8 characters and contain 
                a combination of at least two of the following criteria: Upper case letters, lower case letters, numeric, and/or special characters.`}
                error={errors.password && touched.password}
              />
            </Box>
            <Box mt={2} display="flex" justifyContent="center">
              <UploadPhoto />
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mt={3}
            >
              <Box className="font-small color-white" css={{ maxWidth: '220px' }}>
                By pressing Continue, you’re agreeing to ZenSports’
                <a className="color-primary" href="https://zensports.com/tos">Terms of Service</a>
                &nbsp;&&nbsp;
                <a className="color-primary" href="https://zensports.com/privacy">Privacy Policy</a>
              </Box>
              <Fab type="submit" aria-label="SignUp" disabled={isSubmitting}>
                <ArrowForwardIcon />
              </Fab>
            </Box>
          </form>
        )}
      </Formik>
    </Page>
  );
};

SignUp.propTypes = {
  history: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  register: (data) => dispatch(registerAction(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignUp));
