import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Checkbox,
  Grid,
  InputBase,
  Typography,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Circle from '@material-ui/icons/PanoramaFishEye';
import CheckCircle from '@material-ui/icons/CheckCircle';

import BetResultInfoCard from 'components/Bet/BetResultInfoCard';
import OutlineButton from 'components/Buttons/OutlineButton';
import resultIconImage from 'assets/images/icon/result.png';

import { useDisputeResultsDispatch } from 'hooks/redux/bet';
import {
  useOpenErrorModalDispatch,
  useOpenConfirmModalDispatch,
  useOpenSuccessModalDispatch,
} from 'hooks/redux/modal';
import { getErrorMessage } from 'utils';

const PrimaryCheckbox = withStyles({
  root: {
    color: '#979797',
    '&.Mui-checked': {
      color: '#63C4C6',
    },
  },
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles(({ palette }) => ({
  icon: {
    marginLeft: '5px',
    color: '#29CCD3',
    fontSize: '20px',
  },
  textArea: {
    padding: 10,
    borderRadius: '5px',
    color: palette.secondaryColor,
    backgroundColor: palette.dimGray,
  },
  disputeContent: {
    color: palette.secondaryColor,
    font: '14px SofiaPro',
    textAlign: 'left',
    marginBottom: '10px',
    '& span': {
      display: 'block',
    },
  },
  accept: {
    borderTop: '1px solid #cccccc',
    borderBottom: '1px solid #cccccc',
  },
  subtitle: {
    color: palette.secondaryColor,
    font: '14px SofiaPro-Medium',
    paddingBottom: '5px',
  },
  smallTitle: {
    color: palette.secondaryColor,
    font: '12px SofiaPro-Black',
    paddingBottom: '8px',
  },
  amount: {
    color: palette.secondaryColor,
    font: '18px SofiaPro-Bold',
    textAlign: 'center',
    paddingBottom: '5px',
  },
  total: {
    borderBottom: '1px solid #cccccc',
  },
  results: ({ resultColor }) => ({
    backgroundColor: palette.type === 'light' ? resultColor : palette.cardBetColor,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '16px 5px',
  }),
  resultImage: {
    width: '60px',
  },
  noResult: {
    color: '#A4A4A4',
    font: '14px SofiaPro-Bold',
  },
  card: {
    backgroundColor: palette.cardColor,
    width: '90%',
    borderRadius: '6px',
    padding: '10px',
    marginBottom: '10px',
  },
  cardTitle: {
    paddingBottom: '5px',
    color: palette.resultsColor,
    textAlign: 'center',
  },
  content: {
    color: palette.secondaryColor,
    font: '16px SofiaPro-Bold',
    textAlign: 'center',
  },
  textBlock: {
    '& p': {
      color: '#aeaeae',
      font: '12px SofiaPro',
      textAlign: 'left',
      marginBottom: '10px',
    },
  },
  percentage: {
    color: palette.secondaryColor,
    font: '15px SofiaPro-Medium',
    textAlign: 'center',
    paddingBottom: '5px',
  },
}));

const ResultView = ({ betInfo }) => {
  const classes = useStyles({
    resultColor: (betInfo.status === 'results_set' || betInfo.status === 'settled') ? '#d3f2f5' : '#eeeeee',
  });
  const [details, setDetails] = useState('');
  const [isDispute, setIsDispute] = useState(false);
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const openSuccessModal = useOpenSuccessModalDispatch();
  const openErrorModal = useOpenErrorModalDispatch();
  const openConfirmModal = useOpenConfirmModalDispatch();
  const disputeResults = useDisputeResultsDispatch();

  const handleDispute = async () => {
    if (!details) {
      openErrorModal({ title: 'Oops', subtitle: 'Please enter reason!' });
      return;
    }
    if (isDispute && betInfo.selectedBetId) {
      const data = { details };
      openConfirmModal({
        title: 'Are you sure?',
        subtitle: `Are you 100% sure that you want to submit a dispute for these bet results? This can NOT be undone, and if you end up not
          winning the dispute, not only will you lose your original bet amount, but you'll also pay a 5% penalty for submitting a
          false/erroneous dispute.`,
        agreeBtnText: 'Yes, Submit Dispute',
        disagreeBtnText: 'No, Don\'t Submit',
        callback: async () => {
          setDisabledSubmit(true);
          try {
            await disputeResults({
              betId: betInfo.selectedBetId,
              data,
            });
            setDisabledSubmit(false);
            openSuccessModal({
              title: 'Dispute received',
              subtitle: `The internal compliance team at ZenSports has been notified of your dispute, as has the maker of the bet. ZenSports will
              make a final determination on the bet results within 24 hours. You'll be notified of the dispute results and any winnings/losses
              plus penalties will automatically be added/subtracted from your account balance at that time.`,
              buttonText: 'Got it',
              nextPath: '/bets/results',
            });
          } catch (e) {
            setDisabledSubmit(false);
            openErrorModal({
              title: 'Dispute Error',
              subtitle: getErrorMessage(e),
            });
          }
        },
      });
    }
  };

  return (
    <>
      <BetResultInfoCard betInfo={betInfo} showNotice showAcceptTime />
      {(!betInfo.disputes?.length || betInfo.status === 'settled') && (
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          className={classes.total}
          pb={1}
          pt={1}
        >
          <Typography variant="subtitle2" className={classes.subtitle}>
            {(betInfo.result === 'loss') ? 'Total Payout Received, Bet + Winnings' : 'Total You’ll Receive If You Win'}
          </Typography>
          <Typography variant="subtitle2" style={{ color: `${betInfo.currencyColor}` }} className={classes.amount}>
            {betInfo.formattedTotalReceiveAmount}
          </Typography>
        </Box>
      )}
      <Box className={classes.results} p={2}>
        <img alt="bet" src={resultIconImage} className={classes.resultImage} />
        <Typography variant="subtitle2" className={classes.subtitle}>Bet results</Typography>
        {betInfo.result && (
          <>
            <Box display="flex" mt={3} style={{ flexWrap: 'wrap' }} className={classes.card}>
              <Grid item xs={6} sm={6}>
                <Typography variant="subtitle2" className={classes.cardTitle}>
                  Winner of Bet
                </Typography>
                <Typography variant="subtitle2" className={classes.content}>
                  {betInfo.winnerDescription}
                </Typography>
              </Grid>
              {(betInfo.awayTeamPoints && betInfo.homeTeamPoints) && (
                <Grid item xs={6} sm={6}>
                  <Typography variant="subtitle2" className={classes.cardTitle}>
                    Team Scores
                  </Typography>
                  <Typography variant="subtitle2" className={classes.content}>
                    {!betInfo.isSoccerGame ? betInfo.awayTeamName : betInfo.homeTeamName}
                    {' '}
                    {!betInfo.isSoccerGame ? betInfo.awayTeamPoints : betInfo.homeTeamPoints}
                  </Typography>
                  <Typography variant="subtitle2" className={classes.content}>
                    {!betInfo.isSoccerGame ? betInfo.homeTeamName : betInfo.awayTeamName}
                    {' '}
                    {!betInfo.isSoccerGame ? betInfo.homeTeamPoints : betInfo.awayTeamPoints}
                  </Typography>
                </Grid>
              )}
              {betInfo.resultDetails && (
                <Grid item xs={6} sm={6}>
                  <Typography variant="subtitle2" className={classes.cardTitle}>
                    Result Details
                  </Typography>
                  <Typography variant="subtitle2" className={classes.content}>
                    {betInfo.resultDetails}
                  </Typography>
                </Grid>
              )}
            </Box>
            {betInfo.result === 'win' && betInfo.isDisputedByMe && betInfo.disputeDetails?.length > 0 && (
              <Box className={classes.card}>
                <Box className={classes.subtitle}>Dispute Details</Box>
                {betInfo.disputeDetails.map((detail) => (
                  <Box mt={1}>
                    {detail}
                  </Box>
                ))}
              </Box>
            )}
          </>
        )}
        {(betInfo.status === 'expired' || betInfo.status === 'proposed') && (
          <Box mt={3} textAlign="center">
            <Typography className={classes.noResult}>
              No Results to display for this bet because it was not accepted by any Takers.
            </Typography>
          </Box>
        )}
        {(betInfo.status === 'fully_accepted' || betInfo.status === 'partially_accepted') && (
          <Box className={classes.textBlock} mt={3} textAlign="center">
            <Typography>
              The Maker/Creator of this bet has not yet submitted the results.
            </Typography>
            <Typography>
              <b>
                Once the Maker submits the results of this bet, we will notify you and
                you&apos;ll be able to view and/or dispute them on this screen.
              </b>
            </Typography>
            <Typography>
              If the Maker indicates that you won the bet, your winnings will automatically be released
              into your account balance. If the Maker indicates that they won the bet, you&apos;ll have 24 hours
              to dispute the results, and if you don&apos;t dispute the results within 24 hours, the Maker will
              automatically win the bet. If you dispute the results and are unsuccessful, you will lose not
              only your original bet, but an additional 5% of the bet amount. If you dispute the results
              and are successful, you will win the bet, plus an additional 10% of the bet amount.
            </Typography>
          </Box>
        )}
        {betInfo.result === 'win' && betInfo.status === 'results_set' && betInfo.isDisputedByMe && (
          <Box xs={12} sm={12} className={classes.card}>
            <Typography variant="subtitle2" className={classes.disputeContent}>
              <b>Dispute Status: </b>
              {betInfo.disputeStatus}
            </Typography>
            {(betInfo.disputes.length > 0) && (
              <Typography variant="subtitle2" className={classes.disputeContent}>
                <b>Dispute Details Submitted by Taker: </b>
                {betInfo.disputes.map((detail) => (
                  <span key={detail.details}>
                    {detail.details}
                  </span>
                ))}
              </Typography>
            )}
          </Box>
        )}
      </Box>
      {betInfo.result === 'win' && betInfo.status === 'results_set' && !betInfo.isDisputedByMe && (
        <>
          <Box mt={1} display="flex" alignItems="center" justifyContent="center">
            <Typography className={classes.content}>
              Dispute the bet results?
            </Typography>
            <PrimaryCheckbox
              icon={<Circle />}
              checkedIcon={<CheckCircle />}
              name="checkedH"
              size="medium"
              checked={isDispute}
              onChange={(e) => setIsDispute(e.target.checked)}
            />
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography variant="subtitle2" className={classes.content}>
              {'Reason why you\'re disputing'}
            </Typography>
            <InfoOutlinedIcon className={classes.icon} />
          </Box>
          <Box p={2}>
            <InputBase
              autoComplete="off"
              fullWidth
              multiline
              rows={4}
              className={classes.textArea}
              placeholder="Write your reason here..."
              value={details}
              onChange={(e) => setDetails(e.target.value)}
            />
          </Box>
          <Box p={2}>
            <OutlineButton disabled={disabledSubmit} onClick={() => handleDispute()}>
              Submit Dispute
            </OutlineButton>
          </Box>
        </>
      )}
    </>
  );
};

ResultView.defaultProps = {
  betInfo: {},
};

ResultView.propTypes = {
  betInfo: PropTypes.object,
};

export default ResultView;
