import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Box,
} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import { formatCurrency } from 'utils/currency';

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#eeeeee',
    },
    '&:nth-of-type(even)': {
      backgroundColor: '#fcfcfc',
    },
  },
}))(TableRow);

const useStyles = makeStyles(({ palette }) => ({
  table: {
    width: '100%',
  },
  tableBox: {
    display: 'flex',
    flex: 1,
  },
  thLabel: {
    fontWeight: 550,
    color: '#8988a7',
    padding: '9px 0px 9px 10px',
  },
  tdLabel: {
    padding: '9px 5px 9px 10px',
    borderBottom: 'none',
    fontSize: '12px',
    fontWeight: 'lighter',
    color: palette.secondaryColor,
  },
  tdLabelBlack: {
    padding: '9px 10px 9px 0px',
    borderBottom: 'none',
    textAlign: 'end',
    fontSize: '12px',
    fontWeight: 'lighter',
    color: palette.secondaryColor,
  },
  tdGreen: {
    borderBottom: 'none',
    padding: '9px 10px 9px 0px',
    textAlign: 'end',
    color: '#779c3b',
    fontSize: '12px',
    fontWeight: 'lighter',
  },
  tdRed: {
    borderBottom: 'none',
    padding: '9px 0px 9px 10px',
    color: '#fc5c5c',
    fontSize: '12px',
    fontWeight: 'lighter',
  },
  tBody: {
    '& .MuiTableRow-root:nth-of-type(odd)': {
      backgroundColor: palette.dimGray,
    },
    '& .MuiTableRow-root:nth-of-type(even)': {
      backgroundColor: palette.themeColor,
    },
  },
}));

export default function OrderBookTable({
  sellCurrency,
  buyCurrency,
  buyOrders,
  sellOrders,
  mappedCurrencies,
}) {
  const classes = useStyles();
  const [buyOrdersData, setBuyOrdersData] = useState([]);
  const [sellOrdersData, setSellOrdersData] = useState([]);
  const buyOrdersString = JSON.stringify(buyOrders);
  const sellOrdersString = JSON.stringify(sellOrders);

  useEffect(() => {
    const diff = Math.abs(buyOrders.length - sellOrders.length);
    const sellItems = [...sellOrders];
    const buyItems = [...buyOrders];

    for (let i = 0; i < diff; i += 1) {
      if (buyOrders.length > sellOrders.length) {
        sellItems.push({
          price: '',
          quantity: '',
        });
      } else {
        buyItems.push({
          price: '',
          quantity: '',
        });
      }
    }
    setBuyOrdersData(buyItems);
    setSellOrdersData(sellItems);
    // eslint-disable-next-line
  }, [buyOrdersString, sellOrdersString]);

  return (
    <Box display="flex" position="relative">
      <Box className={classes.tableBox}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.thLabel}>Buy</TableCell>
              <TableCell className={classes.thLabel} />
            </TableRow>
          </TableHead>
          <TableBody className={classes.tBody}>
            {
              buyOrdersData.map((order) => (
                <StyledTableRow key={uuid()}>
                  <TableCell className={classes.tdLabel}>
                    {
                      order.quantity === '' ? '-'
                        : (
                          formatCurrency(
                            order.quantity,
                            mappedCurrencies[buyCurrency],
                            false,
                            true,
                            buyCurrency,
                          )
                        )
                    }
                  </TableCell>
                  <TableCell className={classes.tdGreen}>
                    {
                      order.price === '' ? '-'
                        : (
                          formatCurrency(
                            order.price,
                            mappedCurrencies[sellCurrency],
                            false,
                            true,
                            buyCurrency,
                          )
                        )
                    }
                  </TableCell>
                </StyledTableRow>
              ))
            }
          </TableBody>
        </Table>
      </Box>
      <Box className={classes.tableBox}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.thLabel}>Sell</TableCell>
              <TableCell className={classes.thLabel} />
            </TableRow>
          </TableHead>
          <TableBody className={classes.tBody}>
            {
              sellOrdersData.map((order) => (
                <StyledTableRow key={uuid()}>
                  <TableCell className={classes.tdRed}>
                    {
                      order.price === '' ? '-'
                        : (
                          formatCurrency(
                            order.price,
                            mappedCurrencies[sellCurrency],
                            false,
                            true,
                            buyCurrency,
                          )
                        )
                    }
                  </TableCell>
                  <TableCell className={classes.tdLabelBlack}>
                    {
                      order.quantity === '' ? '-'
                        : (
                          formatCurrency(
                            order.quantity,
                            mappedCurrencies[buyCurrency],
                            false,
                            true,
                            buyCurrency,
                          )
                        )
                    }
                  </TableCell>
                </StyledTableRow>
              ))
            }
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
}

OrderBookTable.propTypes = {
  sellCurrency: PropTypes.string.isRequired,
  buyCurrency: PropTypes.string.isRequired,
  buyOrders: PropTypes.array.isRequired,
  sellOrders: PropTypes.array.isRequired,
  mappedCurrencies: PropTypes.object.isRequired,
};
