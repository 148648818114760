import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import DatePicker from 'react-mobile-datepicker';

import { makeStyles } from '@material-ui/styles';
import {
  Box, FormControl, TextField, Typography,
} from '@material-ui/core';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import OutlineButton from 'components/Buttons/OutlineButton';

import { useAuthUser } from 'hooks/redux/user';
import { useKycUserInfo, useSetKycUserInfoDispatch } from 'hooks/redux/kyc';
import { useOpenErrorModalDispatch } from 'hooks/redux/modal';
import { dateOptions, monthMap } from 'utils/constants';
import CommonStyle from 'styles/common';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 15px',
  },
  line: {
    background: 'linear-gradient(45deg, #298EDA, #29D2D3)',
    height: '3px',
    marginLeft: '-27px',
    width: '40%',
  },
  topic: {
    font: '12px SofiaPro-SemiBold',
    color: palette.colorBtnTxt,
    textTransform: 'capitalize',
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'space-between',
  },
  birthday: {
    padding: '0',
    '& input::placeholder': {
      color: palette.placeholderColor,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: `${palette.grey.A700} !important`,
      },
      '&.Mui-focused fieldset': {
        borderColor: `${palette.grey.A700} !important`,
      },
    },
    '& .MuiInputBase-input': {
      textAlign: 'center',
      color: palette.colorBtnTxt,
      font: '25px SofiaPro-SemiBold',
      opacity: '1',
    },
  },
  datePicker: {
    position: 'absolute',
    width: '100%',
    '& .android': {
      position: 'relative !important',
      top: '15px !important',
      width: '90% !important',
      border: palette.type === 'dark' ? 'none' : '1px solid #d9d4d4',
      margin: 'auto',
      '& .datepicker-content': {
        '& .datepicker-col-1:first-child': {
          order: 3,
        },
        '& .datepicker-col-1:last-child': {
          order: 1,
        },
      },
      '& .datepicker-navbar': {
        flexDirection: 'row-reverse',
        '& .datepicker-navbar-btn:first-child': {
          borderLeft: '1px solid #d9d4d4',
        },
      },
    },
  },
}));

const SubmitButton = ({ birthday, isSubmitting, isResolve }) => {
  const commonClasses = CommonStyle();
  const { setFieldValue } = useFormikContext();
  const handleSubmit = useCallback(async () => {
    await setFieldValue('birthday', birthday);
  }, [setFieldValue, birthday]);

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
      className={commonClasses.continue}
      onClick={handleSubmit}
    >
      <OutlineButton type="submit" disabled={isSubmitting}>
        {isResolve ? 'Done' : 'Next'}
      </OutlineButton>
    </Box>
  );
};

SubmitButton.propTypes = {
  birthday: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isResolve: PropTypes.bool.isRequired,
};

const KycBirthday = ({ history }) => {
  const classes = useStyles();
  const commonClasses = CommonStyle();
  const user = useAuthUser();
  const kycInfo = useKycUserInfo();
  const setKycUserInfo = useSetKycUserInfoDispatch();
  const openErrorModal = useOpenErrorModalDispatch();
  const [isPopup, setIsPopup] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [birthday, setBirthday] = useState('');

  const isResolve = history.location.state === 'resolve';

  const handleSelect = (date) => {
    setIsPopup(!isPopup);
    setIsOpen(false);
    setBirthday(date.toLocaleString('en-US', dateOptions));
  };
  const handleDatePop = () => {
    setIsPopup(!isPopup);
    setIsOpen(false);
  };

  useEffect(() => {
    if (user.id) {
      setKycUserInfo({
        dob: user.dob,
      });
    }
    // eslint-disable-next-line
  }, [user.id]);

  useEffect(() => {
    setBirthday(new Date(kycInfo.dob).toLocaleString('en-US', dateOptions));
  }, [kycInfo.dob]);

  return (
    <Page>
      <Box className={classes.root} display="flex" flexDirection="column" flex={1}>
        <Box className={classes.line} mb={2} />
        <Box>
          <BackButton color={commonClasses.backBtn} page={isResolve ? '/kyc/resolve' : '/kyc/profile'} />
        </Box>
        <Box textAlign="center" mt={3} mb={1}>
          <Typography component="h3" className={classes.topic}>
            KYC/AML VERIFICATION FORM
          </Typography>
        </Box>
        <Box textAlign="center" mb={6}>
          <Typography component="h3" className={classes.title}>
            Date of Birth
          </Typography>
        </Box>
        {
          kycInfo.name !== '' && (
            <Formik
              initialValues={{
                birthday: new Date(kycInfo.dob).toLocaleString('en-US', dateOptions),
              }}
              onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(true);
                const diffMs = Date.now() - new Date(values.birthday).getTime();
                const ageDate = new Date(diffMs);
                const age = Math.abs(ageDate.getUTCFullYear() - 1970);
                if (age >= 18) {
                  setKycUserInfo({
                    dob: birthday,
                  });
                  const route = isResolve ? '/kyc/resolve' : '/kyc/profile/country';
                  history.push(route);
                } else {
                  const message = 'Unfortunately, since you`re less than 18 years of age we won`t be able to give you access to ZenSports.'
                    + ' We`ll be waiting for you once you`re a few years older.';
                  openErrorModal({ title: 'Oops', subtitle: message });
                }
              }}
              validationSchema={Yup.object().shape({
                birthday: Yup.date()
                  .required(),
              })}
            >
              {({
                errors,
                touched,
                isSubmitting,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.form}>
                  <FormControl
                    className={classes.birthday}
                    variant="outlined"
                    fullWidth
                    onClick={() => handleDatePop()}
                  >
                    <div style={{ display: 'inline-block', position: 'relative' }} onClick={() => handleDatePop()}>
                      <TextField
                        id="birthday"
                        variant="outlined"
                        name="birthday"
                        autoComplete="off"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={birthday}
                        disabled
                        placeholder="dd/mm/yyyy"
                        onClick={() => handleDatePop()}
                        aria-describedby="outlined-birthday-helper-text"
                        inputProps={{
                          'aria-label': 'birthday',
                        }}
                        error={errors.birthday && touched.birthday}
                        helperText={(errors.birthday && touched.birthday) && errors.birthday}
                      />
                      <div style={{
                        position: 'absolute',
                        left: '0',
                        right: '0',
                        top: '0',
                        bottom: '0',
                      }}
                      />
                    </div>
                    <Box className={classes.datePicker}>
                      <DatePicker
                        isPopup={isPopup}
                        isOpen={isOpen}
                        theme="android"
                        max={new Date()}
                        onSelect={handleSelect}
                        onCancel={() => setIsPopup(!isPopup)}
                        dateConfig={
                          {
                            year: {
                              format: 'YYYY',
                              caption: 'Year',
                              step: 1,
                            },
                            month: {
                              format: (value) => monthMap[value.getMonth() + 1],
                              caption: 'Mon',
                              step: 1,
                            },
                            date: {
                              format: 'DD',
                              caption: 'Day',
                              step: 1,
                            },
                          }
                        }
                        showHeader={false}
                        cancelText="Cancel"
                        confirmText="OK"
                      />
                    </Box>
                  </FormControl>
                  <SubmitButton
                    birthday={birthday}
                    isSubmitting={isSubmitting}
                    isResolve={isResolve}
                  />
                </form>
              )}
            </Formik>
          )
        }
      </Box>
    </Page>
  );
};

KycBirthday.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(KycBirthday);
