/* eslint-disable */
export const isParlayAvailable = (bet, parlay) => {
  let result = false;
  if (bet?.maker_is_zensports) {
    result = true;
    if (parlay?.currency) {
      result = bet?.currency?.symbol === parlay.currency.symbol
    }
    if (result && parlay?.list) {
      result = parlay.list.findIndex((item) => item.id === bet.id) === -1;
    }
  }
  return result;
};
