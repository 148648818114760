import * as actionTypes from 'store/actionTypes';

const INITIAL_STATE = {
  isLoading: false,
  resourceToken: '',
  countries: [],
  user: {
    dob: '',
    name: '',
    email: '',
    country: {
      id: 0,
      name: '',
      country_code: '',
    },
    state: {
      id: 0,
      name: '',
      abbreviation: '',
    },
    city: '',
    postal_code: '',
    street_1: '',
    street_2: '',
  },
  documentation: {
    country: {
      id: 0,
      name: '',
      country_code: '',
    },
    state: {
      id: 0,
      name: '',
      abbreviation: '',
    },
    documentType: {},
    tax_id_number: '',
    uploaded_front_id: '',
    uploaded_back_id: '',
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_KYC_COUNTRIES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_KYC_COUNTRIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        countries: action.payload,
      };
    case actionTypes.GET_KYC_COUNTRIES_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.GET_KYC_TOKEN_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_KYC_TOKEN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        resourceToken: action.payload,
      };
    case actionTypes.GET_KYC_TOKEN_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.CREATE_KYC_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.CREATE_KYC_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.CREATE_KYC_USER_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.UPDATE_KYC_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.UPDATE_KYC_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.UPDATE_KYC_USER_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.UPDATE_CREATED_KYC_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.UPDATE_CREATED_KYC_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.UPDATE_CREATED_KYC_USER_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.UPLOAD_KYC_DOC_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.UPLOAD_KYC_DOC_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.UPLOAD_KYC_DOC_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.SET_KYC_USER_INFO:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    case actionTypes.SET_KYC_DOC_INFO:
      return {
        ...state,
        documentation: {
          ...state.documentation,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
