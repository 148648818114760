import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import {
  Table,
  TableContainer,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import { formatCurrency } from 'utils/currency';

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#eeeeee',
    },
    '&:nth-of-type(even)': {
      backgroundColor: '#fcfcfc',
    },
  },
}))(TableRow);

const useStyles = makeStyles(({ palette }) => ({
  table: {
    width: '100%',
  },
  thLabel: {
    color: '#8988a7',
    fontWeight: 550,
    padding: '9px 0px 9px 12px',
  },
  thCenterLabel: {
    color: '#8988a7',
    fontWeight: 550,
    textAlign: 'center',
    padding: '9px 0px 9px 0px',
  },
  tdLabel: {
    padding: '9px 0px 9px 12px',
    color: palette.secondaryColor,
    borderBottom: 'none',
  },
  tdGreen: {
    padding: '9px 0px 9px 12px',
    borderBottom: 'none',
    textAlign: 'center',
    color: '#779c3b',
  },
  tdRed: {
    borderBottom: 'none',
    textAlign: 'center',
    color: '#fc5c5c',
    padding: '9px 0px 9px 12px',
  },
  tdLabelBlack: {
    padding: '9px 0px 9px 12px',
    borderBottom: 'none',
    textAlign: 'end',
    color: palette.secondaryColor,
  },
  tBody: {
    '& .MuiTableRow-root:nth-of-type(odd)': {
      backgroundColor: palette.dimGray,
    },
    '& .MuiTableRow-root:nth-of-type(even)': {
      backgroundColor: palette.themeColor,
    },
  },
}));

export default function MarketTradeTable({
  sellCurrency,
  buyCurrency,
  trades,
  mappedCurrencies,
}) {
  const classes = useStyles();

  return (
    <TableContainer>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.thLabel}>Time(UTC)</TableCell>
            <TableCell className={classes.thCenterLabel}>Price</TableCell>
            <TableCell className={classes.thLabel}>Quantity</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tBody}>
          {
            trades && trades.map((trade) => (
              <StyledTableRow key={uuid()}>
                <TableCell className={classes.tdLabel}>
                  {
                    trade.time.slice(11, 19)
                  }
                </TableCell>
                <TableCell className={trade.is_buying ? classes.tdGreen : classes.tdRed}>
                  {
                    formatCurrency(
                      trade.price,
                      mappedCurrencies[sellCurrency],
                      false,
                      true,
                      buyCurrency,
                    )
                  }
                </TableCell>
                <TableCell className={classes.tdLabelBlack}>
                  {
                    formatCurrency(
                      trade.quantity,
                      mappedCurrencies[buyCurrency],
                      false,
                      true,
                      buyCurrency,
                    )
                  }
                </TableCell>
              </StyledTableRow>
            ))
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}

MarketTradeTable.propTypes = {
  sellCurrency: PropTypes.string.isRequired,
  buyCurrency: PropTypes.string.isRequired,
  trades: PropTypes.array.isRequired,
  mappedCurrencies: PropTypes.object.isRequired,
};
