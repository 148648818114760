import React from 'react';
import PropTypes from 'prop-types';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { betMappedAcceptTypes } from 'utils/constants';

const useStyles = makeStyles(({ palette }) => ({
  card: {
    background: palette.cardColor,
    padding: '10px 10px 0',
    borderRadius: '5px 5px 0 0',
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 4px',
    position: 'relative',
    overflow: 'hidden',
  },
  accept: ({ acceptColor }) => ({
    backgroundColor: `${acceptColor}`,
    color: '#FFF',
    justifyContent: 'center',
    font: '12px SofiaPro-Medium',
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    padding: '4px 0',
  }),
  info: {
    padding: '5px 0',
    margin: '10px 0',
    display: 'flex',
  },
  imageGroup: {
    display: 'flex',
    flex: 3,
    position: 'relative',
  },
  image: {
    position: 'absolute',
    width: '30px',
    height: '30px',
    overflow: 'hidden',
    border: '1px solid white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    '& img': {
      width: '100%',
      height: 'auto',
    },
  },
  title: {
    color: palette.secondaryColor,
    font: '16px SofiaPro-SemiBold',
    letterSpacing: -0.3,
    margin: '5px 0px',
  },
  subTitle: {
    color: palette.placeholderColor,
    font: '12px SofiaPro',
  },
  oddsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: '1px solid #F1F1F5',
    padding: '10px 0',
  },
  oddsTopic: {
    backgroundColor: '#FC5A5A',
    color: '#FFF',
    padding: '4px 10px 3px 10px',
    font: '13px SofiaPro-Medium',
    borderRadius: '20px',
  },
  odds: {
    color: '#20CFD4',
    font: '16px SofiaPro-Medium',
    letterSpacing: -0.25,
    margin: '0 10px',
  },
  amountTopic: {
    backgroundColor: '#6236FF',
    color: '#FFF',
    padding: '4px 10px 3px 10px',
    font: '13px SofiaPro-Medium',
    borderRadius: '20px',
  },
  amount: ({ currencyColor }) => ({
    color: currencyColor,
    font: '16px SofiaPro-Medium',
    letterSpacing: -0.2,
    flex: 1,
    margin: '0 10px',
    textAlign: 'right',
  }),
}));

const ParlayResultCard = ({ parlayInfo, showAcceptType, onClick }) => {
  const {
    id, currencyColor, acceptType, bets, formattedOddsAmount, formattedAmount, formattedAmountLabel,
  } = parlayInfo;
  const classes = useStyles({
    currencyColor,
    acceptColor: acceptType ? betMappedAcceptTypes[acceptType].color : '#FFF',
  });

  return (
    <Box className={classes.card} onClick={onClick}>
      {showAcceptType && betMappedAcceptTypes[acceptType] && (
        <Box display="flex" className={classes.accept}>{betMappedAcceptTypes[acceptType].label}</Box>
      )}
      <Box className={classes.info}>
        <Box className={classes.imageGroup}>
          {bets?.slice(0, 3)?.map((bet, index) => (
            <Box
              key={bet.id}
              className={classes.image}
              style={{
                left: index === 0 ? 0 : 25 * index,
                zIndex: index + 1,
                background: bet.backgroundColor,
              }}
            >
              <img src={bet.imageUrl} alt="" />
            </Box>
          ))}
          {bets?.length > 3 && (
            <Box
              className={classes.image}
              style={{
                left: 25 * 3,
                zIndex: 4,
                background: '#14133D',
                color: 'white',
              }}
            >
              {`+${bets.length - 3}`}
            </Box>
          )}
        </Box>
        <Box flex={5}>
          <Typography variant="subtitle1" className={classes.title}>
            {`Parlay Card ID: ${id}`}
          </Typography>
          <Typography variant="subtitle1" className={classes.subTitle}>
            {`Number of Bets: ${bets.length}`}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.oddsContainer}>
        <Typography variant="subtitle1" className={classes.oddsTopic}>
          Odds
        </Typography>
        <Typography variant="subtitle1" className={classes.odds}>
          {formattedOddsAmount}
        </Typography>
      </Box>
      <Box className={classes.oddsContainer}>
        <Typography variant="subtitle1" className={classes.amountTopic}>
          {formattedAmountLabel}
        </Typography>
        <Typography variant="subtitle1" className={classes.amount}>
          {formattedAmount}
        </Typography>
      </Box>
    </Box>
  );
};

ParlayResultCard.defaultProps = {
  parlayInfo: {},
  showAcceptType: true,
  onClick: () => {},
};

ParlayResultCard.propTypes = {
  parlayInfo: PropTypes.object,
  showAcceptType: PropTypes.bool,
  onClick: PropTypes.func,
};

export default ParlayResultCard;
