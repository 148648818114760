import React from 'react';
// import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Modal } from '@material-ui/core';

import {
  useConfirmModal, useShowConfirmModal, useCloseModalDispatch,
} from 'hooks/redux/modal';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: palette.secondaryColor,
  },
  paper: {
    background: palette.cardColor,
    padding: '18px',
    width: 300,
  },
  title: {
    margin: 0,
    font: '20px SofiaPro-Bold',
    color: palette.secondaryColor,
  },
  content: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginTop: '15px',
    fontSize: '16px',
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginTop: '20px',
    '& p': {
      fontSize: '14px',
    },
  },
  agreeButton: {
    color: '#0091FF',
    fontWeight: 'bold',
    margin: 0,
    textTransform: 'uppercase',
    marginLeft: '20px',
  },
  disagreeButton: {
    color: '#AEAEAE',
    fontWeight: 'bold',
    margin: '0 0 0 30px',
    textTransform: 'uppercase',
  },
  border: {
    marginTop: 30,
    width: '110%',
    marginLeft: '-18px',
  },
}));

const ConfirmModal = () => {
  const rootRef = React.useRef(null);
  const classes = useStyles();
  const showModal = useShowConfirmModal();
  const modal = useConfirmModal();
  const closeModal = useCloseModalDispatch();

  const handleCallback = () => {
    closeModal();
    modal.callback();
  };

  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={showModal}
      aria-labelledby="server-modal-title"
      aria-describedby="server-modal-description"
      className={classes.root}
      container={() => rootRef.current}
    >
      <div className={classes.paper}>
        <h2 className={classes.title}>{modal.title}</h2>
        <div
          id="server-modal-description"
          className={classes.content}
          dangerouslySetInnerHTML={{ __html: modal.subtitle }}
        />
        <div className={classes.buttonGroup}>
          <p onClick={handleCallback} className={classes.agreeButton}>{modal.agreeBtnText}</p>
          {modal.disagreeBtnText?.length > 0 && (
            <p onClick={closeModal} className={classes.disagreeButton}>{modal.disagreeBtnText}</p>
          )}
        </div>
      </div>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  // history: PropTypes.object.isRequired,
};

export default withRouter(ConfirmModal);
