import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@material-ui/core';
import Circle from '@material-ui/icons/PanoramaFishEye';
import CheckCircle from '@material-ui/icons/CheckCircle';

import BlankImage from 'assets/images/profile.png';

const PrimaryCheckbox = withStyles({
  root: {
    color: '#979797',
    '&.Mui-checked': {
      color: '#63C4C6',
    },
  },
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles(({ palette }) => ({
  imageUrl: {
    width: '56px',
    height: '56px',
    backgroundColor: 'transparent',
    borderRadius: '50%',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      width: '100%',
      height: 'auto',
    },
  },
  teamImageUrl: {
    width: '20px',
    height: '20px',
    position: 'absolute',
    top: '0px',
    left: '50px',
    backgroundColor: '#F6F6F6',
    border: '2px solid #fff',
    borderRadius: '50%',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      width: '100%',
      height: 'auto',
    },
  },
  playerName: {
    color: palette.secondaryColor,
    font: '19px SofiaPro-SemiBold',
    paddingLeft: '19px',
  },
  teamName: (props) => ({
    color: props.type === 'team' ? palette.secondaryColor : '#a4a4a4',
    font: props.type === 'team' ? '19px SofiaPro-SemiBold' : '14px SofiaPro',
    paddingLeft: '19px',
  }),
  checkBox: {
    margin: '0px',
  },
}));

const PlayerCard = ({
  id,
  type,
  playerName,
  teamName,
  imageUrl,
  teamImageUrl,
  selectedId,
  selectPlayer,
}) => {
  const classes = useStyles({ imageUrl, type });
  return (
    <Box alignItems="center" display="flex" justifyContent="space-between" mb={4}>
      <Box display="flex" position="relative">
        <Box className={classes.imageUrl}>
          <img src={imageUrl || BlankImage} alt="Team" />
        </Box>
        {teamImageUrl && (
          <Box className={classes.teamImageUrl}>
            <img src={teamImageUrl} alt="Team" />
          </Box>
        )}
      </Box>
      <Box pl={2} flex={1} alignItems="flex-start" display="flex" flexDirection="column" justifyContent="center">
        <Typography variant="subtitle1" className={classes.playerName}>
          {playerName}
        </Typography>
        <Typography variant="subtitle1" className={classes.teamName}>
          {teamName}
        </Typography>
      </Box>
      <FormControlLabel
        control={(
          <PrimaryCheckbox
            icon={<Circle style={{ fontSize: 32, color: '#CCCCCC' }} />}
            checkedIcon={<CheckCircle style={{ fontSize: 32 }} />}
            checked={id === selectedId}
            onChange={(e) => selectPlayer(e.currentTarget.value, e.target.checked)}
            name="checkedH"
            size="medium"
            value={id}
          />
        )}
        label=""
        className={classes.checkBox}
      />
    </Box>
  );
};

PlayerCard.defaultProps = {
  imageUrl: null,
  teamImageUrl: null,
  type: 'team',
  teamName: null,
  playerName: null,
  id: 0,
  selectedId: 0,
  selectPlayer: () => {},
};

PlayerCard.propTypes = {
  imageUrl: PropTypes.string,
  teamImageUrl: PropTypes.string,
  type: PropTypes.string,
  teamName: PropTypes.string,
  playerName: PropTypes.string,
  id: PropTypes.number,
  selectedId: PropTypes.number,
  selectPlayer: PropTypes.func,
};

export default PlayerCard;
