import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import commonStyles from 'styles/common';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import OutlineButton from 'components/Buttons/BorderOutlineButton';
import InputBox from 'components/Form/InputBox';

import { useGetTournamentDetail, useRegisterForTournamentDispatch } from 'hooks/redux/play';
import { useAuthUser } from 'hooks/redux/user';
import { useOpenConfirmModalDispatch, useOpenErrorModalDispatch } from 'hooks/redux/modal';
import { getErrorMessage } from 'utils';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0 15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  form: {
    height: '100%',
  },
  backBtn: {
    color: theme.palette.secondaryColor,
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: theme.palette.secondaryColor,
    textAlign: 'center',
    margin: 'auto',
  },
  sportLabel: {
    marginTop: 15,
    fontWeight: 'bold',
    color: theme.palette.secondaryColor,
    fontSize: '15px',
  },
  subtitle: {
    color: '#d4d2d2',
    marginTop: 15,
  },
  info: {
    boxShadow: '2px 2px 9px rgb(0 0 0 / 20%)',
    background: theme.palette.cardColor,
    border: 'none',
    borderRadius: 5,
    '& input': {
      fontSize: '18px',
    },
    '& div': {
      border: 'none',
      borderRadius: 10,
    },
  },
}));

const RegisterUserForTournament = ({ match, history }) => {
  const classes = useStyles();
  const commonClasses = commonStyles();

  const { id } = match.params;
  const details = useGetTournamentDetail(id);
  const registerUser = useRegisterForTournamentDispatch();
  const user = useAuthUser();

  const openConfirmModal = useOpenConfirmModalDispatch();
  const openErrorModal = useOpenErrorModalDispatch();

  return (
    <Page>
      <Formik
        initialValues={{
          discord_id: undefined,
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          const query = {
            payment_type: 'cash',
            user_id: user.id,
            discord_id: values.discord_id,
          };

          openConfirmModal({
            title: 'Competition Registration!',
            subtitle: `Please confirm your entry into this competition. An entry of ${details.fee_display} will be deducted from your wallet.`,
            agreeBtnText: 'Yes',
            disagreeBtnText: 'Cancel',
            callback: async () => {
              try {
                await registerUser(id, query);
                history.push(`/more/play/tournament/view/${id}`);
              } catch (e) {
                const message = getErrorMessage(e);
                openErrorModal({ title: 'Oops', subtitle: message });
              }
            },
          });
        }}
        validationSchema={Yup.object().shape({
          discord_id: Yup.number().optional(),
        })}
      >
        {({
          errors,
          touched,
          values,
          isSubmitting,
          handleChange,
          handleSubmit,
          handleBlur,
        }) => (
          <form onSubmit={handleSubmit} noValidate autoComplete="off" className={classes.form}>
            <Box className={classes.root}>
              <Box>
                <Box>
                  <BackButton color={classes.backBtn} />
                </Box>
                <Box textAlign="center" mb={4} mt={2}>
                  <Typography component="h3" className={classes.title}>
                    Register for Competition
                  </Typography>
                  <Typography component="h3" className={classes.subtitle}>
                    To register for the competition please enter your Discord ID
                    so that you can be identified in our Discord Channels.
                  </Typography>
                </Box>
                <Box>
                  <Typography className={classes.sportLabel}>Discord ID</Typography>
                  <Box className={classes.info} mt={1}>
                    <InputBox
                      label="Enter Discord ID"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.discord_id}
                      name="discord_id"
                      type="number"
                      format="outline"
                      isWritable
                    />
                  </Box>
                  {
                    errors.discord_id && touched.discord_id
                      ? (
                        <Typography className={commonClasses.error}>
                          {errors.discord_id}
                        </Typography>
                      ) : null
                  }
                </Box>
              </Box>
              <OutlineButton
                type="submit"
                style={{ margin: '65px 0 20px' }}
                disabled={isSubmitting}
              >
                Register For Competition
              </OutlineButton>
            </Box>
          </form>
        )}
      </Formik>
    </Page>
  );
};

RegisterUserForTournament.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(RegisterUserForTournament);
