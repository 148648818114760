import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import VList from 'components/VList';
import SearchInput from 'components/Form/SearchInput';

import { useCountries } from 'hooks/redux/country';

const useStyles = makeStyles(({ palette }) => ({
  title: {
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
  },
  stateList: {
    flex: 1,
  },
  stateItem: {
    fontSize: '17px',
    fontWeight: '600',
    lineHeight: '25px',
    color: palette.secondaryColor,
    textAlign: 'center',
    marginBottom: '30px',
  },
  stateActiveItem: {
    color: '#63C4C6',
  },
}));

const SelectState = ({
  country,
  state,
  handleSelect,
}) => {
  const [search, setSearch] = useState('');
  const [states, setStates] = useState([]);
  const [searchedStates, setSearchedStates] = useState([]);
  const classes = useStyles();

  const countries = useCountries();

  const handleSearch = (event) => {
    setSearch(event.target.value);
    const filteredStates = states.filter((filteredState) => filteredState.name.toLowerCase().includes(
      event.target.value.toLowerCase(),
    ));
    setSearchedStates(filteredStates);
  };

  useEffect(() => {
    if (countries.length) {
      const data = countries.find((c) => c.id === country.id)?.subdivisions || [];
      setStates(data);
      setSearchedStates(data);
    }
    // eslint-disable-next-line
  }, [countries.length, country, setStates, setSearchedStates]);

  return (
    <>
      <Box textAlign="center" mt={1}>
        <Typography component="h3" className={classes.title}>
          Select State
        </Typography>
      </Box>
      <SearchInput
        handleChange={handleSearch}
        placeholder="Search for your state"
        value={search}
      />
      <Box className={classes.stateList} mt={3}>
        {searchedStates.length > 0 && (
          <VList
            rowCount={searchedStates.length}
            rowRender={(index) => (
              <>
                <Box
                  className={`${classes.stateItem} ${(state.id === searchedStates[index].id) && classes.stateActiveItem}`}
                  onClick={() => handleSelect(searchedStates[index])}
                >
                  {searchedStates[index].name}
                </Box>
              </>
            )}
          />
        )}
      </Box>
    </>
  );
};

SelectState.defaultProps = {
  handleSelect: () => {},
  state: { id: 0 },
  country: { id: 0 },
};

SelectState.propTypes = {
  handleSelect: PropTypes.func,
  country: PropTypes.object,
  state: PropTypes.object,
};

export default SelectState;
