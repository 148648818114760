import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';

import {
  AppBar,
  Tabs,
  Tab,
  Box,
  Divider,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    backgroundColor: palette.background.paper,
    width: '100%',
    '& .MuiTabs-flexContainer .MuiTab-wrapper': {
      color: '#aeaeae',
      fontSize: '10px',
    },
    '& .MuiTabs-flexContainer .Mui-selected .MuiTab-wrapper': {
      color: '#00dde1',
    },
    '& .MuiTabs-indicator': {
      backgroundColor: '#00dde1',
    },
  },
  tabSection: {
    boxShadow: 'none',
    background: palette.themeColor,
    width: 'calc(100% + 20px)',
    marginLeft: -10,
  },
  tabBox: {
    minHeight: 'auto',
    height: 43,
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  tradeHome: {
    padding: '10px 10px !important',
  },
}));

function TabPanel(props) {
  const classes = useStyles();
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className={classes.tradeHome}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

function DefaultTabs({ tabs }) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default" className={classes.tabSection}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
          className={classes.tabBox}
        >
          {
            tabs && tabs.map((tab, index) => <Tab key={tab.label} label={tab.label} {...a11yProps(index)} />)
          }
        </Tabs>
        <Divider />
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {
            tabs && tabs.map((tab, index) => (
              <TabPanel key={tab.label} value={value} index={index} dir={theme.direction}>{tab.component}</TabPanel>
            ))
        }
      </SwipeableViews>
    </div>
  );
}

DefaultTabs.propTypes = {
  tabs: PropTypes.array.isRequired,
};

export default DefaultTabs;
