import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import SelectCountry from 'components/SelectCountry';

import { setKycDocInfoAction } from 'store/actions/kyc';
import CommonStyle from 'styles/common';

const KycDocCountry = ({
  docCountry,
  setKycDocInfo,
}) => {
  const commonClasses = CommonStyle();
  const history = useHistory();
  const handleSelectCountry = (country) => {
    if (country.id !== docCountry.id) {
      if (country.id !== docCountry.id) {
        setKycDocInfo({
          country,
          state: {
            id: 0,
            name: '',
          },
        });
      }
    }
    history.push('/kyc/documentation/country');
  };

  return (
    <Page>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <BackButton color={commonClasses.backBtn} page="/kyc/documentation/country" />
      </Box>
      <SelectCountry
        handleSelect={handleSelectCountry}
        countryId={docCountry.id}
      />
    </Page>
  );
};

KycDocCountry.defaultProps = {
  docCountry: { id: 0 },
};

KycDocCountry.propTypes = {
  docCountry: PropTypes.object,
  setKycDocInfo: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  docCountry: state.kyc.documentation?.country,
});

const mapDispatchToProps = (dispatch) => ({
  setKycDocInfo: (data) => dispatch(setKycDocInfoAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(KycDocCountry);
