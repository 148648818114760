import React from 'react';

import Page from 'components/Page/Dashboard';
import SelectBetType from 'components/Bet/Step/SelectBetType';

const SelectBetTypeWrapper = () => (
  <Page>
    <SelectBetType mode="edit" />
  </Page>
);

export default SelectBetTypeWrapper;
