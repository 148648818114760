import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import referralService from 'services/referral.service';
import { updateUserAction, getUserAction } from 'store/actions/auth';
import { useOpenErrorModalDispatch } from 'hooks/redux/modal';

import {
  TextField,
  Box,
  makeStyles,
  Fab,
  Typography,
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import Page from 'components/Page/Auth';
import BackButton from 'components/Buttons/BackButton';
import SoccerStadiumImage from 'assets/images/soccer-stadium.png';

const useStyles = makeStyles({
  codeBox: {
    margin: '5px 18px 0',
  },
  codeInput: {
    margin: '0 5px',
    fontSize: '40px',
    color: '#63C4C6',
    '& input': {
      textAlign: 'center',
    },
  },
  fab: {
    marginTop: '56px',
    marginRight: '20px',
    float: 'right',
  },
});

const ReferralBonus = ({
  history, user, updateUser, getMe,
}) => {
  const openErrorModal = useOpenErrorModalDispatch();
  const classes = useStyles();

  return (
    <Page backgroundImage={SoccerStadiumImage}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <BackButton />
      </Box>
      <Box mt={3}>
        <Typography variant="h1" className="text-center color-white">
          Referral Bonus Code
        </Typography>
        <Box mt={2} pl={2} pr={2}>
          <Typography variant="subtitle1" className="text-center color-white">
            Have a Referral Bonus Code? Enter one in below,
            and instead of getting only a $100 Free Play Bonus,
            you&apos;ll receive a $500 Free Play Bonus!
            <span role="img" aria-label="emoji">😎</span>
            <span role="img" aria-label="emoji">💵</span>
          </Typography>
        </Box>
        <Box mt={3}>
          <Typography className="text-center color-primary font-small">
            Referral Bonus Code
          </Typography>
        </Box>
        <Formik
          initialValues={{
            bonus_code: '',
          }}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              setSubmitting(true);
              const data = {
                id: user.id,
                country_id: user.country_id,
                dob: user.dob,
                location: user.location,
              };
              if (user.state_id) {
                data.state_id = user.state_id;
              }
              if (user.registration_location_id) {
                data.registration_location_id = user.registration_location_id;
              }
              if (values.bonus_code) {
                const isValid = await referralService.isValidReferralBonus(values.bonus_code);
                if (!isValid.is_valid) {
                  openErrorModal({
                    title: 'Oops',
                    subtitle: 'Referral Bonus Code is invalid.',
                  });
                  return;
                }
                data.referral_code = values.bonus_code;
              }
              await updateUser(data);
              await getMe(false);
              history.push('/bets');
            } catch (e) {
              openErrorModal({
                title: 'Oops',
                subtitle: 'User data is invalid.',
              });
            }
          }}
          validationSchema={Yup.object().shape({
            bonus_code: Yup.string()
              .max(6)
              .min(6),
          })}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleSubmit,
            handleBlur,
          }) => (
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                className={classes.codeBox}
              >
                <TextField
                  id="bonus_code"
                  autoComplete="off"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    className: classes.codeInput,
                  }}
                  name="bonus_code"
                  value={values.bonus_code}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={(errors.bonus_code && touched.bonus_code) && errors.bonus_code}
                  error={errors.bonus_code && touched.bonus_code}
                />
              </Box>
              <Fab type="submit" variant="round" size="large" className={classes.fab} disabled={isSubmitting}>
                <ArrowForwardIcon />
              </Fab>
            </form>
          )}
        </Formik>
      </Box>
    </Page>
  );
};

ReferralBonus.propTypes = {
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  updateUser: PropTypes.func.isRequired,
  getMe: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  updateUser: (data) => dispatch(updateUserAction(data)),
  getMe: () => dispatch(getUserAction()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReferralBonus));
