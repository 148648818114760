import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

import {
  useShowFundsGuide,
  useHideFundsGuideDispatch,
} from 'hooks/redux/currency';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    backgroundColor: 'rgb(238,238,238,0.5)',
    padding: '10px 15px 30px 15px',
  },
  clear: {
    position: 'absolute',
    right: '15px',
  },
  content: {
    marginTop: '30px',
    textAlign: 'left',
    color: '#AEAEAE',
    font: '12px SofiaPro',
  },
});

const DepositGuide = () => {
  const classes = useStyles();
  const showFunds = useShowFundsGuide();
  const hideFunds = useHideFundsGuideDispatch();

  return (
    <Box>
      {showFunds && (
        <Box className={classes.root}>
          <ClearIcon className={classes.clear} onClick={hideFunds} />
          <Typography className={classes.content}>
            ZenSports gives your a few different options for depositing funds into
            your account. You can deposit ZenSports own utility token called SPORTS, which is built on ICON`s protocal.
            You can also deposit Bitcoin, or you can deposit USD via our Skrill integration.
            <br />
            <br />
            If you choose to deposit SPORTS or Bitcoin, we`ll show you a temporary wallet address to send your funds to.
            That temporary wallet address is tied to your ZenSports account, and once funds are received at that temporary wallet address,
            your ZenSports account will automatically be credited with that amount.
            if you choose the Skrill option, you`ll be able to log into your
            Skrill account on the next screen and deposit funds from your credit card or Skrill account.
            <br />
            <br />
            Once you&apos;ve deposited funds, you can then bet and pay for anything in that currency
            You can also exchange between currencies at any time using the &quot;Exchange&quot; feature on the Funds screen.
          </Typography>
        </Box>
      )}
    </Box>
  );
};
DepositGuide.defaultProps = {};

DepositGuide.propTypes = {};

export default DepositGuide;
