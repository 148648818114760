import React, { useCallback } from 'react';
import { withRouter } from 'react-router-dom';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import Card from 'components/Notification';

import {
  useNotificationSettings,
  useUpdateNotificationSettingsDispatch,
} from 'hooks/redux/notification';
import CommonStyle from 'styles/common';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 15px',
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
  },
}));

const SettingNotification = () => {
  const classes = useStyles();
  const commonClasses = CommonStyle();
  const settings = useNotificationSettings();
  const updateSettings = useUpdateNotificationSettingsDispatch();

  const handleUpdateNotification = useCallback(async (id, status) => {
    const data = {
      permissions: [
        {
          group_id: id,
          setting_type: 'email',
          subscribe: status.email,
        },
        {
          group_id: id,
          setting_type: 'notification',
          subscribe: status.notification,
        },
      ],
    };
    await updateSettings(data);
    window.location.reload();
  }, [updateSettings]);

  return (
    <Page>
      <Box className={classes.root}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <BackButton color={commonClasses.backBtn} />
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center" mb={4} mt={4}>
          <Typography component="h3" className={classes.title}>
            Notification Setting
          </Typography>
        </Box>
        {
          settings.map((item) => (
            <Card
              emailSubscribed={item.subscribed_to_emails}
              notificationSubscribed={item.subscribed_to_notifications}
              key={item.id}
              {...item}
              onChange={handleUpdateNotification}
            />
          ))
        }
      </Box>
    </Page>
  );
};

SettingNotification.propTypes = {};

export default withRouter(SettingNotification);
