import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import VList from 'components/VList';
import SearchInput from 'components/Form/SearchInput';

import { useCountries } from 'hooks/redux/country';

const useStyles = makeStyles(({ palette }) => ({
  title: {
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
  },
  countryList: {
    flex: 1,
  },
  countryItem: {
    fontSize: '17px',
    fontWeight: '600',
    lineHeight: '25px',
    color: palette.secondaryColor,
    textAlign: 'center',
    marginBottom: '30px',
  },
  countryActiveItem: {
    color: '#63C4C6',
  },
}));

const SelectCountry = ({
  handleSelect,
  countryId,
}) => {
  const [search, setSearch] = useState('');
  const [searchedCountries, setSearchedCountries] = useState([]);
  const classes = useStyles();
  const countries = useCountries();

  const handleSearch = (event) => {
    setSearch(event.target.value);
    const filteredCountries = countries.filter((country) => country.name.toLowerCase().includes(
      event.target.value.toLowerCase(),
    ));
    setSearchedCountries(filteredCountries);
  };

  useEffect(() => {
    if (countries.length) {
      setSearchedCountries(countries);
    }
    // eslint-disable-next-line
  }, [countries.length]);

  return (
    <>
      <Box textAlign="center" mt={1}>
        <Typography component="h3" className={classes.title}>
          Select Country
        </Typography>
      </Box>
      <SearchInput
        handleChange={handleSearch}
        placeholder="Search for your country"
        value={search}
      />
      <Box className={classes.countryList} mt={3}>
        {searchedCountries.length > 0 && (
          <VList
            rowCount={searchedCountries.length}
            rowRender={(index) => (
              <>
                <Box
                  className={`${classes.countryItem} ${(searchedCountries[index].id === countryId) && classes.countryActiveItem}`}
                  onClick={() => handleSelect(
                    {
                      ...searchedCountries[index],
                      country_code: searchedCountries[index].country_code || searchedCountries[index].abbreviation,
                    },
                  )}
                >
                  {searchedCountries[index].name}
                </Box>
              </>
            )}
          />
        )}
      </Box>
    </>
  );
};

SelectCountry.defaultProps = {
  handleSelect: () => {},
  countryId: 0,
};

SelectCountry.propTypes = {
  handleSelect: PropTypes.func,
  countryId: PropTypes.number,
};

export default SelectCountry;
