import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import {
  getCountriesAction,
  getRegistrationLocationsAction,
} from 'store/actions/country';

export const useCountries = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCountriesAction());
    // eslint-disable-next-line
  }, []);
  return useSelector(({ country: { list } }) => list);
};

export const useStates = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCountriesAction());
    // eslint-disable-next-line
  }, []);
  return useSelector(({ country: { list } }) => list);
};

export const useRegistrationLocations = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getRegistrationLocationsAction());
    // eslint-disable-next-line
  }, []);
  return useSelector(({ country: { registrationLocations } }) => registrationLocations || []);
};
