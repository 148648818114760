import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMarketsListAction,
  getMarketsDetailsAction,
  getMarketChangeAction,
  createExchangeOrderAction,
  getOrdersListAction,
  cancelOrderAction,
  cancelAllOrdersAction,
  hideRollingOverAction,
} from 'store/actions/exchange';

export const useExchangeIsLoading = () => useSelector(({ exchange: { isLoading } }) => isLoading);
export const useOrdersIsLoading = () => useSelector(({ exchange: { isOrdersLoading } }) => isOrdersLoading);
export const useCancelOrdersIsLoading = () => useSelector(({ exchange: { isCancelOrderLoading } }) => isCancelOrderLoading);
export const useShowRollingOver = () => useSelector(({ exchange: { showRollingOver } }) => showRollingOver);

export const useMarketsList = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMarketsListAction());
    // eslint-disable-next-line
  }, []);
  // Had to remove dependencies: dispatch, params to stop infinite rendering.
  return useSelector(({ exchange: { markets } }) => markets);
};

export const useMarketsDetails = (id) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (id) {
      dispatch(getMarketsDetailsAction({ id }));
    }
    // eslint-disable-next-line
  }, [id]);
  // Had to remove dependencies: dispatch, params to stop infinite rendering.
  return useSelector(({ exchange: { marketDetails } }) => marketDetails);
};

export const useGetMarketChange = () => {
  const dispatch = useDispatch();
  return () => dispatch(getMarketChangeAction());
};

export const useCreateExchangeOrder = () => {
  const dispatch = useDispatch();
  return (body) => dispatch(createExchangeOrderAction(body));
};

export const useOrdersList = (params) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (params) {
      dispatch(getOrdersListAction(params));
    }
    // eslint-disable-next-line
  }, [params]);
  // Had to remove dependencies: dispatch, params to stop infinite rendering.
  return useSelector(({ exchange: { orders } }) => orders);
};

export const useCancelOrderDispatch = () => {
  const dispatch = useDispatch();
  return (data) => dispatch(cancelOrderAction(data));
};

export const useCancelAllOrdersDispatch = () => {
  const dispatch = useDispatch();
  return (data) => dispatch(cancelAllOrdersAction(data));
};

export const useHideRollingOverDispatch = () => {
  const dispatch = useDispatch();
  return () => dispatch(hideRollingOverAction());
};
