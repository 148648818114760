import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import { Box, Button, Typography } from '@material-ui/core';

import Page from 'components/Page/Dashboard';

import { useBankDepositInfo } from 'hooks/redux/account';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 15px',
  },
  close: {
    width: '25%',
    background: '#FFFFFF',
    border: '1px solid #29CFD4',
    boxShadow: '0 1px 20px 0 rgba(0,214,219,0.3), 0 3px 10px 0 rgba(0,0,0,0.15)',
    font: '15px SofiaPro-SemiBold',
    letterSpacing: '0.3px',
    color: '#29CDD4',
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
  },
  content: {
    color: '#A4A4A4',
    fontSize: '14px',
    marginBottom: '10px',
    textAlign: 'center',
  },
  subtitle: {
    font: '15px SofiaPro-Bold',
    color: palette.secondaryColor,
  },
  category: {
    font: '15px SofiaPro-Bold',
    color: '#63C4C6',
    lineHeight: 2,
  },
}));

const WireTransfer = ({ history }) => {
  const classes = useStyles();
  const wire = useBankDepositInfo();

  return (
    <Page>
      <Box className={classes.root} display="flex" flexDirection="column" flex={1}>
        <Box display="flex" justifyContent="flex-end">
          <Button className={classes.close} onClick={() => history.push('/funds/deposit')}>
            Close
          </Button>
        </Box>
        <Box textAlign="center" mt={3} mb={3}>
          <Typography component="h3" className={classes.title}>
            Wire Transfer Deposit
          </Typography>
        </Box>
        <Typography className={classes.content}>
          {`The wire instructions below are unique to YOUR ZenSports account,
          and can be saved and re-used as frequently as you wish.
          Once you send funds via the wire instructions listed below,
          we'll notify you that the funds have been received, and your ZenSports account will be credited.`}
        </Typography>
        <Box mt={2} mb={2}>
          <Typography className={classes.subtitle}>
            Wire Instructions
          </Typography>
          {wire && (
            <>
              <Box mt={2} mb={2}>
                <Typography variant="h2" className={classes.category}>
                  Depository Bank Name
                </Typography>
                <Typography>
                  {wire.depository_bank_name}
                </Typography>
              </Box>
              <Box mt={2} mb={2}>
                <Typography variant="h2" className={classes.category}>
                  Bank Address
                </Typography>
                <Typography>
                  {wire.bank_address}
                </Typography>
              </Box>
              <Box mt={2} mb={2}>
                <Typography variant="h2" className={classes.category}>
                  Account Number
                </Typography>
                <Typography>
                  {wire.account_number}
                </Typography>
              </Box>
              <Box mt={2} mb={2}>
                <Typography variant="h2" className={classes.category}>
                  Routing Number
                </Typography>
                <Typography>
                  {wire.routing_number}
                </Typography>
              </Box>
              <Box mt={2} mb={2}>
                <Typography variant="h2" className={classes.category}>
                  Beneficiary Address
                </Typography>
                <Typography>
                  {wire.beneficiary_address}
                </Typography>
              </Box>
              <Box mt={2} mb={2}>
                <Typography variant="h2" className={classes.category}>
                  Credit To
                </Typography>
                <Typography>
                  {wire.credit_to}
                </Typography>
              </Box>
              <Box mt={2} mb={2}>
                <Typography variant="h2" className={classes.category}>
                  Reference
                </Typography>
                <Typography>
                  {wire.reference}
                </Typography>
              </Box>
              <Box mt={2} mb={2}>
                <Typography variant="h2" className={classes.category}>
                  SWIFT Code
                </Typography>
                <Typography>
                  {wire.swift_code}
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Page>
  );
};

WireTransfer.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(WireTransfer);
