import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  Box,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';

import BlankImage from 'assets/images/profile.png';

import { logoutAction } from 'store/actions/auth';
import { useUnInstallDispatch } from 'hooks/redux/user';
import { useClearParlayDispatch } from 'hooks/redux/parlay';
import CommonStyle from 'styles/common';

const navItems = [
  {
    path: '/more/setting/profile',
    label: 'Edit Profile',
  },
  {
    path: '/more/setting/display',
    label: 'Display Settings',
  },
  {
    path: '/more/setting/notification',
    label: 'Notification Settings',
  },
  {
    path: '/more/setting/system-requirements',
    label: 'System Requirements',
  },
  {
    path: '/logout',
    label: 'Log Out',
  },
];

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 15px',
  },
  header: {
    position: 'relative',
    backgroundImage: 'linear-gradient(45deg, #298EDA, #29D2D3)',
    borderRadius: '4px',
    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.15)',
    height: '121px',
    textAlign: 'center',
    marginTop: '55px',
  },
  photo: {
    height: '86px',
    width: '86px',
    backgroundColor: 'grey',
    border: '2px solid #fff',
    borderRadius: '50%',
    overflow: 'hidden',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    top: '-45px',
    '& img': {
      width: '100%',
      height: 'auto',
    },
  },
  user: {
    paddingTop: '60px',
  },
  navList: {
    background: palette.cardColor,
    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.15)',
    marginTop: '20px',
    fontFamily: 'SofiaPro-SemiBold',
    letterSpacing: '0.3px',
    color: palette.secondaryColor,
    borderRadius: '6px',
  },
  navItem: {
    padding: '20px 0',
    fontSize: '17px',
    borderBottom: '1px solid #EEEEEE',
  },
  arrow: {
    color: '#D6D6E5',
    fontSize: '16px',
  },
}));

const Setting = ({ logout, history, user }) => {
  const classes = useStyles();
  const commonClasses = CommonStyle();
  const unInstall = useUnInstallDispatch();
  const clearParlay = useClearParlayDispatch();
  const handleClick = useCallback(async (path) => {
    if (path === '/logout') {
      clearParlay();
      await unInstall();
      await logout();
    } else {
      history.push(path);
    }
    // eslint-disable-next-line
  }, []);
  return (
    <Page>
      <Box className={classes.root}>
        <Box>
          <BackButton color={commonClasses.backBtn} />
        </Box>
        <Box className={classes.header} pb={2}>
          <Box className={classes.photo}>
            <img src={user.profile_url || BlankImage} alt="profile" />
          </Box>
          <Box className={classes.user}>
            <Typography variant="h4" className="color-white">
              {user.first_name}
              {' '}
              {user.last_name}
            </Typography>
            <Typography variant="subtitle2" className="color-white">
              {user.email}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.navList} pl={3} pr={3} pt={3}>
          {navItems.map((navItem) => (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              className={classes.navItem}
              onClick={() => handleClick(navItem.path)}
              key={navItem.path}
            >
              {navItem.label}
              <ArrowForwardIosIcon className={classes.arrow} />
            </Box>
          ))}
          <Box textAlign="center" mt={5} pb={5}>
            <a
              className="color-primary"
              href="https://zensports.com/tos"
              style={{ fontSize: 14 }}
            >
              Terms of Service
            </a>
          </Box>
        </Box>
      </Box>
    </Page>
  );
};

Setting.propTypes = {
  logout: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logoutAction()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Setting));
