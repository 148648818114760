import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const useStyles = makeStyles({
  root: {
    padding: 4,
    background: '#00000010',
  },
});

const IncludeButton = ({ color, size, onClick }) => {
  const classes = useStyles();
  return (
    <IconButton className={classes.root} onClick={onClick}>
      <AddCircleOutlineIcon style={{ fontSize: size, color }} />
    </IconButton>
  );
};

IncludeButton.defaultProps = {
  color: 'black',
  size: 18,
  onClick: () => {},
};

IncludeButton.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  onClick: PropTypes.func,
};

export default IncludeButton;
