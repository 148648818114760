import lightBlue from '@material-ui/core/colors/lightBlue';

export default {
  breakpoints: {
    keys: [
      'xs',
      'sm',
      'md',
      'lg',
      'xl',
    ],
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  direction: 'ltr',
  mixins: {
    toolbar: {
      minHeight: 56,
      '@media (min-width:0px) and (orientation: landscape)': {
        minHeight: 48,
      },
      '@media (min-width:600px)': {
        minHeight: 64,
      },
    },
  },
  props: {},
  typography: {
    htmlFontSize: 15,
    fontFamily: 'SofiaPro, sans-serif',
    fontSize: 15,
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    h1: {
      fontFamily: 'SofiaPro, sans-serif',
      fontSize: '30px',
      fontWeight: 'bold',
      letterSpacing: '-0.1px',
      marginBlockStart: '13px',
      marginBlockEnd: '7px',
    },
    h2: {
      fontFamily: 'SofiaPro, sans-serif',
      fontWeight: 'bold',
      fontSize: '1.8rem',
      lineHeight: 1.2,
      letterSpacing: '-0.00833em',
    },
    h3: {
      fontFamily: 'SofiaPro, sans-serif',
      fontWeight: 400,
      fontSize: '1.6rem',
      lineHeight: 1.167,
      letterSpacing: '0em',
    },
    h4: {
      fontFamily: 'SofiaPro, sans-serif',
      fontWeight: 400,
      fontSize: '1.4rem',
      lineHeight: 1.235,
      letterSpacing: '0.00735em',
    },
    h5: {
      fontFamily: 'SofiaPro, sans-serif',
      fontWeight: 400,
      fontSize: '1.2rem',
      lineHeight: 1.334,
      letterSpacing: '0em',
    },
    h6: {
      fontFamily: 'SofiaPro, sans-serif',
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: 1.6,
      letterSpacing: '0.0075em',
    },
    subtitle1: {
      fontFamily: 'SofiaPro, sans-serif',
      fontWeight: 500,
      fontSize: '15px',
      lineHeight: '20px',
      letterSpacing: '-0.26px',
    },
    subtitle2: {
      fontFamily: 'SofiaPro, sans-serif',
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: 1.57,
      letterSpacing: '0.00714em',
    },
    body1: {
      fontFamily: 'SofiaPro, sans-serif',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.5,
      letterSpacing: '0.00938em',
    },
    body2: {
      fontFamily: 'SofiaPro, sans-serif',
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
    },
    button: {
      fontFamily: 'SofiaPro, sans-serif',
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: 1.75,
      letterSpacing: '0.02857em',
      textTransform: 'uppercase',
    },
    caption: {
      fontFamily: 'SofiaPro, sans-serif',
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 1.66,
      letterSpacing: '0.03333em',
    },
    overline: {
      fontFamily: 'SofiaPro, sans-serif',
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 2.66,
      letterSpacing: '0.08333em',
      textTransform: 'uppercase',
    },
  },
  shape: {
    borderRadius: 4,
  },
  transitions: {
    easing: {
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
  },
  zIndex: {
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
  overrides: {
    MuiButton: {
      text: {
        background: 'linear-gradient(45deg, #298EDB 0%, #29D2D3 100%)',
        border: 0,
        color: '#14133D',
        height: 30,
        padding: '0 15px',
        boxShadow: '0 6px 30px 0 rgba(0,214,219,0.8), 0 3px 10px 0 rgba(0,0,0,0.15)',
        textTransform: 'capitalize',
      },
    },
    MuiFab: {
      extended: {
        background: 'linear-gradient(45deg, #298EDB 0%, #29D2D3 100%)',
        border: 0,
        color: '#14133D',
        height: 30,
        padding: '0 15px',
        boxShadow: '0 6px 30px 0 rgba(0,214,219,0.8), 0 3px 10px 0 rgba(0,0,0,0.15)',
        textTransform: 'capitalize',
      },
      root: {
        background: 'linear-gradient(45deg, #298EDB 0%, #29D2D3 100%)',
        boxShadow: '0 6px 30px 0 rgba(0,214,219,0.8), 0 3px 10px 0 rgba(0,0,0,0.15)',
        textTransform: 'capitalize',
        height: 50,
        width: 50,
      },
    },
    MuiInputLabel: {
      root: {
        color: '#63C4C6',
      },
    },
    MuiFormHelperText: {
      root: {
        color: '#A6A6A6',
        fontSize: '11px',
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#63C4C6',
      },
    },
    MuiPickersToolbarButton: {
      toolbarBtn: {
        background: 'none',
        boxShadow: 'none',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersYear: {
      root: {
        color: '#63C4C6',
      },
    },
    MuiPickersDay: {
      day: {
        color: '#14133D',
      },
      daySelected: {
        backgroundColor: '#63C4C6',
      },
      dayDisabled: {
        color: lightBlue['100'],
      },
      current: {
        color: '#63C4C6',
      },
    },
    MuiPickersClockNumber: {
      clockNumber: {
        color: '#14133D',
      },
    },
    MuiDialogActions: {
      root: {
        '& .MuiButtonBase-root': {
          color: 'white !important',
        },
      },
    },
  },
};
