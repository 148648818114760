import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import { Box, Typography } from '@material-ui/core';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import CryptoDepositCard from 'components/Deposit/CryptoDepositCard';
import UsdDepositCard from 'components/Deposit/UsdDepositCard';

import { useMappedCurrencies } from 'hooks/redux/currency';
import { useUserRestrictedAccess } from 'hooks/redux/user';

import DepositFundsDialog from 'components/Dialog/DepositFundsDialog';
import commonStyle from 'styles/common';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 15px',
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
    marginBottom: '10px',
  },
  content: {
    textAlign: 'center',
    color: '#AEAEAE',
    font: '15px SofiaPro',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const Deposit = () => {
  const classes = useStyles();
  const commonClasses = commonStyle();
  const mappedCurrencies = useMappedCurrencies();
  const [currency, setCurrency] = useState('');
  const access = useUserRestrictedAccess();

  return (
    <Page>
      <Box className={classes.root}>
        <Box>
          <BackButton color={commonClasses.backBtn} page="/funds" />
        </Box>
        <Box textAlign="center" mb={4} mt={3}>
          <Typography component="h3" className={classes.title}>
            Deposit Funds
          </Typography>
          <Typography component="h3" className={classes.content}>
            How would you like to deposit funds?
            <DepositFundsDialog />
          </Typography>
        </Box>
        <Box mb={15}>
          {access.isCryptoVisible && mappedCurrencies.sports && (
            <CryptoDepositCard
              currency={{ label: 'SPORTS Utility Tokens', ...mappedCurrencies.sports }}
              collapsed={currency === 'sports'}
              onCardClick={setCurrency}
            />
          )}
          {access.isCryptoVisible && mappedCurrencies.btc && (
            <CryptoDepositCard
              currency={{ label: 'BTC', ...mappedCurrencies.btc }}
              collapsed={currency === 'btc'}
              onCardClick={setCurrency}
            />
          )}
          {mappedCurrencies.usdt && (
            <UsdDepositCard
              usdtCurrency={mappedCurrencies.usdt}
              collapsed={currency === 'usd'}
              onCardClick={setCurrency}
            />
          )}
          {access.isCryptoVisible && mappedCurrencies.icx && (
            <CryptoDepositCard
              currency={{ label: 'ICX', ...mappedCurrencies.icx }}
              collapsed={currency === 'icx'}
              onCardClick={setCurrency}
            />
          )}
        </Box>
      </Box>
    </Page>
  );
};

Deposit.propTypes = {};

export default withRouter(Deposit);
