import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import Avatar from 'components/Avatar';
import {
  useUpcomingMatches,
} from 'hooks/redux/play';
import { displayTournamentName } from 'utils/play';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0 15px',
  },
  backBtn: {
    color: theme.palette.secondaryColor,
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: theme.palette.secondaryColor,
    textAlign: 'center',
    margin: 'auto',
  },
  card: {
    background: theme.palette.cardColor,
    borderRadius: '5px',
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 4px',
    position: 'relative',
    overflow: 'hidden',
    marginBottom: '10px',
    width: '100%',
    marginRight: 20,
  },
  contentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px',
  },
  matchContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  matchText: {
    color: theme.palette.secondaryColor,
    fontSize: '13px',
    marginTop: 5,
  },
  roundText: {
    textAlign: 'center',
    color: theme.palette.secondaryColor,
    marginTop: 10,
  },
}));

const UpcomingMatches = ({ match, history }) => {
  const classes = useStyles();
  const { id } = match.params;
  const upcomingMatchDetails = useUpcomingMatches(id);
  return (
    <Page>
      <Box className={classes.root}>
        <Box>
          <BackButton color={classes.backBtn} />
        </Box>
        <Box textAlign="center" mb={4} mt={4}>
          <Typography component="h3" className={classes.title}>
            Upcoming Matches
          </Typography>
        </Box>
        <Box>
          {
          upcomingMatchDetails?.length > 0 && upcomingMatchDetails.map((item) => (
            <Box
              key={item.id}
              className={`cardOverride ${classes.card}`}
              onClick={() => history.push(`/more/play/tournament/view/${id}/match-details/${item.id}`)}
            >
              <Typography className={classes.roundText}>
                {'Round '}
                {item.position}
              </Typography>
              <Box className={classes.contentContainer}>
                {item?.users?.map((user) => (
                  <Box key={user.id} className={classes.matchContainer}>
                    <Avatar src={user.profile_url} size="50px" />
                    <Typography className={classes.matchText}>
                      {displayTournamentName(user)}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          ))
          }
        </Box>
      </Box>
    </Page>
  );
};

UpcomingMatches.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(UpcomingMatches);
