import httpService from './http.service';

const getCurrencyRates = (params) => httpService
  .get('/cryptocurrency_transactions/rates', {}, params)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const getTransactionStatus = (params) => httpService
  .get(`/cryptocurrency_transactions/${params.transactionId}/status`, {}, params)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const getTransactionDetail = (params) => httpService
  .get(`/cryptocurrency_transactions/${params.transactionId}/show`, {}, params)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const createTransaction = (params) => httpService
  .get('/cryptocurrency_transactions/create_transaction', {}, params)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

export default {
  getCurrencyRates,
  getTransactionStatus,
  getTransactionDetail,
  createTransaction,
};
