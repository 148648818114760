import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import SelectState from 'components/SelectState';

import { setKycUserInfoAction } from 'store/actions/kyc';

const useStyles = makeStyles(() => ({
}));

const KycUserState = ({
  userCountry,
  userState,
  setKycUserInfo,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const handleSelectState = (state) => {
    if (state.id !== userState.id) {
      setKycUserInfo({ state });
    }
    history.push('/kyc/profile/address');
  };
  return (
    <Page>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <BackButton color={classes.backBtn} page="/kyc/profile/address" />
      </Box>
      <SelectState
        handleSelect={handleSelectState}
        country={userCountry}
        state={userState}
      />
    </Page>
  );
};

KycUserState.defaultProps = {
  userState: { id: 0 },
  userCountry: { id: 0 },
};

KycUserState.propTypes = {
  userState: PropTypes.object,
  userCountry: PropTypes.object,
  setKycUserInfo: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  userCountry: state.kyc.user.country,
  userState: state.kyc.user.state,
});

const mapDispatchToProps = (dispatch) => ({
  setKycUserInfo: (data) => dispatch(setKycUserInfoAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(KycUserState);
