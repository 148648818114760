import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import uploadIconImage from 'assets/images/icon/upload.png';

const useStyles = makeStyles(({ palette }) => ({
  root: ({ background }) => ({
    background,
    width: '35px',
    height: '35px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
  }),
  image: ({ type }) => ({
    filter: type === 'white' ? 'brightness(0) invert(1)' : palette.invertImage,
  }),
}));

const ShareButton = ({ ...props }) => {
  const { onClick } = props;
  const classes = useStyles(props);

  return (
    <Box className={classes.root} onClick={onClick}>
      <img src={uploadIconImage} alt="Share" className={classes.image} />
    </Box>
  );
};

ShareButton.defaultProps = {
  type: 'black',
  background: 'transparent',
  onClick: () => {},
};

ShareButton.propTypes = {
  type: PropTypes.string,
  background: PropTypes.string,
  onClick: PropTypes.func,
};

export default ShareButton;
