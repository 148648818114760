import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { setPhotoAction } from 'store/actions/auth';

import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import config from 'utils/config';
import BlankImage from 'assets/images/profile.png';

const useStyles = makeStyles({
  root: {
    textAlign: 'center',
  },
  upload: {
    boxSizing: 'border-box',
    height: '80px',
    width: '80px',
    border: '2px solid #29CED3',
    backgroundColor: 'rgba(255,255,255,0.15)',
    boxShadow: '0 3px 10px 0 rgba(0,0,0,0.15)',
    borderRadius: '50%',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    margin: '0 auto',
  },
  label: {
    color: '#63C4C6',
    fontSize: '11px',
    fontWeight: 600,
    lineHeight: '20px',
    textAlign: 'center',
  },
  image: {
    width: '100%',
    height: 'auto',
  },
});

const UploadPhoto = ({ profileUrl, setProfileUrl }) => {
  const classes = useStyles();

  const onUploadFinish = (error, result) => {
    if (error) {
    //  console.log('Upload error: ', error);
    }
    if (result.event === 'success' && result.info?.secure_url) {
      setProfileUrl(result.info.secure_url);
    }
  };

  const onUploadFile = () => {
    const cropWidget = window.cloudinary.createUploadWidget(
      {
        cloudName: config.cloudinaryCloudName,
        uploadPreset: config.cloudinaryUploadPreset,
        folder: 'widgetUpload',
        cropping: true,
      },
      onUploadFinish,
    );
    cropWidget.open();
  };

  return (
    <Box onClick={onUploadFile} className={classes.root}>
      <Box className={classes.label}>Upload Photo (Optional)</Box>
      <Box className={classes.upload} mt={1}>
        <img src={profileUrl || BlankImage} className={classes.image} alt="Upload Profile" />
      </Box>
    </Box>
  );
};

UploadPhoto.defaultProps = {
  profileUrl: '',
};

UploadPhoto.propTypes = {
  profileUrl: PropTypes.string,
  setProfileUrl: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  profileUrl: state.auth.user.profile_url,
});

const mapDispatchToProps = (dispatch) => ({
  setProfileUrl: (data) => dispatch(setPhotoAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadPhoto);
