import { persistConfig } from 'store';
import * as actionTypes from 'store/actionTypes';
import versionService from 'services/version.service';

// eslint-disable-next-line import/prefer-default-export
export const checkAppVersionAction = () => async (dispatch) => {
  try {
    dispatch({
      type: actionTypes.CHECK_APP_VERSION,
    });
    const version = await versionService.checkAppVersion();
    if (version && version.web) {
      const currentVersion = persistConfig.key.slice(10, persistConfig.key.length);
      if (currentVersion !== version.web?.current) {
        dispatch({
          type: actionTypes.OPEN_SUCCESS_MODAL,
          payload: {
            title: 'New Web App Version Available!',
            subtitle: 'Looks like there is a new version of the web app. You will need to re-install the web app to get the latest version.',
            buttonText: 'Got it',
          },
        });
      }
    }
  } catch (e) {
    // todo
  }
};
