import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';

import lightTheme from 'themes/lightTheme';
import darkTheme from 'themes/darkTheme';
import { getUserAction } from 'store/actions/auth';
import { loadThemeAction } from 'store/actions/theme';
import { checkAppVersionAction } from 'store/actions/version';
import AppRoutes from 'routes';

function App(props) {
  const {
    getUser, checkAppVersion, loadTheme, user,
  } = props;

  useEffect(() => {
    getUser();
    loadTheme();
    checkAppVersion();
  }, [getUser, loadTheme, checkAppVersion]);
  return (
    <ThemeProvider theme={user.display_mode === 'dark' ? darkTheme : lightTheme}>
      <AppRoutes />
    </ThemeProvider>
  );
}

App.propTypes = {
  // isLoading: PropTypes.bool.isRequired,
  getUser: PropTypes.func.isRequired,
  checkAppVersion: PropTypes.func.isRequired,
  loadTheme: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  getUser: () => dispatch(getUserAction()),
  loadTheme: () => dispatch(loadThemeAction()),
  checkAppVersion: () => dispatch(checkAppVersionAction()),
});

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  user: state.auth.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
