import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Box, InputBase, makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InputBox from 'components/Form/InputBox';

const useStyles = makeStyles(({ palette }) => ({
  card: {
    boxShadow: '0 2px 10px 1px rgba(0,0,0,0.15)',
    background: palette.cardColor,
    borderRadius: '6px',
    position: 'relative',
  },
  input: {
    color: palette.secondaryColor,
    font: '15px SofiaPro-Bold',
    letterSpacing: -0.2,
    padding: '10px 0px',
    '& input': {
      textAlign: 'center',
    },
  },
  field: {
    '& .MuiInputLabel-root': {
      color: palette.secondaryColor,
      font: '22px SofiaPro-Bold',
    },
  },
  textField: {
    color: palette.colorBtnTxt,
    font: '25px SofiaPro-SemiBold',
    '& input::placeholder': {
      color: palette.placeholderColor,
    },
    '&:before': {
      borderBottom: '1px solid black !important',
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: '1px solid black !important',
    },
  },
  expand: {
    position: 'absolute',
    right: '10px',
    color: '#AEAEAE',
  },
  list: {
    position: 'absolute',
    boxShadow: '0 2px 10px 1px rgba(0,0,0,0.15)',
    borderRadius: '6px',
    overflowY: 'auto',
    maxHeight: '330px',
    marginTop: '3px',
    width: '100%',
    backgroundColor: 'white',
    zIndex: 1,
  },
  item: {
    padding: '10px 0px',
    fontSize: '17px',
    fontWeight: '600',
    lineHeight: '25px',
    color: palette.secondaryColor,
    textAlign: 'center',
  },
}));

const InputDropDown = (props) => {
  const {
    placeholder, handleSelect, items, value, onClick, type,
  } = props;
  const classes = useStyles();
  const [isDropDown, setIsDropDown] = useState(false);
  const [searchedItems, setSearchedItems] = useState([]);
  const [search, setSearch] = useState(value.name ? value.name : '');

  const handleSearch = (event) => {
    setSearch(event.target.value);
    const filteredLists = items.filter((item) => item.name.toLowerCase().includes(
      event.target.value.toLowerCase(),
    ));
    setSearchedItems(filteredLists);
  };

  const handleClick = (item) => {
    setSearch(item.name);
    handleSelect(item);
    setIsDropDown(false);
  };

  useEffect(() => {
    if (items.length) {
      setSearchedItems(items);
    }
  }, [items]);

  return (
    <Box position="relative" mt={2}>
      {
        type === 'default' ? (
          <Box className={classes.card} display="flex" alignItems="center" onClick={onClick}>
            <InputBase
              className={classes.input}
              placeholder={placeholder}
              inputProps={{ 'aria-label': placeholder }}
              onFocus={() => setIsDropDown(true)}
              onChange={handleSearch}
              value={search}
              fullWidth
            />
            <ExpandMoreIcon className={classes.expand} onClick={() => setIsDropDown(true)} />
          </Box>
        )
          : (
            <Box className={classes.field} onClick={onClick}>
              <InputBox
                label={placeholder}
                onChange={handleSearch}
                value={search}
                onFocus={() => setIsDropDown(true)}
              />
            </Box>
          )
      }
      {
        searchedItems.length > 0 && isDropDown && (
          <Box className={classes.list}>
            {searchedItems.map((item) => (
              <Box
                className={classes.item}
                key={item.id}
                onClick={() => handleClick(item)}
              >
                {item.name}
              </Box>
            ))}
          </Box>
        )
      }
    </Box>
  );
};

InputDropDown.defaultProps = {
  items: [],
  value: {},
  handleSelect: () => {},
  onClick: () => {},
  placeholder: '',
  type: 'default',
};

InputDropDown.propTypes = {
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.object,
  items: PropTypes.array,
  onClick: PropTypes.func,
  handleSelect: PropTypes.func,
};

export default InputDropDown;
