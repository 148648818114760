import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  makeStyles,
  Grid,
  Typography,
  InputBase,
} from '@material-ui/core';
import FormattedNumberInput from 'components/Form/FormattedNumberInput';

import { currencyDecimalScale } from 'utils/constants';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: 15,
    textAlign: 'left',
    boxShadow: '0 3px 10px 1px rgba(0,0,0,0.15)',
    borderRadius: '6px',
    flexDirection: 'column',
  },
  subtitle: {
    color: '#949494',
    lineHeight: 2,
    font: '13px SofiaPro',
  },
  value: {
    lineHeight: 2,
    color: palette.secondaryColor,
    font: '15px SofiaPro',
  },
  collapsed: {
    borderTop: '1px solid #949494',
    borderBottom: '1px solid #949494',
  },
  usd: {
    color: '#29CFD4',
    font: '25px SofiaPro-Bold',
  },
  currency: {
    color: '#29CFD4',
    font: '25px SofiaPro-Bold',
    width: '40%',
    letterSpacing: -0.3,
  },
}));

const WireWithdrawCard = (props) => {
  const {
    item, open, onClick, handleChange, value,
  } = props;
  const classes = useStyles();

  return (
    <Box onClick={() => onClick(item.id)} mt={2} display="flex" className={classes.root}>
      <Box display="flex">
        <Grid item xs={6} sm={6}>
          {
            item.bank_name !== null && (
              <>
                <Typography className={classes.subtitle}>
                  Bank Name
                </Typography>
                <Typography className={classes.value}>
                  {item.bank_name}
                </Typography>
              </>
            )
          }
          {
            item.routing_number !== null && (
              <>
                <Typography className={classes.subtitle}>
                  Routing Number
                </Typography>
                <Typography className={classes.value}>
                  {item.routing_number}
                </Typography>
              </>
            )
          }
          <Typography className={classes.subtitle}>
            Account Number
          </Typography>
          <Typography className={classes.value}>
            {'***********'}
            {item.last_4}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6}>
          {
            item.swift_code !== null && (
            <>
              <Typography className={classes.subtitle}>
                SWIFT Code
              </Typography>
              <Typography className={classes.value}>
                {item.swift_code}
              </Typography>
            </>
            )
          }
        </Grid>
      </Box>
      {
        open && (
          <>
            <Box className={classes.collapsed} pt={2} pb={2}>
              <Typography className="text-center">
                Amount in  USD to withdraw
              </Typography>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Typography className={classes.usd}>
                  $
                </Typography>
                <InputBase
                  autoComplete="off"
                  className={classes.currency}
                  value={value}
                  name="amount"
                  onChange={(e) => handleChange(e.target.value)}
                  inputComponent={FormattedNumberInput}
                  inputProps={{ 'aria-label': 'naked', decimalScale: currencyDecimalScale.usd }}
                />
              </Box>
            </Box>
          </>
        )
      }
    </Box>
  );
};

WireWithdrawCard.defaultProps = {
  item: {},
  open: false,
  onClick: () => {},
  handleChange: () => {},
  value: '',
};

WireWithdrawCard.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func,
  handleChange: PropTypes.func,
  open: PropTypes.bool,
  value: PropTypes.string,
};

export default WireWithdrawCard;
