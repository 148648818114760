import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import StarRateIcon from '@material-ui/icons/StarRate';
import CheckIcon from '@material-ui/icons/CheckCircleOutline';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';

import TwitchPlayer from 'components/Play/Tournament/View/TwitchPlayer';

import { formattedDate } from 'utils';
import { tournamentFormatType } from 'utils/constants';

const useStyles = makeStyles((theme) => ({
  card: {
    background: theme.palette.cardColor,
    borderRadius: '5px',
    boxShadow: '0 3px 10px 0 rgba(0,0,0,0.15)',
    position: 'relative',
    overflow: 'hidden',
    top: -15,
    marginBottom: '10px',
  },
  contentContainer: {
    padding: '0px 20px 10px',
  },
  typesText: {
    color: '#b5b5b5',
    marginTop: 5,
  },
  title: {
    font: '30px SofiaPro-Bold',
    fontSize: '21px',
    letterSpacing: -0.3,
    color: theme.palette.secondaryColor,
  },
  tagContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '13px 0 10px',
  },
  tag: {
    background: '#6236ff',
    color: '#fff',
    padding: '3px 12px',
    fontSize: '11px',
    borderRadius: '20px',
    fontWeight: 'lighter',
    textAlign: 'center',
  },
  dateText: {
    color: '#52d4e3',
    fontSize: '12px',
  },
  registerBtn: {
    lineHeight: '32px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.colorBtn,
    border: '1.5px solid #29CFD4',
    boxShadow: '0 1px 20px 0 rgba(0,214,219,0.3), 0 3px 10px 0 rgba(0,0,0,0.15)',
    borderRadius: 50,
    margin: '20px auto',
    font: '13px SofiaPro-SemiBold',
    width: '248px',
    letterSpacing: '0.3px',
    color: theme.palette.colorBtnTxt,
  },
  registeredText: {
    textAlign: 'center',
    font: '13px SofiaPro-SemiBold',
    color: theme.palette.colorBtnTxt,
    margin: '15px auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  streamsBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    boxShadow: 'inset 0 -3px 0 #54a400',
    justifyContent: 'space-between',
  },
  trapezoidBox: {
    width: '40%',
    marginLeft: 'auto',
    marginTop: '5px',
    background: '#54A400',
    display: 'flex',
    justifyContent: 'center',
    padding: '9.5px 5px 9.5px 10px',
    clipPath: 'polygon(10% 0%, 100% 0%, 100% 100%, 0% 100%)',
  },
  contentText: {
    color: 'white',
    fontSize: '13px',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  videoIcon: {
    paddingRight: 5,
    fontSize: '18px',
  },
  twitchTitle: {
    fontSize: '14px',
    display: 'flex',
    textAlign: 'center',
    color: theme.palette.secondaryColor,
    fontWeight: 'bold',
    alignItems: 'flex-end',
    justifyContent: 'center',
    padding: '10px 0',
  },
  registerBtnIcon: {
    paddingRight: 5,
  },
}));

const Summary = ({ details, history, isRegistered }) => {
  const classes = useStyles();

  const getDescription = () => {
    let description = '';
    if (details) {
      if (details.id) {
        description += `ID: ${details.id} `;
      }
      if (details.location) {
        description += `- ${details.location} `;
      }
      if (details.format_display) {
        description += `- ${tournamentFormatType[details.format] || ''} `;
      }
      // if (details.weight_display) {
      //   description += `- ${details.weight_display} `;
      // }
    }
    return description;
  };

  return (
    <Box className={`cardOverride ${classes.card}`}>
      <Box className={classes.contentContainer}>
        <Box className={classes.tagContainer}>
          <Box className={classes.tag}>
            {details.game_type_display}
          </Box>
          <Typography className={classes.dateText}>
            {formattedDate(details.date_iso8601)}
          </Typography>
        </Box>
        <Typography className={classes.title}>
          {details.name}
        </Typography>
        <Typography className={classes.typesText}>
          {getDescription()}
        </Typography>
      </Box>
      <Box className={classes.streamsBox}>
        <Box className={classes.trapezoidBox}>
          <Typography
            className={classes.contentText}
            onClick={() => history.push(`/more/play/tournament/view/${details.id}/watch-streams`)}
          >
            <PlayCircleOutlineIcon className={classes.videoIcon} />
            Player Streams
          </Typography>
        </Box>
      </Box>
      <Box className={classes.twitchTitle}>
        Competition Broadcast
      </Box>
      <TwitchPlayer channel={details.twitch_channel_url} />
      <Box>
        {
          isRegistered ? (
            <Typography
              className={classes.registeredText}
            >
              <CheckIcon className={classes.registerBtnIcon} />
              Registered For Competition
            </Typography>
          )
            : (
              <Typography
                className={classes.registerBtn}
                onClick={() => history.push(`/more/play/tournament/view/${details.id}/register-for-tournament`)}
              >
                <>
                  <StarRateIcon className={classes.registerBtnIcon} />
                  Register For Competition
                </>
              </Typography>
            )
        }
      </Box>
    </Box>
  );
};

Summary.propTypes = {
  details: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  isRegistered: PropTypes.bool.isRequired,
};

export default withRouter(Summary);
