import httpService from './http.service';

const getParlay = (parlayId) => httpService
  .get(`/parlays/${parlayId}`)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const submitParlay = (body) => httpService
  .post('/parlays', body)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const setResultParlay = (body) => httpService
  .post(`/parlays/${body.parlayId}/set_results`, { ...body.data })
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const excludeBetParlay = (body) => httpService
  .post(`/parlays/${body.parlayId}/exclude_bets`, { ...body.data })
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

export default {
  getParlay,
  submitParlay,
  setResultParlay,
  excludeBetParlay,
};
