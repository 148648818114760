import httpService from './http.service';

const createTournament = (body) => httpService
  .post('/pool_tournaments', body)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const updateTournament = (id, body) => httpService
  .put(`/pool_tournaments/${id}`, body)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const getTournamentDetails = (id) => httpService
  .get(`/pool_tournaments/${id}`)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const getTournamentUsers = (id) => httpService
  .get(`/pool_tournaments/${id}/users`)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const currentUserInTournament = (id) => httpService
  .get(`/pool_tournaments/${id}/user_in_tournament`)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const getUpcomingMatches = (id) => httpService
  .get(`/pool_tournaments/${id}/upcoming_matches`)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const getMatchesInProgress = (id) => httpService
  .get(`/pool_tournaments/${id}/matches_in_progress`)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const getCompletedMatches = (id) => httpService
  .get(`/pool_tournaments/${id}/completed_matches`)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const getPrizeMoneyPlayer = (id) => httpService
  .get(`/pool_tournaments/${id}/prize_money`)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const getPrizeMoneyDirector = (id) => httpService
  .get(`/pool_tournaments/${id}/prize_money_director`)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const setMatchWinner = (id, body) => httpService
  .post(`/pool_tournaments/${id}/set_winner`, body)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const closeTournament = (id) => httpService
  .post(`/pool_tournaments/${id}/close_entries`)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const deleteTournament = (id) => httpService
  .post(`/pool_tournaments/${id}/delete`)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const registerForTournament = (id, body) => httpService
  .post(`/pool_tournaments/${id}/add_user`, body)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const updateTwitchUrl = (id, body) => httpService
  .post(`/pool_tournaments/${id}/update_user_twitch_channel_url`, body)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const getTwitchUrl = (id) => httpService
  .get(`/pool_tournaments/${id}/twitch_channels`)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const getTournamentMatchDetails = (id) => httpService
  .get(`/pool_tournament_matches/${id}`)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const setTournamentMatchWinner = (id, body) => httpService
  .post(`/pool_tournament_matches/${id}/set_winner`, body)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

const getBracketsDetails = (id) => httpService
  .get(`/pool_tournaments/${id}/bracket`)
  .then(({ data }) => data)
  .catch((err) => Promise.reject(err?.response?.data));

export default {
  createTournament,
  updateTournament,
  getTournamentDetails,
  getTournamentUsers,
  currentUserInTournament,
  getUpcomingMatches,
  getMatchesInProgress,
  getCompletedMatches,
  getPrizeMoneyPlayer,
  getPrizeMoneyDirector,
  setMatchWinner,
  closeTournament,
  deleteTournament,
  registerForTournament,
  updateTwitchUrl,
  getTwitchUrl,
  getTournamentMatchDetails,
  setTournamentMatchWinner,
  getBracketsDetails,
};
