import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Typography,
} from '@material-ui/core';

import Page from 'components/Page/Dashboard';
import Chart from 'components/Chart';
import Loading from 'components/Loading/Box';
import sportsIcon from 'assets/images/currency/sports-wallet-logo.png';
import rewardsCheck from 'assets/images/rewards/rewards-check.png';
import BackButton from 'components/Buttons/BackButton';
import cupLogo from 'assets/images/rewards/cup-logo.png';
import lowerFees from 'assets/images/rewards/lower-fees.png';
import cashBack from 'assets/images/rewards/cash-back.png';
import withdrawBonus from 'assets/images/rewards/gift-bonus.png';
import LogoMarkImage from 'assets/images/rewards/logo-mark.png';
import { dateMYOptions } from 'utils/constants';

import { useRewardStatus, useAuthIsLoading } from 'hooks/redux/user';
import { getBetChartInfo } from 'utils/betting';
import commonStyle from 'styles/common';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 15px',
  },
  title: {
    font: '14px SofiaPro-Bold',
    color: '#29CED3',
    textTransform: 'capitalize',
  },
  iconImg: {
    margin: '25px 0 10px 0',
  },
  subheader: {
    font: '18px SofiaPro-Bold',
    color: palette.secondaryColor,
  },
  content: {
    margin: '17px -15px 7px',
    color: '#AEAEAE',
    font: '13px SofiaPro',
  },
  subtitle: {
    font: '17px SofiaPro-Bold',
    color: palette.secondaryColor,
    padding: 10,
  },
  cardTitleBoxContainer: {
    background: palette.cardColor,
  },
  contentCard: {
    background: palette.cardColor,
    margin: '10px 5px',
    padding: '0',
    boxShadow: '0 3px 10px 1px rgba(0,0,0,0.15)',
    borderRadius: '6px',
  },
  contentChartCard: {
    margin: '10px 5px',
    padding: '0',
    boxShadow: '0 3px 10px 1px rgba(0,0,0,0.15)',
    borderRadius: '6px',
  },
  cardTitleBox: {
    color: palette.secondaryColor,
    '& img': {
      float: 'left',
      margin: '20px 15px 0px 15px',
    },
  },
  cardBody: {
    clear: 'both',
    font: '13px SofiaPro',
    margin: '15px',
    overflow: 'auto',
    '& p': {
      color: palette.secondaryColor,
    },
  },
  chartBox: {
    '& .highcharts-container': {
      height: '290px !important',
    },
    '& .highcharts-background': {
      fill: palette.chartCardColor,
      height: '400px',
    },
  },
  cardBodyEmphasis: {
    color: '#29CED3',
    fontWeight: 'bold',
  },
  cardTitle: {
    color: palette.secondaryColor,
    font: '16px SofiaPro-medium',
    float: 'left',
  },
  cardTitle2: {
    color: palette.secondaryColor,
    font: '18px SofiaPro-medium',
    float: 'left',
  },
  cardMessage: {
    color: '#aeaeae !important',
  },
  cardMessage2: {
    color: '#aeaeae !important',
    font: '16px SofiaPro-medium',
    margin: 0,
    lineHeight: 1.2,
  },
  card: {
    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.15)',
    padding: '10px 20px',
    borderRadius: '5px',
    color: palette.secondaryColor,
    font: '18px SofiaPro-Bold',
  },
  walletImage: {
    height: '50px',
  },
  add: {
    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.15)',
    borderRadius: '5px',
    background: '#FFFFFF',
    minHeight: 40,
    color: '#29CDD4',
    font: '16px SofiaPro-Bold',
  },
  rewardsTable: {
    borderCollapse: 'collapse',
    '& th': {
      background: palette.tableHeaderColor,
      fontSize: '14px',
      padding: '10px 5px',
      color: palette.secondaryColor,
    },
    '& td': {
      fontSize: '14px',
      color: palette.secondaryColor,
      padding: '10px 5px',
      textAlign: 'center',
      borderBottom: '1px solid #ccc',
    },
  },
  clearfix: {
    overflow: 'auto',
  },
  floatBtn: {
    float: 'left',
    margin: '10px',
  },
  pillMenu: {
    display: 'block',
    listStyle: 'none',
    background: palette.pillMenuBgColor,
    width: '266px',
    overflow: 'auto',
    borderRadius: '24px',
    margin: '0 auto 25px auto',
    padding: '2px;',
    '& li a': {
      float: 'left',
      color: palette.pillInactiveColor,
      padding: '15px 20px',
      borderRadius: '25px',
    },
    '& li a.active': {
      color: palette.secondaryColor,
      background: palette.pillActiveBgColor,
    },
  },
  logoMark: {
    backgroundImage: `url(${LogoMarkImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    padding: '13px 18px 10px',
    borderRadius: '5px',
    marginBottom: '8px',
    flexDirection: 'column',
    marginTop: '25px',
    '& p': {
      color: '#fff',
    },
  },
  logoTitle: {
    font: '20px SofiaPro-Bold',
    color: '#ffffff',
    textAlign: 'center',
  },
  filledButton: {
    background: 'linear-gradient(45deg, #298EDB 0%, #29D2D3 100%)',
    boxShadow: '0 6px 30px 0 rgba(0,214,219,0.8), 0 3px 10px 0 rgba(0,0,0,0.15)',
    minHeight: '50px',
    marginBottom: '10px',
    width: '100%',
    borderRadius: '10px',
    border: 'none',
    color: palette.secondaryColor,
    font: '16px SofiaPro-Medium',
  },
  chartTitle: {
    textAlign: 'center',
    color: '#aeaeae',
    margin: 0,
    font: '13px SofiaPro-Medium',
  },
}));

const Rewards = () => {
  const classes = useStyles();
  const commonClasses = commonStyle();
  const [openTab, setOpenTab] = useState('rewards');

  const reward = useRewardStatus();
  const isAuthLoading = useAuthIsLoading();

  const handleTabClick = (e) => {
    setOpenTab(e.target.id);
  };

  const handleJoinClick = () => {
    window.open('https://t.me/TheZenSports');
  };

  return (
    <Page>
      <Box className={classes.root}>
        <Box className={classes.clearfix}>
          <div className={classes.floatBtn}>
            <BackButton color={commonClasses.backBtn} />
          </div>
          <ul className={classes.pillMenu}>
            <li>
              <a id="rewards" className={(openTab === 'rewards') ? 'active' : ''} onClick={handleTabClick}>
                My Rewards
              </a>
            </li>
            <li>
              <a id="howRewards" className={(openTab === 'howRewards') ? 'active' : ''} onClick={handleTabClick}>
                How It Works
              </a>
            </li>
          </ul>
        </Box>
        <Box style={{ marginBottom: '0px' }} textAlign="center" mb={4} xs={12}>
          <Typography component="h3" className={classes.title}>
            SPORTS REWARDS PROGRAM
          </Typography>
        </Box>
      </Box>
      {openTab === 'rewards' ? (
        <>
          <Box textAlign="center" mb={4} xs={12}>
            <img src={sportsIcon} alt="SPORTS Icon" className={classes.iconImg} />
            <Typography component="h4" className={classes.subheader}>
              Earn the following rewards when betting with SPORTS tokens
            </Typography>
          </Box>
          <Box className={classes.contentCard}>
            <Box className={classes.cardTitleBox}>
              <img src={rewardsCheck} alt="Check Icon" className={classes.iconImg} />
              <h4 className={classes.cardTitle}>Lower Betting Fees</h4>
            </Box>
            <Box className={classes.cardBody}>
              <p className={classes.cardMessage}>Woohoo! You&apos;re paying lower betting fees by betting with SPORTS tokens:</p>
              <p>1. You&apos;re paying 1% for your Maker bets instead of the standard 2.5%.</p>
              <p>2. And you’re only paying 2.5% for your Taker bets instead of the standard 5.00%.</p>
            </Box>
          </Box>
          <Box className={classes.contentCard}>
            <Box className={classes.cardTitleBox}>
              <img src={rewardsCheck} alt="Check Icon" className={classes.iconImg} />
              <h4 className={classes.cardTitle}>Lower Dispute Escrow Fees</h4>
            </Box>
            <Box className={classes.cardBody}>
              <p className={classes.cardMessage}>
                Nice! You’re paying lower dispute escrow fees by
                betting with SPORTS tokens:
              </p>
              <p>
                1. Only 5% is being set aside for your Maker bets instead of the standard 10%.
              </p>
              <p>
                2. Only 2.5% is being set aside when you dispute a bet as the Taker instead of the standard 5.00%.
              </p>
              <p>
                Of course, these fees get refunded to you if you submit correct results / don&apos;t dispute a bet.
              </p>
            </Box>
          </Box>
          <Box className={classes.contentChartCard}>
            <Box className={classes.cardTitleBoxContainer}>
              <Box className={classes.cardTitleBox}>
                <img src={rewardsCheck} alt="Check Icon" className={classes.iconImg} />
                <h4 className={classes.cardTitle}>Cash Back Bonuses</h4>
              </Box>
              <Box className={classes.cardBody}>
                <p className={classes.cardMessage}>
                  Earn cash back based on your SPORTS betting volume each month!
                </p>
              </Box>
            </Box>
            <p className={classes.chartTitle}>
              Earning Period -
              {` ${new Date().toLocaleString('en-US', dateMYOptions)}`}
            </p>
            {!isAuthLoading && reward && Object.keys(reward).length ? (
              <Box mt={-3} className={classes.chartBox}>
                <Chart {...getBetChartInfo(reward, 'taker')} />
              </Box>
            ) : (
              <Loading />
            )}
            <Box className={classes.cardTitleBoxContainer}>
              <table className={classes.rewardsTable}>
                <thead>
                  <tr>
                    <th>Taker Betting Volume in SORTS</th>
                    <th>Cashback in SPORTS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>0 - 1,999,999</td>
                    <td>0%</td>
                  </tr>
                  <tr>
                    <td>2,000,000 - 5,999,999</td>
                    <td>0.25%</td>
                  </tr>
                  <tr>
                    <td>6,000,000 - 9,999,999</td>
                    <td>0.50%</td>
                  </tr>
                  <tr>
                    <td>10,000,000 - 19,999,999</td>
                    <td>1.00%</td>
                  </tr>
                  <tr>
                    <td>20,000,000+</td>
                    <td>2.00%</td>
                  </tr>
                </tbody>
              </table>
            </Box>
            {!isAuthLoading && reward && Object.keys(reward).length ? (
              <Box mt={3} className={classes.chartBox}>
                <Chart {...getBetChartInfo(reward, 'maker')} />
              </Box>
            ) : (
              <Loading />
            )}
            <Box className={classes.cardTitleBoxContainer}>
              <table className={classes.rewardsTable}>
                <thead>
                  <tr>
                    <th>Maker Betting Volume in SPORTS</th>
                    <th>Cashback in SPORTS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>0 - 1,999,999</td>
                    <td>0%</td>
                  </tr>
                  <tr>
                    <td>2,000,000 - 5,999,999</td>
                    <td>0.25%</td>
                  </tr>
                  <tr>
                    <td>6,000,000 - 9,999,999</td>
                    <td>0.50%</td>
                  </tr>
                  <tr>
                    <td>10,000,000 - 19,999,999</td>
                    <td>0.75%</td>
                  </tr>
                  <tr>
                    <td>20,000,000+</td>
                    <td>1.00%</td>
                  </tr>
                </tbody>
              </table>
              <Box className={classes.cardBody}>
                <p className={classes.cardMessage}>
                  All cash back payouts will automatically be issued to your account on the 1st of the month based
                  on the prior month’s betting volume.
                  <span aria-label="emoji" role="img">😎</span>
                </p>
              </Box>
            </Box>
          </Box>
          <Box className={classes.contentCard}>
            <Box className={classes.cardTitleBox}>
              <img src={rewardsCheck} alt="Check Icon" className={classes.iconImg} />
              <h4 className={classes.cardTitle}>Infrequent Withdrawals Bonus</h4>
            </Box>
            <Box className={classes.cardBody}>
              <p className={classes.cardMessage}>
                If you have at least $1,000 equivalent of SPORTS betting volume per month (see above), we’ll give
                you a bonus for not making withdrawals and keeping your money within ZenSports!
              </p>
            </Box>
            <table className={classes.rewardsTable}>
              <thead>
                <tr>
                  <th>Number of Withdrawals per Month</th>
                  <th>Bonus in SPORTS</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Zero</td>
                  <td>0.50%</td>
                </tr>
                <tr>
                  <td>1</td>
                  <td>0.25%</td>
                </tr>
                <tr>
                  <td>2+</td>
                  <td>0%</td>
                </tr>
              </tbody>
            </table>
            <Box className={classes.cardBody}>
              <p className={classes.cardBodyEmphasis}>
                So far this month you’ve made
                <b>
                  {` ${reward?.withdrawals} `}
                </b>
                withdrawals.
              </p>
              <p className={classes.cardMessage}>
                All bonuses for making infrequesnt withdrawals will automatically be issued to your account on the
                1st of the month based on the prior month’s betting volume and withdrawal counts.
                <span aria-label="emoji" role="img">😎</span>
              </p>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box className={classes.logoMark}>
            <Typography variant="h1" className={classes.logoTitle}>
              Welcome to the SPORTS Rewards Program in ZenSports!
            </Typography>
            <p>
              As a valuable ZenSports customer, you’re eligible to earn discounts,
              cash back, and bonuses when you bet using your SPORTS utility tokens within our app.
            </p>
            <p>
              Check out the current ways that you can earn rewards below, and be sure to check back
              here often as well as in our Telegram group, as we’ll be adding new rewards opportunities
              all the time!
            </p>
            <Box mt={3} className={classes.continue}>
              <button type="button" className={classes.filledButton} onClick={handleJoinClick}>
                Join our Telegram group
              </button>
            </Box>
          </Box>
          <Box style={{ marginTop: '20px' }} className={classes.contentCard}>
            <Box className={classes.cardTitleBox}>
              <img src={cupLogo} alt="Check Icon" className={classes.iconImg} />
              <h4 className={classes.cardTitle2}>Betting with SPORTS Tokens</h4>
            </Box>
            <Box className={classes.cardBody}>
              <p className={classes.cardMessage2}>
                Rewards are only available when you bet using SPORTS tokens.
                You can purchase SPORTS tokens using BTC or USDT directly within our Trading Exchange.
              </p>
            </Box>
          </Box>
          <Box className={classes.contentCard}>
            <Box className={classes.cardTitleBox}>
              <img src={lowerFees} alt="Check Icon" className={classes.iconImg} />
              <h4 className={classes.cardTitle2}>Lower Betting Fees</h4>
            </Box>
            <Box className={classes.cardBody}>
              <p className={classes.cardMessage2}>
                You&apos;ll only pay a 1% betting fee as the Maker and only a 2.5% betting fee as the
                Taker when betting with SPORTS tokens. In addition, you&apos;ll have lower dispute
                escrow fees set aside for your bets. ZenSports was already cheap before this,
                and now it&apos;s even cheaper when you bet in SPORTS.
              </p>
            </Box>
          </Box>
          <Box className={classes.contentCard}>
            <Box className={classes.cardTitleBox}>
              <img src={cashBack} alt="Check Icon" className={classes.iconImg} />
              <h4 className={classes.cardTitle2}>Cash Back Bonuses</h4>
            </Box>
            <Box className={classes.cardBody}>
              <p className={classes.cardMessage2}>
                Cha-ching! Earn cash back in SPORTS every single month when your betting volume reaches a certain tier.
                So in addition to saving lots of money in betting fees compared to traditional bookmakers,
                we&apos;re going to give you money just for placing bets! Check out the My Rewards tab for the exact
                cash back tiers.
              </p>
            </Box>
          </Box>
          <Box className={classes.contentCard}>
            <Box className={classes.cardTitleBox}>
              <img src={withdrawBonus} alt="Check Icon" className={classes.iconImg} />
              <h4 className={classes.cardTitle2}>Infrequent Withdrawals Bonus</h4>
            </Box>
            <Box className={classes.cardBody}>
              <p className={classes.cardMessage2}>
                We&apos;re not done giving away free money. When you make infrequent withdrawals and
                decide to keep your funds within your ZenSports account, you&apos;ll receive a SPORTS
                token bonus each month. The fewer withdrawals you make, the larger the bonus!
                Check out the My Rewards tab for the exact Infrequent Withdrawals Bonus tiers.
              </p>
            </Box>
          </Box>
        </>
      )}
    </Page>
  );
};

export default withRouter(Rewards);
