import * as actionTypes from 'store/actionTypes';

const INITIAL_FILTER = {
  betParlayTypes: ['parlay', 'bet'],
  intervals: ['season', 'multiple_games', 'single_game'],
  currencies: ['usd', 'btc', 'sports', 'icx'],
  objectTypes: ['team', 'player'],
  amount: {
    min: '',
    max: '',
  },
  betTypes: ['moneyline', 'point_spread', 'over_under', 'will_not', 'write_in'],
  roleTypes: ['maker', 'taker'],
  winLossTypes: ['won', 'lost', 'tie', 'suspended'],
  isExpired: false,
  acceptable: 'acceptable',
  allAcceptable: ['acceptable', 'not_acceptable'],
};

const INITIAL_STATE = {
  isLoading: false,
  list: [],
  single: {},
  leagues: [],
  games: [],
  objects: [],
  schedules: [],
  betTypes: [],
  message: '',
  selected: {
    league: {},
    games: [],
    object: {},
    schedule: {},
    betType: {},
    betTime: {},
    odds: { proposed_bet_amount: null, proposed_return: null },
    ...JSON.parse(JSON.stringify(INITIAL_FILTER)),
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_LEAGUES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_LEAGUES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        leagues: action.payload.leagues,
      };
    case actionTypes.GET_LEAGUES_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.GET_SCHEDULES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_SCHEDULES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        schedules: action.payload.schedules,
      };
    case actionTypes.GET_SCHEDULES_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.GET_BET_TYPES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_BET_TYPES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        betTypes: action.payload.betTypes,
      };
    case actionTypes.GET_BET_TYPES_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.GET_UPCOMING_GAMES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_UPCOMING_GAMES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        games: action.payload.list,
      };
    case actionTypes.GET_UPCOMING_GAMES_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.GET_BET_OBJECTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_BET_OBJECTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        objects: action.payload.list,
      };
    case actionTypes.GET_BET_OBJECTS_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.GET_BETS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_BETS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: action.payload.list,
      };
    case actionTypes.GET_BETS_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.GET_BET_RESULTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_BET_RESULTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: action.payload.list,
      };
    case actionTypes.GET_BET_RESULTS_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.GET_BET_FAVORITES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_BET_FAVORITES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: action.payload.list,
      };
    case actionTypes.GET_BET_FAVORITES_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.GET_BET_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_BET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        single: action.payload.single,
      };
    case actionTypes.GET_BET_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.ACCEPT_BET_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.ACCEPT_BET_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.ACCEPT_BET_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.CREATE_BET_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.CREATE_BET_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.CREATE_BET_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.SET_RESULTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.SET_RESULTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.SET_RESULTS_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.DISPUTE_RESULTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.DISPUTE_RESULTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.DISPUTE_RESULTS_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.DELETE_BET_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.DELETE_BET_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.DELETE_BET_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.EDIT_BET_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.EDIT_BET_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.EDIT_BET_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.SET_LEAGUE:
      return {
        ...state,
        selected: { ...state.selected, league: action.payload },
      };
    case actionTypes.SET_INTERVALS:
      return {
        ...state,
        selected: { ...state.selected, intervals: action.payload },
      };
    case actionTypes.SET_GAMES:
      return {
        ...state,
        selected: { ...state.selected, games: action.payload },
      };
    case actionTypes.SET_BET_OBJECT:
      return {
        ...state,
        selected: { ...state.selected, object: action.payload },
      };
    case actionTypes.SET_SCHEDULE:
      return {
        ...state,
        selected: { ...state.selected, schedule: action.payload },
      };
    case actionTypes.SET_BET_TYPE:
      return {
        ...state,
        selected: { ...state.selected, betType: action.payload },
      };
    case actionTypes.SET_BET_TIME:
      return {
        ...state,
        selected: { ...state.selected, betTime: action.payload },
      };
    case actionTypes.SET_BET_ODDS:
      return {
        ...state,
        selected: { ...state.selected, odds: action.payload },
      };
    case actionTypes.SET_AMOUNT:
      return {
        ...state,
        selected: { ...state.selected, amount: action.payload },
      };
    case actionTypes.SET_FILTER:
      return {
        ...state,
        selected: { ...state.selected, ...action.payload },
      };
    case actionTypes.RESET_FILTER:
      return {
        ...state,
        selected: {
          ...state.selected,
          ...JSON.parse(JSON.stringify(INITIAL_FILTER)),
        },
      };
    default:
      return state;
  }
};
