import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getFundsOverviewAction,
  createCreditCardDepositAction,
} from 'store/actions/funds';

export const useFundsOverview = (params) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (params) {
      dispatch(getFundsOverviewAction(params));
    }
    // eslint-disable-next-line
  }, [params]);
  return useSelector(({ funds: { overview } }) => overview);
};

export const useCreateCreditCardDeposit = () => {
  const dispatch = useDispatch();
  return (data) => dispatch(createCreditCardDepositAction(data));
};

export default useFundsOverview;
