import httpService from './http.service';

const getCountries = (params) => httpService
  .get('/countries/all', {}, params)
  .then(({ data }) => data.sort((a, b) => (a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1)))
  .catch((err) => Promise.reject(err?.response?.data));

const getRegistrationLocations = (params) => httpService
  .get('/registration_locations', {}, params)
  .then(({ data }) => data.sort((a, b) => (a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1)))
  .catch((err) => Promise.reject(err?.response?.data));

export default {
  getCountries,
  getRegistrationLocations,
};
