import React from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles, Button } from '@material-ui/core';

const useStyles = makeStyles({
  root: (props) => ({
    padding: '7px 0px',
    minWidth: '100px',
    background: props.selected ? 'linear-gradient(45deg, #298EDA, #29D2D3)' : '#FFFFFF',
    border: props.selected ? 'none' : '1px solid #14133D',
    borderRadius: '4px',
    font: '14px SofiaPro-SemiBold',
    letterSpacing: '-0.5px',
    boxShadow: 'none',
    marginTop: '10px',
    color: props.selected ? 'white' : '#14133D',
  }),
});

const SelectButton = ({
  selected,
  children,
  onClick,
  className,
}) => {
  const classes = useStyles({ selected });

  return (
    <Box className={className}>
      <Button className={classes.root} onClick={onClick}>
        { children }
      </Button>
    </Box>
  );
};

SelectButton.defaultProps = {
  selected: false,
  className: '',
  onClick: () => {},
};

SelectButton.propTypes = {
  children: PropTypes.node.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default SelectButton;
