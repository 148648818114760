import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import { Box, Typography } from '@material-ui/core';

import Page from 'components/Page/Dashboard';
import BackButton from 'components/Buttons/BackButton';
import DetailCard from 'components/KYC/DetailCard';
import OutlineButton from 'components/Buttons/OutlineButton';

import { useCountries } from 'hooks/redux/country';
import { useAccountOverviewInfo, useGetOverviewActionDispatch } from 'hooks/redux/account';
import {
  useOpenErrorModalDispatch,
  useOpenConfirmModalDispatch,
  useOpenSuccessModalDispatch,
} from 'hooks/redux/modal';
import {
  useKycUserInfo,
  useKycDocInfo,
  useKycResourceToken,
  useUpdateAccountDispatch,
  useSetKycDocInfoDispatch,
  useSetKycUserInfoDispatch,
} from 'hooks/redux/kyc';
import {
  getKycItems,
  getExceptions,
  getSubmitKycInfo,
} from 'utils/kyc';
import { documentTypes } from 'utils/constants';
import CommonStyle from 'styles/common';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: '0 15px',
  },
  title: {
    font: '30px SofiaPro-Bold',
    color: palette.secondaryColor,
  },
}));

const View = ({ history, match }) => {
  const classes = useStyles();
  const commonClasses = CommonStyle();
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const [kycAttempts, setKycAttempts] = useState(0);
  const [submitButtonText, setSubmitButtonText] = useState('Submit KYC');
  const [kycItems, setKycItems] = useState([]);

  const isResolve = match.path.includes('resolve');
  const overview = useAccountOverviewInfo();
  const user = useKycUserInfo();
  const doc = useKycDocInfo();
  const countries = useCountries();
  const resourceToken = useKycResourceToken();
  const setKycDoc = useSetKycDocInfoDispatch();
  const setKycUser = useSetKycUserInfoDispatch();
  const openSuccessModal = useOpenSuccessModalDispatch();
  const openErrorModal = useOpenErrorModalDispatch();
  const openConfirmModal = useOpenConfirmModalDispatch();
  const updateAccount = useUpdateAccountDispatch();
  const getOverview = useGetOverviewActionDispatch();

  const handleSubmit = () => {
    if (kycAttempts > 1) {
      history.push('/kyc/payment');
    } else {
      const data = getSubmitKycInfo(user, doc, resourceToken);
      openConfirmModal({
        title: 'Confirm KYC Submission',
        subtitle: `Before you submit your KYC information again, make sure that everything is correct.
       You are allowed two free submissions, any more submissions after that will
        result in a fee equivalent to $10 worth of any currency ZenSports offers.`,
        agreeBtnText: 'Yes, Submit KYC',
        disagreeBtnText: 'No Thanks',
        callback: async () => {
          try {
            setDisabledSubmit(true);
            await updateAccount(data);
            setDisabledSubmit(false);
            openSuccessModal({
              title: 'Successfully submitted!',
              subtitle: '',
              buttonText: 'Got it',
            });
            setSubmitButtonText('Re-submit KYC');
            setKycAttempts(kycAttempts + 1);
            getOverview();
          } catch (e) {
            setSubmitButtonText('Re-submit KYC');
            setDisabledSubmit(false);
            openErrorModal({
              title: 'Submit Error',
              subtitle: '',
              buttonText: 'Got it',
            });
          }
        },
      });
    }
  };

  useEffect(() => {
    if (overview.failed_kyc_attempts) {
      setKycAttempts(overview.failed_kyc_attempts);
    }
    if (user.email) {
      setKycItems(getKycItems(user, doc));
    } else if (overview.account_id && countries.length > 0) {
      // populate
      const kycUser = {
        dob: overview.dob,
        name: overview.name,
        email: overview.email,
        country: countries.find((country) => country.country_code === overview.country) || {},
        state: { name: overview.region },
        city: overview.city,
        postal_code: overview.postal_code,
        street_1: overview.street_1,
        street_2: overview.street_2,
      };
      const kycDoc = {
        country: countries.find((country) => country.country_code === overview.tax_country) || {},
        state: { name: overview.tax_state },
        documentType: {},
        tax_id_number: overview.tax_id,
        uploaded_front_id: '',
        uploaded_back_id: '',
      };
      const usaStates = countries.find((c) => c.country_code === 'US')?.states || [];
      if (overview.country === 'US') {
        kycUser.state = usaStates.find((s) => s.abbreviation === overview.region) || {};
      }
      if (overview.tax_country === 'US') {
        kycDoc.state = usaStates.find((s) => s.abbreviation === overview.tax_state) || {};
      }
      if (overview.kyc_document_checks?.length) {
        kycDoc.documentType = documentTypes.find((d) => d.value === overview.kyc_document_checks[0].type) || {};
      }
      setKycUser(kycUser);
      setKycDoc(kycDoc);
      setKycItems(getKycItems(kycUser, kycDoc));
    }
    // eslint-disable-next-line
  }, [overview.account_id, user.email, countries.length]);

  return (
    <Page>
      <Box className={classes.root} display="flex" flexDirection="column" flex={1}>
        <Box>
          <BackButton color={commonClasses.backBtn} page="/kyc/status" />
        </Box>
        <Box textAlign="center" mt={3} mb={3}>
          <Typography component="h3" className={classes.title}>
            KYC/AML Details
          </Typography>
        </Box>
        <Box mb={5}>
          {
            kycItems.map((item) => (
              <DetailCard
                key={item.title}
                title={item.title}
                items={item.items}
                route={item.route}
                isResolve={isResolve}
                exceptions={
                  getExceptions(overview.primetrust_exceptions, item.section)
                }
              />
            ))
          }
        </Box>
        {
          isResolve && (
            <OutlineButton onClick={handleSubmit} disabled={disabledSubmit}>
              { submitButtonText }
            </OutlineButton>
          )
        }
      </Box>
    </Page>
  );
};

View.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withRouter(View);
